import { CheckingAccount } from "./checking_account";
import { Transaction } from "./transaction";
import { Phi } from "../phi/phi";

export class Check
{

    checkId: number;
    checkNumber: string;
    checkingAccount: CheckingAccount;
    checkAmount: number;
    checkText: string;
    payee: Phi;
    checkMemo: string;
    checkDate: Date;
    void: boolean;
    voidDate: Date;
    nonCheckPayment: boolean;
    nonCheckReference: string;
    transactions: Transaction[];
}


export class CheckPosting
{
    checkingAccountId: number;
    transactionIds: number[];
    firstCheck: string;
    attachToOpenInvoice: boolean;
}