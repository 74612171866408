import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AppComponent } from './app.component';
import { HomePageComponent } from './base/home-page/home-page.component';
import { PlanDetailComponent } from './phi/plan-detail/plan-detail.component';
import { MatchingComponent } from './encounters/matching/matching.component';
import { ProviderResearchComponent } from './research/providers/provider-research/provider-research.component';
import { EncounterLookupComponent } from './encounters/encounter-lookup/encounter-lookup.component';
import { ProcedureLookupComponent } from './research/instance/procedure-lookup/procedure-lookup.component';
import { CompanyManagerComponent } from './support/company-manager/company-manager.component';
import { InvoiceListComponent } from './finance/invoice-list/invoice-list.component';
import { TransactionListComponent } from './finance/transaction-list/transaction-list.component';
import { ReportviewerComponent } from './reports/reportviewer/reportviewer.component';
import { ReportrunnerComponent } from './reports/reportrunner/reportrunner.component';
import { ReportmanagerComponent } from './reports/reportmanager/reportmanager.component';
import { SubscriberSearchComponent } from './phi/subscriber-search/subscriber-search.component';
import { InstanceComponent } from './research/instance/instance.component';
import { InstanceSearchComponent } from './research/instance/instance-search/instance-search.component';
import { HomePageV2Component } from './base/home-page-v2/home-page-v2.component';
import { MailSendManagerComponent } from './util/mail-send-manager/mail-send-manager.component';
import { JobMonitorComponent } from './util/job-monitor/job-monitor.component';

const routes: Routes = 
[
  { path: '', component: HomePageComponent, pathMatch:'full'},
  { path: 'home', redirectTo:'', pathMatch:'full'},
  { path: 'home_v2', component: HomePageV2Component },
  // { path: 'plandetail', component: PlanDetailComponent},
  // { path: 'plandetail', component: SearchWithDetailsComponent},
  { path: 'encounter/lookup', component: EncounterLookupComponent },
  { path: 'encounter/:encounterId', component: HomePageComponent },
  { path: 'phi/:phiId', component: HomePageComponent },
  { path: 'phi/:phiId/encounter/:encounterId', component: HomePageComponent },
  { path: 'research/matching', component: MatchingComponent},
  { path: 'research/provider', component: ProviderResearchComponent},
  { path: 'research/procedurelookup', component: ProcedureLookupComponent},
  { path: 'research/instance/:searchType', component: InstanceSearchComponent },
  { path: 'research/plan', component: PlanDetailComponent},
  { path: 'research/member', component:SubscriberSearchComponent },
  { path: 'utility/companymanager', component: CompanyManagerComponent},
  { path: 'utility/invoicemanager', component: InvoiceListComponent},
  { path: 'utility/transactionmanager', component: TransactionListComponent},
  { path: 'utility/massemailmanager', component: MailSendManagerComponent},
  { path: 'utility/jobmanager', component: JobMonitorComponent},
  { path: 'reports/viewreports', component: ReportviewerComponent},
  { path: 'reports/runreports', component: ReportrunnerComponent},
  { path: 'reports/definereports', component: ReportmanagerComponent},
  
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {onSameUrlNavigation: 'reload'})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
