<p-autoComplete placeholder="Search" [(ngModel)]="selectedPhiSearchResult" field="displayName" #phiAutocomplete
                [suggestions]="phiSearchResults" (completeMethod)="phiSearch($event)"
                (onSelect)="phiSelectedFromSearch($event)" [dropdown]="true" dropdownMode="current">
                <!--id="phiAutocomplete" [suggestions]="phiSearchResults" (completeMethod)="phiSearch($event)"-->
    <ng-template let-phi pTemplate="item">
      <div>
        <span style="margin-right: 5px;"><i class="pi {{phi.resultType}}"></i></span>{{phi.displayName}}
      </div>
    </ng-template>
  </p-autoComplete>
