<p-dialog [(visible)]="showAddressEditor" [style]="{width:'600px'}" [closable]="false">
  <table style="width:100%">
    <tr>
      <td style="text-align: center;background-color: #007ad9;color: white">Active</td>
      <td style="text-align: center;background-color: #007ad9;color: white">Type</td>
      <td style="text-align: center;background-color: #007ad9;color: white">Address</td>
      <td *ngIf="showMarketing" style="text-align: center;background-color: #007ad9;color: white">Mail</td>
    </tr>
    <tr *ngFor="let ad of addresses; index as idx">
      <td style="text-align:center">
        <p-checkbox [(ngModel)]="ad.active" [binary]="true" [disabled]="!editablePhi"></p-checkbox>
      </td>
      <td style="text-align:center">
        <p-dropdown [options]="ContactTypes" [(ngModel)]="ad.addressType" optionLabel="description" appendTo="body"></p-dropdown>
      </td>
      <td style="text-align:center">
        <p-inplace [disabled]="!editablePhi" [closable]="true" closeIcon="pi pi-check">
          <ng-template pTemplate="display">
            <span class="field-data">{{ad.address1}} {{ad.address2}}<br>{{ad.city}}, {{ad.state}} {{ad.zipCode}}</span>
          </ng-template>
          <ng-template pTemplate="content">
            <input type="text" [(ngModel)]="ad.address1" pInputText style="width:300px"><br>
            <input type="text" [(ngModel)]="ad.address2" pInputText style="width:300px"><br>
            <input type="text" [(ngModel)]="ad.city" pInputText style="width:150px">
            <input type="text" [(ngModel)]="ad.state" pInputText style="width:50px">
            <input type="text" [(ngModel)]="ad.zipCode" pInputText style="width:100px">
          </ng-template>
        </p-inplace>
      </td>
      <td *ngIf="showMarketing" style="text-align:center">
        <p-checkbox [binary]="true" [disabled]="!editablePhi"></p-checkbox>
      </td>
    </tr>
  </table>
  <ng-template pTemplate="footer">
    <button pButton pRipple label="Add Address" class="p-button-small p-button-rounded p-button-info" (click)="addAddress()"></button>
    <span class="p-buttonset">
      <button pButton pRipple label="Cancel" class="p-button-small p-button-rounded p-button-danger" (click)="cancelButton($event)"></button>
      <button pButton pRipple label="Save" class="p-button-small p-button-rounded p-button-success" (click)="updateButton($event)"></button>
    </span>
  </ng-template>
</p-dialog>
