import { User } from '../util/user';
import { Carrier } from './carrier';
import { Source } from './source';

export class Network
{
    networkId: number;
    networkName: string;
    carrier: Carrier;
    clientNetworkName: string;
    modifiedBy: User;
    sources: Source[];

    get displayName() : string {return this.networkName + " - " + this.clientNetworkName;}

}

export interface NetworkGroup
{
    groupId: number;
    groupName: string;
    source: Source;
    networks: Network[];
}