import { Company } from '../support/company'
import { Phi } from '../phi/phi'
import { User } from '../util/user';
export class Person
{
    personId: number;
    company: Company;
    phi: Phi;
    active: boolean;
    modifiedBy: User;
}