<p-dialog header="Please Wait..." [(visible)]="invoicesUpdating">
    <p-progressBar [value]="invoiceUpdatePercentComplete"></p-progressBar>
</p-dialog>


<div class="flex flex-column fill-vert no-overflow p-1 pb-5">    
    <!-- Top Search Bar -->
    <p-panel header='Transaction Search Criteria'>
    <!-- Approved Date Range -->
        <div class="pt-3">
            <div class="formgroup-inline">
                <span class="p-float-label field">
                    <p-calendar id="invoiceDateStart" [(ngModel)]="invoiceDateStart"></p-calendar>
                    <label for="invoiceDateStart">Invoiced On Or After</label> 
                </span>
                <span class="p-float-label field">
                    <p-calendar id="invoiceDateEnd" [(ngModel)]="invoiceDateEnd"></p-calendar>
                    <label for="invoiceDateEnd">Invoiced On Or Before</label> 
                </span>
                <span class="field-checkbox pl-3">
                    <p-checkbox [(ngModel)]="showClosed" [binary]="true" id="showClosed"></p-checkbox>                        
                    <label for="showClosed">Show Invoiced</label> 
                </span>    

                <div class="field">
                    <app-search-button [searchFunction]="searchFunction"></app-search-button>
                </div>     
            </div>
        </div>
    </p-panel>

    <p-dialog [modal]="true" [closable]="false" appendTo="body" [showHeader]="false" [style]="{'box-shadow':'none'}" [contentStyle]="{'background':'rgba(0,0,0,0)'}" [(visible)]="loading">
        <p-progressSpinner [style]="{'background':'rgba(0,0,0,0)','width':'50px','height':'50px'}" animationDuration=".5s" strokeWidth="8"></p-progressSpinner>
    </p-dialog>

    <p-overlayPanel #newInvoice>
        <ng-template pTemplate>
        </ng-template>
    </p-overlayPanel>

    <div class="pt-2 pb-6 flex flex-column fill-vert" style="box-sizing:border-box;">
        <!-- Transaction Results -->
        <p-table [value] = "foundInvoices" [(selection)]="selectedInvoices" 
                dataKey="invoiceId" sortMode="multiple" [scrollable]="true" 
                scrollHeight="520px" (onHeaderCheckboxToggle)="updateContextMenu($event)" 
                (onRowSelect)="updateContextMenu($event)" (onRowUnselect)="updateContextMenu($event)"
                editMode="row">
            <ng-template pTemplate="header">
                <tr>
                    <th style="width: 12rem">
                        <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                        <button class="ml-2" type="button" pButton icon="pi pi-bars"  (click)="actionMenu.toggle($event)"></button>
                        <button class="ml-2" type="button" pButton icon="pi pi-plus" (click)="newInvoice.toggle($event)"></button>
                        <p-menu #actionMenu [popup]="true" [model]="actions" appendTo="body"></p-menu>
                    </th>
                    <th pSortableColumn="invoiceId">
                        Invoice Number 
                        <p-sortIcon field="invoiceId"></p-sortIcon>
                    </th>
                    <th pSortableColumn="company.companyName">Client<p-sortIcon field="company.companyName"></p-sortIcon></th>
                    <th pSortableColumn="invoiceDate">Invoice Date<p-sortIcon field="invoiceDate"></p-sortIcon></th>
                    <th>Activity Start</th>
                    <th>Activity End</th>
                    <th>PO #</th>
                    <th>Subscribers</th>
                    <th>Admin Fee</th>
                    <th>Incentives</th>
                    <th>Rewards</th>
                    <th>Invoice Total</th>
                    <th pSortableColumn="closed">
                        Closed
                        <p-sortIcon field="closed"></p-sortIcon>
                    </th>
                    <th style="width:9rem;"></th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-invoice let-editing="editing" let-ri="rowIndex">
                <tr [pEditableRow]="invoice">
                    <td  style="width: 12rem">
                        <p-tableCheckbox [value]="invoice"></p-tableCheckbox>
                    </td>
                    <td>{{invoice.invoiceId}}</td>
                    <td>{{invoice.company.companyName}}</td>
                    <td>{{invoice.invoiceDate | date}}</td>
                    <td>{{invoice.activityBegin | date}}</td>
                    <td>{{invoice.activityEnd | date}}</td>
                    <td>
                        <p-cellEditor>
                            <ng-template pTemplate="input">
                                <input type="text" [(ngModel)]="invoice.poNumber">
                            </ng-template>
                            <ng-template pTemplate="output">
                                {{invoice.poNumber}}
                            </ng-template>
                        </p-cellEditor>
                    </td>
                    <td>
                        <p-cellEditor>
                            <ng-template pTemplate="input">
                                <p-inputNumber [(ngModel)]="invoice.eeCount"></p-inputNumber>
                                <!-- <p-dropdown appendTo="body" id="transactionAccount" [showClear]="false" [style]="{'width':'250px'}" [options]="this.tcemsUtilities.checkingAccounts" [(ngModel)]="transaction.account" optionLabel="accountName" [autoDisplayFirst]="false"></p-dropdown> -->
                            </ng-template>
                            <ng-template pTemplate="output">
                                {{invoice.eeCount}}
                            </ng-template>
                        </p-cellEditor>
                    </td>
                    <td>{{invoice.adminFeeAmount | currency: 'USD' : "symbol"}}</td>
                    <td>{{invoice.incentivesAmount | currency: 'USD' : "symbol"}}</td>
                    <td>{{invoice.rewardAmount | currency: 'USD' : "symbol"}}</td>
                    <td>{{invoice.totalAmount | currency: 'USD' : "symbol" }}</td>
                    <td><p-checkbox [ngModel]="invoice.closed" [binary]="true" [readonly]="true" ></p-checkbox></td>
                    <button *ngIf="!editing" pButton pRipple type="button" icon="pi pi-print" (click)="printInvoice(invoice)" class="p-button-rounded p-button-text"></button> 
                    <button *ngIf="!editing" pButton pRipple type="button" pInitEditableRow icon="pi pi-pencil" (click)="onRowEditInit(invoice)" class="p-button-rounded p-button-text"></button> 
                    <button *ngIf="!editing" pButton pRipple type="button" pInitEditableRow icon="pi pi-trash" (click)="deleteInvoice(invoice)" class="p-button-rounded p-button-text p-button-danger"></button> 
                    <button *ngIf="editing" pButton pRipple type="button" pSaveEditableRow icon="pi pi-check" (click)="onRowEditSave(invoice, ri)" class="p-button-rounded p-button-text p-button-success mr-2"></button> 
                    <button *ngIf="editing" pButton pRipple type="button" pCancelEditableRow icon="pi pi-times" (click)="onRowEditCancel(invoice, ri)" class="p-button-rounded p-button-text p-button-danger"></button> 
            
                </tr>
            </ng-template>   
            <!-- <ng-template pTemplate="summary">
                <div class="grid">
                    <div class="col-2">
                        Transactions Found: {{totalTransactions}}
                    </div>                    
                    <div class="col-2">
                        Transactions Selected: {{selectedTransactionCount}}
                    </div>
                    <div class="col-2">
                        Value of Selected Transactions: {{selectedTransactionTotal | currency: 'USD' : "symbol" }}
                    </div>
                </div>
            </ng-template>     -->
        </p-table>
    </div> 

</div>