import { HEMIFileDescriptor } from "../util/filedescriptor";

export class Email 
{
    id: number;
    encounterId: number;
    recipients: string[];
    subject: string;
    cCs: string[];
    bcCs: string[];
    body: string;
    from: string;
    attachments: HEMIFileDescriptor[];
    sent: boolean;
    received: boolean;
    dateSent: Date;
    read: boolean;

    toWrite() : EmailWrite
    {
        let ew =  Object.assign(new EmailWrite(), this);
        ew.attachmentIds = this.attachments.map(a => a.fileId);
        return ew;
    }
}

export class EmailWrite
{
    encounterId: string;
    recipients: string[];
    subject: string;
    ccs: string[];
    bccs: string[];
    body: string;
    from: string;
    attachmentIds: string[];
}
