//This allows for multiple components to listen to login status
//It works with the rxjs subscriber and behavior subject that are built out in LoginService

import { Subscription } from 'rxjs';
import { LoginService, Login } from '../login/login.service';
import { OnInit, Directive } from '@angular/core';


//This is an interface that'll guarantee that anyone that is listening to login status can do their own thing if login status changes
export interface LoginStatusListener
{
    loginStatusChange (status: Login);
}


//The base implementation of the interface here will always keep track of logged in user, id and status.
//By accessing the login service 
@Directive({
    selector: 'abstract-login-status-listener'
})
export abstract class AbstractLoginStatusListener implements OnInit, LoginStatusListener
{

    protected loginSubscription: Subscription;
    public loginStatus: Login;
    
    //Dependency injection for our login service
    constructor (private ls: LoginService){}


    //On initialization of angular, register ourselves as a subscriber of the login subject
    ngOnInit() : void
    {
        this.loginSubscription = this.ls.loggedIn$.subscribe(status => 
            {
                //When things change, then we'll update our own status
                this.loginStatus = status;

                //And then do anything else we want to with that Login object
                this.loginStatusChange(status);
            });
    }

    abstract loginStatusChange(status: Login);
}