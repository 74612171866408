<div class="grid fill-vert">
  <div class="col-4 p-2">
    <app-phi-summary [phi]="currentMember" header="MEMBER/PATIENT" [editablePhi]="true" (optOutStatusChange)="toggleOptOut($event)"></app-phi-summary>

    <!--
    <div class="category-header" style="margin-top: 30px;">TREATMENT</div>

    <table class="data-table">
      <tr>
        <td class="field-name">Preferred Zip</td>
        <td class="field-data">12345</td>
      </tr>
      <tr>
        <td class="field-name">Distance</td>
        <td class="field-data">30 miles</td>
      </tr>
      <tr>
        <td class="field-name">Notes</td>
        <td class="field-data">Appointments must be early in the day, can't drive after 2 pm. Also, car can only turn
          right, so schedule appointments in a clockwise fashion.
        </td>
      </tr>
    </table>
    -->

  </div>

  <div class="col-4 p-2">

    <!-- <app-plan-summary [phi]="currentMember" (planUpdated)="planUpdated($event)" (planInserted)="planUpdated($event)" (phiCompanyChanged)="memberCompanyChanged($event)"></app-plan-summary> -->

    <app-plan-summary [phi]="currentMember" (phiChange)="currentMemberChanged($event)" ></app-plan-summary>
    <app-eob-summary [phi]="currentMember"></app-eob-summary>

  </div>

  <div class="col-4 p-2">
    <!-- Online profile linker -->
    <app-b2cuser-list [phi]="currentMember"></app-b2cuser-list>
    <!-- Notes and diaries -->
    <p-accordion class="fill-vert">
      <p-accordionTab header="Diaries">
        <div style="height:500px">
          <app-diary-scroller [getForCurrentUser]="false" [referenceCode]=2 [relatedTo]="currentMember.phiId" rowBufferLen="5" [allowNew]="true" ></app-diary-scroller>
        </div>
      </p-accordionTab>
      <p-accordionTab header="Notes">
        <div style="height:500px">
          <app-note-scroller [referenceCode]="notesRefCode" [relatedTo]="currentMember.phiId" rowBufferLen="5" ></app-note-scroller>
        </div>
      </p-accordionTab>
    </p-accordion>
  </div>

</div>


