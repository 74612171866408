import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Line } from 'src/app/model/claim/line';

@Component({
  selector: 'app-linelist',
  templateUrl: './linelist.component.html',
  styleUrls: ['./linelist.component.css']
})
export class LinelistComponent implements OnInit {

  @Input() lines: Line[];
  @Output() chosenLine: EventEmitter<Line> = new EventEmitter<Line>();

  selectedLine: Line;

  constructor() { }

  ngOnInit(): void 
  {
  }

  selectLine(event)
  {
    this.chosenLine.emit(this.selectedLine);
  }
  
  unselectLine(event)
  {
    this.chosenLine.emit(null);
  }

}
