import { User } from "../util/user";
import { ReferenceCode } from "./reference_code";

export class Note
{

    constructor()
    {
        this.noteType = new NoteType();
        this.noteType.id = 1;
        this.noteType.typeName = "General Note"
    }

    noteId: number;
    referenceId: number;
    noteText: string;
    shortDesc: string;
    noteDate: Date;
    timeStart: Date;
    referenceType: ReferenceCode;
    createdBy: User;
    noteType: NoteType;
    isObsolete: boolean;
    obsoletedBy: User;

    asUpdate(): NoteUpdate
    {
        let nu = new NoteUpdate();
        nu.shortDesc = this.shortDesc;
        nu.noteText = this.noteText;
        nu.noteType = this.noteType;
        return nu;
    }
}

export class NoteUpdate
{
    shortDesc: string;
    noteText: string;
    noteType: NoteType;
    isObsolete: boolean;
}


export class NoteType
{
    id: number;
    typeName: string;
}