<!-- This is our top banner. We'll throw the basic instance details here -->
    
        <div *ngIf="modelData!=null" class="flex fill-vert scroll-overflow">
        <p-panel>
            <p-header style="width:100%;" >
                <div class="grid pt-1 pl-1" >
                    
                    <div class="col-2">
                        <span><i class="pi pi-info"></i></span>
                        <span class="pl-3">{{modelData?.instanceId}}</span>
                    </div>
                    <div class="col-3">
                        <span><i class="pi pi-id-card"></i></span>
                        <span class="pl-3">{{modelData?.memberId}}</span>
                    </div>
                    <div class="col-2">
                        <span><i class="pi pi-calendar"></i></span>
                        <span class="pl-3">{{modelData?.serviceBegin | date:'shortDate'}}</span>
                    </div>
                    <div class="col-5">
                        <span><i class="pi pi-briefcase"></i></span>
                        <span class="pl-3 no-wrap" >
                            <a (mouseover)="descOverlay.show($event)" (mouseleave)="descOverlay.hide()">
                                {{modelData?.shortDescription()}}
                            </a>
                            <p-overlayPanel #descOverlay (mouseleave)="descOverlay.hide()" appendTo="body" >
                                <ng-template pTemplate>
                                    {{modelData?.generalProcedureDescription}}
                                </ng-template>
                            </p-overlayPanel>
                        </span>
                    </div>
                </div>
            </p-header>
        <!-- There are several sections here for each of the levels to this instance -->
        <!-- Visits -->
            <div class="scroll-overflow">
            <p-fieldset legend="Visits" [toggleable]="true" [collapsed]="true">
                <p-table [value] = "visits" selectionMode="multiple" [(selection)]="selectedVisits" (onRowSelect) = "selectVisit($event)"  (onRowUnselect) = "unselectVisit($event)">
                    <ng-template pTemplate="header">
                        <tr>
                            <th>Visit Id</th>
                            <th>Practice/Provider</th>
                            <th>Visit Cost</th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-visit>
                        <tr [pSelectableRow]="visit">
                            <td>{{visit.visitId}}</td>
                            <td>{{visit.providerLocation?.practiceName}} - {{visit.providerLocation?.providerName}}</td>
                            <td>{{visit.visitCost | currency: 'USD' : "symbol"}}</td>
                        </tr>
                    </ng-template>
                </p-table>
            </p-fieldset>

        <!-- Claims -->
            <p-fieldset legend="Claims" [toggleable]="true" [collapsed]="true">
                <p-table [value] = "claims" selectionMode="multiple" [(selection)]="selectedClaims" (onRowSelect) = "selectClaim($event)" (onRowUnselect) = "unselectClaim($event)">
                    <ng-template pTemplate="header">
                        <tr>
                            <th>Claim Id</th>
                            <th>Claim Number</th>
                            <th>Claim Cost</th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-claim>
                        <tr [pSelectableRow]="claim">
                            <td>{{claim.claimId}}</td>
                            <td class="wrap">{{claim.claimNumber}}</td>
                            <td>{{claim.claimCost | currency: 'USD' : "symbol"}}</td>
                        </tr>
                    </ng-template>            
                </p-table>
            </p-fieldset>

        <!-- Lines -->
            <p-fieldset legend="Lines/Procedures" [toggleable]="true" #target>

                <p-table [value] = "lines" selectionMode="multiple" [(selection)]="selectedLines" (onRowSelect) = "selectLine($event)" (onRowUnselect) = "unselectLine($event)">
                    <ng-template pTemplate="header">
                        <tr>
                            <!-- <th>Line Id</th> -->
                            <th pSortableColumn="procedure.code">Procedure Code</th>
                            <th>Description</th>
                            <th>Units</th>
                            <th>Unit Cost</th>
                            <th>Total Cost</th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-line>
                        <tr [pSelectableRow]="line">
                            <!-- <td>{{line.lineId}}</td> -->
                            <td>{{line.procedure.code}}</td>

                            <td>
                                <a (mouseover)="op.show($event,target)" (mouseleave)="op.hide()" >
                                    {{line.procedure.shortDescription()}}

                                <p-overlayPanel #op (mouseleave)="op.hide()">
                                    <ng-template pTemplate>
                                        {{line.procedure.description}}
                                    </ng-template>
                                </p-overlayPanel>
                            </a>
                            </td>
                            <td>{{line.units}}</td>
                            <td>{{line.unitCost | currency: 'USD' : "symbol"}}</td>
                            <td>{{line.cost | currency: 'USD' : "symbol"}}</td>
                        </tr>
                    </ng-template>            
                </p-table>
            </p-fieldset>
            </div>
            <p-footer style="width:100%; position: -webkit-sticky; position:sticky ; bottom: 0px">
                <div class="grid pt-1 pl-1">
                    <div class="p-col">
                        <span>Instance Cost: {{modelData?.instanceCost | currency: 'USD' : "symbol" }}</span>
                        <span class="pl-3">Selected Cost: {{selectedProcedureTotal | currency: 'USD' : "symbol" }} </span>
                    </div>
                </div>
            </p-footer>
        </p-panel>
        </div>


