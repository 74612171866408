export class InstanceOfCareSummary
{
    instanceId: number;
    memberId: string;
    sourceId: number;
    sourceName: string;
    networkId: number;
    networkName: string;
    inNetwork: boolean;
    serviceBegin: Date;
    serviceEnd: Date;
    generalProcedureDescription: string;
    providerIdList: string;
    providierNameList:string;
    practiceIdList:string;
    practiceNameList:string;
    codeList:string;
    claimNumbers:string;
    visitCount:number;
    claimCount:number;
    lineCount:number;
    instanceCost:number;

    constructor() {};

    public shortDescription(): string
    {
        if (this.generalProcedureDescription == null) return "";
        let splitDesc: string[] = this.generalProcedureDescription.split(",");
        return (splitDesc.length > 0 ? splitDesc[0] : this.generalProcedureDescription).substr(0,25);
    }
}