import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { MenuItem } from 'primeng/api';
import { forkJoin } from 'rxjs';
import { map, mergeAll, mergeMap } from 'rxjs/operators';
import { TcemsService } from 'src/app/api/tcems.service';
import { Encounter } from 'src/app/model/encounter/encounter';
import { Check, CheckPosting } from 'src/app/model/finance/check';
import { CheckingAccount } from 'src/app/model/finance/checking_account';
import { Transaction } from 'src/app/model/finance/transaction';
import { Phi } from 'src/app/model/phi/phi';
import { Plan } from 'src/app/model/phi/plan';
import { Company } from 'src/app/model/support/company';
import { Person } from 'src/app/model/support/person';
import { TcemsUtilitiesService } from 'src/app/util/tcems-utilities.service';


@Component({
  selector: 'app-transaction-list',
  templateUrl: './transaction-list.component.html',
  styleUrls: ['./transaction-list.component.css']
})
export class TransactionListComponent implements OnInit
{


  approvedStart: Date;
  approvedEnd: Date;
  showInvoiced: boolean = false;
  showSelfPaid: boolean = false;


  showNewTransactionDialog: boolean = false;

  transactions: Transaction[];
  tempTransactions:{ [id: number]: Transaction; } = {};
  selectedTransactions: Transaction [];
  get selectedTransactionTotal(): number { return this.selectedTransactions?.reduce((a,b) => a + b.transactionMagnitude,0)};
  get selectedTransactionCount(): number { return this.selectedTransactions?.length};
  get totalTransactions(): number { return this.transactions?.length};

  //Convenience for getting specific sets of transactions
  get selectedOpenTransactions(): Transaction[] { return this.selectedTransactions.filter( t => t.approved == null || !t.approved)};
  get selectedUnpostedTransactions(): Transaction [] { return this.selectedTransactions.filter( t=> t.check == null )}
  get selectedUnInvoicedTransactions(): Transaction [] {return this.selectedTransactions.filter( t=> t.invoiced == null || !t.invoiced)};
  get selectedInvoicedTransactions(): Transaction [] {return this.selectedTransactions.filter( t=> t.invoiced )};


  planMemberMap = [];
  loadingPlanMemberMap = [];

  //Most recent check numbers
  nextCheckNumbers: string[] = [];

  //menu actions for selected rows
  approveAction: MenuItem =
  {
    label: 'Approve',
    icon: 'pi pi-check',
    disabled: true,
    command: () => this.approveSelectedTransactions()
  };

  postAction: MenuItem =
  {
    label: 'Post Checks',
    icon: 'pi pi-money-bill',
    disabled: true,
    command: () => this.postChecksForSelectedTransactions()
  };

  invoiceAction: MenuItem =
  {
    label:"Invoice",
    icon: 'pi pi-envelope',
    disabled: true,
    command: () => this.invoiceSelectedTransactions()
  }

  unInvoiceAction: MenuItem =
  {
    label: 'Un-Invoice',
    icon: 'pi pi-refresh',
    disabled: true,
    command: () => this.uninvoiceSelectedTransactions()
  }

  voidReissueAction: MenuItem =
  {
    label: 'Void/Reissue',
    icon: 'pi pi-times-circle',
    disabled: true,
    command: () => this.voidReissueSelectedTransactions()
  }

  actions: MenuItem[] = [this.approveAction,this.postAction,this.invoiceAction,this.unInvoiceAction, this.voidReissueAction];



  //Certain menu options will be enabled or disabled based on what we have currently selected in our table
  get allowApproval(): boolean { return this.selectedTransactionCount >0 && this.selectedOpenTransactions.length > 0; };
  get allowPostChecks(): boolean { return this.selectedTransactionCount >0 && this.selectedUnpostedTransactions.length > 0; };
  get allowUninvoiceTransactions(): boolean { return this.selectedTransactionCount > 0 && this.selectedInvoicedTransactions.length == this.selectedTransactionCount;};
  get allowInvoiceTransactions(): boolean { return this.selectedTransactionCount > 0 && this.selectedUnInvoicedTransactions.length == this.selectedTransactionCount;};
  get allowVoidReissue(): boolean { return this.selectedTransactionCount > 0 && this.selectedInvoicedTransactions.length == this.selectedTransactionCount;};

  showPostDialog = false;
  showVoidReissueDialog = false;
  loadingPostDialog = false;
  postingChecks = false;
  showProgress = false;

  //progress bar fields
  maxProgress: number = 0;
  currentProgress: number = 0;
  get progressDisplay(): number {return (this.currentProgress/this.maxProgress) * 100};

  //List the bank accounts currently in conention based on what's been selected
  get selectedBankAccounts(): number[] { return [... new Set(this.selectedTransactions.map( t => t.account?.accountId))]};
  checkingAccountsForPosting: CheckingAccount[];

  //Void/Reissue Fields
  accountForReissue: CheckingAccount;
  nextCheckForReissue: string;

  //Post To Open Invoice
  postToOpenInvoice: boolean = true;

  searchFunction = (args: void): Promise<any> =>
  {
      //Clear our selected transaction lis
      this.selectedTransactions = [];
      this.transactions = [];
      //We'll go out to the api and get our transactions, and then after we get 'em, set our transaction list to what we got
      //Returning this promise will cause the UX to wait for us to be done.
      return this.tcems.searchTransactions({approvedDateStart: this.approvedStart, approvedDateEnd: this.approvedEnd, includeInvoiced: this.showInvoiced, includePaidByClient: this.showSelfPaid}).toPromise()
      .then
      (
        result =>
        {
          this.transactions = result;
            var tempTransactions = result;
            this.transactions = [];
            //instantiate the encounter objects in each transaction
            tempTransactions.forEach
            (
              t =>
              {
                var tr:Transaction = Object.assign(new Transaction(), t);
                var e:Encounter = Object.assign(new Encounter(), tr.encounter);
                e.subscriberCompany = Object.assign(new Company(), e.subscriberCompany);
                
                //instantiate the payee
                tr.payee = Object.assign(new Phi(),tr.payee);

                e.paidDateOfConfirmedInstance = e.paidDateOfConfirmedInstance == null ? null : new Date(e.paidDateOfConfirmedInstance);
                tr.encounter = e;
                this.transactions.push(tr);
              });
        }
      )
      .catch
      (
        e =>
        {
          console.log('Transaction Search Failed: ' + e);
          // Most likely that we didn't find any results
        }
      )
      ;
  }

  constructor(private tcems: TcemsService, private store: Store, public tcemsUtilities: TcemsUtilitiesService)
  {

  }

  ngOnInit(): void
  {

  }

  approveSelectedTransactions()
  {
    // TODO: Is there more to "approving a transaction"?
    console.log('approve transaction');
  }

  async uninvoiceSelectedTransactions()
  {
    // We don't have a batch method for this, so we do it one at a time...
    this.currentProgress = 0;
    this.maxProgress= this.selectedInvoicedTransactions.length;
    this.showProgress = true;
    for (const t of this.selectedInvoicedTransactions)
    {

        t.invoiced = false; //change to not invoiced.
        await this.tcems.updateTransaction({transactionId:t.transactionId,transaction:t}).toPromise(); //update!
        this.currentProgress++; //update our progress

    };
    await this.searchFunction();
    this.showProgress = false;
  }

  async invoiceSelectedTransactions()
  {
    //We don't have a batch method for this, so we do it one at a time...
    this.currentProgress = 0;
    this.maxProgress= this.selectedUnInvoicedTransactions.length;
    this.showProgress = true;
    for (const t of this.selectedUnInvoicedTransactions)
    {

        t.invoiced = true; //change to not invoiced.
        await this.tcems.updateTransaction({transactionId:t.transactionId,transaction:t}).toPromise(); //update!
        this.currentProgress++; //update our progress

    };
    await this.searchFunction();
    this.showProgress = false;
  }

  async calcLastChecks(accounts: number[])
  {

    //Get the last check number for each account we're working with
    this.checkingAccountsForPosting = [];

    this.loadingPostDialog = true;
    for (let i = 0 ; i < accounts.length ; i++)
    {
      // console.log(i);
      let a = accounts[i];
      await this.tcems.getCheckingAccount(a).toPromise().then
      (
        r =>
        {
          this.checkingAccountsForPosting.push(r);
          this.nextCheckNumbers[a] = r.nonCheckIssuer ? '' : ((r.lastCheck + 1).toString());
        }
      );
    }
    this.loadingPostDialog = false;
  }

  async voidReissueSelectedTransactions()
  {
    //Get the last check numbers for each account we're working with
    this.showVoidReissueDialog = true;
    this.calcLastChecks(this.tcemsUtilities.checkingAccounts.map(a => a.accountId));
  }

  async postChecksForSelectedTransactions()
  {

    //Make sure that all of our selected transactions have their requirements for sending met 
    let allReqMet = this.selectedTransactions.reduce((a,b) => a && b.contactRequirementsMet, true);
    if(allReqMet)
    {
      // this.selectedBankAccounts.forEach
      this.showPostDialog = true;
      this.calcLastChecks(this.selectedBankAccounts);
    }
    else
    {
      this.tcemsUtilities.displayError("Cannot Post Checks","Please make sure that all transactions have their requirements met in order to send");
    }
  }

  //This is the final post method. It'll actually post the checks and close the dialog
  async postChecks(event: any)
  {
      //Show Please Wait
      this.postingChecks = true;

      //create our posting objects
      let postings: CheckPosting[] = this.checkingAccountsForPosting.map
      (
        a =>
        {
          let cp: CheckPosting = new CheckPosting();
          cp.checkingAccountId = a.accountId;
          cp.firstCheck = a.nonCheckIssuer ? null : this.nextCheckNumbers[a.accountId];
          cp.transactionIds = this.getSelectedTransactionsForAccount(a.accountId).map(t => t.transactionId);
          cp.attachToOpenInvoice = this.postToOpenInvoice;
          return cp;
        }
      );

      // Call API
      // console.log(postings);

      await this.tcems.postChecks({postings: postings}).toPromise().then
      (
        async r =>
        {
          //When we're done with our checks posting, then we need to reload transactions.
          //we can just call our search function again
          await this.searchFunction();
        }
      );

      //Close Dialog
      this.postingChecks = false;
      this.showPostDialog = false;
  }

  async voidAndReissue(event: any)
  {
    //Show wait dialog
    this.postingChecks = true;

    let reissuePromises: Promise<Check>[] = [];

    //For each selected check, void/reissue
    let nextCheck = this.nextCheckForReissue;
    this.selectedTransactions.forEach
    (
      async t =>
      {
        reissuePromises.push(this.tcems.voidAndReissueCheck(t.check,this.accountForReissue,nextCheck).toPromise());
        nextCheck = nextCheck + 1;
      }
    )

    await Promise.all(reissuePromises).then
    (
      async c =>
        await this.searchFunction()
    )

    this.postingChecks = false;
    this.showVoidReissueDialog = false;
    this.accountForReissue = null;
    this.nextCheckForReissue = null;
  }


  getAvailableCheckingAccounts(transaction: Transaction): CheckingAccount[]
  {
    return [...transaction.encounter.subscriberCompany.availableAccounts
            ,... this.tcemsUtilities.checkingAccounts.filter(c=> c.global)];
  }

  isOnValidAccount(transaction: Transaction): boolean
  {
    return this.getAvailableCheckingAccounts(transaction).map(a => a.accountId).includes(transaction.account.accountId);
  }

  getSelectedTransactionsForAccount(accountId: number) : Transaction []
  {
    return this.selectedUnpostedTransactions.filter(t => t.account?.accountId == accountId);
  }

  getSelectedTransactionCountForAccount(accountId: number): number
  {
    return this.getSelectedTransactionsForAccount(accountId).length;
  }

  getSelectedTransactionAmountForAccount(accountId: number):number
  {
    return this.getSelectedTransactionsForAccount(accountId).reduce((a,b) => a + b.transactionMagnitude, 0)
  }

  updateContextMenu(event: any)
  {
    this.approveAction.disabled = !this.allowApproval;
    this.postAction.disabled = !this.allowPostChecks;
    this.unInvoiceAction.disabled = !this.allowUninvoiceTransactions;
    this.voidReissueAction.disabled = !this.allowVoidReissue;
    this.invoiceAction.disabled = !this.allowInvoiceTransactions;
  }

  toggleNewTransaction(event)
  {
    this.showNewTransactionDialog = !this.showNewTransactionDialog;
  }

  newTransaction(event: Transaction)
  {
    //close the dialog
    this.toggleNewTransaction(null);

    //Set the search criteria to the approved date of this transaction
    this.approvedStart = event.approvedDate;
    this.searchFunction();
  }



  onRowEditInit(trn: Transaction) {
    this.tempTransactions[trn.transactionId] = Object.assign(new Transaction(), trn);

    //Load the plan members
    this.loadingPlanMemberMap[trn.transactionId] = true;
    // let plans$ = this.tcems.getPlan(trn.payee.memberId)
    let plans$ = this.tcems.getPlan(trn.encounter.patientPlan.memberId)
    .pipe
    (
      map( (p: Plan) =>  forkJoin(p.planMembers.map(pm => this.tcems.getPhi(pm.phi.phiId)))),
      mergeAll()
    );

    plans$    
    .toPromise()
    .then
    (
      p => 
        {
          this.planMemberMap[trn.transactionId] = p
                      .map
                      (
                        p =>  Object.assign(new Phi(), p)
                      );
          this.loadingPlanMemberMap[trn.transactionId] = false;
        }
    )
  }


  onRowEditSave(trn: Transaction, index: number) 
  {

    //Only allow save if the requirements are met for this account to be used
    if(!trn.contactRequirementsMet)
    {
      this.tcemsUtilities.displayError("Invalid Selection","You must pick an account for which the payee has contact information requirments met. (i.e., has a valid email addresss");
      return false; 
    }

    else
    {
      //UPdate the transaction
      
      this.tcems.updateTransaction( {transactionId:  trn.transactionId, transaction: trn}).toPromise()
      .then
      (
        result => 
        {
          //Delete our cloned version and we're good
          delete this.tempTransactions[trn.transactionId];
        }
      )
    }


    // //See if this is a new row, or an update row
    // if(this.newRows.includes(index))
    // {
    //   //commit by inserting
    //   //Let's try to commit our records
    //   this.tcems.createAppointment({encounterId: this.encounter.encounterId, appt:appt.asUpdate()}).toPromise()
    //   .then
    //   (
    //     result =>
    //     {
    //       //If we're successful, delete the new index entry
    //       this.newRows = this.newRows.filter(r => r != index);
    //     }
    //   )
    // }
    // else
    // {
    //   //Let's try to commit our records
    //   this.tcems.editAppointment({encounterId: this.encounter.encounterId,appointmentId: appt.id, appt:appt.asUpdate()}).toPromise()
    //   .then
    //   (
    //     result =>
    //     {
    //       //If we're successful, delete the cloned, emp appointment
    //       delete this.tempAppointments[appt.id];
    //     }
    //   )
    // }
    
      
  }

  onRowEditCancel(trn: Transaction, index: number) 
  {
    //For new rows, we delete the row
    // if(this.newRows.includes(index))
    // {
    //   //Delete that new row
    //   delete this.appointments[index];
      
    //   //Clear out the new row index entry
    //   this.newRows = this.newRows.filter(r => r != index);
    // }
    // else
    // {
      //For update rows, we just replace everything back to the way it was
      this.transactions[index] = this.tempTransactions[trn.transactionId];
      delete this.tempTransactions[trn.transactionId];
    // }
  }
}
