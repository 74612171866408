import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Store } from '@ngrx/store';
import { TreeNode } from 'primeng/api';
import { TcemsService } from 'src/app/api/tcems.service';
import { Category } from 'src/app/model/claim/category';
import { Procedure } from 'src/app/model/claim/procedure';
import { ProcedureHierarchy } from 'src/app/model/claim/procedure_hierarchy';
import { selectCategories } from 'src/app/state/app.state';
import { TcemsUtilitiesService } from 'src/app/util/tcems-utilities.service';

@Component({
  selector: 'app-procedure-selector',
  templateUrl: './procedure-selector.component.html',
  styleUrls: ['./procedure-selector.component.css']
})
export class ProcedureSelectorComponent implements OnInit {

  procTree: ProcedureHierarchy;
  filteredProcTree: TreeNode<ProcedureHierarchy>[];
  // _selectedNodes: TreeNode<ProcedureHierarchy>[] = [];
  set selectedNodes(val: TreeNode<ProcedureHierarchy>[])
  {
    let selectedProcs: Procedure[] = val.filter(n => n.children == null || n.children.length == 0).map(n => n.data.procedure);

    //Don't allow an overload of these... max is 20 codes
    if (selectedProcs.length > 30)
    {
      this.util.displayError("Too Many Procedures","The maximum number of allowed procedures is 30");
      return;
    }

    //this._selectedNodes = val;
    //console.log(val);
    // console.log(this._procedures);
    this._nodes = val;
    this._procedures = selectedProcs;

  }

  get selectedNodes():TreeNode<ProcedureHierarchy>[]
  {
    //let codes: string[] = this._procedures?.map(p => p.code)
    //return ProcedureHierarchy.filterProcedureTreeNodes(this.filteredProcTree, codes);
    return this._nodes;
    //Find the nodes in our trees that match these codes
    //this.selectedNodesHCPCS = ProcedureHierarchy.filterProcedureTreeNodes(this.filteredProcTreeHCPCS, codes);

    //return //this._selectedNodes;
  }
  categoryOptions: Category[]; //{label: string, value: string} [];
  selectedCategory: Category; //{label: string, value: string};

  procTreeHCPCS: ProcedureHierarchy;
  filteredProcTreeHCPCS: TreeNode[];
  //selectedNodesHCPCS: TreeNode<ProcedureHierarchy>[] = [];
  set selectedNodesHCPCS(val: TreeNode<ProcedureHierarchy>[])
  {
    
    let selectedProcs: Procedure[] = val.filter(n => n.children == null || n.children.length == 0).map(n => n.data.procedure);

    //Don't allow an overload of these... max is 20 codes
    if (selectedProcs.length > 20)
    {
      this.util.displayError("Too Many Procedures","The maximum number of allowed procedures is 20");
      return;
    }

    //this._selectedNodes = val;
    //console.log(val);
    // console.log(this._procedures);
    this._nodes = val;
    this._procedures = selectedProcs;

  } 

  get selectedNodesHCPCS():TreeNode<ProcedureHierarchy>[]
  {
    //let codes: string[] = this._procedures?.map(p => p.code)
    //return ProcedureHierarchy.filterProcedureTreeNodes(this.filteredProcTree, codes);  
    return this._nodes;
    //Find the nodes in our trees that match these codes
    //this.selectedNodesHCPCS = ProcedureHierarchy.filterProcedureTreeNodes(this.filteredProcTreeHCPCS, codes);

    //return //this._selectedNodes;
  }


  categories$ = this.store.select(selectCategories);
  categories: Category[];

  procTreeLoaded: boolean = false;
  procTreeHCPCSLoaded: boolean = false;

  @Input()
    set category(val: Category)
    {
      //We need to find the category from the list of default categories
      //this.selectedCategory =  this.categories?.find(c => c.categoryId == val.categoryId);
      if(  (val?.categoryId == null && this.selectedCategory != null) || (val!=null && this.selectedCategory == null) || (val?.categoryId != this.selectedCategory?.categoryId))
        this.selectedCategory = val;
    }
  @Input() allowCategorySelection: boolean = true;

  _procedures: Procedure[];
  _nodes: TreeNode<ProcedureHierarchy>[];

  @Input()
    set procedures (val: Procedure[])
    {
      let sync = false;

      if((val == null && this._procedures !=null) || (val != null && this._procedures == null) || (val?.length != this._procedures?.length))
      {
        this._procedures = val;
        let codes: string[] = this._procedures?.map(p => p.code)

        //Find the nodes in our trees that match these codes
        
        this._nodes =  [... ProcedureHierarchy.filterProcedureTreeNodes(this.filteredProcTree, codes),... ProcedureHierarchy.filterProcedureTreeNodes(this.filteredProcTreeHCPCS, codes)];  
        //if(this.procTree && this.procTreeHCPCSLoaded) this.selectNodes();
      }
    }

    get procedures() : Procedure[]
    {
      return this._procedures;
    }


  @Output() categoryChange: EventEmitter<Category> = new EventEmitter<Category>();
  @Output() proceduresChange: EventEmitter<Procedure[]> = new EventEmitter<Procedure[]>();



  constructor(private tcems: TcemsService, private store: Store, private util:TcemsUtilitiesService) { }

  ngOnInit(): void
  {
    this.categories$.subscribe
    (
      c =>
      {
        this.categories = [...c];
        this.categories.sort((a,b) => a.categoryName > b.categoryName ? 1 : -1);
        this.selectedCategory =  this.categories?.find(c => c.categoryId == this.selectedCategory?.categoryId);


      }
    );
    this.categoryOptions = this.categories; //this.categories?.map(c => ({label: c.categoryName, value: c.categoryId.toString()}));


    this.util.procHierarchy$.subscribe
    (
      result =>
      {
        this.procTree = Object.assign(new ProcedureHierarchy(),result.cpt);
        this.procTreeHCPCS = Object.assign(new ProcedureHierarchy(),result.hcpcs);
        this.filterTreeForCategory();
        this.procTreeLoaded = true;
        this.procTreeHCPCSLoaded = true;

        //Filter out nodes
        let codes: string[] = this._procedures?.map(p => p.code)
        this._nodes =  [... ProcedureHierarchy.filterProcedureTreeNodes(this.filteredProcTree, codes),... ProcedureHierarchy.filterProcedureTreeNodes(this.filteredProcTreeHCPCS, codes)];  

      }
    )

    // this.tcems.getCodes("CPT").toPromise()
    // .then( result =>
    //     {
    //       //Object.assign(this.procHierarchy,result);
    //       this.procTree = Object.assign(new ProcedureHierarchy(),result);
    //       //this.categorySelected(null);
    //       //this.selectNodes();
    //       this.filterTreeForCategory();
    //       this.procTreeLoaded = true;
    //     });

    // this.tcems.getCodes("HCPCS").toPromise()
    //     .then( result =>
    //         {
    //           //Object.assign(this.procHierarchy,result);
    //           this.procTreeHCPCS = Object.assign(new ProcedureHierarchy(),result);
    //           //this.categorySelected(null);
    //           //this.selectNodes();
    //           this.filterTreeForCategory();
    //           this.procTreeHCPCSLoaded = true;
    //         });
  }

  categorySelected(event)
  {
    //No matter what happens, we need to clear our cpt selections
    this.selectedNodes = [];
    this.selectedNodesHCPCS = [];

    // console.log("Category Selected");
    if (this.selectedCategory == null)
    {
      this.filteredProcTree = [];
      this.filteredProcTreeHCPCS = [];
    }
    else
    {
      this.filterTreeForCategory();
      //.asTreeNode(true).children
      //console.log(this.filteredProcTree);
    }

    this.categoryChange.emit(this.selectedCategory);
    //this.proceduresChange.emit([]);

  }

  filterTreeForCategory()
  {

    //Determine if we want to show all the 'Other' codes
    //In this case, for now, we just show everything...
    if(this.selectedCategory?.categoryId == -1)
    {
      this.filteredProcTree = this.procTree?.asTreeNode(true).children;
      this.filteredProcTreeHCPCS = this.procTreeHCPCS?.asTreeNode(true).children;
    }
    else if(this.selectedCategory != null)
    {

      let filter = this.selectedCategory.procedures.map(p => p.procedureId);

      if(this.procTree != null)
      {
        this.filteredProcTree = this.procTree.filter(filter)?.asTreeNode(true).children; //(tn => this.selectedCategory.procedures.map(p => p.procedureId.toString()).includes(tn.key));
      }

      if(this.procTreeHCPCS != null)
      {
        this.filteredProcTreeHCPCS = this.procTreeHCPCS.filter(filter)?.asTreeNode(true).children; //(tn => this.selectedCategory.procedures.map(p => p.procedureId.toString()).includes(tn.key));
      }
    }
  }

  // selectNodes()
  // {
  //   let codes: string[] = this.selectedProcedures.map(p => p.code)
  //   //Find the nodes in our trees that match these codes
  //   this.selectedNodes = ProcedureHierarchy.filterProcedureTreeNodes(this.filteredProcTree, codes);
  //   this.selectedNodesHCPCS = ProcedureHierarchy.filterProcedureTreeNodes(this.filteredProcTreeHCPCS, codes);
  // }

  codesSelected(event)
  {
    //let procCodes: string[] = Array.prototype.concat(this.selectedNodes.map(n => n.data.procedureCode), this.selectedNodesHCPCS.map(n => n.data.procedureCode));
    //let selectedProcs = [... this.selectedNodes, ... this.selectedNodesHCPCS].map(n => n.data.procedure);   //.reduce((p,c) => [...p, ...ProcedureHierarchy.generateFromTreeNode(c)],[]);
    this.proceduresChange.emit(this._procedures);
  }

  treeTrackingFunction(index, item)
  {
    return item.key;
  }

}
