import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Store } from '@ngrx/store';
import { TcemsService } from '../../../api/tcems.service';
import { Phi } from '../../../model/phi/phi';
import { selectContactTypes } from '../../../state/app.state';
import { ContactType } from '../../../model/util/contact_type';
import { EmailAddress, EmailAddressUpdate } from '../../../model/phi/email-address';
import { PhoneNumber, PhoneNumberUpdate } from '../../../model/phi/phone-number';

@Component({
  selector: 'app-email-editor',
  templateUrl: './email-editor.component.html',
  styleUrls: ['./email-editor.component.scss']
})
export class EmailEditorComponent implements OnInit {

  private _emailAddresses: EmailAddress[];
  private _origEmailAddresses: EmailAddress[];

  constructor(private store: Store, private tcems: TcemsService) { }

  @Input() phiId: number;
  @Input() showEmailEditor: boolean;
  @Input() editablePhi = false;
  @Input() showMarketing = false;
  @Input()
  set emailAddresses(val: EmailAddress[])
  {
    this._emailAddresses = val.map(v => Object.assign(new EmailAddress(), v));
    this._origEmailAddresses = val.map(v => Object.assign(new EmailAddress(), v));
  }
  get emailAddresses(): EmailAddress[]
  {
    return this._emailAddresses;
  }
  @Output() emailAddressesChange: EventEmitter<EmailAddress[]> = new EventEmitter<EmailAddress[]>();
  @Output() showEmailEditorChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  contactTypes$ = this.store.select(selectContactTypes);
  ContactTypes: ContactType[];

  ngOnInit(): void {
    this.contactTypes$.subscribe(t => {this.ContactTypes = t; });
  }

  updateEmailAddress(emailAddressToUpdate: EmailAddress): void {
    let emailAddressUpdate = new EmailAddressUpdate();
    emailAddressUpdate.emailAddress = emailAddressToUpdate.emailAddress;
    emailAddressUpdate.emailType = emailAddressToUpdate.emailType;
    emailAddressUpdate.active = emailAddressToUpdate.active;
    emailAddressUpdate.emailId = emailAddressToUpdate.emailId;

    this.tcems.updateEmailAddress
    (
      {
        phiId: this.phiId,
        emailId: emailAddressToUpdate.emailId,
        emailAddressUpdate
      }
    ).toPromise()
        .then
        (
          result =>
          {
            emailAddressUpdate = result;
            this.refreshEmails();
          }
        )
        .catch
        (
          e =>
          {
            console.log('Update Email Address Failed: ' + e);
          }
        );
  }

  createEmailAddress(emailAddressToAdd: EmailAddress): void {
    let emailAddressUpdate = new EmailAddressUpdate();
    emailAddressUpdate.emailAddress = emailAddressToAdd.emailAddress;
    emailAddressUpdate.emailType = emailAddressToAdd.emailType;
    emailAddressUpdate.active = emailAddressToAdd.active;
    emailAddressUpdate.emailId = emailAddressToAdd.emailId;

    this.tcems.createEmailAddress
    (
      {
        phiId: this.phiId,
        emailAddressUpdate
      }
    ).toPromise()
        .then
        (
          result =>
          {
            emailAddressUpdate = result;
            this.refreshEmails();
          }
        )
        .catch
        (
          e =>
          {
            console.log('Create Email Address Failed: ' + e);
          }
        );
  }

  refreshEmails(): void
  {
    this.tcems.getPhi(this.phiId).toPromise()
    .then
    (
      result =>
        this.emailAddresses = result.emailAddresses
    )
  }


  addEmail(): void
  {
    const newEmailAddress = new EmailAddress();
    newEmailAddress.emailType.code = this.ContactTypes[0].code;
    newEmailAddress.emailType.description = this.ContactTypes[0].description;
    this._emailAddresses.push(newEmailAddress);
  }

  updateButton(event): void
  {
    // Compare original list with current list for changes
    this._emailAddresses.forEach((emAddr, idx) => {
      if (idx < (this._origEmailAddresses.length)) {
        if (JSON.stringify(emAddr) !== JSON.stringify(this._origEmailAddresses[idx])) {
          this.updateEmailAddress(emAddr);
        }
      } else {
        this.createEmailAddress(emAddr);
      }
    });

    this.showEmailEditor = false;

    this.emailAddressesChange.emit(this._emailAddresses);
    this.showEmailEditorChange.emit(this.showEmailEditor);
  }

  cancelButton(event): void
  {
    this.showEmailEditor = false;
    this.showEmailEditorChange.emit(this.showEmailEditor);
  }

}
