import { Component } from '@angular/core';
import { TcemsJobApi } from 'src/app/api/tcems.job.service';
import { Job } from 'src/app/model/jobs/job';

@Component({
  selector: 'app-job-monitor',
  templateUrl: './job-monitor.component.html',
  styleUrls: ['./job-monitor.component.scss']
})
export class JobMonitorComponent 
{

  
  constructor(private jobApi:TcemsJobApi)
  {

  }

  jobs: Job<any>[] = [];
  jobsFrom: Date;
  jobsTo: Date;
  showWait: boolean = false;


  ngOnInit(): void 
  {
    this.jobsFrom = new Date();
    this.jobsFrom.setDate(this.jobsFrom.getDate() - 10);
    this.jobsTo = new Date();
    this.jobsTo.setDate(this.jobsTo.getDate() + 1);

    this.searchJobs();
  }

  searchJobs():void
  {
    this.showWait = true;
    this.jobApi.GetJobsStartedBetween(this.jobsFrom,this.jobsTo).subscribe
    (
      v => 
      {
        this.showWait = false;
        this.jobs = v;
      }
    )
  }

}
