import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient, HttpParams } from '@angular/common/http';
import { Observable, catchError, of, throwError } from 'rxjs';
import { AuthResponse } from 'msal';
import { MsalService } from '@azure/msal-angular';
import { TcemsService } from './tcems.service';
import { APIException } from './api-exception';
import { AccountInfo, AuthenticationResult } from '@azure/msal-browser';

@Injectable({
  providedIn: 'root'
})
export class ApiService {



  //We'll use an injected Login Service for all of our token acquisition and whatnot
  constructor(private authService: MsalService, private client: HttpClient ) 
  { 

  }

  //This method will wrap the HttpClient calls in a way that allow for us to get model objects back from api endpoitns
  //without having to write the same code over and over again everywhere. Especially since we're doing pretty much the same
  //thing over and over again.

  //This call will:
  //Issue a Bearer Token
  //Expect a json response in the body
  //Deserialize the json into a model interface (<T>)
  
  //uriBase - The base uri for the api
  //apiRoute - the route to the specific api method
  //params - any url parameters for the get verb - optional
  //headers - any headers that we want to supply - optional

  //We return a promise of whatever type we need 
  makeAPIGetCall<T> 
  (
      uriBase: string, 
      apiRoute: string,  
      params?: {[param: string]: string | string[]},
      headers?: {[param: string]: string | string []}
  ) : Promise<T>
  {
    

    //Start with empty headers, or whatever was passed in
      var h = headers == null ? new HttpHeaders() : new HttpHeaders(headers);
      var p = params == null ? new HttpParams() : params;


      //Get an observable of the specified type
      return this.client.get<T>(uriBase + apiRoute,
      {
        headers: h,
        observe: 'body',
        responseType: 'json',
        params: p
      }).toPromise()
    .catch((error) => {
      if (error.status == "404") throw new APIException("No Results Found");
      else if (error.status == "401") throw new APIException("Not Authorized");
      else if (error.status == "500") throw new APIException("Server Error");
      else throw error;
    })


  }

  makeAPIPutCall<T> 
  (
      uriBase: string, 
      apiRoute: string,  
      bodyObject: any,
      params?: {[param: string]: string | string[]},
      headers?: {[param: string]: string | string []}
  ) : Promise<T>
  {
    
      //Start with empty headers, or whatever was passed in
      var h = headers == null ? new HttpHeaders() : new HttpHeaders(headers);
      var p = params == null ? new HttpParams() : params;

      //Get an observable of the specified type
      return this.client.put<T>(uriBase + apiRoute, bodyObject,
      {
        headers: h,
        observe: 'body',
        responseType: 'json',
        params: p
      }).toPromise()
    .catch((error) => {
      if (error.status == "404") throw new APIException("No Results Found");
      else if (error.status == "401") throw new APIException("Not Authorized");
      else if (error.status == "500") throw new APIException("Server Error");
      else throw error;
    })

  }

  makeAPIPostCall<T> 
  (
      uriBase: string, 
      apiRoute: string,  
      bodyObject: any,
      params?: {[param: string]: string | string[]},
      headers?: {[param: string]: string | string []}
  ) : Observable<T>
  {
    
      //Start with empty headers, or whatever was passed in
      var h = headers == null ? new HttpHeaders() : new HttpHeaders(headers);
      var p = params == null ? new HttpParams() : params;

      //Get an observable of the specified type
      return this.client.post<T>(uriBase + apiRoute, bodyObject,
      {
        headers: h,
        observe: 'body',
        responseType: 'json',
        params: p
      }).pipe
      (
        catchError(error =>
        {
          if (error.status == "404") return throwError(() => new APIException("No Results Found"));
          else if (error.status == "401") return throwError(() => new APIException("Not Authorized"));
          else if (error.status == "500") return throwError(() =>  new APIException("Server Error"));
          else return throwError(() => error);
        })
      )

  //return null;

  }

  makeAPIPatchCall<T> 
  (
      uriBase: string, 
      apiRoute: string,  
      bodyObject: any,
      params?: {[param: string]: string | string[]},
      headers?: {[param: string]: string | string []}
  ) : Promise<T>
  {
    

    //We have to wait for our token here...
  
      //Start with empty headers, or whatever was passed in
      var h = headers == null ? new HttpHeaders() : new HttpHeaders(headers);
      var p = params == null ? new HttpParams() : params;
      //Add our bearer token for auth (assuming we got one)

      //Get an observable of the specified type
      return this.client.patch<T>(uriBase + apiRoute, bodyObject,
      {
        headers: h,
        observe: 'body',
        responseType: 'json',
        params: p
      }).toPromise()
    .catch((error) => {
      if (error.status == "404") throw new APIException("No Results Found");
      else if (error.status == "401") throw new APIException("Not Authorized");
      else if (error.status == "500") throw new APIException("Server Error");
      else throw error;
    })


  }

  makeAPIDeleteCall<T> 
  (
      uriBase: string, 
      apiRoute: string,  
      params?: {[param: string]: string | string[]},
      headers?: {[param: string]: string | string []}
  ) : Promise<T>
  {
    

    //Start with empty headers, or whatever was passed in
      var h = headers == null ? new HttpHeaders() : new HttpHeaders(headers);
      var p = params == null ? new HttpParams() : params;


      //Get an observable of the specified type
      return this.client.delete<T>(uriBase + apiRoute,
      {
        headers: h,
        observe: 'body',
        responseType: 'json',
        params: p
      }).toPromise()
    .catch((error) => {
      if (error.status == "404") throw new APIException("No Results Found");
      else if (error.status == "401") throw new APIException("Not Authorized");
      else if (error.status == "500") throw new APIException("Server Error");
      else throw error;
    })


  }

  downloadFileFromGet
  (
    uriBase: string, 
    apiRoute: string,  
    params?: {[param: string]: string | string[]},
    headers?: {[param: string]: string | string []}    
  ): Observable<ArrayBuffer>
  {
      const options = { responseType: 'blob' };

      var h = headers == null ? new HttpHeaders() : new HttpHeaders(headers);
      var p = params == null ? new HttpParams() : params;

      return this.client.get<ArrayBuffer>(uriBase + apiRoute,
        {
          responseType:'arraybuffer' as 'json', 
          headers: h,
          params: p,
        });
  }

  downloadFileFromPost
  (
    uriBase: string, 
    apiRoute: string,  
    bodyObject: any,
    params?: {[param: string]: string | string[]},
    headers?: {[param: string]: string | string []}    
  ): Observable<ArrayBuffer>
  {

  //   //Start with empty headers, or whatever was passed in
  //   var h = headers == null ? new HttpHeaders() : new HttpHeaders(headers);
  //   var p = params == null ? new HttpParams() : params;

  //   //Get an observable of the specified type
  //   return this.client.post<T>(uriBase + apiRoute, bodyObject,
  //   {
  //     headers: h,
  //     observe: 'body',
  //     responseType: 'json',
  //     params: p
  //   }).toPromise()
  // .catch((error) => {
  //   if (error.status == "404") throw new APIException("No Results Found");
  //   else if (error.status == "401") throw new APIException("Not Authorized");
  //   else if (error.status == "500") throw new APIException("Server Error");
  //   else throw error;
  // })



      var h = headers == null ? new HttpHeaders() : new HttpHeaders(headers);
      var p = params == null ? new HttpParams() : params;

      return this.client.post<ArrayBuffer>(uriBase + apiRoute, bodyObject,
        {
          responseType:'arraybuffer' as 'json', 
          headers: h,
          params: p,
        })
  }


}
