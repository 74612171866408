export class SearchResult<T>
{
    line1:string;
    line2:string;
    line3:string;
    payload:T;
    id: any;
    constructor(options:
        {
            id?: any;
            payload?: T;
            line1?:string;
            line2?:string;
            line3?:string;
        } = {})
        {
            this.id = options.id;
            this.payload = options.payload;
            this.line1 = options.line1;
            this.line2 = options.line2;
            this.line3 = options.line3;
        }    
}