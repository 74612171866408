<div class="formgroup-inline pt-4 pl-1">                
    
    <div class="field">
        <span class="p-float-label">
            <input id="firstName" type="text" pInputText [(ngModel)]="selectedPhi.firstName"/>
            <label for="firstName">First Name</label> 
        </span>
    </div>
    <div class="field">
        <span class="p-float-label">
            <input id="lastName" type="text" pInputText [(ngModel)]="selectedPhi.lastName"/>
            <label for="lastName">Last Name</label> 
        </span>
    </div>
    <div class="field">
        <span class="p-float-label">
            <p-calendar id="dob" [(ngModel)]="selectedPhi.dob"></p-calendar>
            <label for="dob">Date of Birth</label> 
        </span>
    </div>
</div>

<div class="mb-2">
    <p-fieldset legend="Email/Phone" [toggleable]="true" styleClass="dark-panel">
        <!-- Email Addresses and Phone Numbers-->
        <div class="grid">

            <!--Emails-->

            <div class="col-6">
                <p-panel header="Email Addresses">
                    <div class="flex flex-column">
                        <p-card *ngFor="let email of selectedPhi.emailAddresses" class="mb-2">
                            <div class="w12 formgrid grid">
                                <div class="field col-6 mt-3">
                                    <span class="p-float-label">
                                        <input id="emailAddress" type="text" pInputText [(ngModel)]="email.emailAddress"/>
                                        <label for="emailAddress">{{email.emailType}} Email</label>                       
                                    </span>
                                </div>
                                <div class="field col-6 mt-3">
                                    <p-dropdown [options]="emailTypes" [(ngModel)]="email.emailType" placeholder="Email Type" [showClear]="false"></p-dropdown>
                                </div>    
                                <div class="field col-6">
                                    <p-checkbox label="Active" [binary]="true" [(ngModel)]="email.active"></p-checkbox>
                                </div>                
                                <div class="field col-6">
                                    <p-checkbox label="Confirmed" [binary]="true" [(ngModel)]="email.confirmed"></p-checkbox>
                                </div>       
                            </div>
                        </p-card>
                        <div class="flex justify-content-center">
                            <button pButton pRipple type="button" icon="pi pi-plus" class="p-button-rounded" (click)="addEmail()"></button>
                        </div>
                    </div>
                </p-panel>
            </div>

            <!--Phone Numbers-->
            <div class="col-6">
                <p-panel header="Phone Numbers">
                    <div class="flex flex-column">
                        <p-card *ngFor="let phone of selectedPhi.phoneNumbers" class="mb-2">
                            <div class="w12 formgrid grid">
                                <div class="field col-6 mt-3">
                                    <span class="p-float-label">
                                        <input id="phoneNumber" type="text" pInputText [(ngModel)]="phone.phoneNumber"/>
                                        <label for="phoneNumber">{{phone.phoneType}} Phone Number</label>                       
                                    </span>
                                </div>
                                <div class="field col-6 mt-3">
                                    <p-dropdown [options]="phoneTypes" [(ngModel)]="phone.phoneType" placeholder="Phone Type" [showClear]="false"></p-dropdown>
                                </div>    
                                <div class="field col-6">
                                    <p-checkbox label="Active" [binary]="true" [(ngModel)]="phone.active"></p-checkbox>
                                </div>                  
                            </div>
                        </p-card>
                        <div class="flex justify-content-center">
                            <button pButton pRipple type="button" icon="pi pi-plus" class="p-button-rounded" (click)="addPhone()"></button>
                        </div>
                    </div>
                </p-panel>
            </div>

        </div>
    </p-fieldset>
</div>
<div class="mb-2">
    <p-fieldset legend="Address Info" [toggleable]="true" styleClass="dark-panel">
    <!-- Addresses -->
        <div class="grid">
            <p-card *ngFor="let address of selectedPhi.addresses" class="col-4 mr-2" [header]="address.addressType.description">
                <div  class="w12 formgrid grid">
                    <div class="field col-12 mt-3">
                        <span class="p-float-label">
                            <input id="address1" type="text" pInputText [(ngModel)]="address.address1"/>
                            <label for="address1">Address 1</label> 
                        </span>
                    </div>
                    <div class="field col-12 mt-3">
                        <span class="p-float-label">
                            <input id="address2" type="text" pInputText [(ngModel)]="address.address2"/>
                            <label for="address2">Address 2</label> 
                        </span>
                    </div>
                    <div class="field col-6 mt-3">
                        <span class="p-float-label">
                            <input id="city" type="text" pInputText [(ngModel)]="address.city"/>
                            <label for="city">City</label> 
                        </span>
                    </div>
                    <div class="field col-3 mt-3">
                        <span class="p-float-label">
                            <input id="state" type="text" pInputText [(ngModel)]="address.state"/>
                            <label for="state">State</label> 
                        </span>
                    </div>
                    <div class="field col-3 mt-3">
                        <span class="p-float-label">
                            <input id="zipCode" type="text" pInputText [(ngModel)]="address.zipCode"/>
                            <label for="zipCode">Zip Code</label> 
                        </span>
                    </div>

                    <div class="field col-9">
                    <p-dropdown [options]="addressTypes" [(ngModel)]="address.addressType" placeholder="Address Type" [showClear]="false"></p-dropdown>
                    </div>

                    <div class="col-3 field-checkbox">
                        <p-checkbox label="Active" [binary]="true" [(ngModel)]="address.active"></p-checkbox>
                    </div>     

                </div>  
                
                <p-footer>
                    <p-button label="Save" icon="pi pi-save"></p-button>
                    <p-button label="Cancel" icon="pi pi-times" styleClass="p-button-secondary" [style]="{'margin-left':'.5em'}"></p-button>
                </p-footer>
            </p-card> 
            <div class="p-col1 align-self-center">
                <button pButton pRipple type="button" icon="pi pi-plus" class="p-button-rounded" (click)="addAddress()"></button>
            </div>
        </div>
    </p-fieldset>
</div>

<div>
    <p-fieldset legend="Plans" [toggleable]="true" styleClass="dark-panel">

        <p-table [value]="selectedPhi.plans">
            <ng-template pTemplate="header">
                <tr>
                    <th pSortableColumn="plan.memberId">Member Id</th>
                    <th pSortableColumn="plan.effectiveDate">Effective Date</th>
                    <th pSortableColumn="plan.active">Active</th>
                    <th>Subscriber</th>
                    <th>Dependent Of</th>
                    <th>Network Name</th>
                    <th>HSA Eligible</th>
                    <th>Plan Type</th>
                    <th>Benefit Details</th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-plan>

                <tr>
                    <td>{{plan.memberId}}</td> 
                    <td>{{plan.effectiveDate | date:'shortDate'}}</td>
                    <td>
                        <p-checkbox [binary]="true" [(ngModel)]="plan.active"></p-checkbox>
                    </td>
                    <td>
                        <p-checkbox [binary]="true" [(ngModel)]="plan.isSubscriber"></p-checkbox>
                    </td>
                    <td><!--Subscriber Link--></td>
                    <td><!--Network Name--></td>
                    <td>
                        <p-checkbox [binary]="true" [(ngModel)]="plan.hsaEligible"></p-checkbox>
                    </td>
                    <td>
                        <!--Plan Type-->
                    </td>
                    <td>
                        <button pButton pRipple type="button" icon="pi pi-info" class="p-button-rounded" (click)="planDetailsPlan=plan; op.toggle($event)"></button>
                    </td>
                </tr>
            </ng-template>
        </p-table>

        <p-overlayPanel #op>
            <ng-template pTemplate>
                <p-table [value]="planDetailCols" [autoLayout]="true">
                    <ng-template pTemplate="header">
                        <tr>
                            <th>Benefit Type</th>
                            <th>Details</th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-detail>
                        <tr>
                            <td>{{detail.display}}</td>
                            <td>{{planDetailsPlan[detail.field]}}</td>
                        </tr>

                    </ng-template>
                </p-table>
            </ng-template>
        </p-overlayPanel>
        
    </p-fieldset>
</div>
