import { Category } from "../claim/category";
import { Company } from "../support/company";
import { CheckingAccount } from "./checking_account";

export class RewardRule
{
    ruleId: number;
    ruleName: string;
    fixedAmount: number;
    percentSavings: number;
    allowManualOverride: boolean;
    minSavings: number;
    maxSavings: number;
    baseReward: number;
    paidThroughAccount: CheckingAccount;
    descriptionText: string;
    paidByClient: boolean;
    company: Company;
    active: boolean;
    category: Category;

    get percentSavingsAsWholeNumber():number
    {
        return (this.percentSavings ?? 0) * 100;
    }

    set percentSavingsAsWholeNumber( val: number)
    {
        if(val == null)
        {
            this.percentSavings == null;
        }
        else
        {
            this.percentSavings = val/100;
        }
    }

    asUpdate() : RewardRuleUpdate
    {
        let rru = Object.assign(new RewardRuleUpdate(), this);
        rru.paidThroughAccountId = this.paidThroughAccount.accountId;
        rru.categoryId = this.category?.categoryId;
        return rru;
    }

    asInsert() : RewardRuleInsert
    {
        let rri = Object.assign(new RewardRuleInsert(), this.asUpdate());
        rri.companyId = this.company.companyId;
        return rri;
    }
}

export class RewardRuleUpdate
{
    ruleName: string;
    fixedAmount: number;
    percentSavings: number;
    allowManualOverride: boolean;
    minSavings: number;
    maxSavings: number;
    baseReward: number;
    paidThroughAccountId: number;
    descriptionText: string;
    paidByClient: boolean;
    active: boolean;
    categoryId: number;
}


export class RewardRuleInsert extends RewardRuleUpdate
{
    companyId: number;
}