//import { Component, OnInit, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { LoginService, Login } from './login.service';
import { AbstractLoginStatusListener } from './login.status.listener';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})

//export class LoginComponent implements OnInit, AfterViewInit {
export class LoginComponent extends AbstractLoginStatusListener implements OnInit
{

  loggingIn: boolean = false;
  display: boolean = false;

  constructor(private loginService: LoginService)
  {
    super(loginService);
  }

  loginStatusChange(status: Login)
  {
      this.display = !status.loggedIn;
      this.loggingIn = status.loggingIn;
      // console.log(status.message);
  }

  ngOnInit(): void
  {
    super.ngOnInit();


    if (!this.loginService.checkAuthentication())
    {
      this.login();
    }
    else
    {
      this.loginService.checkAuthorization();
    }


  }


  login()
  {
    this.loggingIn = true;
    this.loginService.login();//.then(() => this.loggingIn = false);
  }

}
