import { ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { InstanceOfCare } from 'src/app/model/claim/instance_of_care';
import { Line } from 'src/app/model/claim/line';
import { textChangeRangeNewSpan } from 'typescript';

@Component({
  selector: 'app-instancelist',
  templateUrl: './instancelist.component.html',
  styleUrls: ['./instancelist.component.css']
})
export class InstancelistComponent implements OnInit {

  _instances: InstanceOfCare[] = [];

  @Output() chosenLines: EventEmitter<{instance: InstanceOfCare, lines:Line[]}> = new EventEmitter<{instance: InstanceOfCare, lines:Line[]}>();

  @Input()  instances: InstanceOfCare[];

  private _instancesAndLines: InstanceAndLines[];

  @Input() set instancesAndLines(val:InstanceAndLines[])
  {
    this._instancesAndLines = val;
    this.instances = this.instancesAndLines.map(v => v.instance);
    this.instancesAndLines.forEach(il => this.linesByInstance[il.instance.instanceId] = il.lines);
  }

  get instancesAndLines():InstanceAndLines[]
  {
    return this._instancesAndLines;
  }


  // @Input() set instances(value: InstanceOfCare[])
  //             {
  //               //Set our local variable
  //               this._instances = value;
                
  //               value.forEach
  //               (
  //                 i =>
  //                 this.linesByInstance[i.instanceId] = i.lines
  //               );
  //             }
  // @Input() set instancesAndLines(value: InstanceAndLines[])
  //             {
  //               //Set our local variable
  //               this._instances = value.map(v => v.instance);
                
  //               value.forEach
  //               (
  //                 i =>
  //                 this.linesByInstance[i.instance.instanceId] = i.lines
  //               );                
  //               //this.detectorRef.markForCheck(); // force update

  //             }            
  
  ngOnInit(): void 
  {
    this.selectedInstance= null;
    this.selectedLines = [];

    if(this.instancesAndLines != null)
    {
      //init by instance and lines
      this.instances = this.instancesAndLines.map(v => v.instance);
      this.instancesAndLines.forEach(il => this.linesByInstance[il.instance.instanceId] = il.lines);
    }
    else
    {
      //init by instances
      this.instances.forEach(i=> this.linesByInstance[i.instanceId] = i.lines);
    }
  }
  
  // ngOnChanges(changes: SimpleChanges): void
  // {
  //   if(this.instancesAndLines != null)
  //   {
  //     //init by instance and lines
  //     this.instances = this.instancesAndLines.map(v => v.instance);
  //     this.instancesAndLines.forEach(il => this.linesByInstance[il.instance.instanceId]= il.lines);
  //   }
  //   else
  //   {
  //     //init by instances
  //     this.instances.forEach(i=> this.linesByInstance[i.instanceId] = i.lines);
  //   }   
  // }

  // get instances(): InstanceOfCare[]
  // {
  //   return this._instances;
  // }



  linesByInstance: {[key: number]: Line[]} = {};

  selectedInstance: InstanceOfCare;
  selectedLines: Line[];

  get selectedLineIds(): number[]
  {
    return this.selectedLines.map(l => l.lineId);
  }

  instanceDialogs: {[key: number]: boolean} ={};

  constructor(private detectorRef: ChangeDetectorRef) { }



  customSelection(instanceId: number, event: number[])
  {
    //this.selectedInstance = this.instances.filter(i => i.instanceId == instanceId)[0];
    var instance = this.instances.filter(i => i.instanceId == instanceId)[0];
    if (this.linesByInstance[instanceId] == null) { this.linesByInstance[instanceId] = []}
    this.linesByInstance[instanceId] = instance.lines.filter(l => event.includes(l.lineId));
    //this.selectedLines = this.linesByInstance[instanceId];
    //this.chosenLines.emit({instance:this.selectedInstance, lines:this.selectedLines});
    // this.detectorRef.detectChanges();

  }
  
  selectInstance(event)
  {
    this.selectedInstance = event.data;
    this.selectedLines = this.linesByInstance[event.data.instanceId];
    this.chosenLines.emit({instance:this.selectedInstance, lines:this.selectedLines});
  }
  unselectInstance(event)
  {
    this.selectedInstance = null;
    this.selectedLines = [];
    this.chosenLines.emit({instance:this.selectedInstance, lines:this.selectedLines});
  }
  
  getSelectedLineCount(instanceId: number)
  {
    return this.linesByInstance[instanceId]?.length;
  }

  getSelectedLineCost(instanceId: number)
  {
    return this.linesByInstance[instanceId]?.reduce( (a,b) => a +b.cost,0); // this.linesByInstance[instanceId]
    //return this.instances.filter(i => i.instanceId == instanceId).reduce((a,b) => a+b.visits.reduce((a,b) => a+b.claims.reduce((a,b) => a+b.lines.filter(l => this.linesByInstance[instanceId].includes(l.lineId)).reduce( (a,b) => a +b.cost,0),0),0),0); // this.linesByInstance[instanceId]
  }
  getSelectedLineUnitCost(instanceId: number)
  {
    return this.linesByInstance[instanceId]?.reduce((a,b) => a +b.unitCost,0); // this.linesByInstance[instanceId]
    //return this.instances.filter(i => i.instanceId == instanceId).reduce((a,b) => a+b.visits.reduce((a,b) => a+b.claims.reduce((a,b) => a+b.lines.filter(l => this.linesByInstance[instanceId].includes(l.lineId)).reduce( (a,b) => a +b.unitCost,0),0),0),0); // this.linesByInstance[instanceId]
  }

  showInstanceDialog(instanceId: number)
  {
    this.instanceDialogs[instanceId] = true;
  }


  getInstanceAndLines(instance: InstanceOfCare) : {instance: InstanceOfCare, lines: Line[]}
  {
    let l:Line[] = this.linesByInstance[instance.instanceId];
    return {instance:instance, lines: l == null ? [] : l}
  }

  sortTable(event)
  {
    //event.field
    //event.order
    //event.multisortmeta 
    console.log(event);

    //This does an in place sort on the data handed in via the event
    event.data.sort
    (
      (a,b) =>
      {
        let val1 = null;
        let val2 = null;
        
        //See if we have any custom function lookups to do
        if(event.field == 'c_selectedUnitCost')
        {
          val1 = this.getSelectedLineUnitCost(a['instanceId']);
          val2 = this.getSelectedLineUnitCost(b['instanceId']);
        }
        else if(event.field == 'c_selectedLines')
        {
          val1 = this.getSelectedLineCount(a['instanceId']);
          val2 = this.getSelectedLineCount(b['instanceId']);
        }
        else
        {
          val1 = a[event.field];
          val2 = b[event.field];
        }
        
        let result = null;
        
        //Do the sort
        if(val1 == null && val2 !=null)
          result = -1;
        else if(val1 != null && val2 == null)
          result = 1;
        else if(val1 == null && val2 == null)
          result = 0;
        // else if(typeof val1 === 'string' && typeof val2 === 'string')
        //   result = val1.localeCompare(val2);
        else
          result = (val1 < val2) ? -1 : (val1 > val2) ? 1 :0;

        return (event.order * result);
      }
    );
  }

}

export class InstanceAndLines
{
    instance:InstanceOfCare;
    lines:Line[];
}