<!-- Busy Dialog -->
<!-- <div style="min-height:500px; height:500px"> -->

<p-dialog [modal]="true" [(visible)]="showBusy" [closable]="false" appendTo="body" [showHeader]="false" [style]="{'box-shadow':'none'}" [contentStyle]="{'background':'rgba(0,0,0,0)'}">
    <p-progressSpinner [style]="{'background':'rgba(0,0,0,0)','width':'50px','height':'50px'}" animationDuration=".5s" strokeWidth="8" ></p-progressSpinner>
  </p-dialog>

<!-- Assign To Ovelay -->
<p-dialog appendTo="body" [(visible)]="showAssignToDialog">
    <div class="flex grid" style="width:200px">
        <div class="col-12 w12 flex mt-3 mb-3">
            <span class="p-float-label" style="width:100%">
                <p-dropdown appendTo="body" id="assignedTo" [showClear]="true"  [options]="util.activeNurses" [(ngModel)]="selectedActiveUser" optionLabel="displayName" [autoDisplayFirst]="false"></p-dropdown>
                <label for="assignedTo">Assign To</label>
            </span>
        </div>
        <div class="col-12 w12 flex justify-content-center align-items-center mt-2 mb-2">
        <span class="p-buttonset">
            <button pButton pRipple label="Cancel" class="p-button-small p-button-rounded p-button-danger" (click)="showAssignToDialog = false"></button>
            <button pButton pRipple label="Save" class="p-button-small p-button-rounded p-button-success" (click)="showAssignToDialog = false;  assignSelectedEncounters()"></button>
        </span>
        </div>
    </div>
  </p-dialog> 

<!-- Closure status Overlay -->

<p-dialog #closeDialog appendTo="body" [(visible)]="showCloseDialog">
    <div class="flex grid" style="width:200px">
        <div class="col-12 w12 flex mt-3 mb-3">
            <span class="p-float-label" style="width:100%">
                <p-dropdown appendTo="body" id="closureReason" [showClear]="true"  [options]="selectedCloseStatus?.availableClosureReasons" [(ngModel)]="selectedClosureReason" optionLabel="reasonDescription" [autoDisplayFirst]="false"></p-dropdown>
                <label for="closureReason">Closure Reason</label>
            </span>
        </div>
        <div class="col-12 w12 flex justify-content-center align-items-center mt-2 mb-2">
        <span class="p-buttonset">
            <button pButton pRipple label="Cancel" class="p-button-small p-button-rounded p-button-danger" (click)="showCloseDialog = false;"></button>
            <button pButton pRipple label="Save" class="p-button-small p-button-rounded p-button-success" (click)="showCloseDialog = false;  closeSelectedEncounters()"></button>
        </span>
        </div>
    </div>
  </p-dialog> 

  <div class="flex-column fill-vert">
        <p-accordion [style]="{'max-height':'300px','display':'block','overflow':'scroll'}">
            <p-accordionTab header="Filters">
                
                <div class="grid pt-3">
                    <div class="col-6 pt-3 pb-3">
                        <span class="p-float-label">
                            <p-multiSelect  appendTo="body" id="showFromTier1" [filter]="true" filterBy="companyName" [options]="filterCompanies" [(ngModel)]="selectedCompanies" display="chip" optionLabel="companyName" (onChange)="storeFilters(); reloadEncounters(sortFields)" [style]="{'min-width': '300px'}"></p-multiSelect>
                            <label for="showFromTier1">Filter By Company</label>
                        </span>            
                    </div>                    
                    <div class="col-6 pt-3 pb-3">
                        <span class="p-float-label">
                            <input id="employerName" type="text" pInputText [(ngModel)]="employerNameContains" (change)="storeFilters(); reloadEncounters(sortFields)" [style]="{'min-width': '300px'}">
                            <label for="employerName">Employer Name</label>
                        </span>            
                    </div>
                    <div class="col-12 pt-3 pb-3">
                        <span class="p-float-label">
                            <p-multiSelect  appendTo="body" id="showAssignedTo" [showClear]="true"  [options]="util.activeNurses" [(ngModel)]="showEncountersFor" optionLabel="displayName"  display="chip" (onChange)="storeFilters(); reloadEncounters(sortFields)" [style]="{'min-width': '300px'}"></p-multiSelect>
                            <label for="showAssignedTo">Assigned To</label>
                        </span>            
                    </div>
                    <div class="col-12 pt-3 pb-3">

                        <span class="p-float-label">
                            <p-multiSelect appendTo="body" id="statusSelect" [options]="util.encounterStatuses" [(ngModel)]="selectedEncounterStatus"  optionLabel="statusDescription" display="chip" (onChange)="storeFilters(); reloadEncounters(sortFields)" [style]="{'min-width': '300px'}" [panelStyle]="{'min-width': '300px'}"></p-multiSelect>
                            <label for="statusSelect">Encounter Statuses</label>
                        </span>
                    </div>
                    <div class="col-12 pt-3 pb-3">
                        <span class="p-float-label">
                            <p-multiSelect appendTo="body" id="categorySelect" [options]="util.categories" [(ngModel)]="selectedCategories"  optionLabel="categoryName" display="chip" (onChange)="storeFilters(); reloadEncounters(sortFields)" [style]="{'min-width': '300px'}" [panelStyle]="{'min-width': '300px'}"></p-multiSelect>
                            <label for="categorySelect">Categories</label>
                        </span>
                    </div>
                    <div class="col-12 pt-3 pb-3">
                        <span class="p-float-label">
                            <p-multiSelect appendTo="body" id="typeSelect" [options]="util.encounterTypes" [(ngModel)]="selectedEncounterTypes"  optionLabel="encounterType" display="chip" (onChange)="storeFilters(); reloadEncounters(sortFields)" [style]="{'min-width': '300px'}" [panelStyle]="{'min-width': '300px'}"></p-multiSelect>
                            <label for="typeSelect">Encounter Types</label>
                        </span>
                    </div>
                </div>

            </p-accordionTab>
        </p-accordion>
    <div style="min-height:700px; height:700px">
        <p-table #encTable *ngIf="encounterBuffer != null" [value]="encounterBuffer.recordsOut" [lazy]="true" (onLazyLoad)="loadMoreEncounters($event)"
                [scrollable]="true" [virtualScroll]="true" dataKey="encounterId" [virtualScrollItemSize]="rowHeight" scrollHeight="flex" [totalRecords]="encounterBuffer.totalRecords"
                sortMode="multiple" [multiSortMeta]="sortFields" (onSort)="encounterSortChange($event)"
                selectionMode="multiple" [(selection)]="selectedEncounters" (onRowSelect)="updateContextMenu($event)" (onRowUnselect)="updateContextMenu($event)">
            <ng-template pTemplate="caption">
                
            </ng-template>
            <ng-template pTemplate="header">
                <tr [style]="{'min-height': rowHeightInPixels, 'height': rowHeightInPixels, 'max-height': rowHeightInPixels}">
                    <th class="w5">
                        <p-button  class="ml-2" icon="pi pi-bars"  (onClick)="actionMenu.toggle($event)"></p-button>
                        <p-menu #actionMenu [popup]="true" [model]="actions" appendTo="body"></p-menu>
                    </th>
                    <th pSortableColumn="encounterId" class="w8">Encounter<p-sortIcon field="encounterId"></p-sortIcon></th>
                    <th pSortableColumn="employerName" class="w8">Employer
                        <p-sortIcon field="employerName"></p-sortIcon>
                    </th>
                    <th pSortableColumn="status.statusDescription" class="w7">Status<p-sortIcon field="status.statusDescription"></p-sortIcon></th>
                    <th class="w6">Category</th>
                    <th pSortableColumn="creationDate" class="w7">Created <p-sortIcon field="creationDate"></p-sortIcon></th>
                    <th pSortableColumn="mostRecentAppointment?.appointmentDate" class="w6" >Next Appt<p-sortIcon field="mostRecentAppointment?.appointmentDate"></p-sortIcon></th>
                    <th class="w8">Servicing Provider</th>
                    <th class="w8">Identified Low Cost</th>
                    <th pSortableColumn="costToBeat" class="w6">Cost To Beat<p-sortIcon field="costToBeat"></p-sortIcon></th>
                    <th class="w6">Low Cost</th>
                    <th pSortableColumn="probableSavings" class="w7">Possible Savings<p-sortIcon field="probableSavings"></p-sortIcon></th>
                    <th [style]="{'max-width':'4rem'}">View</th>
                    <th [style]="{'max-width':'4rem'}">Assgn</th>
                    <th [style]="{'max-width':'4rem'}">Close</th>
                    <th [style]="{'max-width':'4rem'}">Cancel</th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-encounter>
                <tr [style]="{'min-height': rowHeightInPixels, 'height': rowHeightInPixels, 'max-height': rowHeightInPixels}">
                    <td class="w5">
                        <p-tableCheckbox [value]="encounter"></p-tableCheckbox>
                    </td>
                    <td class="w8">{{encounter.encounterId}}<p-tag *ngIf="encounter.patient.phi.hasOptedOut" severity="danger" value="Opted Out"/></td>
                    <td class="w8">{{encounter.employerName}}</td>
                    <td class="w7">{{encounter.status.statusDescription}}</td>
                    <td class="w6">{{encounter.category?.categoryName}}</td>
                    <td class="w7">{{encounter.creationDate | date:'shortDate'}}</td>
                    <td class="w6">{{encounter.mostRecentAppointment?.appointmentDate | date:'shortDate'}}</td>
                    <td class="w8"><span [pTooltip]="encounter.providerToBeat?.providerLocation.location.fullAddress" class="trunc">{{encounter.providerToBeat?.providerLocation.practiceName}}</span></td>
                    <td class="w8"><span [pTooltip]="encounter.probableLowCostProvider?.providerLocation.location.fullAddress" class="trunc">{{encounter.probableLowCostProvider?.providerLocation.practiceName}}</span></td>
                    <td class="w6">{{encounter.costToBeat | currency}}</td>
                    <td class="w6">{{encounter.probableLowCost | currency}}</td>
                    <td class="w7">{{encounter.probableSavings | currency}}</td>
                    <td [style]="{'max-width':'4rem'}">&nbsp;&nbsp;&nbsp;
                        <button pButton icon="pi pi-folder-open" (click)="openEncounter(encounter)"></button>
                    </td>
                    <td [style]="{'max-width':'4rem'}">&nbsp;&nbsp;&nbsp;
                        <button pButton icon="pi pi-check-square" (click)="assignEncounter(encounter)"></button>
                    </td>
                    <td [style]="{'max-width':'4rem'}">&nbsp;&nbsp;&nbsp;
                        <p-button icon="pi pi-ban" styleClass="p-button-danger" (onClick)="setCloseStatus(); statusDialog.toggle($event)"></p-button>
                    </td>
                    <td [style]="{'max-width':'4rem'}">&nbsp;&nbsp;&nbsp;
                        <p-button icon="pi pi-times" styleClass="p-button-danger" (onClick)="setCancelStatus(); statusDialog.toggle($event)"></p-button>
                    </td>
                    
                        <p-overlayPanel #statusDialog>
                            <div class="flex grid">
                        
                                <div class="col-12 w12 flex mt-3 mb-3">
                                <span class="p-float-label" style="width:100%">
                                    <p-dropdown appendTo="body" id="closureReason" [showClear]="true"  [options]="selectedCloseStatus?.availableClosureReasons" [(ngModel)]="encounter.closureReason" optionLabel="reasonDescription" [autoDisplayFirst]="false"></p-dropdown>
                                    <label for="closureReason">Closure Reason</label>
                                </span>
                                </div>
                                <div class="col-12 w12 flex justify-content-center align-items-center mt-2 mb-2">
                                <span class="p-buttonset">
                                    <button pButton pRipple label="Cancel" class="p-button-small p-button-rounded p-button-danger"
                                            (click)="statusDialog.hide();"></button>
                                    <button pButton pRipple label="Save" class="p-button-small p-button-rounded p-button-success"
                                            (click)="closeEncounter(encounter);statusDialog.hide();"></button>
                                </span>
                                </div>
                            </div>
                        </p-overlayPanel>    
                </tr>
            </ng-template>
            <ng-template pTemplate="loadingbody">
                <tr [style]="{'min-height': rowHeightInPixels, 'height': rowHeightInPixels, 'max-height': rowHeightInPixels}">
                    <td>
                        <div class="flex justify-content-center p-1" style="width: 100%; background-color: lightgray; "></div>
                    </td>
                    <td>
                        <div class="flex justify-content-center p-1" style="width: 100%; background-color: lightgray; "></div>
                    </td>
                    <td>
                        <div class="flex justify-content-center p-1" style="width: 100%; background-color: lightgray; "></div>
                    </td>
                    <td>
                        <div class="flex justify-content-center p-1" style="width: 100%; background-color: lightgray; "></div>
                    </td>
                    <td>
                        <div class="flex justify-content-center p-1" style="width: 100%; background-color: lightgray; "></div>
                    </td>
                    <td>
                        <div class="flex justify-content-center p-1" style="width: 100%; background-color: lightgray; "></div>
                    </td>
                    <td>
                        <div class="flex justify-content-center p-1" style="width: 100%; background-color: lightgray; "></div>
                    </td>
                    <td>
                        <div class="flex justify-content-center p-1" style="width: 100%; background-color: lightgray; "></div>
                    </td>
                    <td>
                        <div class="flex justify-content-center p-1" style="width: 100%; background-color: lightgray; "></div>
                    </td>
                    <td>
                        <div class="flex justify-content-center p-1" style="width: 100%; background-color: lightgray; "></div>
                    </td>
                    <td>
                        <div class="flex justify-content-center p-1" style="width: 100%; background-color: lightgray; "></div>
                    </td>
                    <td>
                        <div class="flex justify-content-center p-1" style="width: 100%; background-color: lightgray; "></div>
                    </td>
                    <td>
                        <div class="flex justify-content-center p-1" style="width: 100%; background-color: lightgray; "></div>
                    </td>
                </tr>
            </ng-template>
        </p-table>
    </div>
</div>
<!-- </div> -->
