<div class="flex flex-column fill-vert p-1 no-overflow">

    <p-panel header="Which Encounter?">
        <div class="pt-3">
            <div class="formgroup-inline">
                <span class="p-float-label">
                    <input id="encounterId" type="text" pInputText [(ngModel)]="encounterId"/>
                    <label for="encounterId">Encounter Id</label> 
                </span>

                
                <div class="field">
                    <div >
                        <p-button label="Open Encounter"
                        (click)="openEncounter()" [disabled]="opening" iconPos="right" [icon]="searchIcon"></p-button>
                    </div>
                    <div style="color: red;">
                        {{message}}
                    </div>
                </div>
            </div>
        </div>
    </p-panel>
<!--    
</div>
<div class="mt-3 p-2 grid no-overflow" style="flex-grow:1; flex-shrink:1">
-->

    <p-tabView>
        <p-tabPanel header="Basic Encounter Info">
            <div *ngIf="encounter">
                <table style="border: 0" width="200">
                    <tr>
                        <td>Encounter ID:</td>
                        <td align="right"><b>{{ encounter.encounterId }}</b> <span *ngIf="0 > encounter.encounterId"> (ECS)</span></td>
                    </tr>
                </table>
                <br>
                <table style="border: 0">
                    <tr>
                        <td valign="top">
                            <table border="0" width="400">
                                <tr>
                                    <td style="border-bottom: 1px solid black;" colspan="2"><b>PATIENT</b>
                                        <div style="float: right;">ID: <b>{{ encounter.patient.personId }}</b></div></td>
                                </tr>
                                <tr>
                                    <td align="right" valign="top">Full Name:</td>
                                    <td valign="top">
                                        <p-inplace [closable]="true" closeIcon="pi pi-check">
                                            <ng-template pTemplate="display">
                                                <b>{{ encounter.patient.phi.firstName }} {{ encounter.patient.phi.middleInitial }} {{encounter.patient.phi.lastName }}</b>
                                            </ng-template>
                                            <ng-template pTemplate="content">
                                                <input type="text" value="{{ encounter.patient.phi.firstName }}" pInputText style="width:100px">
                                                <input type="text" value="{{ encounter.patient.phi.middleInitial }}" pInputText style="width:30px" maxlength="1">
                                                <input type="text" value="{{ encounter.patient.phi.lastName }}" pInputText style="width:100px">
                                            </ng-template>
                                        </p-inplace>
                                        
                                    </td>
                                </tr>
                                <tr>
                                    <td align="right" valign="top">Phone:</td>
                                    <td valign="top">
                                        <p-inplace [closable]="true" closeIcon="pi pi-check">
                                            <ng-template pTemplate="display">
                                                <b>{{ encounter.patient.phi.company.phone }}</b>
                                            </ng-template>
                                            <ng-template pTemplate="content">
                                                <!--<input type="text" value="{{ encounter.patient.phi.company.phone }}" pInputText>-->
                                                <p-inputMask mask="(999) 999-9999? x9999999" [(ngModel)]="encounter.patient.phi.company.phone"></p-inputMask>
                                            </ng-template>
                                        </p-inplace>
                                    </td>
                                </tr>
                                <tr>
                                    <td align="right" valign="top">Email:</td>
                                    <td valign="top">
                                        <p-inplace [closable]="true" closeIcon="pi pi-check">
                                            <ng-template pTemplate="display">
                                                <b>{{ encounter.patient.phi.company.email }}</b>
                                            </ng-template>
                                            <ng-template pTemplate="content">
                                                <input type="text" value="{{ encounter.patient.phi.company.email }}" pInputText>
                                            </ng-template>
                                        </p-inplace>
                                    </td>
                                </tr>
                            </table>
                        </td>
                        <td>&nbsp;&nbsp;</td>
                        <td valign="top">
                            <table border="0" width="400">
                                <tr>
                                    <td style="border-bottom: 1px solid black;" colspan="2"><b>SUBSCRIBER</b>
                                        <div style="float: right;">ID: <b>{{ encounter.subscriber.personId }}</b></div></td>
                                </tr>
                                <tr>
                                    <td align="right" valign="top">Full Name:</td>
                                    <td valign="top"><b>{{ encounter.subscriber.phi.firstName }} {{ encounter.subscriber.phi.middleInitial }} {{encounter.subscriber.phi.lastName }}</b></td>
                                </tr>
                                <tr>
                                    <td align="right" valign="top">Phone:</td>
                                    <td valign="top"><b>{{ encounter.subscriber.phi.company.phone }}</b></td>
                                </tr>
                                <tr>
                                    <td align="right" valign="top">Email:</td>
                                    <td valign="top"><b>{{ encounter.subscriber.phi.company.email }}</b></td>
                                </tr>
                            </table>
                        </td>
                        <td>&nbsp;&nbsp;</td>
                        <td valign="top">
                            <table border="0" width="400">
                                <tr>
                                    <td style="border-bottom: 1px solid black;" colspan="2"><b>SUBSCRIBER COMPANY</b>
                                        <div style="float: right;">ID: <b>{{ encounter.subscriberCompany.companyId }}</b></div></td>
                                </tr>
                                <tr>
                                    <td align="right" valign="top">Company Name:</td>
                                    <td valign="top"><b>{{ encounter.subscriberCompany.companyName }}</b></td>
                                </tr>
                                <tr>
                                    <td align="right" valign="top">Phone:</td>
                                    <td valign="top"><b>{{ encounter.subscriberCompany.phone }}</b></td>
                                </tr>
                                <tr>
                                    <td align="right" valign="top">Email:</td>
                                    <td valign="top"><b>{{ encounter.subscriberCompany.email }}</b></td>
                                </tr>
                                <tr>
                                    <td align="right" valign="top">Type:</td>
                                    <td valign="top"><b>{{ encounter.subscriberCompany.companyType.typeDescription }}</b></td>
                                </tr>
                                <tr>
                                    <td align="right" valign="top">Reward:</td>
                                    <td valign="top"><b>{{ encounter.rewardRule.ruleName }}</b><br>{{ encounter.rewardRule.descriptionText }}</td>
                                </tr>
                            </table>
                        </td>
                    </tr>
            <!-- Patient -->
                <!-- Name -->
                <!-- MemberId -->
                <!-- Phone Number -->
                <!-- email address -->
            <!-- Susbscriber -->
                <!-- Same as patient -->
            <!-- Caller -->
                <!-- Name -->
                <!-- Phone Number -->
                <!-- email Address -->
            <!-- Company -->
            <!-- Carrier/Source/Network -->
            <!-- Create Date -->
                </table>
            </div>
        </p-tabPanel>
        <p-tabPanel header="Procedure Lookup Info">
            Content 2
        </p-tabPanel>
        <p-tabPanel header="Notes and Activities">
            Content 3
        </p-tabPanel>
    </p-tabView>

</div>