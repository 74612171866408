<!-- <div class="flex flex-column fill-vert p-1 scroll-overflow" style="width: 100%;"> -->
<!-- <div class="fill-vert scroll-overflow"> -->
    <div class="fill-vert flex-column scroll-overflow">
        <div class="scroll-overflow" style="height: 70%; max-height:70%; min-height:70%;">
            <p-accordion>
                <p-accordionTab header="Search Criteria" [selected]="!resultsPanelVisible" (onOpen)="resultsPanelVisible = false;" >
                    <div class=" col scroll-overflow" >
                        <!-- <p-panel header="Search Criteria" [toggleable]="true" (onBeforeToggle)="toggleSearchPane($event)"> -->
                            <p-accordion>
                                <p-accordionTab header="Step 1 - Procedure Selection" [(selected)]="stepStates[0]">
                                    <app-procedure-selector [(category)]="selectedCategory" [procedures]="selectedProcs" (proceduresChange)="selectedProcsChanged($event)"></app-procedure-selector>
                                        <!-- <div class="flex p-3">
                                            <p-dropdown  appendTo="body" id="Category" [options]="categories" [(ngModel)]="selectedCategory" optionLabel="categoryName" [style]="{'width':'200px'}" [showClear]="true" placeholder="Select Category" (onChange)="categorySelected($event)"></p-dropdown>
                                        </div>
                                        <p-tabView>
                                                <p-tabPanel header="CPT">
                                                    <p-tree [value]="filteredProcTree" selectionMode="checkbox" filter="true" [(selection)]="selectedNodes"></p-tree>
                                                </p-tabPanel>
                                                <p-tabPanel header="HCPCS">
                                                    <p-tree [value]="filteredProcTreeHCPCS" selectionMode="checkbox" filter="true" [(selection)]="selectedNodesHCPCS"></p-tree>
                                                </p-tabPanel>
                                        </p-tabView> -->
                                </p-accordionTab>
                                <p-accordionTab header="Step 2 - Select Source and Network" [(selected)]="stepStates[1]">
                                    <!-- Source -->
                                    <div class="formgroup-inline mt-3">
                                        <!-- <span class="p-float-label" style="min-width: 150px;" > -->
                                            <p-dropdown  appendTo="body" id="Source" [options]="sources" [(ngModel)]="selectedSource" optionLabel="sourceName" [style]="{'width':'200px'}" [showClear]="true" placeholder="Select Source"></p-dropdown>
                                            <!-- <label for="Source">Source</label>  -->
                                        <!-- </span>     -->
                                        <!-- <span class="p-float-label" style="min-width: 150px;" >
                                            <p-dropdown appendTo="body" id="NetworkGroup" [options]="networkGroupOptions" [(ngModel)]="selectedNetworkGroup" optionLabel="label"  [style]="{'width':'200px'}" [showClear]="true" placeholder=""></p-dropdown>
                                            <label for="NetworkGroup">NetworkGroup</label> 
                                        </span>         -->
                                        <!-- <span class="p-float-label" style="min-width: 150px;" > -->
                                            <p-dropdown  appendTo="body" id="Network" [options]="networks" [(ngModel)]="selectedNetwork" optionLabel="displayName" [style]="{'width':'200px'}" [showClear]="true" placeholder="Select Network"></p-dropdown>
                                            <!-- <label for="Network">Network</label>  -->
                                        <!-- </span>   -->
                                        <!-- <span class="p-float-label" style="min-width: 150px;" > -->
                                            <!-- <label for="Category">Category</label>  -->
                                        <!-- </span>                     -->
                                    </div>
                                    <!-- Network -->

 
                                </p-accordionTab>
                                <p-accordionTab header="Step 3 - Select Search Area and Lookback" [(selected)]="stepStates[2]">
                                    <div class="formgroup-inline mt-3">
                                        <!-- Zip Code -->   
                                        <span class="p-float-label">
                                            <input  id="zipCode" type="text" pInputText [(ngModel)]="zipCode"/>
                                            <label for="zipCode">Zip Code</label> 
                                        </span>
                                        <span class="p-float-label">
                                            <!-- <input  id="radius" type="text" pInputText [(ngModel)]="radius"/> -->
                                            <p-inputNumber [(ngModel)]="radius" inputId="radius" suffix=" mi"></p-inputNumber>
                                            <label for="radius">Radius</label> 
                                        </span>            
                                        <span class="p-float-label">
                                            <input  id="lookback" type="number" pInputText [(ngModel)]="lookbackDays"/>
                                            <label for="lookback">Lookback Days</label> 
                                        </span>
                                    </div>
                                    <!-- Go! -->
                                    <p-button label="Go!" (click)="doLookup()" [disabled]="searching" iconPos="right" [icon]="searchIcon"></p-button>

                                </p-accordionTab>
                                <!-- <p-accordionTab header="Step 4 - Search Results"> -->
                                    <!-- Data Table of Results -->
                            </p-accordion>
                        <!-- </p-panel> -->
                        <!-- <p-divider type="dashed" align="center">
                            <span class="p-tag">Results</span>
                        </p-divider> -->
                    </div>
                </p-accordionTab>
                <p-accordionTab header = "Search Results" [(selected)]="resultsPanelVisible">
                    <div class="col scroll-overflow">
                        <!-- <p-panel header="Search Results" [toggleable]="true" [collapsed]="!resultsPanelVisible"> -->
                            <p-accordion [activeIndex]="selectedResultSet">
                                <p-accordionTab header="Specific Results">
                                    <p-tabView [activeIndex]="selectedResultsType">
                                        <p-tabPanel header="Procedure Results">
                                            <app-procedure-results [procedureResults]="lookupResults?.specificProcedureResults" (chosenResult)="chosenPracticeResult($event.chosenResult); chosenSpecificProcs($event.chosenItems);"></app-procedure-results>
                                        </p-tabPanel>
                                        <p-tabPanel header="Category Results">
                                            <app-category-results [categoryResults]="lookupResults?.specificCategoryResults" (chosenResult)="chosenPracticeResult($event.chosenResult); chosenSpecificCats($event.chosenItems);"></app-category-results>
                                        </p-tabPanel>
                                        <p-tabPanel header="Instance Results">
                                            <app-instance-results [instanceResults]="lookupResults?.specificInstanceResults" (chosenResult)="chosenPracticeResult($event.chosenResult); chosenSpecificInstances($event.chosenItems);"></app-instance-results>
                                        </p-tabPanel>
                                    </p-tabView>
                                </p-accordionTab>
                                <p-accordionTab header="General Results">
                                    <p-tabView [activeIndex]="selectedResultsType">
                                        <p-tabPanel header="Procedure Results">
                                            <app-procedure-results [procedureResults]="lookupResults?.generalProcedureResults" (chosenResult)="chosenPracticeResult($event.chosenResult); chosenGeneralProcs($event.chosenItems);"></app-procedure-results>    
                                        </p-tabPanel>
                                        <p-tabPanel header="Category Results">
                                            <app-category-results [categoryResults]="lookupResults?.generalCategoryResults" (chosenResult)="chosenPracticeResult($event.chosenResult); chosenGeneralCats($event.chosenItems);"></app-category-results>
                                        </p-tabPanel>
                                        <p-tabPanel header="Instance Results">
                                            <app-instance-results [instanceResults]="lookupResults?.generalInstanceResults" (chosenResult)="chosenPracticeResult($event.chosenResult); chosenGeneralInstances($event.chosenItems);"></app-instance-results>
                                    </p-tabPanel>
                                    </p-tabView>
                                </p-accordionTab>
                                <p-accordionTab header="MRF Results">
                                    <p-table *ngIf="mrfResults != null" [value]="mrfResults" dataKey="practice.ein" selectionMode="single" [(selection)]="selectedMRFResult" (onRowSelect)="MRFChosen($event)" (onRowUnselect)="MRFChosen($event)"  sortField="practice.practiceName">
                                        <ng-template pTemplate="header">
                                            <tr>
                                                <th style="width: 3rem;"></th>
                                                <th pSortableColumn="practice.practiceName">Practice Name <p-sortIcon field="practice.practiceName"></p-sortIcon></th>
                                                <th style="width: 3rem;"></th>
                                                <th>Locations</th>
                                                <th pSortableColumn="practiceCost">Total Price <p-sortIcon field="practiceCost"></p-sortIcon></th>
                                            </tr>
                                        </ng-template>
                                        <ng-template pTemplate="body" let-mrf let-expanded="expanded">  
                                            <tr [pSelectableRow]="mrf">
                                                <td>
                                                    <button type="button" pButton pRipple [pRowToggler]="mrf" class="p-button-text p-button-rounded p-button-plain" [icon]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"></button>
                                                </td>
                                                <td>{{mrf.practice.practiceName}}</td>
                                                <td>
                                                    <button type="button" pButton pRipple class="p-button-text p-button-rounded p-button-plain" icon="pi pi-pencil" (click)="showPracticeEditor[mrf.practice.ein] = true"></button>
                                                    <!-- Pracice Editor Dialog -->
                                                    <p-dialog appendTo="body" header="Edit Practice" [(visible)]="showPracticeEditor[mrf.practice.ein]" [style] ="{width: '900px', 'max-height': '800px' }">
                                                        <!-- <app-practice-editor [(modelData)]="result.practice"></app-practice-editor> -->
                                                        <app-practice-editor *ngIf="showPracticeEditor[mrf.practice.ein]" [modelId]="mrf.practice.ein"></app-practice-editor>
                                                    </p-dialog>
                                                </td>
                                                <td>
                                                    <app-location-tag *ngFor="let location of mrf.locations" [location]="location"></app-location-tag>
                                                </td>
                                                <td>{{mrf.practiceCost | currency}}</td>
                                            </tr>
                                        </ng-template>             
                                        <ng-template pTemplate="rowexpansion" let-mrf>
                                            <tr>
                                                <td colspan="4">
                                                    <div>
                                                        <p-table [value]="mrf.procedureBasedResults" dataKey="Procedure.procId">
                                                            <ng-template pTemplate="header">
                                                                <tr>
                                                                    <th>Procedure</th>
                                                                    <th>Description</th>
                                                                    <th>Price</th>
                                                                </tr>
                                                            </ng-template>
                                                            <ng-template pTemplate="body" let-proc>
                                                                <tr>
                                                                    <td>{{proc.procedure.code}}</td>
                                                                    <td>{{proc.procedure.displayText}}</td>
                                                                    <td>{{proc.topMRF.price | currency}}</td>
                                                                </tr>
                                                            </ng-template>
                                                        </p-table>  
                                                    </div>
                                                </td>
                                            </tr>                                         
                                        </ng-template>
                                    </p-table>
                                </p-accordionTab>
                            </p-accordion>
                        <!-- </p-panel> -->
                    </div>
                    
                </p-accordionTab>
            </p-accordion>      
        </div>
        <div style="height: 2%; max-height:2%; min-height:2%;"></div>
        <div class="scroll-overflow" style="height: 28%; max-height:28%; min-height:28%;">
        <!-- <p-divider type="dashed" align="center">
            <span class="p-tag">Selected Model</span>
        </p-divider> -->
        <!-- <p-panel header="Selected Lines" [toggleable]="true" [collapsed]="!resultsPanelVisible"> -->
            <div class="grid">
                <div class="col-5">
                    <p-card header="Selected Provider">
                        
                        <div>
                            {{selectedPracticeResult?.practice?.practiceName}}
                        </div>
                        <div *ngIf="selectedPracticeResult != null">
                            <p-table [value]="selectedPracticeResult.locations" [(selection)]="selectedLocation" dataKey="location.locationId" (onRowSelect)="locationRowSelected($event)" (onRowUnselect)="locationRowDeselected($event)">
                                <ng-template pTemplate="header">
                                    <tr>
                                        <th style="width: 3rem;"></th>
                                        <th>Street 1</th>
                                        <th>Street 2</th>
                                        <th>City</th>
                                        <th>State</th>
                                        <th>Zip Code</th>
                                        <th>Distance</th>
                                    </tr>
                                </ng-template>
                                <ng-template pTemplate="body" let-location>
                                    <tr>
                                        <td>
                                            <p-tableRadioButton [value]="location"></p-tableRadioButton>
                                        </td>
                                        <td>{{location.location.street1}}</td>
                                        <td>{{location.location.street2}}</td>
                                        <td>{{location.location.city}}</td>
                                        <td>{{location.location.state}}</td>
                                        <td>{{location.location.zip}}</td>
                                        <td>{{location.distance.length | number: '1.0-2' }} {{location.distance.units}}</td>
                                    </tr>
                                </ng-template>
                            </p-table>
                        </div>

                    </p-card>
                </div>
                <div class="col-7">
                    <p-card header="Selected Lines">
                        <p-table [value]="pseudoInstance" dataKey="line.lineId" > 
                            <ng-template pTemplate="header">
                                <tr>
                                    <th style="width:7rem">Code</th>
                                    <!-- <th>Practice</th> -->
                                    <!-- <th style="width:5rem">Date Of Service</th> -->
                                    <th style="width:7rem">Description</th>
                                    <th style="width:7rem">Unit Cost</th>
                                    <th style="width:4rem">Units</th>
                                    <th style="width: 10rem;">Total Cost</th>
                                    <th style="width:3rem"></th>
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="body" let-model>
                                <tr>
                                    <td style="width:7rem">{{model.procedure.code}}</td>
                                    <!-- <td style="width:5rem">{{model.instanceOfCare.serviceBegin | date:'shortDate'}}</td> -->
                                    <td style="width:7rem">{{model.procedure.displayText}}</td>
                                    <td style="width:7rem">{{model.unitCost | currency }}</td>
                                    <td style="width:4rem">{{model.units}}</td>
                                    <td style="width: 10rem;">{{model.cost | currency}}</td>
                                    <td style="width: 3rem;">
                                        <p-confirmPopup appendTo="body"></p-confirmPopup>
                                        <button pButton pRipple type="button" icon="pi pi-trash" (click)="deletePseudoLine(model)" class="p-button-rounded p-button-text p-button-danger"></button> 
                                    </td>
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="footer">
                                <tr>
                                    <td colspan=2>Total</td>
                                    <td style="width:7rem"> <span *ngIf="pendingEncounterProvider != null"> Override <p-inputSwitch  [(ngModel)]="pendingEncounterProvider.override" (onChange)="encounterProviderChange.emit(pendingEncounterProvider)"></p-inputSwitch></span></td>
                                    <td style="width:7rem">
                                        <span *ngIf="pendingEncounterProvider != null">
                                            {{pseudoInstanceTotalUnitCost | currency}}
                                        </span>
                                    </td>
                                    <td style="width:4rem">
                                        <span *ngIf="pendingEncounterProvider != null">
                                            {{pseudoInstanceTotalUnits}}
                                        </span>
                                    </td>                                    
                                    <td style="width: 10rem;"> 
                                        <span *ngIf="pendingEncounterProvider != null && !pendingEncounterProvider.override"> 
                                            {{pendingEncounterProvider.cost  | currency }}
                                        </span>
                                        <span *ngIf="pendingEncounterProvider != null && pendingEncounterProvider.override"> 
                                            <span class="p-float-label">
                                                <p-inputNumber [inputStyle]="{'max-width':'8rem'}" [(ngModel)]="pendingEncounterProvider.costOverride" mode="currency" inputId="costOverride" currency="USD" locale="en-US" (onInput)="encounterProviderChange.emit(pendingEncounterProvider)"></p-inputNumber>
                                                <label for="costOverride">Cost Override</label>
                                            </span>
                                        </span>
                                    </td>
                                </tr>
                            </ng-template>            
                        </p-table>
                    </p-card>
                </div>
            </div>
        </div> 
    </div>
<!-- </div> -->
        <!-- </p-panel> -->
        <!-- <p-table [value]="lookupResults" dataKey="practice.ein">
            <ng-template pTemplate="header">
                <tr>
                    <th style="width: 3rem"></th>
                    <th>Practice</th>
                    <th>Locations</th>
                    <th>Suggested Price</th>
                    <th>Procedure Count</th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-result let-expanded="expanded">
                <tr>
                    <td>
                        <button type="button" pButton pRipple [pRowToggler]="result" class="p-button-text p-button-rounded p-button-plain" [icon]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"></button>
                    </td>
                    <td>{{result.practice.practiceName}}</td>
                    <td></td>
                    <td>{{result.instanceMedianCost}}</td>
                    <td>{{result.acceptableInstances.length}}</td>
                </tr>
            </ng-template>
            <ng-template pTemplate="rowexpansion" let-result>
                <tr>
                    <td colspan="5">
                        <div>
                            <p-table [value]="result.acceptableInstancesOfCare()" dataKey="instanceId">
                                <ng-template pTemplate="header">
                                    <tr>
                                        <th>Instance Id</th>
                                        <th>Date Of Service</th>
                                        <th>Network</th>
                                        <th>Cost</th>
                                        <th style="width: 3rem"></th>
                                    </tr>
                                </ng-template>
                                <ng-template pTemplate="body" let-ioc>
                                    <tr>
                                        <td>{{ioc.instanceId}}</td>
                                        <td>{{ioc.serviceBegin}}</td>
                                        <td>{{ioc.network.networkName}}</td>
                                        <td>{{ioc.instanceCost}}</td>
                                        <td>         
                                            <p-dialog appendTo="body" header="Instance Details" [(visible)]="instanceDialogs[ioc.instanceId.toString()]"    [modal]="false" [dismissableMask]="true" [closeOnEscape]="true" [responsive]="true" [closeable]="true" [style]="{width: '50%'}">
                                                <app-instance [modelId]="ioc.instanceId"></app-instance>
                                                
                                            </p-dialog>                           
                                            <button pButton pRipple type="button" icon="pi pi-info" class="p-button-rounded" (click)="showInstanceDialog(ioc.instanceId)"></button>
                                        </td>
                                    </tr>
                                </ng-template>

                            </p-table>
                        </div>
                    </td>
                </tr>
            </ng-template>
        </p-table> -->
        <!-- </p-accordionTab> -->
    <!-- </div> -->
<!-- </div> -->
