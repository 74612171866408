import { Company } from "../support/company";
import { Network } from "../support/network";
import { User } from "../util/user";
import { Phi } from "./phi";


export class Plan 
{
    active:boolean;
    dependentOf: Plan;
    detailColonoscopy: string;
    detailLabs: string;
    detailOccTherapy: string;
    detailPt: string;
    detailRadiology: string;
    detailUrgentCare: string;
    detailsOutpatientSurgery: string;
    effectiveDate: Date;
    hsaEligible: boolean;
    isSubscriber: boolean;
    maskedEquivalent: string;
    memberId: string;
    company: Company;
    modifiedBy: User;
    phi: Phi;
    network: Network;
    dependents: Plan[];
    planMembers: Plan[];
    planType: PlanType;

    asUpdate(): PlanUpdate
    {
        let pu: PlanUpdate = new PlanUpdate();

        pu.active = this.active;
        pu.dependentOfMemberId = this.dependentOf?.memberId;
        pu.effectiveDate = this.effectiveDate;
        pu.hsaEligible = this.hsaEligible;
        pu.isSubscriber = this.isSubscriber;
        pu.networkId = this.network.networkId;
        pu.phiId = this.phi.phiId;
        pu.planType = this.planType;
        pu.companyId = this.company.companyId;

        return pu;
    }
}

export class PlanType
{
    planTypeId: number;
    planType: string;
}
export class PlanUpdate
{
    active?: boolean;
    dependentOfMemberId?: string;
    effectiveDate?: Date;
    hsaEligible?: boolean;
    isSubscriber?: boolean;
    planType?: PlanType;
    phiId?: number;
    networkId?: number;
    companyId?: number;
}
