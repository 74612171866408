import { CheckingAccount } from "../finance/checking_account";
import { InvoiceRule } from "../finance/invoice";
import { RewardRule } from "../finance/reward_rule";
import { User } from "../util/user";
import { Captive } from "./captive";
import { CompanyContact } from "./company_contact";
import { CompanyHierarchy } from "./company_hierarchy";
import { CompanyType } from "./company_type";
import { Source } from './source';
import { WhiteLabelProgram } from "./white_label";
export class Company
{
    companyName: string;
    companyId: number;
    clientCompanyId: string;
    active: boolean;
    address1: string;
    address2: string;
    city: string;
    state: string;
    zipCode: string;
    email: string;
    phone: string;
    fax: string;
    tier: number;
    companyType: CompanyType;
    tier1Name: string;
    tier2Name: string;
    tier3Name: string;
    tier4Name: string;
    tier5Name: string;
    tier6Name: string;
    tier7Name: string;
    tier2ClientId: string;
    tier3ClientId: string;
    tier4ClientId: string;
    tier5ClientId: string;
    tier6ClientId: string;
    tier7ClientId: string;

    tier1Type: CompanyType;
    tier2Type: CompanyType;
    tier3Type: CompanyType;
    tier4Type: CompanyType;
    tier5Type: CompanyType;
    tier6Type: CompanyType;
    tier7Type: CompanyType;

    tier1Id: number;
    tier2Id: number;
    tier3Id: number;
    tier4Id: number;
    tier5Id: number;
    tier6Id: number;
    tier7Id: number;

    parent: Company;

    source: Source;

    modifiedBy: User;

    companyContacts: CompanyContact[];

    rewardRules: RewardRule[];
    invoiceRules: InvoiceRule[];

    subCount: number;

    hierarchyDetail: CompanyHierarchy;

    whiteLabelDetail: WhiteLabelProgram;
    availableAccounts: CheckingAccount[];

    captive: Captive;

    get employerName(): string
    {
        let employerName: string;
        switch (this.hierarchyDetail.employerTier)
        {
            case 1:
                employerName = this.tier1Name;
                break;
            case 2:
                employerName = this.tier2Name;
                break;
            case 3:
                employerName = this.tier3Name;
                break;
            case 4:
                employerName = this.tier4Name;
                break;
            case 5:
                employerName = this.tier5Name;
                break;
            case 6:
                employerName = this.tier6Name;
                break;
            case 7:
                employerName = this.tier7Name;
                break;
        }
        return employerName;
    }

    get displayName(): string
    {
        let displayName: string = this.companyName;
        if (this.employerName  != this.companyName)
        {
            displayName += " - " + this.employerName;
        }

        if(this.employerName != this.tier1Name)
        {
            displayName += " (" + this.tier1Name + ")"
        }

        return displayName;
    }

    asUpdate(): CompanyUpdate
    {
        let cu = new CompanyUpdate();

        cu.companyName = this.companyName;
        cu.active = this.active;
        cu.address1 = this.address1;
        cu.address2 = this.address2;
        cu.city = this.city;
        cu.state = this.state;
        cu.zipCode = this.zipCode;
        cu.companyType = this.companyType?.typeCode;
        cu.email = this.email;
        cu.fax = this.fax;
        cu.phone = this.phone;
        cu.sourceId = this.source?.sourceId;
        cu.subCount = this.subCount;
        cu.clientCompanyId = this.clientCompanyId;
        cu.captiveId = this.captive?.captiveId;

        return cu;
    }

    asInsert(): CompanyInsert
    {
        return Object.assign(new CompanyInsert(), this.asUpdate());
    }

}
export class CompanyUpdate
{
    companyName: string;
    clientCompanyId: string;
    active: boolean;
    address1: string;
    address2: string;
    city: string;
    state: string;
    zipCode: string;
    email:string;
    phone: string;
    fax:string;
    companyType: number;
    sourceId: number;
    subCount: number;
    captiveId: number;
}

export class CompanyInsert extends CompanyUpdate
{

}