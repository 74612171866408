
<div *ngIf = "!endUserAlreadyExists" class="box">
  <div class="row">
    <span class="p-float-label">
      <p-dropdown id="searchOn" [options]="options" (onChange)="dropdownChanged($event)" [(ngModel)]="selectedOption" optionLabel="name" [autoDisplayFirst]="false"></p-dropdown>
      <label for="searchOn">Search On</label>
    </span>
    <span class="p-float-label">
      <p-calendar id="searchCriteria" *ngIf="dateOfBirthSelected" [(ngModel)]="selectedDate"></p-calendar>

      <input id="searchCriteria" *ngIf="!dateOfBirthSelected" type="text" pInputText [(ngModel)]="name"/>
      <label for="searchCriteria">Search Criteria</label>
    </span>
  </div>
  
  <button pButton type="button" label="Search for User Profile" (click)="retrieveUsers()"></button>

  <!--p-table-->
  <!-- <ul class="user-item">
    <li *ngFor="let user of users">
      <p>{{user.givenName}}</p>
    </li>
  </ul> -->
  <div class="box" *ngIf="b2cUsers.length > 0"> 
    <p-table [value]="b2cUsers" selectionMode="single" [(selection)]="selectedB2CUser">
      <ng-template pTemplate="header">
          <tr>
              <th>Name</th>
              <!-- <th>Year</th>
              <th>Brand</th>
              <th>Color</th> -->
          </tr>
      </ng-template>
      <ng-template pTemplate="body" let-user>
          <tr>
              <td>
                <p-tableRadioButton [value]="user" ></p-tableRadioButton>
                {{user.firstName}} {{user.lastName}}
              </td>
              <!-- <td>{{car.year}}</td>
              <td>{{car.brand}}</td>
              <td>{{car.color}}</td> -->
          </tr>
      </ng-template>
    </p-table>
    <button *ngIf="b2cUsers.length > 0" pButton type="button" label="Submit Selection" (click)="createEndUserFromRevisedPhi()"></button>
  </div>
</div>
