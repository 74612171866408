export class MassEmailHeader
{
    subject: string;
    recipientCount: number;
    fromAddress: string;
    status:string;
    statusMessage: string;
    sentDate: Date;
    createdDate: Date;
    scheduledOnDate: Date;
    scheduledSendDate: Date;
    scheduledSend: boolean;
    bodyName: string;
    bodyOverridden: boolean;
    bodyTemplateId: string;
    audienceId: number;
    bodyId: number;
    id: number;
}

