<!-- Busy Dialog -->
<p-dialog [modal]="true" [(visible)]="showBusy" [closable]="false" appendTo="body" [showHeader]="false" [style]="{'box-shadow':'none'}"
          [contentStyle]="{'background':'rgba(0,0,0,0)'}">
    <p-progressSpinner [style]="{'background':'rgba(0,0,0,0)','width':'50px','height':'50px'}" animationDuration=".5s" strokeWidth="8" ></p-progressSpinner>
</p-dialog>



<!-- New Diary Dialog -->
    <p-dialog appendTo="body" header="New Diary" [(visible)]="showNewDiaryDialog"
                                [modal]="false" [dismissableMask]="true" [closeOnEscape]="true"
                                [closable]="true" [style]="{width: '50%'}">


        <div class="w12 formgrid grid">
            <div class="field col-12 mt-3" >
                <span class="p-float-label">
                    <input id="shortDesc" type="text" pInputText [(ngModel)]="newDiary.shortDescription">
                    <label for="shortDesc">Short Description</label>
                </span>
            </div>
            <div class="field col-3 mt-3">
                <!-- Assign To -->
                <span class="p-float-label">
                    <p-dropdown id="assignedTo" [(ngModel)]="newDiary.assignedTo" [options]="activeUsers" optionLabel="displayName"
                                [showClear]="true" [autoDisplayFirst]="false" dataKey="userId" ></p-dropdown>
                    <label for="assignedTo">Assigned</label>
                </span>
            </div>
            <div class="field col-3 mt-3">
                <!-- Due Date -->
                <span class="p-float-label">
                    <p-calendar id="dueDate" [(ngModel)]="newDiary.diaryDueDate"></p-calendar>
                    <label for="dueDate">Due Date</label>
                </span>
            </div>

            <div class="field col-3 mt-3">
                <span class="p-float-label">
                    <p-dropdown id="diaryType" [(ngModel)]="newDiary.diaryType" [options]="diaryTypes" optionLabel="typeName"
                                [showClear]="true" [autoDisplayFirst]="false" dataKey="id" ></p-dropdown>
                    <label for="diaryType">Type</label>                    
                </span>
            </div>
            <div class="field col-3">
            </div>

            <div class ="col-12">
                <p-editor [(ngModel)]="newDiary.diaryText" [style]="{'height':'320px'}" ></p-editor>
            </div>
        </div>
        <div class="flex align-items-center justify-content-center">
            <span class="p-buttonset">
                <button pButton pRipple label="Cancel" class="p-button-small p-button-rounded p-button-danger" (click)="cancelButton($event)"></button>
                <button pButton pRipple label="Save" class="p-button-small p-button-rounded p-button-success" (click)="saveButton($event)"></button>
            </span>
        </div>

    </p-dialog>

    <p-table *ngIf="loggedIn" [value]="diaryBuffer.recordsOut" [lazy]="true" (onLazyLoad)="loadMoreDiaries($event)"
          [scrollable]="true" [virtualScroll]="true" dataKey="diaryId" [virtualRowHeight]="diaryCardHeight" scrollHeight="flex" [totalRecords]="diaryBuffer.totalRecords">
          <ng-template   pTemplate="caption">
            <div class="flex align-items-center justify-content-between">
                <span *ngIf="allowStatusSelection">
                    <span *ngFor="let choice of availableStatuses">
                        <p-checkbox  [(ngModel)]="selectedStatuses" name="statuses" [value]="choice.val" [label]="choice.display" (onChange)="updateStatus($event)"></p-checkbox>
                    </span>
                </span>
                <button *ngIf="allowNew" pButton pRipple title="Create New Diary" icon="pi pi-plus" class="p-button-rounded" (click)="createNewDiary($event)"></button>
            </div>
          </ng-template>

          <ng-template pTemplate="body" let-diary>
            <tr [style]="{'min-height': diaryCardHeightInPixels, 'height': diaryCardHeightInPixels, 'max-height': diaryCardHeightInPixels}">
                <td style="min-width: 100%;" class="flex w12">
                    <div class="grid flex w12 mt-2 mb-2" style="width:100%">

                        <div class="col-12 flex" style="width:100%">
                            <div class="grid flex" style="width:100%">
                                <div class="col-3 align-items-center flex">
                                    <p-tag *ngIf="diary.diaryStatus=='A'
                                                    && (diary.diaryDueDate|date:'yyyyMMdd') <= todaysDate
                                                    && (diary.diaryDueDate|date: 'yyyyMMdd') >= fiveDaysAgo
                                                    && diary.diaryType.id == 1" [style]="{'background-color':'green'}">Active</p-tag>
                                    <p-tag *ngIf="diary.diaryStatus=='A'
                                                    && (diary.diaryDueDate|date:'yyyyMMdd') < fiveDaysAgo
                                                    && diary.diaryType.id == 1" [style]="{'background-color':'red'}">Active</p-tag>
                                    <p-tag *ngIf="diary.diaryStatus=='A'
                                                    && (diary.diaryDueDate|date:'yyyyMMdd') > todaysDate
                                                    && diary.diaryType.id == 1">Active</p-tag>
                                    <p-tag *ngIf="diary.diaryStatus=='O'
                                                    && diary.diaryType.id == 1" [style]="{'background-color':'lightgreen'}">New</p-tag>
                                    <p-tag *ngIf="diary.diaryStatus=='C'
                                                    && diary.diaryType.id == 1" [style]="{'background-color':'lightgray'}">Closed</p-tag>
                                    <p-tag *ngIf="diary.diaryType.id==2" [style]="{'background-color':'purple'}">Encounter Created</p-tag>
                                    
                                </div>
                                <div class="col-4 align-items-center flex">
                                    <span *ngIf="diary.diaryStatus != 'C' && diary.diaryDueDate != null">Due: <b>{{diary.diaryDueDate | date: 'shortDate'}}</b></span>
                                    <span *ngIf="diary.diaryStatus == 'C' "><b>{{diary.closeDate | date: 'shortDate'}}</b></span>
                                </div>
                                <div class="col-5 align-items-center flex">
                                    <div *ngIf="showReferenceLinks && diary.referenceType.code==1" class="flex align-items-center">
                                        <span>Enc: <b>{{diary.referenceId}}</b></span>
                                        <span><button pButton pRipple title="Open Encounter" icon="pi pi-external-link"
                                                      class="p-button-text" (click)="loadReference(diary)"></button></span>
                                    </div>
                                    <div *ngIf="showReferenceLinks && diary.referenceType.code==2" class="flex align-items-center">
                                        <span>Mbr: <b>{{diary.referenceId}}</b></span>
                                        <span><button pButton pRipple title="Open Member" icon="pi pi-external-link"
                                                      class="p-button-text" (click)="loadReference(diary)"></button></span>
                                    </div>
                                </div>
                                <div class="col-8  align-items-center flex">
                                    <span *ngIf="getForCurrentUser && diary.referenceType.code==1">Patient: <b>{{diary.target?.patient?.phi.firstName}} {{diary.target?.patient?.phi.lastName}}</b></span>
                                    <span *ngIf="getForCurrentUser && diary.referenceType.code==2">Member: <b>{{diary.target?.firstName}} {{diary.target?.lastName}}</b></span>
                                    <span *ngIf="!getForCurrentUser && diary.diaryDueDate != null">Assigned To: {{diary.assignedTo?.firstName}} {{diary.assignedTo?.lastName}}</span>
                                </div>
                                <div class="col-4">
                                    <div class="grid">
                                        <div class="col-4  align-items-center flex">
                                            <p-dialog appendTo="body" header="{{diary.shortDescription}}" [(visible)]="diaryDialogs[diary.diaryId]"
                                            [modal]="false" [dismissableMask]="true" [closeOnEscape]="true"
                                            [closable]="true" [style]="{width: '50%'}">

                                                <p-editor *ngIf="diaryDialogs[diary.diaryId]" [(ngModel)]="diary.diaryText" [style]="{'height':'320px'}" [readonly]="true">
                                                    <!-- PHI Details Overlay -->
                                                    <p-overlayPanel #phiDeets>
                                                        <app-phi-summary *ngIf="diaryPhi[diary.diaryId] != null" [phi]="diaryPhi[diary.diaryId]" header="MEMBER/PATIENT" [editablePhi]="false"></app-phi-summary>
                                                    </p-overlayPanel>
                                                    <ng-template pTemplate="header">
                                                        <span>Type: <b>{{diary.diaryType.typeName}} &middot;</b></span>
                                                        <span>Created: <b>{{diary.createdBy.firstName}} {{diary.createdBy.lastName}}</b> ({{diary.createDate | date: 'shortDate'}}) &middot;</span>
                                                        <span *ngIf="diary.diaryDueDate != null">Assigned: <b>{{diary.assignedTo?.firstName}} {{diary.assignedTo?.lastName}}</b> &middot;</span>
                                                        <span *ngIf="diary.diaryDueDate != null">Due: <b>{{diary.diaryDueDate | date: 'shortDate'}}</b> &middot;</span>
                                                        <span *ngIf="diary.diaryStatus == 'C' ">Closed: <b> {{diary.closedBy.firstName}} {{diary.closedBy.lastName}} </b> {{diary.closeDate | date: 'shortDate'}})</span>
                                                        <span>Enc: {{diary.referenceId}}</span>
                                                        <span class="ml-3" [style]="{'cursor': 'pointer'}"><i *ngIf="diaryPhi[diary.diaryId] != null" class="pi pi-user" (click)="phiDeets.toggle($event)"></i></span>
                                                    </ng-template>
                                                </p-editor>
                                            </p-dialog>
                                            <button pButton pRipple title="View Diary" icon="pi pi-search" class="p-button-text" (click)="showDiaryDialog(diary)" [pTooltip]="util.shortenDescription(diary.diaryText,255,true)"></button>
                                        </div>
                                        <div *ngIf="diary.diaryStatus!='C'" class="col-4 align-items-center flex">
                                            <button pButton pRipple title="Assign Diary" icon="pi pi-reply" class="p-button-text" (click)="loadForward(diary)" ></button>

                                            <span>
                                            <p-dialog appendTo="body" header="Assign Diary" [(visible)]="forwardDialogs[diary.diaryId]" [modal]="false" [dismissableMask]="true" [closeOnEscape]="true" [closable]="true">
                                                <div class="grid flex w12">

                                                    <div class="col-12 align-items-center justify-content-center mt-3 pt-3">
                                                        <span class="p-float-label">
                                                            <p-dropdown appendTo="body" id="assignTo" [(ngModel)]="diary.assignedTo" [options]="activeUsers" optionLabel="displayName" [showClear]="true" [autoDisplayFirst]="false" ></p-dropdown>
                                                            <label for="assignTo">Assign To</label>
                                                        </span>
                                                    </div>

                                                    <div class="col-12 align-items-center justify-content-center mt-3">
                                                        <span class="p-float-label">
                                                            <p-calendar appendTo="body" id="diaryDueDate" [(ngModel)]="diary.diaryDueDate"></p-calendar>
                                                            <label for="diaryDueDate">Due Date</label>
                                                        </span>
                                                    </div>
                                                    <div class="col-12 align-items-center justify-content-center">

                                                        <span class="p-buttonset">
                                                            <button pButton pRipple label="Cancel" class="p-button-small p-button-rounded p-button-danger" (click)="forwardDialogs[diary.diaryId] = false"></button>
                                                            <button pButton pRipple label="Save" class="p-button-small p-button-rounded p-button-success" (click)="assignDiary($event,diary)"></button>
                                                        </span>

                                                    </div>
                                                </div>
                                            </p-dialog>
                                            </span>
                                        </div>
                                        <div *ngIf="diary.diaryStatus=='A'" class="col-4 align-items-center flex">
                                            <button pButton pRipple title="Close Diary" icon="pi pi-check-circle" class="p-button-text" (click)="closeDiary($event,diary)" ></button>
                                        </div>
                                    </div>
                                </div>


                                <div class="col-12  align-items-center flex">
                                    {{util.shortenDescription(diary.shortDescription,40,true)}}
                                </div>
                                <div class="col-12  align-items-center flex">
                                    <span style="font-size:.8em">Created: <b>{{diary.createDate | date: 'shortDate'}}</b>
                                    by <b>{{diary.createdBy.firstName}} {{diary.createdBy.lastName}}</b></span>
                                </div>
                            </div>
                        </div>
                    </div>
                </td>
            </tr>
          </ng-template>
          <ng-template pTemplate="loadingbody">
            <tr [style]="{'min-height': diaryCardHeightInPixels, 'height': diaryCardHeightInPixels, 'max-height': diaryCardHeightInPixels}">
              <td>
                <div class="flex justify-content-center" style="width: 100%;">
                  <p-progressSpinner strokeWidth="8" animationDuration=".5s"></p-progressSpinner>
                </div>
              </td>
            </tr>
          </ng-template>
        </p-table>
