import { Visit } from "./visit";
import { Source } from "../support/source";
import { Carrier } from "../support/carrier";
import { Network } from "../support/network";
import { Line } from "./line";
import { Provider } from "@angular/core";
import { ProviderLocation } from "../provider/provider_location";

export class InstanceOfCare
{
    serviceBegin:Date;
    serviceEnd:Date;
    instanceId:number;
    memberId:string;
    visits:Visit[];
    source:Source;
    network:Network;
    inNetwork:boolean;
    visitCount:number;
    claimCount:number;
    lineCount:number;
    instanceCost:number;
    generalProcedureDescription:string;

    constructor()
    {
        
    }

    get primaryProviderName(): string
    {
        return this.primaryProviderLocation?.providerName ?? 'UNKNOWN';
    }

    get primaryProviderLocation(): ProviderLocation
    {
        return this.primaryVisit.providerLocation;
    }

    get primaryVisit(): Visit
    {
        //Grab the visit that's at a facility provider
        let facilityVisit: Visit = this.visits.find(v => v.providerLocation.providerIsOrganization);

        if (facilityVisit == null)
        {
            //with no facility visit, take the most expensive one for that day
            return this.visits.sort((a,b) => a.visitCost > b.visitCost ? -1 : 1)[0];
        }
        return facilityVisit;
    }

    public shortDescription(): string
    {
        if (this.generalProcedureDescription == null) return "";
        let splitDesc: string[] = this.generalProcedureDescription.split(",");
        return (splitDesc.length > 0 ? splitDesc[0] : this.generalProcedureDescription).substr(0,25);
    }

    get totalUnitCost() : number
    {
        return this.visits.reduce((a,b) => a + b.claims.reduce((a,b) => a + b.lines.reduce((a,b) => a + b.unitCost, 0),0),0);
    }
    get totalCost() : number
    {
        return this.visits.reduce((a,b) => a + b.claims.reduce((a,b) => a + b.lines.reduce((a,b) => a + b.cost, 0),0),0);
    }
    get totalUnits() : number
    {
        return this.visits.reduce((a,b) => a + b.claims.reduce((a,b) => a + b.lines.reduce((a,b) => a + b.units, 0),0),0);
    }
    get totalLines() : number
    {
        return this.visits.reduce((a,b) => a + b.claims.reduce((a,b) => a + b.lines.length,0),0);
    }

    get lines() : Line[]
    {
        return this.visits.reduce((a,b) => a.concat(b.claims.reduce((a,b) => a.concat(b.lines),[])),[]);
    }
}

