<div  class="grid">
  <div class="col-12">
    <div class="category-header">EXPLANATION OF BENEFITS
      <span *ngIf="MemberBenefits == null" style="color:#CC0000">( Searching )</span>
      <span *ngIf="( MemberBenefits != null ) && ( MemberBenefits.hemiBenefitList.length == 0 ) && ( MemberBenefits?.planDetails?.eligibilityStatus == '' )" style="color:#CC0000">[ Not a Harvard Pilgrim plan ]</span>
      <span *ngIf="( MemberBenefits != null ) && ( MemberBenefits.hemiBenefitList.length == 0 ) && ( MemberBenefits?.planDetails?.eligibilityStatus != '' )" style="color:#CC0000">[ {{MemberBenefits.planDetails.eligibilityStatus}} ]</span>
      <span *ngIf="( MemberBenefits != null ) && ( MemberBenefits.hemiBenefitList.length != 0 ) && ( ! MemberBenefits?.active )" style="color:#CC0000;font-weight:bolder;">[ PLAN NOT ACTIVE ]<br>{{MemberBenefits?.planDetails?.eligibilityStatus}}</span>
    </div>
  </div>
  <div *ngIf="( MemberBenefits != null ) && ( MemberBenefits.hemiBenefitList.length != 0 ) && ( ! MemberBenefits?.active )" style="color:#CC0000;font-weight:bolder;">[ PLEASE SEND MEMBER ID TO TOM ]<br>{{MemberBenefits?.planDetails?.eligibilityStatus}}</div>
  
  <div *ngIf="( MemberBenefits != null ) && ( MemberBenefits.hemiBenefitList.length != 0 )" class="col-12">

    <table class="data-table">
      <tr>
        <td class="field-data" style="border-bottom:1px solid black">&nbsp;</td>
        <td class="field-data" style="border-bottom:1px solid black" align="center" colspan="2">Deductible</td>
        <td class="field-data" style="border-bottom:1px solid black" align="center" colspan="2">Out of Pocket</td>
      </tr>
      <tr>
        <td class="field-data" style="border-bottom:1px solid black" align="center"><b>Individual</b></td>
        <td class="field-data" style="border-bottom:1px solid black" align="right">Remaining</td>
        <td class="field-data" style="border-bottom:1px solid black" align="right">Total</td>
        <td class="field-data" style="border-bottom:1px solid black" align="right">Remaining</td>
        <td class="field-data" style="border-bottom:1px solid black" align="right">Total</td>
      </tr>
      <tr *ngFor="let Tier of MemberBenefits.benefits.inNetwork.individual | keyvalue">
        <td class="field-data">{{MemberBenefits.benefits.inNetwork.individual[Tier.key].description}}</td>
        <td class="field-data" align="right">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{MemberBenefits.benefits.inNetwork.individual[Tier.key].deductibleRemaining | number: '1.2-2'}}</td>
        <td class="field-data" align="right">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{MemberBenefits.benefits.inNetwork.individual[Tier.key].deductible | number: '1.2-2'}}</td>
        <td class="field-data" align="right">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{MemberBenefits.benefits.inNetwork.individual[Tier.key].outOfPocketRemaining | number: '1.2-2'}}</td>
        <td class="field-data" align="right">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{MemberBenefits.benefits.inNetwork.individual[Tier.key].outOfPocket | number: '1.2-2'}}</td>
      </tr>
      <tr>
        <td class="field-data" style="border-bottom:1px solid black; border-top:1px solid black" align="center"><b>Family</b></td>
        <td class="field-data" style="border-bottom:1px solid black; border-top:1px solid black" align="right">&nbsp;</td>
        <td class="field-data" style="border-bottom:1px solid black; border-top:1px solid black" align="right">&nbsp;</td>
        <td class="field-data" style="border-bottom:1px solid black; border-top:1px solid black" align="right">&nbsp;</td>
        <td class="field-data" style="border-bottom:1px solid black; border-top:1px solid black" align="right">&nbsp;</td>
      </tr>
      <tr *ngFor="let Tier of MemberBenefits.benefits.inNetwork.family | keyvalue">
        <td class="field-data">{{MemberBenefits.benefits.inNetwork.family[Tier.key].description}}</td>
        <td class="field-data" align="right">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{MemberBenefits.benefits.inNetwork.family[Tier.key].deductibleRemaining | number: '1.2-2'}}</td>
        <td class="field-data" align="right">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{MemberBenefits.benefits.inNetwork.family[Tier.key].deductible | number: '1.2-2'}}</td>
        <td class="field-data" align="right">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{MemberBenefits.benefits.inNetwork.family[Tier.key].outOfPocketRemaining | number: '1.2-2'}}</td>
        <td class="field-data" align="right">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{MemberBenefits.benefits.inNetwork.family[Tier.key].outOfPocket | number: '1.2-2'}}</td>
      </tr>
    </table>

    <div class="col-12">
      <div class="category-header">COVERAGES</div>
    </div>
    <table class="data-table">
      <tr *ngFor="let ServiceType of MemberBenefits.hemiBenefitList">
        <td class="field-data" style="width:50%;font-weight:lighter;white-space:pre-wrap">{{ServiceType.description}}</td>
        <td class="field-data" style="white-space:pre-wrap;">{{ServiceType.summary}}</td>
      </tr>
    </table>

    <div class="col-12">
      <div class="category-header">PLAN DETAILS
        <!--<span *ngIf="MemberBenefits == null" style="color:#ff0000">( Searching )</span>-->
      </div>
    </div>
    <table class="data-table">
      <tr *ngIf="(MemberBenefits.planDetails.startDate != null) && (MemberBenefits.planDetails.endDate != null)">
        <td class="field-data" style="width:50%;font-weight:lighter;white-space:pre-wrap">Plan Term</td>
        <td class="field-data" style="white-space:pre-wrap">{{MemberBenefits.planDetails.startDate.substr(4,2)}}/{{MemberBenefits.planDetails.startDate.substr(6,2)}}/{{MemberBenefits.planDetails.startDate.substr(0,4)}} -
          {{MemberBenefits.planDetails.endDate.substr(4,2)}}/{{MemberBenefits.planDetails.endDate.substr(6,2)}}/{{MemberBenefits.planDetails.endDate.substr(0,4)}}</td>
      </tr>
      <tr *ngIf="MemberBenefits.planDetails.type != null">
        <td class="field-data" style="width:50%;font-weight:lighter;white-space:pre-wrap">Type</td>
        <td class="field-data" style="white-space:pre-wrap">{{MemberBenefits.planDetails.type}}</td>
      </tr>
      <tr *ngIf="MemberBenefits.planDetails.description != null">
        <td class="field-data" style="width:50%;font-weight:lighter;white-space:pre-wrap">Description</td>
        <td class="field-data" style="white-space:pre-wrap">{{MemberBenefits.planDetails.description}}</td>
      </tr>
      <tr *ngIf="MemberBenefits.planDetails.coverageBasis != null">
        <td class="field-data" style="width:50%;font-weight:lighter;white-space:pre-wrap">Coverage Basis</td>
        <td class="field-data" style="white-space:pre-wrap">{{MemberBenefits.planDetails.coverageBasis}}</td>
      </tr>
      <tr *ngIf="MemberBenefits.planDetails.eligibilityStatus != null">
        <td class="field-data" style="width:50%;font-weight:lighter;white-space:pre-wrap">Eligibility Status</td>
        <td class="field-data" style="white-space:pre-wrap">{{MemberBenefits.planDetails.eligibilityStatus}}</td>
      </tr>
      <tr *ngIf="MemberBenefits.primaryCareProvider.firstName != null">
        <td class="field-data" style="width:50%;font-weight:lighter;white-space:pre-wrap">Primary Care</td>
        <td class="field-data" style="white-space:pre-wrap">{{MemberBenefits.primaryCareProvider.firstName}} {{MemberBenefits.primaryCareProvider.lastName}}
          <br>{{MemberBenefits.primaryCareProvider.address}}
          <br>{{MemberBenefits.primaryCareProvider.city}}, {{MemberBenefits.primaryCareProvider.state}} {{MemberBenefits.primaryCareProvider.zipCode}}
          <br>Tel: {{(MemberBenefits.primaryCareProvider.telephone??"").substr(0,3)}}-{{(MemberBenefits.primaryCareProvider.telephone??"").substr(3,3)}}-{{(MemberBenefits.primaryCareProvider.telephone??"").substr(6)}}</td>
      </tr>
    </table>
  </div>
</div>