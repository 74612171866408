import { ClosureReason } from "../encounter/closure_reason";
import { Encounter } from "../encounter/encounter";
import { EncounterProvider } from "../encounter/encounter_provider";
import { EncounterStatus } from "../encounter/encounter_status";
import { Transaction } from "../finance/transaction";

export class ConfirmedInstance
{
    instanceId: number;
    lineIds: number[];
    status: number;
    closureReason: number;
    useInstanceCost: boolean;
    costOverride: number;
    rewardRuleId: number;
    useManualReward: boolean;
    manualRewardAmount: number;
    issueReward: boolean;

    constructor()
    {
        this.status = EncounterStatus.STATUS_CONFIRMED; //Default to confirmed
        this.closureReason = undefined;

    }
}

export class ConfirmedEncounter
{
    encounter: Encounter; 
    confirmedProvider: EncounterProvider;
    rewardTransaction: Transaction;
}