import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Phi } from 'src/app/model/phi/phi';
import { Plan } from 'src/app/model/phi/plan';
import { Company } from 'src/app/model/support/company';
import { ReferenceCode } from 'src/app/model/support/reference_code';

@Component( {
  selector: 'app-member-details-page',
  templateUrl: './member-details-page.component.html',
  styleUrls: [ './member-details-page.component.css' ]
} )
export class MemberDetailsComponent
  implements OnInit
{

  @Input() currentMember: Phi;
  // @Input() currentMemberSubscriber: Phi;

  @Output() currentMemberChange: EventEmitter<Phi> = new EventEmitter<Phi>();
  @Output() optOutStatusChange: EventEmitter<boolean> = new EventEmitter<boolean>()

  notesRefCode = ReferenceCode.create(ReferenceCode.TYPE_PHI);

  constructor(private cdr:ChangeDetectorRef)
  {
    
  }

  ngOnInit(): void
  {
  }

  toggleOptOut($flag)
  {
    this.optOutStatusChange.emit($flag);
  }

  currentMemberChanged(phi: Phi)
  {
    this.currentMember = phi;
    this.currentMemberChange.emit(phi);
  }
  // memberCompanyChanged(company:Company)
  // {
  //   this.currentMember.company = company;
  //   //Change detection
  //   this.currentMember = Object.assign(new Phi(),this.currentMember);
  // }

  // planUpdated(plan:Plan)
  // {
  //   this.currentMember.plans = [... this.currentMember.plans.filter(p => p.memberId != plan.memberId),plan];
  //   this.memberUpdated.emit(this.currentMember);
  // }
}
