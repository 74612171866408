export class Task
{
    internalId: number;
    externalId: string;
    status: number;
    type: number;
    created: Date;
    completed: Date;
    canceled: Date;
    assignedUserId: number;
    queue: string;
    durationSeconds: number;
    attributes: { [Name: string]: string};
}