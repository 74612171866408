export class SearchField<T>
{
    value: T;
    key: string;
    label: string;
    order: number;
    options: {label: string, value:string}[];
    selectedOptions: {label: string, value:string}[];
    fieldType: string;

    constructor(options:
        {
            value?: T;
            key?: string;
            label?: string;
            order?: number;
            type?: string;
            options?: {label: string, value:string} [];
        } = {})
        {
            this.value=options.value;
            this.key=options.key;
            this.label=options.label;
            this.options = options.options;
            this.order=options.order === undefined ? 1 : options.order;
            this.fieldType = options.type;
          }
    public typeIsString() : boolean
    {
        return  this.fieldType == "string";
    }

    public typeIsDate() : boolean
    {
        return this.fieldType == "date";
    }

    public typeIsSingleChoice() : boolean
    {
        return this.fieldType == "singleChoice";
    }

    public typeIsMultiChoice() : boolean
    {
        return this.fieldType == "multiChoice";
    }

    public typeIsCheck(): boolean
    {
        return this.fieldType == "check";
    }
}

