import { Encounter } from "../encounter/encounter";
import { Company } from "../support/company";
import { User } from "../util/user";
import { Check } from "./check";
import { Transaction } from "./transaction";

export class Invoice
{
    invoiceId: number;
    invoiceRule: InvoiceRule;
    invoiceDate: Date;
    activityBegin: Date;
    activityEnd: Date;
    eeCount: number;
    adminFeeOverride: number;
    rewardFeeOverride: number;
    overrideAdminFee: boolean;
    overrideRewardFee: boolean;
    percentSavingsAmt: number;
    pepmAmt: number;
    fixedAmt: number;
    adminFeeAmount: number;
    grossSavings: number; 
    rewardAmount: number;
    incentivesAmount: number;
    totalAmount: number;
    rewardChecksBeingInvoiced: Check[];
    incentiveTransactions: Transaction[];
    rewardEncounters: Encounter[];
    company: Company;
    usePEPM: boolean;
    usePercentSavings: boolean;
    useFixedAmount: boolean;
    modifiedBy: User;
    closed: boolean;
    poNumber: string;

    asUpdate(): InvoiceUpdate
    {
        let retVal: InvoiceUpdate = new InvoiceUpdate;

        retVal.ruleId = this.invoiceRule.ruleId;
        retVal.activityBegin = this.activityBegin;
        retVal.activityEnd = this.activityEnd;
        retVal.adminFee = this.adminFeeOverride;
        retVal.closed = this.closed;
        retVal.employeeCount = this.eeCount;
        retVal.invoiceDate = this.invoiceDate;
        retVal.overrideAdminFee = this.overrideAdminFee;
        retVal.overrideRewardFee = this.overrideRewardFee;
        retVal.rewardFee = this.rewardFeeOverride;
        retVal.poNumber = this.poNumber;
        
        //No transaction ids as an update
        return retVal;
    }

    asInsert(): InvoiceInsert
    {
        let retVal: InvoiceInsert =  Object.assign(new InvoiceInsert(), this.asUpdate());

        retVal.transactions = this.incentiveTransactions.map(t => t.transactionId);
        
        return retVal;
    }
}

export class InvoiceUpdate
{
    ruleId: number; 
    activityBegin: Date; 
    activityEnd: Date;
    invoiceDate: Date;
    employeeCount: number;
    overrideAdminFee: boolean;
    adminFee: number;
    overrideRewardFee: boolean;
    rewardFee: number;
    closed: boolean;
    poNumber: string;
}

export class InvoiceInsert extends InvoiceUpdate
{
    transactions: number[]; 
}

export class InvoiceRule
{
    ruleId: number;
    ruleName: string;
    pepmFee: number;
    percentSavingsFee: number;
    fixedFee: number;
    useGreaterOfFees: boolean;
    modifiedBy: User;
    netOfRewards: boolean;
    active: boolean;
    company: Company;

    get percentOfSavingsAsWholeNumber() : number
    {
        return (this.percentSavingsFee ?? 0) * 100;
    }

    set percentOfSavingsAsWholeNumber(val: number)
    {
        this.percentSavingsFee = val/100;
    }

    asUpdate() : InvoiceRuleUpdate
    {
        let iru = Object.assign(new InvoiceRuleUpdate,this);
        return iru;
    }

    asInsert() : InvoiceRuleInsert
    {
        let iri = Object.assign(new InvoiceRuleInsert(), this.asUpdate());
        iri.companyId = this.company.companyId;

        return iri;
    }
    
}

export class InvoiceRuleUpdate
{
    ruleName: string;
    pepmFee: number;
    percentSavingsFee: number;
    fixedFee: number;
    useGreaterOfFees: boolean;
    netOfRewards: boolean;
    active: boolean;
}

export class InvoiceRuleInsert extends InvoiceRuleUpdate
{
    companyId: number;
}