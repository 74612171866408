<p-overlayPanel appendTo="body" #phoneNumberEditor>
    <div class="form-grid grid w12">
        <div class="col-4 field">
            <span class="p-float-label">
                <p-inputMask id="phone" [(ngModel)]="provider.providerLocation.phoneNumber" mask="(999) 999-9999? x*****"></p-inputMask>
                <label for="phone">Phone</label>
            </span>
        </div>
        <div class="col-4 field">
            <span class="p-float-label">
                <p-inputMask id="fax" [(ngModel)]="provider.providerLocation.faxNumber" mask="(999) 999-9999? x*****"></p-inputMask>
                <label for="fax">Fax</label>
            </span>
        </div>
        <div class="col-4 field">
            <p-button icon="pi pi-check" class="p-button-sm" (click)="commitPhone(provider.providerLocation); phoneNumberEditor.hide()"></p-button>
            <p-button icon="pi pi-times" class="p-button-sm" (click)="phoneNumberEditor.hide()"></p-button>
        </div>
    </div>
</p-overlayPanel>

<p-overlayPanel appendTo="body" [dismissable]="false" #noteEditor>
    <div style="height:400px; width:400px;">
        <app-note-scroller *ngIf="noteEditor.overlayVisible" title="Provider Location Notes" [referenceCode]="noteScrollerType" [relatedTo]="provider.providerLocation.practiceLocation.id" rowBufferLen="2"></app-note-scroller>
    </div>
</p-overlayPanel>

<p-overlayPanel appendTo="body" #pseudoInstanceViewer>
    <div *ngIf="provider.modelProcedures != null && provider.modelProcedures.length > 0" style="width: 400px;">
        <p-table [value]="provider.modelProcedures">
            <ng-template pTemplate="header">
                <tr>
                    <th>Code</th>
                    <th>Description</th>
                    <th>Cost</th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-mp>
                <tr>
                    <td>{{mp.procedure.code}}</td>
                    <td [pTooltip]="mp.procedure.description">{{util.shortenDescription(mp.procedure.displayText,50)}}</td>
                    <td>{{mp.cost | currency}}</td>
                </tr>
            </ng-template>
            <ng-template pTemplate="footer">
                <tr>
                    <td colspan=2>Total</td>
                    <td>
                        {{provider.modelCost | currency}}
                    </td>
                </tr>
            </ng-template>
        </p-table>
        <p-divider></p-divider>
    </div>
    <div class="mt-3">
        Manual Cost Override: {{provider.costOverride | currency}}
    </div>
</p-overlayPanel>


<p-card >
    <ng-template pTemplate="title">
        <span>{{provider.providerLocation.practiceName}}</span>
        <span *ngIf="provider.providerLocation.providerIsOrganization && provider.providerLocation.providerName != provider.providerLocation.practiceName"> - {{provider.providerLocation.providerName}}</span>
        <span>
            <button type="button" [disabled]="provider.providerLocation.conversionLocation" pButton pRipple class="p-button-text p-button-rounded p-button-plain" icon="pi pi-pencil" (click)="practiceEditorVisible = true"></button>
            <!-- Pracice Editor Dialog -->
            <p-dialog appendTo="body" header="Edit Practice" [(visible)]="practiceEditorVisible" [style] ="{width: '900px', 'max-height': '800px' }">
                <!-- <app-practice-editor [(modelData)]="result.practice"></app-practice-editor> -->
                <app-practice-editor *ngIf="practiceEditorVisible" [modelId]="provider.providerLocation.ein" (practiceChange)="practiceUpdate($event)"></app-practice-editor> 
            </p-dialog>
        </span>
    </ng-template>
    <ng-template pTemplate="subtitle">
        Ein: {{provider.providerLocation.ein}}<br>Npi: {{provider.providerLocation.npi}}
    </ng-template>
    <ng-template pTemplate="content">
        <div class="grid">
        <div clas="col-12 justify-content-center align-items-center">
            <div *ngIf="showProviderInfo">
                <span>Provider: {{provider.providerLocation.providerName}}</span>
                <span class="ml-3" *ngIf="provider.providerLocation.providerIsOrganization"><p-tag>Facility</p-tag></span>
                <span class="ml-3" *ngIf="!provider.providerLocation.providerIsOrganization"><p-tag>Professional</p-tag></span>
            </div>
        </div>
        <div class="col-12 justify-content-center align-items-center">
            <div class="flex grid">
                <div class="col-5">
                    <div>
                        <span>
                            {{provider.providerLocation.location.street1}}<br>
                            {{provider.providerLocation.location.street2}}<br *ngIf="provider.providerLocation.location.street2 != null && provider.providerLocation.location.street2.length > 0">
                            {{provider.providerLocation.location.city}}, {{provider.providerLocation.location.state}} {{provider.providerLocation.location.zip}} 
                        </span>
                    </div>
                </div>
                <div class="col-5">
                    <div *ngIf="showProviderInfo">
                        <div>Phone: {{provider.providerLocation.phoneNumber}}</div>
                        <div>Fax: {{provider.providerLocation.faxNumber}}</div>
                    </div>
                </div>
                <div class="col-2 justify-content-center align-items-center">
                    <div class="flex flex-column">
                        <div>
                            <button type="button" [disabled]="provider.providerLocation.conversionLocation" pButton pRipple class="p-button-text p-button-rounded p-button-plain" icon="pi pi-phone" (click)="phoneNumberEditor.toggle($event)"></button>
                        </div>
                        <div>
                            <button type="button" [disabled]="provider.providerLocation.conversionLocation" pButton pRipple class="p-button-text p-button-rounded p-button-plain" icon="pi pi-file-o" (click)="noteEditor.toggle($event)"></button>
                        </div>
                        <div>
                            <button type="button" pButton pRipple class="p-button-text p-button-rounded p-button-plain" icon="pi pi-list" (click)="pseudoInstanceViewer.toggle($event)"></button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    </ng-template>
    <ng-template pTemplate="footer">
        {{provider.cost | currency}}
    </ng-template>
</p-card>


