import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TcemsService } from 'src/app/api/tcems.service';
import { SinglePanelComponent } from 'src/app/base/single-panel/single-panel.component';
import { Practice } from 'src/app/model/provider/practice';
import { Provider } from 'src/app/model/provider/provider';
import { ProviderLocation } from 'src/app/model/provider/provider_location';
import { PagingSpec } from 'src/app/model/util/resultpaging';
import { Location } from 'src/app/model/provider/location';
import { ReferenceCode } from 'src/app/model/support/reference_code';

@Component({
  selector: 'app-practice-editor',
  templateUrl: './practice-editor.component.html',
  styleUrls: ['./practice-editor.component.scss']
})
export class PracticeEditorComponent 
extends SinglePanelComponent<Practice>
{


  practice: Practice;
  noteScrollerType=ReferenceCode.create(ReferenceCode.TYPE_PRACTICE);

  @Output() practiceChange:  EventEmitter<Practice> = new EventEmitter<Practice>();


  constructor(private tcems:TcemsService, private cdr: ChangeDetectorRef) 
  { 
    super();
  }

  // ngOnInit(): void 
  // {
  //   super.ngOnInit();
  // }
  protected loadPanelFromModelData() 
  {
    //Go and get providers and provider locations from the api
    this.tcems.getProvidersForPractice({ein:this.modelData.ein,pageResults:false,pagingSpec:new PagingSpec()}).toPromise()
    .then
    (
      providers =>
      {
        this.modelData.providers = providers.records.map(p => Object.assign(new Provider(), p));
      }
    )

    this.tcems.getProviderLocationsForPractice({ein:this.modelData.ein,pageResults:false,pagingSpec:new PagingSpec()}).toPromise()
    .then
    (
      providerLocations =>
      {
        this.modelData.providerLocations = providerLocations.records.map(p => Object.assign(new ProviderLocation(), p));
      }
    )

  }


  protected createNewEmptyObject(): Practice 
  {
    return new Practice();
  }

  protected loadModelDataFromId(): Promise<Practice> 
  {
      //Get our practice from the supplied ein
      return this.tcems.getPractice(this.modelId).toPromise();
  }

  setPracticeName(aliasId: number)
  {
    this.modelData.practiceName = this.modelData.aliases.find(a => a.aliasId == aliasId).aliasName;
    
    //Persist
    this.tcems.updatePractice({practice:this.modelData.toUpdate()}).toPromise().then
    (
      practice=>
      {
        this.practiceChange.emit(this.modelData);
        this.cdr.detectChanges();
      }
    );
  }

  
}
