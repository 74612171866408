import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { MessageService } from 'primeng/api';
import { Category } from '../model/claim/category';
import { ProcedureHierarchy } from '../model/claim/procedure_hierarchy';
import { AppointmentStatus } from '../model/encounter/appointment_status';
import { ClosureReason } from '../model/encounter/closure_reason';
import { EncounterType } from '../model/encounter/encounter';
import { EncounterStatus } from '../model/encounter/encounter_status';
import { CheckingAccount } from '../model/finance/checking_account';
import { TransactionType } from '../model/finance/transaction';
import { PlanType } from '../model/phi/plan';
import { CompanyType } from '../model/support/company_type';
import { Source } from '../model/support/source';
import { User, UserState } from '../model/util/user';
import { selectActiveUsers, selectAppointmentStatuses, selectCaptives, selectCategories, selectCheckingAccounts, selectCompanyTypes, selectCurrentUser, selectEncounterClosureReasons, selectEncounterStatuses, selectEncounterType, selectPlanType, selectProcHierarchy, selectSources, selectTransactionType, selectUserState } from '../state/app.state';
import { Captive } from '../model/support/captive';

@Injectable({
  providedIn: 'root'
})
export class TcemsUtilitiesService {


  currentUser$ = this.store.select(selectCurrentUser);
  currentUser : User = null;

  currentUserState$ = this.store.select(selectUserState);
  currentUserState : UserState;

  encounterStatuses$ = this.store.select(selectEncounterStatuses);
  encounterStatuses: EncounterStatus[];

  appointmentStatuses$ = this.store.select(selectAppointmentStatuses);
  appointmentStatuses: AppointmentStatus[];

  closureReasons$ = this.store.select(selectEncounterClosureReasons);
  closureReasons: ClosureReason[];

  sources$ = this.store.select(selectSources);
  sources : Source[];

  categories$ = this.store.select(selectCategories);
  categories : Category[];

  transactionTypes$ = this.store.select(selectTransactionType);
  transactionTypes : TransactionType[];


  checkingAccounts$ = this.store.select(selectCheckingAccounts);
  checkingAccounts : CheckingAccount[];


  encounterTypes$ = this.store.select(selectEncounterType);
  encounterTypes : EncounterType[];
  

  planTypes$ = this.store.select(selectPlanType);
  planTypes : PlanType[];

  activeUsers$ = this.store.select(selectActiveUsers);
  activeUsers: User[] = [];

  activeNurses: User[] = [];

  companyTypes$ = this.store.select(selectCompanyTypes);
  companyTypes : CompanyType[] = null;

  procHierarchy$ = this.store.select(selectProcHierarchy);
  procHierarchy : {cpt:ProcedureHierarchy, hcpcs:ProcedureHierarchy} = null;

  captives$ = this.store.select(selectCaptives);
  captives : Captive[] = [];


  constructor(private store: Store, private msg: MessageService) 
  { 
    this.currentUser$.subscribe(
      u => 
      {
        this.currentUser = Object.assign(new User(),u); 

      });
  
    this.currentUserState$.subscribe(
      u => 
      {
        if (u==null) return;

        this.currentUserState = Object.assign(new UserState(),u);
      });

      this.encounterStatuses$.subscribe(es => {this.encounterStatuses = [...es]; this.encounterStatuses.sort((a,b) => a.statusDescription > b.statusDescription ? 1 : -1)}); //[new EncounterStatus(), ...es]);
      this.sources$.subscribe(s => {this.sources = [...s]; this.sources.sort((a,b) => a.sourceName > b.sourceName ? 1 :-1)});
      this.transactionTypes$.subscribe( t=> {this.transactionTypes = [...t]; this.transactionTypes.sort((a,b) => a.transactionDescription > b.transactionDescription ? 1 :-1)});
      this.checkingAccounts$.subscribe( a => {this.checkingAccounts = [...a]; this.checkingAccounts.sort ((x,y) => x.accountName > y.accountName ? 1 : -1)});
      this.closureReasons$.subscribe( cr => {this.closureReasons = [...cr]; this.closureReasons.sort((a,b) => a.reasonDescription > b.reasonDescription ? 1 : -1)}); //[new ClosureReason(), ...cr]);
      this.appointmentStatuses$.subscribe( aps => {this.appointmentStatuses = [...aps]; this.appointmentStatuses.sort((a,b) => a.statusDescription > b.statusDescription ? 1 :-1)});
      this.encounterTypes$.subscribe( et => {this.encounterTypes = [...et]; this.encounterTypes.sort((a,b) => a.encounterType > b.encounterType ? 1 :-1)});
      this.planTypes$.subscribe( pt => {this.planTypes = [...pt]; this.planTypes.sort((a,b) => a.planType > b.planType ? 1 :-1)});
      this.categories$.subscribe (c => {this.categories = [...c]; this.categories.sort((a,b) => a.categoryName > b.categoryName ? 1 : -1)});
      this.activeUsers$.subscribe 
      (
        au => 
        {
          this.activeUsers = [...au.map(u => Object.assign(new User(), u))]; 
          this.activeUsers.sort((a,b) => a.firstName+a.lastName > b.firstName+a.lastName ? 1 : -1);
          this.activeNurses = [...au.filter(u => u.labels.map(l => l.label).includes("Nurse")).map(u => Object.assign(new User(), u))]; 
          this.activeNurses.sort((a,b) => a.firstName+a.lastName > b.firstName+a.lastName ? 1 : -1)
        }
      );
      this.procHierarchy$.subscribe (ph => {this.procHierarchy = ph});
      this.captives$.subscribe (c => {this.captives = c});
      this.companyTypes$.subscribe (ct => {this.companyTypes = [...ct]; this.companyTypes.sort((a,b) => a.typeDescription > b.typeDescription ? 1 : -1)});   
  }

  
  displayError(summary: string, detail:string,sticky: boolean = false, closeable = true)
  {
    this.msg.add({key:sticky?'sticky':'normal', sticky:sticky, closable:closeable, severity:'error', summary:summary, detail:detail});    
  }

  displayWarn(summary: string, detail:string,sticky: boolean = false, closeable = true)
  {
    this.msg.add({key:sticky?'sticky':'normal', sticky:sticky, closable:closeable, severity:'warn', summary:summary, detail:detail});
  }

  displaySuccess(summary: string, detail:string,sticky: boolean = false, closeable = true)
  {
    this.msg.add({key:sticky?'sticky':'normal',sticky:sticky, closable:closeable, severity:'success', summary:summary, detail:detail});
  }

  clearMessages()
  {
    //clear message service
    this.msg.clear();
  }


shortenDescription(str: string, len: number, stripHTML:boolean = false): string
  {
    if (str == null || str.length == 0) return "";
    if ( stripHTML ) str = str.replace(/(<([^>]+)>)/ig, '');
    if (str.length <= len) {
      // Message is less than limit
      return str;
    }
    if ((str.substr(len - 1, 1) === ' ') || (str.substr(len, 1) === ' ')) {
      // The break is a space, so just truncate and add '...'
      return str.substr(0, len) + '...';
    }
    // Look for the previous space and break there
    for (let i = len; i >= 0; i--) {
      if (str.substr(i, 1) === ' ') {
        return str.substr(0, i ) + '...';
      }
    }
    // No spaces, so just truncate
    return str.substr(0, len) + '...';
  }
}
