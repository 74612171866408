

<p-table [value]="procedureResults" dataKey="practice.ein" sortField="practice.practiceName" > 
    <ng-template pTemplate="header">
        <tr>
            <th style="width: 3rem"></th>
            <th pSortableColumn="practice.practiceName">Practice <p-sortIcon field="practice.practiceName"></p-sortIcon></th>
            <th style="width: 3rem"></th>
            <th pSortableColumn="closestLocation.distance.length">Locations  <p-sortIcon field="closestLocation.distance.length"></p-sortIcon></th>
            <th pSortableColumn="practiceCost">Suggested Price <p-sortIcon field="practiceCost"></p-sortIcon></th>
        </tr>
    </ng-template>
    <ng-template pTemplate="body" let-result let-expanded="expanded">
        <tr>
            <td>
                <button type="button" pButton pRipple [pRowToggler]="result" class="p-button-text p-button-rounded p-button-plain" [icon]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"></button>
            </td>
            <td><span>{{result.practice.practiceName}}</span><span><p-tag *ngIf="result.tier != null && result.tier >0">Tier {{result.tier}}</p-tag></span></td>

            <td>
                <button type="button" pButton pRipple class="p-button-text p-button-rounded p-button-plain" icon="pi pi-pencil" (click)="showPracticeEditor[result.practice.ein] = true"></button>
                <!-- Pracice Editor Dialog -->
                <p-dialog appendTo="body" header="Edit Practice" [(visible)]="showPracticeEditor[result.practice.ein]" [style] ="{width: '900px', 'max-height': '800px' }">
                    <!-- <app-practice-editor [(modelData)]="result.practice"></app-practice-editor> -->
                    <app-practice-editor *ngIf="showPracticeEditor[result.practice.ein]" [modelId]="result.practice.ein"></app-practice-editor>
                </p-dialog>
            </td>
            <td>
                <app-location-tag *ngFor="let location of result.locations" [location]="location"></app-location-tag>
            </td>
            <td>{{result.practiceCost | currency }}</td>
        </tr>
    </ng-template>
    <ng-template pTemplate="rowexpansion" let-result>
        <tr>
            <td colspan="5">
                <div>
                    <p-table [value]="result.procedureBasedResults" dataKey="procedure.procId" sortField="procedure.code">
                        <ng-template pTemplate="header">
                            <tr>
                                <th pSortableColumn="procedure.code">Procedure <p-sortIcon field="procedure.code"></p-sortIcon></th>
                                <th pSortableColumn="procedure.description">Description <p-sortIcon field="procedure.description"></p-sortIcon></th>
                                <th>Min</th>
                                <th>Max</th>
                                <th>Avg</th>
                                <th>Count</th>
                                <th pSortableColumn="median">Suggested Cost <p-sortIcon field="median"></p-sortIcon></th>
                                <th style="width: 3rem"></th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-procResult>
                            <tr>
                                <td>{{procResult.procedure.code}}</td>
                                <td><span [pTooltip]="procResult.procedure.description">{{procResult.procedure.displayText}}</span></td>
                                <td>{{procResult.min | currency }}</td>
                                <td>{{procResult.max | currency }}</td>
                                <td>{{procResult.mean | currency }}</td>
                                <td>{{procResult.n}}</td>
                                <td>{{procResult.median | currency }}</td>
                                <td>
                                    <button type="button" pButton pRipple class="p-button-text p-button-rounded p-button-plain" icon="pi pi-table" (click)="loadLines(procResult); showDialog(procResult.procedure.procedureId);"></button>
                                    <p-dialog appendTo="body" header="Select a Line"  [(visible)]="showLineSelector[procResult.procedure.procedureId]" [style] ="{width: '500px', 'max-height': '400px' }">
                                        <div class="flex flex-column">
                                            <app-linelist [lines]="lines[procResult.procedure.procedureId]"  (chosenLine)="lineChosen(result,procResult.procedure.procedureId,$event)" ></app-linelist>
                                            <div class="flex grid justify-content-center">
                                                <div class="p-col">
                                                    <button type="button" pButton label="Close" icon="pi pi-times" (click)="closeLineDialog(procResult.procedure.procedureId)"></button>
                                                </div>
                                            </div>
                                        </div>
                                    </p-dialog>
                                </td>
                            </tr>
                        </ng-template>
                    </p-table>
                </div>
            </td>
        </tr>
    </ng-template>
</p-table>
            