import { ProviderLocation } from "../provider/provider_location";
import { User } from "../util/user";
import { AppointmentStatus } from "./appointment_status";

export class Appointment
{
    id: number;
    providerLocation: ProviderLocation;
    appointmentDate: Date;
    status: AppointmentStatus;
    modifiedBy: User;

    asUpdate(): AppointmentUpdate
    {
        let au:AppointmentUpdate = new AppointmentUpdate();
        au.providerLocationId = this.providerLocation.id;
        au.appointmentDate = this.appointmentDate;
        au.statusId = this.status.statusCode;

        return au;
    }
}

export class AppointmentUpdate
{
    providerLocationId: number;
    appointmentDate: Date;
    statusId: number;
}