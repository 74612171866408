//This is a root component that other components should extend for functionality
//It provides for a three pane search screen:
//Top Pane - Search Criteria Selection
//Side Pane - Search Result Summary List
//Main Panel - Selected Result Details


import { Type, Directive, Component, Input, OnInit, ViewChild, ComponentFactory, ComponentFactoryResolver, ViewContainerRef, ChangeDetectorRef, Injectable, ContentChild, TemplateRef, EventEmitter, Output } from '@angular/core';
import { SearchField } from './search-field';
import { SearchResult } from './search-result';
import { DetailsPane } from './detail-pane';
import { ApiService } from 'src/app/api/api.service';
import { SearchWithDetailsImplementor } from './search-with-details-implementor';
import { SinglePanelComponent } from '../single-panel/single-panel.component';
import { InstanceComponent } from 'src/app/research/instance/instance.component';
import { SearchError } from './search-error';
import { Store } from '@ngrx/store';
import { TcemsService } from 'src/app/api/tcems.service';
import { ActivatedRoute, Router } from '@angular/router';
import { loggedIn } from 'src/app/actions/login.actions';

@Component({
  selector: 'app-search-with-details',
  templateUrl: './search-with-details.component.html',
  styleUrls: ['./search-with-details.component.scss']
})

// @Directive ({})
export class SearchWithDetailsComponent implements OnInit{

  @ContentChild("details", { static: false })
  detailsRef: TemplateRef<any>;

  // @ViewChild(DetailsPane, {static:true}) detailsPane!: DetailsPane;  //This is where we'll have dynamic content for the details of our selected item
  
  // @Input() implementor: SearchWithDetailsImplementor<T>

  @Input() fields: SearchField<string>[];
  @Input() sortColumns: {key:string, value:string}[];
  @Input() foundSet: SearchResult<any>[];
  @Input() searchInProgress: boolean;
  @Input() sortField: {sortField:{key:string,value:string}, sortDesc: boolean};
  @Output() searchInProgressChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() sortFieldChange: EventEmitter<{sortField:{key:string,value:string}, sortDesc: boolean}> = new EventEmitter<{sortField:{key:string,value:string}, sortDesc: boolean}>();
  @Output() doSearch: EventEmitter<SearchField<any>[]> = new EventEmitter<SearchField<any>[]>();
  @Output() doSort: EventEmitter<{sortField:{key:string,value:string}, sortDesc: boolean}> = new EventEmitter<{sortField:{key:string,value:string}, sortDesc: boolean}>();
  @Output() resultSelected: EventEmitter<any> = new EventEmitter<any>(); 



  // fields: SearchField<string>[];
  // foundSet:SearchResult<T>[];
  searching: boolean = false;
  message: string;
  searchIcon: string;
  selectedId: string;
  selectedResult: SearchResult<any>;
  // sortColumns: {key:string, value:string}[];
  selectedSortColumn: {key:string, value:string};
  sortDescending: boolean;

  // constructor(protected apiService : ApiService, 
  //             protected store: Store, 
  //             protected tcemsService: TcemsService,
  //             protected changeDetector: ChangeDetectorRef,
  //             protected route: ActivatedRoute,
  //             protected router: Router) 
  // { 
  // }
  constructor()
  {

  }
  // abstract getImplementor():SearchWithDetailsImplementor<T>;

  ngOnInit(): void 
  {

      // this.getImplementor().init().subscribe(v =>
      //   {
      
      //   this.fields = this.getImplementor().fields;
      //   this.sortColumns = this.getImplementor().getSortColumns().map(sc => {return {key:sc, value:sc}});
        // this.selectedSortColumn = this.sortColumns[0];
      //   }
      // );
      this.selectedSortColumn = this.sortField.sortField;
      this.sortDescending = this.sortField.sortDesc;
  }
  
  search() : void
  {
    //We're searching
    this.searchInProgressChange.emit(true);
    this.doSearch.emit(this.fields);
    this.message = '';
    

    //  this.getImplementor().doSearch(this.apiService, this.tcemsService).then(result => 
    //     {
    //       //Sort the results by our selected sort field
    //       if(!result || result.length == 0)
    //       {
    //         this.foundSet = undefined;
    //       }
    //       else
    //       {
    //         this.foundSet = this.getImplementor().sortSearchResults(result,this.selectedSortColumn.value,this.sortDescending);   //Push whatever we got back into the array
    //       }
    //       this.updateSearching(false);
    //     })
    //  //If there's an error...

    //  .catch((error: any) => {
    //    this.foundSet = [];
    //    this.message = error.errorMessage;
    //    this.updateSearching(false);
    // });

  }

  sortResults()
  {
    this.sortField = {sortField:this.selectedSortColumn,sortDesc:this.sortDescending};
    this.sortFieldChange.emit(this.sortField);
    this.doSort.emit(this.sortField);
    // this.foundSet = this.getImplementor().sortSearchResults(this.foundSet,this.selectedSortColumn.value,this.sortDescending);
  }



  select(selectedId: string): void
  {
    //Set our selected Id
    this.selectedId = selectedId;
    
    //Also, set our selected item
    this.selectedResult = this.foundSet.find(result => result.id == selectedId);

    this.resultSelected.emit(this.selectedResult);

    // //Update our center content
    // // this.router.navigate(['coins'], {relativeTo:this.route});

    // //Load up the details pane
    // // const componentFactory = this.componentFactoryResolver.resolveComponentFactory(this.implementor.detailComponentType);
    // const viewContainerRef = this.detailsPane.viewContainerRef;

    // // //Clear the existing content
    // viewContainerRef.clear();

    // let cr = viewContainerRef.createComponent<SinglePanelComponent<T>>(this.getImplementor().getDetailPanel()); //  .  (componentType: Type<SinglePanelComponent<T>>)

    // cr.instance.modelData = this.selectedResult.payload;
    // // //Load up the new stuff
    // // // const componentRef = viewContainerRef.createComponent<SinglePanelComponent<T>>(componentFactory);
    // // const componentRef = viewContainerRef.
    
    // // //Set the model data for the newly instantiated component, by way of calling the hook from the implementor here
    // // componentRef.instance.modelData = this.selectedResult.payload;//this.implementor.selectResult(this.selectedResult);

  }




}
