export class FileDescriptor
{
    contentType: string;
    fileName: string;
    fileExtension: string;
    baseName: string;
    completePath: string;
    lastModified: Date;
}

export class HEMIFileDescriptor extends FileDescriptor
{
    fileType: string;
    fileId: string;
    description: string;
    
    get shortDesc() : string
    {
        return this.description.length >= 15 ? this.description.substring(0,5) + "..." + this.description.substring(this.description.length - 6, this.description.length) : this.description;
    }
}