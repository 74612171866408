import { ClosureReason } from "./closure_reason";

export class EncounterStatus
{
    statusCode: number;
    statusDescription: string;
    availableClosureReasons: ClosureReason[];

    public static readonly STATUS_CONFIRMED: number = 4;
    public static readonly STATUS_PARTIALLY_CONFIRMED: number = 7;
    public static readonly STATUS_CONFIRMED_NEEDS_REVIEW: number = 8;
    public static readonly STATUS_COMPLETE: number = 5;
    public static readonly STATUS_CLOSED: number = 6;
    public static readonly STATUS_CANCELED: number = 9;
    public static readonly STATUS_OPEN: number = 1;
    public static readonly STATUS_PENDING_MORE_INFO: number = 3;
    public static readonly STATUS_SCHEDULED: number = 2;

}