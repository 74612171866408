import { EncounterSummary } from "../encounter/encounter_summary";
import { Match } from "./match";

export class MatchSummary
{
    encounter: EncounterSummary;
    possibleMatches: Match[];
    matchCount: number;
    bestMatchScore: number;
    bestMatchInstanceId: number;

    constructor() {};
}