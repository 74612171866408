
<div [ngClass]="rightSlideClasses">
    <p-card>
    <p-tabView [(activeIndex)]="tabIndex">
        <p-tabPanel >
            <ng-template pTemplate="header">
                <i class="pi pi-phone"></i>
                <span>Phone</span>
            </ng-template>
            
            <div class="grid">

                <div  *ngFor="let u of userIds" class="col-6">
                    <!--<p-avatar [label]="usersById[u].firstName.charAt(0)" styleClass="mr-2" size="xlarge" shape="square" pBadge [value]="presenceById[u].status" [severity]="badgeTypeById[u]"></p-avatar>-->
                    <p-avatar [label]="usersById[u].firstName.charAt(0)" styleClass="mr-2" size="large" shape="square"></p-avatar>
                    <p-badge class="p-overlay-badge" [value]="presenceById[u].status" [severity]="badgeTypeById[u]"></p-badge>
                    <span style="font-size:medium">
                        &nbsp;&nbsp;&nbsp;&nbsp;{{usersById[u].firstName}} ({{usersById[u].extNumber}})
                    </span>
                </div>
            </div>
        </p-tabPanel>
        <p-tabPanel>
            <ng-template pTemplate="header">
                <i class="pi pi-comment"></i>
                <span>Chat</span>
            </ng-template>
            <div *ngIf="chatActive" class="flex w-full flex-column">
                <div class="w-full">
                    <iframe  class="iframe" [src]="urlSafe"></iframe>
                </div>
                <div class="flex w-full justify-content-center">
                    <p-button label="End Chat" (click)="endCurrentTask()"></p-button>
                </div>
            </div>
            <div *ngIf="!chatActive" class="grid fill-vert">
                <div class="col-12 flex justify-content-center align-items-center">
                    <span>No Active Chat</span>
                </div>
            </div>
        </p-tabPanel>
        <p-tabPanel>
            <ng-template pTemplate="header">
                <i class="pi pi-book"></i>
                <span>Diaries</span>
            </ng-template>

            <div style="height:600px; overflow:scroll;">
                <app-diary-scroller [allowNew]="false" [getForCurrentUser]="true" [showReferenceLinks]="true" (loadDiaryReference)="loadEntityFromDiary($event)" [allowStatusSelection]="false"></app-diary-scroller>
            </div>
        </p-tabPanel>
    </p-tabView>
    </p-card>
    <div >
        <!-- <span class="p-buttonset"> -->


        <button pButton type="button" (click)="togglePhone()" icon="pi pi-phone" class="right-slider-phone-button" ></button>
        <button pButton type="button" (click)="toggleChat()" icon="pi pi-comment" class="right-slider-chat-button" ></button>
        <button pButton type="button" (click)="toggleDiary()" icon="pi pi-book" class="right-slider-diary-button" ></button>

        <!-- </span> -->

    </div>
</div>