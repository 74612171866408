import { Company } from "../support/company";
import { User } from "../util/user";
import { Address } from "./address";
import { EmailAddress } from "./email-address";
import { PhoneNumber } from "./phone-number";
import { Plan } from "./plan";
import { Person } from "../support/person";
import { EndUser } from "../util/end_user";

export class Phi
{
    phiId: number;
    addresses: Address[];
    emailAddresses: EmailAddress[];
    firstName: string;
    lastName: string;
    middleInitial: string;
    modifiedBy: User;
    phoneNumbers: PhoneNumber[];
    plans: Plan[];
    dob: Date;
    hasOptedOut: boolean;
    company: Company;
    peoples: Person[];
    endUser: EndUser;
    get isSubscriber(): boolean
    {
        let activePlan: Plan = this.plans.filter(p => p.active)[0];
        // return (activePlan == null) ? null : activePlan.isSubscriber;
        return activePlan == null || activePlan.isSubscriber == null || activePlan.isSubscriber;
    }
    get memberId(): string
    {
        let activePlan: Plan = this.plans.filter(p => p.active)[0];
        return (activePlan == null) ? null : activePlan.memberId;
    }
    get mailSuffix(): string
    {
        let activePlan: Plan = this.plans.filter(p => p.active)[0];
        return (activePlan == null || activePlan.phi.company.whiteLabelDetail.emailSuffix == null ) ? "harlowehealth.com" : activePlan.phi.company.whiteLabelDetail.emailSuffix;
    }
    get currentSubscriber(): Phi
    {
        let activePlan: Plan = this.plans.filter(p => p.active)[0];
        // return (activePlan == null) ? null : activePlan.isSubscriber;
        return activePlan == null || activePlan.isSubscriber == null || activePlan.isSubscriber || activePlan.dependentOf == null ? this : activePlan.dependentOf?.phi;        
    }
    get isMinor(): boolean
    {
      return Math.floor((new Date().getTime() - new Date(this.dob).getTime() ) /(1000 * 60 * 60 * 24 * 365)) < 18;
    }

    asUpdate():PhiUpdate
    {
        let pu = new PhiUpdate();
        pu.dob = this.dob;
        pu.firstName = this.firstName;
        pu.lastName = this.lastName;
        pu.middleInitial = this.middleInitial;
        
        return pu;
    }
    get currentActivePlan(): Plan
    {
      return this.plans.filter(p => p.active)[0];
    }
    get firstActivePhoneNumber(): PhoneNumber
    {
      return this.phoneNumbers.filter( p => p.active && p.phoneType.code === 1)[0];
    }
    get firstActiveEmailAddress(): EmailAddress
    {
      return this.emailAddresses.filter( p => p.active && p.emailType.code === 1)[0];
    }
    get firstActivePerson(): Person
    {
      return this.peoples.filter( p => p.active)[0];
    }

    get displayNameWithSubDep(): string
    {
      return this.firstName + " " + this.lastName + " (" + (this.isSubscriber ? "sub" : "dep") + ")";
    }

    get hasActiveEmail(): boolean
    {
      let email: EmailAddress = this.firstActiveEmailAddress;
      if(email == null || email.emailAddress.trim() == "N/A" || email.emailAddress.trim() == "None" || email.emailAddress.trim() == "" || email.emailAddress.trim() == "none" || email.emailAddress.trim() == "NA")
      {
        return false;
      }
      else 
      {
        return true;
      }
    }

}

export class PhiUpdate
{
    phiId: number;
    firstName: string;
    lastName: string;
    middleInitial: string;
    dob: Date;
    hasOptedOut: boolean;
}
