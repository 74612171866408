export class ReferenceCode
{
    code: number;
    referenceType: string;
    static TYPE_ENCOUNTER:number = 1;
    static TYPE_PHI: number = 2;
    static TYPE_PLAN: number = 3;
    static TYPE_EMAIL: number = 4;
    static TYPE_PHONE: number =5;
    static TYPE_ADDRESS: number = 6;
    static TYPE_PROVIDER: number = 7;
    static TYPE_PROVIDER_LOCATION: number = 8;
    static TYPE_APPOINTMENT: number = 9; 
    static TYPE_PRACTICE: number = 10;
    static TYPE_PRACTICE_LOCATION: number = 11;

    static create(type: number):ReferenceCode
    {
        let rc = new ReferenceCode();
        if(type == ReferenceCode.TYPE_ENCOUNTER)
        {
            rc.code = ReferenceCode.TYPE_ENCOUNTER;
            rc.referenceType = 'Encounter'
        }
        else if(type == ReferenceCode.TYPE_PRACTICE)
        {
            rc.code = ReferenceCode.TYPE_PRACTICE;
            rc.referenceType = 'Practice'
        }
        else if(type == ReferenceCode.TYPE_PROVIDER_LOCATION)
        {
            rc.code = ReferenceCode.TYPE_PROVIDER_LOCATION;
            rc.referenceType = 'Provider Location'
        }
        else if(type == ReferenceCode.TYPE_PRACTICE_LOCATION)
        {
            rc.code = ReferenceCode.TYPE_PRACTICE_LOCATION;
            rc.referenceType = 'Practice Location'
        }
        else if(type == ReferenceCode.TYPE_PHI)
        {
            rc.code = ReferenceCode.TYPE_PHI;
            rc.referenceType = 'PHI'
        }
        return rc;
    }

}