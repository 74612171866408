import { Injectable } from "@angular/core";
import { MessageService } from "primeng/api";

@Injectable()
export abstract class AbstractSearchWithDetailsComponent
{
    searchInProgress: boolean = false;
    
    constructor(protected messageService: MessageService)
    {}

    protected logError(message: string)
    {
        this.messageService.add({key: 'searchErrors', severity:'error', summary: 'Search Error', detail: message});          
    }   
    protected logWarn(message: string)
    {
        this.messageService.add({key: 'searchErrors', severity:'warning', summary: 'Search Warning', detail: message});          
    }

    
}