<div class="flex p-3">
    <p-dropdown  [disabled]="!allowCategorySelection" appendTo="body" id="Category" [options]="categories" [(ngModel)]="selectedCategory" optionLabel="categoryName" [style]="{'width':'200px'}" [showClear]="true" placeholder="Select Category" (onChange)="categorySelected($event)"></p-dropdown>
</div>
<p-tabView>
        <p-tabPanel header="CPT">
            <p-tree [value]="filteredProcTree" selectionMode="checkbox" [filter]="true" [trackBy]="treeTrackingFunction" [(selection)]="selectedNodes" (onNodeSelect)="codesSelected($event)" (onNodeUnselect)="codesSelected($event)">
                <ng-template let-node pTemplate="default">
                    <span [pTooltip]="node.data.procedureDescription">{{node.label}}</span>
                </ng-template>
            </p-tree>
        </p-tabPanel>
        <p-tabPanel header="HCPCS">
            <p-tree [value]="filteredProcTreeHCPCS" selectionMode="checkbox" [filter]="true" [(selection)]="selectedNodesHCPCS" (onNodeSelect)="codesSelected($event)" (onNodeUnselect)="codesSelected($event)">
                <ng-template let-node pTemplate="default">
                    <span [pTooltip]="node.data.procedureDescription">{{node.label}}</span>
                </ng-template>
            </p-tree>
        </p-tabPanel>
        <!-- <p-tabPanel header="Type III Codes">
        </p-tabPanel>                 -->
</p-tabView>