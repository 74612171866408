<div class="category-header">ENCOUNTER SUMMARY<div style="float:right">( {{encounter.encounterId}} )</div></div>

<table class="data-table">
  <tr>
    <td class="field-name">Description</td>
    <td class="field-data">{{encounter.procedureDescription}}</td>
  </tr>
  <tr>
    <td class="field-name">Status</td>
    <td class="field-data">
      
      <p-inplace [closable]="true" closeIcon="pi pi-check" (onDeactivate)="processStatusChange($event)">
        <ng-template pTemplate="display">
          <span class="field-data">{{encounter.status?.statusDescription}}</span>
        </ng-template>
        <ng-template pTemplate="content">
          <p-dropdown appendTo="body" id="encounterStatus" [options]="util.encounterStatuses" [(ngModel)]="pendingStatus" optionLabel="statusDescription" style="width:100px"></p-dropdown>
        </ng-template>
      </p-inplace>
    </td>
  </tr>
  <tr *ngIf="encounter.status?.statusCode == closed || showClosureEdit">
    <td class="field-name">Closure Reason</td>

    <p-inplace [active]="showClosureEdit" [closable]="pendingClosureReason != null" closeIcon="pi pi-check" (onDeactivate)="commitEncounterStatus($event)">
      <ng-template pTemplate="display">
        <span class="field-data">{{encounter.closureReason?.reasonDescription}}</span>
      </ng-template>
      <ng-template pTemplate="content">
        <p-dropdown appendTo="body" id="closureReason" [showClear]="true" placeholder="Select" [options]="util.closureReasons" [(ngModel)]="pendingClosureReason" optionLabel="reasonDescription" style="width:100px"></p-dropdown>
      </ng-template>
    </p-inplace>
    <td class="field-data"></td>
  </tr>
  <tr>
    <td class="field-name">Type</td>
    <td class="field-data">{{encounter.encounterType?.encounterType}}</td>
  </tr>
  <tr>
    <td class="field-name">Created</td>
    <td class="field-data">{{encounter.creationDate | date: 'shortDate'}}</td>
  </tr>
  <tr *ngIf="encounter.confirmedDate">
    <td class="field-name">Confirmed</td>
    <td class="field-data">{{encounter.confirmedDate |date:'shortDate'}}</td>
  </tr>
  <tr *ngIf="encounter.closedDate">
    <td class="field-name">Closed</td>
    <td class="field-data">{{encounter.closedDate |date:'shortDate'}}</td>
  </tr>

  <tr *ngIf="encounter.costToBeat">
    <td class="field-name">Cost to Beat</td>
    <td class="field-data">{{encounter.costToBeat | currency }}</td>
  </tr>
  <tr *ngIf="encounter.probableLowCost && !isConfirmed">
    <td class="field-name">Prob. Low Cost</td>
    <td class="field-data">{{encounter.probableLowCost | currency }}</td>
  </tr>
  <tr *ngIf="encounter.confirmedCost">
    <td class="field-name">Confirmed Cost</td>
    <td class="field-data">{{encounter.confirmedCost | currency }}</td>
  </tr>
  <tr *ngIf="encounter.probableSavings || encounter.confirmedSavings">
    <td class="field-name"><span *ngIf="!isConfirmed">Prob.</span> Savings</td>
    <td class="field-data">
      <span *ngIf="!isConfirmed">{{encounter.probableSavings | currency}}</span>
      <span *ngIf="isConfirmed">{{encounter.confirmedSavings | currency}}</span>
    </td>
  </tr>
  <!-- <tr *ngIf="encounter.confirmedSavings">
    <td class="field-name">Conf. Savings</td>
    <td class="field-data">{{encounter.confirmedSavings | currency}}</td>
  </tr> -->
  <tr *ngIf="encounter.manualRewardAmount">
    <td class="field-name">Man. Reward</td>
    <td class="field-data">{{encounter.manualRewardAmount.toLocaleString('en-US',{style:'currency',currency:'USD'})}}</td>
  </tr>
  <tr *ngIf="encounter.finalRewardAmount">
    <td class="field-name">Final Reward</td>
    <td class="field-data">{{encounter.finalRewardAmount.toLocaleString('en-US',{style:'currency',currency:'USD'})}}</td>
  </tr>
</table>
