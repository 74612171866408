


<div class="flex flex-column fill-vert no-overflow p-1 pb-5">    
    <!-- Top Search Bar -->
    <p-panel header='Transaction Search Criteria'>
    <!-- Approved Date Range -->
        <div class="pt-3">
            <div class="formgroup-inline">
                <span class="p-float-label field">
                    <p-calendar id="approvedStart" [(ngModel)]="approvedStart"></p-calendar>
                    <label for="approvedStart">Approved On Or After</label> 
                </span>
                <span class="p-float-label field">
                    <p-calendar id="approvedEnd" [(ngModel)]="approvedEnd"></p-calendar>
                    <label for="approvedEnd">Approved On Or Before</label> 
                </span>
                <span class="field-checkbox pl-3">
                    <p-checkbox [(ngModel)]="showInvoiced" [binary]="true" id="showInvoiced"></p-checkbox>                        
                    <label for="showInvoiced">Show Invoiced</label> 
                </span>    
                <span class="field-checkbox pl-3">
                    <p-checkbox [(ngModel)]="showSelfPaid" [binary]="true" id="showSelfPay"></p-checkbox>                        
                    <label for="showSelfPay">Show Paid By Client</label> 
                </span>                   

                <div class="field">
                    <app-search-button [searchFunction]="searchFunction"></app-search-button>
                </div>     
            </div>
        </div>
    </p-panel>

    <p-dialog header="Please Wait..." [(visible)]="showProgress">
        <p-progressBar [value]="progressDisplay"></p-progressBar>
    </p-dialog>

    <p-dialog header="Void/Reissue Checks" [(visible)]="showVoidReissueDialog">
        <div *ngIf="loadingPostDialog" class="grid justify-content-center align-items-center flex-column">
            <div class="col justify-content-center">
                <p-progressSpinner></p-progressSpinner>
            </div>
            <div>
                Calculating Next Check Numbers
            </div>
        </div>        
        <div *ngIf="postingChecks" class="grid justify-content-center align-items-center flex-column">
            <div class="col justify-content-center">
                <p-progressSpinner></p-progressSpinner>
            </div>
            <div>
                Voiding/Reissuing Checks. This could take awhile.
            </div>
        </div>    
        
        <!-- Pick a checking account on which to reissue these suckers -->
        <div class="mt-2" *ngIf="!loadingPostDialog && !postingChecks">
            <div class="grid mt-3 mb-3">
                <div class="col-12">
                    <span class="p-float-label" style="width:100%">
                        <p-dropdown appendTo="body" id="reissueAccount" [showClear]="true" [style]="{'width':'250px'}" [options]="checkingAccountsForPosting" [(ngModel)]="accountForReissue" optionLabel="accountName" [autoDisplayFirst]="false" (onChange)="nextCheckForReissue = nextCheckNumbers[accountForReissue.accountId]"></p-dropdown>
                        <label for="reissueAccount">Account For Reissue</label>
                    </span>
                </div>
                <div class="col-12 mt-3">
                    <span class="p-float-label field">
                        <input type="text" pInputText id="reissueCheck" [(ngModel)]="nextCheckForReissue" [disabled]="accountForReissue?.nonCheckIssuer" style="width: 7rem;">
                        <label for="reissueCheck">Next Check</label> 
                    </span>
                </div>
                <div class="align-items-center justify-content-center">
                    <p-button label="Void And Reissue Checks" (onClick)="voidAndReissue($event)" icon="pi pi-thumbs-up"></p-button>
                </div>
        
            </div>
        </div>
    </p-dialog>

    <p-dialog header="Post Checks From Transactions" [(visible)]="showPostDialog">

        <!-- <div *ngIf="loadingPostDialog" class="grid flex-column justify-content-center"><div class="col-12 justify-content-center align-items-center"><p-progressSpinner></p-progressSpinner></div></div> -->
        <div *ngIf="loadingPostDialog" class="grid justify-content-center align-items-center flex-column">
            <div class="col justify-content-center">
                <p-progressSpinner></p-progressSpinner>
            </div>
            <div>
                Calculating Next Check Numbers
            </div>
        </div>
        <div *ngIf="postingChecks" class="grid justify-content-center align-items-center flex-column">
            <div class="col justify-content-center">
                <p-progressSpinner></p-progressSpinner>
            </div>
            <div>
                Posting Checks. This could take awhile.
            </div>
        </div>
        <div class="flex flex-column mt-2" *ngIf="!loadingPostDialog && !postingChecks">
            <div *ngFor="let checkingAccount of checkingAccountsForPosting;" class="grid mt-3 mb-3">
                <div class="col-4 p-2"> 
                    {{checkingAccount.accountName}} 
                </div>
                <div class="col-3 p-2">
                    <span class="p-float-label field">
                        <input type="text" pInputText [id]="checkingAccount.accountId" [(ngModel)]="nextCheckNumbers[checkingAccount.accountId]" [disabled]="checkingAccount.nonCheckIssuer" style="width: 7em;">
                        <label [for]="checkingAccount.accountId">Next Check</label> 
                    </span>
                </div>
                <div class="col-3 p-2">{{getSelectedTransactionCountForAccount(checkingAccount.accountId)}} Transactions</div>
                <div class="col-2 p-2">{{getSelectedTransactionAmountForAccount(checkingAccount.accountId) | currency: 'USD' : "symbol" }}</div>
            </div>
            <div>
                <span class="field-checkbox pl-3">
                    <p-checkbox [(ngModel)]="postToOpenInvoice" [binary]="true" id="postToOpenInvoice"></p-checkbox>                        
                    <label for="postToOpenInvoice">Post To Open Invoices</label> 
                </span>               
            </div>
            <p-button label="Post Checks" (onClick)="postChecks($event)" icon="pi pi-thumbs-up"></p-button>
        </div>

    </p-dialog>

    <p-dialog header="New Transaction" [(visible)]="showNewTransactionDialog">
        <app-transaction [isNew]="true" (transaction)="newTransaction($event)"></app-transaction>
    </p-dialog>


    <div class="pt-2 pb-6 flex flex-column fill-vert" style="box-sizing:border-box;">
        <!-- Transaction Results -->
        <p-table [value] = "transactions" [(selection)]="selectedTransactions" 
                dataKey="transactionId" sortMode="multiple" [scrollable]="true" 
                scrollHeight="520px" (onHeaderCheckboxToggle)="updateContextMenu($event)" 
                (onRowSelect)="updateContextMenu($event)" (onRowUnselect)="updateContextMenu($event)"
                editMode="row">
            <ng-template pTemplate="header">
                <tr>
                    <th style="min-width:3rem ; width: 3rem ; max-width:3rem">
                        <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                        
                    </th>
                    <th style="min-width: 8rem ; width: 8rem ; max-width: 8rem">Transaction Id</th>
                    <th style="min-width: 10rem ; width: 10rem ;max-width: 10rem" pSortableColumn="account.accountName">
                        Checking Account 
                        <p-sortIcon field="account.accountName"></p-sortIcon>
                        <p-columnFilter type="text" field="account.accountName" display="menu"></p-columnFilter>
                    </th>
                    <th style="min-width: 7rem ; width: 7rem ; max-width: 7rem" pSortableColumn="encounter.encounterId">Encounter Id<p-sortIcon field="encounter.encounterId"></p-sortIcon></th>
                    <th style="min-width: 10rem ; width: 10rem ; max-width: 10rem;" pSortableColumn="transactionType.transactionDescription">Type<p-sortIcon field="transactionType.transactionDescription"></p-sortIcon></th>
                    <th style="min-width: 8rem ; width: 8rem ; max-width: 8rem" pSortableColumn="approvedDate">Approved Date<p-sortIcon field="approvedDate"></p-sortIcon></th>
                    <th style="min-width: 8rem ; width: 8rem ; max-width: 8rem" pSortableColumn="encounter.paidDateOfConfirmedInstance">
                        Paid Date
                        <p-sortIcon field="encounter.paidDateOfConfirmedInstance"></p-sortIcon>
                        <p-columnFilter type="date" field="encounter.paidDateOfConfirmedInstance" display="menu"></p-columnFilter>
                    </th>
                    <th style="min-width: 7rem ; width: 7rem ; max-width: 7rem" pSortableColumn="transactionMagnitude">Amount<p-sortIcon field="transactionMagnitude"></p-sortIcon></th>
                    <th style="min-width: 15rem; width: 15rem ; max-width: 15rem;">Payee</th>
                    <th style="min-width: 8rem ; width: 8rem ; max-width: 8rem">Delivery Req Met</th>
                    <th style="min-width: 10rem ; width: 10rem ; max-width: 10rem;" pSortableColumn="encounter.subscriberCompany.companyName">Company <p-sortIcon field="encounter.subscriberCompany.displayName"></p-sortIcon></th>
                    <th style="min-width: 5rem ; width: 5rem ; max-width: 5rem;">Check</th>
                    <th style="min-width: 8rem ; width: 8rem; max-width: 8rem;" pSortableColumn="invoiced">
                        Invoiced
                        <p-sortIcon field="invoiced"></p-sortIcon>
                        <p-columnFilter type="boolean" field="invoiced" display="menu"></p-columnFilter>
                    </th>
                    <th style="min-width: 10rem ; width:10rem; max-width:10rem">
                        <button class="ml-2" type="button" pButton icon="pi pi-bars"  (click)="actionMenu.toggle($event)"></button>
                        <button class="ml-2" type="button" pButton icon="pi pi-plus" (click)="toggleNewTransaction($event)"></button>
                        <p-menu #actionMenu [popup]="true" [model]="actions" appendTo="body"></p-menu>
                    </th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-transaction let-editing="editing" let-ri="rowIndex">
                <tr [pEditableRow]="transaction" [ngClass]="transaction.void ? 'voidedTransaction' : ''" [pTooltip]="'Void Date: '+ transaction.voidDateDisplay" [tooltipDisabled]="!transaction.void">
                    <td style="min-width: 3rem; width:3rem; max-width:3rem" class="transactionRow" >
                        <p-tableCheckbox [value]="transaction"></p-tableCheckbox>
                    </td>
                    <td style="min-width: 8rem; width:8rem ; max-width: 8rem" class="transactionRow" >{{transaction.transactionId}}</td>
                    <td style="min-width: 10rem; width:10rem ; max-width: 10rem" class="transactionRow">
                        <p-cellEditor>
                            <ng-template pTemplate="input">
                                <!-- <input type="text" [(ngModel)]="transaction.account"> -->
                                <i class="pi pi-exclamation-triangle" *ngIf="!isOnValidAccount(transaction)"  style="color:red"></i>
                                <p-dropdown appendTo="body" id="transactionAccount" [showClear]="false" [style]="{'width':'10rem'}" [options]="getAvailableCheckingAccounts(transaction)" [(ngModel)]="transaction.account" optionLabel="accountName" [autoDisplayFirst]="false"></p-dropdown>
                            </ng-template>
                            <ng-template pTemplate="output">
                                <i class="pi pi-exclamation-triangle" *ngIf="!isOnValidAccount(transaction)" style="color:red"></i>
                                {{transaction.account?.accountName}}  
                            </ng-template>
                        </p-cellEditor>
                    </td>
                    <td style="min-width: 7rem; width:7rem; max-width:7rem" class="transactionRow" >
                    <p-overlayPanel #encounterOverlay appendTo="body">
                        <ng-template pTemplate>
                            <div *ngIf="transaction.encounter != null" class="align-items-center justify-content-center">
                                <div>
                                    Create Date: {{transaction.encounter.creationDate | date:'shortDate'}}
                                </div>
                                <div>
                                    Total Savings: {{transaction.encounter.confirmedSavings | currency}}
                                </div>
                            </div>
                    
                        </ng-template>
                    </p-overlayPanel>
                        <span (mouseenter)="encounterOverlay.toggle($event)" (mouseout)="encounterOverlay.toggle($event)">
                            {{transaction.encounter?.encounterId}}
                        </span>
                    </td>
                    <td style="min-width: 10rem; width:10rem; max-width:10rem" class="transactionRow" >
                        <p-cellEditor>
                            <ng-template pTemplate="input">
                                <!-- <input type="text" [(ngModel)]="transaction.account"> -->
                                <p-dropdown appendTo="body" id="transactitonType" [showClear]="false" [options]="this.tcemsUtilities.transactionTypes" [(ngModel)]="transaction.transactionType" optionLabel="transactionDescription" [autoDisplayFirst]="false"></p-dropdown>
                            </ng-template>
                            <ng-template pTemplate="output">
                                {{transaction.transactionType.transactionDescription}}
                            </ng-template>
                        </p-cellEditor>
                    </td>
                    <td style="min-width: 8rem ; width:8rem ; max-width: 8rem" class="transactionRow" >{{transaction.approvedDate | date:'shortDate'}}</td>
                    <td style="min-width: 8rem; width:8rem; max-width: 8rem;" class="transactionRow" >{{transaction.encounter.paidDateOfConfirmedInstance | date:'shortDate'}}</td>
                    <!-- <td class="transactionRow" >{{transaction.transactionMagnitude | currency: 'USD' : "symbol" }}</td> -->
                    <td style="min-width: 7rem ; width:7rem ; max-width:7rem;" class="transactionRow">
                        <p-cellEditor>
                            <ng-template pTemplate="input">
                                <!-- <input type="text" [(ngModel)]="transaction.account"> -->
                                <p-inputNumber [inputStyle]="{'max-width':'7rem'}" [(ngModel)]="transaction.transactionMagnitude" inputId="transactionMagnitudeInput" mode="currency" currency="USD" locale="en-US"></p-inputNumber>                            
                            </ng-template>
                            <ng-template pTemplate="output">
                                {{transaction.transactionMagnitude | currency: 'USD' : "symbol" }}
                            </ng-template>
                        </p-cellEditor>
                    </td>
                    <td style="min-width: 15rem; width:15rem; max-width: 15rem;" class="transactionRow" >
                        <p-cellEditor>
                            <ng-template pTemplate="input">
                                <i class="pi pi-exclamation-triangle" *ngIf="transaction.payee.isMinor" style="color:red"></i>
                                <span *ngIf="loadingPlanMemberMap[transaction.transactionId]">
                                    <p-progressSpinner [style]="{width: '2rem', height: '2rem'}" styleClass="custom-spinner" strokeWidth="8" fill="var(--surface-ground)" animationDuration=".5s"></p-progressSpinner>
                                </span>
                                <p-dropdown *ngIf="!loadingPlanMemberMap[transaction.transactionId]loadingPlanMemberMap[transaction.transactionId]" [style]="{'max-width':'15rem'}" appendTo="body" id="payeeSelector" [showClear]="false" [options]="planMemberMap[transaction.transactionId]" [(ngModel)]="transaction.payee" dataKey="phiId" optionLabel="displayNameWithSubDep" [autoDisplayFirst]="false"></p-dropdown>
                            </ng-template>
                            <ng-template pTemplate="output">
                                <i class="pi pi-exclamation-triangle" *ngIf="transaction.payee.isMinor" style="color:red"></i>
                                {{transaction.payee.firstName}} {{transaction.payee.lastName}}
                            </ng-template>
                        </p-cellEditor>
                                            </td>
                    <td style="min-width:8rem; width:8rem ; max-width: 8rem;" class="transactionRow" >
                        <p-checkbox [disabled]="true" [binary]="true" [(ngModel)]="transaction.contactRequirementsMet"></p-checkbox>
                    </td>
                    <td style="min-width: 10rem; width:10rem ; max-width: 10rem;" class="transactionRow"  style="overflow-wrap: break-word;">{{transaction.encounter.subscriberCompany?.employerName}}</td>
                    <td style="min-width: 5rem ; width:5rem ; max-width: 5rem" class="transactionRow" >{{transaction.check?.checkNumber}}</td>
                    <td style="min-width: 8rem; width:8rem ; max-width: 8rem" class="transactionRow" ><p-checkbox [ngModel]="transaction.invoiced" [binary]="true" [readonly]="true" ></p-checkbox></td>
                    <td style="min-width: 10rem; width:10rem; max-width: 10rem">
                        <button *ngIf="!editing" pButton pRipple type="button" pInitEditableRow icon="pi pi-pencil" (click)="onRowEditInit(transaction)" class="p-button-rounded p-button-text"></button> 
                        <button *ngIf="editing" pButton pRipple type="button" pSaveEditableRow icon="pi pi-check" (click)="onRowEditSave(transaction, ri)" class="p-button-rounded p-button-text p-button-success mr-2"></button> 
                        <button *ngIf="editing" pButton pRipple type="button" pCancelEditableRow icon="pi pi-times" (click)="onRowEditCancel(transaction, ri)" class="p-button-rounded p-button-text p-button-danger"></button> 
                    </td>
                </tr>
            </ng-template>   
            <ng-template pTemplate="summary">
                <div class="grid">
                    <div class="col-2">
                        Transactions Found: {{totalTransactions}}
                    </div>                    
                    <div class="col-2">
                        Transactions Selected: {{selectedTransactionCount}}
                    </div>
                    <div class="col-2">
                        Value of Selected Transactions: {{selectedTransactionTotal | currency: 'USD' : "symbol" }}
                    </div>
                </div>
            </ng-template>    
        </p-table>
    </div> 
</div>