import { ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { TcemsService } from 'src/app/api/tcems.service';
import { Email, EmailWrite } from 'src/app/model/email/email';
import { TcemsUtilitiesService } from 'src/app/util/tcems-utilities.service';
import { Encounter } from 'src/app/model/encounter/encounter';
import { WhiteLabelType } from 'src/app/model/email/whitelabeltype';
import { HEMIFileDescriptor } from 'src/app/model/util/filedescriptor';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-email-scroller',
  templateUrl: './email-scroller.component.html',
  styleUrls: ['./email-scroller.component.scss']
})
export class EmailScrollerComponent implements OnInit
{
  @Input() encounter: Encounter;

  encounterEmails: Email[] = [];
  selectedEmail: Email;
  newEmailVisible: boolean = false;
  pendingEmail: EmailWrite;
  // whiteLabelTypes: WhiteLabelType[];
  // whiteLabelType: WhiteLabelType;
  emailDomains: string[] = [environment.defaultEmailDomain];
  emailFrom: string;
  selectedEmailDomain: string = "";
  encounterFiles: HEMIFileDescriptor[] = [];
  selectedFilesToSend: HEMIFileDescriptor[] =[];
  myAddressPermutations: string[] = [];

  constructor(private tcems: TcemsService, public util: TcemsUtilitiesService, private cdr: ChangeDetectorRef) {}

  ngOnInit(): void
  {
    //Set the base portion of the from address
    this.emailFrom =  this.util.currentUser.email + "@";

    //See if this encounter is part of a white label program
    if(this.encounter.subscriberCompany.whiteLabelDetail != null)
    {
      this.emailDomains = [... this.emailDomains,this.encounter.subscriberCompany.whiteLabelDetail.emailSuffix ];
      this.selectedEmailDomain = this.encounter.subscriberCompany.whiteLabelDetail.emailSuffix;
    }
    else
    {
      this.selectedEmailDomain = environment.defaultEmailDomain;
    }

    //set up all the address permutations that could be out there
    this.myAddressPermutations = this.emailDomains.map(d => this.emailFrom + d);
    this.myAddressPermutations.push(this.emailFrom + "@tandemcare.com");

    // this.getWhiteLabels();
    this.getEncounterEmails();
    this.getEncounterFiles();
  }

  getEncounterFiles()
  {
    //Get files for the supplied encounter
    this.tcems.fetchEncounterFiles(this.encounter.encounterId).toPromise().then
    (
      el =>
      {
        this.encounterFiles = el.map(f => Object.assign(new HEMIFileDescriptor(), f));
      }
    );
  }


  // getWhiteLabels() 
  // {
  //   this.tcems.getWhiteLabels().toPromise().then(
  //     whiteLabels => 
  //     {
  //       if (whiteLabels != null) 
  //       {
  //         var uniqueWhiteLabels: WhiteLabelType[] = [];
  //         whiteLabels.forEach(whiteLabel => {
  //           if (uniqueWhiteLabels.length == 0) {
  //             uniqueWhiteLabels.push(whiteLabel);
  //           }

  //           for (var i = 0;i<whiteLabels.length;i++) {
  //             if (i < uniqueWhiteLabels.length && uniqueWhiteLabels[i].emailSuffix != whiteLabel.emailSuffix) {
  //               uniqueWhiteLabels[i].emailSuffix != whiteLabel.emailSuffix
  //             }
  //           }
  //         });

  //         this.whiteLabelTypes = uniqueWhiteLabels;
  //       }
  //     });
  // }

  getEncounterEmails()
  {

    //Get files for the supplied encounter
    this.tcems.getEmailsForEncounter(this.encounter.encounterId).toPromise().then
    (
      e =>
      {
        this.encounterEmails = e.map(em => Object.assign(new Email(), em));
      }
    );
  }

  newEmail()
  {
    this.getEncounterFiles();
    this.pendingEmail = new EmailWrite();
    this.selectedFilesToSend = [];
    this.newEmailVisible = true;
  }

  cancelButton(event)
  {
    this.pendingEmail = null;
    this.newEmailVisible = false;
    this.selectedFilesToSend = [];
  }
  
  saveButton(event)
  {

    //Double check to make sure we have all the values we need
    if(this.pendingEmail.recipients == null || this.pendingEmail.recipients.length == 0)
      this.util.displayError("Could Not Send Email","Recipient Needed");
    else if(this.pendingEmail.recipients.some(r => !r.includes('@')))
      this.util.displayError("Could Not Send Email","Some recipients are invalid");
    else if(this.pendingEmail.ccs != null && this.pendingEmail.ccs.some(r => !r.includes('@')))
      this.util.displayError("Could Not Send Email","Some ccs are invalid");
    else if(this.pendingEmail.bccs != null && this.pendingEmail.bccs.some(r => !r.includes('@')))
      this.util.displayError("Could Not Send Email","Some bccs are invalid");
    else
    {

      this.pendingEmail.attachmentIds = this.selectedFilesToSend.map(f => f.fileId);
      this.sendEmail();
    }
  }

  get saveButtonActive(): boolean
  {
    if
    (
      this.pendingEmail.subject != null &&
      this.pendingEmail.recipients != null
    )
    {
      return true;
    }
    else
    {
      return false;
    }
  }

  previewFile(fd: HEMIFileDescriptor)
  {
    //show popup with invoice PDF
    this.tcems.downloadEncounterFile(this.encounter.encounterId,fd.fileId,fd.contentType)
    .then
    (
      blob =>
      {
        if (blob.size == 0)
        {
          this.util.displayError("Could Not Retrieve File","File Does Not Exist");          
          return;
        }
        let url = window.URL.createObjectURL(blob);
        let pwa = window.open(url,"Preview","height=640,width=960,toolbar=no,menubar=no,scrollbars=no,location=no,status=no");
      }
    )
    .catch
    (
      e =>
      {
        console.log(e);
        this.util.displayError("Could Not Open File",e.error);
      }
    )    
  }

  sendEmail()
  {

      this.pendingEmail.from = this.emailFrom + this.selectedEmailDomain;
      this.tcems.createEmail(this.encounter.encounterId, this.pendingEmail).toPromise()
      .then(
        e => 
        {
          this.pendingEmail = new EmailWrite();
          this.newEmailVisible = false;
          this.selectedFilesToSend = [];
          this.getEncounterFiles();
          this.getEncounterEmails();
        }
        )
        .catch
        (
          e =>
          {
            console.log(e);
            this.util.displayError("Could Not Send Email", e.error);
          }
        )
  }
  reply(e: Email)
  {
    this.getEncounterFiles();
    this.pendingEmail = new EmailWrite();
    this.selectedFilesToSend = [];
    this.pendingEmail.subject = e.subject.includes("Re:") ? e.subject : "Re: " + e.subject;
    this.pendingEmail.body = this.quoteBody(e.body);
    this.pendingEmail.recipients = [e.from]; //, ... e.recipients.filter(r => !this.myAddressPermutations.includes(r))];
    // this.pendingEmail.ccs = [... e.cCs.filter(c => !this.myAddressPermutations.includes(c))];
    this.newEmailVisible = true;
  }

  replyAll(e:Email)
  {
    this.reply(e);    
    this.pendingEmail.recipients = [e.from, ... e.recipients.filter(r => !this.myAddressPermutations.includes(r))];
    this.pendingEmail.ccs = [... e.cCs.filter(c => !this.myAddressPermutations.includes(c))];
  }

  forward(e: Email)
  {
    this.getEncounterFiles();
    this.pendingEmail = new EmailWrite();
    this.selectedFilesToSend = [];
    this.pendingEmail.subject = e.subject.includes("Fw:") ? e.subject : "Fw: " + e.subject;
    this.pendingEmail.body = this.quoteBody(e.body);
    // this.pendingEmail.attachmentIds = e.attachments.map(a => a.fileId);
    this.selectedFilesToSend = e.attachments;
    this.newEmailVisible = true;
  }

  quoteBody(body:string):string
  {
    return "<br><br><blockquote>" + body + "</blockquote>";
  }

  read(e: Email)
  {
    this.tcems.readEamil(e.encounterId, e.id).toPromise()
    .then(
      result =>  e.read = true
    )
  }
}