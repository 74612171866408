
<div>

    <p-table [value]="instances" dataKey="instanceId" selectionMode="single" (sortFunction)="sortTable($event)" [customSort]="true" [(selection)]="selectedInstance" (onRowSelect)="selectInstance($event)" (onRowUnselect)="unselectInstance($event)">
        <ng-template pTemplate="header">
            <tr>
                <th style="width:6rem">Instance Id</th>
                <th style="width:10rem" pSortableColumn="primaryProviderName">Provider<p-sortIcon field="primaryProviderName"></p-sortIcon></th>
                <th style="width:4rem" pSortableColumn="serviceBegin">Service Date <p-sortIcon field="serviceBegin"></p-sortIcon></th>
                <th style="width:3rem" pSortableColumn="totalLines">Total Lines<p-sortIcon field="totalLines"></p-sortIcon></th>
                <th style="width:3rem" pSortableColumn="c_selectedLines">Currently Selected Lines<p-sortIcon field="c_selectedLines"></p-sortIcon></th>
                <th style="width:4rem" pSortableColumn="totalCost">Total Cost<p-sortIcon field="totalCost"></p-sortIcon></th>
                <!-- <th>Selected Normalized Cost</th> -->
                <th style="width:4rem" pSortableColumn="c_selectedUnitCost">Currently Selected Cost <p-sortIcon field="c_selectedUnitCost"></p-sortIcon> </th>
                <th style="width:3rem"></th>
                
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-instance>
            <tr [pSelectableRow]="instance">
                <td style="width:6rem">{{instance.instanceId}}</td>
                <td style="width:10rem">
                    <span>{{instance.primaryProviderName}}</span>
                    <span class="ml-3"><p-tag *ngIf="instance.primaryProviderLocation?.providerIsOrganization">Facility</p-tag></span>
                    <span class="ml-3"><p-tag *ngIf="!instance.primaryProviderLocation?.providerIsOrganization">Professional</p-tag></span>
                </td>
                <td style="width:4rem">{{instance.serviceBegin | date:'shortDate'}}</td>
                <td style="width:3rem">{{instance.totalLines}}</td>
                <td style="width:3rem">{{getSelectedLineCount(instance.instanceId)}}</td>
                <td style="width:4rem">{{instance.totalCost | currency}} ({{instance.totalUnitCost | currency}})</td>
                <!-- <td></td> -->
                <td style="width:4rem">{{getSelectedLineCost(instance.instanceId) | currency}} ({{getSelectedLineUnitCost(instance.instanceId) | currency}}) </td>
                <td style="width:3rem">
                    <p-dialog appendTo="body" header="Instance Details" [(visible)]="instanceDialogs[instance.instanceId]"    [modal]="false" [dismissableMask]="true" [closeOnEscape]="true" [responsive]="true" [closable]="true" [style]="{width: '50%'}">
                        <!-- <app-instance (linesSelected)="customSelection(instance.instanceId, $event)" [lineIds]="selectedLineIds" [modelData]="instance"></app-instance> -->
                        <app-instance (linesSelected)="customSelection(instance.instanceId, $event)" [instanceAndLines]="getInstanceAndLines(instance)"></app-instance>

                        <div class="flex justify-content-center" style="width:100%">
                            <!-- <div class="m-1"><button pButton pRipple type="button" icon="pi pi-check" label="Confirm" (click)="confirmPartialInstance(match.instanceOfCare.instanceId)"></button></div>
                            <div class="m-1"><button pButton pRipple type="button" icon="pi pi-clock" label="Confirm - Waiting For Data" (click)="confirmPartialInstance(match.instanceOfCare.instanceId, STATUS_PARTIALLY_CONFIRMED)"></button></div>
                            <div class="m-1"><button pButton pRipple type="button" icon="pi pi-clock" label="Confirm - Needs Review" (click)="confirmPartialInstance(match.instanceOfCare.instanceId, STATUS_CONFIRMED_NEEDS_REVIEW)"></button></div> -->
                            <!-- <div class="m-1"><button pButton pRipple type="button" icon="pi pi-thumbs-down" label="Close - Treated At Provider To Beat" (click)="closePartialInstance(match.instanceOfCare.instanceId, STATUS_CLOSED, NOT_LOW_COST_OPTION,false)"></button></div> -->
                            <!-- <div class="m-1"><button pButton pRipple type="button" icon="pi pi-times-circle" label="Close - Claim(s) Denied by Payor" (click)="closePartialInstance(match.instanceOfCare.instanceId, STATUS_CLOSED, PAYOR_DENIED,false)"></button></div> -->
                        </div>
                    </p-dialog>
                    <button pButton pRipple type="button" icon="pi pi-info" class="p-button-rounded" (click)="showInstanceDialog(instance.instanceId)"></button>
                </td>
            </tr>
        </ng-template>
    </p-table>
    </div>