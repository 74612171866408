import { ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild, ViewEncapsulation } from '@angular/core';
import { Login } from 'src/app/login/login.service';
import { resultMemoize, Store } from '@ngrx/store';
import { TcemsService } from 'src/app/api/tcems.service';
import { Encounter, EncounterGroup, EncounterType, UserEncounter } from 'src/app/model/encounter/encounter';
import { Diary } from 'src/app/model/support/diary';
import { LazyLoadEvent, MenuItem } from 'primeng/api';
import { LazyBuffer, LazyDiaryBuffer, LazyEncounterBuffer, LazyUserDiaryBuffer, LazyUserEncounterGroupBuffer, PagingResult, SortDetails, SortSpec } from 'src/app/model/util/resultpaging';
import { Phi, PhiUpdate } from 'src/app/model/phi/phi';
import { Plan, PlanType, PlanUpdate } from 'src/app/model/phi/plan';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { updateUserState } from 'src/app/actions/api/tcems.actions';
import { User, UserPreferences, UserState } from 'src/app/model/util/user';
import { loggedIn } from 'src/app/actions/login.actions';
import { selectCurrentUser, selectUserState } from 'src/app/state/app.state';
import { initialNetworkState } from 'src/app/reducers/api/tcems/globals.reducer';
import { TcemsUtilitiesService } from 'src/app/util/tcems-utilities.service';
import { EncounterProvider } from 'src/app/model/encounter/encounter_provider';
import { Category } from 'src/app/model/claim/category';
import { formatDate } from '@angular/common';
import { EncounterSummary } from 'src/app/model/encounter/encounter_summary';
import { Company } from 'src/app/model/support/company';
import { Network } from 'src/app/model/support/network';
import { combineLatest, forkJoin } from 'rxjs';
import { EncounterStatus } from 'src/app/model/encounter/encounter_status';
import { OverlayPanel } from 'primeng/overlaypanel';
import { TabPanel } from 'primeng/tabview';
import { Tooltip } from 'primeng/tooltip';

import { ChipModule } from 'primeng/chip';
import { ReferenceCode } from 'src/app/model/support/reference_code';
import { TwilioService } from 'src/app/services/twilio.service';
import { Address } from 'src/app/model/phi/address';
import { EmailAddress } from 'src/app/model/phi/email-address';

interface Personality {  // used for White Label branding
  primary: { "R": number, "G": number, "B": number, "O": number },
  secondary: { "R": number, "G": number, "B": number, "O": number },
  tertiary: { "R": number, "G": number, "B": number, "O": number },
  middleGradient: { "R": number, "G": number, "B": number, "O": number },
  text: { "R": number, "G": number, "B": number, "O": number },
  onError: { "R": number, "G": number, "B": number, "O": number },
  textAccent: { "R": number, "G": number, "B": number, "O": number },
  gradientStart: { "R": number, "G": number, "B": number, "O": number },
  gradientEnd: { "R": number, "G": number, "B": number, "O": number },
  background: { "R": number, "G": number, "B": number, "O": number },
  logo: string
}

// export abstract class PhiSearchResult
// {
//   phiId: number;
//   abstract result: Phi | EncounterSummary | Plan;
//   abstract get displayName():string;
// }

// export class PhiResult extends PhiSearchResult
// {
//   result: Phi;

//   get displayName(): string
//   {
//     return this.result.firstName + " " + this.result.lastName + " " + formatDate(this.result.dob, "MM/dd/yyyy","en-us");
//   }
// }

// export class PlanResult extends PhiSearchResult
// {
//   result: Plan;

//   get displayName(): string
//   {
//     return this.result.memberId + " " + this.result.phi.firstName + " " + this.result.phi.lastName;
//   }
// }

// export class EncounterResult extends PhiSearchResult
// {
//   result: EncounterSummary;

//   get displayName(): string
//   {
//     return this.result.encounterId.toString() + " " + this.result.patientFirstName + " " + this.result.patientLastName;
//   }
// }


@Component( {
  selector: 'app-home-page',
  templateUrl: './home-page.component.html',
  styleUrls: [ './home-page.component.scss' ]
} )
export class HomePageComponent implements OnInit, OnChanges
{
  @Input() loginStatus: Login;
  @Input() showUserHomePage: boolean = false;
  @Output() showUserHomePageChange: EventEmitter<boolean> = new EventEmitter<boolean>()

  showMessaging: boolean = false;

  twilioNumber: string;

  showClosedEncounters = false;
  tabIndex = 0;

  loggedIn$ = this.store.select(selectCurrentUser);
  loggedInUser: User;

  userState$ = this.store.select(selectUserState);
  userState: UserState;

  encounterCreateOptions: MenuItem[];

  // This is the complete list of user encounters that we're working with
  userEncounters: UserEncounter[] = [];
  diaryPhi: {[diaryId: number]: Phi} = {};

  showBusy: boolean = false;

  newPatientIsSubscriber: boolean = true;
  newPatientIsCaller: boolean = true;
  useExistingSubscriber: boolean = true;
  useExistingCaller: boolean = true;

  //New Person Properties
  pendingPatient: Phi = new Phi();
  pendingCompany: Company = null;
  pendingNetwork: Network = null;
  pendingHSAEligible: boolean = false;
  pendingPlanType: PlanType = null;
  pendingSubscriber: Phi = new Phi();
  pendingPatientMemberId: string = null;
  pendingSubscriberMemberId: string = null;  
  pendingCaller: Phi = new Phi();
  pendingCallerMemberId: string = null;

  //Existing Person Stuff
  existingSubscriberSearchResult: Phi;
  existingSubscriber: Phi = null;
  existingSubscriberPlan: Plan = null;

  //Existing Caller Stuff
  existingCallerSearchResult: Phi;
  existingCaller: Phi = null;
  existingCallerPlan: Plan = null;

  //companySearchResults: Company[] = [];
  availableNetworks: Network[] = [];

  // selectedPhiSearchResult: PhiSearchResult; //{phiId: number,result:{patient: Phi, encounter: Encounter, plan: Plan}};

  availableEncounterTypes: EncounterType[];
  encounterTypeFilter: EncounterType[];

  //default categories for unassigned encounters
  // defaultCategories: Category[] = [];

  //Encounter Group Scroller
  encounterGroupBuffer: LazyUserEncounterGroupBuffer;
  egScrollSize: number = 10;
  egBufferSize: number = this.egScrollSize * 2;
  egRowHeight: number = 75;
  egRowHeightInPixels: string = this.egRowHeight.toString() + 'px';
  egScrollerHeight: string = (this.egRowHeight * this.egScrollSize).toString() + 'px';

  
  //Recent Encounter Scroller
  recentEncounters: LazyEncounterBuffer;
  reScrollSize: number = 6;
  reBufferSize: number = this.reScrollSize * 2;
  reRowHeight: number = 75;
  reRowHeightInPixels: string = this.reRowHeight.toString() + 'px';
  reScrollerHeight: string = (this.reRowHeight * this.reScrollSize).toString() + 'px';


  //Pinned Encounter Scroller
  pinnedEncounters: LazyEncounterBuffer;
  peScrollSize: number = 6;
  peBufferSize: number = this.peScrollSize * 2;
  peRowHeight: number = 75;
  peRowHeightInPixels: string = this.peRowHeight.toString() + 'px';
  peScrollerHeight: string = (this.peRowHeight * this.peScrollSize).toString() + 'px';

  //Workbench tab visibility
  mineTabSelected: boolean = false;
  recentTabSelected: boolean = true;
  pinnedTabSelected: boolean = false;

  whiteLabelPersonality: Personality;

  // @ViewChild('missingEmailOverlay') missingEmailOP: OverlayPanel;
  // @ViewChild('memberTab') memberTab;
  get missingEmail(): boolean
  {
    const emailMember: EmailAddress = this.currentEncounterGroup?.phi?.emailAddresses?.filter( a => a.active )[0];
    return emailMember == null;
    //return this.currentEncounterGroup != null && (this.currentEncounterGroup.phi.emailAddresses == null || this.currentEncounterGroup.phi.emailAddresses.length == 0)
  }
  // @ViewChild('missingEmailDiv') missingEmailTooltip: Tooltip;


  // getSelectedPhiLabel(): string
  // {
  //   if(this.selectedPhiSearchResult == null) return "";
  //   if(this.selectedPhiSearchResult.result?.patient != null) return this.selectedPhiSearchResult.result.patient.firstName + " " + this.selectedPhiSearchResult.result.patient.lastName
  //   if(this.selectedPhiSearchResult.result?.plan != null) return this.selectedPhiSearchResult.result.plan.memberId;
  //   if(this.selectedPhiSearchResult.result?.encounter != null) return this.selectedPhiSearchResult.result.encounter.encounterId.toString();
  //   return "";
  // }

  // private _phiSearchResults: PhiSearchResult[] //{phiId: number,result:{patient: Phi, encounter: Encounter, plan: Plan}}[] = [];
  // get phiSearchResults(): PhiSearchResult[] //{phiId: number,result:{patient: Phi, encounter: Encounter, plan: Plan}}[]
  // {
  //   // //sort our results so that all phi results, are followed by plan, and then encounter results
  //   // let phiResults = this._phiSearchResults.filter(s => s.result.patient != null);
  //   // let planResults = this._phiSearchResults.filter(s => s.result.plan != null);
  //   // let encounterResults = this._phiSearchResults.filter(s => s.result.encounter != null);

  //   // return [... phiResults, ... planResults, ... encounterResults];
  //   return this._phiSearchResults;

  // }
  // set phiSearchResults(val: PhiSearchResult[])//{phiId: number,result:{patient: Phi, encounter: Encounter, plan: Plan}}[])
  // {
  //   this._phiSearchResults = val;
  // }


  // These are the open user encounters I Own
  // get myEncounters(): UserEncounter[]
  // {
  //   return this.userEncounters.filter(ue => ue.openEncounters.some(e => e.assignedTo != null && e.assignedTo.userId == this.currentUserId && this.encounterTypeFilter.map(tf => tf.encounterTypeId).includes(e.encounterType.encounterTypeId)) );
  // }

  // // And these are the ones I have pinned
  // get pinnedEncounters(): UserEncounter[]
  // {
  //   return this.userEncounters.filter(ue => ue.pinnedEncounters.some(e => this.userState.pinnedEncounters.includes(e.encounterId) && this.encounterTypeFilter.map(tf => tf.encounterTypeId).includes(e.encounterType.encounterTypeId) ));
  // }

  // // And, lastly, these are the ones which are recent
  // // recentEncounters: UserEncounter[] = [];
  // get recentEncounters(): UserEncounter[]
  // {
  //   if(this.userState != null && this.userState.recentLookups != null && this.userEncounters != null && this.userEncounters.length > 0)
  //   {
  //     // console.log(this.userEncounters.map(ue => ue.recentEncounters.map(re => re.encounterType)));
  //     let re = this.userEncounters.filter(ue => ue.recentEncounters.some(e => this.userState.recentLookups.includes(e.encounterId) && this.encounterTypeFilter.map(tf => tf.encounterTypeId).includes(e.encounterType.encounterTypeId)));
  //     // sort this list according to the list of recent encounters in the user state
  //     let returnSet = new Set<UserEncounter>();
  //     this.userState.recentLookups.forEach( rl => returnSet.add(re.find(e => e.allEncounters.some(ee => ee.encounterId == rl))));
  //     let retArray = Array.from(returnSet);
  //     return retArray.length > 0 && retArray[0] != null ? retArray : null;
  //   }
  //   return null;
  // }

  get currentUserId(): number
  {
    // Convenience so we can override for testing
    return this.loginStatus?.currentUser?.userId;
  }

  get currentUser(): User
  {
    return this.loginStatus?.currentUser;
  }



  showAllEncounters = false;
  currentMember: UserEncounter = null;
  currentEncounterGroup: EncounterGroup = null;
  // diaries: Diary[] = [];//   = Array.from({length:1000});






  constructor(
    private tcems: TcemsService,
    private store: Store,
    public tcemsUtility: TcemsUtilitiesService,
    private changeDetectorRef: ChangeDetectorRef,
    private twilioService: TwilioService,
    private route: ActivatedRoute
  ) 
  { 
    this.encounterCreateOptions = [
      {
          label: 'New Registration Encounter',
          command: () => {
              this.newRegistration();
          }
      }
  ];


  }

  ngOnChanges(changes: SimpleChanges): void 
  {
    this.ngOnInit();
  }
  


  get hasActivePlan(): boolean
  {
    const plan: Plan = this.currentEncounterGroup?.phi?.plans?.filter( p => p.active )[0];
    const company: Company = this.currentEncounterGroup?.phi?.company;
    return plan != null && company!= null && company.active;
  }

  get hasActiveAddress(): boolean
  {
    const addrMember: Address = this.currentEncounterGroup?.phi?.addresses?.filter( a => a.active )[0];
    return addrMember != null;
  }

  get currentMemberSubscriber(): Phi
  {
    const plan: Plan = this.currentEncounterGroup.phi.plans.filter( p => p.active )[0];

    if ( plan == null || plan.isSubscriber )
      {
        return this.currentEncounterGroup.phi;
      }
    else
      {
        return plan?.dependentOf?.phi;
      }
  }

  ngOnInit(): void
  {
    //Subscribe to a logged in event and set our user.
    //Also, make sure we initialize our user encounters when this happens
    this.loggedIn$.subscribe(
      u =>
      {
        if(u == null) return;
        this.loggedInUser = Object.assign(new User(),u);
        // this.userState = Object.assign(new UserState(), u.userState);
        
        this.loadMyEncounters();
      });

    this.userState$.subscribe(
      us =>
      {
        const oldState = this.userState;
        this.userState = Object.assign(new UserState(), us);

        //If we had a null old state and a non-null new state
        //or, we have different values for any of our pinned or recent encounters, we'll want to reload things
        if(us != null && (oldState == null || JSON.stringify(oldState.pinnedEncounters) != JSON.stringify(us.pinnedEncounters)))
        {
          this.loadMyPinnedEncounters(us.pinnedEncounters);
        }
        if(us != null && (oldState == null || JSON.stringify(oldState.recentLookups) != JSON.stringify(us.recentLookups)))
        {
          this.loadMyRecentEncounters(us.recentLookups);
        }

        // //Change the default categories if they have changed
        // if((us != null && us.userPreferences != null) && ((oldState == null || oldState.userPreferences == null)  || oldState.userPreferences.defaultCategoriesForUnassignedEncounters != us.userPreferences.defaultCategoriesForUnassignedEncounters))
        // {
        //   this.defaultCategories = this.tcemsUtility.categories.filter(c => us.userPreferences.defaultCategoriesForUnassignedEncounters.includes(c.categoryId));
        // }

        
      }
      
    );

    this.tcemsUtility.encounterTypes$.subscribe
    (
      et =>
      {
        //Limit to inbound and outbound encounter types
        this.availableEncounterTypes = et.filter(t => t.encounterTypeId == 1 || t.encounterTypeId == 6);
        this.encounterTypeFilter = this.availableEncounterTypes;
      }
    )

    // //set our default categories for the unassigned encounters
    // combineLatest([this.tcemsUtility.categories$, this.loggedIn$]).subscribe
    // (
    //   results =>
    //   {
    //     if(results[0] != null && results[0].length > 0 && results[1] !=null)
    //     {
    //       let userState = Object.assign(new UserState(), results[1].userState);
    //       userState.userPreferences = Object.assign(new UserPreferences(), userState.userPreferences);
    //       if(userState.userPreferences.defaultCategoriesForUnassignedEncounters == null || userState.userPreferences.defaultCategoriesForUnassignedEncounters.length==0)
    //       {
    //         userState.userPreferences.defaultCategoriesForUnassignedEncounters = [1,2];
    //         this.store.dispatch({type: updateUserState.type, userState: userState});
    //       }
    //       this.defaultCategories = results[0].filter(c => userState.userPreferences.defaultCategoriesForUnassignedEncounters.includes(c.categoryId));
    //     }
    //   }
    // )

    this.twilioService.currentTwilioNumber.subscribe( t => {
      if (t != "")
      {
        var phi = new Phi();
        phi.phiId = Number(t);
        this.loadUserEncounterFromPhi(phi.phiId);
      }
    } )

    this.route.url.
    subscribe
    (
      u => 
      {
        console.log(u);
      }
    )

    //Handle routes directly into an encounter
    this.route.paramMap.
    subscribe
    (
      (params: ParamMap) => 
      {
        let encId = +params.get('encounterId');
        let phiId = +params.get('phiId');
        if(phiId == 0 && encId != 0)
        {
          this.loadEntityFromEncounterId(encId);
        }
        else if(phiId !=0)
        {
          this.loadUserEncounterFromPhi(phiId,encId != 0 ? encId : null );
        }
        else
        {
          this.showUserHomePage = true;
          this.showUserHomePageChange.emit(true);
        }
      }
    )

  }


  toggleOptOut($value)
  {
    this.currentEncounterGroup.phi.hasOptedOut = $value;
  }

  loadMyEncounters(): void
  {
    //Load up our encounter groups
    let ss = new SortSpec();
    ss.sortFields[LazyUserEncounterGroupBuffer.SORT_BY_FIRST_NAME] = new SortDetails(SortDetails.DIR_ASC,SortDetails.NOT_A_LIST);
    ss.sortFields[LazyUserEncounterGroupBuffer.SORT_BY_LAST_NAME] = new SortDetails(SortDetails.DIR_ASC,SortDetails.NOT_A_LIST);

    this.encounterGroupBuffer = new LazyUserEncounterGroupBuffer(this.tcems,this.egBufferSize,ss,this.tcemsUtility.currentUser.userId,false,this.changeDetectorRef); 
    this.encounterGroupBuffer.lazyLoadFromBuffer(0,this.egScrollSize);
  }

  loadMyRecentEncounters(recentEncounters:number[]): void
  {
    //Don't load if we don't have any
    if (recentEncounters == null || recentEncounters.length == 0) return;

    // //clean up/dedup our recent encounters
    // let uniqueRecent:Set<number> = new Set(recentEncounters);
    // if(uniqueRecent.size != this.userState.recentLookups.length)
    // {
    //   let newState = Object.assign(new UserState(), this.userState);
    //   newState.recentLookups = [...uniqueRecent];
    //   this.store.dispatch({type: updateUserState.type, userState: newState});
    // }

    //We're going to get all recent encounters, sorteed AS IS in the pinned encounter list
    this.recentEncounters = new LazyEncounterBuffer(this.tcems,this.reBufferSize,null,{encounterIds:[...recentEncounters.slice(0,100)]},this.changeDetectorRef,true);
    this.recentEncounters.lazyLoadFromBuffer(0,this.reScrollSize);
  }

  loadMyPinnedEncounters(pinnedEncounters: number[]): void
  {
    if (pinnedEncounters == null || pinnedEncounters.length == 0) return;

    let ss = new SortSpec();
    ss.sortFields["Patient.Phi.FirstName"]=new SortDetails(SortDetails.DIR_ASC,SortDetails.NOT_A_LIST);;
    ss.sortFields["Patient.Phi.LastName"]=new SortDetails(SortDetails.DIR_ASC,SortDetails.NOT_A_LIST);;
    //These will be loaded/sorted by name
    this.pinnedEncounters = new LazyEncounterBuffer(this.tcems,this.peBufferSize,ss,{encounterIds:pinnedEncounters},this.changeDetectorRef);
    this.pinnedEncounters.lazyLoadFromBuffer(0,this.peScrollSize);
  }

  getUserEncounters(userId: number): void
  {
    // this.showBusy = true;
    //this.updateOpening( true );
    // this.tcems.getUserEncounters( userId, true )
    //     .toPromise()
    //     .then
    //     (
    //       result =>
    //       {
    //         this.userEncounters = result.map( r => Object.assign( new UserEncounter(), r ) );

    //         // Make sure that the user encounter phi objects are ALSO assigned to objects
    //         this.userEncounters.forEach
    //         (
    //           ue =>
    //           {
    //             ue.phi = Object.assign(new Phi(), ue.phi);
    //           }
    //         );
    //         // console.log(this.userEncounters);
    //         this.updateOpening( false );
    //         this.showBusy = false;
    //       }
    //     )
    //     .catch
    //     (
    //       e =>
    //       {
    //         // this.message = e.errorMessage;
    //         this.showBusy = false;
    //       }
    //     );
  }

  updateOpening( opening: boolean ): void
  {
    // this.opening = opening;
    // this.searchIcon = opening ? 'pi pi-spin pi-spinner' : '';
  }

  toggleHomePage()
  {
    this.showUserHomePage = !this.showUserHomePage;
    this.showUserHomePageChange.emit(this.showUserHomePage);
  }

  showMemberDetails(
    event,
    encounterGroup: EncounterGroup,
    goToEncounter: number = null
  ): void
  {
    this.showBusy = true;
    if(this.showUserHomePage)
    {
      this.toggleHomePage();
    }
    this.showClosedEncounters = false;
  

    this.currentEncounterGroup = encounterGroup;
    if (this.currentEncounterGroup.phi.company?.whiteLabelDetail != null)
      this.whiteLabelPersonality = JSON.parse(this.currentEncounterGroup.phi.company.whiteLabelDetail.personality) as Personality
    else
      this.whiteLabelPersonality = {
        "primary":{"R":27,"G":0,"B":121,"O":1},
        "secondary":{"R":15,"G":209,"B":177,"O":1},
        "tertiary":{"R":255,"G":102,"B":0,"O":1},
        "middleGradient":{"R":0,"G":0,"B":0,"O":1},
        "text":{"R":0,"G":0,"B":0,"O":1},
        "onError":{"R":40,"G":40,"B":40,"O":1},
        "textAccent":{"R":40,"G":40,"B":40,"O":1},
        "gradientStart":{"R":39,"G":136,"B":196,"O":1},
        "gradientEnd":{"R":10,"G":10,"B":10,"O":1},
        "background":{"R":255,"G":255,"B":255,"O":1},
        "logo":"Hemi-Harlowe_50x313.png"
      } 

    if(goToEncounter != null)
    {
      this.tabIndex = this.getTabIndexForEncounter(goToEncounter);
      this.addRecentEncounter(goToEncounter);
    }
    else
    {
      this.tabIndex = 0;
    }

    this.showBusy = false;

    this.tcemsUtility.clearMessages();
    if(this.missingEmail)
    {
      this.tcemsUtility.displayWarn("Missing Email Address","Member needs valid email address",true);
    }
    if(!this.hasActiveAddress)
    {
      this.tcemsUtility.displayWarn("Missing Address","Member needs valid mailing address",true);
    }
  }

  showMemberDetailsDeprecated(
    event,
    userEncounter: UserEncounter,
    goToEncounter: number = null
  ): void
  {
    this.showBusy = true;
    if(this.showUserHomePage)
    {
      this.toggleHomePage();
    }
    this.showClosedEncounters = false;

    const allEnc = userEncounter?.allEncounters;
    const openEnc = userEncounter?.allOpenEncounters;
    const pinnedEnc = userEncounter?.pinnedEncounters;
    // const openAndPinnedEnc = userEncounter.myOpenAndPinnedEncounters(this.currentUserId);

    let allPromises: Promise<any>[] = [];

    // userEncounter.openEncounters = [];
    // userEncounter.pinnedEncounters = [];
    if(allEnc != null && allEnc.length > 0)
    {
      allEnc.forEach
      (
        oe => allPromises = [...allPromises,this.tcems.getEncounter( oe.encounterId )
                  .toPromise()
                  .then
                  (
                    result =>
                    {
                      // userEncounter.openEncounters.find(oe => oe.encounterId == result.encounterId)[0] = result;
                      let encounters = [...userEncounter.allEncounters];
                      encounters[encounters.findIndex(e => e.encounterId == oe.encounterId)] = Object.assign(new Encounter(), result);
                      userEncounter.allEncounters = encounters;
                      // this.currentMember.all = encounters;
                      return result;
                    }
                  )]
      );
    }

    if(openEnc != null && openEnc.length > 0)
    {
      // TODO - See if this is used anywhere
      openEnc.forEach
            (
              oe => allPromises = [...allPromises,this.tcems.getEncounter( oe.encounterId )
                        .toPromise()
                        .then
                        (
                          result =>
                          {
                            // userEncounter.openEncounters.find(oe => oe.encounterId == result.encounterId)[0] = result;
                            let encounters = [...userEncounter.allOpenEncounters];
                            encounters[encounters.findIndex(e => e.encounterId == oe.encounterId)] = Object.assign(new Encounter(), result);
                            userEncounter.allOpenEncounters = encounters;
                            //  this.currentMember.openEncounters = encounters;
                            return result;
                          }
                        )]
            );
    }
    if(pinnedEnc != null && pinnedEnc.length > 0)
    {
      pinnedEnc.forEach
              (
                oe => allPromises = [...allPromises,this.tcems.getEncounter( oe.encounterId )
                          .toPromise()
                          .then
                          (
                            result =>
                            {
                                // userEncounter.pinnedEncounters.find(oe => oe.encounterId == result.encounterId)[0] = result
                                // userEncounter.pinnedEncounters = userEncounter.pinnedEncounters.concat( [ result ] )
                                let encounters = [...userEncounter.pinnedEncounters];
                                encounters[encounters.findIndex(e => e.encounterId == oe.encounterId)] = Object.assign(new Encounter(), result);
                                userEncounter.pinnedEncounters =   encounters;
                                // this.currentMember.pinnedEncounters = encounters;
                                return result;
                            }
                          )]
              );
    }
    allPromises = [...allPromises,this.tcems.getPhi( userEncounter.phi.phiId )
        .toPromise()
        .then
        (
          result =>
          {
            userEncounter.phi = Object.assign( new Phi(), result );
            return result;
          }
        )];

    //Wait for all promises to complete so we can stop being busy
    //and then go to the correct tab in our details (if we were supplied an encounter)
    //We also want to add this user encounter to our recent encounter list
    Promise.all(allPromises).then
    (
      result =>
        {
          this.showBusy = false;

          this.currentMember = userEncounter;

          if(goToEncounter != null)
          {
            this.tabIndex = this.getTabIndexForEncounter(goToEncounter);
            this.addRecentEncounter(goToEncounter);
          }
          else
          {
            this.tabIndex = 0;
          }



        }
    );

  }

  addRecentEncounter(encounterId: number)
  {
            let newState = Object.assign(new UserState(),this.userState);
            //Fiddle with this thing in the recent encounter list
            //see if it's already in the list. If so, remove
            newState.recentLookups = this.userState.recentLookups.filter(e => e != encounterId);

            //Add it to the top
            newState.recentLookups = [encounterId, ...newState.recentLookups];

            //Fix up our recent encounter list
            //userEncounter.recentEncounters = [userEncounter.allEncounters.find(e => e.encounterId == goToEncounter),...userEncounter.recentEncounters];
            // this.currentMember.recentEncounters = this.currentMember.recentEncounters.filter(re => re.encounterId != encounterId);
            // this.currentMember.recentEncounters = [this.currentMember.allEncounters.find(e => e.encounterId == encounterId),...this.currentMember.recentEncounters]

            //We may already have had this user encounter in our list
            // let ue = this.userEncounters.find(ue => ue.phi.memberId == this.currentMember.phi.memberId);
            // if(ue == null)
            // {
            //   //New user encounter
            //   this.userEncounters = [...this.userEncounters, this.currentMember];
            // }
            // else
            // {
            //   ue.recentEncounters = this.currentMember.recentEncounters;
            //   //Trigger change detection
            //   this.userEncounters = [...this.userEncounters];
            // }

            //Fire an event that the encounter has been pinned
            this.store.dispatch({type: updateUserState.type, userState: newState});
            this.changeDetectorRef.detectChanges();
  }

  

  async getDiaryPatient(diary: Diary): Promise<Phi>
  {
    if (diary == null) return null;
    //See if we have this thing first
    if (this.diaryPhi[diary.diaryId] == null)
    {
      if (diary.referenceType.referenceType == "Encounter")
      {
        //Okay, we're working with an encounter
        let encounterId: number = diary.referenceId;

        //Get the encounter from the api
        await this.tcems.getEncounter(encounterId).toPromise().then
        (
          result =>
          {
            this.diaryPhi[diary.diaryId] =  Object.assign(new Phi(), result.patient.phi);
          }

        );
      }
    }

    return this.diaryPhi[diary.diaryId];



  }

  loadEntityFromDiary(diary: Diary)
  {

    if (diary.referenceType.code == ReferenceCode.TYPE_ENCOUNTER)
    {
      //Okay, we're working with an encounter
      let encounterId: number = diary.referenceId;
      this.loadEntityFromEncounterId(encounterId);
    }
    else if (diary.referenceType.code == ReferenceCode.TYPE_PHI)
    {
      //Okay, we're working with an encounter
      let phiId: number = diary.referenceId;
      this.loadUserEncounterFromPhi(phiId);
    }

    // let result = this.getDiaryPatient(diary);
    // this.getDiaryPatient(diary).then
    // (
    //   result =>
    //   {
    //       //Let's see if we already have this guy in our user encounter list
    //       this.loadUserEncounterFromPhi(result);
    //   }
    // )


    //What kind of entity are we related to

    // else if (diary.referenceType.referenceType == "")

  }

  loadEntityFromEncounterId(encounterId: number)
  {
    this.tcems.getEncounter(encounterId).toPromise().then
    (
      encounter => this.loadEntityFromEncounter(encounter)
    )
  }

  loadEntityFromEncounter(encounter: Encounter)
  {
    this.showBusy = true;
    this.loadUserEncounterFromPhi(encounter.patient.phi.phiId,encounter.encounterId);
  }

  loadUserEncounterFromPhi(phiId: number, encounter: number = null)
  {
    this.showBusy = true;
    // console.log(phi);
    // if (this.userEncounters.map(ue => ue.phi.phiId).includes(phi.phiId))
    // {
    //   // Great! Just zip over to that
    //   this.showMemberDetails(null,this.userEncounters.find(ue => ue.phi.phiId == phi.phiId),encounter);

    //   // TODO - Scroll to User Encounter in list
    // }

    // Once we have our encounter, we will load our user encounter from the patient
    this.tcems.getEncounterGroupingsForPhi(phiId).toPromise().then
    (
      result =>
      {
        let eg: EncounterGroup = Object.assign(new EncounterGroup(), result.records[0]);

        //Now we have our user encounter!
        //map all the encounter objects correctly
        eg.encounters = eg.encounters.map(e => Object.assign(new Encounter(), e));
        
        //And the phi
        eg.phi = Object.assign(new Phi(), eg.phi);

        this.showMemberDetails(null, eg ,encounter);
        // return result;
      }
   )
   .catch
   (
     error => 
     {
       this.tcemsUtility.displayError("Error",error.message);
       console.log(error);
       this.showBusy = false;
     }
   );
  }

  isPinnedEncounter(encounterId: number):boolean
  {
    return this.userState.pinnedEncounters.includes(encounterId);
  }

  togglePin(event, encounter: Encounter)
  {
    //Fetch State from db
    //dispatch new state
    //Update state with change in pinned encounter
    //dispatch new state

    let newState = Object.assign(new UserState(),this.userState);

    if(this.isPinnedEncounter(encounter.encounterId))
    {
      newState.pinnedEncounters = this.userState.pinnedEncounters.filter(pe => pe != encounter.encounterId);
      //this.currentMember.pinnedEncounters = this.currentMember.pinnedEncounters.filter(pe => pe.encounterId != encounter.encounterId);

    }
    else
    {
      let newPinned: number[] = [...this.userState.pinnedEncounters,encounter.encounterId];
      newState.pinnedEncounters = Array.from(new Set(newPinned));
      //Also add it to the list of pinned encounters in the user encounter
      //this.currentMember.pinnedEncounters = [...this.currentMember.pinnedEncounters, encounter];
    }


    //Sync our pinned encounter list with our fill list
    //this.userEncounters.find(ue => ue.phi.memberId == encounter.patientPlan.memberId).pinnedEncounters = this.currentMember.pinnedEncounters;


    //Trigger change detection
    //this.userEncounters = [...this.userEncounters];


    //Fire an event that the encounter has been pinned
    this.store.dispatch({type: updateUserState.type, userState: newState});
  }

  currentMemberChanged(phi: Phi)
  {
    this.currentEncounterGroup.phi = phi;
    this.currentEncounterGroup = Object.assign(new EncounterGroup(), this.currentEncounterGroup);

    this.tcemsUtility.clearMessages();
    if(this.missingEmail)
    {
      this.tcemsUtility.displayWarn("Missing Email","Member needs valid email address",true);
    }
    if(!this.hasActiveAddress)
    {
      this.tcemsUtility.displayWarn("Missing Address","Member needs valid mailing address",true);
    }

  }

  newRegistration()
  {
    this.newEncounter(2,5);
  }


  newEncounter(encounterTypeId: number = 1, encounterStatusId: number = 1)
  {

      this.tcems.createNewEncounterForPhi(this.currentEncounterGroup.phi.phiId,encounterTypeId,encounterStatusId).toPromise()
      .then
      (
        newEnc =>
        {
          let encounter = Object.assign(new Encounter(), newEnc);
          //Add it to our list. It should show up in our dang pile now
          //this.currentMember.allEncounters = [...this.currentMember.allEncounters, encounter];
          //this.currentMember.openEncounters = [...this.currentMember.openEncounters, encounter];
          this.currentEncounterGroup.encounters = [...this.currentEncounterGroup.encounters, encounter];
          this.changeDetectorRef.detectChanges();
          //this.tabIndex =  this.currentMember.myOpenEncounters(this.currentUserId).length;
          if(this.currentEncounterGroup.myOpenEncounters(this.currentUserId).length == 0)
            this.tabIndex = 1;
          else
            this.tabIndex = this.currentEncounterGroup.myOpenEncounters(this.currentUserId).length;
        }
      )
  }

  addEncounterProvider(encounter: Encounter, ep: EncounterProvider)
  {
    //encounter.encounterProviders = [... encounter.encounterProviders, ep];
  }

  removeEncounterProvider(encounter: Encounter, ep: EncounterProvider)
  {
    //encounter.encounterProviders = encounter.encounterProviders.filter(eep => eep.id != ep.id);
  }

  setEncounterCategory(encounter: Encounter, c: Category)
  {
    encounter.category = c;
  }

  handleEncounterTabChange(event)
  {
    //Find the encounter we went to
    let encounter: Encounter;
    let myOpenLen = this.currentEncounterGroup.myOpenEncounters(this.currentUserId).length;
    let otherOpenLen = this.currentEncounterGroup.otherOpenEncounters(this.currentUserId).length;
    let closedLen = this.currentEncounterGroup.closedEncounters.length;

    if (event.index > 0 && event.index - 1 < myOpenLen)
    {
      encounter = this.currentEncounterGroup.myOpenEncounters(this.currentUserId)[event.index - 1 ];
    }
    else if (event.index > 0 &&  event.index - 1 < myOpenLen + otherOpenLen)
    {
      encounter = this.currentEncounterGroup.otherOpenEncounters(this.currentUserId)[event.index - myOpenLen - 1];
    }
    else if (event.index > 0 && event.index - 1 < myOpenLen + otherOpenLen + closedLen)
    {
      encounter = this.currentEncounterGroup.closedEncounters[event.index -myOpenLen - otherOpenLen- 1];
    }
    else
    {
      encounter = null;
    }
    if (encounter != null)
    {
      this.addRecentEncounter(encounter.encounterId);
    }
  }

  // phiSearch($event)
  // {
  //   let q = $event.query;
  //   // console.log("Search:" + q);

  //   //Depending on the search string, we want to build our results a bit differently
  //   //Numbers mean that we're looking at member ids and/or encounter ids
  //   //strings mean that we're looking at member ids and/or phi

  //   let allPromises: Promise<PhiSearchResult[]>[] = [];

  //   if (q == null || q.length == 0 ) { return; } // empty query
  //   if (/^-?\d+$/.test(q)) // Numbers only
  //   {
  //     // Do Encounters
  //     allPromises = [... allPromises, this.tcems.searchForEncounters({q:q}).toPromise()
  //     .then
  //     (
  //       results =>
  //         {
  //           // console.log(results);
  //           return results.map
  //           (
  //             e =>
  //             {
  //               let encounterResult = new EncounterResult();
  //               encounterResult.result = e;
  //               encounterResult.phiId = e.patientPhiId;
  //               return encounterResult;
  //             }
  //           );
  //         }
  //     )
  //     .catch
  //     (
  //       error =>
  //       {
  //         console.log('PHI Search Failed: ' + error);
  //         // this.phiSearchResults = [];
  //         return null;
  //       }
  //     )
  //     ];
  //   }
  //   else //String based
  //   {
  //     // Do Phi
  //     allPromises = [...allPromises, this.tcems.searchForPhi({query: q}).toPromise()
  //     .then
  //     (
  //       results =>
  //         {
  //           // console.log(results);
  //           return results.map
  //           (
  //             p =>
  //             {
  //               let phiResult = new PhiResult();
  //               phiResult.result = p;
  //               phiResult.phiId = p.phiId;
  //               return phiResult;
  //             }
  //           );
  //         }
  //     )
  //     .catch
  //     (
  //       error =>
  //       {
  //         console.log('PHI Search Failed: ' + error);
  //         // this.phiSearchResults = [];
  //         return null;
  //       }
  //     )];

  //   }

  //   // And, also get Member Ids
  //   allPromises = [...allPromises, this.tcems.searchForPlan({query: q}).toPromise()
  //     .then
  //     (
  //       results =>
  //         {
  //           // console.log(results);
  //           return results.map
  //           (
  //             p =>
  //             {
  //               let phiResult = new PlanResult();
  //               phiResult.result = p;
  //               phiResult.phiId = p.phi.phiId;
  //               return phiResult;
  //             }
  //           );
  //         }
  //     )
  //     .catch
  //     (
  //       error =>
  //       {
  //         //console.log('PHI Search Failed: ');
  //         //console.log(error);

  //         // this.phiSearchResults = [];
  //         return null;
  //       }
  //     )];


  //   // Wait til we get everything back
  //   Promise.all(allPromises).then
  //   (
  //     //Set up our list of suggestions

  //     result =>
  //     {
  //       let flatResults:PhiSearchResult[] = [];
  //       result.forEach( subResult => {if (subResult != null ) flatResults = [...flatResults, ...subResult]});
  //       this.phiSearchResults = [... flatResults];
  //     }
  //   )






  //     // this.tcems.searchForPlan({query: q}).toPromise()
  //     // .then
  //     // (
  //     //   results =>
  //     //     {
  //     //       console.log(results);
  //     //       this.phiSearchResults = results.map(p => { return {phiId: p.phiId, result:{patient: p, encounter: null, plan: null}} } );
  //     //     }
  //     // )
  //     // .catch
  //     // (
  //     //   error =>
  //     //   {
  //     //     console.log("Error" + error);
  //     //     this.phiSearchResults = [];
  //     //   }
  //     // )
  // }

  phiSelectedFromSearch(event:{phi:Phi, encounterId:number})
  {
    // if (event == null || event.phiId == null || event.phiId == undefined) return;
    // // console.log("User Selected");
    // // console.log(event);

    // // See if we've got an encounter search result
    // let encounterId = event instanceof EncounterResult ? (event as EncounterResult).result.encounterId : null;

    this.loadUserEncounterFromPhi(event.phi.phiId,event.encounterId);
    // this.tcems.getPhi(event.phi.phiId).toPromise().then(result => this.loadUserEncounterFromPhi(Object.assign(new Phi(), result),event.encounterId));
  }

  getTabIndexForEncounter(encounter: number): number
  {
    //search by encounter id
    let eId: number = encounter;
    let enc: Encounter = this.currentEncounterGroup.myOpenEncounters(this.currentUserId).find(e => e.encounterId == eId);
    let index = 1; //Starting index is the second tab in our panel (first tab is PHI details)

    if(enc != null)
    {
      //Found it in my open encounters
      return index + this.currentEncounterGroup.myOpenEncounters(this.currentUserId).indexOf(enc);
    }

    enc = this.currentEncounterGroup.otherOpenEncounters(this.currentUserId).find(e => e.encounterId == eId);
    if(enc != null)
    {
      //Found it with other open encounters
      return index + this.currentEncounterGroup.myOpenEncounters(this.currentUserId).length
                  + this.currentEncounterGroup.otherOpenEncounters(this.currentUserId).indexOf(enc);

    }

    enc = this.currentEncounterGroup.closedEncounters.find(e => e.encounterId == eId);
    if(enc != null)
    {
      //It's a closed encounter
      this.showClosedEncounters = true;
      return index + this.currentEncounterGroup.myOpenEncounters(this.currentUserId).length
                  + this.currentEncounterGroup.otherOpenEncounters(this.currentUserId).length
                  + this.currentEncounterGroup.closedEncounters.indexOf(enc);
    }


    //if we get here, it's not an encounter for this phi. Just return -1
    return -1;

  }

  //New Person Setup
  newCompanySelected(event:Company)
  {
    //Load the available networks for this company
    this.availableNetworks = [];
    this.tcems.getNetworksForCompany(event.companyId).toPromise().then
    (
      networks =>
        this.availableNetworks = networks
    )
    this.pendingCompany = event;
  }
  
  // companySearch(event)
  // {

  //     let q = event.query;
  //     if (q == null || q.length == 0 ) { return; } // empty query

  //     //Check our api
  //     this.tcems.searchCompanies(q).toPromise().then
  //     (
  //       results =>
  //       {
  //         //We should filter these results.
  //         //Only show results that can be attached to
  //         this.companySearchResults = results.filter(c => c.hierarchyDetail != null && c.hierarchyDetail.attachmentTier == c.tier    ).map(c => Object.assign(new Company(), c));
  //       }
  //     )
  // }
  
  existingSubscriberSelected(event:{phi:Phi, encounterId:number})
  {
    this.tcems.getPhi(event.phi.phiId).toPromise().then
    (
      phi =>
      {
        this.existingSubscriber = Object.assign(new Phi(), phi);
        this.pendingCompany = phi.company;
        this.tcems.getNetworksForCompany(phi.company.companyId).toPromise().then
        (
          networks =>
            this.availableNetworks = networks
        )

      }
    )
  }

  existingSubscriberPlanSelected(event)
  {
    this.tcems.getPlan(event.value.memberId).toPromise().then
    (
      plan =>
      {
        //From here, we need to set all of our company, network plan type and hsa fields
        this.pendingPlanType = this.tcemsUtility.planTypes.find(pt => pt.planTypeId == plan.planType.planTypeId);
        this.pendingHSAEligible = plan.hsaEligible;
        this.pendingNetwork = this.availableNetworks.find(n => n.networkId == plan.network.networkId);
      }
    )

  }

  existingCallerSelected(event:{phi:Phi, encounterId:number})
  {
    this.tcems.getPhi(event.phi.phiId).toPromise().then
    (
      phi =>
      {
        this.existingCaller = Object.assign(new Phi(), phi);
      }
    )
  }

  confirmNewPerson(event, overlay)
  {
    //Add new Phi, which should also create the people reecord that attaches them to a company
    let subscriberPromise: Promise<Phi>;
    let patientPromise:Promise<Phi>;
    let callerPromise:Promise<Phi>;
    
    if ( this.pendingPatientMemberId != null ) this.pendingPatientMemberId = this.pendingPatientMemberId.toUpperCase();
    if ( this.pendingSubscriberMemberId != null ) this.pendingSubscriberMemberId = this.pendingSubscriberMemberId.toUpperCase();
    if ( this.pendingCallerMemberId != null ) this.pendingCallerMemberId = this.pendingCallerMemberId.toUpperCase();

    //If the patient isn't the subscriber, and we're not using an existing one then we need to set up the subscriber first
    if(!this.newPatientIsSubscriber && this.existingSubscriber == null)
    {
      subscriberPromise = this.tcems.newPhi({phi:this.pendingPatient.asUpdate(), companyId:this.pendingCompany.companyId}).toPromise().then
      (
        newPatient =>
        {
          //Create the plan for this patient
          let newPlan: PlanUpdate = new PlanUpdate();
          this.pendingPatientMemberId;
          newPlan.networkId = this.pendingNetwork.networkId;
          newPlan.active = true;
          newPlan.isSubscriber = this.newPatientIsSubscriber;
          newPlan.dependentOfMemberId = !this.newPatientIsSubscriber ? this.pendingSubscriberMemberId : null;
          newPlan.hsaEligible = this.pendingHSAEligible;
          newPlan.planType = this.pendingPlanType;
          newPlan.effectiveDate = new Date();
          newPlan.companyId = this.pendingCompany.companyId;
          
          return this.tcems.newPlan({phiId: newPatient.phiId, memberId:this.pendingSubscriberMemberId, plan:newPlan}).toPromise().then
          (
            newPlan => 
            {
              //Add the plan to the patient and return the new patient
              newPatient.plans = [newPlan];
              return newPatient;
            }
          )
        }
      )
      .catch
      (
        error =>
        {
          if(error.status == 409)
            this.tcemsUtility.displayError("Error","This subscriber already exists.",true);
          else
            this.tcemsUtility.displayError("Error","Could not add subscriber",true);
          throw error;
        }
      );
    }

    //Patient
    patientPromise = this.tcems.newPhi({phi:this.pendingPatient.asUpdate(), companyId:this.pendingCompany.companyId}).toPromise().then
    (
      newPatient =>
      {

        let p: Promise<Phi>;
        //We may need to wait for the subscriber promise to be done
        if(subscriberPromise != null && !this.newPatientIsSubscriber && this.existingSubscriber == null)
        {
          p = subscriberPromise;
        }
        else if(subscriberPromise == null && this.existingSubscriber != null)
        {
          p = new Promise<Phi>((resolve,reject) => resolve(this.existingSubscriber));
        }
        else
        {
          p = new Promise<Phi>((resolve,reject) => resolve(null));
        }

        //We have a promise that'll either resolve to null (patient is the subscriber) or to the phi of the subscriber

        return p.then
        (
          subscriber => 
          {
            //Create the plan for this patient
            let newPlan: PlanUpdate = new PlanUpdate();
            this.pendingPatientMemberId;
            newPlan.networkId = this.pendingNetwork.networkId;
            newPlan.active = true;
            newPlan.isSubscriber = this.newPatientIsSubscriber;
            newPlan.dependentOfMemberId = !this.newPatientIsSubscriber ? this.existingSubscriber != null ? this.existingSubscriberPlan.memberId : this.pendingSubscriberMemberId : null;
            newPlan.hsaEligible = this.pendingHSAEligible;
            newPlan.planType = this.pendingPlanType;
            newPlan.effectiveDate = new Date();
            newPlan.companyId = this.pendingCompany.companyId;
            

            return this.tcems.newPlan({phiId: newPatient.phiId, memberId:this.pendingPatientMemberId, plan:newPlan}).toPromise().then
            (
              newPlan => 
              {
                //Add the plan to the patient and return the new patient
                newPatient.plans = [newPlan];
                return newPatient;
              }
            )
          }
        )      
        

      }
    )
    .catch
    (
      error =>
      {
        if(error.status == 409)
          this.tcemsUtility.displayError("Error","This patient already exists.",true);
        else
          this.tcemsUtility.displayError("Error","Could not add patient",true);
        throw error;
      }
    );

    //Caller
    if(!this.newPatientIsCaller && this.existingCaller == null)
    {
      callerPromise = this.tcems.newPhi({phi:this.pendingCaller.asUpdate(), companyId:this.pendingCompany.companyId}).toPromise().then
      (
        newCaller =>
        {

          let p: Promise<Phi>;
          //We may need to wait for the subscriber promise to be done. 
          if(subscriberPromise != null && !this.newPatientIsSubscriber && this.existingSubscriber == null)
          {
            p = subscriberPromise;
          }
          else if(subscriberPromise == null && this.existingSubscriber != null)
          {
            p = new Promise<Phi>((resolve,reject) => resolve(this.existingSubscriber));
          }
          else
          {
            p = new Promise<Phi>((resolve,reject) => resolve(null));
          }

          //We have a promise that'll either resolve to null (patient is the subscriber) or to the phi of the subscriber

          return p.then
          (
            subscriber => 
            {
              //Create the plan for this patient
              let newPlan: PlanUpdate = new PlanUpdate();
              this.pendingCallerMemberId;
              newPlan.networkId = this.pendingNetwork.networkId;
              newPlan.active = true;
              newPlan.isSubscriber = false;
              newPlan.dependentOfMemberId = !this.newPatientIsSubscriber ? this.existingSubscriber != null ? this.existingSubscriberPlan.memberId : this.pendingSubscriberMemberId : null;
              newPlan.hsaEligible = this.pendingHSAEligible;
              newPlan.planType = this.pendingPlanType;
              newPlan.effectiveDate = new Date();
              newPlan.companyId = this.pendingCompany.companyId;
              

              return this.tcems.newPlan({phiId: newCaller.phiId, memberId:this.pendingCallerMemberId, plan:newPlan}).toPromise().then
              (
                newPlan => 
                {
                  //Add the plan to the patient and return the new patient
                  newCaller.plans = [newPlan];
                  return newCaller;
                }
              )
            }
          )

        }
      ).catch
      (
        error =>
        {
          if(error.status == 409)
            this.tcemsUtility.displayError("Error","This caller already exists.",true);
          else
            this.tcemsUtility.displayError("Error","Could not add caller",true);
          throw error;
        }
      );
    }
    
    //Okay... with all our promises in place, we can wait for it all to be done. Then we hand back our patient phi so we can open them as an
    //encounter user
    let phiPromises: Promise<Phi>[] = [];
    if(subscriberPromise != null) 
      phiPromises.push(subscriberPromise);
    if(patientPromise != null) 
      phiPromises.push(patientPromise);
    if(callerPromise != null) 
      phiPromises.push(callerPromise);
    

    Promise.all(phiPromises).then
    (
      phis =>
      {
        //Even though I get back all the phi, I only really care about the patient phi...
        let phi:Phi = phis.find(p => Object.assign(new Phi(), p).memberId == this.pendingPatientMemberId);

        overlay.hide();

        //This shouldn't be null...
        this.loadUserEncounterFromPhi(phi.phiId);

        //And, clear everything so we can do another new one if we want/need
        this.clearNewPersonData();
      }
    ).catch
    (
      error =>
      {
          console.log(error);
          this.clearNewPersonData();
      }
    );
   
  }

  clearNewPersonData()
  {
    this.newPatientIsSubscriber = true;
    this.newPatientIsCaller = true;
    this.useExistingSubscriber = true;
    this.useExistingCaller = true;
  
    //New Person Properties
    this.pendingPatient = new Phi();
    this.pendingCompany = null;
    this.pendingNetwork = null;
    this.pendingHSAEligible = false;
    this.pendingPlanType = null;
    this.pendingSubscriber = new Phi();
    this.pendingPatientMemberId = null;
    this.pendingSubscriberMemberId = null;  
    this.pendingCaller = new Phi();
    this.pendingCallerMemberId = null;
  
    //Existing Person Stuff
    // this.existingSubscriberSearchResult = null;
    this.existingSubscriber = null;
    this.existingSubscriberPlan = null;
  
    //Existing Caller Stuff
    // this.existingCallerSearchResult = null;
    this.existingCaller = null;
    this.existingCallerPlan = null;
  
    //this.companySearchResults = [];
    this.availableNetworks= [];  
  }

  workbenchTabChanged(event)
  {
    this.recentTabSelected = event.index == 0;
    this.pinnedTabSelected = event.index == 1;
    this.mineTabSelected = event.index == 2;
  }
  loadMoreEncounterGroups(event:LazyLoadEvent)
  {
      this.encounterGroupBuffer.lazyLoadFromBuffer(event.first, event.rows);
  }

  loadMorePinnedEncounters(event:LazyLoadEvent)
  {
      this.pinnedEncounters.lazyLoadFromBuffer(event.first, event.rows);
  }

  loadMoreRecentEncounters(event:LazyLoadEvent)
  {
      this.recentEncounters.lazyLoadFromBuffer(event.first, event.rows)
  }


  encounterStatusChanged(encounter: Encounter, status: EncounterStatus)
  {
    //If we are closing the encounter, we should set the selected index to the left
    //And refresh the view
    if(status.statusCode == EncounterStatus.STATUS_CLOSED || status.statusCode == EncounterStatus.STATUS_COMPLETE || status.statusCode == EncounterStatus.STATUS_CANCELED)
    {
      this.tabIndex = this.tabIndex - 1;
      this.changeDetectorRef.detectChanges();
    }
  }

}




