import { Practice } from '../provider/practice';
import { InstanceOfCare } from '../claim/instance_of_care';
import { Location } from '../provider/location';
import { Procedure } from './procedure';
import { Category } from './category';
import { Network } from '../support/network';
import { Line } from './line';
import { Distance} from '../geocode/distance';

export class ProcedureLookupResult
{
    /*procedures: Procedure[];
    practice: Practice;
    locations: Location[];
    instancesOfCare: InstanceOfCare[];
    instanceStandardDeviation: Number;
    instanceAverageCost: Number;
    instanceMedianCost: Number;
    instanceMinCost: Number;
    instanceMaxCost: Number;
    bestInstances: number[];
    acceptableInstances: number[];
    acceptableMax: number;
    acceptableMin: number;
    acceptableAverage: number;


    rawProcedureCost: number;
    minProcedureCost: number;
    maxProcedureCost: number;
    medianProcedureCost: number;
    averageProcedureCost: number;
    procedureStandardDeviation: number;

    public acceptableInstancesOfCare(): InstanceOfCare[]
    {
        // console.log("expanding");
        let ioc: InstanceOfCare[] = this.instancesOfCare.filter(i => this.acceptableInstances.includes(i.instanceId));
        // console.log(ioc);
        return ioc;
    } */

    procedures: Procedure[];
    zipCode: string;
    radius: number;
    searchBegin: Date;
    searchEnd: Date;
    network: Network;
    category:Category;
    specificProcedureResults: PracticeProcedureResult[];
    specificCategoryResults: PracticeCategoryResult[];
    specificInstanceResults: PracticeInstanceResult[];
    generalProcedureResults: PracticeProcedureResult[];
    generalCategoryResults: PracticeCategoryResult[];
    generalInstanceResults: PracticeInstanceResult[];


    // //These methods flatten the procedure data by provider so that we can get a total of the median cost of each procedure by provider
    // specificProcedureTotalForPractce(ein:string) : number
    // {
    //     return this.specificProcedureResults.filter(spr => spr.practice.ein == ein).map(spr => spr.median).reduce((a,b) => a + b, 0);
    // }

    // generalProcedureTotalForPractice(ein:string) : number
    // {
    //     return this.generalProcedureResults.filter(spr => spr.practice.ein == ein).map(spr => spr.median).reduce((a,b) => a + b, 0);
    // }
}


export class LookupResult
{
    mean: number;
    median: number;
    min: number;
    max: number;
    n: number;
}

export class ProcedureBasedResult extends LookupResult
{
    procedure: Procedure;
    lineIds: number[];
}

export class CategoryBasedResult extends LookupResult
{
    category: Category;
    instanceAndLineIds: {[key: number]: number[]};
}

export class InstanceBasedResult extends LookupResult
{
    instanceIds: number[];
}

export class LocationResult
{
    location: Location;
    distance: Distance;
}

export class PracticeResult
{
    practice: Practice;
    tier: number;
    locations: LocationResult[];
    get closestLocation():LocationResult
    {
        return this.locations.sort((a,b) => a.distance.length < b.distance.length ? -1 : a.distance.length == b.distance.length ? 0 : 1)[0];
    }
}

export class PracticeProcedureResult extends PracticeResult
{
    procedureBasedResults: ProcedureBasedResult[];
    practiceCost: number;
}

export class PracticeCategoryResult extends PracticeResult
{
    categoryBasedResult: CategoryBasedResult;
}

export class PracticeInstanceResult extends PracticeResult
{
    instanceBasedResult: InstanceBasedResult;
}

export class MRFPracticeResult extends PracticeResult
{
    procedureBasedResults: MRFProcedureResult[];
    practiceCost: number;
}


export class MRFProcedureResult
{
    mrfResults: MRFResult[];
    procedure: Procedure;
    topMRF: MRFResult;
}


export class MRFResult
{
    procedure: Procedure;
    price: number;
    professional: boolean;
    facility: boolean;
    procedurePriceId: number;
    n: number;
}