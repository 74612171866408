import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/api/api.service';
import { environment } from '../../../environments/environment';
import { Phi } from '../../model/phi/phi';
import { Address } from '../../model/phi/address';
import { EmailAddress } from '../../model/phi/email-address';
import { PhoneNumber } from '../../model/phi/phone-number';
import { Plan } from '../../model/phi/plan';


@Component({
  selector: 'app-subscriber-search',
  templateUrl: './subscriber-search.component.html',
  styleUrls: ['./subscriber-search.component.scss']
})
export class SubscriberSearchComponent implements OnInit {

  firstName:string = "";
  lastName:string = "";
  dob:Date;
  foundSet: Phi[];
  selectedId: number;
  selectedPhi: Phi;
  searching: boolean = false;
  message: string;
  searchIcon: string;


  constructor(private apiService : ApiService) { }

  ngOnInit(): void {
  }

  search() : void
  {
    this.message = '';
    this.updateSearching(true);
    this.apiService.makeAPIGetCall<Phi[]>(
      environment.tcemsAPIBase,
      environment.tcemsAPI_phi + "/search",
      {

          "firstName":this.firstName,
          "lastName":this.lastName,
          "dob":this.dob != null ? (this.dob.getMonth() + 1) + "/" + this.dob.getUTCDate() + "/" + this.dob.getFullYear() : ""
      }


    )
    //When done...
    .then((result: Phi[]) =>
      {
        this.foundSet = result;

        //sort by last name
        this.foundSet.sort((a,b) =>
        {
          var lNameComp:number = a.lastName.localeCompare(b.lastName);
          return lNameComp == 0 ? a.firstName.localeCompare(b.firstName) : lNameComp;
        });
        this.updateSearching(false);
      })

     //If there's an error...
    .catch(error => {
       this.message = error.error;
       this.updateSearching(false);
    });

  }

  select(selectedId: number): void
  {
    // Set our selected Id
    this.selectedId = selectedId;
    // Also, set our selected item
    this.selectedPhi = this.foundSet.find(phi => phi.phiId == selectedId);
    // console.log("selected: " + selectedId);
  }


  updateSearching(searching:boolean)
  {
    this.searching = searching;
    this.searchIcon = searching ? 'pi pi-spin pi-spinner' : '';
  }
}
