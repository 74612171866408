<p-dialog [modal]="true" [(visible)]="showBusy" [closable]="false" appendTo="body" [showHeader]="false" [style]="{'box-shadow':'none'}" [contentStyle]="{'background':'rgba(0,0,0,0)'}">
    <p-progressSpinner [style]="{'background':'rgba(0,0,0,0)','width':'50px','height':'50px'}" animationDuration=".5s" strokeWidth="8" ></p-progressSpinner>
</p-dialog>

<p-dialog #reLookupProviderToBeat [(visible)]="showRelookup">
    <div>
        <app-procedure-lookup *ngIf="showRelookup"
        [(selectedProcs)]="relookupProcs"
        [selectedCategory]="encounter.category"
        [zipCode]="encounter.patientZip"
        [radius]=25
        [lookbackDays]=10
        [initialStep]=3
        [network]="encounter.patientNetwork"
        [ein]="relookupEIN"
        (encounterProviderChange)="relookupEncounterProvider = $event"
        >
        </app-procedure-lookup>
    </div>
    <div class="flex justify-content-center align-items-center">
        <div class="flex justify-content-center align-items-center">
            <div>
              <span class="p-buttonset">
                <button pButton pRipple label="Cancel"  (click)="showRelookup = false;" class="p-button-rounded p-button-danger"></button>
                <button pButton pRipple label="Select" [disabled]="!encounterProviderReady()"
                        (click)="showRelookup = false; newPtBSelected(relookupEncounterProvider);" class="p-button-rounded p-button-success"></button>
              </span>
            </div>
          </div>
    </div>
</p-dialog>

<p-dialog appendTo="body"  header="Matching Details" [(visible)]="activelyMatching"  [modal]="false" [dismissableMask]="true" [closeOnEscape]="true" [responsive]="true" [closable]="true" [style]="{width: '60%'}">
    
    
    <div class="grid">
        <div class="col-4">
            <p-table *ngIf="candidateLines != null" [value]="candidateLines"
            [scrollable]="true"  dataKey="lineId" selectionMode="multiple" [(selection)]="this.matchedCandidateLines"
            (onRowSelect)="adjustCandidateLineSelection($event)" (onRowUnselect)="adjustCandidateLineSelection($event)">
            <ng-template pTemplate="header">   
                <tr>
                    <th style="width: 2rem"></th>
                    <th>Code</th>
                    <th>Description</th> 
                </tr>
            </ng-template> 
            <ng-template pTemplate="body" let-candidateLine>
                <tr>
                    <td>
                        <p-tableCheckbox [value]="candidateLine"></p-tableCheckbox>
                    </td>
                    <td>
                        {{candidateLine.procedure.code}}
                    </td>
                    <td>
                        <span [pTooltip]="candidateLine.procedure.description">{{candidateLine.procedure.alternateDescription}}</span>
                    </td>
                </tr>
            </ng-template>
            </p-table>
        </div>
        <div class="col-8" *ngIf="providerToBeat != null">
            <div class="grid">
                <div class="col-3"></div>
                <div class="col-3">Proposed</div>
                <div class="col-3">Actual</div>
                <div class="col-3">Final</div>
                <div class="col-3">Provider To Beat</div>
                <div class="col-3">{{originalProviderToBeat?.cost | currency: 'USD' : "symbol"}}</div>
                <div class="col-3">
                    {{providerToBeat?.cost | currency: 'USD' : "symbol"}}
                    <div>
                        <button pButton pRipple type="button" icon="pi pi-list" label="Recalculate" (click)="this.showPtBRelookup();"></button>
                    </div>
                </div>
                <div class="col-3">
                    <div>
                        <span class="p-float-label">
                            <!-- <input  [id]="providerToBeatFinal" type="text" pInputText [disabled]="!providerToBeat.override" [(ngModel)]="providerToBeat.cost"/> -->
                            <p-inputNumber [disabled]="!providerToBeat.override" [(ngModel)]="providerToBeat.cost" id="providerToBeatFinal" mode="currency" currency="USD" locale="en-US" (onBlur)="checkRewardRule()" ></p-inputNumber>                            
                            <label for="providerToBeatFinal">Price To Beat</label> 
                        </span>
                    </div>
                    <div>
                        <p-checkbox name="OverridePTB" [binary]="true" [(ngModel)]="providerToBeat.override" inputId="OverridePTB" (onChange)="checkRewardRule()"></p-checkbox>
                        <label for="OverridePTB">Override</label> 
                    </div>
                </div>


                <div class="col-3">
                    Low Cost Provider
                    <div>
                        <p-dropdown [options]="lowCostProviderOptions" [(ngModel)]="selectedLowCostProviderOption" optionLabel="label"></p-dropdown>
                    </div>
                </div>
                <div class="col-3">{{(selectedLowCostProviderOption?.value.cost) | currency: 'USD' : "symbol"}}</div>
                <div class="col-3">{{(selectedLowCostProviderOption?.value.cost) | currency: 'USD' : "symbol"}}</div>
                <div class="col-3">{{(selectedLowCostProviderOption?.value.cost) | currency: 'USD' : "symbol"}}</div>


                <div class="col-3">Confirmed Provider</div>
                <div class="col-3">---</div>
                <div class="col-3">{{(candidateLinesCost) | currency: 'USD' : "symbol"}}</div>
                <div class="col-3">
                    <span class="p-float-label">
                        <!-- <input  [id]="ConfirmFinal" type="text" pInputText [disabled]="!overrideConfirmed" [(ngModel)]="finalCostConfirm"/> -->
                        <p-inputNumber [disabled]="!overrideConfirmed" [(ngModel)]="finalCostConfirm" id="ConfirmFinal" mode="currency" currency="USD" locale="en-US" (onBlur)="checkRewardRule()"></p-inputNumber>
                        <label for="ConfirmFinal">Confirm</label> 
                    </span>         
                    <div>
                        <p-checkbox name="OverrideConfirmed" [binary]="true" [(ngModel)]="overrideConfirmed" inputId="OverrideConfirmed" (onChange)="checkRewardRule()"></p-checkbox>
                        <label for="OverrideConfirmed">Override</label> 
                    </div>      
                    <div class="pt-2">
                        Total Savings: {{(confirmedSavings | currency: 'USD' : "symbol")}}
                    </div>        
                </div>

                <div class="col-3">Reward</div>
                <div class="col-3">---</div>
                <div class="col-3">{{(finalRewardAmount | currency: 'USD' : "symbol" )}}</div>
                <div class="col-3">
                    <span class="p-float-label">
                        <!-- <input [id]="Reward" type="text" pInputText [disabled]="useRuledReward" [(ngModel)]="finalRewardAmount"/> -->
                        <p-inputNumber [disabled]="useRuledReward" [(ngModel)]="finalRewardAmount" id="Reward" mode="currency" currency="USD" locale="en-US"></p-inputNumber>
                        <label for="Reward">Reward</label> 
                    </span>                  
                    <div>
                        <p-checkbox name="RewardRule" [binary]="true" [(ngModel)]="useRuledReward" id="RewardRule" (onChange)="useRuleChange()"></p-checkbox>
                        <label for="RewardRule">Use Reward Rule</label> 
                    </div>  
                    <div>
                        <p-checkbox [disabled]="!allowRewardIssuance" name="Issue Reward" [binary]="true" [(ngModel)]="issueReward" id="IssueReward"></p-checkbox>
                        <label for="IssueReward">Issue Reward</label> 
                    </div>           
                </div>                

            </div>
        </div>
        <div class="col-12 grid flex-row-reverse mt-5 mr-2">
            <div class="col-6">
                <div class="grid justify-content-end">
                    <div class="formgroup-inline">
                        <p-dropdown appendTo="body" placeholder="Select Status" [options]="availableClaimStatusOptions" [(ngModel)]="selectedClaimStatusOption" optionLabel="label" (onChange)="statusChanged($event)"></p-dropdown>
                        <button pButton pRipple type="button" icon="pi pi-check" label="Confirm" (click)="confirmCandidateInstance(selectedClaimStatusOption.value)"></button>
                
                    </div>
                </div>
            </div>
            <div class ="col-6">
                <div>
                    <p-dialog header="Reward Rule" [(visible)]="rewardRuleDialogVisible"><pre style="max-width:800px; white-space: pre-wrap;">{{encounter?.rewardRule?.descriptionText}}</pre></p-dialog>
                    <button pButton pRipple type="button" icon="pi pi-info" class="p-button-rounded" (click)="showRewardRuleDialog()"></button>
                    {{candidateRewardRule?.ruleName}}
                </div>
            </div>

        </div>
    </div>
</p-dialog>
    
    



<app-search-with-details [(searchInProgress)]="searchInProgress" [(sortField)]="currentSortField" [fields]="fields" [sortColumns]="sortFields" [foundSet]="searchResults" (doSearch)="doSearch($event)" (doSort)="doSort($event)" (resultSelected)="resultSelected($event)">
    <ng-template #details>
        <div *ngIf="encounter != null" class="grid p-3 scroll-overflow fill-vert">
            <div class="col-4 pt-1 align-self-stretch fill-vert scroll-overflow">
                
                <p-card header="Encounter Details">

            <!-- Encounter Details -->
            <!-- Procedure Search Details (Old Library Info) -->
                    <div class="grid">
                        <div class="col-4">
                            <div class="flex flex-column">
                                <div>Encounter Id</div>
                                <div><a [routerLink]="['/encounter', encounter?.encounterId]" target="_blank">{{encounter?.encounterId}}</a></div>
                            </div>
                        </div>
                        <div class="col-4">
                            <div class="flex flex-column">
                                <div>Created</div>
                                <div>{{encounter.creationDate | date:'shortDate'}}</div>
                                <div>Appt Date</div>
                                <div>{{encounter.mostRecentAppointment?.appointmentDate | date:'shortDate'}}</div>
                            </div>
                        </div>
                        <div class="col-4">
                            <div class="flex flex-column">
                                <div>Status</div>
                                <div>{{encounter.status.statusDescription}}</div>
                            </div>
                        </div>
            <!-- Row 2 -->
                    <div class="col-4">
                        <div class="flex flex-column">
                            <div>Patient Name</div>
                            <div>{{encounter?.patient.phi.lastName}}, {{encounter?.patient.phi.firstName}}</div>
                        </div>
                    </div>
                    <div class="col-4">
                        <div class="flex flex-column">
                            <div>Member Id</div>
                            <div>{{encounter?.patientPlan.memberId}}</div>
                        </div>
                    </div>
                    <div class="col-4">
                        <div class="flex flex-column">
                            <div>Company</div>
                            <div class="wrap">{{encounter?.subscriberCompany.companyName}}</div>
                            <div class="wrap">{{encounter?.patientPlan?.network?.networkName}}</div>
                            <div class="wrap">{{encounter?.patientPlan?.network?.clientNetworkName}}</div>
                            
                        </div>
                    </div>

                    </div>
                </p-card>
                <div class="pt-5">
                    <p-card header="Procedure Search Criteria">
                        <p-table [value] = "encounter.procedures">
                            <ng-template pTemplate="header">
                                <tr>
                                    <th>Primary</th>
                                    <th pSortableColumn="procedure.code">Code</th>
                                    <th>Description</th>
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="body" let-procedure>
                                <tr>
                                    <!-- <td>{{procedure.primaryProcedure}}</td> -->
                                    <td><p-checkbox [readonly]="true" [(ngModel)]="procedure.primaryProcedure" [binary]="true"></p-checkbox></td>
                                    <td>{{procedure?.procedure.code}}</td>
                                    <td class="no-wrap">
                                        <a (mouseover)="procedureOverlay.show($event)" (mouseleave)="procedureOverlay.hide()" >
                                            {{procedure?.procedure.shortDescription()}}    
                                        <p-overlayPanel #procedureOverlay (mouseleave)="procedureOverlay.hide()" appendTo="body">
                                            <ng-template pTemplate>
                                                {{procedure?.procedure.description}}
                                            </ng-template>
                                        </p-overlayPanel>
                                        </a>
                                    </td>
                                </tr>
                            </ng-template>
                        </p-table>
                    </p-card>
                </div>
            </div>
            <div class="col-8 pt-1 align-self-stretch scroll-overflow fill-vert">

                <div class="flex-column">
                    <div>
                        <!-- This is where all of our provider info goes -->
                        <p-accordion [multiple]="true">   
                            <p-accordionTab header="Provider To Beat" [selected]="true">
                                <!-- Provider To Beat (Should only be one of these guys) -->
                                <div class="grid">
                                    <div class="p-col">
                                        <div class="flex flex-column">
                                            <div>Practice Name: {{providerToBeat?.providerLocation?.practiceName}} </div>
                                            <div>Provider Name: {{providerToBeat?.providerLocation?.providerIsOrganization ? 
                                                                    providerToBeat?.providerLocation?.providerOrganizationName : 
                                                                    providerToBeat?.providerLocation?.providerFirstName + ' ' + 
                                                                        providerToBeat?.providerLocation?.providerLastName}}</div>
                                            <!-- <div>Procedure Cost: {{(!providerToBeat.useCostFromModel ? providerToBeat.costOverride : '0.00') | currency: 'USD' : "symbol"}}</div> -->
                                            <div>Procedure Cost: {{(providerToBeat?.cost) | currency: 'USD' : "symbol"}}</div>
                                        </div>
                                    </div>
                                    <div class="p-col">
                                        <div class="flex flex-column">
                                            <div>{{providerToBeat?.providerLocation?.location?.street1}}</div>
                                            <div>{{providerToBeat?.providerLocation?.location?.street2}}</div>
                                            <div>{{providerToBeat?.providerLocation?.location?.city}}, {{providerToBeat?.providerLocation?.location?.state}} {{providerToBeat?.providerLocation?.location?.zip}}</div>
                                        </div>
                                    </div>
                                </div>
                            </p-accordionTab>
                            <p-accordionTab header="Low Cost Provider(s)" [selected]="true">
                                <div class="flex flex-column">
                                    <p-tabView>

                                    <p-tabPanel *ngFor="let provider of lowCostProviders; let i = index" [header]="provider?.providerLocation.practiceName" [selected]="i == 0">
                                        <!-- Low Cost Provider(s) - There could be more than one of these -->
                                        <div>Practice Name: {{provider?.providerLocation.practiceName}} </div>
                                        <div>Provider Name: {{provider?.providerLocation.providerIsOrganization ? 
                                                                provider?.providerLocation.providerOrganizationName : 
                                                                provider?.providerLocation.providerFirstName + ' ' + 
                                                                provider?.providerLocation.providerLastName}}</div>
                                        <!-- <div>Procedure Cost: {{(!provider.useCostFromModel ? provider.costOverride : '0.00') | currency: 'USD' : "symbol"}}</div> -->
                                        <div>Procedure Cost: {{(provider?.cost) | currency: 'USD' : "symbol"}}</div>
                                    </p-tabPanel>
                                    </p-tabView>
                                </div>                
                            </p-accordionTab>
                            <p-accordionTab header="Confirmed Provider(s)">
                                <div class="flex flex-column">
                                    <p-tabView>

                                    <p-tabPanel *ngFor="let provider of confirmedProviders; let i = index" [header]="provider?.providerLocation.practiceName" [selected]="i == 0">
                                        <!-- Confirmed Provider(s) - There could be more than one of these -->
                                        <div>Practice Name: {{provider?.providerLocation.practiceName}} </div>
                                        <div>Provider Name: {{provider?.providerLocation.providerIsOrganization ? 
                                                                provider?.providerLocation.providerOrganizationName : 
                                                                provider?.providerLocation.providerFirstName + ' ' + 
                                                                provider?.providerLocation.providerLastName}}</div>
                                        <!-- <div>Procedure Cost: {{(!provider.useCostFromModel ? provider.costOverride : '0.00') | currency: 'USD' : "symbol"}}</div> -->
                                        <div>Procedure Cost: {{(provider?.cost) | currency: 'USD' : "symbol"}}</div>
                                    </p-tabPanel>
                                    </p-tabView>
                                </div>                               
                            </p-accordionTab>
                        </p-accordion>
                    </div>
                    

                </div>
                <div class="flex-column">
                    <div class="pt-5">
                        <p-card header="Possible Matches">
                            <!-- Match Short List -->
                            <p-table [value]="matches" #matchTarget sortMode="multiple">
                                <ng-template pTemplate="header">
                                    <tr>
                                        <th>Confirmed</th>
                                        <th> </th>
                                        <th pSortableColumn="instanceOfCare.serviceBegin">Service Date <p-sortIcon field="instanceOfCare.serviceBegin"></p-sortIcon></th>
                                        <th>Procedure</th>
                                        <th pSortableColumn="instanceOfCare.instanceCost">Total Cost <p-sortIcon field="instanceOfCare.instanceCost"></p-sortIcon></th>
                                        <th pSortableColumn="matchScore">Match Score <p-sortIcon field="matchScore"></p-sortIcon></th>
                                        <th> </th>
                                    </tr>
                                </ng-template>
                                <ng-template pTemplate="body" let-match>
                                    <tr>
                                        <td><p-checkbox [readonly]="true" [value]="match.instanceOfCare.instanceId" [(ngModel)]="confirmedInstances"></p-checkbox></td>
                                        <td>
                                            <!-- <p-overlayPanel #instanceOverlay (mouseleave)="instanceOverlay.hide($event)" appendTo="body">
                                            <ng-template pTemplate>
                                                <app-instance [modelId]="match.instanceOfCare.instanceId"></app-instance>
                                            </ng-template>
                                        </p-overlayPanel> -->
                                            <p-dialog appendTo="body" header="Instance Details" [(visible)]="instanceDialogs[match.instanceOfCare.instanceId.toString()]"    [modal]="false" [dismissableMask]="true" [closeOnEscape]="true" [responsive]="true" [closable]="true" [style]="{width: '50%'}">
                                                <app-instance (linesSelected)="linesSelected(match.instanceOfCare.instanceId, $event)" [modelId]="match.instanceOfCare.instanceId"></app-instance>
                                                <div class="flex justify-content-center" style="width:100%">
                                                    <div class="m-1"><button pButton pRipple type="button" icon="pi pi-check" label="Confirm" (click)="confirmPartialInstance(match.instanceOfCare.instanceId)"></button></div>
                                                    <!-- <div class="m-1"><button pButton pRipple type="button" icon="pi pi-clock" label="Confirm - Waiting For Data" (click)="confirmPartialInstance(match.instanceOfCare.instanceId, STATUS_PARTIALLY_CONFIRMED)"></button></div>
                                                    <div class="m-1"><button pButton pRipple type="button" icon="pi pi-clock" label="Confirm - Needs Review" (click)="confirmPartialInstance(match.instanceOfCare.instanceId, STATUS_CONFIRMED_NEEDS_REVIEW)"></button></div> -->
                                                    <div class="m-1"><button pButton pRipple type="button" icon="pi pi-thumbs-down" label="Close - Treated At Provider To Beat" (click)="closePartialInstance(match.instanceOfCare.instanceId, STATUS_CLOSED, NOT_LOW_COST_OPTION)"></button></div>
                                                    <div class="m-1"><button pButton pRipple type="button" icon="pi pi-times-circle" label="Close - Claim(s) Denied by Payor" (click)="closePartialInstance(match.instanceOfCare.instanceId, STATUS_CLOSED, PAYOR_DENIED)"></button></div>

                                                </div>
                                            </p-dialog>
                                            <button pButton pRipple type="button" icon="pi pi-info" class="p-button-rounded" (click)="showInstanceDialog(match.instanceOfCare.instanceId)"></button>
                                        </td>
                                        <td>{{match.instanceOfCare.serviceBegin | date:'shortDate'}}</td>
                                        <td class="no-wrap">
                                            <p-overlayPanel #matchOverlay (mouseleave)="matchOverlay.hide()" appendTo="body" >
                                                <ng-template pTemplate>
                                                    {{match.instanceOfCare.generalProcedureDescription}}
                                                </ng-template>
                                            </p-overlayPanel>
                                            <a (mouseover)="matchOverlay.show($event)" (mouseleave)="matchOverlay.hide()" >
                                                {{match.instanceOfCare.shortDescription()}}
                                            </a>
                                        </td>
                                        <td>{{match.instanceOfCare.instanceCost | currency: 'USD' : "symbol"}}</td>
                                        <td>{{match.matchScore}}</td>
                                        <!-- <td> <p-button *ngIf="! confirmedInstances.includes(match.instanceOfCare.instanceId)" label="Confirm" (onClick)="confirmInstance(match.instanceOfCare,$event)"></p-button></td> -->
                                        <td><div><p-splitButton   appendTo="body" *ngIf="! confirmedInstances.includes(match.instanceOfCare.instanceId)"  label="Confirm" icon="pi pi-check" (onClick)="confirmFullInstance(match.instanceOfCare)" [model]="instanceActions[match.instanceOfCare.instanceId]"></p-splitButton></div></td>
                                    </tr>
                                </ng-template>
                            </p-table>
                        </p-card>
                    </div>
                </div>
            </div>

            <!-- <div class="col-4 align-self-stretch scroll-overflow fill-vert">
                <div *ngIf="instanceSelected" class="flex scroll-overflow" style="min-height: 100%; height: 100%; max-height: 100% ;">
                    <app-instance [modelData]="selectedInstanceOfCare"></app-instance>
                </div>
            </div> -->

            
            <!-- Match Buttons -->
            


        </div>
    </ng-template>
</app-search-with-details>


