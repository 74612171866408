import { Directive, EventEmitter, Output } from "@angular/core";
import { InstanceOfCare } from "src/app/model/claim/instance_of_care";
import { Line } from "src/app/model/claim/line";
import { PracticeResult } from "src/app/model/claim/procedure_lookup_result";
import { EncounterModelProcedure } from "src/app/model/encounter/encounter_model_procedure";
import { EncounterProvider } from "src/app/model/encounter/encounter_provider";
import { ProviderLocation } from "src/app/model/provider/provider_location";


@Directive ({})
export abstract class ResultsSelection<T> 
{
    // @Output() chosenItems: EventEmitter<EncounterModelProcedure[]> = new EventEmitter<EncounterModelProcedure[]>();
    // @Output() chosenResult: EventEmitter<PracticeResult> = new EventEmitter<PracticeResult>();
    @Output() chosenResult: EventEmitter<ProcedureResultSelection<T>> = new EventEmitter<ProcedureResultSelection<T>>();

    publishSelection()
    {
        let newResult = new ProcedureResultSelection();
        newResult.chosenItems = this.updateSelections();
        newResult.chosenResult = this.updateChosenResult();
        
        this.chosenResult.emit(newResult);
    }
    
    abstract updateSelections(): EncounterModelProcedure[];
    abstract updateChosenResult(): PracticeResult;
    
}

export class ProcedureResultSelection<T>
{
    chosenItems: EncounterModelProcedure[];
    chosenResult: PracticeResult;
}
