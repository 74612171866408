<div class="flex align-items-center justify-content-center">
    <p-toast key="reportRunner" position="top-center"></p-toast>
    <p-card header="Report Wizard" [style]="{'width':'800px'}">
        <div class="grid">
            <div class="col-12 mt-4 mb-4">
                <p-steps [model]="steps" [activeIndex]="activeStep"></p-steps>
            </div>
            
            
            <!-- Step 0 - Choose Report Type -->
            <div *ngIf="activeStep == 0" class="col-12 mt-4 mb-4 ml-5">
                <div class="form-grid align-items-center">
                    <div class="field-radiobutton">
                        <p-radioButton  name="reportType" value="system" [(ngModel)]="selectedReportType" id="systemType"></p-radioButton>
                        <label for="systemType">System Report</label>
                    </div>
                    <div class="field-radiobutton">
                        <p-radioButton  name="reportType" value="client" [(ngModel)]="selectedReportType" id="clientType"></p-radioButton>
                        <label for="clientType">Client Report</label>   
                    </div>                 
                </div>
            </div>

             <!-- Choose Companies -->
             <div *ngIf="activeStep == 1" class="col-12 mt-4 mb-4 ml-5">
                <div class="form-grid align-items-center">
                    <p-multiSelect  [options]="availableCompanies" [(ngModel)]="selectedCompanies" optionLabel="companyName" [style]="{'width':'600px'}"></p-multiSelect>
                </div>
            </div>           


             <!-- Choose Period -->
             <div *ngIf="activeStep == 2" class="col-12 mt-4 mb-4 ml-5">
                <div class="grid">
                    <div class="col-6">
                        <div class="form-grid align-items-center" *ngFor="let period of periods; let i = index">
                            
                            <p-radioButton [id]="'period-'+period" [value]="period" name="periodType" [(ngModel)]="selectedPeriod" (onClick)="periodSelected()"></p-radioButton>
                            <label [for]="'period-'+period">{{period}}</label>
                        </div>
                    </div>
                    <div class="col-6 form-grid">
                        <div class="mt-3" style="width: 300px;">
                            <span class="p-float-label">
                                <p-dropdown [style]="{'width': '300px'}" id="year" [options]="this.years" [(ngModel)]="this.selectedYear" [autoDisplayFirst]="false"></p-dropdown>
                                <label for="year">Year</label>
                            </span>
                        </div>
                        <div  class="mt-3" [style]="{'width': '300px'}" *ngIf="this.selectedPeriod == 'Quarterly' || this.selectedPeriod == 'Monthly'">
                            <span class="p-float-label">
                                <p-dropdown [style]="{'width': '300px'}" id="periodDetail" [options]="this.periodNames" [(ngModel)]="this.selectedPeriodName" [autoDisplayFirst]="false"></p-dropdown>
                                <label *ngIf="this.selectedPeriod=='Quarterly'" for="periodDetail">Quarter</label>
                                <label *ngIf="this.selectedPeriod=='Monthly'" for="periodDetail">Month</label>

                            </span>
                        </div>
                        <div  class="mt-3"  *ngIf="this.selectedPeriod == 'Ad Hoc'">
                            <span class="p-float-label">
                                <input style="width: 300px" id="manualReportName" type="text" pInputText [(ngModel)]="this.selectedPeriodName"/>
                                <label for="manualReportName">Report Name</label>
                            </span>
                        </div>
                    </div>
                </div>
            </div>          

            <!-- Choose Report -->
            <div *ngIf="activeStep == 3" class="col-12 mt-4 mb-4 ml-5">

                <div class="form-grid align-items-center">
                    <div>
                        <span class="p-float-label">
                            <p-dropdown id="reportSelector" [options]="availableReports" [(ngModel)]="selectedReport" optionLabel="reportName" [filter]="true" filterBy="reportName" [showClear]="true" [autoDisplayFirst]="false" [style]="{'width':'300px'}" (onChange)="reportSelected()"></p-dropdown>
                            <label for="reportSelector">Report</label>
                        </span>
                    </div>
                    <div>    
                        <span class="p-float-label">
                            <p-dropdown id="outputFormatSelector" [options]="availableOutputFormats" [(ngModel)]="selectedOutputFormat" optionLabel="fileExtension" [showClear]="true" [autoDisplayFirst]="false" [style]="{'width':'150px'}" (onChange)="reportSelected()"></p-dropdown>                    
                            <label for="outputFormatSelector">OutputFormat</label>

                        </span>
                    </div>
                    
                </div>
           </div>           


            <!-- Set Params -->
            <div *ngIf="activeStep == 4" class="col-12 mt-1 mb-1 ml-1">
                <div class="grid">
                    <div *ngFor="let p of reportParameters; let i = index" class="col-6" >
                        <app-reportparameter *ngIf="!p.param.clientParameter" [(parameter)]="reportParameters[i]"></app-reportparameter>
                    </div>
                </div>
            </div>          

            <!-- Execute -->
            <div *ngIf="activeStep == 5" class="col-12 mt-4 mb-4 ml-5">
               <div class="form-grid align-items-center">
                    <button pButton type="button" label="Execute" (click)="executeReport()"></button>
               </div>
           </div>           

            <div class="col-3 justify-content-center align-items-center flex">
                <button pButton type="button" label="Prev" icon="pi pi-arrow-left" iconPos="left" (click)="prevStep()" [disabled]="prevDisabled"></button>
            </div>
            <div class="col-6"></div>
            <div class="col-3 justify-content-center align-items-center flex">
                <button pButton type="button" label="Next" icon="pi pi-arrow-right" iconPos="right" (click)="nextStep()" [disabled]="nextDisabled" ></button>
            </div>
        </div>
    </p-card>
</div>