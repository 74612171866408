import { Encounter } from "./encounter";
import { ProviderLocation } from "../provider/provider_location";
import { User } from "../util/user";
import { EncounterModelProcedure, EncounterModelProcedureUpdate } from "./encounter_model_procedure";


export class EncounterProvider
{
    id: number;
    encounterId: number;
    encounter: Encounter;
    providerLocation: ProviderLocation;
    costOverride: number;
    useCostFromModel: boolean;
    providerToBeat: boolean;
    originalProviderToBeat: boolean;
    referringProvider: boolean;
    lowCostProvider:boolean;
    confirmedProvider:boolean;
    modifiedBy: User;
    modelProcedures: EncounterModelProcedure[];
    paidDate: Date;
    serviceDate: Date;
    instanceId : number;
    get cost() : number { return this.useCostFromModel ? this.modelCost : this.costOverride;}
    set cost(cost: number) 
    { 
        //This is kind of a hack, but it'll work based on the state of this object
        //if we're set to override, then setting the cost will set the cost override
        //if we're not set to override, then we do nothing. Since we don't want to be able to set the cost
        if (!this.useCostFromModel)
        {
            this.costOverride = cost;
        }
        
    }
    set override(override: boolean) { this.useCostFromModel = !override}
    get override() : boolean {return !this.useCostFromModel}
    get modelCost(): number
    {
        return this.modelProcedures.map(mp => mp.cost).reduce((p,c) => p + c, 0); 
    }
    set modelCost(val: number)
    {
        //do nothing
    }

    public static generateUpdateVersion(ep: EncounterProvider):EncounterProviderUpdate
    {
        let epu: EncounterProviderUpdate = new EncounterProviderUpdate();

        epu.costOverride = ep.costOverride;
        epu.useCostFromModel = ep.useCostFromModel;
        epu.providerToBeat = ep.providerToBeat;
        epu.originalProviderToBeat = ep.originalProviderToBeat;
        epu.lowCostProvider = ep.lowCostProvider;
        epu.confirmedProvider = ep.confirmedProvider;
        epu.referringProvider = ep.referringProvider;
        epu.encounterId = ep.encounterId;
        epu.providerLocation = ep.providerLocation.id;
        return epu;
    }

    asUpdate(): EncounterProviderUpdate
    {
        return EncounterProvider.generateUpdateVersion(this);
    }

    asInsert(): EncounterProviderInsert
    {
        let epi: EncounterProviderInsert = Object.assign(new EncounterProviderInsert(), this.asUpdate());
        let modelProcedures = this.modelProcedures.map(mp => Object.assign(new EncounterModelProcedure(), mp).toUpdate());
        epi.modelProcedures = modelProcedures;
        return epi;
    }
}

export class EncounterProviderUpdate
{
    constructor()
    {

    }

    costOverride: number;
    useCostFromModel: boolean;
    providerToBeat: boolean;
    originalProviderToBeat: boolean;
    lowCostProvider: boolean;
    confirmedProvider: boolean;
    referringProvider: boolean;
    encounterId: number;
    providerLocation: number;
}

export class EncounterProviderInsert extends EncounterProviderUpdate
{
    constructor()
    {
        super();
    }

    modelProcedures: EncounterModelProcedureUpdate[];
}