import { createAction, props } from "@ngrx/store";

export const ReservationAccepted = createAction(
    '[Twilio] Reservation Accepted',
    props<{ reservation }>()
  );

export const ReservationCancelled = createAction(
    '[Twilio] Reservation Cancelled',
    props<{ reservation }>()
  );

export const ReservationCreated = createAction(
    '[Twilio] Reservation Created',
    props<{ reservation }>()
  );

export const ReservationTimeout = createAction(
    '[Twilio] Reservation Timeout',
    props<{ reservation }>()
  );

export const ReservationRejected = createAction(
    '[Twilio] Reservation Rejected',
    props<{ reservation }>()
  );

export const ReservationWrapup = createAction(
    '[Twilio] Reservation Wrapup',
    props<{ reservation }>()
  );

export const WorkerReady = createAction(
    '[Twilio] Worker Ready',
    props<{ worker }>()
  );
export const WorkerStatusChanged = createAction(
    '[Twilio] Worker Status Changed',
    props<{ workerStatus: string }>()
  );


