import { User } from "../util/user";

export class CompanyContact
{
    companyContactId: number;
    firstName: string;
    lastName: string;
    phone: string;
    fax: string;
    email:string;
    address1: string;
    address2: string;
    city:string;
    state:string;
    zipCode: string;
    modifiedBy: User;

    asUpdate(): CompanyContactUpdate
    {
        return Object.assign(new CompanyContactUpdate(), this);
    }

    asInsert(): CompanyContactInsert
    {
        return Object.assign(new CompanyContactInsert(),this);
    }
}

export class CompanyContactUpdate
{
    firstName: string;
    lastName: string;
    phone: string;
    fax: string;
    email:string;
    address1: string;
    address2: string;
    city:string;
    state:string;
    zipCode: string;
}

export class CompanyContactInsert extends CompanyContactUpdate
{
    companyId: number
}