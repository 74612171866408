<p-table  [value]="jobs" [scrollable]="true" scrollHeight="flex"
    rowGroupMode="subheader" groupRowsBy="status" >
    <ng-template pTemplate="caption">
        <!-- Search parameters -->
        <div class="grid pt-4">
            <div class="col-2">
                <span class="p-float-label">
                    <p-calendar id="jobsFrom"[(ngModel)]="jobsFrom"></p-calendar>
                    <label for="jobsFrom">Jobs Started After</label>
                </span>
            </div>
            <div class="col-2">
                <span class="p-float-label">
                    <p-calendar id="jobsTo" [(ngModel)]="jobsTo"></p-calendar>
                    <label for="jobsTo">Jobs Started Before</label>
                </span>
            </div>
            <div class="col-2">
                <span class="pr-4"><p-button label="Search" (click)="searchJobs()"></p-button></span>
                <span><p-progressSpinner *ngIf="showWait" styleClass="w-2rem h-2rem" strokeWidth="8" fill="var(--surface-ground)" animationDuration="2s"></p-progressSpinner></span>
            </div>
          
        </div>
    </ng-template>
    <ng-template pTemplate="header">
        <tr>
            <th>Job Id</th>
            <th>Name</th>
            <th>Start Time</th>
            <th>End Time</th>
            <th>Message</th>
            <th></th>
        </tr>
    </ng-template>
    <ng-template pTemplate="groupheader" let-email>
        <tr pRowGroupHeader>
            <td colspan="8">
                <span class="font-bold status-header">Status: {{email.status}}</span>
            </td>
        </tr>
    </ng-template>
    <ng-template pTemplate="body" let-job>
        <tr>
            <td>{{job.jobId}}</td>
            <td>{{job.jobTitle}}</td>
            <td>{{job.startTime | date:'short'}}</td>
            <td>{{job.endTime | date:'short'}}</td>
            <td>{{job.statusMessage}}</td>
        </tr>
    </ng-template>
    <!-- <ng-template *ngIf="emails.length == 0" pTemplate="body">
        <p-progressSpinner></p-progressSpinner>
    </ng-template> -->
</p-table>