import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { from } from 'rxjs/internal/observable/from';
import { first } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { B2CUser } from '../model/phi/b2cuser';
import { Phi } from '../model/phi/phi';
import { EndUser } from '../model/util/end_user';
import { ApiService } from './api.service';

@Injectable({
    providedIn: 'root',
})
export class B2CUserService {
    users: Array<B2CUser> = [];
    constructor(private apiService: ApiService) {
        // apiService.
    }

    retrieveUsers(phiId: number): Observable<B2CUser[]>
    {
      return from (this.apiService.makeAPIGetCall<B2CUser[]>
        (
          environment.tcemsAPIBase,
          environment.tcemsAPI_phi + "/" + phiId.toString() + "/b2cendusers",
          {}
        ));
    }

    retrieveUsersByFirstName(phiId: number, firstName: String): Observable<B2CUser[]>
    {
      return from (this.apiService.makeAPIGetCall<B2CUser[]>
        (
          environment.tcemsAPIBase,
          environment.tcemsAPI_phi + "/" + phiId.toString() + "/b2cendusers/" + "firstname/" + firstName,
          {}
        ));
    }

    retrieveUsersByLastName(phiId: number, lastName: String): Observable<B2CUser[]>
    {
      return from (this.apiService.makeAPIGetCall<B2CUser[]>
        (
          environment.tcemsAPIBase,
          environment.tcemsAPI_phi + "/" + phiId.toString() + "/b2cendusers/" + "lastname/" + lastName,
          {}
        ));
    }

    retrieveUsersByDateOfBirth(phiId: number, dateOfBirth: Date): Observable<B2CUser[]>
    {
      return from (this.apiService.makeAPIGetCall<B2CUser[]>
        (
          environment.tcemsAPIBase,
          environment.tcemsAPI_phi + "/" + phiId.toString() + "/b2cendusers/" + "dateofbirth/" + dateOfBirth.getMonth() + "-" + dateOfBirth.getDay() + "-" + dateOfBirth.getFullYear(),
          {}
        ));
    }

    submitUserSelection(phiId: number, b2cUserId: string): Observable<EndUser>
    {
      return from(this.apiService.makeAPIPostCall<EndUser>
        (
          environment.tcemsAPIBase,
          environment.tcemsAPI_phi + "/" + phiId.toString() + "/enduser/" + b2cUserId , {},
          {}
        ));
    }
}
