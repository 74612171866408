import { User } from "../util/user";
import { Provider } from './provider';
import { PracticeAlias } from './practice_alias';
import { ProviderLocation } from "./provider_location";
import { Location } from "./location";

export class Practice
{
    ein: string;
    practiceId: number;
    practiceName: string;
    conversionPractice: boolean;
    modifiedByUser: User;
    providers: Provider[];
    aliases: PracticeAlias[];

    private _providerLocations: ProviderLocation[];
    private _locations: Location[];
    private _providerLocationsByLocationId: Map<number, ProviderLocation[]>

    get providerLocations(): ProviderLocation[]
    {
        return this._providerLocations;
    }

    set providerLocations(val: ProviderLocation[])
    {
        this._providerLocations = val;
        let ids:Set<number> = new Set(this.providerLocations.map(pl => pl.location.locationId));
        this._locations = Array.from(ids).map( l => this.providerLocations.find(pl => pl.location.locationId == l).location);
        this._providerLocationsByLocationId = new Map( this._locations.map( loc => [loc.locationId,this.providerLocations.filter(pl => pl.location.locationId == loc.locationId)]));
    }

    get locations(): Location[]
    {
        return this._locations;
    }

    set locations(val: Location[])
    {
        this._locations = val;
    }

    get providerLocationsByLocationIds():Map<number,ProviderLocation[]>
    {
        return this._providerLocationsByLocationId
    }

    set providerLocationsByLocationIds(val :Map<number, ProviderLocation[]>)
    {
        this._providerLocationsByLocationId = val;
    }


    // get locations():Location[]
    // {

    //     //Unique location ids
    //     let ids:Set<number> = new Set(this.providerLocations.map(pl => pl.location.locationId));

    //     //Fetch the specific locations with these ids
    // }

    // get providerLocationsByLocationIds():Map<number,ProviderLocation[]>
    // {
    //     //get our locations
    //     let locs: Location[] = this.locations;
        
    //     return new Map( locs.map( loc => [loc.locationId,this.providerLocations.filter(pl => pl.location.locationId == loc.locationId)]));
        
    // }

    toUpdate() :PracticeUpdate
    {
        let pu:PracticeUpdate = new PracticeUpdate();
        pu.ein = this.ein;
        pu.practiceName = this.practiceName;
        return pu;
    }
}

export class PracticeUpdate
{
    ein: string;
    practiceName: string;
}