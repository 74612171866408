import { ChangeDetectorRef, Component, OnInit, Type } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { MessageService } from 'primeng/api';
import { Observable, of } from 'rxjs';
import { ApiService } from 'src/app/api/api.service';
import { TcemsService } from 'src/app/api/tcems.service';
import { AbstractSearchWithDetailsComponent } from 'src/app/base/search-with-details/abstract-search-with-details.component';
import { SearchField } from 'src/app/base/search-with-details/search-field';
import { SearchResult } from 'src/app/base/search-with-details/search-result';
import { SearchWithDetailsImplementor } from 'src/app/base/search-with-details/search-with-details-implementor';
import { SearchWithDetailsComponent } from 'src/app/base/search-with-details/search-with-details.component';
import { SinglePanelComponent } from 'src/app/base/single-panel/single-panel.component';
import { Practice } from 'src/app/model/provider/practice';
import { PracticeEditorComponent } from 'src/app/provider/practice-editor/practice-editor.component';
import { TcemsUtilitiesService } from 'src/app/util/tcems-utilities.service';

@Component({
  selector: 'app-provider-research',
  templateUrl: './provider-research.component.html',
  styleUrls: ['./provider-research.component.css']
})
export class ProviderResearchComponent
extends AbstractSearchWithDetailsComponent
implements OnInit
// extends SearchWithDetailsComponent<Practice>
// implements SearchWithDetailsImplementor<Practice> , OnInit
{

  // getImplementor(): SearchWithDetailsImplementor<Practice> 
  // {
  //   return this;
  // }

  // constructor(protected apiService : ApiService, 
  //   protected store: Store, 
  //   protected tcems: TcemsService,
  //   protected util: TcemsUtilitiesService,
  //   protected cdr: ChangeDetectorRef,
  //   protected route: ActivatedRoute,
  //   protected router: Router) 
  // { 
  //   super(apiService,store,tcems,cdr,route,router);
  //   this.detailComponent = new PracticeEditorComponent(this.tcems,cdr);
  //   this.detailComponentType = PracticeEditorComponent;
  // }
  constructor(messageService: MessageService, private tcems: TcemsService)
  {
    super(messageService);
  }

  // getDetailPanel(): Type<SinglePanelComponent<Practice>> 
  // {
  //   return PracticeEditorComponent;
  // }

  fields: SearchField<any>[];
  searchResults: SearchResult<Practice>[];
  selectedPractice: Practice;

  sortFields: {key: string, value: string}[] = (["Practice Name"]).map(sc => {return {key:sc, value:sc}});
  defaultSortField: {sortField:{key:string, value:string} ,sortDesc:boolean} = {sortField: {key:"Practice Name", value:"Practice Name"},sortDesc:false};
  currentSortField: {sortField:{key:string, value:string} ,sortDesc:boolean} = this.defaultSortField;
  // detailComponent: SinglePanelComponent<Practice>;
  // detailComponentType: Type<SinglePanelComponent<Practice>>;


  ngOnInit()
  {
    //Set search fields
    this.fields = [
      new SearchField<string>(
        {
          key: 'PracticeName',
          label: 'Practice Name',
          type: 'string'
        }),
        new SearchField<string>({
          key: 'ZipCode',
          label: 'Zip Code',
          type: 'string'
        }),
        new SearchField<string>({
          key: 'Ein',
          label: 'EIN',
          type: 'string'
        })        
    ]
  }

  doSearch(searchEvent: SearchField<any>[])
  {
    //Get our practices from the api
    this.tcems.searchPractices
    ({
      name: searchEvent.find(f => f.key == "PracticeName")?.value,
      zipCode: searchEvent.find(f => f.key == "ZipCode")?.value,
      ein: searchEvent.find(f => f.key == "Ein")?.value
    }).toPromise()
    .then
    (
      result =>
      {
      
        this.searchResults = result.records.map
        (
          p => 
          new SearchResult<Practice>( 
          {
            payload: Object.assign(new Practice(), p), 
            id: p.ein,
            line1: p.practiceName,
            line2: p.ein,
            line3: ""
          })
        );
          
        this.searchInProgress = false;
        this.doSort(this.currentSortField);

      }
    )
    .catch(
      error =>
      {
        this.logError(error.errorMessage);
        this.searchInProgress = false;
      }
    );
  }


  


  doSort(sortEvent: {sortField:{key:string, value:string} ,sortDesc:boolean} )
  {
    this.currentSortField = sortEvent;
    if (sortEvent.sortField.key == "Practice Name") 
    {
      this.searchResults =  this.searchResults.slice().sort((a,b) => (a.payload.practiceName < b.payload.practiceName ? -1 : a.payload.practiceName > b.payload.practiceName ? 1 : 0) * (sortEvent.sortDesc ? -1 : 1)  );
    }    
  }

  practiceSelected(selectionEvent: SearchResult<Practice>)
  {
    this.loadPractice(selectionEvent.payload);
  }

  protected loadPractice(modelData:Practice)
  {
    this.selectedPractice = modelData;
  }


}
