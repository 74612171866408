import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { TcemsService  } from 'src/app/api/tcems.service';
import { MemberBenefits } from 'src/app/model/phi/member-benefits';
import { Phi } from 'src/app/model/phi/phi';

@Component({
  selector: 'app-eob-summary',
  templateUrl: './eob-summary.component.html',
  styleUrls: ['./eob-summary.component.scss']
})
export class EobSummaryComponent implements OnInit,OnChanges {

  constructor(private store: Store, private tcems: TcemsService) {
  }

  @Input() phi: Phi;
  MemberBenefits: MemberBenefits;

  ngOnInit(): void
  {
    this.instantiateEoB();
  }

  ngOnChanges(): void 
  {
    this.instantiateEoB();
  }


  instantiateEoB(): void
  {
    this.MemberBenefits = null;
    this.tcems.getEoB(this.phi.memberId).toPromise().then
    (
      sub => 
      {
        this.MemberBenefits = Object.assign(new MemberBenefits(), sub);
      }
    )//.catch ()
  }



}
