
<p-dialog header="HEMI Login" [(visible)]="display" [closable]="false" [modal]="true">
<div style="text-align: center">
    <button pButton *ngIf="!loggingIn" type="button" label="Login" (click)="login()" style="width:100%"></button>
</div>
<div style="text-align: center">
    <p-progressSpinner *ngIf="loggingIn" ></p-progressSpinner>
</div>
<div >
    {{loginStatus.message}}
</div>

</p-dialog>