import { BrowserModule } from '@angular/platform-browser';
import { NgModule  } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { environment } from '../environments/environment';
import { ReactiveFormsModule } from '@angular/forms';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SubscriberSearchComponent } from './phi/subscriber-search/subscriber-search.component';
import { LoginComponent } from './login/login.component';
import { PlanDetailComponent } from './phi/plan-detail/plan-detail.component';

// Prime NG Component Modules
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { ButtonModule } from 'primeng/button';
import { DialogModule } from 'primeng/dialog';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { TabMenuModule } from 'primeng/tabmenu';
import { PanelMenuModule } from 'primeng/panelmenu';
import { PanelModule } from 'primeng/panel';
import { MenubarModule } from 'primeng/menubar';
import { MenuModule } from 'primeng/menu';
import { CardModule } from 'primeng/card';
import { VirtualScrollerModule } from 'primeng/virtualscroller';
import { ScrollPanelModule } from 'primeng/scrollpanel';
import { FieldsetModule } from 'primeng/fieldset';
import { AccordionModule } from 'primeng/accordion';
import { TableModule, Table } from 'primeng/table';
import { TabViewModule } from 'primeng/tabview';
import { TreeModule } from 'primeng/tree';
import { InplaceModule } from 'primeng/inplace';
import { AvatarModule } from 'primeng/avatar';
import { InputMaskModule } from 'primeng/inputmask';
import { ConfirmationService, MessageService, TreeNode } from 'primeng/api';
import { ProgressBarModule } from 'primeng/progressbar';
import { DividerModule } from 'primeng/divider';
import { EditorModule } from 'primeng/editor';
import { TagModule } from 'primeng/tag';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { MessagesModule } from 'primeng/messages';
import { MessageModule } from 'primeng/message';
import { ToastModule } from 'primeng/toast';
import {BadgeModule} from 'primeng/badge';
import {InputSwitchModule} from 'primeng/inputswitch';
import {SlideMenuModule} from 'primeng/slidemenu';
import {FileUploadModule} from 'primeng/fileupload';
import { PickListModule } from 'primeng/picklist';
import { ChipsModule } from 'primeng/chips';
import { AvatarGroupModule } from 'primeng/avatargroup';

// Prime NG Form Stuff
import { FormsModule } from '@angular/forms';
import {InputTextModule } from 'primeng/inputtext';
import {InputTextareaModule} from 'primeng/inputtextarea';
import {InputNumberModule} from 'primeng/inputnumber';
import {CalendarModule } from 'primeng/calendar';
import { CheckboxModule } from 'primeng/checkbox';
import { RadioButtonModule } from 'primeng/radiobutton';
import { DropdownModule } from 'primeng/dropdown';
import { MultiSelectModule } from 'primeng/multiselect';
import { SplitButtonModule } from 'primeng/splitbutton';
import {ChartModule} from 'primeng/chart';
import {StepsModule} from 'primeng/steps';

//Reducers and other NGRX Stuff
import { activeUsersReducer, appointmentStatusReducer, categoryReducer, checkingAccountReducer, contactTypeReducer, encounterClosureReasonreducer, encounterStatusReducer, encounterTypeReducer, networkGroupReducer, networkReducer, planTypeReducer, sourceReducer, transactionTypeeReducer, userReducer, userStateReducer, companyTypesReducer, procHierarchyReducer, captivesReducer } from 'src/app/reducers/api/tcems/globals.reducer';

// Azure AD Imports and constant for handling login properly on IE
import { MsalModule, MsalInterceptor } from '@azure/msal-angular';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { PhiDetailComponent } from './phi/phi-detail/phi-detail.component';
import { InstanceComponent } from './research/instance/instance.component';
import { DetailsPane } from './base/search-with-details/detail-pane';
import { MatchingComponent } from './encounters/matching/matching.component';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { EffectsModule } from '@ngrx/effects';
import { AppEffects } from './app.effects';
import { EncounterSummaryComponent } from './encounters/encounter-summary/encounter-summary.component';
import { ProcedureLookupComponent } from './research/instance/procedure-lookup/procedure-lookup.component';
import { EncounterLookupComponent } from './encounters/encounter-lookup/encounter-lookup.component';
import { TransactionComponent } from './finance/transaction/transaction.component';
import { TransactionListComponent } from './finance/transaction-list/transaction-list.component';
import { SearchButtonComponent } from './base/search-button/search-button.component';
import { InteractionType, PublicClientApplication } from '@azure/msal-browser';
import { HomePageComponent } from './base/home-page/home-page.component';
import { ProcedureResultsComponent } from './research/instance/procedure-lookup/results/procedure-results/procedure-results.component';
import { CategoryResultsComponent } from './research/instance/procedure-lookup/results/category-results/category-results.component';
import { InstanceResultsComponent } from './research/instance/procedure-lookup/results/instance-results/instance-results.component';
import { LinelistComponent } from './research/instance/linelist/linelist.component';
import { InstancelistComponent } from './research/instance/instancelist/instancelist.component';
import { PhiDetailsComponent } from './phi/phi-summary/phi-summary.component';
import { EncounterDetailsComponent } from './base/home-page/encounter-details-page/encounter-details-page.component';
import { MemberDetailsComponent } from './base/home-page/member-details-page/member-details-page.component';
import { PlanSummaryComponent } from './plan/plan-summary/plan-summary.component';
import { ProcedureSelectorComponent } from './base/procedure-selector/procedure-selector.component';
import { TooltipModule } from 'primeng/tooltip';
import { PracticeEditorComponent } from './provider/practice-editor/practice-editor.component';
import { ProviderResearchComponent } from './research/providers/provider-research/provider-research.component';
import { LocationTagComponent } from './research/instance/procedure-lookup/results/location-tag/location-tag.component';
import { EncounterProviderComponent } from './encounters/encounter-provider/encounter-provider.component';
import { SelectButtonModule } from 'primeng/selectbutton';
import { PhoneEditorComponent } from './base/editors/phone-editor/phone-editor.component';
import { EmailEditorComponent } from './base/editors/email-editor/email-editor.component';
import { AddressEditorComponent } from './base/editors/address-editor/address-editor.component';
import { PracticeLocationEditorComponent } from './provider/practice-location-editor/practice-location-editor.component';
import { DiaryScrollerComponent } from './base/diary-scroller/diary-scroller.component';
import { NoteScrollerComponent } from './base/note-scroller/note-scroller.component';
import { AppointmentEditorComponent } from './base/appointment-editor/appointment-editor.component';
import { UnassignedEncountersComponent } from './base/home-page/encounter-list/encounter-list.component';
import { PhiSearchComponent } from './phi/phi-search/phi-search.component';
import { CompanySearchComponent } from './support/company-search/company-search.component';
import { SidebarModule } from 'primeng/sidebar';
import {ConfirmDialogModule} from 'primeng/confirmdialog';
import {ConfirmPopupModule} from 'primeng/confirmpopup';

import { InvoiceListComponent } from './finance/invoice-list/invoice-list.component';
import { B2CUserListComponent } from './plan/plan-summary/b2cuser-list.component';
import { CompanyManagerComponent } from './support/company-manager/company-manager.component';
import { ReportrunnerComponent } from './reports/reportrunner/reportrunner.component';
import { ReportviewerComponent } from './reports/reportviewer/reportviewer.component';
import { ReportmanagerComponent } from './reports/reportmanager/reportmanager.component';
import { ReportparameterComponent } from './reports/reportparameter/reportparameter.component';
import { EobSummaryComponent } from './plan/eob-summary/eob-summary.component';
import { FileScrollerComponent } from './base/file-scroller/file-scroller.component';
import { EmailScrollerComponent } from './base/email-scroller/email-scroller.component';
import { FaxScrollerComponent } from './base/fax-scroller/fax-scroller.component';
import { InstanceOfCare } from './model/claim/instance_of_care';
import { SearchWithDetailsComponent } from './base/search-with-details/search-with-details.component';
import { InstanceSearchComponent } from './research/instance/instance-search/instance-search.component';
import { SidebarComponent } from './base/home-page/sidebar/sidebar.component';
import { TwilioReservationReducer, TwilioWorkerReducer, TwilioWorkerStatusReducer } from './reducers/twilio.reducers';
import { HomePageV2Component } from './base/home-page-v2/home-page-v2.component';
import { OverlayModule } from 'primeng/overlay';
import { JobMonitorComponent } from './util/job-monitor/job-monitor.component';
import { MailSendManagerComponent } from './util/mail-send-manager/mail-send-manager.component';

const isIE = window.navigator.userAgent.indexOf('MSIE ') > -1 || window.navigator.userAgent.indexOf('Trident/') > -1;

@NgModule({

  declarations: [
    AppComponent,
    B2CUserListComponent,
    SubscriberSearchComponent,
    LoginComponent,
    PlanDetailComponent,
    PhiDetailComponent,
    InstanceComponent,
    DetailsPane,
    MatchingComponent,
    SearchWithDetailsComponent,
    InstanceComponent,
    EncounterSummaryComponent,
    ProcedureLookupComponent,
    EncounterLookupComponent,
    TransactionComponent,
    TransactionListComponent,
    SearchButtonComponent,
    HomePageComponent,
    ProcedureResultsComponent,
    CategoryResultsComponent,
    InstanceResultsComponent,
    LinelistComponent,
    InstancelistComponent,
    PhiDetailsComponent,
    EncounterDetailsComponent,
    MemberDetailsComponent,
    PlanSummaryComponent,
    ProcedureSelectorComponent,
    PracticeEditorComponent,
    ProviderResearchComponent,
    LocationTagComponent,
    EncounterProviderComponent,
    PhoneEditorComponent,
    EmailEditorComponent,
    AddressEditorComponent,
    PracticeLocationEditorComponent,
    DiaryScrollerComponent,
    NoteScrollerComponent,
    AppointmentEditorComponent,
    UnassignedEncountersComponent,
    PhiSearchComponent,
    CompanySearchComponent,
    InvoiceListComponent,
    CompanyManagerComponent,
    ReportrunnerComponent,
    ReportviewerComponent,
    ReportmanagerComponent,
    ReportparameterComponent,
    EobSummaryComponent,
    FileScrollerComponent,
    EmailScrollerComponent,
    FaxScrollerComponent,
    InstanceSearchComponent,
    SidebarComponent,
    HomePageV2Component,
    JobMonitorComponent,
    MailSendManagerComponent
    // SinglePanelComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,

    // HTTP Client Modules
    HttpClientModule,

    // Prime NG Imports
    OverlayPanelModule,
    ButtonModule,
    SplitButtonModule,
    DialogModule,
    ProgressSpinnerModule,
    MenubarModule,
    PanelMenuModule,
    MenuModule,
    TabMenuModule,
    SlideMenuModule,
    PanelModule,
    CardModule,
    VirtualScrollerModule,
    ScrollPanelModule,
    TableModule,
    TabViewModule,
    SplitButtonModule,
    TreeModule,
    ProgressBarModule,
    InplaceModule,
    AvatarModule,
    AvatarGroupModule,
    InputMaskModule,
    DividerModule,
    EditorModule,
    ChartModule,
    TagModule,
    StepsModule,
    TooltipModule,
    AutoCompleteModule,
    MessagesModule,
    MessageModule,
    ToastModule,
    BadgeModule,
    InputSwitchModule,
    SidebarModule,
    ConfirmDialogModule,
    ConfirmPopupModule,
    FileUploadModule,
    PickListModule,
    ChipsModule,
    OverlayModule,


    // Prime NG Forms
    FormsModule,
    InputTextModule,
    InputTextareaModule,
    InputNumberModule,
    CalendarModule,
    CheckboxModule,
    RadioButtonModule,
    DropdownModule,
    FieldsetModule,
    AccordionModule,
    MultiSelectModule,
    SelectButtonModule,



    // AzureAD -- We're using environment variables for all this crap so we can change it during production
    MsalModule.forRoot
              ( new PublicClientApplication(
                  {
                    auth:
                      {
                        clientId: environment.azureAdClientId,
                        authority: environment.azureAdAuthority,
                        redirectUri: environment.azureAdRedirectUri
                      },
                    cache:
                      {
                        cacheLocation: 'localStorage',
                        storeAuthStateInCookie: isIE
                      }
                  } ), null,
                {
                  interactionType: InteractionType.Popup,
                  protectedResourceMap: new Map( environment.azureAdProtectedResourceMap )
                }
              ),

    ! environment.production ? StoreDevtoolsModule.instrument() : [],

    //Set up the ngrx store module
    StoreModule.forRoot({ encounterStatus:encounterStatusReducer,
                          closureReasons:encounterClosureReasonreducer,
                          sources:sourceReducer,
                          networks:networkReducer,
                          networkgroups:networkGroupReducer,
                          transactionTypes:transactionTypeeReducer,
                          categories:categoryReducer,
                          encounterTypes:encounterTypeReducer,
                          currentUser:userReducer,
                          userState:userStateReducer,
                          contactTypes:contactTypeReducer,
                          checkingAccounts:checkingAccountReducer,
                          appointmentStatuses:appointmentStatusReducer,
                          planTypes:planTypeReducer,
                          activeUsers:activeUsersReducer,
                          companyTypes:companyTypesReducer,
                          procHierarchy:procHierarchyReducer,
                          captives:captivesReducer,
                          twilioWorker:TwilioWorkerReducer,
                          twilioCurrentReservation:TwilioReservationReducer,
                          currentWorkerStatus:TwilioWorkerStatusReducer
                        }),


    EffectsModule.forRoot([AppEffects]),



  ],
  providers:
  [{
    provide: HTTP_INTERCEPTORS,
    useClass: MsalInterceptor,
    multi: true
  },
    MessageService,
    ConfirmationService
  ],
  bootstrap: [AppComponent]
})
export class AppModule
{ }
