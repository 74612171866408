import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { TcemsService } from 'src/app/api/tcems.service';
import { HEMIFileDescriptor } from 'src/app/model/util/filedescriptor';
import { TcemsUtilitiesService } from 'src/app/util/tcems-utilities.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-file-scroller',
  templateUrl: './file-scroller.component.html',
  styleUrls: ['./file-scroller.component.scss']
})
export class FileScrollerComponent implements OnInit
 {
  
  @Input() encounter: number;

  encounterFiles: HEMIFileDescriptor[] = [];
  // tempDescriptors: HEMIFileDescriptor[] = [];
  fileDestURL: string;
  fileTypes: string[];
  fileOriginalNames: string[] = [];

  constructor(
    private tcems: TcemsService,
    public util: TcemsUtilitiesService,
    private store: Store,
    private cdr: ChangeDetectorRef
  ) { }

  ngOnInit(): void 
  {
    this.getEncounterFiles();
    this.fileDestURL = environment.tcemsAPIBase  + environment.tcemsAPI_encounter + "/" + this.encounter.toString() + "/file"; 
    this.tcems.getAllAvailableFileTypes().toPromise().then(ft => this.fileTypes = ft);
  }

  getEncounterFiles()
  {

    //Get files for the supplied encounter
    this.tcems.fetchEncounterFiles(this.encounter).toPromise().then
    (
      el =>
      {
        this.encounterFiles = el.map(e => Object.assign(new HEMIFileDescriptor(), e));
      }
    );
  }

  fileUploaded(event)
  {
    this.getEncounterFiles();
  }

  deleteFile(fd: HEMIFileDescriptor)
  {
    this.tcems.deleteEncounterFile(this.encounter,fd.fileId).toPromise().then(fd => this.getEncounterFiles());
  }

  typeChanged(fd:HEMIFileDescriptor, event)
  {
    this.tcems.changeEncounterFileType(this.encounter, fd.fileId,fd.fileType).toPromise().then(fd => this.getEncounterFiles());
  }
  nameChanged(fd:HEMIFileDescriptor, event)
  {
    this.tcems.changeEncounterFileName(this.encounter, fd.fileId,fd.description).toPromise().then(fd => this.getEncounterFiles());
  }
  // onRowEditSave(fd: HEMIFileDescriptor, index: number)
  // {

  // }

  // onRowEditInit(fd: HEMIFileDescriptor)
  // {
  //   this.tempDescriptors[fd.fileId] = Object.assign(new HEMIFileDescriptor(),fd);
  // }

  // onRowEditCancel(fd: HEMIFileDescriptor, index:number)
  // {

  // }

  viewFile(fd: HEMIFileDescriptor)
  {
    //show popup with invoice PDF
    this.tcems.downloadEncounterFile(this.encounter,fd.fileId,fd.contentType)
    .then
    (
      blob =>
      {
        if (blob.size == 0)
        {
          this.util.displayError("Could Not Retrieve File","File Does Not Exist");          
          return;
        }
        let url = window.URL.createObjectURL(blob);
        let pwa = window.open(url,"Preview","height=640,width=960,toolbar=no,menubar=no,scrollbars=no,location=no,status=no");
      }
    )
    .catch
    (
      e =>
      {
        console.log(e);
        this.util.displayError("Could Not Open File",e.error);
      }
    )    
  }


}
