import { Component, OnInit } from '@angular/core';
import { TcemsUtilApi } from 'src/app/api/tcems.util.service';
import { MassEmailHeader } from 'src/app/model/util/massemail/mass_email';

@Component({
  selector: 'app-mail-send-manager',
  templateUrl: './mail-send-manager.component.html',
  styleUrls: ['./mail-send-manager.component.scss']
})
export class MailSendManagerComponent implements OnInit
{

  constructor(private utilApi:TcemsUtilApi)
  {

  }

  emails: MassEmailHeader[] = [];
  emailsFrom: Date;
  emailsTo: Date;
  showWait: boolean = false;


  ngOnInit(): void 
  {
    this.emailsFrom = new Date();
    this.emailsFrom.setDate(this.emailsFrom.getDate() - 90);
    this.emailsTo = new Date();
    this.emailsTo.setDate(this.emailsFrom.getDate() + 1);

    this.searchEmails();
  }

  searchEmails():void
  {
    this.showWait = true;
    this.utilApi.GetMassEmails(this.emailsFrom,this.emailsTo,null).subscribe
    (
      v => 
      {
        this.showWait = false;
        this.emails = v;
      }
    )
  }

  approve(email:MassEmailHeader)
  {
    this.utilApi.ApproveMassEmail(email.id).subscribe
    (
      e => 
      {
        //When we are done, we'll update our model
        Object.assign(this.emails.find(em => em.id == e.id), e);
      }
    )
  }


  send(email:MassEmailHeader)
  {
    this.utilApi.SendMassEmail(email.id).subscribe
    (
      e => 
      {
        //When we are done, we'll update our model
        Object.assign(this.emails.find(em => em.id == e.id), e);
      }
    )
  }
}
