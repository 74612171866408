import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { RouteReuseStrategy } from '@angular/router';
import { Store } from '@ngrx/store';
import { ConfirmationService, TreeNode } from 'primeng/api';
import { ApiService } from 'src/app/api/api.service';
import { TcemsService } from 'src/app/api/tcems.service';
import { Category } from 'src/app/model/claim/category';
import { InstanceOfCare } from 'src/app/model/claim/instance_of_care';
import { Line } from 'src/app/model/claim/line';
import { Procedure } from 'src/app/model/claim/procedure';
import { ProcedureHierarchy } from 'src/app/model/claim/procedure_hierarchy';
import { LocationResult, MRFPracticeResult, MRFResult, PracticeResult, ProcedureLookupResult } from 'src/app/model/claim/procedure_lookup_result';
import { EncounterModelProcedure } from 'src/app/model/encounter/encounter_model_procedure';
import { EncounterProvider } from 'src/app/model/encounter/encounter_provider';
import { ProviderLocation } from 'src/app/model/provider/provider_location';
import { Network, NetworkGroup } from 'src/app/model/support/network';
import { Source } from 'src/app/model/support/source';
import { PagingSpec } from 'src/app/model/util/resultpaging';
import { selectCategories, selectNetworkGroups, selectNetworks, selectSources } from 'src/app/state/app.state';

@Component({
  selector: 'app-procedure-lookup',
  templateUrl: './procedure-lookup.component.html',
  styleUrls: ['./procedure-lookup.component.scss']
})
export class ProcedureLookupComponent implements OnInit {

  //procHierarchy: ProcedureHierarchy = new ProcedureHierarchy();
  // procTree: ProcedureHierarchy;
  // filteredProcTree: TreeNode[];
  // selectedNodes: TreeNode[] = [];

  // procTreeHCPCS: ProcedureHierarchy;
  // filteredProcTreeHCPCS: TreeNode[];
  // selectedNodesHCPCS: TreeNode[] = [];
  @Input() selectedProcs: Procedure[];
  
  private _selectedCategory:Category;
  @Input() set selectedCategory(val: Category)
  {
    this._selectedCategory = val;
    this.lookbackDays = val.defaultLookbackDays;
  }

  get selectedCategory(): Category
  {
    return this._selectedCategory;
  }


  @Input() zipCode: string;
  @Input() radius: number = 25;
  @Input() lookbackDays: number = 60;
  @Input() ein: string = null;
  @Input() npi: string = null;

  @Input() set initialStep(val:number)
  {
    this.stepStates = [val-1==0,val-1==1,val-1==2];
  }
  @Input() set network(val: Network)
  {
    this.selectedNetwork = val;
  }

  @Input() set source(val: Source)
  {
    this.selectedSource = val;
  }

  @Input() set showSearchCriteria(val: boolean)
  {
    this.resultsPanelVisible = true;
  }

  // @Output() linesSelected: EventEmitter<EncounterModelProcedure[]> = new EventEmitter<EncounterModelProcedure[]>();
  // @Output() locationSelected: EventEmitter<ProviderLocation> = new EventEmitter<ProviderLocation>();
  @Output() selectedProcsChange: EventEmitter<Procedure[]> = new EventEmitter<Procedure[]>();
  @Output() encounterProviderChange: EventEmitter<EncounterProvider> = new EventEmitter<EncounterProvider>();

  networkOptions: Network[]; //{label: string, value:string} [];
  networkGroupOptions: NetworkGroup[]; //{label: string, value:string} [];
  sourceOptions: Source[]; //{label: string, value:string} [];
  // categoryOptions: Category[]; //{label: string, value: string} [];
  selectedSource: Source; //{label:string, value:string}; //{label: string, value:string};
  selectedNetwork: Network; //{label: string, value:string};
  // selectedCategory: Category; //{label: string, value: string};
  selectedNetworks: Network[]; //{label: string, value:string} [];
  selectedNetworkGroup: NetworkGroup; //{label: string, value:string};

  stepStates: boolean[] = [true,false,false]; //step 1 by default

  searching: boolean = false;
  searchIcon: string;
  resultsPanelVisible: boolean = false;
  selectedResultsType: number = 0;
  selectedResultSet: number = 0;


  sources$ = this.store.select(selectSources);
  sources : Source[];

  networks$ = this.store.select(selectNetworks);
  networks : Network[];

  networkGroups$ = this.store.select(selectNetworkGroups);
  networkGroups : NetworkGroup[];

  // categories$ = this.store.select(selectCategories);
  // categories: Category[];

  lookupResults: ProcedureLookupResult;
  mrfResults: MRFPracticeResult[] = null;
  selectedMRFResult: MRFPracticeResult = null;
  showPracticeEditor: {[key:string]: boolean} = {};

  instanceDialogVisible: number;
  instanceDialogs: {} = {};

  //These are the lines as they've been loaded from the database for procedure based lookups
  lines: {lineId:number,line:Line}[];
  instances: {instanceId:number,instance:InstanceOfCare}[];

  //Once a user selects a type of result, they can't use another
  //Additionally, this will allow for us to prevent selections from another provider
  selectedResult: PracticeResult;

  //It's entirely possible that a provider will have multiple locations in the same set of search results
  //This keeps track of the one that's been selected
  selectedLocation: LocationResult;


  pendingEncounterProvider: EncounterProvider = null;

  csp: EncounterModelProcedure[] = [];
  cgp: EncounterModelProcedure[] = [];
  csc: EncounterModelProcedure[] = [];
  cgc: EncounterModelProcedure[] = [];
  csi: EncounterModelProcedure[] = [];
  cgi: EncounterModelProcedure[] = [];
  mrf: EncounterModelProcedure[] = [];


  chosenSpecificProcs(event: EncounterModelProcedure[]) {this.csp = event;this.fireLinesSelected();}
  chosenGeneralProcs (event: EncounterModelProcedure[]) {this.cgp = event;this.fireLinesSelected();}
  chosenSpecificCats (event: EncounterModelProcedure[]) {this.cgc = []; this.csp = []; this.cgp = []; this.csi = []; this.cgi = []; this.csc = event;this.fireLinesSelected();}
  chosenGeneralCats (event: EncounterModelProcedure[]) {this.csc = []; this.csp = []; this.cgp = []; this.csi = []; this.cgi = []; this.cgc = event;this.fireLinesSelected();}
  chosenSpecificInstances (event: EncounterModelProcedure[]) {this.cgi = []; this.csp = []; this.cgp = []; this.csc = []; this.cgc = []; this.csi = event;this.fireLinesSelected();}
  chosenGeneralInstances (event: EncounterModelProcedure[]) {this.csi = []; this.csp = []; this.cgp = []; this.csc = []; this.cgc = [];this.cgi = event;this.fireLinesSelected();}
  clearAllSelections()
  {
    this.csp = [];
    this.cgp = [];
    this.csc = [];
    this.cgc = [];
    this.csi = [];
    this.cgi = [];
    this.mrf = [];
  }

  MRFChosen(event)
  {
    let mrfResult:MRFPracticeResult = event.data;
    //Choose the practice result
    this.chosenPracticeResult(mrfResult);

    //Set up the encounter model procedure(s)
    this.mrf = mrfResult.procedureBasedResults.map
      (
        r => 
        {
          let emp = new EncounterModelProcedure()
          emp.cost = r.topMRF.price;
          emp.mrfProcedurePriceId = r.topMRF.procedurePriceId;
          emp.units = 1;
          emp.unitCost = r.topMRF.price;
          emp.procedure = Object.assign(new Procedure(), r.topMRF.procedure);

          return emp;
        }
      );
    this.fireLinesSelected();

  }


  chosenPracticeResult (event: PracticeResult)
  {
    this.selectedPracticeResult = event;

    //Set our location to the first one
    this.selectedLocation = this.selectedPracticeResult.locations[0];

    //Also, we need to scrap our last encounter provider (if we have one) for a new one
    this.pendingEncounterProvider = new EncounterProvider();
    this.pendingEncounterProvider.override = false;
    this.encounterProviderChange.emit(this.pendingEncounterProvider);
    this.setLocationInEncounterProvider();
  }

  locationRowSelected(event)
  {
    this.selectedLocation = event.data;
    this.setLocationInEncounterProvider();
  }

  locationRowDeselected(event)
  {
    this.selectedLocation = null;
    this.setLocationInEncounterProvider();
  }

  setLocationInEncounterProvider()
  {

    if(this.selectedLocation != null)
    {


      //get our first provider location with this ein and location
      this.tcems.getProviderLocationsForPractice({ein: this.selectedPracticeResult.practice.ein, pageResults: false, pagingSpec: new PagingSpec()}).toPromise()
      .then
      (
        result =>
        {
          //Try for the facility record first
          let firstLocation = result.records.find(l => l.location.locationId == this.selectedLocation.location.locationId && l.providerIsOrganization && !l.conversionLocation);
          if(firstLocation == null)
          {
            //If no facility exists, take the first professional entry from there
            firstLocation = result.records.find(l => l.location.locationId == this.selectedLocation.location.locationId);
          }

          this.pendingEncounterProvider.providerLocation = firstLocation;
          this.encounterProviderChange.emit(this.pendingEncounterProvider);
          //if(firstLocation != null) this.locationSelected.emit(firstLocation);
        }
      )
    }
    else
    {
      this.pendingEncounterProvider.providerLocation = null;
      this.encounterProviderChange.emit(this.pendingEncounterProvider);
    }
  }

  fireLinesSelected()
  {
    this.pendingEncounterProvider.modelProcedures = this.pseudoInstance;
    this.pendingEncounterProvider.modelCost = this.pseudoInstance.reduce((a,b) => a + b.cost, 0);
    this.encounterProviderChange.emit(this.pendingEncounterProvider);
    // this.linesSelected.emit(this.pseudoInstance);
  }

  selectedPracticeResult: PracticeResult;

  private _selectedProvider: ProviderLocation;

  get selectedProvider(): ProviderLocation
  {
    return this._selectedProvider;
  }

  get pseudoInstance() :EncounterModelProcedure[]
  {
    return this.csp
    .concat(this.cgp)
    .concat(this.csc)
    .concat(this.cgc)
    .concat(this.csi)
    .concat(this.cgi)
    .concat(this.mrf);
  }

  get pseudoInstanceTotalUnitCost(): number
  {
    //return this.pendingEncounterProvider.modelCost;
    return this.pseudoInstance.reduce((a,b) => a+b.unitCost,0);
  }

  get pseudoInstanceTotalUnits(): number
  {
    //return this.pendingEncounterProvider.modelCost;
    return this.pseudoInstance.reduce((a,b) => a+b.units,0);
  }

  constructor(private tcems: TcemsService, private store: Store, private confirmationService: ConfirmationService)
  {
  }


  ngOnInit(): void
  {
    this.updateSearching(false);
    //Get our sources
    //We subscribe a local variable to whatever we can get from the store
    this.sources$.subscribe
    (
      s =>
      {
        this.sources = s
        //If we already have a selected source, ensure that this new list jives with it
        if(this.selectedSource != null) this.selectedSource = this.sources.find(s => s.sourceId == this.selectedSource.sourceId);
      }
    );
    this.networks$.subscribe
    (
      n =>
      {
        this.networks = n;
        //If we already have a selected network, ensure that this new list jives with it
        if(this.selectedNetwork != null) this.selectedNetwork = this.networks.find(n => n.networkId == this.selectedNetwork.networkId);
      }
    );
    this.networkGroups$.subscribe(ng => {this.networkGroups = ng});
    // this.categories$.subscribe(c => {this.categories = c});
    // Create our list of options from this
    this.sourceOptions = this.sources; // this.sources?.map(s => ({ label: s.sourceName, value:s.sourceId.toString()}));

    // console.log(this.networks);
    this.networkOptions = this.networks; // this.networks?.map(n => ({label: n.networkName + "-" + n.clientNetworkName , value:n.networkId.toString()}));
    this.networkGroupOptions = this.networkGroups; // this.networkGroups?.map(ng => ({label: ng.groupName, value:ng.groupId.toString()}));
    // this.categoryOptions = this.categories; // this.categories?.map(c => ({label: c.categoryName, value: c.categoryId.toString()}));

    // And then our networks


    // this.tcems.getCodes("CPT").toPromise()
    // .then( result =>
    //     {
    //       //Object.assign(this.procHierarchy,result);
    //       this.procTree = Object.assign(new ProcedureHierarchy(),result);
    //     });

    // this.tcems.getCodes("HCPCS").toPromise()
    //     .then( result =>
    //         {
    //           //Object.assign(this.procHierarchy,result);
    //           this.procTreeHCPCS = Object.assign(new ProcedureHierarchy(),result);
    //         });
  }

  codeSelected(): void
  {
    //Update our selected codes with the leaf nodes of what we've selected
  }

  doLookup(): void
  {
    this.clearAllSelections();

    //clear our current results first
    this.lookupResults = null;
    this.mrfResults = null;

    this.updateSearching(true);
    // Call our lookup method
    // console.log(this.selectedSource);

    // let procCodes: string[] = Array.prototype.concat(this.selectedNodes.map(n => n.data.procedureCode), this.selectedNodesHCPCS.map(n => n.data.procedureCode));

    let procCodes: string[] = this.selectedProcs.map(p => p.code);

    let standardLookupPromise = this.tcems.lookupProceduresInZip(this.zipCode,this.radius,this.selectedSource?.sourceId,procCodes
                        ,this.selectedNetwork?.networkId,this.selectedCategory?.categoryId,this.lookbackDays,
                        this.ein,this.npi).toPromise().then(result =>
      {
      

        //Load up our results
        this.lookupResults = Object.assign(new ProcedureLookupResult(),result);

        

        //console.log(this.selectedCategory);

        //console.log(this.lookupResults);
        //Deep instantiation
        //this.lookupResults.forEach(l => l.instancesOfCare = l.instancesOfCare.map(i => Object.assign(new InstanceOfCare(),i)));
        //this.lookupResults.forEach(l => l.)
        // console.log(this.lookupResults);
      })
    .catch(error =>
      {
        console.log('Procedure Lookup Failed: ' + error);
        this.lookupResults = null
      }

    );

    let mrfPromise = this.tcems.mrfLookupByZipRadius(this.zipCode,this.radius,this.selectedNetwork?.networkId,procCodes,
      this.ein).toPromise().then(result =>
    {
      if (result != null)
        this.mrfResults = [ ... result as MRFPracticeResult[] ];
      this.mrfResults.forEach(m => m.procedureBasedResults.forEach(p => p.procedure = Object.assign(new Procedure(),p.procedure)));

    })
    .catch
    (error =>
      {
        this.mrfResults = null;
        console.log('MRF Lookup failed: ' + error);
      }
    );

    Promise.all([standardLookupPromise,mrfPromise])
    .then
    (
      result =>
      {
        
        this.updateSearching(false);

        if(this.lookupResults != null)
        {
          //Dynamically set the accordion and tab indicies for results based on what came back
          //If we have no specific entries, default to general results
          if(this.lookupResults.specificInstanceResults.length == 0)
          {
            this.selectedResultSet = 1;
          }
          else
          {
            this.selectedResultSet = 0;
          }

          //Now, go to the correct result type inside that set
          let defaultType:string = this.selectedCategory.defaultResultType;
          if(defaultType == 'P')
          {
            this.selectedResultsType = 0;
          }
          else if (defaultType == 'C')
          {
            this.selectedResultsType = 1;
          }
          else if (defaultType == 'I')
          {
            this.selectedResultsType = 2;
          }
        }
        else if(this.mrfResults != null)
        {
          this.selectedResultSet = 2;
        }
        
        this.resultsPanelVisible = true;
      }
    );



  }

  updateSearching(searching:boolean)
  {
    this.searching = searching;
    this.searchIcon = searching ? 'pi pi-spin pi-spinner' : '';
  }

  showInstanceDialog(instanceId: number)
  {
    this.instanceDialogVisible = instanceId;
    this.instanceDialogs[instanceId.toString()] = true;
  }

  selectedProcsChanged(procs: Procedure[])
  {
    this.selectedProcs = procs;
    this.selectedProcsChange.emit(procs);
  }

  deletePseudoLine(pl: EncounterModelProcedure)
  {
    this.confirmationService.confirm({
      target: event.target,
      message: 'Delete Selected Line?',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
          //Find this thing...
          this.cgc = [... this.cgc.filter(v => v != pl)];
          this.cgi = [... this.cgi.filter(v => v != pl)];
          this.cgp = [... this.cgp.filter(v => v != pl)];
          this.csc = [... this.csc.filter(v => v != pl)];
          this.csi = [... this.csi.filter(v => v != pl)];
          this.csp = [... this.csp.filter(v => v != pl)];
          this.mrf = [... this.mrf.filter(v => v != pl)];
          this.fireLinesSelected();
      },
      reject: () => {
          //Do nothing... we're good.
      }
    });
  }
  // toggleSearchPane(event)
  // {
  //   this.resultsPanelVisible = !event.collapsed;
  // }

  // setProcs(event)
  // {
  //   this.selectedProcs = event;
  // }

  // setCategory(event)
  // {
  //   this.selectedCategory = event;
  // }

  // categorySelected(event)
  // {
  //   //No matter what happens, we need to clear our cpt selections
  //   this.selectedNodes = [];
  //   this.selectedNodesHCPCS = [];

  //   console.log("Category Selected");
  //   if (this.selectedCategory == null)
  //   {
  //     this.filteredProcTree = [];
  //     this.filteredProcTreeHCPCS = [];
  //   }
  //   else
  //   {
  //     this.filteredProcTree = this.procTree.filter(this.selectedCategory.procedures.map(p => p.procedureId))?.asTreeNode(true).children; //(tn => this.selectedCategory.procedures.map(p => p.procedureId.toString()).includes(tn.key));
  //     this.filteredProcTreeHCPCS = this.procTreeHCPCS.filter(this.selectedCategory.procedures.map(p => p.procedureId))?.asTreeNode(true).children; //(tn => this.selectedCategory.procedures.map(p => p.procedureId.toString()).includes(tn.key));

  //     //.asTreeNode(true).children
  //     //console.log(this.filteredProcTree);
  //   }

  // }
}
