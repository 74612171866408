<!-- For Error Messages -->
<p-toast position="top-center" key="searchErrors"></p-toast>



<div class="flex flex-column fill-vert p-1 no-overflow">
    <!--Search Pane - Full Width-->
    <!--<div class="shadow-10 align-items-start" >-->
  
    <p-panel header="Search Criteria">
        <div class="pt-3">
            <div class="formgroup-inline">
                <!--Dynamically load fields here-->
                <div *ngFor="let field of fields" class="field"> 
                    <span *ngIf='field.typeIsString()' class="p-float-label">
                        <input  [id]="field.key" type="text" pInputText [(ngModel)]="field.value"/>
                        <label [for]="field.key">{{field.label}}</label> 
                    </span>
                    <span *ngIf='field.typeIsDate()' class="p-float-label">
                        <p-calendar [id]="field.key" [(ngModel)]="field.value"></p-calendar>
                        <label [for]="field.key">{{field.label}}</label> 
                    </span>
                    <span *ngIf='field.typeIsSingleChoice()' class="p-float-label">
                        <p-dropdown [id]="field.key" [options]="field.options" [(ngModel)]="field.selectedOptions" optionLabel="label"></p-dropdown>
                        <label [for]="field.key">{{field.label}}</label> 
                    </span>                   
                    <span *ngIf='field.typeIsMultiChoice()' class="p-float-label" style="min-width: 150px;" >
                        <p-multiSelect [id]="field.key" [options]="field.options" [(ngModel)]="field.selectedOptions" optionLabel="label" [style]="{'width':'200px'}"></p-multiSelect>
                        <label [for]="field.key">{{field.label}}</label> 
                    </span>      
                    <span *ngIf='field.typeIsCheck()' class="field-checkbox pl-3">
                        <!-- <p-multiSelect [id]="field.key" [(ngModel)]="field.selectedOptions"></p-multiSelect> -->
                        <p-checkbox [(ngModel)]="field.value" [binary]="true" [id]="field.key"></p-checkbox>                        
                        <label [for]="field.key">{{field.label}}</label> 
                    </span>    
                </div>
                
                <div class="field">
                    <div >
                        <p-button *ngIf="!searchInProgress" label="Search" (click)="search()" [disabled]="false" iconPos="right"></p-button>
                        <p-button *ngIf="searchInProgress" label="Search" (click)="search()" [disabled]="true" iconPos="right" icon="pi pi-spin pi-spinner"></p-button>
                    </div>
                    <div style="color: red;">
                        {{message}}
                    </div>
                </div>
            </div>
        </div>
    </p-panel>
    <!--</div>-->
<!-- Lower portion of screen -->
<div class="mt-3 p-2 grid no-overflow" style="flex-grow:1; flex-shrink:1">
    <!-- <div class="grid fill-vert" style="background-color: red;"> -->
        <!--Results Pane - Left - 25%-->
        <div class="col-3 fill-vert scroll-overflow" >

            <!--<div class="fill-vert" style="background-color: orange;">-->
                <p-panel header="Search Results">
                    <div class="formgroup-inline pt-3 pb-1" style="height:55px">
                        <span class="p-float-label">
                            <p-dropdown [id]="sortColumns" [options]="sortColumns" [(ngModel)]="selectedSortColumn" optionLabel="key" [showClear]="false" (onChange)="sortResults()"></p-dropdown>
                            <label for="sortColumns">Sort By</label> 
                        </span>
                        <span class="field-checkbox pl-3">
                            <p-checkbox [(ngModel)]="sortDescending" [binary]="true" inputId="sortDescending" (onChange)="sortResults()"></p-checkbox>
                            <label for="sortDescending">Sort Descending</label>
                        </span>
                    </div>
                    <div class="fill-vert">
                        <div *ngFor="let searchResult of foundSet" class="fill-vert"> 
                            <!-- <p-virtualScroller [(value)]="foundSet" scrollHeight="525px" > -->
                            <!-- <ng-template pTemplate="item" let-searchResult > -->
                                <div [ngClass]="{ 
                                    'm-2': true,
                                    'p-1': true,
                                    'grid': true,
                                    'shadow-10': true,
                                    'selected': searchResult.id == selectedId }">
                                    <div class="col-9 align-self-top">
                                        <div>{{searchResult.line1}}</div>
                                        <div>{{searchResult.line2}}</div>
                                        <div>{{searchResult.line3}} </div>
                                    </div>
                                    <div class='col align-self-center'>
                                        <button pButton  type="button" icon="pi pi-chevron-right" class="p-button-rounded" (click)="select(searchResult.id)"></button>
                                    </div>
                                </div>
                            <!-- </ng-template> -->
                        </div>
                            <!-- </p-virtualScroller> -->
                    </div>
                </p-panel>
            <!--</div>-->
        </div>

        <!--Detail Pan - Right - 75% -->
        <div class="col ml-2 scroll-overflow fill-vert">
            <div class="flex flex-column scroll-overflow fill-vert" >
                <p-panel header="Detail"> 
                        <div>  <!--Same height as the scroller pane-->
                            <ng-template #defaultDetails></ng-template>
                            <!-- <ng-template #detailsPane></ng-template> This is where we'll dynamically insert our details pane -->
                            <ng-container [ngTemplateOutlet]="detailsRef || defaultDetails"></ng-container>
                            <!-- <app-phi-detail *ngIf="selectedId != null" [selectedPhi]="selectedPhi"></app-phi-detail> -->
                        </div>
                </p-panel>
            </div>
        </div>
    <!-- </div> -->
</div>
</div>