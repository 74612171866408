import { Component, OnInit, Input } from '@angular/core';
import { Phi } from 'src/app/model/phi/phi';
import { SelectItem } from 'primeng/api';
import { Plan } from 'src/app/model/phi/plan';

@Component({
  selector: 'app-phi-detail',
  templateUrl: './phi-detail.component.html',
  styleUrls: ['./phi-detail.component.scss']
})
export class PhiDetailComponent implements OnInit {


  @Input() selectedPhi: Phi;

  addressTypes: SelectItem[];
  emailTypes: SelectItem[];
  phoneTypes: SelectItem[];
  planDetailCols: any[];
  planDetailsPlan: Plan;

  constructor() { }

  ngOnInit(): void {

    // Initialize address types
    this.addressTypes = [
      {label: 'Primary', value: 'Primary'},
      {label: 'Secondary', value: 'Secondary'},
      {label: 'Mailing', value: 'Mailing'}
    ];

    this.emailTypes = [
      {label: 'Primary', value: 'Primary'},
      {label: 'Other', value: 'Other'}
    ];

    this.phoneTypes = [
      {label: 'Home', value: 'Home'},
      {label: 'Work', value: 'Work'},
      {label: 'Mobile', value: 'Mobile'}
    ];

    this.planDetailCols = [
      {field: 'detailColonoscopy', display: 'Colonscopy'},
      {field: 'detailLabs' , display: 'Laboratory'},
      {field: 'detailOccTherapy', display: 'Occupational Therapy'},
      {field: 'detailsOutpatientSurgery', display: 'Outpatient Surgery'},
      {field: 'detailPt', display: 'Physical Therapy'},
      {field: 'detailRadiology', display: 'Radiology'},
      {field: 'detailUrgentCare', display: 'Urgent Care'}
    ];

  }

  addAddress(): void
  {
    // TODO: Does this need to be implemented, or is it handled elsewhere?
    // console.log("Address Added");
  }

  addEmail(): void
  {
    // TODO: Does this need to be implemented, or is it handled elsewhere?
    // console.log("Email Added");
  }

  addPhone(): void
  {
    // TODO: Does this need to be implemented, or is it handled elsewhere?
    // console.log("Phone Added");
  }
}
