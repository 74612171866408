import { Component, Directive, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
// import { SinglePanelImplementor } from './single-panel-implementor';

// @Component({
//   selector: 'app-single-panel',
//   templateUrl: './single-panel.component.html',
//   styleUrls: ['./single-panel.component.scss']
// })
@Directive ({})
export abstract class SinglePanelComponent<T> 
implements OnInit, OnChanges
{

  
  // private _modelData: T;
  // @Input() set modelData(val: T)
  // {
  //   this._modelData = val;
  //   if(val != null)
  //   {
  //     this.loadPanelFromModelData();
  //   }
  // }

  // get modelData(): T
  // {
  //   return this._modelData;
  // }



  @Input() modelData: T;
  
  @Input() modelId: any;

  isLoading: boolean = false;
  isLoaded: boolean = false;
  message: string = "";

  constructor() { }
  ngOnChanges(changes: SimpleChanges): void 
  {
    this.ngOnInit();
  }

  ngOnInit(): void 
  {
    this.isLoading = true;
    //If we don't have data, then load the model data from whatever id is passed in
    if(this.modelData == null)
    {
      this.loadModelDataFromId()
        .then(
          (result: T) =>
          {
            this.modelData = Object.assign(this.createNewEmptyObject(), result);
            this.loadPanelFromModelData();
          });
    }
    else
      //Now, load the panel from the model data
      this.loadPanelFromModelData();
    this.isLoading = false;

    this.isLoaded = true;
    
  }

  protected abstract loadPanelFromModelData();
  protected abstract loadModelDataFromId() : Promise<T>;
  protected abstract createNewEmptyObject(): T;

}
