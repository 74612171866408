import { Injectable, OnInit, Output, EventEmitter, isDevMode } from '@angular/core';
import { environment } from '../../environments/environment';

//This is all the AzureAD Stuff
import { MsalService, MsalBroadcastService } from '@azure/msal-angular';
import { Logger, CryptoUtils, UserAgentApplication, AuthenticationParameters, AuthResponse } from 'msal';

import { ApiService } from '../api/api.service';
import { ADProfile } from '../model/util/adprofile';
import { User } from '../model/util/user';
import { BehaviorSubject } from 'rxjs';
import { Store } from '@ngrx/store';
import { loggedIn } from 'src/app/actions/login.actions';
import { TcemsService } from '../api/tcems.service';

// const adUserDetailScope: string = environment.azureAPIScope_me;
// const tcemsUserDetailScope: string = environment.tcemsAPIScope_util;

@Injectable({
    providedIn: 'root'
})
export class LoginService implements OnInit
{

    isIframe: boolean = false;


    //This will be the value that the sytem monitors to know if we're logged in or not
    private _loggedIn = new BehaviorSubject<Login>(new Login(false, false, null,null));

    //make this value observable
    loggedIn$ = this._loggedIn.asObservable();

    // constructor() {}

    // ngOnInit() {
    //   this.store.dispatch({ type: '[Movies Page] Load Movies' });
    // }
    constructor(private broadcastService: MsalBroadcastService,
                private authService: MsalService,
                private apiService: ApiService,
                private tcemsService: TcemsService,
                private store: Store) { }


    ngOnInit() : void
    {

    //Determine if we're in an iFrame
    this.isIframe = window !== window.parent && !window.opener;


    }


  login()
  {

      this.authService.loginPopup().subscribe
      ({
        next: (result) =>
        {
          // console.log(result);
          this.checkAuthorization();
        },
        error: (error) => {this.changeLogin(false,false,"Login Error");}
      })
  }

  logout()
  {
    this.authService.logoutPopup({postLogoutRedirectUri: environment.logoutRedirect});
  }


  //Azure has two layers to logging in
  //1.) Log in with your credentials (Authenticate)
  //2.) Try to get a token for an endpoint we'll use (Authorize)
  checkAuthentication() : boolean
  {
    this.changeLogin(false,true, "Checking Authentication");

    var a : boolean = this.authService.instance.getAllAccounts().length > 0;
    if (!a)
    {
      this.changeLogin(false,false,"Not Logged In");
    }
    //We have a login token, but we should check the timestamp of the expiry
    else
    {
         this.changeLogin(false,false,"Login Token Expired, Please Log In Again");
      }


    return a;
  }


  checkAuthorization() : Promise<boolean>
  {

    this.changeLogin(false, true, "Checking Authorization");

    //So, we can check if we're logged in. Chances are pretty good that we are already
    if(this.checkAuthentication())
    {


        //Get a token that we can use to get further user details
        //ls.apiService.getToken([adUserDetailScope]);

        //Get our user details from Azure AD first
        return this.getADUserDetails()

        //Then, pass the azure id off to the tcems api to get the user from there
        .then((profile: ADProfile) =>
        {
            //find the correct user
            this.getTCEMSUserDetails(profile.id)

            //Also, threaded... so we wait for the promise to finish
            .then((result: User) =>
            {
              //Okay, we're set, and we should have a user here.

              //Notify that we're logged in!
              //this.notifyLogin.emit(new Login(true,"Zach",1));
              // console.log(result);
              this.store.dispatch({type:'[Login] Successful Login', currentUser: Object.assign(new User(),result)});
              this.changeLogin(true,false,"Logged In!", result);


              return true;

            })

            //If we don't find the user, then we get an error
            .catch(error =>
              {
                this.changeLogin(false, false,"User Not Found");
                return false;
              })
            return false;
            ;
        })

        .catch(error => {
          this.changeLogin(false,false, "Authorization Error: " + error);
          return false;

        });


    }

    this.changeLogin(false,false); //Not logged in
    return new Promise<boolean> (() => {return false;});

  }

  //Syntactic sugar for updating our observed login state...
  changeLogin(loggedIn: boolean, loggingIn: boolean, message?: string, user?: User)
  {
    this._loggedIn.next(new Login(loggedIn,loggingIn, message,user));
  }

  getADUserDetails() : Promise<ADProfile>
  {
      return this.apiService.makeAPIGetCall<ADProfile>(environment.azureAPIBase, environment.azureAPI_me);
  }

  getTCEMSUserDetails(adId: string): Promise<User>
  {
    if(isDevMode())
    {
      //always return a user that we can work with that has real data
      return this.tcemsService.getUser(environment.devUserId).toPromise();
    }
    else
    {
      return this.apiService.makeAPIGetCall<User>(environment.tcemsAPIBase, environment.tcemsAPI_util + '/user',
        {"adId": adId});
    }
  }
}


//POCO for a login object
export class Login
{

  constructor(
      public loggedIn: boolean,
      public loggingIn: boolean,
      public message?: string,
      public currentUser?: User)
  {
  }
}
