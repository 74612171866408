import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { TcemsService } from 'src/app/api/tcems.service';
import { EncounterProvider } from 'src/app/model/encounter/encounter_provider';
import { ProviderLocation, ProviderLocationUpdate } from 'src/app/model/provider/provider_location';
import { ReferenceCode } from 'src/app/model/support/reference_code';
import { TcemsUtilitiesService } from 'src/app/util/tcems-utilities.service';
import { Procedure } from 'src/app/model/claim/procedure';
import { Practice } from 'src/app/model/provider/practice';

@Component({
  selector: 'app-encounter-provider',
  templateUrl: './encounter-provider.component.html',
  styleUrls: ['./encounter-provider.component.css']
})
export class EncounterProviderComponent implements OnInit {

  @Input() provider: EncounterProvider
  @Input() showProviderInfo: boolean = false

  practiceEditorVisible: boolean;
  noteScrollerType: ReferenceCode = ReferenceCode.create(ReferenceCode.TYPE_PRACTICE_LOCATION);
  constructor(private tcems:TcemsService, public util:TcemsUtilitiesService, private cdr: ChangeDetectorRef) { }

  ngOnInit(): void 
  {
    this.provider.modelProcedures.forEach(mp => mp.procedure = Object.assign(new Procedure(),mp.procedure))
  }
  
  commitPhone(providerLocation: ProviderLocation)
  {
    //Issue a provider location update statement
    let plu:ProviderLocationUpdate = new ProviderLocationUpdate();
    //no need to update if nothing has changed
    
    plu.phoneNumber = providerLocation.phoneNumber;
    plu.faxNumber = providerLocation.faxNumber;


    this.tcems.updateProviderLocation({ein: providerLocation.ein,providerLocationId:providerLocation.id,providerLocation:plu}).toPromise().then
    (
      
    )
  }
  practiceUpdate(practice: Practice)
  {
    this.cdr.detectChanges();
  }

}
