import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { SearchField } from 'src/app/base/search-with-details/search-field';
import { SearchWithDetailsComponent } from 'src/app/base/search-with-details/search-with-details.component';
import { SearchResult } from 'src/app/base/search-with-details/search-result';
import { ApiService } from 'src/app/api/api.service';
import { environment } from 'src/environments/environment';
import { formatDate } from '@angular/common';
import { SearchWithDetailsImplementor } from 'src/app/base/search-with-details/search-with-details-implementor';
import { SinglePanelComponent } from 'src/app/base/single-panel/single-panel.component';
import { Type } from '@angular/core';
import { Visit } from 'src/app/model/claim/visit';
import { Line } from 'src/app/model/claim/line';
import { Claim } from 'src/app/model/claim/claim';
import { EVENT_KEYS } from 'msal/lib-commonjs/telemetry/ApiEvent';
import { Procedure } from 'src/app/model/claim/procedure';
import { SearchError } from 'src/app/base/search-with-details/search-error';
import { TcemsService } from 'src/app/api/tcems.service';
import { InstanceOfCare } from 'src/app/model/claim/instance_of_care';
import { Observable, of } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { AbstractSearchWithDetailsComponent } from 'src/app/base/search-with-details/abstract-search-with-details.component';
import { MessageService } from 'primeng/api';
// import { SinglePanelImplementor } from 'src/app/base/single-panel/single-panel-implementor';

@Component({
  selector: 'app-instance',
  templateUrl: './instance.component.html',
  styleUrls: ['./instance.component.scss']
})

export class InstanceComponent
implements OnInit, OnChanges
  // extends SinglePanelComponent<InstanceOfCare>
  // implements SearchWithDetailsImplementor<InstanceOfCare>
// extends SearchWithDetailsComponent<InstanceOfCare> //implements OnInit
{
  protected createNewEmptyObject(): InstanceOfCare
  {
    return new InstanceOfCare();
  }

  lineIds: number[] = [];

  @Input() modelData: InstanceOfCare;
  @Input() modelId: number;

  @Input() instanceAndLines : {instance: InstanceOfCare, lines: Line[]}
 

  @Output() linesSelected = new EventEmitter<number[]>();

  //This component can search for instances in one of two ways:
  //1.) By direct instance Id -> instance
  //2.) By search on membner id and dates of service -> search

  

  ngOnInit()
  {
    
    if(this.modelId != null)
    {
      this.loadModelDataFromId();
    }
    if(this.instanceAndLines != null)
    {
      this.modelData = this.instanceAndLines.instance;
      this.lineIds = this.instanceAndLines.lines.map(l => l.lineId);      
    }
    //Decide how we're going to load this thing
    if(this.modelData != null)
    {
      this.loadPanelFromModelData();
    }
    
  }




  //These are the methods that we implement for being a single panel
  //component as well

  constructor(private tcemsService: TcemsService,  private apiService: ApiService)
  {

  }


  ngOnChanges(changes: SimpleChanges): void 
  {

    this.ngOnInit();
    
  }

  // getDetailPanel(): Type<SinglePanelComponent<InstanceOfCare>> 
  // {
  //   return InstanceComponent;
  // }

  //convenience properties
  visits: Visit[];
  claims: Claim[];
  lines: Line[];


  //Fields for what is selected in the table
  selectedVisits: Visit[];

  selectedClaims: Claim[];

  selectedLines: Line[];

  // Selected Row Calculations
  selectedProcedureTotal: number;


  loadModelDataFromId()
  {
    // console.log("Loading Instance From Id")
    // Fetch our instance from the api
    return this.tcemsService.getInstanceOfCare(this.modelId).toPromise().then(i => {this.modelData = i; this.loadPanelFromModelData()});

  }

  loadPanelFromModelData()
  {
    //Make sure we've got an actual instance of the Instance
    this.modelData = Object.assign(new InstanceOfCare(), this.modelData);

    //We want all the visits to be selected to start
    this.visits = (this.modelData as any).visits;
    this.claims = this.visits.map(v => v.claims).reduce((a,b) => a.concat(b));
    this.lines = this.claims.map( c => c.lines).reduce((a,b) => a.concat(b));

    //Make sure we are really working with Procedure objects on each of the lines
    this.lines.forEach(l => l.procedure = Object.assign(new Procedure(), l.procedure));

    this.selectedVisits = this.visits;
    this.selectedClaims = this.claims;
    this.selectedLines = this.lineIds == null || this.lineIds.length == 0 ?  this.lines : this.lines.filter(l => this.lineIds.includes(l.lineId));

    this.calculateSelectedCost();
    this.emitLinesChanged();
  }

  // selectResult(selectedItem: SearchResult<InstanceOfCare>) :any
  // {
  //   return selectedItem.payload;
  // }

  private calculateSelectedCost()
  {
    this.selectedProcedureTotal = this.selectedLines.map(l => l.cost).reduce((a,b) => a+b,0);
  }


  public selectVisit(event)
  {
    //Update all of our models based on the selected Visits
    this.selectedClaims = this.selectedVisits.map(v=> v.claims).reduce((a,b) => a.concat(b));
    this.selectedLines = this.selectedClaims.map(v=> v.lines).reduce((a,b) => a.concat(b));

    //recalculate selected price
    this.calculateSelectedCost();
    this.emitLinesChanged();

  }

  public unselectVisit(event)
  {

    //Update all of our models based on the selected Visits
    if (this.selectedVisits.length == 0)
    {
      this.selectedClaims = [];
      this.selectedLines = [];
    }
    else
    {
      this.selectedClaims = this.selectedVisits.map(v=> v.claims).reduce((a,b) => a.concat(b));
      this.selectedLines = this.selectedVisits.map(v=> v.claims).reduce((a,b) => a.concat(b)).map(v=> v.lines).reduce((a,b) => a.concat(b));
    }

    //recalculate selected price
    this.calculateSelectedCost();
    this.emitLinesChanged();

  }

  public selectClaim(event)
  {
    //We have to work on selections both up the hierarchy, as well as down. Do the lines first. It's easier
    this.selectedLines = this.selectedClaims.map(v=> v.lines).reduce((a,b) => a.concat(b));

    //Now, select visits from the currently selected claims
    this.selectedVisits = this.visits.filter(v => v.claims.filter(c => this.selectedClaims.includes(c)).length > 0);

    //recalculate selected price
    this.calculateSelectedCost();
    this.emitLinesChanged();

  }

  public unselectClaim(event)
  {

    if(this.selectedClaims.length == 0)
    {
      this.selectedLines = [];
    }
    else
    {
      //We have to work on selections both up the hierarchy, as well as down. Do the lines first. It's easier
      this.selectedLines = this.selectedClaims.map(v=> v.lines).reduce((a,b) => a.concat(b));
      //Now, select visits from the currently selected claims
      this.selectedVisits = this.visits.filter(v => v.claims.filter(c => this.selectedClaims.includes(c)).length > 0);
    }

    //recalculate selected price
    this.calculateSelectedCost();
    this.emitLinesChanged();


  }

  public selectLine(event)
  {
    //In this case, we only have to make selections back up the hierarchy.
    this.selectedClaims = this.claims.filter(c => c.lines.filter(l => this.selectedLines.includes(l)).length > 0);
    this.selectedVisits = this.visits.filter(v => v.claims.filter(c => this.selectedClaims.includes(c)).length > 0);

    //recalculate selected price
    this.calculateSelectedCost();

    //emit change
    this.emitLinesChanged();
  }

  public unselectLine(event)
  {
    if(this.selectedLines.length == 0)
    {
      this.selectedClaims = [];
      this.selectedVisits = [];
    }
    else
    {
      //Thankfully, this is the same as above
      this.selectedClaims = this.claims.filter(c => c.lines.filter(l => this.selectedLines.includes(l)).length > 0);
      this.selectedVisits = this.visits.filter(v => v.claims.filter(c => this.selectedClaims.includes(c)).length > 0);
    }
    //recalculate selected price
    this.calculateSelectedCost();
    this.emitLinesChanged();
  }

  emitLinesChanged()
  {
    this.linesSelected.emit(this.selectedLines.reduce((a,b) => a.concat(b.lineId), []));
  }


}
