import { User } from '../util/user';
import { ContactType } from '../util/contact_type';

export class Address
{
    active: boolean;
    address1: string;
    address2: string;
    addressId: number;
    addressType: ContactType;
    city: string;
    effectiveDate: Date;
    modifiedBy: User;
    state: string;
    zipCode: string;

    constructor()
    {
      this.address1 = null;
      this.address2 = null;
      this.city = null;
      this.state = null;
      this.zipCode = null;
      this.addressType = new ContactType();
      this.active = true;
    }
}

export class AddressUpdate
{
    active: boolean;
    address1: string;
    address2: string;
    addressId: number;
    addressType: ContactType;
    city: string;
    effectiveDate: Date;
    state: string;
    zipCode: string;
}


