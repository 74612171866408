import { Category } from "../claim/category";
import { Procedure } from "../claim/procedure";
import { User } from "../util/user";

export class EncounterProcedure
{
    id:number;
    primaryProcedure: boolean;
    encounterId: number;
    modifiedBy: User;
    procedure: Procedure;
    category: Category;

    asUpdate()
    {
        let epu:EncounterProcedureUpdate = new EncounterProcedureUpdate();
        epu.encounterId = this.encounterId,
        epu.primaryProcedure = this.primaryProcedure,
        epu.procedureId = this.procedure.procedureId
        return epu;
    }
}

export class EncounterProcedureUpdate
{
    primaryProcedure: boolean;
    encounterId: number;
    procedureId: number;
}