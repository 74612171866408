import { createReducer, on } from "@ngrx/store";
import { ReservationAccepted, ReservationCancelled, ReservationCreated, ReservationRejected, ReservationTimeout, ReservationWrapup, WorkerReady, WorkerStatusChanged } from "../actions/twilio.actions";

export const initialWorker = {};
export const initialReservation:{reservation:any,reservationStatus:string} = {reservation:null,reservationStatus:null};
export const initialStatus: string = "Initializing";


export const TwilioWorkerReducer = createReducer(
    initialWorker,
    on(WorkerReady, (state, {worker}) => worker)
  );

export const TwilioWorkerStatusReducer = createReducer(
    initialStatus,
    on(WorkerStatusChanged, (state, {workerStatus}) => workerStatus)
  );

export const TwilioReservationReducer = createReducer(
      initialReservation,
      on(ReservationAccepted,(state,{reservation}) => ({reservation:reservation,reservationStatus:"Accepted"}))
      ,on(ReservationCancelled,(state,{reservation}) => ({reservation:reservation,reservationStatus:"Cancelled"}))
      ,on(ReservationCreated,(state,{reservation}) => ({reservation:reservation,reservationStatus:"Created"}))
      ,on(ReservationRejected,(state,{reservation}) => ({reservation:reservation,reservationStatus:"Rejected"}))
      ,on(ReservationTimeout,(state,{reservation}) => ({reservation:reservation,reservationStatus:"Timeout"}))
      ,on(ReservationCancelled,(state,{reservation}) => ({reservation:reservation,reservationStatus:"Cancelled"}))
      ,on(ReservationWrapup,(state,{reservation}) => ({reservation:reservation,reservationStatus:"Wrapup"}))
  );

