import { formatDate } from '@angular/common';
import { Injectable } from '@angular/core';
import { from, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { MatchSummary } from '../model/claim/match_summary';
import { ClosureReason } from '../model/encounter/closure_reason';
import { EncounterStatus } from '../model/encounter/encounter_status';
import { EncounterSummary } from '../model/encounter/encounter_summary';
import { Encounter, EncounterGroup, EncounterType, EncounterUpdate, UserEncounter } from '../model/encounter/encounter';
import { ApiService } from './api.service';
import { InstanceOfCare } from '../model/claim/instance_of_care';
import { ConfirmedEncounter, ConfirmedInstance } from '../model/claim/confirmed_instance';
import { ProcedureHierarchy } from '../model/claim/procedure_hierarchy';
import { MRFPracticeResult, ProcedureLookupResult } from '../model/claim/procedure_lookup_result';
import { EncounterProvider, EncounterProviderUpdate } from '../model/encounter/encounter_provider';
import { Source } from '../model/support/source';
import { Transaction, TransactionType } from '../model/finance/transaction';
import { CheckingAccount } from '../model/finance/checking_account';
import { Check, CheckPosting } from '../model/finance/check';
import { Network, NetworkGroup } from '../model/support/network';
import { Category } from '../model/claim/category';
import { Line } from '../model/claim/line';
import { Phi, PhiUpdate } from '../model/phi/phi';
import { Diary, DiaryType, DiaryUpdate } from '../model/support/diary';
import { PagingResult, PagingSpec, SortDetails, SortSpec } from '../model/util/resultpaging';
import { User, UserState } from '../model/util/user';
import { Note, NoteType, NoteUpdate } from '../model/support/note';
import { ReferenceCode } from '../model/support/reference_code';
import { PhoneNumber, PhoneNumberUpdate } from '../model/phi/phone-number';
import { ContactType } from '../model/util/contact_type';
import { EncounterProcedure, EncounterProcedureUpdate } from '../model/encounter/encounter_procedure';
import { Practice, PracticeUpdate } from '../model/provider/practice';
import { Provider } from '../model/provider/provider';
import { ProviderLocation, ProviderLocationUpdate } from '../model/provider/provider_location';
import { Distance } from '../model/geocode/distance';
import { EncounterModelProcedure, EncounterModelProcedureUpdate } from '../model/encounter/encounter_model_procedure';
import { EmailAddress, EmailAddressUpdate } from '../model/phi/email-address';
import { Address, AddressUpdate } from '../model/phi/address';
import { Plan, PlanType, PlanUpdate } from '../model/phi/plan';
import { AppointmentStatus } from '../model/encounter/appointment_status';
import { Appointment, AppointmentUpdate } from '../model/encounter/appointment';
import { Company, CompanyInsert, CompanyUpdate } from '../model/support/company';
import { TwilioConnection } from '../model/comm/twilioconnection';
import { Invoice, InvoiceInsert, InvoiceRule, InvoiceRuleInsert, InvoiceRuleUpdate, InvoiceUpdate } from '../model/finance/invoice';
import { RewardRule, RewardRuleInsert, RewardRuleUpdate } from '../model/finance/reward_rule';
import { WhiteLabelProgram, WhiteLabelProgramUpdate } from '../model/support/white_label';
import { CompanyHierarchy, CompanyHierarchyUpdate } from '../model/support/company_hierarchy';
import { CompanyType } from '../model/support/company_type';
import { CompanyContact, CompanyContactInsert, CompanyContactUpdate } from '../model/support/company_contact';
import { ClientReports, ReportOutputFormat, ReportSpec, RuntimeReportSpec, StoredReport, SystemReport } from '../model/reports/report_spec';
import { StorageSpec } from '../model/storage/storage_spec';
import { Job } from '../model/jobs/job';
import { MemberBenefits } from '../model/phi/member-benefits';
import { ClientReportDescriptor, SystemReportDescriptor } from '../model/reports/descriptor';
import { HEMIFileDescriptor } from '../model/util/filedescriptor';
import { Email, EmailWrite } from '../model/email/email';
import { EncounterResult } from '../phi/phi-search/phi-search.component';
import { WhiteLabelType } from '../model/email/whitelabeltype';
import { EncounterFax, EncounterFaxWrite } from '../model/encounter/encounterfax';
import { Captive } from '../model/support/captive';
import { UserPresence } from '../model/util/user_presence';

@Injectable({
  providedIn: 'root'
})
export class TcemsService {

  constructor(private apiService: ApiService ) {}

  getPhi(phiId: number): Observable<Phi>
  {
    return from (this.apiService.makeAPIGetCall<Phi>
      (
        environment.tcemsAPIBase,
        environment.tcemsAPI_phi + "/" + phiId.toString(),
        {}
      ));
  }

  getEoB(memberId: string) : Observable<MemberBenefits>
  {
    return from (this.apiService.makeAPIGetCall<MemberBenefits>
      (
        environment.tcemsAPIBase,
        environment.tcemsAPI_edi + "/270/realtimerequest",
        {
          memberId: memberId
        }
      ));
  }

  searchForPhi(options:{firstName?: string, lastName?: string, phone?: string, email?: string, dob?: Date, query?: string})
  {
    return from (this.apiService.makeAPIGetCall<Phi[]>
      (
        environment.tcemsAPIBase,
        environment.tcemsAPI_phi + "/search",
        {
          ... options.firstName && {firstName: options.firstName},
          ... options.lastName && {lastName: options.lastName},
          ... options.phone && {phone: options.phone},
          ... options.email && {email: options.email},
          ... options.dob && {dob: formatDate(options.dob, "MM/dd/yyyy","en-us")},
          ... options.query && {q: options.query}
        }
      ));
  }

  searchForPlan(options:{query?: string})
  {
    return from (this.apiService.makeAPIGetCall<Plan[]>
      (
        environment.tcemsAPIBase,
        environment.tcemsAPI_phi + "/plan/search",
        {
          ... options.query && {q: options.query}
        }
      ));
  }

  getPlan(memberId: string) : Observable<Plan>
  {
    return from (this.apiService.makeAPIGetCall<Plan>
      (
        environment.tcemsAPIBase,
        environment.tcemsAPI_phi + "/plan/" + memberId,
        {}
      ));
  }

  getPlansForPhi(phiId: number): Observable<Plan[]>
  {
    return from (this.apiService.makeAPIGetCall<Plan[]>
      (
        environment.tcemsAPIBase,
        environment.tcemsAPI_phi + "/" + phiId.toString() + "/plans",
        {}
      ));
  }


  getAvailableEncounterStatuses(): Observable<EncounterStatus[]>
    {
      return from(this.apiService.makeAPIGetCall<EncounterStatus[]>
              (
                environment.tcemsAPIBase,
                environment.tcemsAPI_util + "/encounter_status",
                {}
              ));
    }

    getAvailableAppointmentStatuses(): Observable<AppointmentStatus[]>
    {
      return from(this.apiService.makeAPIGetCall<AppointmentStatus[]>
              (
                environment.tcemsAPIBase,
                environment.tcemsAPI_util + "/appointment_status",
                {}
              ));
    }

    getAvailableEncounterClosureReasons(): Observable<ClosureReason[]>
    {
      return from(this.apiService.makeAPIGetCall<ClosureReason[]>
              (
                environment.tcemsAPIBase,
                environment.tcemsAPI_util + "/closure_reason",
                {}
              ));
    }

    getAllSources(): Observable<Source[]>
    {
      return from(this.apiService.makeAPIGetCall<Source[]>
        (
          environment.tcemsAPIBase,
          environment.tcemsAPI_support + "/source",
          {}
        ));
    }

    getAllContactTypes(): Observable<ContactType[]>
    {
      return from(this.apiService.makeAPIGetCall<ContactType[]>
        (
          environment.tcemsAPIBase,
          environment.tcemsAPI_util + "/contact_types",
          {}
        ));
    }

    getAllCategories(): Observable<Category[]>
    {
      return from(this.apiService.makeAPIGetCall<Category[]>
        (
          environment.tcemsAPIBase,
          environment.tcemsAPI_claims + "/category",
          {}
        ));
    }

    getAllNetworks(): Observable<Network[]>
    {
      return from(this.apiService.makeAPIGetCall<Network[]>
        (
          environment.tcemsAPIBase,
          environment.tcemsAPI_support + "/network",
          {}
        ));
    }

    getAllNetworkGroups(): Observable<NetworkGroup[]>
    {
      return from(this.apiService.makeAPIGetCall<NetworkGroup[]>
        (
          environment.tcemsAPIBase,
          environment.tcemsAPI_support + "/network-group",
          {}
        ));
    }


    getAllEncounterTypes(): Observable<EncounterType[]>
    {
      return from(this.apiService.makeAPIGetCall<EncounterType[]>
        (
          environment.tcemsAPIBase,
          environment.tcemsAPI_util + "/encounter_types",
          {}
        ));
    }

    getAllCompanyTypes(): Observable<CompanyType[]>
    {
      return from(this.apiService.makeAPIGetCall<CompanyType[]>
        (
          environment.tcemsAPIBase,
          environment.tcemsAPI_util + "/company_types",
          {}
        ));
    }

    getAllNoteTypes(): Observable<NoteType[]>
    {
      return from(this.apiService.makeAPIGetCall<NoteType[]>
        (
          environment.tcemsAPIBase,
          environment.tcemsAPI_util + "/note_types",
          {}
        ));
    }

    getAllDiaryTypes(): Observable<DiaryType[]>
    {
      return from(this.apiService.makeAPIGetCall<DiaryType[]>
        (
          environment.tcemsAPIBase,
          environment.tcemsAPI_util + "/diary_types",
          {}
        ));
    }


    getAllPlanTypes(): Observable<PlanType[]>
    {
      return from(this.apiService.makeAPIGetCall<PlanType[]>
        (
          environment.tcemsAPIBase,
          environment.tcemsAPI_util +"/plan_types",
          {}
        ));
    }

    getAllTransactionTypes(): Observable<TransactionType[]>
    {
      return from(this.apiService.makeAPIGetCall<TransactionType[]>
        (
          environment.tcemsAPIBase,
          environment.tcemsAPI_util + "/transaction_types",
          {}
        ));
    }

    getDiariesAssignedToUser(options:
      {
        userId: number,
        dueAfter?: Date,
        dueBefore?: Date,
        page?: number,
        pageSize?: number,
        index?: number,
        records?: number
      }) : Observable<PagingResult<Diary>>
      {

        //create our page spec
        let ps: PagingSpec = new PagingSpec();
        ps.pageNumber = options.page;
        ps.pageSize = options.pageSize;
        ps.startIndex = options.index;
        ps.records = options.records;
        ps.sortSpec = new SortSpec();
        ps.sortSpec.sortFields = {"DiaryDueDate":new SortDetails(SortDetails.DIR_ASC,SortDetails.NOT_A_LIST)};

        return from (this.apiService.makeAPIPostCall<PagingResult<Diary>>
          (
            environment.tcemsAPIBase,
            environment.tcemsAPI_support + "/diaries/foruser/" + options.userId.toString() + "/assigned",
            ps,
            {
              status: "A",
              ... options.dueAfter && {dueFrom: formatDate(options.dueAfter, "MM/dd/yyyy","en-us")},
              ... options.dueBefore && {dueTo: formatDate(options.dueBefore, "MM/dd/yyyy","en-us")}
            }
          )
        );
      }

      getDiariesForEncounter(options:
        {
          dueAfter?: Date,
          dueBefore?: Date
          createdAfter?: Date,
          createdBefore?: Date,
          encounterId: number,
          statuses: string[],
          page?: number,
          pageSize?: number,
          index?: number,
          records?: number
        }) : Observable<PagingResult<Diary>>
        {

          //create our page spec
          let ps: PagingSpec = new PagingSpec();
          ps.pageNumber = options.page;
          ps.pageSize = options.pageSize;
          ps.startIndex = options.index;
          ps.records = options.records;
          ps.sortSpec = new SortSpec();
          ps.sortSpec.sortFields = {"DiaryDueDate":new SortDetails(SortDetails.DIR_DESC,SortDetails.NOT_A_LIST)};



          return from (this.apiService.makeAPIPostCall<PagingResult<Diary>>
            (
              environment.tcemsAPIBase,
              environment.tcemsAPI_support + "/diaries/relatedto/" + ReferenceCode.TYPE_ENCOUNTER.toString() + "/" + options.encounterId.toString(),
              ps,
              {
                ... options.createdAfter && {createdFrom: formatDate(options.createdAfter, "MM/dd/yyyy","en-us")},
                ... options.createdBefore && {createdBefore: formatDate(options.createdBefore, "MM/dd/yyyy","en-us")},
                ... options.dueAfter && {dueFrom: formatDate(options.dueAfter, "MM/dd/yyyy","en-us")},
                ... options.dueBefore && {dueTo: formatDate(options.dueBefore, "MM/dd/yyyy","en-us")},
                ... options.statuses && {status: options.statuses }
              }
            )
          );
        }

        getDiariesRelatedTo(options:
          {
            dueAfter?: Date,
            dueBefore?: Date,
            createdAfter?: Date,
            createdBefore?: Date,
            referenceType: number,
            referenceId: number,
            statuses: string[],
            page?: number,
            pageSize?: number,
            index?: number,
            records?: number
          }) : Observable<PagingResult<Diary>>
          {
  
            //create our page spec
            let ps: PagingSpec = new PagingSpec();
            ps.pageNumber = options.page;
            ps.pageSize = options.pageSize;
            ps.startIndex = options.index;
            ps.records = options.records;
            ps.sortSpec = new SortSpec();
            ps.sortSpec.sortFields = {"DiaryDueDate":new SortDetails(SortDetails.DIR_DESC,SortDetails.NOT_A_LIST)};

  
            return from (this.apiService.makeAPIPostCall<PagingResult<Diary>>
              (
                environment.tcemsAPIBase,
                environment.tcemsAPI_support + "/diaries/relatedto/" + options.referenceType?.toString() + "/" +  options.referenceId?.toString(),
                ps,
                {
                  ... options.createdAfter && {createdFrom: formatDate(options.createdAfter, "MM/dd/yyyy","en-us")},
                  ... options.createdBefore && {createdBefore: formatDate(options.createdBefore, "MM/dd/yyyy","en-us")},
                  ... options.dueAfter && {dueFrom: formatDate(options.dueAfter, "MM/dd/yyyy","en-us")},
                  ... options.dueBefore && {dueTo: formatDate(options.dueBefore, "MM/dd/yyyy","en-us")},
                  ... options.statuses && {status: options.statuses }
                }
              )
            );
          }



      createDiary(options:
        {
          referenceCode: number,
          relatedTo: number,
          diary: DiaryUpdate
        }): Observable<Diary>
        {
          return from(this.apiService.makeAPIPutCall<Diary>
            (
              environment.tcemsAPIBase,
              environment.tcemsAPI_support + "/diary/relatedto/" + options.referenceCode.toString() + "/" + options.relatedTo.toString(),
              options.diary,
              {}
            ));
        }

      assignDiary(options:
        {
          diaryId: number,
          assignedTo: User,
          diary: DiaryUpdate
        }) : Observable<Diary>
        {
          return from(this.apiService.makeAPIPatchCall<Diary>
            (
              environment.tcemsAPIBase,
              environment.tcemsAPI_support + "/diary/" + options.diaryId + "/assignTo/" + options.assignedTo.userId.toString(),
              options.diary,
              {}
            ));
        }

      closeDiary(options:
        {
          diaryId: number
        }) :Observable<Diary>
        {
          return from(this.apiService.makeAPIPostCall<Diary>
            (
              environment.tcemsAPIBase,
              environment.tcemsAPI_support + "/diary/" + options.diaryId + "/close",
              {},
              {}
            ))
        }

        getNotesForEncounter(options:
        {
          createdAfter?: Date,
          createdBefore?: Date,
          encounterId: number,
          page?: number,
          pageSize?: number,
          index?: number,
          records?: number
        }) : Observable<PagingResult<Note>>
        {

          //create our page spec
          let ps: PagingSpec = new PagingSpec();
          ps.pageNumber = options.page;
          ps.pageSize = options.pageSize;
          ps.startIndex = options.index;
          ps.records = options.records;
          ps.sortSpec = new SortSpec();
          ps.sortSpec.sortFields = {"NoteDate":new SortDetails(SortDetails.DIR_DESC,SortDetails.NOT_A_LIST)};


          return from (this.apiService.makeAPIPostCall<PagingResult<Note>>
            (
              environment.tcemsAPIBase,
              environment.tcemsAPI_support + "/notes/relatedto/" + ReferenceCode.TYPE_ENCOUNTER.toString() + "/" +  options.encounterId.toString(),
              ps,
              {
                ... options.createdAfter && {createdFrom: formatDate(options.createdAfter, "MM/dd/yyyy","en-us")},
                ... options.createdBefore && {createdBefore: formatDate(options.createdBefore, "MM/dd/yyyy","en-us")}
              }
            )
          );
        }

        getNotesRelatedTo(options:
          {
            createdAfter?: Date,
            createdBefore?: Date,
            referenceType: number,
            referenceId: number,
            page?: number,
            pageSize?: number,
            index?: number,
            records?: number
          }) : Observable<PagingResult<Note>>
          {
  
            //create our page spec
            let ps: PagingSpec = new PagingSpec();
            ps.pageNumber = options.page;
            ps.pageSize = options.pageSize;
            ps.startIndex = options.index;
            ps.records = options.records;
            ps.sortSpec = new SortSpec();
            ps.sortSpec.sortFields = {"NoteDate":new SortDetails(SortDetails.DIR_DESC,SortDetails.NOT_A_LIST)};

  
            return from (this.apiService.makeAPIPostCall<PagingResult<Note>>
              (
                environment.tcemsAPIBase,
                environment.tcemsAPI_support + "/notes/relatedto/" + options.referenceType.toString() + "/" +  options.referenceId.toString(),
                ps,
                {
                  ... options.createdAfter && {createdFrom: formatDate(options.createdAfter, "MM/dd/yyyy","en-us")},
                  ... options.createdBefore && {createdBefore: formatDate(options.createdBefore, "MM/dd/yyyy","en-us")}
                }
              )
            );
          }

        getNotesForUser(options:
          {
            createdAfter?: Date,
            createdBefore?: Date,
            userId: number,
            page?: number,
            pageSize?: number,
            index?: number,
            records?: number
          }) : Observable<PagingResult<Note>>
          {

            //create our page spec
            let ps: PagingSpec = new PagingSpec();
            ps.pageNumber = options.page;
            ps.pageSize = options.pageSize;
            ps.startIndex = options.index;
            ps.records = options.records;
            ps.sortSpec = new SortSpec();

            var result = (this.apiService.makeAPIPostCall<PagingResult<Note>>
              (
                environment.tcemsAPIBase,
                environment.tcemsAPI_support + "/notes/createdBy/" +  options.userId.toString(),
                ps,
                {
                  ... options.createdAfter && {createdFrom: formatDate(options.createdAfter, "MM/dd/yyyy","en-us")},
                  ... options.createdBefore && {createdBefore: formatDate(options.createdBefore, "MM/dd/yyyy","en-us")}
                }
              )
            );
            
            return from (this.apiService.makeAPIPostCall<PagingResult<Note>>
              (
                environment.tcemsAPIBase,
                environment.tcemsAPI_support + "/notes/createdBy/" +  options.userId.toString(),
                ps,
                {
                  ... options.createdAfter && {createdFrom: formatDate(options.createdAfter, "MM/dd/yyyy","en-us")},
                  ... options.createdBefore && {createdBefore: formatDate(options.createdBefore, "MM/dd/yyyy","en-us")}
                }
              )
            );
          }
          

          createEmail(
              encounterId: number,
              email: EmailWrite): Observable<void>
            {
              return from(this.apiService.makeAPIPostCall<void>
                (
                  environment.tcemsAPIBase,
                  environment.tcemsAPI_encounter + "/" + encounterId + "/email",
                  email,
                  {}
                ));
            }

            readEamil(
              encounterId: number,
              emailId: number
            ) : Observable<Email>
            {
              return from(this.apiService.makeAPIPostCall<Email>
                (
                  environment.tcemsAPIBase,
                  environment.tcemsAPI_encounter + "/" + encounterId + "/email/" + emailId.toString() + "/read",
                  {},
                  {}
                ));              
            }



            // return from (this.apiService.makeAPIPostCall<PagingResult<Note>>
            //   (
            //     environment.tcemsAPIBase,
            //     environment.tcemsAPI_support + "/notes/relatedto/" + ReferenceCode.TYPE_ENCOUNTER.toString() + "/" +  options.encounterId.toString(),
            //     ps,
            //     {

            // getEmailsForEncounter(options:
            //   {
            //     page: number,
            //     pageSize: number,
            //     encounterId: number,
            //   }) : Observable<PagingResult<Email>>
            //   {
            //     return from (this.apiService.makeAPIPostCall<PagingResult<Email>>
            //       (
                    // environment.tcemsAPIBase,
                    // environment.tcemsAPI_encounter + "/" + options.encounterId + "/emails",
                    // {},
                    // {}
            //       )
            //     );
            //   }
          
            getEmailsForEncounter(encounterId: number)
            {
              
              return from (this.apiService.makeAPIGetCall<Email[]>
                (
                  environment.tcemsAPIBase,
                  environment.tcemsAPI_encounter + "/" + encounterId + "/emails",
                  {},
                  {}
                )
              );
            }

            createNote(options:
            {
              referenceCode: number,
              relatedTo: number,
              note: NoteUpdate
            }): Observable<Note>
            {
              return from(this.apiService.makeAPIPutCall<Note>
                (
                  environment.tcemsAPIBase,
                  environment.tcemsAPI_support + "/note/relatedto/" + options.referenceCode.toString() + "/" + options.relatedTo.toString(),
                  options.note,
                  {}
                ));
            }

            editNote(options:
              {
                noteId: number,
                note: NoteUpdate
              }): Observable<Note>
              {
                return from(this.apiService.makeAPIPatchCall<Note>
                  (
                    environment.tcemsAPIBase,
                    environment.tcemsAPI_support + "/note/" + options.noteId.toString(),
                    options.note,
                    {}
                  ));
              }

              createAppointment(options:
                {
                  encounterId: number,
                  appt: AppointmentUpdate
                }): Observable<Appointment>
                {
                  return from(this.apiService.makeAPIPutCall<Appointment>
                    (
                      environment.tcemsAPIBase,
                      environment.tcemsAPI_encounter + "/" + options.encounterId + "/appointment/",
                      options.appt,
                      {}
                    ));
                }

                editAppointment(options:
                  {
                    encounterId: number,
                    appointmentId: number,
                    appt: AppointmentUpdate
                  }): Observable<Appointment>
                  {
                    return from(this.apiService.makeAPIPatchCall<Appointment>
                      (
                        environment.tcemsAPIBase,
                        environment.tcemsAPI_encounter + "/" + options.encounterId + "/appointment/" + options.appointmentId,
                        options.appt,
                        {}
                      ));
                  }


    searchForEncounters(options:
                      {startDate?: Date,
                        endDate?: Date,
                        patientMemberId?: string,
                        subscriberMemberId?: string,
                        encounterStatus?: number[],
                        clientId?: number [],
                        assignedTo?: number[],
                        encounterTypes?: number[],
                        categories?: number[],
                        includeUnassigned?: boolean,
                        encounterIds? : number[],
                        q?: string
                        } = {}) : Observable<EncounterSummary[]>
    {
      return from(this.apiService.makeAPIGetCall<EncounterSummary[]>
              (
                environment.tcemsAPIBase,
                environment.tcemsAPI_encounter + "/search",
                {
                  ... options.patientMemberId && {patientMemberId: options.patientMemberId},
                  ... options.subscriberMemberId && {subscriberMemberId: options.subscriberMemberId},
                  ... options.startDate && {startDate: formatDate(options.startDate,"MM/dd/yyyy","en-US")},
                  ... options.endDate && {endDate: formatDate(options.endDate,"MM/dd/yyyy","en-US")},
                  ... options.encounterStatus && {encounterStatus:  options.encounterStatus?.map(s => s.toString())},
                  ... options.clientId && { clientId: options.clientId?.map(c => c.toString())},
                  ... options.assignedTo && {assignedTo: options.assignedTo?.map(u => u.toString())},
                  ... options.encounterTypes && { encounterType: options.encounterTypes?.map(t => t.toString())},
                  ... options.categories && { category: options.categories?.map(c => c.toString())},
                  ... options.includeUnassigned && { includeUnassigned: options.includeUnassigned?.toString()},
                  ... options.encounterIds && { encounterId: options.encounterIds?.map(e => e.toString())},
                  ... options.q && {q: options.q}
                }
              ));
    }


    searchForEncountersPaged(options:
                      {startDate?: Date,
                        endDate?: Date,
                        patientMemberId?: string,
                        subscriberMemberId?: string,
                        encounterStatus?: number[],
                        clientId?: number [],
                        assignedTo?: number[],
                        encounterTypes?: number[],
                        categories?: number[],
                        includeUnassigned?: boolean,
                        includeAssigned?: boolean,
                        encounterIds? : number[],
                        includeRelatedRecords? : boolean,
                        includeAppointments?: boolean,
                        includeCostData?: boolean,
                        q?: string,
                        pageResults?: boolean,
                        pagingSpec?: PagingSpec,
                        employerNameContains?: string

                        } = {}) : Observable<PagingResult<Encounter>>
    {

      return (this.apiService.makeAPIPostCall<PagingResult<Encounter>>
              (
                environment.tcemsAPIBase,
                environment.tcemsAPI_encounter + "/search",
                options.pagingSpec,
                {
                  ... options.patientMemberId && {patientMemberId: options.patientMemberId},
                  ... options.subscriberMemberId && {subscriberMemberId: options.subscriberMemberId},
                  ... options.startDate && {startDate: formatDate(options.startDate,"MM/dd/yyyy","en-US")},
                  ... options.endDate && {endDate: formatDate(options.endDate,"MM/dd/yyyy","en-US")},
                  ... options.encounterStatus && {encounterStatus:  options.encounterStatus?.map(s => s.toString())},
                  ... options.clientId && { clientId: options.clientId?.map(c => c.toString())},
                  ... options.assignedTo && {assignedTo: options.assignedTo?.map(u => u.toString())},
                  ... options.encounterTypes && { encounterType: options.encounterTypes?.map(t => t.toString())},
                  ... options.categories && { category: options.categories?.map(c => c.toString())},
                  ... options.includeUnassigned != null && { includeUnassigned: options.includeUnassigned?.toString()},
                  ... options.includeAssigned != null && { includeAssigned: options.includeAssigned?.toString()},
                  ... options.includeRelatedRecords != null && { includeRelatedRecords: options.includeRelatedRecords?.toString()},
                  ... options.includeAppointments != null && { includeAppointments: options.includeAppointments?.toString()},
                  ... options.includeCostData != null && { includeCostData: options.includeCostData?.toString()},
                  ... options.encounterIds && { encounterId: options.encounterIds?.map(e => e.toString())},
                  ... options.pageResults != null && { pageResults: options.pageResults?.toString()},
                  ... options.employerNameContains != null && { employerNameContains: options.employerNameContains},
                  ... options.q && {q: options.q}
                }
              ));
    }


    getEncounter(encounterId: number) : Observable<Encounter>
    {
      return from(this.apiService.makeAPIGetCall<Encounter>
        (
          environment.tcemsAPIBase,
          environment.tcemsAPI_encounter + "/" + encounterId.toString(),
          {}
        ));
    }

    getUserEncounters(userId: number,summaryView: boolean = false) : Observable<UserEncounter[]>
    {
      return from(this.apiService.makeAPIGetCall<UserEncounter[]>
        (
          environment.tcemsAPIBase,
          environment.tcemsAPI_encounter + "/userencounters/user/" + userId.toString(),
          {
            ... summaryView && {summaryView: summaryView.toString()},
          }
        ));
    }

    getEncounterGroupingsForUser(options:{userId: number, pagingSpec: PagingSpec, includeClosed: boolean}) : Observable<PagingResult<EncounterGroup>>
    {
      return from(this.apiService.makeAPIPostCall<PagingResult<EncounterGroup>>
      (
        environment.tcemsAPIBase,
        environment.tcemsAPI_encounter + "/encountergroups/user/" + options.userId,
        options.pagingSpec,
        {includeClosed: options.includeClosed.toString()}
      ));
    }

    getEncounterGroupingsForPhi(phiId: number) : Observable<PagingResult<EncounterGroup>>
    {
      return from(this.apiService.makeAPIPostCall<PagingResult<EncounterGroup>>
      (
        environment.tcemsAPIBase,
        environment.tcemsAPI_encounter + "/encountergroups/phi/" + phiId,
        {},
        {}
      ));
    }

    getEncounterPatient(encounterId: number) : Observable<Phi>
    {
      return from(this.apiService.makeAPIGetCall<Phi>
        (
          environment.tcemsAPIBase,
          environment.tcemsAPI_encounter + "/" + encounterId.toString() + "/patient",
          {}
        ));
    }


    getUser(userId: number) :Observable<User>
    {
      return from(this.apiService.makeAPIGetCall<User>
        (
          environment.tcemsAPIBase,
          environment.tcemsAPI_util + "/user/" + userId.toString(),
          {}
        ));
    }

    getAllUserPresece() : Observable<UserPresence[]>
    {
      return from(this.apiService.makeAPIGetCall<UserPresence[]>
        (
          environment.tcemsAPIBase,
          environment.tcemsAPI_util + "/users/presence",
          {}
        ));
    }

    getActiveUsers():Observable<User[]>
    {
      return from(this.apiService.makeAPIGetCall<User[]>
        (
          environment.tcemsAPIBase,
          environment.tcemsAPI_util + "/users/find",
          {
            active: 'true'
          }
        )

        )
    }

    getUserState(userId: number) : Observable<UserState>
    {
      return from(this.apiService.makeAPIGetCall<UserState>
        (
          environment.tcemsAPIBase,
          environment.tcemsAPI_util + "/userstate/" + userId.toString(),
          {}
        ));
    }

    getMemberEncounter(phiId: number,summaryView: boolean = false) : Observable<UserEncounter[]>
    {
      return from(this.apiService.makeAPIGetCall<UserEncounter[]>
        (
          environment.tcemsAPIBase,
          environment.tcemsAPI_encounter + "/userencounters/phi/" + phiId,
          {
            ... summaryView && {summaryView: summaryView.toString()},
          }
        ));
    }

    getInstanceOfCare(instanceId: number): Observable<InstanceOfCare>
    {
      return from(this.apiService.makeAPIGetCall<InstanceOfCare>(
        environment.tcemsAPIBase,

        environment.tcemsAPI_claims + "/instance/" + instanceId.toString(),
        {}
      ));
    }

    getLine(lineId: number): Observable<Line>
    {
      return from(this.apiService.makeAPIGetCall<Line>(
        environment.tcemsAPIBase,

        environment.tcemsAPI_claims + "/line/" + lineId.toString(),
        {}
      ));
    }

    getCodes(codeset: string, fromCode?:string): Observable<ProcedureHierarchy>
    {
      return from(this.apiService.makeAPIGetCall<ProcedureHierarchy>(
        environment.tcemsAPIBase,
        environment.tcemsAPI_claims + "/procedures/hierarchy/" + codeset,
        {
          ... fromCode && {fromCode: fromCode}
        }
      ));
    }

    mrfLookupByZipRadius(zipCode: string, radius: number, network: number, procedureCodes: string[], ein:string = null)
    {
      return from(this.apiService.makeAPIGetCall<MRFPracticeResult[]>(
        environment.tcemsAPIBase,
        environment.tcemsAPI_claims + "/lookup/mrf/zip/" + zipCode,
        {
          ... radius && {radius: radius.toString()},
          ... procedureCodes && { procedureCodes: procedureCodes},
          ... network && { network: network.toString()},
          ... ein && {ein:ein}
        }
      ));      
    }


    lookupProceduresInZip(zipCode: string, radius: number, source: number, procedureCodes: string [], network: number, category: number, lookbackDays: number = 365, ein: string = null, npi: string = null)
    {
      return from(this.apiService.makeAPIGetCall<ProcedureLookupResult[]>(
        environment.tcemsAPIBase,
        environment.tcemsAPI_claims + "/lookup/zip/" + zipCode,
        {
          ... radius && {radius: radius.toString()},
          ... source && {source: source.toString()},
          ... procedureCodes && { procedureCodes: procedureCodes},
          ... network && { network: network.toString()},
          //... networkGroup && { networkGroup: networkGroup.toString()},
          ... category && { category: category.toString()},
          ... lookbackDays && {lookbackDays: lookbackDays.toString()},
          ... ein && {ein:ein},
          ... npi && {npi:npi}
        }
      ));
    }

    searchForMatches(options:
      {
        startDate?: Date,
        endDate?: Date,
        encounterStatus?: number[],
        clientId? : number[],
        sourceId? : number[],
        includeMatched? : boolean
      })
      {
        return from(this.apiService.makeAPIGetCall<MatchSummary[]>
                (
                  environment.tcemsAPIBase,
                  environment.tcemsAPI_claims + "/match/search",
                  {
                    ... options.startDate && {start: formatDate(options.startDate,"MM/dd/yyyy","en-US")},
                    ... options.endDate && {end: formatDate(options.endDate,"MM/dd/yyyy","en-US")},
                    ... options.encounterStatus && {encounterStatus:  options.encounterStatus?.map(s => s.toString())},
                    ... options.clientId && { clientId: options.clientId?.map(c => c.toString())},
                    ... options.sourceId && { sourceId: options.sourceId?.map(s => s.toString())},
                    ... options.includeMatched && { includeMatched: options.includeMatched.toString()}
                  }
                ));
      }

    //Provider Methods
    getPractice(ein:string) : Observable<Practice>
    {
      return from(this.apiService.makeAPIGetCall<Practice>
        (
          environment.tcemsAPIBase,
          environment.tcemsAPI_provider + "/practice/" + ein,
          {}
        ));
    }

    searchPractices
    (options: {
      name?:string,
      zipCode?: string,
      ein?: string,
      searchAliases?: boolean,
      q?: string
    })
    {
      return from(this.apiService.makeAPIPostCall<PagingResult<Practice>>
        (
          environment.tcemsAPIBase,
          environment.tcemsAPI_provider + "/practice/search",
          {},
          {
            ... options.name && {name: options.name},
            ... options.zipCode && {zipCode: options.zipCode},
            ... options.ein && {ein: options.ein},
            ... options.searchAliases && {searchAliases: options.searchAliases.valueOf.toString()},
            ... options.q && {q: options.q}
          }
        ))
    }

    searchProviders
    (options: {q?: string})
    {
      return from(this.apiService.makeAPIPostCall<PagingResult<Provider>>
        (
          environment.tcemsAPIBase,
          environment.tcemsAPI_provider + "/search",
          {},
          {
            ... options.q && {q: options.q}
          }
        ))
    }

    getProvidersForPractice
    (options: {
      ein: string,
      pageResults: boolean,
      pagingSpec: PagingSpec
    })
    {
      return from(this.apiService.makeAPIPostCall<PagingResult<Provider>>
        (
          environment.tcemsAPIBase,
          environment.tcemsAPI_provider + "/practice/" + options.ein + "/providers",
          options.pagingSpec,
          {
            ... options.pageResults && {pageResults: options.pageResults.valueOf.toString()}
          }

        ))
    }

    getProviderLocationsForPractice
    (options: {
      ein: string,
      pageResults: boolean,
      pagingSpec: PagingSpec
    })
    {
      return from(this.apiService.makeAPIPostCall<PagingResult<ProviderLocation>>
        (
          environment.tcemsAPIBase,
          environment.tcemsAPI_provider + "/practice/" + options.ein + "/providerlocations",
          options.pagingSpec,
          {
            ... options.pageResults && {pageResults: options.pageResults.valueOf.toString()}
          }

        ))
    }

    getProviderLocationsForProvider
    (options: {
      npi: string,
      pageResults: boolean,
      pagingSpec: PagingSpec
    })
    {
      return from(this.apiService.makeAPIPostCall<PagingResult<ProviderLocation>>
        (
          environment.tcemsAPIBase,
          environment.tcemsAPI_provider + "/" + options.npi + "/providerlocations",
          options.pagingSpec,
          {
            ... options.pageResults && {pageResults: options.pageResults.valueOf.toString()}
          }

        ))
    }


    addPractice
    (
      options:
      {
        practice:PracticeUpdate
      }
    )
    {
      return from (this.apiService.makeAPIPutCall<Practice>
      (
        environment.tcemsAPIBase,
        environment.tcemsAPI_provider + "/practice",
        options.practice,
        {}
      ));

    }

    updatePractice
    (
      options:
      {
        practice:PracticeUpdate
      }
    )
    {
      return from (this.apiService.makeAPIPatchCall<Practice>
      (
        environment.tcemsAPIBase,
        environment.tcemsAPI_provider + "/practice/"+options.practice.ein,
        options.practice,
        {}
      ));

    }

    updateProviderLocation
    (
      options:
      {
        ein: string,
        providerLocationId: number,
        providerLocation:ProviderLocationUpdate
      }
    )
    {
      return from (this.apiService.makeAPIPatchCall<ProviderLocation>
      (
        environment.tcemsAPIBase,
        environment.tcemsAPI_provider + "/practice/"+options.ein+"/providerlocation/"+options.providerLocationId.toString(),
        options.providerLocation,
        {

        }
      ));

    }

    //Finance Methods
    getTransaction(transactionId: number) : Observable<Transaction>
    {
      return from(this.apiService.makeAPIGetCall<Transaction>
        (
          environment.tcemsAPIBase,
          environment.tcemsAPI_finance + "/transaction/" + transactionId.toString(),
          {}
        ));
    }

    getCheckingAccount(accountId:number) : Observable<CheckingAccount>
    {
      return from(this.apiService.makeAPIGetCall<CheckingAccount>
        (
          environment.tcemsAPIBase,
          environment.tcemsAPI_finance+"/checkingaccount/" + accountId.toString(),
          {}
      ));
    }

    getCheckingAccounts() :Observable<CheckingAccount[]>
    {
      return from(this.apiService.makeAPIGetCall<CheckingAccount[]>
        (
          environment.tcemsAPIBase,
          environment.tcemsAPI_finance+"/checkingaccounts",
          {}
        ));
    }

    searchTransactions
    (options:
      {
        createDateStart?: Date,
        createDateEnd?: Date,
        approvedDateStart?: Date,
        approvedDateEnd?: Date,
        voidDateStart?:Date,
        voidDateEnd?: Date,
        minAmount?: number,
        maxAmount?: number,
        includeOpen?: boolean,
        includeApproved?: boolean,
        includeReissue?: boolean,
        includeInvoiced?: boolean,
        includePaidByClient?: boolean,
        encounters?: number[],
        phis?: number[]
      } = {}
    )
    {
      return from(this.apiService.makeAPIGetCall<Transaction[]>
        (
          environment.tcemsAPIBase,
          environment.tcemsAPI_finance + "/transactions/search",
          {
            ... options.createDateStart && {createDateStart: formatDate(options.createDateStart,"MM/dd/yyyy","en-US")},
            ... options.createDateEnd && {createDateEnd: formatDate(options.createDateEnd,"MM/dd/yyyy","en-US")},
            ... options.approvedDateStart && {approvedDateStart: formatDate(options.approvedDateStart,"MM/dd/yyyy","en-US")},
            ... options.approvedDateEnd && {approvedDateEnd: formatDate(options.approvedDateEnd,"MM/dd/yyyy","en-US")},
            ... options.voidDateStart && {voidDateStart: formatDate(options.voidDateStart,"MM/dd/yyyy","en-US")},
            ... options.voidDateEnd && {voidDateEnd: formatDate(options.voidDateEnd,"MM/dd/yyyy","en-US")},
            ... options.minAmount && {minAmount: options.minAmount?.toString()},
            ... options.maxAmount && {maxAmount: options.maxAmount?.toString()},
            ... options.includeOpen != null && {includeOpen: options.includeOpen?.toString()},
            ... options.includeApproved != null && {includeApproved: options.includeApproved?.toString()},
            ... options.includeReissue != null && {includeReissue: options.includeReissue?.toString()},
            ... options.includeInvoiced != null && {includeInvoiced: options.includeInvoiced?.toString()},
            ... options.includePaidByClient!= null && {includePaidByClient: options.includePaidByClient?.toString()},
            ... options.encounters && { encounter: options.encounters?.map(e => e.toString())},
            ... options.phis && { phi: options.phis?.map(p => p.toString())}
          }
        ));
    }

    searchInvoices
    (
      options: 
      {
        invoiceDateStart?: Date
        ,invoiceDateEnd?: Date
        ,invoiceId?: number[]
        ,companyId?: number[]
        ,closed?: boolean
        ,open?: boolean
      }
    )
    {
      return from(this.apiService.makeAPIGetCall<Invoice[]>
        (
          environment.tcemsAPIBase,
          environment.tcemsAPI_finance + "/invoices/search",
          {
            ... options.invoiceDateStart && {invoiceDateStart: formatDate(options.invoiceDateStart,"MM/dd/yyyy","en-US")},
            ... options.invoiceDateEnd && {invoiceDateEnd: formatDate(options.invoiceDateEnd,"MM/dd/yyyy","en-US")},
            ... options.invoiceId && {invoiceId: options.invoiceId?.map(i => i.toString())},
            ... options.companyId && {companyId: options.companyId?.map(c => c.toString())},
            ... options.closed != null && {closed: options.closed?.toString()},
            ... options.open != null && {open: options.open?.toString()}
          }
        ));

    }

    getInvoice(invoiceId : number)
    {
      return from(this.apiService.makeAPIGetCall<Invoice>
        (
          environment.tcemsAPIBase,
          environment.tcemsAPI_finance + "/invoice/" + invoiceId.toString(),
          {}
        ));
    }

    getInvoicePDF(invoiceId: number)
    {
      return from(this.apiService.downloadFileFromGet
        (
          environment.tcemsAPIBase,
          environment.tcemsAPI_finance + "/invoice/" + invoiceId.toString() + "/print/pdf",
          {}
        ))
        .toPromise().then
        (
          response =>
            new Blob([response], { type: 'application/pdf' })
        );
    }

    createInvoice(invoice: InvoiceInsert)
    {
      return from(this.apiService.makeAPIPutCall<Invoice>
        (
          environment.tcemsAPIBase,
          environment.tcemsAPI_finance + "/invoice/",
          invoice,
          {}
        ));
    }

    deleteInvoice(invoiceId:number)
    {
      return from(this.apiService.makeAPIDeleteCall<Invoice>
        (
          environment.tcemsAPIBase,
          environment.tcemsAPI_finance + "/invoice/" + invoiceId.toString(),
          {}
        ))
    }
    

    updateInvoice(invoiceId: number, invoice: InvoiceUpdate)
    {
      return from(this.apiService.makeAPIPatchCall<Invoice>
        (
          environment.tcemsAPIBase,
          environment.tcemsAPI_finance + "/invoice/" + invoiceId.toString(),
          invoice,
          {}
        ));
    }

    getInvoiceRule(ruleId: number)
    {
      return from(this.apiService.makeAPIGetCall<InvoiceRule>
        (
          environment.tcemsAPIBase,
          environment.tcemsAPI_finance + "/invoicerule/" + ruleId.toString(),
          {}
        ));
    }

    deleteInvoiceRule(ruleId: number)
    {
      return from(this.apiService.makeAPIDeleteCall<InvoiceRule>
        (
          environment.tcemsAPIBase,
          environment.tcemsAPI_finance + "/invoicerule/" + ruleId.toString(),
          {}
        ));
    }

    getInvoiceRules
    (
      options:
      {
        companyId?: number[], 
        active?: boolean, 
        inactive?: boolean
      }
    )
    {
      return from(this.apiService.makeAPIGetCall<InvoiceRule[]>
        (
          environment.tcemsAPIBase,
          environment.tcemsAPI_finance + "/invoicerule/search",
          {
            ... options.companyId && {companyId: options.companyId?.map(c => c.toString())},
            ... options.active != null && {closed: options.active?.toString()},
            ... options.inactive != null && {open: options.inactive?.toString()}
          }
        ));
    }

    createInvoiceRule(invoiceRule: InvoiceRuleInsert)
    {
      return from(this.apiService.makeAPIPutCall<InvoiceRule>
        (
          environment.tcemsAPIBase,
          environment.tcemsAPI_finance + "/invoicerule/",
          invoiceRule,
          {}
        ));
    }

    createRewardRule(invoiceRule: RewardRuleInsert)
    {
      return from(this.apiService.makeAPIPutCall<RewardRule>
        (
          environment.tcemsAPIBase,
          environment.tcemsAPI_finance + "/rewardrule/",
          invoiceRule,
          {}
        ));
    }

    updateInvoiceRule(ruleId: number, rule:InvoiceRuleUpdate)
    {
      return from(this.apiService.makeAPIPatchCall<RewardRule>
        (
          environment.tcemsAPIBase,
          environment.tcemsAPI_finance + "/invoicerule/" + ruleId.toString(),
          rule,
          {}
        ));
    }

    updateRewardRule(ruleId: number, rule:RewardRuleUpdate)
    {
      return from(this.apiService.makeAPIPatchCall<RewardRule>
        (
          environment.tcemsAPIBase,
          environment.tcemsAPI_finance + "/rewardrule/" + ruleId.toString(),
          rule,
          {}
        ));
    }

    deleteRewardRule(ruleId: number)
    {
      return from(this.apiService.makeAPIDeleteCall<RewardRule>
        (
          environment.tcemsAPIBase,
          environment.tcemsAPI_finance + "/rewardrule/" + ruleId.toString(),
          {}
        ));
    }



    //Update Methods
    confirmEncounter(options:
      {
        encounterId: number,
        confirmedInstance: ConfirmedInstance
      })
      {
        return from(this.apiService.makeAPIPostCall<ConfirmedEncounter>
          (
              environment.tcemsAPIBase,
              environment.tcemsAPI_encounter + "/" + options.encounterId.toString() + "/confirm",
              options.confirmedInstance,
              {}
          ));
      }

  updatePhi(options:
              {
                phiId: number,
                phiUpdate: PhiUpdate
              }
  ): Observable<Phi>
  {
    return from(this.apiService.makeAPIPatchCall<Phi>
    (
      environment.tcemsAPIBase,
      environment.tcemsAPI_phi + '/' + options.phiId.toString(),
      options.phiUpdate,
      {}
    ));
  }

  updatePhiCompany(options:
    {
      phiId: number,
      companyId: number
    }
  ): Observable<Phi>
  {
  return from(this.apiService.makeAPIPatchCall<Phi>
  (
  environment.tcemsAPIBase,
  environment.tcemsAPI_phi + '/' + options.phiId.toString() + "/company/" + options.companyId.toString(),
  {},
  {}
  ));
  }


  updatePlan(options:
    {
      phiId: number,
      memberId: string,
      planUpdate: PlanUpdate
    }
  ): Observable<Plan>
  {
  return from(this.apiService.makeAPIPatchCall<Plan>
  (
  environment.tcemsAPIBase,
  environment.tcemsAPI_phi + '/' + options.phiId.toString() + "/plan/" + options.memberId,
  options.planUpdate,
  {}
  ));
  }





  updatePhoneNumber(options:
                      {
                        phiId: number,
                        phoneId: number,
                        phoneNumberUpdate: PhoneNumberUpdate
                      }
  ): Observable<PhoneNumber>
  {
    return from(this.apiService.makeAPIPatchCall<PhoneNumber>
    (
      environment.tcemsAPIBase,
      environment.tcemsAPI_phi + '/' + options.phiId.toString() + '/phonenumber/' + options.phoneId.toString(),
      options.phoneNumberUpdate,
      {}
    ));
  }

  createPhoneNumber(options:
                      {
                        phiId: number,
                        phoneNumberUpdate: PhoneNumberUpdate
                      }
  ): Observable<PhoneNumber>
  {
    return from(this.apiService.makeAPIPutCall<PhoneNumber>
    (
      environment.tcemsAPIBase,
      environment.tcemsAPI_phi + '/' + options.phiId.toString() + '/phonenumber/',
      options.phoneNumberUpdate,
      {}
    ));
  }

  updateEmailAddress(options:
                       {
                         phiId: number,
                         emailId: number,
                         emailAddressUpdate: EmailAddressUpdate
                       }
  ): Observable<EmailAddress>
  {
    return from(this.apiService.makeAPIPatchCall<EmailAddress>
    (
      environment.tcemsAPIBase,
      environment.tcemsAPI_phi + '/' + options.phiId.toString() + '/emailaddress/' + options.emailId.toString(),
      options.emailAddressUpdate,
      {}
    ));
  }

  createEmailAddress(options:
                       {
                         phiId: number,
                         emailAddressUpdate: EmailAddressUpdate
                       }
  ): Observable<EmailAddress>
  {
    return from(this.apiService.makeAPIPutCall<EmailAddress>
    (
      environment.tcemsAPIBase,
      environment.tcemsAPI_phi + '/' + options.phiId.toString() + '/emailaddress/',
      options.emailAddressUpdate,
      {}
    ));
  }

  updateAddress(options:
                       {
                         phiId: number,
                         addressId: number,
                         addressUpdate: AddressUpdate
                       }
  ): Observable<Address>
  {
    return from(this.apiService.makeAPIPatchCall<Address>
    (
      environment.tcemsAPIBase,
      environment.tcemsAPI_phi + '/' + options.phiId.toString() + '/address/' + options.addressId.toString(),
      options.addressUpdate,
      {}
    ));
  }

  createAddress(options:
                       {
                         phiId: number,
                         addressUpdate: AddressUpdate
                       }
  ): Observable<Address>
  {
    return from(this.apiService.makeAPIPutCall<Address>
    (
      environment.tcemsAPIBase,
      environment.tcemsAPI_phi + '/' + options.phiId.toString() + '/address/',
      options.addressUpdate,
      {}
    ));
  }

  getRewardRulesForCompany(companyId: number)
  {
    return from(this.apiService.makeAPIGetCall<RewardRule[]>
      (
        environment.tcemsAPIBase,
        environment.tcemsAPI_support + '/company/' + companyId.toString() + '/rewardrules',
        {}
      ));
  }

  getValidEncounterRewardRules(encounterId: number)
  {
    return from(this.apiService.makeAPIGetCall<RewardRule[]>
      (
        environment.tcemsAPIBase,
        environment.tcemsAPI_encounter + '/' + encounterId.toString() + '/validrewardrules',
        {}
      ));
  }

  recalcRewardRuleForEncounter(encounterId: number)
  {
    return from(this.apiService.makeAPIPostCall<Encounter>
      (
        environment.tcemsAPIBase,
        environment.tcemsAPI_encounter + '/' + encounterId.toString() + '/recalculaterewardrule',
        {}
      ));
  }
  
  recalcRewardForEncounter(encounterId: number, ruleId: number)
  {
    return from(this.apiService.makeAPIPostCall<Encounter>
      (
        environment.tcemsAPIBase,
        environment.tcemsAPI_encounter + '/' + encounterId.toString() + '/recalculatereward/' + ruleId.toString(),
        {}
      ));
  }

  removeRewardForEncounter(encounterId: number)
  {
    return from(this.apiService.makeAPIPostCall<Encounter>
      (
        environment.tcemsAPIBase,
        environment.tcemsAPI_encounter + '/' + encounterId.toString() + '/recalculatereward/none',
        {}
      ));
  }


  updateEncounter(options:
  {
  encounterId: number,
  encounter: Encounter
  })
  {
  return from(this.apiService.makeAPIPatchCall<Encounter>
    (
        environment.tcemsAPIBase,
        environment.tcemsAPI_encounter + "/" + options.encounterId.toString(),
        Encounter.generateUpdateVersion(options.encounter),
        {}
    ));
  }

  updateEncounterRaw(options:
  {
    encounterId: number,
    encounter: EncounterUpdate
  })
  {
    return from(this.apiService.makeAPIPatchCall<Encounter>
      (
          environment.tcemsAPIBase,
          environment.tcemsAPI_encounter + "/" + options.encounterId.toString(),
          options.encounter,
          {}
      ));
  }


  updateEncounterProvider(options:
  {
    encounterId: number,
    encounterProviderId: number;
    encounterProvider: EncounterProvider
  })
  {
    return from(this.apiService.makeAPIPatchCall<EncounterProvider>
      (
          environment.tcemsAPIBase,
          environment.tcemsAPI_encounter + "/" + options.encounterId.toString() + "/encounterProvider/" + options.encounterProviderId,
          EncounterProvider.generateUpdateVersion(options.encounterProvider),
          {}
      ));
  }

  addEncounterProvider(options:
    {
      encounterId: number,
      encounterProvider: EncounterProviderUpdate
    })
    {
      return from(this.apiService.makeAPIPutCall<EncounterProvider>
        (
          environment.tcemsAPIBase,
          environment.tcemsAPI_encounter + "/" + options.encounterId.toString() + "/encounterprovider",
          options.encounterProvider,
          {}
        ))
    }

  getEncounterProvider(options:
    {
      encounterId: number,
      encounterProviderId: number
    })
    {
      return from(this.apiService.makeAPIGetCall<EncounterProvider>
        (
          environment.tcemsAPIBase,
          environment.tcemsAPI_encounter + "/" + options.encounterId.toString() + "/encounterprovider/" + options.encounterProviderId,
          {}
        ))
    }

    deleteEncounterProvider(options: {encounterId: number, providerId: number})
    {
      return from(this.apiService.makeAPIDeleteCall<void>
        (
          environment.tcemsAPIBase,
          environment.tcemsAPI_encounter + "/" + options.encounterId.toString() + "/encounterprovider/" + options.providerId.toString(),
          {
            ... {deleteModelProcs:"true"}
          }
        ));
    }

    addModelProcedure(options:
      {
        encounterId: number,
        encounterProviderId: number,
        modelProc: EncounterModelProcedureUpdate
      })
      {
        return from(this.apiService.makeAPIPutCall<EncounterModelProcedure>
          (
            environment.tcemsAPIBase,
            environment.tcemsAPI_encounter + "/" + options.encounterId.toString() + "/encounterprovider/" + options.encounterProviderId.toString() + "/encounterModelProcedure",
            options.modelProc,
            {}
          ))
      }

  updateUserState(options:
  {
    userId: number,
    state: UserState,
    clearActiveTask: boolean 
  })
  {
    return from(this.apiService.makeAPIPatchCall<UserState>
      (
        environment.tcemsAPIBase,
        environment.tcemsAPI_util + "/userstate/" + options.userId.toString(),
        options.state,
        {... options.clearActiveTask && {clearActiveTask: options.clearActiveTask.toString()}}
      ));
  }


  createTransaction(options:
    {
      transaction: Transaction
    })
    {
      return from(this.apiService.makeAPIPutCall<Transaction>
        (
            environment.tcemsAPIBase,
            environment.tcemsAPI_finance + "/transaction",
            Transaction.generateUpdateVersion(options.transaction),
            {}
        ));
    }
  updateTransaction(options:
    {
      transactionId: number,
      transaction: Transaction
    })
    {
      return from(this.apiService.makeAPIPatchCall<Transaction>
        (
          environment.tcemsAPIBase,
          environment.tcemsAPI_finance + "/" + options.transactionId,
          Transaction.generateUpdateVersion(options.transaction),
          {}
        ))
    }

  postChecks(options:
    {
      postings: CheckPosting[]
    })
  {
    return from(this.apiService.makeAPIPostCall<Check>
      (
        environment.tcemsAPIBase,
        environment.tcemsAPI_finance + "/checks",
        options.postings,
        {}
      ));
  }

  voidAndReissueCheck(c:Check, newAccount:CheckingAccount, nextCheck: string)
  {
    return from(this.apiService.makeAPIPostCall<Check>
      (
        environment.tcemsAPIBase,
        environment.tcemsAPI_finance + "/checkbook/" + c.checkingAccount.accountId.toString() + "/checks/" + c.checkId + "/void/reissue",
        {},
        {
          ... newAccount.accountId && {newAccountNumber: newAccount.accountId.toString()}
          , ... nextCheck && {newCheckNumber: nextCheck}
        }
      ))
  }

  createNewEncounterForPhi(phiId: number, encounterTypeId: number = 1, encounterStatusId: number = 1)
  {
    return from(this.apiService.makeAPIPostCall<Encounter>
      (
        environment.tcemsAPIBase,
        environment.tcemsAPI_encounter + "/newfor/phi/" + phiId.toString(),
        {},
        {
          encounterTypeId: encounterTypeId.toString(), 
          encounterStatusId: encounterStatusId.toString()
        }
      ))
  }

  addEncounterProcedure(options: {encounterId: number, proc: EncounterProcedureUpdate})
  {
    return from(this.apiService.makeAPIPutCall<EncounterProcedure>
      (
        environment.tcemsAPIBase,
        environment.tcemsAPI_encounter + "/" + options.encounterId.toString() + "/procedures",
        options.proc,
        {}
      ));
  }

  deleteEncounterProcedure(options: {encounterId: number, procId: number})
  {
    return from(this.apiService.makeAPIDeleteCall<void>
      (
        environment.tcemsAPIBase,
        environment.tcemsAPI_encounter + "/" + options.encounterId.toString() + "/procedures/" + options.procId.toString(),
        {}
      ));
  }


  updateEncounterProcedure(options: {encounterId: number, procId: number, proc: EncounterProcedureUpdate})
  {
    return from(this.apiService.makeAPIPatchCall<EncounterProcedure>
      (
        environment.tcemsAPIBase,
        environment.tcemsAPI_encounter + "/" + options.encounterId.toString() + "/procedures/" + options.procId.toString(),
        options.proc,
        {}
      ));
  }

  searchCompanies(q: string)
  {
    return from(this.apiService.makeAPIGetCall<Company[]>
      (
        environment.tcemsAPIBase,
        environment.tcemsAPI_support + "/company/search",
        {q:q}
      ));
  }

  getRootCompanies()
  {
    return from(this.apiService.makeAPIGetCall<Company[]>
      (
        environment.tcemsAPIBase,
        environment.tcemsAPI_support + "/company/list/root",
        {}
      ));
  }

  getChildCompanies(parentCompanyId: number)
  {
    return from(this.apiService.makeAPIGetCall<Company[]>
      (
        environment.tcemsAPIBase,
        environment.tcemsAPI_support + "/company/list/" + parentCompanyId.toString(),
        {}
      ));
  }

  addRootCompany(ci: CompanyInsert)
  {
    return from(this.apiService.makeAPIPutCall<Company>
      (
        environment.tcemsAPIBase,
        environment.tcemsAPI_support + "/company",
        ci,
        {}
      ));
  }

  addChildCompany(parentCompanyId: number, ci: CompanyInsert)
  {
    return from(this.apiService.makeAPIPutCall<Company>
      (
        environment.tcemsAPIBase,
        environment.tcemsAPI_support + "/company/" + parentCompanyId,
        ci,
        {}
      ));
  }

  updateCompany(id: number, cu: CompanyUpdate)
  {
    return from(this.apiService.makeAPIPatchCall<Company>
      (
        environment.tcemsAPIBase,
        environment.tcemsAPI_support + "/company/" + id,
        cu,
        {}
      ));
  }

  addCompanyContact(companyId:number, cci:CompanyContactInsert)
  {
    //Ensure we've got a company id
    if(cci.companyId == null) cci.companyId = companyId;
    return from(this.apiService.makeAPIPutCall<CompanyContact>
      (
        environment.tcemsAPIBase,
        environment.tcemsAPI_support + "/company/" + companyId + "/companycontact/",
        cci,
        {}
      ));
    
  }


  updateCompanyContact(companyId:number, companyContactId:number, ccu:CompanyContactUpdate)
  {
    return from(this.apiService.makeAPIPatchCall<CompanyContact>
      (
        environment.tcemsAPIBase,
        environment.tcemsAPI_support + "/company/" + companyId + "/companycontact/" + companyContactId,
        ccu,
        {}
      ));
  }

  deleteCompanyContact(companyId: number, companyContactId: number)
  {
    return from(this.apiService.makeAPIDeleteCall<CompanyContact>
      (
        environment.tcemsAPIBase,
        environment.tcemsAPI_support + "/company/" + companyId + "/companycontact/" + companyContactId,
        {}
      ));
  }

  updateCompanyWhiteLabelProgram(id: number, wlp: WhiteLabelProgramUpdate)
  {
    return from(this.apiService.makeAPIPatchCall<WhiteLabelProgram>
      (
        environment.tcemsAPIBase,
        environment.tcemsAPI_support + "/company/" + id + "/whitelabel",
        wlp,
        {}
      ));
  }

  updateCompanyHierarchyDetails(id: number, chd: CompanyHierarchyUpdate)
  {
    return from(this.apiService.makeAPIPatchCall<CompanyHierarchy>
      (
        environment.tcemsAPIBase,
        environment.tcemsAPI_support + "/company/" + id + "/hierarchydetails",
        chd,
        {}
      ));
  }

  getSubscriberCount(companyId: number)
  {
    return from(this.apiService.makeAPIGetCall<number>
      (
        environment.tcemsAPIBase,
        environment.tcemsAPI_support + "/company/" + companyId.toString() + "/subscount",
        {}
      ));
  }

  deleteWhiteLabelProgram(companyId: number)
  {

    return from(this.apiService.makeAPIDeleteCall<WhiteLabelProgram>
      (
        environment.tcemsAPIBase,
        environment.tcemsAPI_support + "/company/" + companyId + "/whitelabel",
        {}
      ));
  }

  getWhiteLabels()
  {
    return from(this.apiService.makeAPIGetCall<WhiteLabelType[]>
      (
        environment.tcemsAPIBase,
        environment.tcemsAPI_encounter + "/whitelabels",
        {}
      ));
  }

  getNetworksForCompany(companyId: number)
  {
    return from(this.apiService.makeAPIGetCall<Network[]>
      (
        environment.tcemsAPIBase,
        environment.tcemsAPI_support + "/company/" + companyId + "/networks",
        {}
      ));
  }

  newPhi(options: {phi: PhiUpdate,companyId?: number})
  {
    return from(this.apiService.makeAPIPutCall<Phi>
      (
        environment.tcemsAPIBase,
        environment.tcemsAPI_phi,
        options.phi,
        { ... options.companyId && {companyId: options.companyId.toString()}}
      ));
  }

  newPlan(options: {plan: PlanUpdate, phiId: number, memberId: string} )
  {
    return from(this.apiService.makeAPIPutCall<Plan>
      (
        environment.tcemsAPIBase,
        environment.tcemsAPI_phi + "/" + options.phiId.toString() + "/plan/" + options.memberId,
        options.plan,{}
      ));
  }





  /////////////////
  //GEOCODE CALLS
  /////////////////
  getZipDistance(zipStart: string, zipEnds: string[]) : Observable<Distance[]>
  {
    return from(this.apiService.makeAPIGetCall<Distance[]>
      (
        environment.tcemsAPIBase,
        environment.tcemsAPI_geocode + "/distance/zip/" + zipStart,
        {
          ... zipEnds && {toZip:  zipEnds}
        }
      ));
  }




  //////////////////////
  // COMM CALLS
  //////////////////////
  getJwt() : Observable<TwilioConnection>
  {
    return from(this.apiService.makeAPIGetCall<TwilioConnection>
      (
        environment.tcemsAPIBase,
        environment.tcemsAPI_comm + "/getjwt",
        {}
      ));
  }

  getTaskRouterCapabilityToken( twilioWorkspaceSid: string, twilioWorkerSid: string ) : Observable<TwilioConnection>
  {
    return from(this.apiService.makeAPIGetCall<TwilioConnection>
      (
        environment.tcemsAPIBase,
        environment.tcemsAPI_comm + "/gettaskroutercapabilitytoken/" + twilioWorkspaceSid + "/" + twilioWorkerSid,
        {}
      ));
  }


  acceptTask(taskSid: string)
  {
    return from(this.apiService.makeAPIGetCall<TwilioConnection>
      (
        environment.tcemsAPIBase,
        environment.tcemsAPI_publicapi + "/task/" + taskSid + "/accept",
        {}
      ));
  }

  rejectTask(taskSid: string)
  {
    return from(this.apiService.makeAPIGetCall<TwilioConnection>
      (
        environment.tcemsAPIBase,
        environment.tcemsAPI_publicapi + "/task/" + taskSid + "/reject",
        {}
      ));
  }

  completeTask( taskSid:string )
  {
    return from( this.apiService.makeAPIGetCall<TwilioConnection>(
      environment.tcemsAPIBase,
      environment.tcemsAPI_publicapi + "/task/wrapuptask/" + taskSid,
      {}
    ));
  }

  hangupConversation( taskSid:string, conversationId: number )
  {
    return from( this.apiService.makeAPIGetCall<TwilioConnection>(
      environment.tcemsAPIBase,
      environment.tcemsAPI_publicapi + "/conversation/" + conversationId.toString() + "/hangup",
      {taskSid: taskSid}
    ));
  }

  sendMessage( conversationId:string, participantSid:string, message:string )
  {
    return from( this.apiService.makeAPIGetCall<boolean>(
      environment.tcemsAPIBase,
      environment.tcemsAPI_publicapi + "/conversation/sendmessage/" + conversationId + "/as/" + participantSid,
      { message: message }
    ));
  }

  getWorkspaceSid( workspaceName: string ) : Observable<string>
  {
    return from(this.apiService.makeAPIGetCall<string>
      (
        environment.tcemsAPIBase,
        environment.tcemsAPI_comm + "/getworkspacesid/" + workspaceName,
        {}
      ));
  }

  connectCall( cid: string, endpoint1: string, endpoint2: string, tasksid: string, workersid: string, workername: string, message: string, timeout: number ) : Observable<string>
  {
    return from(this.apiService.makeAPIGetCall<string>
      (
        environment.tcemsAPIBase,
        environment.tcemsAPI_publicapi + "/call/connectcall/" + cid + "/" + endpoint1 + "/" + endpoint2 + "/"
          + tasksid + "/" + workersid + "/" + encodeURI( workername ) + "/" + encodeURIComponent( message ) + "/" + timeout,
        {}
      ) );
  }

  //////////////////////
  // REPORTING CALLS
  //////////////////////

  getReportsForClient(clientId: number) : Observable<ClientReports>
  {
    return from(this.apiService.makeAPIGetCall<ClientReports>
      (
        environment.tcemsAPIBase,
        environment.tcemsAPI_reports + "/clientreports/"+ clientId.toString() + "/list",
        {}
      ));
  }

  getAllClientReportDescriptors() : Observable<ClientReportDescriptor[]>
  {
    return from(this.apiService.makeAPIGetCall<ClientReportDescriptor[]>
      (
        environment.tcemsAPIBase,
        environment.tcemsAPI_reports + "/clientreports/descriptors/all/list",
        {}
      ));
  }


  getSystemReportDescriptors() : Observable<SystemReportDescriptor[]>
  {
    return from(this.apiService.makeAPIGetCall<SystemReportDescriptor[]>
      (
        environment.tcemsAPIBase,
        environment.tcemsAPI_reports + "/systemreports/descriptors",
        {}
      ));
  }

  getClientReportsAtFolder(clientId: number, folderPath: string) : Observable<ClientReports[]>
  {
    return from(this.apiService.makeAPIGetCall<ClientReports[]>
      (
        environment.tcemsAPIBase,
        environment.tcemsAPI_reports + "/clientreports/" + clientId.toString() + "/at/folder/" + folderPath,
        {}
      ));
  }

  getSystemReportsInFolder( folderPath: string) : Observable<SystemReport[]>
  {
    return from(this.apiService.makeAPIGetCall<SystemReport[]>
      (
        environment.tcemsAPIBase,
        environment.tcemsAPI_reports + "/systemreports/at/folder/" + folderPath,
        {}
      ));
  }

  getAllClientReports() : Observable<ClientReports[]>
  {
    return from(this.apiService.makeAPIGetCall<ClientReports[]>
      (
        environment.tcemsAPIBase,
        environment.tcemsAPI_reports + "/clientreports/all/list",
        {}
      ));
  }

  getAllSystemReports() : Observable<SystemReport[]>
  {
    return from(this.apiService.makeAPIGetCall<SystemReport[]>
      (
        environment.tcemsAPIBase,
        environment.tcemsAPI_reports + "/systemreports/all/list",
        {}
      ));
  }

  downloadReport(reportLocation:StorageSpec)
  {
    return from (this.apiService.downloadFileFromPost
      (
        environment.tcemsAPIBase,
        environment.tcemsAPI_reports + "/downloadreport/storagespec",
        reportLocation,
        {}
      )
    )
    .toPromise().then
    (
      response =>
        new Blob([response], { type: reportLocation.contentType})
    );;
  }

  downloadReports(reportSpecs:string[])
  {
    return from (this.apiService.downloadFileFromPost
      (
        environment.tcemsAPIBase,
        environment.tcemsAPI_reports + "/downloadreports",
        reportSpecs,
        {}
      )
    )
    .toPromise().then
    (
      response =>
        new Blob([response], { type: "application/zip"})
    );;
  }

  downloadReportsBySpec(reportSpecs:StorageSpec[])
  {
    return from (this.apiService.downloadFileFromPost
      (
        environment.tcemsAPIBase,
        environment.tcemsAPI_reports + "/downloadreports/storagespec",
        reportSpecs,
        {}
      )
    )
    .toPromise().then
    (
      response =>
        new Blob([response], { type: "application/zip"})
    );;
  }

  getClientReportSpecs()
  {
    return from (this.apiService.makeAPIGetCall<ReportSpec[]>
      (
        environment.tcemsAPIBase,
        environment.tcemsAPI_reports + "/list/client",
        {}
      ));
  }

  getSystemReportSpecs()
  {
    return from (this.apiService.makeAPIGetCall<ReportSpec[]>
      (
        environment.tcemsAPIBase,
        environment.tcemsAPI_reports + "/list/system",
        {}
      ));
  }

  getReportOutputFormats()
  {
    return from (this.apiService.makeAPIGetCall<ReportOutputFormat[]>
      (
        environment.tcemsAPIBase,
        environment.tcemsAPI_reports + "/outputformats",
        {}
      ));
  }

  runClientReports(options: 
                  {
                    year:number, 
                    period:string,
                    month?:number,
                    quarter?:number, 
                    fileName?:string,
                    spec:RuntimeReportSpec
                  })
  {
    return from(this.apiService.makeAPIPostCall<Job<any>[]>
    (
      environment.tcemsAPIBase,
      environment.tcemsAPI_reports + "/run/clientreports/" + options.year.toString() + "/" + options.period,
      options.spec,
      {
        ... options.month && {month: options.month.toString()},
        ... options.quarter && {quarter: options.quarter.toString()},
        ... options.fileName && {fileName: options.fileName}
      }

    ));
  }

  runSystemReport(options: 
    {
      year:number,
      fileName:string,
      spec:RuntimeReportSpec
    })
    {
    return from(this.apiService.makeAPIPostCall<Job<any>[]>
    (
    environment.tcemsAPIBase,
    environment.tcemsAPI_reports + "/run/systemreport/" + options.year.toString() + "/" + options.fileName,
    options.spec,
    {}

    ));
    }
  
  fetchEncounterFiles(encounterId: number)
  {
    return from (this.apiService.makeAPIGetCall<HEMIFileDescriptor[]>
      (
        environment.tcemsAPIBase,
        environment.tcemsAPI_encounter + "/" + encounterId.toString() + "/files",
        {}
      ));
  }
  fetchEncounterFaxes(encounterId: number)
  {
    return from (this.apiService.makeAPIGetCall<EncounterFax[]>
      (
        environment.tcemsAPIBase,
        environment.tcemsAPI_encounter + "/" + encounterId.toString() + "/faxes",
        {}
      ));
  }

  sendFax(encounterId: number,  fax:EncounterFaxWrite)
  {
    return from (this.apiService.makeAPIPostCall<EncounterFax>
      (
        environment.tcemsAPIBase,
        environment.tcemsAPI_encounter + "/" + encounterId.toString() + "/fax",
        fax,
        {}
      ));
  }
  
  deleteEncounterFile(encounterId: number, fileId: string)
  {
    return from(this.apiService.makeAPIDeleteCall<HEMIFileDescriptor>
      (
        environment.tcemsAPIBase,
        environment.tcemsAPI_encounter + "/" + encounterId.toString() + "/file/" + fileId,
        {}
      ));
  }



  getAllAvailableFileTypes()
  {
    return from(this.apiService.makeAPIGetCall<string[]>
      (
        environment.tcemsAPIBase,
        environment.tcemsAPI_encounter + "/filetypes",
        {} 
      ))
  }

  changeEncounterFileType(encounterId: number, fileId: string, type:string)
  {
    return from(this.apiService.makeAPIPatchCall
      (
        
        environment.tcemsAPIBase,
        environment.tcemsAPI_encounter + "/" + encounterId.toString() + "/file/" + fileId + "/type/" + type,
        {},
        {}
      ))
  }

  changeEncounterFileName(encounterId: number, fileId: string, name:string)
  {
    return from(this.apiService.makeAPIPatchCall
      (
        
        environment.tcemsAPIBase,
        environment.tcemsAPI_encounter + "/" + encounterId.toString() + "/file/" + fileId + "/name/" + name,
        {},
        {}
      ))
  }

  downloadEncounterFile(encounterId: number, fileId: string, contentType: string)
  {
    return from (this.apiService.downloadFileFromGet
      (
        environment.tcemsAPIBase,
        environment.tcemsAPI_encounter + "/" + encounterId.toString() + "/file/" + fileId,
        {}
      )
    )
    .toPromise().then
    (
      response =>
        new Blob([response], { type: contentType})
    );;
  }

  getAllCaptives()
  {
    return from(this.apiService.makeAPIGetCall<Captive[]>
      (
        environment.tcemsAPIBase,
        environment.tcemsAPI_support + "/captive",
        {} 
      ))
  }

}
