<p-table  [value]="emails" [scrollable]="true" scrollHeight="flex"
    rowGroupMode="subheader" groupRowsBy="status" >
    <ng-template pTemplate="caption">
        <!-- Search parameters -->
        <div class="grid pt-4">
            <div class="col-2">
                <span class="p-float-label">
                    <p-calendar id="emailsFrom"[(ngModel)]="emailsFrom"></p-calendar>
                    <label for="emailsFrom">Emails Created After</label>
                </span>
            </div>
            <div class="col-2">
                <span class="p-float-label">
                    <p-calendar id="emailsTo" [(ngModel)]="emailsTo"></p-calendar>
                    <label for="emailsTo">Emails Created Before</label>
                </span>
            </div>
            <div class="col-2">
                <span class="pr-4"><p-button label="Search" (click)="searchEmails()"></p-button></span>
                <span><p-progressSpinner *ngIf="showWait" styleClass="w-2rem h-2rem" strokeWidth="8" fill="var(--surface-ground)" animationDuration="2s"></p-progressSpinner></span>
            </div>
          
        </div>
    </ng-template>
    <ng-template pTemplate="header">
        <tr>
            <th>Subject</th>
            <th>From</th>
            <th>Body</th>
            <th>Created Date</th>
            <th>Recipients</th>
            <th>Scheduled Send</th>
            <th>Scheduled For</th>
            <th></th>
        </tr>
    </ng-template>
    <ng-template pTemplate="groupheader" let-email>
        <tr pRowGroupHeader>
            <td colspan="8">
                <span class="font-bold status-header">Status: {{email.status}}</span>
            </td>
        </tr>
    </ng-template>
    <ng-template pTemplate="body" let-email>
        <tr>
            <td>{{email.subject}}</td>
            <td>{{email.fromAddress}}</td>
            <td>{{email.bodyName}}</td>
            <td>{{email.createdDate | date:'short'}}</td>
            <td>{{email.recipientCount}}</td>
            <td><p-checkbox [(ngModel)]="email.scheduledSend" [disabled]="true" [binary]="true"></p-checkbox></td>
            <td>{{email.scheduledSendDate | date:'short'}}</td>
            <td>
                <p-button *ngIf="email.status == 'New' || email.status == 'Awaiting Approval'"  pTooltip="Approve" type="button" class="mr-2 p-button-rounded p-button-text" icon="pi pi-check" (click)="approve(email)"></p-button>
                <p-button *ngIf="email.status == 'Approved'" pTooltip="Send" type="button" class="mr-2 p-button-rounded p-button-text" icon="pi pi-send" (click)="send(email)"></p-button>
            </td>
        </tr>
    </ng-template>
    <!-- <ng-template *ngIf="emails.length == 0" pTemplate="body">
        <p-progressSpinner></p-progressSpinner>
    </ng-template> -->
</p-table>

