import { ClosureReason } from "./closure_reason";
import { EncounterStatus } from "./encounter_status";
import { Phi } from '../phi/phi';
import { Plan } from "../phi/plan";
import { Company } from "../support/company";
import { EncounterProvider } from "./encounter_provider";
import { RewardRule } from "../finance/reward_rule";
import { User } from "../util/user";
import { Appointment } from "./appointment";
import { EncounterProcedure } from "./encounter_procedure";
import { Person } from "../support/person";
import { Category } from "../claim/category";
import { Network } from "../support/network";
import { PhoneNumber } from '../phi/phone-number';
import { EmailAddress } from '../phi/email-address';

export class Encounter
{
    encounterId: number;
    status: EncounterStatus;
    assignedTo: User;
    closureReason: ClosureReason;
    subscriber: Person;
    patient: Person;
    phoneNumbers: PhoneNumber;
    emailAddresses: EmailAddress;

    get patientZip(): string
    {
        return this.patient.phi.addresses.find(a => a.active).zipCode;
    }

    caller: Person;
    patientPlan: Plan;

    get patientNetwork(): Network
    {
        return this.patientPlan.network;
    }
    subscriberPlan: Plan;
    subscriberCompany: Company;
    procedureDescription: string;
    category: Category;
    // costToBeat: number;
    get costToBeat(): number
    {
        return this.providerToBeat == null ? 0 : this.providerToBeat.cost;
    }
    set costToBeat(val: number)
    {
        // do nothing... we want this calculated
    }
    // probableLowCost: number;
    get probableLowCost (): number
    {
        return this.probableLowCostProvider == null ? 0 : this.probableLowCostProvider.cost;
    }
    set probableLowCost (val : number)
    {
        // do nothing... we want this calculated
    }
    // confirmedCost: number;
    get confirmedCost (): number
    {
        return this.confirmedProvider == null ? 0 : this.confirmedProvider.cost;
    }
    set confirmedCost (val: number)
    {
        // do nothing... we want this calculated
    }

    // probableSavings: number;
    get probableSavings() : number
    {
        return (this.providerToBeat == null || this.probableLowCostProvider == null) ? 0 : this.costToBeat - this.probableLowCost;
    }
    set probableSavings(val:number)
    {
        //do nothing... we want this calculated
    }

    //confirmedSavings: number;
    get confirmedSavings(): number
    {
        return (this.providerToBeat == null || this.confirmedProvider == null) ? 0 : this.costToBeat - this.confirmedCost;
    }
    set confirmedSavings(val: number)
    {
        //do nothing.. we want this calculated
    }

    savings: number;
    //get confirmedSavings() : number {return this.costToBeat - this.confirmedCost;}
    //set confirmedSavings(amount:number) {this.savings = amount}
    creationDate: Date;
    confirmedDate: Date;
    paidDateOfConfirmedInstance: Date;
    closedDate: Date;
    ruledRewardAmount: number;
    manualRewardAmount: number;
    // finalRewardAmount: number;
    get finalRewardAmount() : number {return this.manualRewardAmount ? this.manualRewardAmount : this.ruledRewardAmount}
    set finalRewardAmount(amount:number) {if (!amount || (amount && this.manualRewardAmount)) {this.manualRewardAmount = amount;} } //Only set our manual reward amount if we've got an existing manual amount, or if we're setting it to null
    get overrideReward() : boolean {return this.manualRewardAmount == null}
    set overrideReward(val: boolean) {this.manualRewardAmount = 0}
    //providerToBeat: EncounterProvider;

    get providerToBeat() : EncounterProvider
    {
        return this.encounterProviders?.find(ep => ep.providerToBeat);
    }

    get originalProviderToBeat() : EncounterProvider
    {
        return this.encounterProviders?.find(ep => ep.originalProviderToBeat);
    }

    get referringProvider() : EncounterProvider
    {
        return this.encounterProviders?.find(ep => ep.referringProvider);
    }

    set providerToBeat(val: EncounterProvider)
    {
        this.encounterProviders = [...this.encounterProviders?.filter(ep => !ep.providerToBeat), val];
    }

    set originalProviderToBeat(val: EncounterProvider)
    {
        this.encounterProviders = [...this.encounterProviders?.filter(ep => !ep?.originalProviderToBeat), val];
    }

    get employerName() :string
    {
        return this.subscriberCompany?.employerName;
    }

    get lowCostProviders() : EncounterProvider[]
    {
        return this.encounterProviders?.filter(ep => ep.lowCostProvider);
    }

    get probableLowCostProvider() : EncounterProvider
    {
        //Return the top on an inverted sort
        return this.lowCostProviders.sort
        (
            (a,b) =>
            {
                if (a.cost > b.cost) return 1;
                if (a.cost = b.cost) return 0;
                if (a.cost < b.cost) return -1;
            }
        )[0];
    }

    set lowCostProviders(val: EncounterProvider[])
    {
        this.encounterProviders = [...this.encounterProviders?.filter(ep => !ep.lowCostProvider), ...val];
    }

    get lowestLowCostProvider(): EncounterProvider
    {
        return this.lowCostProviders.sort
        (
            (a,b) =>
            {
                if (a.cost < b.cost) return -1
                if (a.cost == b.cost) return 0
                if (a.cost > b.cost) return 1
            }
        )[0];
    }

    get confirmedProvider() : EncounterProvider
    {
        return this.encounterProviders?.find(ep => ep.confirmedProvider);
    }

    set confirmedProvider(val: EncounterProvider)
    {
        this.encounterProviders = [...this.encounterProviders?.filter(ep => !ep.confirmedProvider), val];
    }

    rewardRule: RewardRule;
    modifiedBy: User;
    appointments: Appointment[];
    procedures: EncounterProcedure[];
    encounterProviders: EncounterProvider[] = [];
    encounterType: EncounterType;

    get mostRecentAppointment() : Appointment
    {
        if (this.appointments == null || this.appointments.length == 0) return null;
        return this.appointments.sort((a,b) =>
        {
            if (a.appointmentDate < b.appointmentDate)
            {
                return -1;
            }
            else if (a.appointmentDate == b.appointmentDate)
            {
                return 0;
            }
            else
            {
                return 1;
            }
        })[this.appointments.length - 1];
    }



    public static generateUpdateVersion(e:Encounter): EncounterUpdate
    {
        let eu : EncounterUpdate = new EncounterUpdate();

        eu.status = e.status;
        eu.closureReason = e.closureReason;
        eu.subscriber = e.subscriber.personId;
        eu.patient = e.patient.personId;
        eu.caller = e.caller?.personId;
        eu.patientPlan = e.patientPlan.memberId;
        eu.subscriberPlan = e.subscriberPlan.memberId;
        eu.subscriberCompany = e.subscriberCompany.companyId;
        eu.creationDate = e.creationDate;
        eu.closedDate = e.closedDate;
        eu.manualRewardAmount = e.manualRewardAmount;
        eu.encounterType = e.encounterType;
        eu.assignedToId = e.assignedTo?.userId;

        return eu;

    }
}

export class EncounterType
{
    encounterTypeId: number;
    encounterType: string;

    public static TYPE_REGISTRATION:number = 2;
    public static TYPE_LOW_COST_PROCEDURE_LOOKUP: number =1;
    public static TYPE_PATIENT_ADVOCACY: number = 3;
    public static TYPE_CASE_MANAGEMENT: number = 4;
    public static TYPE_OTHER: number = 5;
}

export class EncounterGroup
{
    phi:Phi;
    encounters:Encounter[];

    get phiId(): number
    {
        return this.phi?.phiId;
    }

    get totalEncountersCount(): number
    {
        return this.encounters.length;
    }

    get totalOpenEncountersCount(): number
    {
        return this.openEncounters.length;
    }

    get openEncounters(): Encounter[]
    {
        return this.encounters.filter(e => ![EncounterStatus.STATUS_CLOSED, EncounterStatus.STATUS_COMPLETE, EncounterStatus.STATUS_CANCELED].includes(e.status.statusCode));
    }

    get closedEncounters(): Encounter[]
    {
        return this.encounters == null || this.encounters.length == 0 ? [] :  this.encounters.filter(ae => [EncounterStatus.STATUS_CLOSED, EncounterStatus.STATUS_COMPLETE, EncounterStatus.STATUS_CANCELED].includes(ae.status.statusCode));
    }

    myOpenEncounters(userId: number): Encounter[]
    {
        return this.encounters == null || this.encounters.length == 0 ? [] : this.encounters.filter(ae => (ae.assignedTo != null && ae.assignedTo.userId == userId)
                && ![EncounterStatus.STATUS_CLOSED, EncounterStatus.STATUS_COMPLETE, EncounterStatus.STATUS_CANCELED].includes(ae.status.statusCode)
        );

    }

    

    myOpenAndPinnedEncounters(userId: number, pinned: number[]): Encounter[]
    {
        //We want unique values here
        //Get all of our encounter ids here, and then return what we have found from the all encounters array
        let encounterIds: number[] =  Array.from(new Set([... this.openEncounters.filter(e => e.assignedTo != null
          && e.assignedTo.userId == userId).map(e=> e.encounterId) ,...pinned]));

        return this.encounters == null || this.encounters.length == 0 ? [] :  this.encounters.filter(ae => encounterIds.includes(ae.encounterId));

    }

    otherOpenEncounters(userId: number): Encounter[]
    {
        return this.encounters == null || this.encounters.length == 0 ? [] :  this.encounters.filter(ae => (ae.assignedTo == null || ae.assignedTo.userId != userId)
                                                //&& !this.pinnedEncounters.map(pe => pe.encounterId).includes(ae.encounterId)
                                                && ![EncounterStatus.STATUS_CLOSED, EncounterStatus.STATUS_COMPLETE, EncounterStatus.STATUS_CANCELED].includes(ae.status.statusCode)
                                                );
    }


}


export class UserEncounter
{
    phi: Phi;
    openEncounters: Encounter[];
    confirmedEncounters: Encounter[];
    allEncounters: Encounter[];
    pinnedEncounters: Encounter[];
    recentEncounters: Encounter[];
    allOpenEncounters: Encounter[];
    pinnedEncountersCount: number;
    openEncountersCount: number;
    confirmedEncountersCount: number;
    totalEncountersCount: number;
    totalOpenEncountersCount: number;



    get pinned() : boolean
    {
        return this.openEncounters.length > 0 || this.pinnedEncounters.length > 0;
    }

    myOpenEncounters(userId: number): Encounter[]
    {
        return this.allEncounters == null || this.allEncounters.length == 0 ? [] : this.allEncounters.filter(ae => (ae.assignedTo != null && ae.assignedTo.userId == userId)
                && ![EncounterStatus.STATUS_CLOSED, EncounterStatus.STATUS_COMPLETE, EncounterStatus.STATUS_CANCELED].includes(ae.status.statusCode)
        );

    }

    myOpenAndPinnedEncounters(userId: number): Encounter[]
    {
        //We want unique values here
        //Get all of our encounter ids here, and then return what we have found from the all encounters array
        let encounterIds: number[] =  Array.from(new Set([... this.openEncounters.filter(e => e.assignedTo != null
          && e.assignedTo.userId == userId).map(e=> e.encounterId) ,...(this.pinnedEncounters.map(e=>e.encounterId))]));

        return this.allEncounters == null || this.allEncounters.length == 0 ? [] :  this.allEncounters.filter(ae => encounterIds.includes(ae.encounterId));

    }

    otherOpenEncounters(userId: number): Encounter[]
    {
        return this.allEncounters == null || this.allEncounters.length == 0 ? [] :  this.allEncounters.filter(ae => (ae.assignedTo == null || ae.assignedTo.userId != userId)
                                                //&& !this.pinnedEncounters.map(pe => pe.encounterId).includes(ae.encounterId)
                                                && ![EncounterStatus.STATUS_CLOSED, EncounterStatus.STATUS_COMPLETE, EncounterStatus.STATUS_CANCELED].includes(ae.status.statusCode)
                                                );
    }

    closedEncounters(): Encounter[]
    {
        return this.allEncounters == null || this.allEncounters.length == 0 ? [] :  this.allEncounters.filter(ae => [EncounterStatus.STATUS_CLOSED, EncounterStatus.STATUS_COMPLETE, EncounterStatus.STATUS_CANCELED].includes(ae.status.statusCode))//&& !this.pinnedEncounters.map(pe => pe.encounterId).includes(ae.encounterId));
    }


}

export class EncounterUpdate
{
    constructor()
    {

    }

    status: EncounterStatus;
    closureReason: ClosureReason;
    subscriber: number;
    patient: number;
    caller: number;
    patientPlan: string;
    subscriberPlan: string;
    subscriberCompany: number;
    creationDate: Date;
    confirmedDate: Date;
    closedDate: Date;
    manualRewardAmount: number;
    encounterType: EncounterType;
    assignedToId?: number;
}
