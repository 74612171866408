import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { TcemsService } from 'src/app/api/tcems.service';
import { Company } from 'src/app/model/support/company';

@Component({
  selector: 'app-company-search',
  templateUrl: './company-search.component.html',
  styleUrls: ['./company-search.component.scss']
})
export class CompanySearchComponent implements OnInit, OnChanges {


  pendingCompany: Company = null;
  companySearchResults: Company[] = [];

  @Input() company: Company;
  @Output() companySelected: EventEmitter<Company> = new EventEmitter<Company>();

  constructor(private tcems:TcemsService) { }
  
  
  ngOnChanges(changes: SimpleChanges): void 
  {
  }


  ngOnInit(): void 
  {
  }
  
  companySearch(event)
  {

      let q = event.query;
      if (q == null || q.length == 0 ) { return; } // empty query

      //Check our api
      this.tcems.searchCompanies(q).toPromise().then
      (
        results =>
        {
          //We should filter these results.
          //Only show results that can be attached to
          this.companySearchResults = results.filter(c => c.hierarchyDetail != null && c.hierarchyDetail.attachmentTier == c.tier    ).map(c => Object.assign(new Company(), c));
        }
      )
  }

  newCompanySelected(event:Company)
  {
    this.companySelected.emit(event);
  }
}
