import { ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { MenuItem, PrimeIcons } from 'primeng/api';
import { Observable, Subscription } from 'rxjs';
import { TcemsService } from 'src/app/api/tcems.service';
import { Category } from 'src/app/model/claim/category';
import { Procedure } from 'src/app/model/claim/procedure';
import { ProcedureHierarchy } from 'src/app/model/claim/procedure_hierarchy';
import { AppointmentStatus } from 'src/app/model/encounter/appointment_status';
import { ClosureReason } from 'src/app/model/encounter/closure_reason';
import { Encounter, EncounterUpdate } from 'src/app/model/encounter/encounter';
import { EncounterModelProcedure } from 'src/app/model/encounter/encounter_model_procedure';
import { EncounterProcedure } from 'src/app/model/encounter/encounter_procedure';
import { EncounterProvider, EncounterProviderInsert, EncounterProviderUpdate } from 'src/app/model/encounter/encounter_provider';
import { EncounterStatus } from 'src/app/model/encounter/encounter_status';
import { Practice } from 'src/app/model/provider/practice';
import { PracticeAlias } from 'src/app/model/provider/practice_alias';
import { Provider } from 'src/app/model/provider/provider';
import { ProviderLocation } from 'src/app/model/provider/provider_location';
import { PagingResult } from 'src/app/model/util/resultpaging';
import { TcemsUtilitiesService } from 'src/app/util/tcems-utilities.service';
import { Transaction } from 'src/app/model/finance/transaction';
import { formatDate } from '@angular/common';
import { Phi } from '../../../model/phi/phi';
import { Plan } from '../../../model/phi/plan';
import { Person } from '../../../model/support/person';
import { PhoneNumber } from '../../../model/phi/phone-number';
import { EmailAddress } from '../../../model/phi/email-address';
import { User } from 'src/app/model/util/user';
import { ReferenceCode } from 'src/app/model/support/reference_code';
import { Diary, DiaryType } from 'src/app/model/support/diary';
import { RewardRule } from 'src/app/model/finance/reward_rule';
import { Address } from 'src/app/model/phi/address';


export class PracticeWithAlias
{
  practice: Practice;
  alias: PracticeAlias;
  get displayName(): string
  {
    return this.practice.practiceName + " - " + this.alias.aliasName;
  }
}


@Component( {
  selector: 'app-encounter-details-page',
  templateUrl: './encounter-details-page.component.html',
  styleUrls: [ './encounter-details-page.component.scss' ]
} )
export class EncounterDetailsComponent
  implements OnInit, OnChanges
{

  @Input() encounter: Encounter;
  @Input() currentMember: Phi;
  @Input() currentMemberSubscriber: Phi;

  @Output() encounterProviderAdded: EventEmitter<EncounterProvider> = new EventEmitter<EncounterProvider>();
  @Output() encounterProviderChanged: EventEmitter<{old:EncounterProvider,new:EncounterProvider}> = new EventEmitter<{old:EncounterProvider,new:EncounterProvider}>();
  @Output() encounterProviderRemoved: EventEmitter<EncounterProvider> = new EventEmitter<EncounterProvider>();
  @Output() categorySet: EventEmitter<Category> = new EventEmitter<Category>();
  @Output() proceduresChanged: EventEmitter<EncounterProcedure[]> = new EventEmitter<EncounterProcedure[]>();
  @Output() statusChange: EventEmitter<EncounterStatus> = new EventEmitter<EncounterStatus>();
  @Output() closureReasonChange: EventEmitter<ClosureReason> = new EventEmitter<ClosureReason>();
  @Output() diaryAdded: EventEmitter<Diary> = new EventEmitter<Diary>();

  encSavings: any = {};
  //chartOptions: any = {};
  subscription: Subscription;
  statusCodes: MenuItem[];
  statusHighlight: number;
  procLookupVisible: boolean = false;
  referringProviderSearchVisible: boolean = false;
  referringPracticeSearchResults: PracticeWithAlias[];
  referringProviderSearchResults: Provider[];
  selectedReferringPractice: PracticeWithAlias = null;
  selectedReferringProvider: Provider = null;
  referringProviderLocations: ProviderLocation[];
  selectedReferringProviderLocation: ProviderLocation;
  selectedPractice: Practice = null;
  selectedProvider: Provider = null;
  planMembers: Plan[];
  selected;
  showBusy = false;
  transactions: Transaction[] = [];
  pendingEncounterCaller: Person;

  rewardDialogVisible: boolean = false;
  encounterRewardRules: RewardRule[] = [];
  tempRewardRule: RewardRule = null;

  noteScrollerType=ReferenceCode.create(ReferenceCode.TYPE_ENCOUNTER);


  //categorySelectionEnabled: boolean = true;
  get categorySelectionEnabled():boolean
  {
    if (this.encounter == null) return false;
    return this.encounter.providerToBeat != null || this.encounter.probableLowCostProvider != null || this.encounter.confirmedProvider != null ? false :true;
  }

  // showStatusDialog: boolean = false;
  pendingStatus:EncounterStatus;
  pendingClosureReason:ClosureReason;
  closed: number = EncounterStatus.STATUS_CLOSED;
  canceled: number = EncounterStatus.STATUS_CANCELED;
  confirmed: number = EncounterStatus.STATUS_CONFIRMED;
  partiallyConfirmed: number = EncounterStatus.STATUS_PARTIALLY_CONFIRMED;
  underReview: number = EncounterStatus.STATUS_CONFIRMED_NEEDS_REVIEW;
  notScheduled: number = EncounterStatus.STATUS_PENDING_MORE_INFO;
  scheduled: number = EncounterStatus.STATUS_SCHEDULED;
  open: number = EncounterStatus.STATUS_OPEN;
  complete: number = EncounterStatus.STATUS_COMPLETE;


  apptScheduled: number = AppointmentStatus.STATUS_SCHEDULED;
  apptComplete: number = AppointmentStatus.STATUS_COMPLETE;
  apptCancelled: number = AppointmentStatus.STATUS_CANCELLED;
  apptFirstAvailable: number = AppointmentStatus.STATUS_FIRST_AVAILABLE;
  get now():Date
  {
    return new Date();
  }

  // showClosureEdit: boolean = false;

  private _procedures: Procedure[];

  get procedures(): Procedure[]
  {
    //return this.encounter.procedures.map(p => Object.assign(new Procedure(), p.procedure));
    return this._procedures;
  }

  set procedures(val: Procedure[])
  {
    //Determine what to add
    let toAdd:Procedure[] = val == null ? [] : val.filter(v => !this.encounter.procedures.map(p => p.procedure.procedureId).includes(v.procedureId));
    let toDelete: EncounterProcedure[] = val == null ? this.encounter.procedures : this.encounter.procedures.filter(p => !val.map(v => v.procedureId).includes(p.procedure.procedureId));


    //This syntax is interesting...
    //Were creating a promise that will run when all of the adds and deletes (promises from the api themselves) are completed.
    //To do this, we define an array
    //the first item is a promise of all the promises from the add actions
    //And the second is a prmise of all the promises from the delete actions
    //At the end, we want to propigate the change event to our event emitter

    Promise.all(
    [
      Promise.all(toAdd.map
      (
        p =>
        {
          let newProc = new EncounterProcedure();
          newProc.encounterId = this.encounter.encounterId;
          newProc.primaryProcedure = true;
          newProc.procedure = p;
          this.encounter.procedures = [ ...this.encounter.procedures, newProc ];
          return this.tcems.addEncounterProcedure({encounterId: this.encounter.encounterId,proc:newProc.asUpdate()}).toPromise()
          .then
          (
            ep =>
            {
              //Make sure we add the newly created encounter provider entries to the encounter
              newProc.id = ep.id;
            }
          )
        }
      ))
      ,
      //Delete stuff from the encounter procedures
      Promise.all(toDelete.map
      (
        p =>
        {
          this.encounter.procedures = this.encounter.procedures.filter(ep => ep.procedure.procedureId != p.procedure.procedureId);
          this.tcems.deleteEncounterProcedure({encounterId: this.encounter.encounterId,procId: p.id});
        }
      ))
    ])
    .then
    (
      result=>
      {
        this._procedures = this.encounter.procedures.map(p => Object.assign(new Procedure(), p.procedure));
        this.proceduresChanged.emit(this.encounter.procedures);
      }
    );
    // //Find out what's been added or deleted
    // let oldProcs = [... this.encounter.procedures];
    // this.encounter.procedures = [];
    // val.forEach
    // (
    //   p =>
    //   {
    //     let newProc = new EncounterProcedure();
    //     newProc.encounterId = this.encounter.encounterId;
    //     newProc.primaryProcedure = true;
    //     newProc.procedure = p;
    //     this.encounter.procedures = [ ...this.encounter.procedures, newProc ];
    //   }
    // );

    // //Compare what we have now
    // //stuff in old procs that isn't in new procs should be deleted
    // //stuff in new procs that isn't in old procs should be added
    // oldProcs.filter(op => op.)
    // this.encounter.procedures.filter(p=> )

  }

  pendingEncounterProvider: EncounterProvider;
  // currentlySelectedModelProcs: EncounterModelProcedure[];
  // currentlySelectedProviderLocation: ProviderLocation;
  lookupAction: number = 0; // 1 = ptb ; 2 = lcp ; 3 = cp ; 0 = none

  todaysDate = formatDate(Date.now(), 'yyyyMMdd', 'en-US');

  constructor(private tcems: TcemsService, public util: TcemsUtilitiesService, private cdr: ChangeDetectorRef)
  {
  }


  ngOnChanges(changes: SimpleChanges): void 
  {
    this.init();
  }

  ngOnInit(): void
  {
    this.init();
  }

  get hasActiveAddress(): boolean
  {
    const addrMember: Address = this.encounter.patient?.phi?.addresses?.filter( a => a.active )[0];
    return addrMember != null;
  }

  init()
  {
    //If we don't have a caller yet, then we need to add the patient as the caller.
    
    if (this.encounter.caller == null)
      {
        this.encounter.caller = this.encounter.patient;
        this.pendingEncounterCaller = this.encounter.patient;
        //this.encounter = Object.assign(new Encounter(), this.encounter);
        this.updateCaller(null,false);
        
      }

      //Initialize the caller phi
    this.tcems.getPhi(this.encounter.caller.phi.phiId).toPromise().then
    (
      phi =>
      {
        this.encounter.caller.phi = Object.assign(new Phi(), phi);
      }
    );

    this.tcems.getPlan(this.encounter.patientPlan.memberId).toPromise()
        .then(
            plan =>
            {
              const p: Plan = Object.assign(new Plan(), plan);
              //this.planMembers = phi.currentActivePlan?.planMembers;
              this.planMembers = plan.planMembers;
              if (this.planMembers != null)
                this.planMembers.forEach(p => p.phi = Object.assign(new Phi(), p.phi));

              //Stuff this phi into the encounter patient as well
              //This will ensure that we have everything that we need at our disposal later o
              //this.encounter.patient.phi = p.phi;
            }
        );

      this.loadEncounterDeets();

  }

  loadEncounterDeets()
  {

    let lowCost: number = this.encounter.confirmedProvider == null ? this.encounter.probableLowCost : this.encounter.confirmedCost;
    let savings: number = this.encounter.confirmedProvider == null ? this.encounter.probableSavings : this.encounter.confirmedSavings;

    this.encSavings = {
      labels: [
        'Low Cost: $ ' + Math.round( lowCost )
                             .toLocaleString()
        + ' ( ' + Math.round( 100 * lowCost / this.encounter.costToBeat )
                      .toString() + '% )',
        'Savings: $ ' + Math.round( savings )
                            .toLocaleString()
        + ' ( ' + Math.round( 100 * savings / this.encounter.costToBeat )
                      .toString() + '% )' ],
      datasets: [
        {
          data: [
            Math.round( lowCost ),
            Math.round( savings )
          ],
          backgroundColor: [
            '#FFA726',
            '#66BB6A'
          ],
          hoverBackgroundColor: [
            '#FFB74D',
            '#81C784'
          ]
        }
      ]
    };

    this.statusCodes = [ {
      label: 'Open',
      icon: 'pi pi-plus'
    },
      {
        label: 'Not Scheduled',
        icon: 'pi pi-plus'
      },
      {
        label: 'Scheduled',
        icon: 'pi pi-plus'
      },
      {
        label: 'Confirmed',
        icon: 'pi pi-plus'
      },
      {
        label: 'Completed',
        icon: 'pi pi-check'
      } ];


    //this.refreshChart();
    this.refreshProgress();
    this.loadPendingStatus();

    //Ensure that the procedures are instantiated properly
    this.encounter.procedures = this.encounter.procedures.map
    (
      ep => 
      {
        let newEP = Object.assign(new EncounterProcedure(), ep);
        newEP.procedure = Object.assign(new Procedure(), ep.procedure);
        return newEP;
      }
    );
    
    this._procedures = this.encounter.procedures.map(p => Object.assign(new Procedure(), p.procedure));

    //instantiate all of our encounter providers
    this.encounter.encounterProviders = this.encounter.encounterProviders.map
    (
      ep => 
      {
        let newEP = Object.assign(new EncounterProvider(), ep);
        return newEP;
      }
    )


    //Set the pending caller to the current caller
    this.pendingEncounterCaller = this.encounter.caller;

    // for testing different step states
    // this.encounter.status.statusCode = Math.floor(Math.random() * 9) + 1;
  }

  addProviderToBeat(event): void
  {
    this.lookupAction = 1;
    this.procLookupVisible = true;
    this.pendingEncounterProvider = null;
  }

  addLowCostOption(event): void
  {
    this.lookupAction = 2;
    this.procLookupVisible = true;
    this.pendingEncounterProvider = null;
  }

  matchEncounter(event): void
  {

  }

  cancelLookupSelection(): void
  {
    this.lookupAction = 0;
    this.procLookupVisible = false;
  }

  copyProviderToBeatAsLowCost()
  {
    this.lookupAction = 2;
    this.pendingEncounterProvider = Object.assign(new EncounterProvider(),this.encounter.providerToBeat);
    this.pendingEncounterProvider.modelProcedures = this.pendingEncounterProvider.modelProcedures.map
    (
      mp => 
      {
        let newMp =Object.assign(new EncounterModelProcedure, mp);
        newMp.procedure = Object.assign(new Procedure(), newMp.procedure);
        return newMp;
      }
    );
    this.confirmLookupSelection();
  }


  confirmLookupSelection(): void
  {
    this.procLookupVisible = false;

    //Create a new encounter provider
    let ep = new EncounterProviderInsert();
    ep.encounterId = this.encounter.encounterId;
    ep.providerToBeat = this.lookupAction == 1;
    ep.lowCostProvider = this.lookupAction == 2;
    ep.confirmedProvider = this.lookupAction == 3;
    ep.referringProvider = false;
    ep.useCostFromModel = this.pendingEncounterProvider.useCostFromModel;
    ep.providerLocation = this.pendingEncounterProvider.providerLocation.id;
    ep.costOverride = this.pendingEncounterProvider.costOverride;
    ep.modelProcedures = this.pendingEncounterProvider.modelProcedures.map(mp => mp.toUpdate());

    //Persist
    this.addEncounterProvider(ep);



    //With the id of our newly inserted provider, we can add the model procs

    this.lookupAction = 0;

  }

  autoRefreshRewardRule()
  {
    //See what we have left in our encounter for encounter providers. At the very least, we need
    //A provider to beat and a low cost provider.
    this.showBusy = true;
    if(this.encounter.encounterProviders.find(ep => ep.providerToBeat) != null 
        && (this.encounter.encounterProviders.find(ep=> ep.lowCostProvider) !=null 
            || this.encounter.encounterProviders.find(ep=> ep.confirmedProvider) !=null ))
            {
              //We've still got what we need to auto choose a rule for use
              this.tcems.recalcRewardRuleForEncounter(this.encounter.encounterId).toPromise()
              .then
              (
                result =>
                {
                  this.encounter = this.instantiateNewEncounter(result);
                  this.loadEncounterDeets();
                  this.showBusy = false;
                }
              )
              .catch
              (
                error =>
                {
                  this.util.displayError("Could Not Update Reward Rule","Please update manually.");
                  console.log(error);
                  this.showBusy = false;
                }
              )


            }
    else
    {
      //we're missing at least one thing we need. So, remove reward rule and reward
      this.recalculateReward(null);
    }
  }

  addEncounterProvider(encounterProvider: EncounterProviderInsert): void
  {
    this.tcems.addEncounterProvider({encounterId: this.encounter.encounterId,encounterProvider:encounterProvider}).toPromise()
    .then
    (
      newEncounterProvider =>
      {
        let ep:EncounterProvider = Object.assign(new EncounterProvider(), newEncounterProvider);
        this.encounter.encounterProviders = [...this.encounter.encounterProviders, ep];
        //this.refreshChart();
        this.encounterProviderAdded.emit(ep);
        this.autoRefreshRewardRule();
      }
    );
  }

  deleteEncounterProvider(encounterProvider: EncounterProvider)
  {
    //We should probably prompt... for now just delete
    this.tcems.deleteEncounterProvider({encounterId:encounterProvider.encounterId,providerId:encounterProvider.id}).toPromise()
    .then
    (
      //make the change in our model
      result =>
      {
        this.encounter.encounterProviders = this.encounter.encounterProviders.filter(ep => ep.id != encounterProvider.id);
        //this.refreshChart();
        //And fire the emitter
        this.encounterProviderRemoved.emit(encounterProvider);
        this.autoRefreshRewardRule();
      }
    )
  }

  addReferringProvider(event)
  {
    this.referringProviderSearchVisible = true;
  }

  categorySelected(event: Category)
  {
    if (event == null)
    {
      //Whenever we select a category, clear out our procedures
      this.proceduresSelected(null);
    }
    this.encounter.category = event;
    this.categorySet.emit(event);
  }

  proceduresSelected(event: Procedure[])
  {
    this.procedures = event;
  }

  removeProcedure(procedure: Procedure)
  {
    this.procedures = this.procedures.filter(p => p.procedureId != procedure.procedureId);
  }

/*
  refreshChart()
  {
    this.chartOptions = {
      plugins: {
      title: {
        display: true,
        text: 'Cost to Beat: $ ' + Math.round( this.encounter.costToBeat )
                                       .toLocaleString(),
        fontSize: 16
      },
      legend: {
        position: 'bottom'
      }
    }
    };
  }
*/

  refreshProgress()
  {
    switch ( this.encounter.status.statusCode )
      {
        case 1: // open
          this.statusHighlight = 0;
          break;
        case 2: // scheduled
          this.statusHighlight = 2;
          break;
        case 3: // not scheduled
          this.statusHighlight = 1;
          break;
        case 4: // confirmed
          this.statusHighlight = 3;
          this.statusCodes[3].label = 'Confirmed';
          break;
        case 5: // complete
          this.statusHighlight = 4;
          this.statusCodes[4].label = 'Complete';
          break;
        case 6: // closed
          this.statusHighlight = 4;
          this.statusCodes[4].label = 'Closed';
          break;
        case 7: // partially confirmed
          this.statusHighlight = 3;
          this.statusCodes[3].label = 'Partially Confirmed';
          break;
        case 8: // confirmed - needs review
          this.statusHighlight = 3;
          this.statusCodes[3].label = 'Confirmed-Needs Review';
          break;
        case 9: // peer review (future?)
          this.statusHighlight = 3;
          this.statusCodes[3].label = 'Peer Review';
          break;
      }
  }

  practiceSearch($event)
  {
    let q = $event.query;
    // console.log("Search:" + q);

    //Depending on the search string, we want to build our results a bit differently
    //Numbers mean that we're looking at member ids and/or encounter ids
    //strings mean that we're looking at member ids and/or phi

    let allPromises: Promise<PagingResult<Practice>>[] = [];

    if(q == null || q.length == 0 ) return; //empty query

    //Execute query
    this.tcems.searchPractices({q:q}).toPromise()
    .then
    (
      result =>
        {
          //Peel out the results
          this.referringPracticeSearchResults = [];
          result.records.forEach
          (
            r =>
            this.referringPracticeSearchResults = this.referringPracticeSearchResults.concat
            (
              r.aliases.map
              (
                a=>
                {
                  let pwa = new PracticeWithAlias();
                  pwa.alias = a;
                  pwa.practice = r;
                  return pwa;
                }
              )
            )
          );
        }
    )
    .catch
    (
      error =>
      {
        console.log('Practice Search Failed: ' + error);
        this.referringPracticeSearchResults = [];
        return null;
      }
    )

  }

  practiceSelectedFromSearch(event: PracticeWithAlias)
  {
    // console.log(event);
    this.selectedPractice = event.practice;
    // extract all of our provider locations
    // this.tcems.getProviderLocationsForPractice({ein:event.practice.ein})

  }

  providerSearch($event)
  {
    let q = $event.query;
    // console.log("Search:" + q);

    // Depending on the search string, we want to build our results a bit differently
    // Numbers mean that we're looking at member ids and/or encounter ids
    // strings mean that we're looking at member ids and/or phi

    let allPromises: Promise<PagingResult<Provider>>[] = [];

    if(q == null || q.length == 0 ) return; //empty query

    //Execute query
    this.tcems.searchProviders({q:q}).toPromise()
    .then
    (
      result => this.referringProviderSearchResults = result.records.map(r => Object.assign(new Provider(),r))

    )
    .catch
    (
      error =>
      {
        console.log('Provider Search Failed: ' + error);
        this.referringProviderSearchResults = [];
        return null;
      }
    );

  }

  providerSelectedFromSearch(event: Provider): void
  {
    // console.log(event);
    this.selectedProvider = event;
    // extract all of our provider locations
    // this.tcems.getProviderLocationsForPractice({ein:event.practice.ein})

  }

  referringProviderSelected(provider: ProviderLocation)
  {
    // console.log(provider);
    this.selectedReferringProviderLocation = provider;

  }

  confirmedProviderSelection(event)
  {
    //Make it into an encounter provider and then add it
    let ep = new EncounterProviderInsert();
    ep.encounterId = this.encounter.encounterId;
    ep.providerToBeat = false
    ep.lowCostProvider = false;
    ep.confirmedProvider = false;
    ep.referringProvider = true;
    ep.useCostFromModel = false;
    ep.providerLocation = this.selectedReferringProviderLocation.id;

    this.addEncounterProvider(ep);
  }

  encounterStatusChanged(status:EncounterStatus)
  {
    this.encounter.status = status;
    this.refreshProgress();
  }

  encounterClosureReasonChanged(reason:ClosureReason)
  {
    this.encounter.closureReason = reason;
    this.refreshProgress();
  }

  loadPendingStatus()
  {
    this.pendingStatus = this.util.encounterStatuses.find(s => s.statusCode == this.encounter.status.statusCode);
    this.pendingClosureReason = this.util.closureReasons.find( cr => cr.reasonCode == this.encounter.closureReason?.reasonCode);
  }

  cancelStatusChange(event)
  {
    this.loadPendingStatus();
  }

  confirmStatusChange(event)
  {

    //If the pending status is closed, then we must also have a set closure reason
    if((this.pendingStatus.statusCode == this.closed || this.pendingStatus.statusCode == this.canceled) && this.pendingClosureReason == null)
    {
      //warn and return
      this.util.displayError("Closure Reason Needed","When closing an encounter, you must specify a closure reason.");
      return;
    }

    this.showBusy = true;

    //Build an encounter Update object
    let updateEncounter: EncounterUpdate = new EncounterUpdate();
    updateEncounter.status = this.pendingStatus;

    if ( this.pendingStatus.statusCode == this.closed || this.pendingStatus.statusCode == this.canceled)  // Update encounter with reason and date
    {
      updateEncounter.closureReason = this.pendingClosureReason;
      updateEncounter.closedDate = new Date();
    }
//    else  // Remove reason and date, if present, since encounter is being reopened
//    {
//      if (updateEncounter.closureReason != null)
//        updateEncounter.closureReason = null;
//      if (updateEncounter.closedDate != null)
//        updateEncounter.closedDate = null;
//    }

    //Commit the change
    this.tcems.updateEncounterRaw({encounterId:this.encounter.encounterId,encounter:updateEncounter}).toPromise()
    .then
    (
      result =>
      {
        //Change our internal status to match what's been committed
        this.encounter.status = this.pendingStatus;

        if ( this.pendingStatus.statusCode == this.closed || this.pendingStatus.statusCode == this.canceled )
        {
          this.encounter.closureReason = this.pendingClosureReason;
          this.encounter.closedDate = result.closedDate;
          this.closureReasonChange.emit(result.closureReason);
        }
//        else
//        {
//          if ( this.encounter.closureReason != null )
//          {
//            this.encounter.closureReason = null;
//            this.closureReasonChange.emit( result.closureReason );
//          }
//          if ( this.encounter.closedDate != null )
//            this.encounter.closedDate = null;
//        }

        //And, fire our event emitter
        this.statusChange.emit(result.status);

        this.refreshProgress();

        //Done being busy
        this.showBusy = false;
      }
    )
  }

  updateCaller(even,reloadEncounter: boolean = false): void
  {
    this.showBusy = true;

    // Build an encounter Update object
    const updateEncounter: EncounterUpdate = new EncounterUpdate();
    updateEncounter.caller = this.pendingEncounterCaller.personId;//this.encounter.caller.personId;

    // Commit the change
    this.tcems.updateEncounterRaw({encounterId: this.encounter.encounterId, encounter: updateEncounter}).toPromise()
        .then
        (
          result =>
          {
            if(reloadEncounter)
              // this.encounter = Object.assign(new Encounter(), result);
              // this.encounter.caller = Object.assign(new Person(), result.caller)
              // this.encounter.caller.phi = Object.assign (new Phi(), result.caller.phi);
              this.encounter = this.instantiateNewEncounter(result);
              this.loadEncounterDeets();
              this.pendingEncounterCaller = this.encounter.caller;

              this.cdr.detectChanges();

            // Done being busy
            this.showBusy = false;
          }
        );
  }

  instantiateNewEncounter(encounter: any) : Encounter
  {
    let e: Encounter =  Object.assign(new Encounter(), encounter);
    e.caller = Object.assign(new Person(), e.caller)
    e.caller.phi = Object.assign (new Phi(), e.caller.phi);

    return e
  }

  loadTransactions()
  {
    this.tcems.searchTransactions({encounters: [this.encounter.encounterId]}).toPromise()
    .then
    (
      results =>
        this.transactions = results
    )
  }

  preloadAssignedTo()
  {
    //Set this encounter assignedTo to something from the list of active users so that we can work with/change it if needed
    if(this.encounter.assignedTo != null)
      this.encounter.assignedTo = this.util.activeNurses.find(au => au.userId == this.encounter.assignedTo.userId);
  }

  confirmAssignedTo(event)
  {
    this.showBusy = true;
    let eu: EncounterUpdate = new EncounterUpdate();
    
    eu.assignedToId = event.value.userId;
    this.tcems.updateEncounterRaw({encounterId:this.encounter.encounterId, encounter: eu}).toPromise().then
    (
      result =>
      {
        this.encounter.assignedTo = Object.assign(new User(), result.assignedTo);

        //Also add a diary assigned to the same user, notifying them of assignment

        let diary:Diary = new Diary();
        diary.assignedTo = this.encounter.assignedTo;
        diary.createDate = new Date();
        diary.diaryDueDate = new Date();
        diary.referenceId = this.encounter.encounterId;
        diary.referenceType = new ReferenceCode();
        diary.referenceType.code = ReferenceCode.TYPE_ENCOUNTER;
        diary.shortDescription = "Encounter Assigned";
        diary.diaryText = "You've been assigned an Encounter";
        diary.diaryType = DiaryType.GetType(DiaryType.TYPE_ID_NEW_ENCOUNTER);
        
        this.tcems.createDiary({referenceCode: ReferenceCode.TYPE_ENCOUNTER, relatedTo: this.encounter.encounterId, diary: diary.asUpdate()}).toPromise()
        .then
        (
          newDiary => 
          {
            //Created... now assign it
            this.tcems.assignDiary({diaryId: newDiary.diaryId, assignedTo:diary.assignedTo,diary: diary.asUpdate()}).toPromise()
            .then
            (
              result =>
              {
                this.showBusy = false;
                this.diaryAdded.emit(newDiary);
              }
            )

          }

        )

      }
    )
    .catch
    (
      error =>
      {
        this.showBusy = false;
        this.util.displayError("Update Failed","Could not update assigned to user: " + error.message);
      }
    )
  }

  encounterProviderReady(): boolean
  {
    //We need to have a pending encounter provider
    //It needs to have a provider location
    //and we either need there to be model procs, or a price override (or both)
    let ready: boolean = true;

    //Is the whole thing null?
    ready = ready && this.pendingEncounterProvider != null;
    
    //Is the provider location null
    ready = ready && this.pendingEncounterProvider.providerLocation != null;

    //We need either a model proc list or a price override
    ready = ready && ((!this.pendingEncounterProvider.override && this.pendingEncounterProvider.modelProcedures != null && this.pendingEncounterProvider.modelProcedures.length > 0) 
                      ||(this.pendingEncounterProvider.override && this.pendingEncounterProvider.costOverride != null && this.pendingEncounterProvider.costOverride > 0)
                     );
  
    return ready;
  }

  openRewardDialog(event)
  {
    this.tempRewardRule = this.encounter.rewardRule;
    //Get the reward rules for this encounter's company
    this.showBusy = true;
    this.tcems.getValidEncounterRewardRules(this.encounter.encounterId).toPromise()
    .then
    (
      result => 
        {
          this.encounterRewardRules = result.map(r => Object.assign(new RewardRule(), r)).filter(rr => rr.active);
          this.encounterRewardRules.sort((a,b) => b.company.tier - a.company.tier || a.ruleName.localeCompare(b.ruleName));
          this.showBusy = false;
          this.rewardDialogVisible = true;
        }

    )
    .catch
    (
      error =>
      {
        this.showBusy = false;
        console.log(error);
      }
    )

  }

  saveNewRewardRuleSelection()
  {
    this.recalculateReward(this.tempRewardRule)
    .then
    (
      result =>
      {
        this.rewardDialogVisible = false;
        this.tempRewardRule = null;
      }
    )
  }


  recalculateReward(rewardRule: RewardRule) : Promise<Encounter>
  {
    this.showBusy = true;
    let updateCall$: Observable<Encounter>; 
    
    if(rewardRule != null)
      updateCall$ = this.tcems.recalcRewardForEncounter(this.encounter.encounterId, rewardRule.ruleId);
    else
      updateCall$ = this.tcems.removeRewardForEncounter(this.encounter.encounterId);

  
    return updateCall$.toPromise()
    .then
    (
      result =>
        {
          this.encounter = this.instantiateNewEncounter(result);
          this.showBusy = false;
          return this.encounter;
        }
    )
    .catch
    (
      error =>
      {
        console.log(error);
        this.showBusy = false;
        return null;
      }
    )
  }

  cancelRewardRuleEdit()
  {
    this.tempRewardRule = null;
    this.rewardDialogVisible = false;
  }

  // processStatusChange(event)
  // {
  //   console.log("Status Changed");
  //   //If we're closing things, then we need to show the closure reason edit first
  //   //otherwise, we can just change!
  //   if(this.pendingStatus.statusCode == EncounterStatus.STATUS_CLOSED)
  //   {
  //     this.showClosureEdit = true;
  //   }
  //   else
  //   {
  //     this.showClosureEdit = false;
  //     this.commitEncounterStatus(null);
  //   }
  // }

  // commitEncounterStatus(event)
  // {
  //   //need a closure reason
  //   if(this.pendingClosureReason == null && this.pendingStatus.statusCode == EncounterStatus.STATUS_CLOSED)
  //   {
  //     this.showClosureEdit = true;
  //     return false;
  //   }
  //   //Create our update object
  //   let updateEncounter: EncounterUpdate = new EncounterUpdate();
  //   updateEncounter.status = this.pendingStatus;
  //   if (this.pendingClosureReason != null) updateEncounter.closureReason = this.pendingClosureReason;
  //   this.showClosureEdit = false;

  //   this.tcems.updateEncounterRaw({encounterId:this.encounter.encounterId,encounter:updateEncounter}).toPromise()
  //   .then
  //   (
  //     result =>
  //     {
  //       this.encounter.status = this.pendingStatus;
  //       //And, fire our event emitter
  //       this.statusChange.emit(result.status);

  //       if(this.pendingClosureReason != null)
  //       {
  //         this.encounter.closureReason = this.pendingClosureReason;
  //         this.closureReasonChange.emit(result.closureReason);
  //       }
  //       this.refreshProgress();

  //     }
  //   );
  // }

}
