import { formatDate } from '@angular/common';
import { Component, OnInit, Type } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { ApiService } from 'src/app/api/api.service';
import { TcemsService } from 'src/app/api/tcems.service';
import { SearchField } from 'src/app/base/search-with-details/search-field';
import { SearchResult } from 'src/app/base/search-with-details/search-result';
import { SearchWithDetailsImplementor } from 'src/app/base/search-with-details/search-with-details-implementor';
import { SinglePanelComponent } from 'src/app/base/single-panel/single-panel.component';
import { InstanceOfCare } from 'src/app/model/claim/instance_of_care';
import { Line } from 'src/app/model/claim/line';
import { Match } from 'src/app/model/claim/match';
import { MatchSummary } from 'src/app/model/claim/match_summary';
import { Encounter } from 'src/app/model/encounter/encounter';
import { EncounterStatus } from 'src/app/model/encounter/encounter_status';
import { EncounterSummary } from 'src/app/model/encounter/encounter_summary';
import { selectEncounterStatuses, selectSources, selectTransactionType } from 'src/app/state/app.state'
import { Procedure } from 'src/app/model/claim/procedure'
import { EncounterProcedure } from 'src/app/model/encounter/encounter_procedure';
import { EncounterProvider, EncounterProviderInsert, EncounterProviderUpdate } from 'src/app/model/encounter/encounter_provider';
import { InstanceOfCareSummary } from 'src/app/model/claim/inistance_of_care_summary';
import { ConfirmedInstance } from 'src/app/model/claim/confirmed_instance';
import { Source } from 'src/app/model/support/source';
import { MenuItem, MessageService } from 'primeng/api';
import { ClosureReason } from 'src/app/model/encounter/closure_reason';
import { Transaction, TransactionType } from 'src/app/model/finance/transaction';
import { Observable, combineLatest, concat, filter, forkJoin, iif, map, merge, mergeAll } from 'rxjs';
import { RewardRule } from 'src/app/model/finance/reward_rule';
import { TcemsUtilitiesService } from 'src/app/util/tcems-utilities.service';
import { ProcedureHierarchy } from 'src/app/model/claim/procedure_hierarchy';
import { AbstractSearchWithDetailsComponent } from 'src/app/base/search-with-details/abstract-search-with-details.component';


@Component({
  selector: 'app-matching-panel',
  templateUrl: './matching.component.html',
  styleUrls: ['./matching.component.scss']
})
export class MatchingComponent
// extends SinglePanelComponent<MatchSummary>
extends AbstractSearchWithDetailsComponent
implements OnInit
// implements SearchWithDetailsImplementor<MatchSummary>
{
  protected createNewEmptyObject(): MatchSummary
  {
    return new MatchSummary();
  }

  constructor(private store: Store, private tcemsService: TcemsService, private util: TcemsUtilitiesService,protected messageService: MessageService)
  {
    super (messageService);
  }

  
  // getDetailPanel(): Type<SinglePanelComponent<MatchSummary>>
  // {
  //   return MatchingPanelAndImplementor;
  // }

  fields: SearchField<any>[];
  searchResults: SearchResult<MatchSummary>[];
  sortFields: {key: string, value: string}[] = (["Encounter Creation Date","Member Id","Number Of Matches","Match Score"]).map(sc => {return {key:sc, value:sc}});
  defaultSortField: {sortField:{key:string, value:string} ,sortDesc:boolean} = {sortField: {key:"Match Score", value:"Match Score"},sortDesc:true};
  currentSortField: {sortField:{key:string, value:string} ,sortDesc:boolean} = this.defaultSortField;
  // detailComponent: SinglePanelComponent<MatchSummary> = this;
  // detailComponentType = MatchingComponent;

  encounterStatuses$ = this.store.select(selectEncounterStatuses);
  encounterStatuses: EncounterStatus[];

  sources$ = this.store.select(selectSources);
  sources : Source[];

  transactionTypes$ = this.store.select(selectTransactionType);
  transactionTypes : TransactionType[];

  encounter: Encounter;
  matches: Match[];
  providerToBeat: EncounterProvider;
  originalProviderToBeat: EncounterProvider;
  lowCostProviders: EncounterProvider [];
  lowCostProviderOptions: {label: string, value:EncounterProvider}[];
  selectedLowCostProviderOption: {label:string, value:EncounterProvider};
  confirmedProviders: EncounterProvider [];
  confirmedInstances: number[];
  candidateInstance: InstanceOfCare;
  candidateLines: Line[];
  matchedCandidateLines: Line[];
  candidateLinesCost: number;
  candidateRewardRule: RewardRule = null;


  selectedMatch: Match = new Match();
  selectedInstanceOfCare: InstanceOfCare;
  instanceSelected: boolean = false;
  activelyMatching: boolean = false;

  recalcCostPTB: number;
  recalcCostLC: number;

  // overridePTB: boolean;
  // overrideLC: boolean;
  overrideConfirmed: boolean;
  useManualReward: boolean;

  issueReward: boolean = false;

  showBusy: boolean = false;

  showRelookup: boolean = false;
  relookupProcs: Procedure[];
  relookupEncounterProvider: EncounterProvider;
  relookupEIN: string;
  initialPtBId: number;

  allowRewardIssuance: boolean = false;

  instanceActions: {[key: number]: MenuItem[]} = {};

  finalCostPTB: number;
  finalCostLC: number;
  //finalCostConfirm: number;
  finalCost: number;
  get finalCostConfirm() : number {return this.overrideConfirmed ? this.finalCost : this.candidateLinesCost;}
  set finalCostConfirm(cost:number) {if (this.overrideConfirmed) {this.finalCost = cost;}}

  useRuledReward: boolean;

  finalReward: number;

  get confirmedSavings(): number {return Math.round((this.providerToBeat.cost - this.finalCostConfirm)) ;}

  get finalRewardAmount() : number {return this.useRuledReward ? this.encounter.ruledRewardAmount : this.finalReward}
  set finalRewardAmount(reward:number) { if (!this.useRuledReward) {this.finalReward = reward;}}


  rewardRuleDialogVisible: boolean = false;

  instanceDialogVisible: number;
  instanceDialogs: {} = {};
  instanceLinesSelected: {} = {};

  STATUS_PARTIALLY_CONFIRMED = EncounterStatus.STATUS_PARTIALLY_CONFIRMED;
  STATUS_CONFIRMED_NEEDS_REVIEW = EncounterStatus.STATUS_CONFIRMED_NEEDS_REVIEW;
  STATUS_CLOSED = EncounterStatus.STATUS_CLOSED;
  NOT_LOW_COST_OPTION = ClosureReason.NOT_LOW_COST_OPTION;
  PAYOR_DENIED = ClosureReason.PAYOR_DENIED;

  availableClaimStatusOptions =
  [
    ,{label:"Confirmed",value:EncounterStatus.STATUS_CONFIRMED}
    ,{label:"Partially Confirmed",value:EncounterStatus.STATUS_PARTIALLY_CONFIRMED}
    ,{label:"Needs Review",value:EncounterStatus.STATUS_CONFIRMED_NEEDS_REVIEW}
  ];

  selectedClaimStatusOption: {label: string, value:number}; // = {label:"Confirmed",value:EncounterStatus.STATUS_CONFIRMED};


  

  buildConfirmOption(instanceOfCare:InstanceOfCareSummary) : MenuItem []
  {
    return [
       {
         label: 'Confirm - Waiting for Claim Data', icon: 'pi pi-clock', command: (event) =>
         {this.confirmFullInstance(instanceOfCare, EncounterStatus.STATUS_PARTIALLY_CONFIRMED,null,false)}
       },
       {
         label: 'Confirm - Needs Review', icon: 'pi pi-question', command: (event) =>
         {this.confirmFullInstance(instanceOfCare, EncounterStatus.STATUS_CONFIRMED_NEEDS_REVIEW,null,false)}
       },
      {
        label: 'Confirm Partial Instance', icon: 'pi pi-clone', command: (event) =>
        {this.showInstanceDialog(instanceOfCare.instanceId)} //We'll just use the dialog we already have to confirm parts of this instance...
      },
      {
        label: 'Confirm and Close - Treated At Provider To Beat', icon: 'pi pi-thumbs-down', command: (event) =>
        {this.confirmFullInstance(instanceOfCare, EncounterStatus.STATUS_CLOSED, ClosureReason.NOT_LOW_COST_OPTION,false)}
      },
      {
        label: 'Confirm and Close - Claim Denied By Payor', icon: 'pi pi-times-circle', command: (event) =>
        {this.confirmFullInstance(instanceOfCare, EncounterStatus.STATUS_CLOSED, ClosureReason.PAYOR_DENIED,false)}
      },
      {
        label: 'Confirm and Close - Patient Never had Procedure', icon: 'pi pi-question-circle', command: (event) =>
        {this.confirmFullInstance(instanceOfCare, EncounterStatus.STATUS_CLOSED, ClosureReason.NO_CLAIM_RECEIVED,false)}
      }

    ];
  }

  ngOnInit()
  {

    this.transactionTypes$.subscribe( t=> {this.transactionTypes = t});


    //subscribe to any changes on our encounter statuses (not likely)
    // this.encounterStatuses$.subscribe(es => this.encounterStatuses = es);
    // this.sources$.subscribe(s => {this.sources = s});

    combineLatest([this.encounterStatuses$,this.sources$])
    .pipe
    (
      filter(p => p[0].length >0 && p[1].length > 0)
    ).subscribe(val =>
        {
        this.encounterStatuses = val[0];
        this.sources = val[1];

        if(this.encounterStatuses.length > 0 && this.sources.length > 0)
        {
          this.fields = [
            new SearchField<string>(
              {
                key: 'EncounterStatus',
                label: 'Encounter Statuses',
                type: 'multiChoice',
                options: this.encounterStatuses.map(es => ({label: es.statusDescription, value:es.statusCode.toString()}))
                //options: [{label:"Open",value:"Open"},{label:"Closed",value:"Closed"}],
              }),
              new SearchField<string>({
                key: 'Source',
                label: 'Sources',
                type: 'multiChoice',
                options: this.sources?.map(s => ({ label: s.sourceName, value:s.sourceId.toString()}))
              }),
              new SearchField<Date>({
                key: 'DateFrom',
                label: 'From Date Of Service',
                type: 'date'
              }),
              new SearchField<Date>({
                key: 'DateTo',
                label: 'To Date Of Service',
                type: 'date'
              }),
              new SearchField<boolean>({
                key: 'ShowMatched',
                label: 'Show Matched?',
                type: 'check'
              })
          ]
        }



      } 
    );


    

  }

  // doSearch(apiService: ApiService, tcemsService: TcemsService): Promise<SearchResult<MatchSummary>[]>
  doSearch(searchEvent: SearchField<any>[])
  {
    // console.log( this.fields.find(f => f.key=="Source").selectedOptions?.map( s=> parseInt(s.value)));

    // console.log(this.fields.find(f => f.key=="Source").selectedOptions);

    this.tcemsService.searchForMatches
    ({
      startDate: searchEvent.find(f => f.key=="DateFrom").value,
      endDate: searchEvent.find(f=>f.key=="DateTo").value,
      encounterStatus: searchEvent.find(f=>f.key=="EncounterStatus").selectedOptions?.map(so => parseInt(so.value)),
      sourceId: searchEvent.find(f => f.key=="Source").selectedOptions?.map( s=> parseInt(s.value)),
      includeMatched: searchEvent.find(f => f.key=="ShowMatched").value
    }
    ).toPromise()
    .then( (value: MatchSummary[]) => {
      // console.log(JSON.stringify(value));
      this.searchResults =  value.map(v =>
        {

          // let m: MatchSummary = Object.assign(new MatchSummary(), v);
          // m.encounter = Object.assign(m.encounter, v.encounter );
          // m.possibleMatches = v.possibleMatches.map( pm =>
          //     {
          //       let match: Match = Object.assign(new Match(),pm);
          //       match.instanceOfCare = Object.assign(new InstanceOfCare(), pm.instanceOfCare);
          //       return match;
          //     });

          return new SearchResult<MatchSummary>(
          {

            payload: Object.assign(new MatchSummary(), v),
            id: v.encounter.encounterId,
            line1: v.encounter.encounterId.toString(),
            //line2: formatDate(v.encounter.encounterCreationDate,"MM/dd/yyyy","en-US"),
            line2: v.encounter.procedureDescription,
            line3: "Matches: "  + v.matchCount.toString() + " - Best Match: " + v.bestMatchScore
          });
        });

        this.searchInProgress = false;
        this.doSort(this.currentSortField);
      })
      .catch(
        error =>
        {
          this.logError(error);
          this.searchInProgress = false;
        }
      );
      
  }




  doSort(sortEvent: {sortField:{key:string, value:string} ,sortDesc:boolean} )
  // sortSearchResults(results: SearchResult<MatchSummary>[], columnName:string, descending: boolean): SearchResult<MatchSummary>[]
  {
    if (sortEvent.sortField.key == "Encounter Creation Date")
    {
      this.searchResults = this.searchResults.slice().sort((a,b) =>  (new Date((b.payload as MatchSummary).encounter.encounterCreationDate).valueOf() - new Date((a.payload as MatchSummary).encounter.encounterCreationDate).valueOf()) * (sortEvent.sortDesc ? 1 : -1));
    }
    else if (sortEvent.sortField.key == "Member Id")
    {
      this.searchResults =  this.searchResults.slice().sort((a,b) => (a.payload as MatchSummary).encounter.patientMemberId.toString().localeCompare((b.payload as MatchSummary).encounter.patientMemberId.toString())* (sortEvent.sortDesc ? 1 : -1));
    }
    else if (sortEvent.sortField.key == "Number Of Matches")
    {
      this.searchResults =  this.searchResults.slice().sort((a,b) => ((b.payload as MatchSummary).matchCount - ((a.payload as MatchSummary).matchCount))* (sortEvent.sortDesc ? 1 : -1));
    }
    else if (sortEvent.sortField.key == "Match Score")
    {
      this.searchResults =  this.searchResults.slice().sort((a,b) => ((b.payload as MatchSummary).bestMatchScore - ((a.payload as MatchSummary).bestMatchScore))* (sortEvent.sortDesc ? 1 : -1));
    }
    else
    {
      this.searchResults =  this.searchResults;
    }

  }


  // selectResult(selectedItem: SearchResult<MatchSummary>)
  // {
  //   //We'll grab a full encounter based on the encounter id from the payload here
  //   let encounterId: number = selectedItem.payload.encounter.encounterId;

  //   //Go snag the encounter
  //   return this.tcemsService.getEncounter(encounterId).toPromise().then((value: Encounter) => value);

  // }
  // protected loadModelDataFromId() : Promise<MatchSummary>
  // {
  //   //we don't ever do this
  //   return null;
  // }






  protected loadMatch(modelData:MatchSummary)
  {

    //We need to load up an encounter from the encounter id in the encounter summary in the match summary
    this.tcemsService.getEncounter(modelData.encounter.encounterId).toPromise()
    .then
    (
      (value: Encounter) =>
        {
          this.loadEncounter(Object.assign(new Encounter() , value));

          //Set the current status
          this.selectedClaimStatusOption = (value.status.statusCode < 4 ? this.availableClaimStatusOptions[0] :  this.availableClaimStatusOptions.filter(o => o != null).find( o => o.value == value.status.statusCode));
          
          // this.availableClaimStatusOptions  availableClaimStatusOptions =
          // [
          //   ,{label:"Confirmed",value:EncounterStatus.STATUS_CONFIRMED}
          //   ,{label:"Partially Confirmed",value:EncounterStatus.STATUS_PARTIALLY_CONFIRMED}
          //   ,{label:"Needs Review",value:EncounterStatus.STATUS_CONFIRMED_NEEDS_REVIEW}
          // ];
        }
    );

    //Let's get our match stuff loaded up
    this.matches = modelData.possibleMatches;

    this.instanceActions = {};

    //Build our individual instance actions
    this.matches.forEach(m =>
      {
        this.instanceActions[m.instanceOfCare.instanceId] = this.buildConfirmOption(m.instanceOfCare);
      })

    //Instantiate the InstanceOfCare Summaries (so we can access the methods inside)
    this.matches.forEach(m => m.instanceOfCare = Object.assign(new InstanceOfCareSummary(), m.instanceOfCare));

    //Hide all of the match detail dialogs
    //this.matches.forEach(m=> this.instanceDialogs[m.instanceOfCare.instanceId.toString()] = false);

  }

  protected loadEncounter( value: Encounter)
  {
    this.encounter = value;

    //Make sure we are really working with Procedure objects for each of the encounter procedures
    this.encounter.procedures = this.encounter.procedures.reduce((a,b) => {return a.concat(Object.assign(new EncounterProcedure(), b))},[]);
    this.encounter.procedures.forEach(l => {l.procedure = Object.assign(new Procedure(), l.procedure)});

    // Also, we want to sort the encounter procedures so that the primary ones
    // are first in the list
    this.encounter.procedures = this.encounter.procedures.slice().sort( (a,b) => a.primaryProcedure? -1 : 1); //Has to be reverse sorted because it's string based: true comes after false in that context

    // Let's make sure we're working with actual provider details
    this.encounter.encounterProviders = this.encounter.encounterProviders.reduce((a,b) => {return a.concat(Object.assign(new EncounterProvider(), b))},[]);
    this.encounter.providerToBeat = Object.assign(new EncounterProvider(), this.encounter.providerToBeat);
    this.encounter.originalProviderToBeat = Object.assign(new EncounterProvider(), this.encounter.originalProviderToBeat);
    // console.log(JSON.stringify(this.encounter.providerToBeat));
    // console.log(this.encounter);
    // Now, copy out the providers to the properties we've created
    this.providerToBeat = this.encounter.providerToBeat;
    this.originalProviderToBeat = this.encounter.originalProviderToBeat ?? Object.assign(new EncounterProvider(), this.encounter.providerToBeat);
    this.lowCostProviders = this.encounter.encounterProviders.filter(ep => ep.lowCostProvider);
    this.lowCostProviderOptions = this.lowCostProviders.map((a) => {return {label: a.providerLocation.practiceName, value: a}; });
    this.selectedLowCostProviderOption = this.lowCostProviderOptions[0];
    this.confirmedProviders = this.encounter.encounterProviders.filter(ep => ep.confirmedProvider);
    this.confirmedInstances = this.confirmedProviders.map(cp => cp.instanceId);  //reduce((a,b) => a.concat(b.modelProcedures.reduce((x,y) => x.concat(y.instanceOfCare.instanceId),[])) ,[]); //oof... okay, this is making sure we have all of the confirmed instance ids set aside

    this.useRuledReward = this.encounter.manualRewardAmount == null;
    this.finalRewardAmount = this.encounter.finalRewardAmount;
    // console.log(JSON.stringify(this.encounter.encounterProviders));
    // console.log(JSON.stringify(this.lowCostProviders));

    this.candidateRewardRule = this.encounter.rewardRule;
    if(this.confirmedProviders != null && this.confirmedProviders.length > 0 && !this.confirmedProviders[0].useCostFromModel)
    {
      this.overrideConfirmed = true;
      this.finalCostConfirm = this.confirmedProviders[0].costOverride;
    }


    this.relookupEIN = this.providerToBeat?.providerLocation?.ein;
    this.initialPtBId = this.providerToBeat.id;
    


  }

  selectInstance(instanceId) : Promise<any>
  {
    //Fetch our instance from the api
    return this.tcemsService.getInstanceOfCare(instanceId).toPromise()
    .then
    (
      (result: InstanceOfCare) =>
      {
        this.selectedInstanceOfCare = result;
        //this.instanceSelected = true;
      }
    );

  }

  unselectMatch(event)
  {

  }

  showInstanceDialog(instanceId: number)
  {
    this.instanceDialogVisible = instanceId;
    this.instanceDialogs[instanceId.toString()] = true;
  }

  showRewardRuleDialog()
  {
    this.rewardRuleDialogVisible = true;
  }

  hideInstanceDialog()
  {
    // console.log("Closing");
    this.instanceDialogVisible = undefined;
  }

  dialogVisible(instanceId: number):boolean
  {
    return this.instanceDialogVisible === instanceId;
  }

  linesSelected(instanceId: number, lineIds:number[])
  {
    this.instanceLinesSelected[instanceId.toString()] = lineIds;
  }


  confirmPartialInstance(instanceId: number)
  {
    //We should have line ids in the lines selected array
    //console.log("Will Partially Confirm: " + instanceId + " " + JSON.stringify(this.instanceLinesSelected[instanceId.toString()]));
    //this.confirmInstance(instanceId, this.instanceLinesSelected[instanceId.toString()],status);
    this.tcemsService.getInstanceOfCare(instanceId).toPromise()
    .then(
      (result: InstanceOfCare) =>
      {
        //Build a confirmed instance object from this
        //let instanceId:number = result.instanceId;
        let lines:Line[] = result.visits.reduce
                                                      (
                                                        (a,b) => a.concat(b.claims.reduce
                                                        (
                                                          (c,d) => c.concat(d.lines),[]
                                                        )), []
                                                      );
        //Hand off to the main method that'll do the confirmation
        //this.confirmInstance(instanceId,lineIds, status);
        //this.instanceLinesSelected[instanceId.toString()] = lineIds;
        //let lineIds: number[] = lines.reduce((a,b) => a.concat(b.lineId),[]);
        this.candidateInstance = result;
        this.candidateLines = lines.filter(l => this.instanceLinesSelected[instanceId.toString()].includes(l.lineId));
        this.matchedCandidateLines = [... this.candidateLines.filter(l => this.providerToBeat.modelProcedures.map(p => p.procedure.code).includes(l.procedure.code))];
        this.candidateLinesCost = this.matchedCandidateLines.reduce((a,b) => a + b.cost,0)
        this.activelyMatching = true;
        this.checkRewardRule();

      });

  }




  closePartialInstance(instanceId: number, status: number= EncounterStatus.STATUS_CONFIRMED, closureReason: number = undefined )
  {
    this.tcemsService.getInstanceOfCare(instanceId).toPromise()
    .then(
      (result: InstanceOfCare) =>
      {
        //Build a confirmed instance object from this
        //let instanceId:number = result.instanceId;
        let lines:Line[] = result.visits.reduce
                                                      (
                                                        (a,b) => a.concat(b.claims.reduce
                                                        (
                                                          (c,d) => c.concat(d.lines),[]
                                                        )), []
                                                      );
        //Hand off to the main method that'll do the confirmation
        //this.confirmInstance(instanceId,lineIds, status);
        //this.instanceLinesSelected[instanceId.toString()] = lineIds;
        //let lineIds: number[] = lines.reduce((a,b) => a.concat(b.lineId),[]);
        this.candidateInstance = result;
        this.candidateLines = lines.filter(l => this.instanceLinesSelected[instanceId.toString()].includes(l.lineId));
        this.matchedCandidateLines = [... this.candidateLines.filter(l => this.providerToBeat.modelProcedures.map(p => p.procedure.code).includes(l.procedure.code))];
        this.candidateLinesCost = this.matchedCandidateLines.reduce((a,b) => a + b.cost,0);
              this.checkRewardRule();

        this.confirmCandidateInstance(status,closureReason);
      });

  }

  confirmFullInstance(instanceOfCare: InstanceOfCareSummary,  status: number = EncounterStatus.STATUS_CONFIRMED, closureReason: number = undefined, showConfirmDialog: boolean = true)
  {
    //Get our instance of care and all line ids from the supplied summary
    this.tcemsService.getInstanceOfCare(instanceOfCare.instanceId).toPromise()
    .then(
      (result: InstanceOfCare) =>
      {
        //Build a confirmed instance object from this
        let instanceId:number = result.instanceId;
        let lines:Line[] = result.visits.reduce
                                                      (
                                                        (a,b) => a.concat(b.claims.reduce
                                                        (
                                                          (c,d) => c.concat(d.lines),[]
                                                        )), []
                                                      );
        let lineIds: number[] = lines.reduce((a,b) => a.concat(b.lineId),[]);
        //Hand off to the main method that'll do the confirmation
        //this.confirmInstance(instanceId,lineIds, status);
        this.instanceLinesSelected[instanceId.toString()] = lineIds;
        this.candidateInstance = result;
        this.candidateLines = lines;
        this.matchedCandidateLines = [... this.candidateLines.filter(l => this.providerToBeat.modelProcedures.map(p => p.procedure.code).includes(l.procedure.code))];
        this.candidateLinesCost = this.matchedCandidateLines.reduce((a,b) => a + b.cost,0);
        // console.log(lines);
        this.activelyMatching = showConfirmDialog;
        this.checkRewardRule();

        if(!showConfirmDialog) {this.confirmInstance(instanceId,lineIds, status);}
      });

  }

  amountOverridden(event)
  {
    this.checkRewardRule();
  }

  checkRewardRule()
  {
    if(this.candidateRewardRule == null)
      this.candidateRewardRule = this.encounter.rewardRule;

    
    //Only if we're using a reward rule
    if(this.useRuledReward)
    {
      //Check that our current savings is within range
      let inRange:boolean =  this.candidateRewardRule != null &&  (this.candidateRewardRule.minSavings == null || this.confirmedSavings >=  this.candidateRewardRule.minSavings) && (this.candidateRewardRule.maxSavings == null || this.confirmedSavings <= this.candidateRewardRule.maxSavings);
      if(!inRange || !this.candidateRewardRule.active || this.finalRewardAmount == null)
      {
        //Need to find new reward rule
        this.tcemsService.getRewardRulesForCompany(this.encounter.subscriberCompany.companyId).toPromise()
        .then
        (
          result =>
          {
            let rules = result.map(rr => Object.assign(new RewardRule(),rr )).filter(rr => rr.active);

            //See if we can find a rule that fits
            let rulesInRange = rules.filter
            (
              rr => 
                (rr.minSavings == null || this.confirmedSavings >= rr.minSavings) 
                && (rr.maxSavings == null || this.confirmedSavings <= rr.maxSavings)
                && (rr.category == null || rr.category.categoryId == this.encounter.category.categoryId )
                && (rr.active)
            );
            
            //Order by tier and then category
            rulesInRange.sort((a,b) => b.company.tier - a.company.tier || (b.category?.categoryId ?? 0) - (a.category?.categoryId ?? 0));

            // let tiers = [...new Set(rulesInRange.map(rr=>rr.company.tier))];
            // let tierCats = {};
            // tiers.forEach(t => tierCats[t] = [... new Set(rulesInRange.filter(rr => rr.company.tier == t).map(rr => rr.category.categoryId))]);

            //Group by tier, and then by category
            // let groupedRules = tiers.map( t => {return {tier: t , tierRules: tierCats[t].map(c => {return {category: c, rules: rulesInRange.filter(rr => rr.company.tier == t && rr.category.categoryId == c)  }})}})
          
            //Find the best first match
            let rule = rulesInRange.find( rir => (rir.minSavings == null || rir.minSavings<= this.confirmedSavings) && (rir.maxSavings == null || rir.maxSavings >= this.confirmedSavings) );
            
            if(rule == null)
            {
              //nothing matches
              this.util.displayError("Could Not Find Reward Rule","No Applicable Reward Rule Exists");
            }
            else
            {
              this.candidateRewardRule = rule;

              //Recalc reward
              this.recalcReward();
            }
          }
        )
      }
      else
      {
        this.recalcReward();
      }
    }
  }

  recalcReward()
  {
    this.encounter.ruledRewardAmount = Math.max((this.candidateRewardRule.fixedAmount != null ? this.candidateRewardRule.fixedAmount : Math.round(this.candidateRewardRule.percentSavings * this.confirmedSavings)), this.candidateRewardRule.baseReward);
    this.finalReward = Math.max((this.candidateRewardRule.fixedAmount != null ? this.candidateRewardRule.fixedAmount : Math.round(this.candidateRewardRule.percentSavings * this.confirmedSavings)), this.candidateRewardRule.baseReward);
  }

  // autoRefreshRewardRule()
  // {
    
  //             //We've still got what we need to auto choose a rule for use
  //             this.tcemsService.recalcRewardRuleForEncounter(this.encounter.encounterId).toPromise()
  //             .then
  //             (
  //               result =>
  //               {
  //                 this.encounter = this.instantiateNewEncounter(result);
  //                 this.showBusy = false;
  //               }
  //             )
  //             .catch
  //             (
  //               error =>
  //               {
  //                 this.util.displayError("Could Not Update Reward Rule","Please update manually.");
  //                 console.log(error);
  //                 this.showBusy = false;
  //               }
  //             )


  //           }
  //   else
  //   {
  //     //we're missing at least one thing we need. So, remove reward rule and reward
  //     this.recalculateReward(null);
  //   }
  // }

  // recalculateReward(rewardRule: RewardRule) : Promise<Encounter>
  // {
  //   this.showBusy = true;
  //   let updateCall$: Observable<Encounter>; 
    
  //   if(rewardRule != null)
  //     updateCall$ = this.tcems.recalcRewardForEncounter(this.encounter.encounterId, rewardRule.ruleId);
  //   else
  //     updateCall$ = this.tcems.removeRewardForEncounter(this.encounter.encounterId);

  
  //   return updateCall$.toPromise()
  //   .then
  //   (
  //     result =>
  //       {
  //         this.encounter = this.instantiateNewEncounter(result);
  //         this.showBusy = false;
  //         return this.encounter;
  //       }
  //   )
  //   .catch
  //   (
  //     error =>
  //     {
  //       console.log(error);
  //       this.showBusy = false;
  //       return null;
  //     }
  //   )
  // }

  statusChanged(event)
  {
    if(this.selectedClaimStatusOption.value == EncounterStatus.STATUS_CONFIRMED)
    {
      this.allowRewardIssuance = true;
    }
    else
    {
      this.issueReward = false;
      this.allowRewardIssuance = false;
    }
  }

  confirmCandidateInstance(status: number = EncounterStatus.STATUS_CONFIRMED, closureReason: number = undefined)
  {

    if(status == EncounterStatus.STATUS_CONFIRMED && this.finalRewardAmount == null)
    {
      return;
    }
    
   


    
    // //Update the encounter with the reward amount
    // if(this.useRuledReward)
    // {
    //   this.encounter.manualRewardAmount = null;
    // }
    // else
    // {
    //   this.encounter.manualRewardAmount = this.finalRewardAmount;
    // }
    // // console.log("Update Encounter reward");
    // // console.log(this.encounter);
    // // console.log(Encounter.generateUpdateVersion(this.encounter));
    // this.tcemsService.updateEncounter({encounterId: this.encounter.encounterId,encounter:this.encounter});

    // //Create an appointment for the date of service on the claim (if one doesn't already exist)

    //If the original provider to beat is null, let's stash our existing provider to beat there
    let providerUpdates: Promise<EncounterProvider>[] = [];
    
    //Take care of the original encounter provider
    if (this.encounter.originalProviderToBeat == null)
    {
      let newOPtB: EncounterProviderInsert = this.originalProviderToBeat.asInsert();
      newOPtB.providerToBeat = false;
      newOPtB.originalProviderToBeat = true;
      newOPtB.referringProvider = false;
      providerUpdates.push(this.tcemsService.addEncounterProvider({encounterId: this.encounter.encounterId,encounterProvider:newOPtB}).toPromise());
    }

    //We've got a pending provider to beat change:
    if(this.relookupEncounterProvider != null)
    {
      //delete the existing provider to beat, and add this new one
      providerUpdates.push(this.tcemsService.deleteEncounterProvider({encounterId:this.encounter.encounterId,providerId:this.initialPtBId}).toPromise().then
      (
        result =>
        {
          let newPtB: EncounterProviderInsert = this.providerToBeat.asInsert();
          newPtB.providerToBeat = true;
          newPtB.originalProviderToBeat = false; 
          newPtB.referringProvider = false;

          return this.tcemsService.addEncounterProvider({encounterId: this.encounter.encounterId,encounterProvider:newPtB}).toPromise();
        }
      ));
    }
    else
    {
      //just update the one we've already got
      providerUpdates.push(this.tcemsService.updateEncounterProvider({encounterId: this.encounter.encounterId, encounterProviderId: this.providerToBeat.id, encounterProvider:this.providerToBeat}).toPromise());
    }

    //Set Overrides on Price to beat
    // console.log("Set Provider To Beat Stuff");
    // console.log(EncounterProvider.generateUpdateVersion(this.providerToBeat));

    //Set overrides on Low Cost Provider
    //providerUpdates.push(this.tcemsService.updateEncounterProvider({encounterId: this.encounter.encounterId, encounterProviderId: this.selectedLowCostProviderOption.value.id, encounterProvider:this.selectedLowCostProviderOption.value}).toPromise());
    // console.log("Set Low Cost Provider");
    // console.log(EncounterProvider.generateUpdateVersion(this.selectedLowCostProviderOption.value));

    //Confirm Instance
    Promise.all(providerUpdates).then
    (
      result => 
        this.confirmInstance(this.candidateInstance.instanceId, this.matchedCandidateLines.map(l=>l.lineId),status,closureReason)
    );
    // console.log("Confirm Instance");



    // //Issue Reward
    // if(this.issueReward)
    // {
    //   let t: Transaction= new Transaction()
    //   t.transactionMagnitude = this.finalRewardAmount;
    //   t.createDate = new Date();
    //   t.approved = true;
    //   t.approvedDate = new Date();
    //   t.encounter = this.encounter;
    //   t.payee = this.encounter.patient.phi;
    //   t.account = this.encounter.rewardRule.paidThroughAccount;
    //   // console.log(this.transactionTypes);
    //   // Set transaction type to rewards if the savings is greater than 0
    //   // console.log(this.confirmedSavings);
    //   if (this.confirmedSavings == 0)
    //   {
    //     t.transactionType = this.transactionTypes.filter( t=> t.typeId == TransactionType.TYPE_CONFIRMATION_INCENTIVE)[0];
    //   }
    //   else
    //   {
    //     t.transactionType = this.transactionTypes.filter(t => t.typeId == TransactionType.TYPE_SAVINGS_REWARD)[0];
    //   }

    //   // console.log("Create Transaction");
    //   // console.log(t);
    //   // console.log(JSON.stringify(Transaction.generateUpdateVersion(t)));
    //   this.tcemsService.createTransaction({transaction:t});
    // }



    // //Reload our encounter
    // this.loadPanelFromModelData();

    //Close our dang window
    this.activelyMatching = false;
    this.closeAllDialogs();

  }

  confirmInstance(instanceId: number, lineIds: number[], status: number = EncounterStatus.STATUS_CONFIRMED, closureReason: number = undefined)
  {



        //Build a confirmed instance object from the parameters
        let confirmedInstance = new ConfirmedInstance();
        confirmedInstance.status = status;
        confirmedInstance.closureReason = closureReason;
        confirmedInstance.instanceId = instanceId;
        confirmedInstance.lineIds = lineIds;
        confirmedInstance.useInstanceCost = !this.overrideConfirmed;
        confirmedInstance.costOverride = this.finalCostConfirm;
        confirmedInstance.issueReward = this.issueReward;
        confirmedInstance.rewardRuleId = this.candidateRewardRule.ruleId;
        confirmedInstance.manualRewardAmount = this.useRuledReward ? null : this.finalRewardAmount;
        confirmedInstance.useManualReward = !this.useRuledReward;
        

        //And, confirm
        this.tcemsService.confirmEncounter({encounterId: this.encounter.encounterId, confirmedInstance: confirmedInstance}).toPromise()
        .then(
          (result) =>
          //Set our current encounter to the one that comes back
          {
            //this.encounter =  
            this.loadEncounter(result.encounter);
          }
        )
      this.closeAllDialogs();
  }

  closeAllDialogs()
  {
    this.rewardRuleDialogVisible = false;
    this.instanceDialogVisible = null;
    for (const d in this.instanceDialogs)
    {
      this.instanceDialogs[d] = false;
    }
    this.activelyMatching = false;
  }


  useRuleChange()
  {
    this.checkRewardRule();
  }

  showPtBRelookup()
  {
    this.showRelookup = true;
    this.relookupProcs = [... this.candidateLines.map(l=>l.procedure)];
  }

  newPtBSelected(ep: EncounterProvider)
  {
    //Shore up the encounter provider
    ep.encounterId = this.encounter.encounterId;
    ep.confirmedProvider = false;
    ep.originalProviderToBeat = false;
    ep.providerToBeat = true;
    ep.lowCostProvider = false;

    this.encounter.providerToBeat = ep;
    this.providerToBeat = ep;

    this.matchedCandidateLines = [... this.candidateLines.filter(l => this.providerToBeat.modelProcedures.map(p => p.procedure.code).includes(l.procedure.code))];
    this.candidateLinesCost = this.matchedCandidateLines.reduce((a,b) => a + b.cost,0)  }

  adjustCandidateLineSelection(event)
  {
    // this.candidateLines = [...this.matchedCandidateLines];
    this.candidateLinesCost = this.matchedCandidateLines.reduce((a,b) => a + b.cost,0)
  }

  encounterProviderReady(): boolean
  {
    //We need to have a pending encounter provider
    //It needs to have a provider location
    //and we either need there to be model procs, or a price override (or both)
    let ready: boolean = true;

    //Is the whole thing null?
    ready = ready && this.relookupEncounterProvider != null;
    
    //Is the provider location null
    ready = ready && this.relookupEncounterProvider.providerLocation != null;

    //We need either a model proc list or a price override
    ready = ready && ((!this.relookupEncounterProvider.override && this.relookupEncounterProvider.modelProcedures != null && this.relookupEncounterProvider.modelProcedures.length > 0) 
                      ||(this.relookupEncounterProvider.override && this.relookupEncounterProvider.costOverride != null && this.relookupEncounterProvider.costOverride > 0)
                     );
  
    return ready;
  }

  resultSelected(selectionEvent: SearchResult<MatchSummary>)
  {
    this.loadMatch(selectionEvent.payload);
  }

}
