<!-- Busy Dialog -->
<p-dialog [modal]="true" [(visible)]="showBusy" [closable]="false" appendTo="body" [showHeader]="false" [style]="{'box-shadow':'none'}" [contentStyle]="{'background':'rgba(0,0,0,0)'}">
    <p-progressSpinner [style]="{'background':'rgba(0,0,0,0)','width':'50px','height':'50px'}" animationDuration=".5s" strokeWidth="8" ></p-progressSpinner>
</p-dialog>

<!-- New Note Dialog -->
    <p-dialog appendTo="body" header="New Note" [(visible)]="showNewNoteDialog"
                                [modal]="false" [dismissableMask]="true" [closeOnEscape]="true"
                                [closable]="true" [style]="{width: '50%'}">


        <div class="w12 formgrid grid">
            <div class="field col-12 mt-3" >
                <span class="p-float-label">
                    <input id="shortDesc" type="text" pInputText [(ngModel)]="newNote.shortDesc">
                    <label for="shortDesc">Short Description</label>
                </span>
            </div>

            <div class="field col-3">
                <span class="p-float-label">
                    <p-dropdown id="noteType" [(ngModel)]="newNote.noteType" [options]="noteTypes" optionLabel="typeName"
                                [showClear]="true" [autoDisplayFirst]="false" dataKey="id" ></p-dropdown>
                    <label for="noteType">Type</label>                    
                </span>
            </div>
            <div class="field col-9">
            </div>

            <div class ="col-12">
                <p-editor [(ngModel)]="newNote.noteText" [style]="{'height':'320px'}" ></p-editor>
            </div>
        </div>
        <div class="flex align-items-center justify-content-center">
            <span class="p-buttonset">
                <button pButton pRipple label="Cancel" class="p-button-small p-button-rounded p-button-danger" (click)="cancelButton($event)"></button>
                <button pButton pRipple label="Save" class="p-button-small p-button-rounded p-button-success" (click)="saveButton($event)"></button>
            </span>
        </div>

    </p-dialog>

    <p-table *ngIf="loggedIn" [value]="noteBuffer.recordsOut" [lazy]="true" (onLazyLoad)="loadMoreNotes($event)"
          [scrollable]="true" [virtualScroll]="true" dataKey="noteId"  [virtualRowHeight]="noteCardHeight" scrollHeight="flex" [totalRecords]="noteBuffer.totalRecords">
          <ng-template   pTemplate="caption">
            <div class="grid flex-row-reverse">
                <div class="col-2 flex align-items-center justify-content-between">

                    <button *ngIf="allowNew" pButton pRipple title="Create Note" icon="pi pi-plus" class="p-button-rounded p-button-sm" (click)="createNewNote($event)"></button>

                </div>
                <div class="flex col-3 align-items-center justify-content-left">
                    <p-checkbox inputId="showObsolete" [(ngModel)]="showObsolete" [binary]="true" (onChange)="updateStatus(null)"></p-checkbox>                        
                    <label for="showObsolete">Show Obsolete?</label> 
                </div>
                <div class="flex col-5 align-items-center justify-content-left">
                    {{title}}
                </div>
            </div>
          </ng-template>

          <ng-template pTemplate="body" let-note>
            <tr *ngIf="(note.isObsolete && showObsolete) || !note.isObsolete" [style]="{'min-height': noteCardHeightInPixels, 'height': noteCardHeightInPixels, 'max-height': noteCardHeightInPixels}">
                <td style="min-width: 100%;" class="flex w12">
                    <div class="grid flex w12" style="width:100%">

                        <div class="col-9 flex">
                            <div class="grid flex">
                                <div class="col-4 align-items-center flex">
                                    <span> <b>{{note.noteDate | date: 'shortDate'}}</b></span>
                                </div>
                                <div class="col-6 align-items-center flex">
                                    <div *ngIf="showReferenceLinks" class="flex align-items-center">
                                        <span>Ref: <b>{{note.referenceId}}</b></span>
                                        <span><button pButton pRipple type="button" icon="pi pi-external-link" class="p-button-text" (click)="loadReference(note)"></button></span>
                                    </div>
                                </div>
                                <div class="col-12  align-items-center flex">
                                    <span *ngIf="!getForCurrentUser">Created By: {{note.createdBy?.firstName}} {{note.createdBy?.lastName}}</span>
                                </div>

                                <div class="col-12  align-items-center flex">
                                    {{util.shortenDescription(note.shortDesc,45)}}
                                </div>
                            </div>
                        </div>
                        <div class="col-1  align-items-center flex">
                            <p-dialog appendTo="body" header="{{note.shortDesc}}" [(visible)]="noteDialogs[note.noteId]"
                            [modal]="false" [dismissableMask]="true" [closeOnEscape]="true"
                            [closable]="true" [style]="{width: '50%'}">
                                <div class="grid flex w12">
                                    <div class="field col-12 mt-3" >
                                        <span *ngIf="editMode" class="p-float-label">
                                            <input id="shortDesc" type="text" pInputText [(ngModel)]="note.shortDesc" >
                                            <label for="shortDesc">Short Description</label>
                                        </span>
                                    </div>

                                    <div class="col-3 flex align-items-center">
                                        <span class="p-float-label">
                                            <p-dropdown id="noteType" [(ngModel)]="note.noteType" [options]="noteTypes" [disabled]="!editMode" optionLabel="typeName"
                                                        [showClear]="true" [autoDisplayFirst]="false" dataKey="id" ></p-dropdown>
                                            <label for="noteType">Type</label>                    
                                        </span>
                                    </div>
                                    <div class="flex col-6 align-items-center">
                                        <!-- <span class="p-float-label"> -->
                                            <p-checkbox inputId="obsoleteCheck" [(ngModel)]="noteObsolete[note.noteId]" [binary]="true" [disabled]="!editMode"></p-checkbox>                        
                                            <label for="obsoleteCheck">Obsolete?</label> 
                                        <!-- </span>   -->
                                    </div>
                                    <div class="field col-3">
                                    </div>

                                    <div class="field col-12">
                                        <p-editor *ngIf="noteDialogs[note.noteId]" [(ngModel)]="note.noteText" [style]="{'height':'320px'}" [readonly]="!editMode">
                                            <ng-template *ngIf="!editMode" pTemplate="header">
                                                <span><b>{{note.noteType.typeName}}</b> -
                                                    <span *ngIf="note.referenceType.code == 1">Encounter # <b>{{note.referenceId}}</b> -</span>
                                                    Created by <b>{{note.createdBy.firstName}} {{note.createdBy.lastName}}</b> on  <b>{{note.noteDate | date: 'shortDate'}}</b></span>
                                                <span *ngIf="( note.noteDate|date:'yMMdd' ) != ( convertToLocal( note.timeStart )|date:'yMMdd' )" style="color:darkred;font-weight:bold;">- (Modified: {{convertToLocal(note.timeStart) |date:'MM/dd/yy HH:mm:ss'}})</span>
                                            </ng-template>
                                        </p-editor>
                                    </div>
                                    <div *ngIf="editMode" class="col-12 flex align-items-center justify-content-center">
                                        <span class="p-buttonset">
                                            <button pButton pRipple label="Cancel" class="p-button-small p-button-rounded p-button-danger" (click)="cancelEdit(note)"></button>
                                            <button pButton pRipple label="Save" class="p-button-small p-button-rounded p-button-success" (click)="commitEdit(note)"></button>
                                        </span>
                                    </div>
                                </div>
                            </p-dialog>
                            <button pButton pRipple title="View Note" icon="pi pi-search" class="p-button-text" (click)="showNoteDialog(note.noteId)" [pTooltip]="util.shortenDescription(note.noteText,255,true)"></button>
                        </div>

                        <div class="col-1 align-items-center flex">
                            <button pButton pRipple title="Edit Note" icon="pi pi-pencil" class="p-button-text" *ngIf="allowEdit || ( ( note.noteDate|date:'yMMddHHmmss' ) >= ( oneDayAgo|date:'yMMddHHmmss' ) )" (click)="editNote($event,note)" ></button>
                        </div>
                    </div>
                </td>
            </tr>
          </ng-template>
          <ng-template pTemplate="loadingbody">
            <tr [style]="{'min-height': noteCardHeightInPixels, 'height': noteCardHeightInPixels, 'max-height': noteCardHeightInPixels}">
              <td>
                <div class="flex justify-content-center" style="width: 100%;">
                  <p-progressSpinner strokeWidth="8" animationDuration=".5s"></p-progressSpinner>
                </div>
              </td>
            </tr>
          </ng-template>
        </p-table>
