import { formatDate } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { TreeNode } from 'primeng/api';
import { TcemsService } from 'src/app/api/tcems.service';
import { ClientReportDescriptor, SystemReportDescriptor } from 'src/app/model/reports/descriptor';
import { AdHocClientReport, AnnualClientReport, ClientReport, ClientReports, MonthlyClientReport, QuarterlyClientReport, SystemReport } from 'src/app/model/reports/report_spec';

@Component({
  selector: 'app-reportviewer',
  templateUrl: './reportviewer.component.html',
  styleUrls: ['./reportviewer.component.scss']
})
export class ReportviewerComponent implements OnInit {

  // monthlyReports: MonthlyClientReport[];
  // quarterlyReports: QuarterlyClientReport[];
  // annualReports: AnnualClientReport[];
  // adHocReports: AdHocClientReport[];

  // years: TreeNode[] = [];

  clientReportsNode: TreeNode = 
  {
    label:"Client Reports", 
    expandedIcon: "pi pi-folder-open", 
    collapsedIcon: "pi pi-folder",
    children:[] 
  };

  systemReportsNode: TreeNode = 
  {
    label:"System Reports", 
    expandedIcon: "pi pi-folder-open", 
    collapsedIcon: "pi pi-folder",
    children:[]
  };
  reportTree: TreeNode[] = 
  [
    this.clientReportsNode,
    this.systemReportsNode
  ];

  selectedReports: TreeNode[];

  constructor(private tcems: TcemsService) { }

  // private mapFolderToTreeNode(folder: Folder) : TreeNode
  // {
  //   return  {label:folder.name,
  //             expandedIcon: "pi pi-folder-open", 
  //             collapsedIcon: "pi pi-folder", 
  //             data: folder,
  //             children: folder.subFolders?.map(f => this.mapFolderToTreeNode(f))};
  // }

  ngOnInit(): void 
  {
    let monthMap = ["January", "February", "March","April","May","June","July","August","September","October","November","December"];
    let monthOrder = {};
    for (var i=0; i<monthMap.length; i++)
      monthOrder[monthMap[i]] = i;

    //Set up system folders
    this.tcems.getSystemReportDescriptors().toPromise().then
    (
      result =>
      {
        let yearSet: Set<number> = new Set(result.map(r => r.year));

        this.systemReportsNode.children = [...yearSet].map
        (
          y => 
          {
            let reportsInYear: SystemReportDescriptor[] = result.filter(report => report.year == y)
            let reportDays: Set<string> = new Set(reportsInYear.map(r => formatDate(r.runDate,"MM-dd-yyy", 'en-US')));
            return {
              label:y.toString(), 
              expandedIcon: "pi pi-folder-open", 
              collapsedIcon: "pi pi-folder", 
              children: [...reportDays].map
              (
                rd=> 
                {
                  return {
                    label:rd,
                    expandedIcon: "pi pi-folder-open", 
                    collapsedIcon: "pi pi-folder",
                    children: reportsInYear.filter(r => formatDate(r.runDate,"MM-dd-yyy", 'en-US') == rd).map
                    (
                      sr =>
                      {
                        return {
                          label:sr.reportName,
                          data:sr,
                          collapsedIcon: sr.contentType == "application/pdf" ? "pi pi-file-pdf" : sr.contentType == "application/xlsx" ? "pi pi-file-excel" : "pi pi-file"
                        }
                        
                      }
                    )
                  }
                }
              )
            }
          }
        )
      }
    )

    //And the client report folders
    this.tcems.getAllClientReportDescriptors().toPromise().then
    (
        result =>
        {
          let yearSet: Set<number> = new Set(result.map(r => r.year));
          let months = {};
          let quarters = {};         

          yearSet.forEach
          (
            y =>
            {
              //Do months
              let m = months[y.toString()];
              if (m == null)
              {
                m = new Set<string>();
                months[y.toString()] = m;
              }
              
              let monthSet = new Set(result.filter(r => r.year == y && r.monthlyReport).map(r => r.month));

              monthSet.forEach
              (
                month =>
                {
                  m.add(month);
                }
              );

              //And quarters
              let q = quarters[y.toString()];
              if (q == null)
              {
                q = new Set<string>();
                quarters[y.toString()] = q;
              }
              let quarterSet = new Set(result.filter(r => r.year == y && r.quarterlyReport).map(r => r.quarter));

              quarterSet.forEach
              (
                quarter =>
                {
                  q.add(quarter);
                }
              );

            }
          )
          
          
          //With our years in place, we can begin to build our treenodes
          this.clientReportsNode.children = [...yearSet].map
          (
            y => 
            {

              return { 
                label: y.toString(), 
                data:y, 
                expandedIcon: "pi pi-folder-open", 
                collapsedIcon: "pi pi-folder",
                children: 
                [
                  {
                    label:"Annual Reports", 
                    expandedIcon: "pi pi-folder-open", 
                    collapsedIcon: "pi pi-folder", 
                    children: [...new Set(result.filter(crd => crd.annualReport && crd.year == y).map(crd => crd.companyId))].map
                    (
                      r => 
                      {
                        //Unique Companies
                        return {
                          label:result.find(cr => cr.companyId == r).companyName,
                          expandedIcon: "pi pi-folder-open", 
                          collapsedIcon: "pi pi-folder", 
                          children: result.filter(crd => crd.annualReport && crd.year == y && crd.companyId == r).map
                          (
                            ar => 
                            {
                              return {
                                label:ar.reportName,
                                data:ar,
                                collapsedIcon: ar.contentType == "application/pdf" ? "pi pi-file-pdf" : ar.contentType == "application/xlsx" ? "pi pi-file-excel" : "pi pi-file"
                              }
                            }
                          )

                        }
                      }
                    ).filter(r => r != null)
                  },
                  {
                    label:"Quarterly Reports", 
                    expandedIcon: "pi pi-folder-open", 
                    collapsedIcon: "pi pi-folder", 
                    children: quarters == null ? [] : [...quarters[y.toString()]].map
                    (
                      q =>
                      {
                        return {
                          label:q,
                          expandedIcon: "pi pi-folder-open", 
                          collapsedIcon: "pi pi-folder", 
                          children: [...new Set(result.filter(crd => crd.quarterlyReport && crd.year == y && crd.quarter == q).map(crd => crd.companyId))].map
                          (
                            r => 
                            {
                                return {
                                  label:result.find(cr => cr.companyId == r).companyName,
                                  expandedIcon: "pi pi-folder-open", 
                                  collapsedIcon: "pi pi-folder", 
                                  children: result.filter(crd => crd.quarterlyReport && crd.year == y && crd.quarter == q && crd.companyId == r).map
                                  (
                                    qr => 
                                    {
                                      return {
                                        label:qr.reportName,
                                        data:qr,
                                        collapsedIcon: qr.contentType == "application/pdf" ? "pi pi-file-pdf" : qr.contentType == "application/xlsx" ? "pi pi-file-excel" : "pi pi-file"
                                      }
                                    }
                                  )
                                }
                            }
                          ).filter(r => r != null)
  
                        }
                      }
                    )
                  },
                  {
                    label:"Monthly Reports", 
                    expandedIcon: "pi pi-folder-open", 
                    collapsedIcon: "pi pi-folder", 
                    children: months == null ? [] : [...months[y.toString()]].sort((a,b) => monthOrder[a] - monthOrder[b]).map
                    (
                      m =>
                      {
                        return {
                          label:m,
                          expandedIcon: "pi pi-folder-open", 
                          collapsedIcon: "pi pi-folder", 
                          children: [...new Set(result.filter(crd => crd.monthlyReport && crd.year == y && crd.month == m).map(crd => crd.companyId))].map
                          (
                            r => 
                            {
                              // let rs = Object.assign(new ClientReports(),r);
                              // if(rs.getMonthlyReportsForYear(y,m).length > 0)
                              // {
                                return {
                                  label:result.find(cr => cr.companyId == r).companyName,
                                  expandedIcon: "pi pi-folder-open", 
                                  collapsedIcon: "pi pi-folder", 
                                  children: result.filter(crd => crd.monthlyReport && crd.year == y && crd.month == m && crd.companyId == r).map
                                  (
                                    mr => 
                                    {
                                      return {
                                        label:mr.reportName,
                                        data:mr,
                                        collapsedIcon: mr.contentType == "application/pdf" ? "pi pi-file-pdf" : mr.contentType == "application/xlsx" ? "pi pi-file-excel" : "pi pi-file"
                                      }
                                    }
                                  )
                                }
                              // }
                              // else
                              // {
                              //   return null;
                              // }
                            }
                          ).filter(r => r !=null)
  
                        }
                      }
                    )
                  },
                  {
                    label:"Ad Hoc Reports", 
                    expandedIcon: "pi pi-folder-open", 
                    collapsedIcon: "pi pi-folder", 
                    children: [...new Set(result.filter(crd => crd.adHocReport && crd.year == y).map(crd => crd.companyId))].map
                    (
                      r => 
                      {
                                                 
                          return {
                            label:result.find(cr => cr.companyId == r).companyName,
                            expandedIcon: "pi pi-folder-open", 
                            collapsedIcon: "pi pi-folder", 
                            children: result.filter(crd => crd.adHocReport && crd.year == y && crd.companyId == r).map
                            (
                              ar => 
                              {
                                return {
                                  label:ar.reportName,
                                  data:ar,
                                  collapsedIcon: ar.contentType == "application/pdf" ? "pi pi-file-pdf" : ar.contentType == "application/xlsx" ? "pi pi-file-excel" : "pi pi-file"
                                }
                              }
                            )
  
                          }
                      }
                    ).filter( r=> r != null)
                  },
                ]
              }
            }
          );
        }
      );
    
    
    
    
    
    // (
    //   d =>
    //   {
    //     //group by year,
    //     //Then by monthly, quarterly, annual and adhoc
    //     //then by sub field (if monthly/quartlerly)
    //     this.clientReportsNode.children = folders.map(f => this.mapFolderToTreeNode(f));
    //   }
    // )
    //Get All reports



    // this.tcems.getAllSystemReports().toPromise().then
    // (
    //   result =>
    //   {
    //     let yearSet: Set<number> = new Set(result.map(r => r.year));

    //     this.systemReportsNode.children = [...yearSet].map
    //     (
    //       y => 
    //       {
    //         let reportsInYear: SystemReport[] = result.filter(report => report.year == y)
    //         let reportDays: Set<string> = new Set(reportsInYear.map(r => formatDate(r.reportDate,"MM-dd-yyy", 'en-US')));
    //         return {
    //           label:y.toString(), 
    //           expandedIcon: "pi pi-folder-open", 
    //           collapsedIcon: "pi pi-folder", 
    //           children: [...reportDays].map
    //           (
    //             rd=> 
    //             {
    //               return {
    //                 label:rd,
    //                 expandedIcon: "pi pi-folder-open", 
    //                 collapsedIcon: "pi pi-folder", 
    //                 children: reportsInYear.filter(r => formatDate(r.reportDate,"MM-dd-yyy", 'en-US') == rd).map
    //                 (
    //                   sr =>
    //                   {
    //                     return {
    //                       label:sr.storageSpec.name,
    //                       data:sr,
    //                       collapsedIcon: sr.reportExecutionSpec.outputFormat.fileExtension == "pdf" ? "pi pi-file-pdf" : sr.reportExecutionSpec.outputFormat.fileExtension == "xlsx" ? "pi pi-file-excel" : "pi pi-file"
    //                     }
                        
    //                   }
    //                 )
    //               }
    //             }
    //           )
    //         }
    //       }
    //     )
    //   }
    // );

    // this.tcems.getAllClientReports().toPromise().then
    // (
    //   result =>
    //   {
    //     //Parse all of the years out of the list of client reports
    //     let yearSet = new Set<number>();
    //     let months = {};
    //     let quarters = {};

    //     result.forEach
    //     (
    //       r => 
    //       {
    //         let cr = Object.assign(new ClientReports(), r);
    //         cr.years.forEach
    //         (
    //           y => 
    //           {
    //             yearSet.add(y);

    //             //Do months
    //             let m = months[y.toString()];
    //             if (m == null)
    //             {
    //               m = new Set<string>();
    //               months[y.toString()] = m;
    //             }

    //             cr.getMonthsForYear(y).forEach
    //             (
    //               month =>
    //               {
    //                 m.add(month);
    //               }
    //             );

    //             //And quarters
    //             let q = quarters[y.toString()];
    //             if (q == null)
    //             {
    //               q = new Set<string>();
    //               quarters[y.toString()] = q;
    //             }

    //             cr.getQuartersForYear(y).forEach
    //             (
    //               quarter =>
    //               {
    //                 q.add(quarter);
    //               }
    //             );

    //           }
    //         );
            

    //       }
    //     );
        
    //     //With our years in place, we can begin to build our treenodes
    //     this.clientReportsNode.children = [...yearSet].map
    //     (
    //       y => 
    //       {
            
    //         return { 
    //           label: y.toString(), 
    //           data:y, 
    //           expandedIcon: "pi pi-folder-open", 
    //           collapsedIcon: "pi pi-folder",
    //           children: 
    //           [
    //             {
    //               label:"Annual Reports", 
    //               expandedIcon: "pi pi-folder-open", 
    //               collapsedIcon: "pi pi-folder", 
    //               children: result.map
    //               (
    //                 r => 
    //                 {
    //                   let rs = Object.assign(new ClientReports(),r);
    //                   if(rs.getAnnualReportsForYear(y).length > 0)
    //                   {
    //                     return {
    //                       label:rs.companyName,
    //                       expandedIcon: "pi pi-folder-open", 
    //                       collapsedIcon: "pi pi-folder", 
    //                       children: rs.getAnnualReportsForYear(y).map
    //                       (
    //                         ar => 
    //                         {
    //                           return {
    //                             label:ar.storageSpec.name,
    //                             data:ar,
    //                             collapsedIcon: ar.reportExecutionSpec.outputFormat.fileExtension == "pdf" ? "pi pi-file-pdf" : ar.reportExecutionSpec.outputFormat.fileExtension == "xlsx" ? "pi pi-file-excel" : "pi pi-file"
    //                           }
    //                         }
    //                       )

    //                     }
    //                   }
    //                   else
    //                   {
    //                     return null;
    //                   }
    //                 }
    //               ).filter(r => r != null)
    //             },
    //             {
    //               label:"Quarterly Reports", 
    //               expandedIcon: "pi pi-folder-open", 
    //               collapsedIcon: "pi pi-folder", 
    //               children: quarters == null ? [] : [...quarters[y.toString()]].map
    //               (
    //                 q =>
    //                 {
    //                   return {
    //                     label:q,
    //                     expandedIcon: "pi pi-folder-open", 
    //                     collapsedIcon: "pi pi-folder", 
    //                     children: result.map
    //                     (
    //                       r => 
    //                       {
    //                         let rs = Object.assign(new ClientReports(),r);
    //                         if(rs.getQuarterlyReportsForYear(y,q).length >0)
    //                         {
    //                           return {
    //                             label:rs.companyName,
    //                             expandedIcon: "pi pi-folder-open", 
    //                             collapsedIcon: "pi pi-folder", 
    //                             children: rs.getQuarterlyReportsForYear(y,q).map
    //                             (
    //                               qr => 
    //                               {
    //                                 return {
    //                                   label:qr.storageSpec.name,
    //                                   data:qr,
    //                                   collapsedIcon: qr.reportExecutionSpec.outputFormat.fileExtension == "pdf" ? "pi pi-file-pdf" : qr.reportExecutionSpec.outputFormat.fileExtension == "xlsx" ? "pi pi-file-excel" : "pi pi-file"
    //                                 }
    //                               }
    //                             )
    //                           }
    //                         }
    //                         else
    //                         {
    //                           return null;
    //                         }
    //                       }
    //                     ).filter(r => r != null)

    //                   }
    //                 }
    //               )
    //             },
    //             {
    //               label:"Monthly Reports", 
    //               expandedIcon: "pi pi-folder-open", 
    //               collapsedIcon: "pi pi-folder", 
    //               children: months == null ? [] : [...months[y.toString()]].map
    //               (
    //                 m =>
    //                 {
    //                   return {
    //                     label:m,
    //                     expandedIcon: "pi pi-folder-open", 
    //                     collapsedIcon: "pi pi-folder", 
    //                     children: result.map
    //                     (
    //                       r => 
    //                       {
    //                         let rs = Object.assign(new ClientReports(),r);
    //                         if(rs.getMonthlyReportsForYear(y,m).length > 0)
    //                         {
    //                           return {
    //                             label:rs.companyName,
    //                             expandedIcon: "pi pi-folder-open", 
    //                             collapsedIcon: "pi pi-folder", 
    //                             children: rs.getMonthlyReportsForYear(y,m).map
    //                             (
    //                               mr => 
    //                               {
    //                                 return {
    //                                   label:mr.storageSpec.name,
    //                                   data:mr,
    //                                   collapsedIcon: mr.reportExecutionSpec.outputFormat.fileExtension == "pdf" ? "pi pi-file-pdf" : mr.reportExecutionSpec.outputFormat.fileExtension == "xlsx" ? "pi pi-file-excel" : "pi pi-file"
    //                                 }
    //                               }
    //                             )
    //                           }
    //                         }
    //                         else
    //                         {
    //                           return null;
    //                         }
    //                       }
    //                     ).filter(r => r !=null)

    //                   }
    //                 }
    //               )
    //             },
    //             {
    //               label:"Ad Hoc Reports", 
    //               expandedIcon: "pi pi-folder-open", 
    //               collapsedIcon: "pi pi-folder", 
    //               children: result.map
    //               (
    //                 r => 
    //                 {
    //                   let rs = Object.assign(new ClientReports(),r);
    //                   if(rs.getAdHocReportsForYear(y).length > 0)
    //                   {
                        
    //                     return {
    //                       label:rs.companyName,
    //                       expandedIcon: "pi pi-folder-open", 
    //                       collapsedIcon: "pi pi-folder", 
    //                       children: rs.getAdHocReportsForYear(y).map
    //                       (
    //                         ar => 
    //                         {
    //                           return {
    //                             label:ar.storageSpec.name,
    //                             data:ar,
    //                             collapsedIcon: ar.reportExecutionSpec.outputFormat.fileExtension == "pdf" ? "pi pi-file-pdf" : ar.reportExecutionSpec.outputFormat.fileExtension == "xlsx" ? "pi pi-fild-excel" : "pi pi-file"
    //                           }
    //                         }
    //                       )

    //                     }
    //                   }
    //                   else
    //                   {
    //                     return null;
    //                   }
    //                 }
    //               ).filter( r=> r != null)
    //             },
    //           ]
    //         }
    //       }
    //     );
    //   }
    // );

  }


  private getRootNode (node: TreeNode) : TreeNode
  {
    if(node.parent == null)
    {
      return node;
    }
    else
    {
      return this.getRootNode(node.parent);
    }
  }


  //recursive child node extraction
  extractSelectedReports(node: TreeNode): ClientReportDescriptor[]
  {
    let children = node.children;
    if(children == null || children.length == 0)
    {
      return [node.data];
    }
    else
    {
      let reports: ClientReportDescriptor[] = [];
      children.forEach(c => reports = reports.concat(this.extractSelectedReports(c)));
      return reports;
    }
  }

  // nodeExpanded(event)
  // {
  //   console.log(event);
  //   let isClient: boolean = this.getRootNode(event.node).label== "Client Reports";
  //   //Check the children of this node. If they are leaf nodes, then  load up all thhe reports for each one
  //   if(event.node.children != null && event.node.children.length > 0)
  //   event.node.children.foreach
  //   (
  //     (c: TreeNode) =>
  //     {
  //       //Check the children of each of these nodes
  //       if(c.children == null || c.children.length == 0)
  //       {
  //         //These are the leaf nodes. Go get any reports that might be sitting here
  //         c.children.forEach
  //         (
  //           ln => 
  //           {
  //             // if(isClient)
  //             // {
  //             //   this.tcems.getClientReportsAtFolder(ln.data)
  //           }
  //         )
  //       }
  //     }
  //   )
  // }

  // nodeSelect(event)
  // {
  //   console.log(event);
  //   console.log(this.selectedReports);
  //   console.log(this.selectedReports.map(sr => this.extractSelectedReports(sr)));
  // }
  // nodeUnselect(event)
  // {
  //   console.log(event);
  //   console.log(this.selectedReports);
  //   console.log(this.selectedReports.map(sr => this.extractSelectedReports(sr)));
  // }

  downloadSelectedReports()
  {
    let reports: ClientReportDescriptor[] = [];
    this.selectedReports.forEach(sr => reports = reports.concat(this.extractSelectedReports(sr)));
    let reportsToDownload = reports.map(r => r.path);

    this.tcems.downloadReports(reportsToDownload)
    .then
    (
      blob => {
        const a = document.createElement('a')
        const objectUrl = URL.createObjectURL(blob)
        a.href = objectUrl
        a.download = "reports.zip";
        a.click();
        URL.revokeObjectURL(objectUrl);
      }
    );
  }

  
  // "label": "Pictures",
  //           "data": "Pictures Folder",
  //           "expandedIcon": "pi pi-folder-open",
  //           "collapsedIcon": "pi pi-folder",
  //           "children": [
  //               {"label": "barcelona.jpg", "icon": "pi pi-image", "data": "Barcelona Photo"},
  //               {"label": "logo.jpg", "icon": "pi pi-file", "data": "PrimeFaces Logo"},
  //               {"label": "prim

  //Monthly Report Runs
  //Quarterly Report Runs
  //Annual Report Runs
  //Ad Hoc Report Runs
  //System Reports 

  // selectReportsForCompany(company: Company)
  // {
  //   this.displayReportsSidebar = true;
  //   this.tcems.getReportsForClient(company.companyId).toPromise()
  //   .then
  //   (
  //     result =>
  //       {
  //         let cr: ClientReports = Object.assign(new ClientReports(),result);
  //         let years: number[] = cr.years;

  //         this.companyReportMenu =  years.map<MenuItem>
  //         (
  //           y => 
  //           {
  //             return {
  //                 label: y.toString(),
  //                 items:
  //                 [
  //                   {
  //                     label: 'Annual Reports',
  //                     command: (event) => {this.companyReports = cr.getAnnualReportsForYear(y)}
  //                   },
  //                   {
  //                     label: 'Quarterly Reports',
  //                     ... cr.getQuartersForYear(y).length > 0 && {items: cr.getQuartersForYear(y)
  //                           .map
  //                           (
  //                             q =>
  //                             {
  //                               return {
  //                                 label: q,
  //                                 command: (event) => {this.companyReports = cr.getQuarterlyReportsForYear(y, q)}
                                 
  //                               }
  //                             }
  //                           )}
  //                   },
  //                   {
  //                     label: 'Monthly Reports',
  //                     ... cr.getMonthsForYear(y).length> 0 && {items: cr.getMonthsForYear(y)
  //                           .map
  //                           (
  //                             m =>
  //                             {
  //                               return {
  //                                 label: m,
  //                                 command: (event) => {this.companyReports = cr.getMonthlyReportsForYear(y, m)}
                                 
  //                               }
  //                             }
  //                           )}
  //                   },
  //                   {
  //                     label: 'AdHoc Reports',
  //                     command: (event) => {this.companyReports = cr.getAdHocReportsForYear(y)}

  //                   }
  //                 ]
  //               }
  //             }
  //         );
  //       }
  //   )
  // }

  // downloadReport(report: ClientReport)
  // {
  //   this.tcems.downloadReport(report.storageSpec)
  //   .then
  //   (
  //     blob => {
  //       const a = document.createElement('a')
  //       const objectUrl = URL.createObjectURL(blob)
  //       a.href = objectUrl
  //       a.download = report.storageSpec.name + "." + report.storageSpec.fileExtension;
  //       a.click();
  //       URL.revokeObjectURL(objectUrl);
  //     }
  //   );
  // }


}
