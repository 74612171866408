export class EncounterFax
{
    id: number;
    encounterId:number;
    attn: string;
    from: string;
    subject: string;
    notes: string;
    faxNumber: string;
    status: string;
    totalPages: number;
    pagesSent: number;
    retries: 1;
    faxSendTime: Date;
    faxCompleteTime: Date;
    attachmentIds: string[];
}

export class EncounterFaxWrite
{
    attn: string;
    from: string;
    subject: string;
    notes:string;
    faxNumber:string;
    attachmentIds: string[];
}
