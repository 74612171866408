import { Input } from '@angular/core';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-search-button',
  templateUrl: './search-button.component.html',
  styleUrls: ['./search-button.component.css']
})
export class SearchButtonComponent implements OnInit 
{

  @Input() searchFunction : (ags: void) => Promise<void>;
  @Input() buttonText : string = 'Search';
  @Input() searchingIcon : string = 'pi pi-spin pi-spinner';

  searching: boolean = false;
  searchIcon: string = '';

  constructor() { }

  ngOnInit(): void 
  {
    
  }

  search() : void
  {
    this.searching = true;
    this.searchIcon = this.searchingIcon;
    this.searchFunction().then
    (
      result =>
      {
        this.searchIcon = '';
        this.searching = false;
      }
    )
    .catch
    (
      e => 
      {
        this.searchIcon = '';
        this.searching = false;
      }
    )
    ;
  }

}
