import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MenuItem, MessageService } from 'primeng/api';
import { TcemsService } from 'src/app/api/tcems.service';
import { Invoice, InvoiceInsert, InvoiceRule, InvoiceUpdate } from 'src/app/model/finance/invoice';
import { RewardRule } from 'src/app/model/finance/reward_rule';
import { Company } from 'src/app/model/support/company';
import { CompanyHierarchy } from 'src/app/model/support/company_hierarchy';
import { WhiteLabelProgram } from 'src/app/model/support/white_label';
import { TcemsUtilitiesService } from 'src/app/util/tcems-utilities.service';
import { FormGroup, FormControl } from '@angular/forms';
import {ConfirmationService} from 'primeng/api';

import { catchError, map, mergeAll, mergeMap } from 'rxjs/operators';
import { CompanyContact } from 'src/app/model/support/company_contact';
import { OverlayPanel } from 'primeng/overlaypanel';
import { combineLatest, Observable, of } from 'rxjs';
import { ClientReport, ClientReports } from 'src/app/model/reports/report_spec';

@Component({
  selector: 'app-company-manager',
  templateUrl: './company-manager.component.html',
  styleUrls: ['./company-manager.component.scss']
})
export class CompanyManagerComponent implements OnInit {

  @Input() rootCompany;

  companies: Company[] = [];
  tempCompanies: Company[] = [];
  selectedCompanies: Company[] = [];

  sortFields = [{field:"companyName", order:1}];
  filters = {active: [{value:true,matchMode:'contains',operator:'and'}]};

  loading: boolean = false;
  editingNewRow: boolean = false;

  //Subscriber counts
  activeSubs = {};

  //sidebar visibility
  displayContactsSidebar = false;
  displayWhiteLabelSidebar = false;
  displayHDSidebar = false;
  displayRewardRuleSidebar = false;
  displayInvoiceRuleSidebar = false;
  displayReportsSidebar = false;
  displayChildCompanySidebar = false;

  //Sidebar model objects
  activeSidebarCompany: Company;
  rewardRules: RewardRule[];
  selectedRewardRule: RewardRule;
  tempRewardRule: RewardRule;

  invoiceRules: InvoiceRule[];
  selectedInvoiceRule: InvoiceRule;
  tempInvoiceRule: InvoiceRule;

  hierarchyDetail: CompanyHierarchy;
  tempHierarchyDetail: CompanyHierarchy;

  whiteLabelDetail: WhiteLabelProgram;
  tempWhiteLabelDetail: WhiteLabelProgram;

  companyContacts: CompanyContact[];
  selectedCompanyContact: CompanyContact;
  tempCompanyContact: CompanyContact;

  companyReportMenu: MenuItem[];
  companyReports: ClientReport[];

  tiers: number[] = Array.from(Array(8).keys());

  //Invoice Overlay model objects
  invoiceFrom: Date;
  invoiceTo: Date;
  invoiceDate: Date;
  percentComplete: number = 0;
  creatingInvoices: boolean = false;
  

  // //RewardRule Form Controls
  // rr_fc_RuleName: FormControl = new FormControl('');
  // rr_fc_Active: FormControl = new FormControl('');

  // rr_fg: FormGroup = new FormGroup
  // ({
  //   ruleName: this.rr_fc_RuleName,
  //   active: this.rr_fc_Active
  // });


  @ViewChild('invoicesOverlay') invoiceOverlay: OverlayPanel;

  generateInvoicesAction: MenuItem =
  {
    label: 'Generate Invoice(s)',
    icon: 'pi pi-money-bill',
    disabled: true,
    command: (event) => {console.log(event); this.showInvoiceOverlay(event);}
  }

  actions: MenuItem[] = [this.generateInvoicesAction];

  constructor(private tcems:TcemsService, public util: TcemsUtilitiesService, private messageService: MessageService, private confirmationService: ConfirmationService) 
  { 
  }


  loadCompanies(): void
  {
    
    this.loading = true;
    var companies$ : Observable<Company[]>;
    
    
    if(this.rootCompany == null)
      companies$ = this.tcems.getRootCompanies();
    else
      companies$ = this.tcems.getChildCompanies(this.rootCompany);

    //Load up the subscriber counts
    companies$
    .pipe
    (
      mergeAll(),
      mergeMap(
        c => 
          combineLatest([of(c),this.tcems.getSubscriberCount(c.companyId)])
      )
    )
    .subscribe
    ({
      next: (result) =>
      {
        if(result !=null && result[0] != null)
          this.activeSubs[result[0].companyId] = result[1];
      },
      error: () => {null},
      complete: () =>
      {
        this.updateContextMenu(null);
      }

    })

    companies$
    .subscribe
    (
      results =>
      {
        if(results != null && results.length > 0)
        {
          this.companies = results.map(c => Object.assign(new Company(), c));
          //Make sure each reward rule and invoice rule is instantiated
          this.companies.forEach
          (
            c=>
            {
              c.rewardRules = c.rewardRules?.map(rr => Object.assign(new RewardRule(), rr));
              c.invoiceRules = c.invoiceRules?.map(ir => Object.assign(new InvoiceRule(),ir));
              c.whiteLabelDetail = Object.assign(new WhiteLabelProgram(), c.whiteLabelDetail);
              c.hierarchyDetail = Object.assign(new CompanyHierarchy(),c.hierarchyDetail );
            }
          )
          this.loading = false;
        }
      }
      );
      // ,
      // e =>
      // {
      //   console.log(e);
      //   this.util.displayError("Couldn't load Companies",e);
      //   this.loading = false;
      // }
  }

  ngOnInit(): void 
  {
    this.loadCompanies();
    
  
    // //Connect form listener for reward rules
    // this.rr_fg.valueChanges
    //   .subscribe
    //   (
    //     value => this.saveRewardRule(value)
    //   )
  


  }


  ////////////////////////
  //Company Methods
  ///////////////////////


  updateContextMenu(event: any)
  {
    //Only show the invoice action if we have companies selected and 
    //if they all have eeCounts calculated
    var companiesSelected = this.selectedCompanies != null && this.selectedCompanies.length > 0;
    var selectedCoCounts = this.selectedCompanies.map(c => this.activeSubs[c.companyId]).filter(c => c != null);
    

    this.generateInvoicesAction.disabled = this.selectedCompanies == null 
                                            || this.selectedCompanies.length == 0 
                                            || selectedCoCounts.length != this.selectedCompanies.length;
  }

  newCompany(table)
  {
    let newCo = new Company();
    newCo.active = true;

    this.companies = [...this.companies, newCo];
    this.editingNewRow = true;
    table.initRowEdit(this.companies[this.companies.length-1]);
  }

  onRowEditInit(c: Company) 
  {
    this.tempCompanies[c.companyId] = Object.assign(new Company(),c);
  }


  onRowEditSave(c: Company, index: number, table) 
  {
    //If the company id doesn't exist yet, this is a new company
    if(c.companyId == null)
    {
      //Insert new company
      
      let companyPromise = this.rootCompany == null ? this.tcems.addRootCompany(c.asInsert()).toPromise() : this.tcems.addChildCompany(this.rootCompany,c.asInsert()).toPromise();
      companyPromise.then
      (
        result =>
        {
          // //Add the incoming id to the company in the companies list
          // // this.companies[index].companyId = result.companyId;
          // let newCo:Company = Object.assign(new Company(), result);
          // newCo.rewardRules = newCo.rewardRules?.map(rr => Object.assign(new RewardRule(), rr));
          // newCo.invoiceRules = newCo.invoiceRules?.map(ir => Object.assign(new InvoiceRule(),ir));
          // newCo.whiteLabelDetail = Object.assign(new WhiteLabelProgram(), newCo.whiteLabelDetail);
          // newCo.hierarchyDetail = Object.assign(new CompanyHierarchy(),newCo.hierarchyDetail );
          // this.companies[index] = newCo;
          this.messageService.add({key: 'companyUpdate', severity:'success', summary: 'Update Successful', detail: 'Compaany Saved Successfully'});          
          this.editingNewRow = false;
          this.loadCompanies();
        }
      )
      .catch
      (
        error =>
        {
          console.log(error);
          this.messageService.add({key: 'companyUpdate', severity:'error', summary: 'Company Save Failed', detail: error.error});
          table.initRowEdit(c);
        }
      )
    }
    else
    {
      //commit existing company
      this.tcems.updateCompany(c.companyId,c.asUpdate()).toPromise()
      .then
      (
        result =>
        {
          delete this.tempCompanies[c.companyId];
          this.messageService.add({key: 'companyUpdate', severity:'success', summary: 'Update Successful', detail: 'Compaany Saved Successfully'});
        }

      )
      .catch
      (
        error =>
        {
          this.messageService.add({key: 'companyUpdate', severity:'error', summary: 'Update Failed', detail: 'Company Not Saved Successfully'});
        }
      )
    }
  }

  onRowEditCancel(c: Company, index: number) 
  {
    //If this one is new, just delete it from our list and move on
    if(c.companyId == null)
    {
      delete this.companies[index];
      this.companies = [...this.companies];
      this.editingNewRow = false;
    }
    else
    {
      this.companies[index] = this.tempCompanies[c.companyId];
      delete this.tempCompanies[c.companyId];
    }
  }
  
  listSorted(event)
  {
    console.log(event);
  }

  // rewardRuleSelected(event)
  // {
  //   console.log(event);
  //   //load our form elements
  //   this.rr_fc_Active.setValue(this.selectedRewardRule.active);
  //   this.rr_fc_RuleName.setValue(this.selectedRewardRule.ruleName);
  // }




  ///////////////////////////////
  //Reward Rule Sidebar Stuff
  ///////////////////////////////
  selectRewardRule(event)
  {
    this.tempRewardRule=Object.assign(new RewardRule(), this.selectedRewardRule);
  }


  unselectRewardRule(event)
  {
    Object.assign(event.data,this.tempRewardRule);
    this.tempRewardRule = null;
  }

  

  //Reward Rule Form Update
  saveRewardRule(rr: RewardRule)
  {

    if(rr.ruleId == null)
    {
      //Make sure we have a checking account selected
      if(rr.paidThroughAccount == null)
      {
        this.messageService.add({key: 'rrUpdate', severity:'error', summary: 'No Account Selected', detail: 'You must select an account for this rule to pay rewards out of.'});
        return;
      }

      //new
      this.tcems.createRewardRule(Object.assign(new RewardRule(),rr).asInsert()).toPromise()
      .then
      (
        result =>
        {
          this.messageService.add({key: 'rrUpdate', severity:'success', summary: 'Success!', detail: 'Reward Rule Created'});
          let newRule = Object.assign(new RewardRule(), result);
          this.tempRewardRule = newRule;
          this.selectedRewardRule = newRule;
          this.activeSidebarCompany.rewardRules = [...this.activeSidebarCompany.rewardRules, newRule];
          this.rewardRules = this.activeSidebarCompany.rewardRules;
        }
      )
      .catch
      (
        error =>
        {
          console.log(error);
          this.messageService.add({key: 'rrUpdate', severity:'error', summary: 'Update Failed', detail: error.error});
        }
      )
    }
    else
    {

      
      //Commit
      this.tcems.updateRewardRule(rr.ruleId,Object.assign(new RewardRule(), rr).asUpdate()).toPromise()
      .then
      (
        result =>
        {
          //toast
          this.messageService.add({key: 'rrUpdate', severity:'success', summary: 'Update Successful', detail: 'Reward Rule Saved Successfully'});
          
          //reset out our temp 
          this.tempRewardRule = Object.assign(this.tempRewardRule, result);
        }
      )
      .catch
      (
        error =>
        {
          this.messageService.add({key: 'rrUpdate', severity:'error', summary: 'Update Failed', detail: error.error});
        }
      )
    }
  }

  cancelRewardRuleUpdate()
  {
    if(this.selectedRewardRule != null)
    {
      if(this.selectedRewardRule.ruleId != null)
      {
        //Do this for rules that aren't new. We can just delete it
        Object.assign(this.selectedRewardRule,this.tempRewardRule);
      }
      this.selectedRewardRule = null;
      this.tempRewardRule = null;
    }
  }

  newRewardRule()
  {
    this.selectedRewardRule = new RewardRule();
    this.tempRewardRule = new RewardRule();
    this.selectedRewardRule.company = this.activeSidebarCompany;
    this.selectedRewardRule.active = true;
  }

  deleteRewardRule(rr: RewardRule)
  {
    this.confirmationService.confirm({
      target: event.target,
      message: 'Are you sure that you want to delete this reward rule?',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
          //Delete this guy from the db, as well as from this company
          this.tcems.deleteRewardRule(rr.ruleId).toPromise()
          .then
          (
            result =>
            {
              //Delete successful
              this.messageService.add({key: 'rrUpdate', severity:'success', summary: 'Success', detail: 'Rule Deleted'});
              this.activeSidebarCompany.rewardRules = this.activeSidebarCompany.rewardRules.filter(r => r.ruleId != rr.ruleId);
              this.rewardRules = this.rewardRules.filter(r => r.ruleId != rr.ruleId);
              this.tempRewardRule = null;
              this.selectedRewardRule = null;
            }
          )
          .catch
          (
            error =>
            {
              this.messageService.add({key: 'rrUpdate', severity:'error', summary: 'Delete Failed', detail: error.error});
            }
          )
      },
      reject: () => {
          //Do nothing... we're good.
      }
    });
  }


  ////////////////////////////////
  //Invoice Rule Sidebar Stuff
  ///////////////////////////////
  selectInvoiceRule(event)
  {
    this.tempInvoiceRule=Object.assign(new InvoiceRule(), this.selectedInvoiceRule);
  }


  unselectInvoiceRule(event)
  {
    Object.assign(event.data,this.tempInvoiceRule);
    this.tempInvoiceRule = null;
  }

  

  //Reward Rule Form Update
  saveInvoiceRule(ir: InvoiceRule)
  {

    if(ir.ruleId == null)
    {
      

      //new
      this.tcems.createInvoiceRule(Object.assign(new InvoiceRule(),ir).asInsert()).toPromise()
      .then
      (
        result =>
        {
          this.messageService.add({key: 'irUpdate', severity:'success', summary: 'Success!', detail: 'Invoice Rule Created'});
          let newRule = Object.assign(new InvoiceRule(), result);
          this.tempInvoiceRule = newRule;
          this.selectedInvoiceRule = newRule;
          this.activeSidebarCompany.invoiceRules = [...this.activeSidebarCompany.invoiceRules, newRule];
          this.invoiceRules = this.activeSidebarCompany.invoiceRules;
        }
      )
      .catch
      (
        error =>
        {
          console.log(error);
          this.messageService.add({key: 'irUpdate', severity:'error', summary: 'Update Failed', detail: error.error});
        }
      )
    }
    else
    {

      
      //Commit
      this.tcems.updateInvoiceRule(ir.ruleId,Object.assign(new InvoiceRule(), ir).asUpdate()).toPromise()
      .then
      (
        result =>
        {
          //toast
          this.messageService.add({key: 'irUpdate', severity:'success', summary: 'Update Successful', detail: 'Invoice Rule Saved Successfully'});
          
          //reset out our temp 
          this.tempInvoiceRule = Object.assign(this.tempInvoiceRule, result);
        }
      )
      .catch
      (
        error =>
        {
          this.messageService.add({key: 'irUpdate', severity:'error', summary: 'Update Failed', detail: error.error});
        }
      )
    }
  }

  cancelInvoiceRuleUpdate()
  {
    if(this.selectedInvoiceRule != null)
    {
      if(this.selectedInvoiceRule.ruleId != null)
      {
        //Do this for rules that aren't new. We can just delete it
        Object.assign(this.selectedInvoiceRule,this.tempInvoiceRule);
      }
      this.selectedInvoiceRule = null;
      this.tempInvoiceRule = null;
    }
  }

  newInvoiceRule()
  {
    this.selectedInvoiceRule = new InvoiceRule();
    this.tempInvoiceRule = new InvoiceRule();
    this.selectedInvoiceRule.company = this.activeSidebarCompany;
    this.selectedInvoiceRule.active = true;
  }

  deleteInvoiceRule(ir: InvoiceRule)
  {
    this.confirmationService.confirm({
      target: event.target,
      message: 'Are you sure that you want to delete this invoice rule?',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
          //Delete this guy from the db, as well as from this company
          this.tcems.deleteInvoiceRule(ir.ruleId).toPromise()
          .then
          (
            result =>
            {
              //Delete successful
              this.messageService.add({key: 'irUpdate', severity:'success', summary: 'Success', detail: 'Rule Deleted'});
              this.activeSidebarCompany.invoiceRules = this.activeSidebarCompany.invoiceRules.filter(r => r.ruleId != ir.ruleId);
              this.invoiceRules = this.invoiceRules.filter(r => r.ruleId != ir.ruleId);
              this.tempInvoiceRule = null;
              this.selectedInvoiceRule = null;
            }
          )
          .catch
          (
            error =>
            {
              this.messageService.add({key: 'irUpdate', severity:'error', summary: 'Delete Failed', detail: error.error});
            }
          )
      },
      reject: () => {
          //Do nothing... we're good.
      }
    });
  }

  ////////////////////////////////
  //White Label Sidebar Stuff
  ///////////////////////////////
  
  saveWhiteLabel()
  {

    //Commit
    this.tcems.updateCompanyWhiteLabelProgram(this.activeSidebarCompany.companyId,Object.assign(new WhiteLabelProgram(), this.whiteLabelDetail).asUpdate()).toPromise()
    .then
    (
      result =>
      {
        //toast
        this.messageService.add({key: 'wlpUpdate', severity:'success', summary: 'Update Successful', detail: 'White Label Program Saved Successfully'});
        
        //reset out our temp 
        this.tempWhiteLabelDetail = Object.assign(this.tempWhiteLabelDetail, result);
        this.whiteLabelDetail = Object.assign(this.whiteLabelDetail, result);
      }
    )
    .catch
    (
      error =>
      {
        this.messageService.add({key: 'wlpUpdate', severity:'error', summary: 'Update Failed', detail: error.error});
      }
    )
    
  }

  cancelWhiteLabelUpdate()
  {
    if (this.whiteLabelDetail.id == null)
    {
      //It's new, just delete it
      this.whiteLabelDetail = null;
      this.tempWhiteLabelDetail = null;
    }
    else
    {
      //Roll back
      this.whiteLabelDetail = Object.assign(this.whiteLabelDetail,this.tempWhiteLabelDetail);
    }
  }

  newWhiteLabelProgram()
  {
    this.whiteLabelDetail = new WhiteLabelProgram();
    this.tempWhiteLabelDetail = new WhiteLabelProgram();
  }

  deleteWhiteLabelProgram()
  {
    this.confirmationService.confirm({
      target: event.target,
      message: 'Are you sure that you want to delete this white label program?',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
          //Delete this guy from the db, as well as from this company
          this.tcems.deleteWhiteLabelProgram(this.activeSidebarCompany.companyId).toPromise()
          .then
          (
            result =>
            {
              //Delete successful
              this.messageService.add({key: 'wlpUpdate', severity:'success', summary: 'Success', detail: 'Program Deleted'});
              this.activeSidebarCompany.whiteLabelDetail = null;
              this.tempWhiteLabelDetail = null;
              this.whiteLabelDetail = null;
            }
          )
          .catch
          (
            error =>
            {
              this.messageService.add({key: 'wlpUpdate', severity:'error', summary: 'Delete Failed', detail: error.error});
            }
          )
      },
      reject: () => {
          //Do nothing... we're good.
      }
    });
  }

  selectWhiteLabelProgram(company: Company)
  {
    this.whiteLabelDetail = company.whiteLabelDetail;
    this.tempWhiteLabelDetail = Object.assign(new WhiteLabelProgram(),this.whiteLabelDetail);
  }


  ////////////////////////////////
  //Hierarchy Detail Stuff
  ///////////////////////////////
  
  saveHierarchy()
  {

    //Commit
    this.tcems.updateCompanyHierarchyDetails(this.activeSidebarCompany.companyId,Object.assign(new CompanyHierarchy(), this.hierarchyDetail).asUpdate()).toPromise()
    .then
    (
      result =>
      {
        //toast
        this.messageService.add({key: 'hdUpdate', severity:'success', summary: 'Update Successful', detail: 'Hierarchy Saved Successfully'});
        
        //reset out our temp 
        this.tempHierarchyDetail = Object.assign(this.tempHierarchyDetail, result);
        this.hierarchyDetail = Object.assign(this.hierarchyDetail,result);
      }
    )
    .catch
    (
      error =>
      {
        this.messageService.add({key: 'hdUpdate', severity:'error', summary: 'Update Failed', detail: error.error});
      }
    )
    
  }

  cancelHierarchyUpdate()
  {
    
    //Roll back
    this.hierarchyDetail = Object.assign(this.hierarchyDetail,this.tempHierarchyDetail);
    
  }

  selectHierarchy(company: Company)
  {
    this.hierarchyDetail = company.hierarchyDetail;
    this.tempHierarchyDetail = Object.assign(new CompanyHierarchy(),this.hierarchyDetail);
  }


  ///////////////////////////////
  //Company Contact Sidebar Stuff
  ///////////////////////////////
  selectCompanyContact(event)
  {
    this.tempCompanyContact=Object.assign(new CompanyContact(), this.selectedCompanyContact);
  }


  unselectCompanyContact(event)
  {
    Object.assign(event.data,this.tempCompanyContact);
    this.tempCompanyContact = null;
  }

  

  //Reward Rule Form Update
  saveCompanyContact(cc: CompanyContact)
  {

    if(cc.companyContactId == null)
    {
      //Make sure we have a first, last and email selected
      if(cc.firstName == null || cc.lastName == null || cc.email == null)
      {
        this.messageService.add({key: 'ccUpdate', severity:'error', summary: 'Missing Data', detail: 'Please provide a first name, last name, and email address'});
        return;
      }

      //new
      this.tcems.addCompanyContact(this.activeSidebarCompany.companyId, Object.assign(new CompanyContact(),cc).asInsert()).toPromise()
      .then
      (
        result =>
        {
          this.messageService.add({key: 'ccUpdate', severity:'success', summary: 'Success!', detail: 'Company Contact Created'});
          let newContact = Object.assign(new CompanyContact(), result);
          this.tempCompanyContact = newContact;
          this.selectedCompanyContact = newContact;
          this.activeSidebarCompany.companyContacts = [...this.activeSidebarCompany.companyContacts, newContact];
          this.companyContacts = this.activeSidebarCompany.companyContacts;
        }
      )
      .catch
      (
        error =>
        {
          console.log(error);
          this.messageService.add({key: 'ccUpdate', severity:'error', summary: 'Update Failed', detail: error.error});
        }
      )
    }
    else
    {

      
      //Commit
      this.tcems.updateCompanyContact(this.activeSidebarCompany.companyId, cc.companyContactId,Object.assign(new CompanyContact(), cc).asUpdate()).toPromise()
      .then
      (
        result =>
        {
          //toast
          this.messageService.add({key: 'ccUpdate', severity:'success', summary: 'Update Successful', detail: 'Company Contact Saved Successfully'});
          
          //reset out our temp 
          this.tempCompanyContact = Object.assign(this.tempCompanyContact, result);
        }
      )
      .catch
      (
        error =>
        {
          this.messageService.add({key: 'ccUpdate', severity:'error', summary: 'Update Failed', detail: error.error});
        }
      )
    }
  }

  cancelCompanyContactUpdate()
  {
    if(this.selectedCompanyContact != null)
    {
      if(this.selectedCompanyContact.companyContactId != null)
      {
        //Do this for contacts that aren't new. We can just delete it
        Object.assign(this.selectedCompanyContact,this.tempCompanyContact);
      }
      this.selectedCompanyContact = null;
      this.tempCompanyContact = null;
    }
  }

  newCompanyContact()
  {
    this.selectedCompanyContact = new CompanyContact();
    this.tempCompanyContact = new CompanyContact();
  }

  deleteCompanyContact(cc: CompanyContact)
  {
    this.confirmationService.confirm({
      target: event.target,
      message: 'Are you sure that you want to delete this contact?',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
          //Delete this guy from the db, as well as from this company
          this.tcems.deleteCompanyContact(this.activeSidebarCompany.companyId,cc.companyContactId).toPromise()
          .then
          (
            result =>
            {
              //Delete successful
              this.messageService.add({key: 'ccUpdate', severity:'success', summary: 'Success', detail: 'Contact Deleted'});
              this.activeSidebarCompany.companyContacts = this.activeSidebarCompany.companyContacts.filter(c => c.companyContactId != cc.companyContactId);
              this.companyContacts = this.companyContacts.filter(c => c.companyContactId != cc.companyContactId);
              this.tempCompanyContact = null;
              this.selectedCompanyContact = null;
            }
          )
          .catch
          (
            error =>
            {
              this.messageService.add({key: 'ccUpdate', severity:'error', summary: 'Delete Failed', detail: error.error});
            }
          )
      },
      reject: () => {
          //Do nothing... we're good.
      }
    });
  }

  ///////////////////////////////
  //Reports Stuff
  ///////////////////////////////

  selectReportsForCompany(company: Company)
  {
    this.displayReportsSidebar = true;
    this.tcems.getReportsForClient(company.companyId).toPromise()
    .then
    (
      result =>
        {
          let cr: ClientReports = Object.assign(new ClientReports(),result);
          let years: number[] = cr.years;

          this.companyReportMenu =  years.map<MenuItem>
          (
            y => 
            {
              return {
                  label: y.toString(),
                  items:
                  [
                    {
                      label: 'Annual Reports',
                      command: (event) => {this.companyReports = cr.getAnnualReportsForYear(y)}
                    },
                    {
                      label: 'Quarterly Reports',
                      ... cr.getQuartersForYear(y).length > 0 && {items: cr.getQuartersForYear(y)
                            .map
                            (
                              q =>
                              {
                                return {
                                  label: q,
                                  command: (event) => {this.companyReports = cr.getQuarterlyReportsForYear(y, q)}
                                 
                                }
                              }
                            )}
                    },
                    {
                      label: 'Monthly Reports',
                      ... cr.getMonthsForYear(y).length> 0 && {items: cr.getMonthsForYear(y)
                            .map
                            (
                              m =>
                              {
                                return {
                                  label: m,
                                  command: (event) => {this.companyReports = cr.getMonthlyReportsForYear(y, m)}
                                 
                                }
                              }
                            )}
                    },
                    {
                      label: 'AdHoc Reports',
                      command: (event) => {this.companyReports = cr.getAdHocReportsForYear(y)}

                    }
                  ]
                }
              }
          );
        }
    )
  }

  downloadReport(report: ClientReport)
  {
    this.tcems.downloadReport(report.storageSpec)
    .then
    (
      blob => {
        const a = document.createElement('a')
        const objectUrl = URL.createObjectURL(blob)
        a.href = objectUrl
        a.download = report.storageSpec.name + "." + report.storageSpec.fileExtension;
        a.click();
        URL.revokeObjectURL(objectUrl);
      }
    );
  }


  ///////////////////////////////
  //Actions
  ///////////////////////////////


  showInvoiceOverlay(event)
  {
    this.invoiceOverlay.show(event.originalEvent);
  }
  
  cancelInvoice()
  {
    this.invoiceDate = null;
    this.invoiceFrom = null;
    this.invoiceTo = null;
    this.invoiceOverlay.hide();
  }

  confirmInvoice()
  {
    //Build our list of invoices to create
    var invoices: InvoiceInsert[] = this.selectedCompanies.map
    (
      c =>
      {
        let i:InvoiceInsert = new InvoiceInsert();
        let r:InvoiceRule = c.invoiceRules.find(r => r.active);
        i.activityBegin = this.invoiceFrom;
        i.activityEnd = this.invoiceTo;
        i.invoiceDate = this.invoiceDate;
        i.ruleId = r.ruleId;
        i.employeeCount = c.subCount ?? this.activeSubs[c.companyId];

        return i;
      }
    );


    //Iterate and confirm
    var completeInvoices: Invoice[] = [];
    this.creatingInvoices = true;

    //create an observable of observables
    of(invoices.map
    (
      i =>
        {
          return this.tcems.createInvoice(i)
        }
    ))
    //flatten it out
    .pipe
    (
      mergeAll(),
      mergeAll()
    )
    //And, subscribe to the results!
    .subscribe
    (
      {
        next: i =>
        {
          //Update our list of complete invoices
          completeInvoices.push(i);
          this.percentComplete = (completeInvoices.length/invoices.length) * 100;
        },
        //And then run this when we've finished everything
        complete: () => 
        {
            this.creatingInvoices = false;
            this.invoiceDate = null;
            this.invoiceFrom = null;
            this.invoiceTo = null;
            this.invoiceOverlay.hide();
            this.messageService.add({key: 'companyUpdate', severity:'success', summary: 'Invoices Created', detail: 'Created ' + completeInvoices.length.toString() + " invoices"});
        }
      }
    )


  }

}
