import { Injectable } from "@angular/core";
import { Observable, catchError, from, map, of } from "rxjs";
import { MassEmailHeader } from "../model/util/massemail/mass_email";
import { ApiService } from "./api.service";
import { environment } from "src/environments/environment";
import { formatDate } from "@angular/common";

@Injectable({
    providedIn: 'root'
  })
  export class TcemsUtilApi
  {

    constructor(private apiService: ApiService ) {}



    GetAllPendingMassEmails(): Observable<MassEmailHeader[]>
    {
        return from (this.apiService.makeAPIGetCall<MassEmailHeader[]>
        (
            environment.tcemsAPIBase,
            environment.tcemsAPI_util + "/massemail/pending",
            {}
        ))
        .pipe
        (
            map
            (
                data => 
                {
                    return data.map(d => Object.assign(new MassEmailHeader(), d));
                }
            )
        );
    }

    GetMassEmails(createdFrom:Date, createdTo:Date, statuses:string[]): Observable<MassEmailHeader[]>
    {
        return from (this.apiService.makeAPIGetCall<MassEmailHeader[]>
        (
            environment.tcemsAPIBase,
            environment.tcemsAPI_util + "/massemail/search",
            {
                ... createdFrom && {createdFrom: formatDate(createdFrom,"MM/dd/yyyy","en-US")},
                ... createdTo && {createdTo: formatDate(createdTo,"MM/dd/yyyy","en-US")},
                ... statuses && { statuses: statuses?.map(e => e.toString())},
            }
        ))
        .pipe
        (
            map
            (
                data => 
                {
                    return data.map(d => Object.assign(new MassEmailHeader(), d));
                }
            )
        );
    }

    ApproveMassEmail(id:number): Observable<MassEmailHeader>
    {
        return this.apiService.makeAPIPostCall<MassEmailHeader>
        (
            environment.tcemsAPIBase,
            environment.tcemsAPI_util + "/massemail/" + id.toString() + "/approve",
            {}

        )
        .pipe
        (
             catchError(e => {console.log(e); throw e;})
        )
        .pipe
        (
            map
            (
                data => 
                {
                    return Object.assign(new MassEmailHeader(), data);
                }
            )
        )
    }

    SendMassEmail(id:number): Observable<MassEmailHeader>
    {
        return from (this.apiService.makeAPIPostCall<MassEmailHeader>
        (
            environment.tcemsAPIBase,
            environment.tcemsAPI_util + "/massemail/" + id.toString() + "/send",
            {},
            {}
        ))
        .pipe
        (
            map
            (
                data => 
                {
                    return Object.assign(new MassEmailHeader(), data);
                }
            )
        );
    }
  }