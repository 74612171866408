import { User } from "../util/user";
import { ReferenceCode } from "./reference_code";

export class Diary
{
    constructor ()
    {
        this.diaryType = DiaryType.GetType(DiaryType.TYPE_ID_GENERAL);
    }

    diaryId: number;
    referenceId: number;
    target: any;
    createDate: Date;
    diaryDueDate: Date = null;
    closeDate: Date;
    diaryStatus: string;
    shortDescription: string;
    diaryText: string;
    referenceType: ReferenceCode;
    createdBy: User;
    assignedTo: User;
    closedBy: User;
    diaryType: DiaryType;

    asUpdate(): DiaryUpdate
    {
        let du = new DiaryUpdate();
        du.diaryDueDate = this.diaryDueDate;
        du.shortDescription = this.shortDescription;
        du.diaryText = this.diaryText;
        du.diaryType = this.diaryType;
        return du;
    }
}

export class DiaryType
{
    id: number;
    typeName: string;

    public static TYPE_ID_GENERAL:number = 1;
    public static TYPE_ID_NEW_ENCOUNTER:number = 2;

    public static  GetType(typeId: number): DiaryType
    {
        let retVal:DiaryType = new DiaryType();
        if(typeId == this.TYPE_ID_GENERAL)
        {
            retVal.id = this.TYPE_ID_GENERAL;
            retVal.typeName = "General Task";
        }
        else if(typeId == this.TYPE_ID_NEW_ENCOUNTER)
        {
            retVal.id = this.TYPE_ID_NEW_ENCOUNTER;
            retVal.typeName = "New Encounter Assigned";
        }
        else
        {
            retVal = null;
        }
        return retVal;
    }
}

export class DiaryUpdate
{
    diaryDueDate: Date = null;
    shortDescription: string;
    diaryText: string;
    diaryType: DiaryType;
}

