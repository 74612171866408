import { Network } from '../support/network';
import { ProviderLocation } from './provider_location';
import { User } from '../util/user';

export class Provider
{
    npi: string;
    providerId: number;
    conversionProvider: boolean;
    providerFirstName: string;
    providerLastName: string;
    organizationName: string;
    isIndividual: boolean;
    isOrganization: boolean;
    modifiedByUser: User;
    providerLocations: ProviderLocation[];
    eligibleNetworks: Network[];

    get providerName():string
    {
        return this.isOrganization ? this.organizationName : this.providerFirstName + " "+ this.providerLastName;
    }

}