import { Phi } from "../phi/phi";
import { Check } from "./check";
import { Encounter } from "../encounter/encounter";
import { Practice } from "../provider/practice";
import { CheckingAccount } from "./checking_account";
import { formatDate } from "@angular/common";

export class Transaction
{
    constructor()
    {
        this.createDate = new Date();
    }
    transactionId: number;
    transactionType: TransactionType;
    transactionMagnitude: number;
    void: boolean;
    voidDate: Date;
    createDate: Date;
    approved: boolean;
    approvedDate: Date;
    check: Check;
    payee: Phi;
    encounter: Encounter;
    payeePractice: Practice;
    account: CheckingAccount;
    invoiced: boolean;
    reissue: boolean;
    originalTransactionId: number;
    originalTransaction: Transaction;

    get contactRequirementsMet() : boolean
    {
        return (!this.account.requiresEmail || (this.account.requiresEmail && this.payee.hasActiveEmail));
    }

    get voidDateDisplay(): string
    {
        return this.voidDate == null ? null : formatDate(this.voidDate,'shortDate','en-US');
    }
    


    public static generateUpdateVersion(t: Transaction): TransactionUpdate
    {
        let tu: TransactionUpdate = new TransactionUpdate();

        tu.transactionType = t.transactionType;
        tu.transactionMagnitude = t.transactionMagnitude;
        tu.void = t.void;
        tu.voidDate = t.voidDate;
        tu.createDate = t.createDate;
        tu.approved = t.approved;
        tu.approvedDate = t.approvedDate;
        tu.payee = t.payee.phiId;
        tu.encounter = t.encounter?.encounterId;
        tu.payeePractice = t.payeePractice?.ein;
        tu.accountId = t.account.accountId;
        tu.invoiced = t.invoiced;
        tu.reissue = t.reissue;
        tu.originalTransactionId = t.originalTransactionId;
        return tu;
    }
}

export class TransactionType
{
    typeId: number;
    transactionDescription: string;
    payment: boolean;
    deposit: boolean;

    public static TYPE_SAVINGS_REWARD: number = 1;
    public static TYPE_CONFIRMATION_INCENTIVE: number = 2;
    public static TYPE_REGISTRATION_INCENTIVE: number = 3;
    public static TYPE_GENERAL_REIMBURSEMENT: number = 4;
    public static TYPE_GENERAL_PAYMENT: number = 5;
    public static TYPE_PRICING_ERROR_REIMBURSEMENT: number = 6;


}

export class TransactionUpdate
{
    transactionType: TransactionType;
    transactionMagnitude: number;
    void: boolean;
    voidDate: Date;
    createDate: Date;
    approved: boolean;
    approvedDate: Date;
    payee: number;
    encounter: number;
    payeePractice: string;
    accountId: number;
    invoiced: boolean;
    reissue: boolean;
    originalTransactionId: number;
}



