
<!--<div class="grid flex-column fill-vert" style="background-color: green;">-->
<div class="flex flex-column fill-vert">

    <!--Search Pane - Full Width-->
    <!--<div class="shadow-10 align-items-start" >-->
    <p-panel header="Search Criteria">
        <div class="pt-3">
            <div class="formgroup-inline">
                <div class="field">
                    <span class="p-float-label">
                        <input id="firstName" type="text" pInputText [(ngModel)]="firstName"/>
                        <label for="firstName">First Name</label> 
                    </span>
                </div>
                <div class="field">
                    <span class="p-float-label ">
                        <input id="lastName" type="text" pInputText [(ngModel)]="lastName"/>
                        <label for="lastName">Last Name</label> 
                    </span>
                </div>
                <div class="field">
                    <span class="p-float-label ">
                        <p-calendar id="dob" [(ngModel)]="dob"></p-calendar>
                        <label for="dob">Date of Birth</label>
                    </span>
                </div>
                <div class="field">
                    <div >
                        <p-button label="Search"
                        (click)="search()" [disabled]="searching" iconPos="right" [icon]="searchIcon"></p-button>
                    </div>
                    <div style="color: red;">
                        {{message}}
                    </div>
                </div>
            </div>
        </div>
    </p-panel>
    <!--</div>-->

    <!-- Lower portion of screen -->
    <div class="fill-vert mt-3">
        <div class="grid fill-vert">
            <!--Results Pane - Left - 25%-->
            <div class="col-3">

                <!--<div class="fill-vert" style="background-color: orange;">-->
                    <p-panel header="Search Results">
                        <p-virtualScroller [value]="foundSet" scrollHeight="450px" >
                            <ng-template pTemplate="item" let-phi >
                                <div [ngClass]="{ 
                                    'm-2': true,
                                    'p-1': true,
                                    'grid': true,
                                    'shadow-10': true,
                                    'selected': phi.phiId == selectedId }">
                                    <div class="col-9 align-self-top">
                                        <div>{{phi.lastName}},</div>
                                        <div>{{phi.firstName}}</div>
                                        <div>DOB: {{phi.dob | date:'shortDate'}} </div>
                                    </div>
                                    <div class='col align-self-center'>
                                        <button pButton  type="button" icon="pi pi-chevron-right" class="p-button-rounded" (click)="select(phi.phiId)"></button>
                                    </div>
                                </div>
                            </ng-template>
                        </p-virtualScroller>
                    </p-panel>
                <!--</div>-->
            </div>

            <!--Detail Pan - Right - 75% -->
            <div class="col ml-2">
                <div class="flex fill-vert flex-column" style="max-height:550px">
                    <p-panel header="Detail"> 
                        <div class="fill-vert" style="height:450px">  <!--Same height as the scroller pane-->
                            <app-phi-detail *ngIf="selectedId != null" [selectedPhi]="selectedPhi"></app-phi-detail>
                        </div>
                    </p-panel>
                </div>
            </div>
        </div>
    </div>
</div>
