<span class="p-float-label">
    <p-autoComplete inputId="patientCompany" [(ngModel)]="company" field="companyName" [suggestions]="companySearchResults" (completeMethod)="companySearch($event)" [dropdown]="true" dropdownMode="current" (onSelect)="newCompanySelected($event)">
      <ng-template let-company pTemplate="item">
        <div>
          <span style="margin-right: 5px;"><i class="pi pi-id-card"></i></span>
          <span *ngIf="company.active" >{{company.displayName}}</span>
          <span *ngIf="!company.active" style="color:red;" >{{company.displayName}}</span>
        </div>
      </ng-template>
    </p-autoComplete>
    <label for="patientCompany">Company</label>
  </span>