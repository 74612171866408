
import { Directive, Component, Input, OnInit, ViewContainerRef } from '@angular/core';


@Directive 
({
    selector: '[detailsPane]'
})
export class DetailsPane
{
    constructor(public viewContainerRef: ViewContainerRef){}
}
