import {TreeNode} from 'primeng/api';
import { Category } from './category';
import { Procedure } from './procedure';

export class ProcedureHierarchy
{
    procedureId: number;
    procedureCode: string;
    procedureDescription: string;
    alternateDescription: string;
    get displayText():string
    {
        return this.alternateDescription ?? this.procedureDescription;
    }
    children: ProcedureHierarchy[];
    codeSet: string;
    procedure: Procedure;

    
    public static generateFromTreeNode(tn: TreeNode):Procedure[]
    {
        //Traverse the hierarchy and convert all leaf node to procedure hierarchy objects
        if (tn.children != null && tn.children.length > 0)
        {
            let nodes: Procedure[] = [];
            tn.children.forEach(c => nodes.concat(this.generateFromTreeNode(c)));
            return nodes;
        } 
        return [tn.data];
    }

    public static filterProcedureTreeNodes(tn: TreeNode<ProcedureHierarchy>[], codes: string[]): TreeNode<ProcedureHierarchy>[]
    {
        //Do a recursive search of the children of each element in the array
        //Select all parents of that node.
        let nodes: TreeNode<ProcedureHierarchy>[] = [];
        tn?.forEach(
            n =>
            {
                if(n.children != null && n.children.length > 0)
                {
                    let childSelections = this.filterProcedureTreeNodes(n.children, codes);
                    nodes = [... nodes, ...childSelections];
                    //if (codes.includes(n.data.procedureCode))
                    if(nodes.filter(nn => n.children.map(c => c.data.procedureCode).includes(nn.data.procedureCode)).length == n.children.length)
                    {
                        nodes = [... nodes, n];
                    }
                    else if(childSelections.length > 0)
                    {
                        n.partialSelected = true;
                    }
                    

                }
                else
                {
                    if (codes.includes(n.data.procedureCode))
                    {
                        nodes = [... nodes, n];
                    }
                }
            })
        return nodes;
    }

    public asTreeNode(selectable?: boolean): TreeNode<ProcedureHierarchy>
    {

        let tn: TreeNode<ProcedureHierarchy> = {};
        //tn.label = "" + (this.children?.length == 0 ? this.procedureCode + " - " : "" ) + this.procedureDescription?.substring(0,255);
        tn.label = "" + this.procedureCode + " - " + this.displayText?.substring(0,100);
        
        tn.data = this;

        //instantiate the procedure properly
        tn.data.procedure = Object.assign(new Procedure(),tn.data.procedure);

        tn.selectable = selectable;
        tn.key = this.procedureId.toString();
        tn.children = 
            this.children?.filter(c => !ProcedureHierarchy.ignorableCodes[this.codeSet].includes(c.procedureCode))    //Exclude the ignorable codes
            .sort((a,b) =>        //Sort
                                    { 
                                        var valA = a.displayText?.toUpperCase(); 
                                        var valB = b.displayText?.toUpperCase(); 
                                        if (valA < valB) return -1; 
                                        if (valA > valB) return 1; 
                                        else return 0;
                                     })
            .map(c => Object.assign(new ProcedureHierarchy(),c).asTreeNode(selectable));    //Recursively set up children. 
        return tn;
    }

    public filter(procedureIds: number[]): ProcedureHierarchy
    {
         //Parent nodes, or those that match the category
        // {
            let ph: ProcedureHierarchy = new ProcedureHierarchy();
            ph.codeSet = this.codeSet;
            ph.procedureCode = this.procedureCode;
            ph.procedureDescription = this.procedureDescription;
            ph.alternateDescription = this.alternateDescription;
            ph.procedureId = this.procedureId;
            ph.procedure = this.procedure;
            if(procedureIds.includes(this.procedureId))
            {
                return ph;  //Cool! This is an acceptable leaf node
            }
            else
            {
                //Well, if we don't match, if we have children, then we need to process them
                if(this.children != null && this.children.length > 0)
                {
                    let children = this.children.map(c => Object.assign(new ProcedureHierarchy(),c).filter(procedureIds) );
                    
                    //Now, we've processed our children. If all we have is a set of nulls, then we just pass back another null
                    ph.children = children.filter(c => c != null);
                    if(ph.children != null && ph.children.length >0)
                    {
                        return ph;
                    }
                    else
                    {
                        return null;
                    }

                }
                else
                {
                    //No children, and not a match... let's just return a null
                    return null
                }
            


            }           
            
            
        // }
        // else
        // {
        //     return null;
        // }
    }

    static ignorableCodes = {"CPT":['1021435','1014311','1021863','1028554'],"HCPCS":[]}; 
}