<p-card [header]="this.parameter.param.displayName">
    <!-- DateTime -->
    <div *ngIf="this.parameter.param.dataType == 'DateTime'">
        <!-- Date Picker -->
        <p-calendar [(ngModel)]="dateInput" (onBlur)="this.dateFieldExit()" (onSelect)="this.dateValueSet(dateInput)"></p-calendar>
    </div>

    <!-- Number -->
    <div *ngIf="this.parameter.param.dataType == 'number'">
        <!-- Number Entry -->
        <p-inputNumber [(ngModel)]="numberInput" (onInput)="this.numberValueSet($event)"></p-inputNumber>
    </div>

    <!-- String -->
    <div *ngIf="this.parameter.param.dataType == 'string'">
        <!-- Standard Input -->
        <input style="width: 300px" type="text" pInputText [(ngModel)]="stringInput" (change)="this.stringValueSet()"/>
    </div>

    <!-- Boolean -->
    <div *ngIf="this.parameter.param.dataType == 'boolean'">
        <!-- Checkbox -->
        <p-checkbox [binary]="true" [(ngModel)]="this.booleanInput" (onChange)="this.boolValueSet()"></p-checkbox>
    </div>

    <div style="width:100%">
        <textarea pInputTextarea disabled [style]="{'width':'100%'}">{{this.parameter.param.description}}</textarea>
    </div>
</p-card>