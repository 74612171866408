import { Component, OnInit } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { Observable } from 'rxjs';
import { TcemsService } from 'src/app/api/tcems.service';
import { Invoice } from 'src/app/model/finance/invoice';
import { TcemsUtilitiesService } from 'src/app/util/tcems-utilities.service';

@Component({
  selector: 'app-invoice-list',
  templateUrl: './invoice-list.component.html',
  styleUrls: ['./invoice-list.component.scss']
})
export class InvoiceListComponent implements OnInit 
{


  invoiceDateStart: Date;
  invoiceDateEnd: Date;
  showClosed: boolean;

  invoicesUpdated: number = 0;
  invoicesUpdating: boolean = false;

  get invoiceUpdatePercentComplete(): number
  {
    return Math.round(this.invoicesUpdated/this.selectedInvoices.length);
  }

  foundInvoices: Invoice[] = [];
  tempInvoices: Invoice[] = [];
  selectedInvoices: Invoice[] = [];

  loading: boolean = false;

  closeAction: MenuItem =
  {
    label: 'Close',
    icon: 'pi pi-check-square',
    disabled: true,
    command: () => this.closeInvoices()
  };

  actions: MenuItem[] = [this.closeAction];

  constructor(private tcems:TcemsService, private util: TcemsUtilitiesService) 
  { 
  }

  ngOnInit(): void 
  {
  }

  updateContextMenu(event: any)
  {
    this.closeAction.disabled = this.selectedInvoices == null || this.selectedInvoices.length == 0;
  }

  searchFunction = (args: void): Promise<any> =>
  {
    this.loading = true;
    return this.tcems.searchInvoices
    ( 
      {
        invoiceDateStart: this.invoiceDateStart,
        invoiceDateEnd: this.invoiceDateEnd,
        open: true,
        closed: this.showClosed
      }
    ).toPromise()
    .then
    (
      results =>
      {
        this.foundInvoices = results.map(i => Object.assign(new Invoice(), i));
        this.loading = false;
      }
    )
    .catch
    (
      e =>
        {
          console.log(e);
          this.util.displayError("Couldn't load Invoices",e.errorMessage);
          this.loading = false;
        }
    )
  }



  onRowEditInit(i: Invoice) 
  {
    this.tempInvoices[i.invoiceId] = Object.assign(new Invoice(),i);
  }


  onRowEditSave(i: Invoice, index: number) 
  {

    // //Only allow save if the requirements are met for this account to be used
    // if(!trn.contactRequirementsMet)
    // {
    //   this.tcemsUtilities.displayError("Invalid Selection","You must pick an account for which the payee has contact information requirments met. (i.e., has a valid email addresss");
    //   return false; 
    // }

    // else
    // {
    //   //UPdate the transaction
      
    //   this.tcems.updateTransaction( {transactionId:  trn.transactionId, transaction: trn}).toPromise()
    //   .then
    //   (
    //     result => 
    //     {
    //       //Delete our cloned version and we're good
    //       delete this.tempTransactions[trn.transactionId];
    //     }
    //   )
    // }

    //Update the invoice
    this.tcems.updateInvoice(i.invoiceId,i.asUpdate()).toPromise()
    .then
    (
      result => 
      {
        //We're good with our update
        this.foundInvoices[i.invoiceId] = Object.assign(new Invoice(), result);
        this.selectedInvoices = [];
        delete this.tempInvoices[i.invoiceId];
      }
    )
    .catch
    (
      error =>
      {
        console.log(error);
        this.util.displayError("Update Error","Could not update invoice. Check log for details");
      }
    )
  }

  onRowEditCancel(i: Invoice, index: number) 
  {
    // this.transactions[index] = this.tempTransactions[trn.transactionId];
    // delete this.tempTransactions[trn.transactionId];
    this.foundInvoices[index] = this.tempInvoices[i.invoiceId];
    delete this.tempInvoices[i.invoiceId];
  }

  printInvoice(invoice: Invoice)
  {
    //show popup with invoice PDF
    this.tcems.getInvoicePDF(invoice.invoiceId)
    .then
    (
      blob =>
      {
        if (blob.size == 0)
        {
          this.util.displayError("Could Not Print Invoice","Invoice Does Not Exist");          
          return;
        }
        let url = window.URL.createObjectURL(blob);
        let pwa = window.open(url);
      }
    )
    .catch
    (
      e =>
      {
        console.log(e);
        this.util.displayError("Could Not Print Invoice",e.error);
      }
    )
  }

  closeInvoices()
  {
    this.invoicesUpdated = 0;
    this.invoicesUpdating = true;

    //Update the closed status on all the invoices, and then post 'em
    let invoiceUpdates: Promise<Invoice>[] = this.selectedInvoices.map
    (
      async i =>
      {
        i.closed = true;
        let iUpdate = i.asUpdate();
        return this.tcems.updateInvoice(i.invoiceId,iUpdate).toPromise()
              .then
              (
                result =>
                {
                  this.invoicesUpdated ++;
                  return result;
                }
              );
      }
    );

    Promise.all(invoiceUpdates)
    .then
    (
      result =>
      {
        //When we're all done
        this.invoicesUpdating = false;

        //Reload
        this.searchFunction();
      }
    )

  }

  deleteInvoice(invoice:Invoice)
  {
    this.invoicesUpdating = true;
    this.tcems.deleteInvoice(invoice.invoiceId).toPromise().then
    (
      result =>
      {
        this.invoicesUpdating = false;
        this.searchFunction();
      }
    )
  }

}
