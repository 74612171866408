import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TcemsService } from 'src/app/api/tcems.service';
import { InstanceOfCare } from 'src/app/model/claim/instance_of_care';
import { Line } from 'src/app/model/claim/line';
import { Procedure } from 'src/app/model/claim/procedure';
import { PracticeProcedureResult, PracticeResult, ProcedureBasedResult } from 'src/app/model/claim/procedure_lookup_result';
import { EncounterModelProcedure } from 'src/app/model/encounter/encounter_model_procedure';
import { EncounterProvider } from 'src/app/model/encounter/encounter_provider';
import { Practice } from 'src/app/model/provider/practice';
import { ProviderLocation } from 'src/app/model/provider/provider_location';
import { ResultsSelection } from '../results-selection';

@Component({
  selector: 'app-procedure-results',
  templateUrl: './procedure-results.component.html',
  styleUrls: ['./procedure-results.component.css']
})
export class ProcedureResultsComponent extends ResultsSelection<ProcedureBasedResult> implements OnInit {


  //@Input() procedureResults: PracticeProcedureResult[];

  private _procedureResults: PracticeProcedureResult[] = [];
  private _selectedResult: PracticeResult;


  @Input() set procedureResults(val: PracticeProcedureResult[])
  {

     //Instantiate all the practices and procedures properly

     if (val == null)
     {
       this.lines = {};
       this._procedureResults = [];
     }
     else
     {
      this._procedureResults = val;
     }

     this._procedureResults = this._procedureResults.map
     (
       pr => 
       {
         pr.practice = Object.assign(new Practice(),pr.practice);
         pr.procedureBasedResults.forEach
         (
           pbr => 
           {
             pbr.procedure = Object.assign(new Procedure(),pbr.procedure);
           }
         )
         return Object.assign(new PracticeProcedureResult(),pr);
       }
     );
  } 

  get procedureResults(): PracticeProcedureResult[]
  {
    return this._procedureResults;
  }

  //This is what we'll use to store expanded line results. It's keyed by procedure id
  lines: {[key:number]: Line[]} = {};

  chosenLines: {[key:number]: Line} = {};



  showLineSelector: {[key:number]: boolean} = {};
  showPracticeEditor: {[key:string]: boolean} = {};

  constructor(private tcems: TcemsService)
  {
    super();
  }

  ngOnInit(): void
  {
  }

  updateSelections(): EncounterModelProcedure[] {
    //Convert our lines into EMPs

    var retArr = [];

    for (var l in this.chosenLines)
    {
      if(this.chosenLines[l] != null)
      {
        var emp = new EncounterModelProcedure();
        emp.instanceId = this.chosenLines[l].claim.visit.instance.instanceId;
        emp.lineId = this.chosenLines[l].lineId;
        emp.cost = this.chosenLines[l].cost;
        emp.procedure = Object.assign(new Procedure(), this.chosenLines[l].procedure);
        emp.unitCost =  this.chosenLines[l].unitCost;
        emp.units = this.chosenLines[l].units;

        // emp.instanceOfCare = this.chosenLines[l].claim.visit.instance;
        // emp.line = this.chosenLines[l];
        retArr.push(emp);
      }
    };

    // console.log(retArr);
    return retArr;
  }

  updateChosenResult(): PracticeResult
  {
    return this._selectedResult;
  }

  lineChosen(result: PracticeResult, procId: number, event: Line)
  {
    this.chosenLines[procId] = event;
    this._selectedResult = result;
    this.publishSelection();
  }

  showDialog(procedureCode: number )
  {
    this.showLineSelector[procedureCode] = true;
  }

  closeLineDialog(procedureCode: number)
  {
    this.showLineSelector[procedureCode] = false;
  }

  loadLines(data: ProcedureBasedResult)
  {
    //Get our lines
    //We will make several async calls to get lines
    data.lineIds.forEach
    (
      l =>
        {
          //See if we've already got these guys loaded
          if(this.lines[data.procedure.procedureId] == null || this.lines[data.procedure.procedureId].length == 0)
          {
            this.lines[data.procedure.procedureId] = [];
            this.tcems.getLine(l).toPromise()
            .then
            (
              result =>
              {
                this.lines[data.procedure.procedureId] = [...this.lines[data.procedure.procedureId],result].filter((value, index, array) => array.indexOf(value) === index);
              }
            )
          }
        }
    )
  }
}
