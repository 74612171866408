import { StorageSpec } from "../storage/storage_spec";


export class ReportSpec
{
    reportId: number;
    reportName: string;
    displayName: string;
    supportedParameters: ReportParamSpec[];
    clientReport: boolean;
    systemReport: boolean;

    get runtimeParameters(): RuntimeParameter[]
    {
        return this.supportedParameters.map(p => 
            { 
                let rp = new RuntimeParameter(); 
                rp.param = p; 
                return rp
            });
    }
}
export class ReportParamSpec
{
    parameterId: number;
    parameterName: string;
    dataType: string;
    defaultValue:string;
    required: boolean;
    displayName: string;
    description: string;
    clientParameter: boolean;
}

export class RuntimeParameter
{
    constructor()
    {
        Object.defineProperty(this,"parameterSpecId", {
        get: () => this.param.parameterId,
        set: value => this.param.parameterId = value,
        enumerable: true});
    }
    param: ReportParamSpec;
    value: string;
    


    get parameterSpecId(): number
    {
        return this.param.parameterId;
    } 
}


export class RuntimeReportSpec
{
    reportSpecId: number;
    exportSpecId: number;
    parameters: RuntimeParameter[];
}

export class RuntimeClientReportSpec extends RuntimeReportSpec
{
    clientIds: number[];
    clientParam: number;
}



export class ExecutedReportParameter
{
    parameterId: number;
    parameterName: string;
    parameterValue: string;
}

export class ReportOutputFormat
{
    id:number;
    contentType: string;
    fileExtension: string;
}

export class ReportExecutionSpec
{
    reportSpecId: number;
    reportSpecName: string;
    parameters: ExecutedReportParameter[];
    outputFormat: ReportOutputFormat;
}

export class StoredReport
{
    year: number;
    reportExecutionSpec: ReportExecutionSpec;
    storageSpec: StorageSpec;
}

export class SystemReport extends StoredReport
{
    reportDate: Date;
}

export class ClientReport extends StoredReport
{
   
}

export class AnnualClientReport extends ClientReport
{
    
}
export class MonthlyClientReport extends ClientReport
{
    monthName: string;

}

export class QuarterlyClientReport extends ClientReport
{
    quarterName: string;
}

export class AdHocClientReport extends ClientReport
{
    reportName: string;
}

export class ClientReports
{

    monthMap = ["January", "February", "March","April","May","June","July","August","September","October","November","December"];
    monthOrder = {};

    annualReports: AnnualClientReport[];
    quarterlyReports: QuarterlyClientReport[];
    monthlyReports: MonthlyClientReport[];
    adHocReports: AdHocClientReport[];
    companyId: number;
    companyName: string;

    get years(): number[]
    {
        let years = [... new Set<number>
                    (
                        [
                            ... this.annualReports.map(ar=>ar.year),
                            ... this.monthlyReports.map(mr => mr.year),
                            ... this.quarterlyReports.map(qr => qr.year),
                            ... this.adHocReports.map(ahr => ahr.year)
                        ]
                    )
                ]
        years.sort((a,b) => a < b ? -1 : 1);
        return years;
    }

    getMonthsForYear(year: number): string[]
    {
        let months = [... new Set(this.monthlyReports.filter(mr => mr.year == year).map(mr => mr.monthName))];
        for (var i=0; i<this.monthMap.length; i++)
            this.monthOrder[this.monthMap[i]] = i;
        months.sort((a,b) => this.monthOrder[a] - this.monthOrder[b]);
        return months;
    }

    getQuartersForYear(year: number): string[]
    {
        let quarters = this.quarterlyReports.filter(qr => qr.year == year).map(qr => qr.quarterName);
        quarters.sort((a,b) => a < b ? -1 : 1);
        return quarters;
    }
    

    getAnnualReportsForYear(year: number): AnnualClientReport[]
    {
        return this.annualReports.filter(ar => ar.year == year);
    }
    
    getQuarterlyReportsForYear(year: number, quarter: string ): QuarterlyClientReport[]
    {
        return this.quarterlyReports.filter(qr => qr.year == year && qr.quarterName == quarter);
    }
    
    getMonthlyReportsForYear(year: number, month: string): MonthlyClientReport[]
    {
        return this.monthlyReports.filter(mr => mr.year == year && mr.monthName == month);
    }
    
    getAdHocReportsForYear(year: number): AdHocClientReport[]
    {
        return this.adHocReports.filter(ahr => ahr.year == year);
    }
}

