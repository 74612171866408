import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType} from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { combineLatest, concat, forkJoin, from, merge, Observable, of } from 'rxjs';
import { catchError, concatAll, concatMap, exhaustMap, map, mapTo, mergeAll, mergeMap, tap, } from 'rxjs/operators';
import { TcemsService } from 'src/app/api/tcems.service';
import { loadActiveUsers, loadAppointmentStatuses, loadCaptives, loadCategories, loadCheckingAccounts, loadCompanyTypes, loadContactTypes, loadEncounterClosureReasons, loadEncounterStatuses, loadEncounterTypes, loadNetworkGroups, loadNetworks, loadPlanTypes, loadProcedureHierarchy, loadSources, loadTransactionTypes, updateUserState } from './actions/api/tcems.actions';
import { loggedIn } from './actions/login.actions';
import { ProcedureHierarchy } from './model/claim/procedure_hierarchy';
import { EncounterStatus } from './model/encounter/encounter_status';
import { Network } from './model/support/network';
import { ReservationAccepted, ReservationCreated, WorkerStatusChanged } from './actions/twilio.actions';




@Injectable()
export class AppEffects {


  loginChores$ = createEffect ( () =>
    this.actions$.pipe
    (
      ofType(loggedIn),
      mergeMap(action => 
        {
          let obs: Observable<any>[] = [
            this.tcemsService.getAllCaptives().pipe(map(captives => loadCaptives({captives: captives}))),                                                   //Captives
            this.tcemsService.getAvailableEncounterStatuses().pipe(map(statuses => loadEncounterStatuses({statuses: statuses}))),                           //Encounter Statues
            this.tcemsService.getAvailableEncounterClosureReasons().pipe(map(reasons => loadEncounterClosureReasons({closureReasons: reasons}))),           //Closure Reasons
            this.tcemsService.getAvailableAppointmentStatuses().pipe(map(statuses => loadAppointmentStatuses({appointmentStatuses: statuses}))),            //Appointment Statuses
            this.tcemsService.getAllSources().pipe(map(sources => loadSources({sources : sources}))),                                                       //Sources
            this.tcemsService.getAllNetworks().pipe(map(n => loadNetworks({networks : n.map(n => Object.assign(new Network(),n)) }))),                      //Networks
            this.tcemsService.getAllCategories().pipe(map(categories => loadCategories({categories : categories}))),                                        //Categories
            this.tcemsService.getAllNetworkGroups().pipe(map(networkgroups => loadNetworkGroups({networkgroups : networkgroups}))),                         //NetworkGroups
            this.tcemsService.getAllEncounterTypes().pipe(map(encounterTypes => loadEncounterTypes({encounterTypes : encounterTypes}))),                    //Encounter Types
            this.tcemsService.getAllPlanTypes().pipe(map(planTypes => loadPlanTypes({planTypes : planTypes}))),                                             //Plan Types
            this.tcemsService.getAllTransactionTypes().pipe(map(transactionTypes => loadTransactionTypes({transactionTypes : transactionTypes}))),          //TransactionTypes
            this.tcemsService.getActiveUsers().pipe(map(users => loadActiveUsers({users : users}))),                                                        //Active Users
            this.tcemsService.getAllContactTypes().pipe(map(contactTypes => { return loadContactTypes({contactTypes : contactTypes})})),                    //Contact Types
            this.tcemsService.getAllCompanyTypes().pipe(map(companyTypes => { return loadCompanyTypes({companyTypes : companyTypes})})),                    //Company Types
            this.tcemsService.getCheckingAccounts().pipe(map(accounts => loadCheckingAccounts({accounts : accounts}))),                                     //Checking Accounts
            
          ];
         
          return obs
        }),
        mergeAll()
      
    )
  );

  loadProcHierarchies$ = createEffect( () =>
    this.actions$.pipe
    (
      ofType(loggedIn),                                                       //On the Login Action
        mergeMap
        (
          action =>  
            combineLatest(
            [
              this.tcemsService.getCodes("CPT")
              ,this.tcemsService.getCodes("HCPCS")
            ])
            .pipe
            (
              map(ph => loadProcedureHierarchy({cpt:ph[0],hcpcs:ph[1]}))
            )
        ),
    )
  );


  //this.tcems.getCodes("CPT").toPromise()


  //Update the user state from the logged in user

  loadInitialUserState$ = createEffect( () =>

  this.actions$.pipe
  (
    ofType(loggedIn),                                                       //On the Login Action
      map(action => updateUserState({userState: action.currentUser.userState}) //Fire the update User State
      ),
    )
  );


    //Update the user state from the fired event
    updateUserState$ = createEffect ( () =>

    this.actions$.pipe
    (
      ofType(updateUserState),
        map
        (
          action =>
          {
            this.tcemsService.updateUserState({userId: action.userState.userId,state: action.userState, clearActiveTask: action.userState.activeTask == null})
            // Push the changes to local storage too (this updates any other browser tabs)
            if(localStorage.getItem('USER_STATE') != JSON.stringify( action.userState ))
              localStorage.setItem( 'USER_STATE',JSON.stringify( action.userState ) );
          }
        )
    ), {dispatch: false}
    );


  //error$ =


  constructor(private actions$: Actions, private tcemsService: TcemsService,private store: Store)
  {

  }

}
