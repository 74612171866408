import { Component, Input, OnInit } from '@angular/core';
import { TcemsService } from 'src/app/api/tcems.service';
import { InstanceOfCare } from 'src/app/model/claim/instance_of_care';
import { Line } from 'src/app/model/claim/line';
import { Procedure } from 'src/app/model/claim/procedure';
import { InstanceBasedResult, PracticeInstanceResult, PracticeResult } from 'src/app/model/claim/procedure_lookup_result';
import { EncounterModelProcedure } from 'src/app/model/encounter/encounter_model_procedure';
import { Practice } from 'src/app/model/provider/practice';
import { ProviderLocation } from 'src/app/model/provider/provider_location';
import { ResultsSelection } from '../results-selection';

@Component({
  selector: 'app-instance-results',
  templateUrl: './instance-results.component.html',
  styleUrls: ['./instance-results.component.css']
})
export class InstanceResultsComponent extends ResultsSelection<InstanceBasedResult> implements OnInit {

  private _instanceResults: PracticeInstanceResult[] = [];
  private _selectedResult: PracticeResult;

  @Input() set instanceResults(val: PracticeInstanceResult[])
  {

    if(val == null)
    {
      this.instances = {};
      this._instanceResults = [];
    }
    else
    {
      this._instanceResults = val;
    }

    this._instanceResults = this._instanceResults.map
    (
      pr => 
      {
        pr.practice = Object.assign(new Practice(),pr.practice);
        pr.instanceBasedResult = Object.assign(new PracticeInstanceResult(), pr.instanceBasedResult);

        return Object.assign(new PracticeInstanceResult(),pr);
      }
    );

  }

  get instanceResults(): PracticeInstanceResult[]
  {
    return this._instanceResults;
  }

  instances: {[key:string]: InstanceOfCare[]} = {};
  chosenLines: Line[] = [];
  chosenInstance: InstanceOfCare;
  showInstanceSelector: {[key:string]: boolean} = {};
  showPracticeEditor: {[key:string]: boolean} = {};


  constructor(private tcems:TcemsService)
  {
    super();
  }

  ngOnInit(): void {
  }

  showDialog(ein: string )
  {
    this.showInstanceSelector[ein] = true;
  }

  closeInstanceDialog(ein: string)
  {
    this.showInstanceSelector[ein] = false;
  }

  updateSelections(): EncounterModelProcedure[] {
    // Convert our lines into EMPs

    let retArr: EncounterModelProcedure[] = this.chosenLines.map
    (
      l =>
      {
        var emp = new EncounterModelProcedure();
        emp.instanceId = this.chosenInstance.instanceId;
        emp.cost = l.cost;
        emp.lineId = l.lineId;
        emp.procedure = l.procedure;
        emp.unitCost = l.unitCost;
        emp.units = l.units;
        return emp;
      }
    );

    // console.log(retArr);
    return retArr;
  }

  updateChosenResult(): PracticeResult
  {
    return this._selectedResult;
  }

  instanceChosen(result: PracticeResult, event: {instance:InstanceOfCare, lines:Line[]})
  {
    this.chosenInstance = event.instance;
    this._selectedResult = result;
    this.chosenLines= event.lines;
    this.chosenLines.forEach(l => l.procedure = Object.assign(new Procedure(),l.procedure));

    this.publishSelection();
  }

  loadInstances(ein: string, data: InstanceBasedResult)
  {
    //Get our lines
    //We will make several async calls to get lines
    data.instanceIds.forEach
    (
      i =>
        {
          //See if we've already got these guys loaded
          if(this.instances[ein] == null || this.instances[ein].length == 0)
          {
            this.instances[ein] = [];
            this.tcems.getInstanceOfCare(i).toPromise()
            .then
            (
              result =>
              {
                // this.instances[ein] = this.instances[ein].concat([(Object.assign(new InstanceOfCare(),result))]);
                this.instances[ein] = [... this.instances[ein], (Object.assign(new InstanceOfCare(),result))];
              }
            )
          }
        }
    )
  }

}
