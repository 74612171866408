<!-- Used for displaying messages to the end user -->
<!-- Reference the tcems-utilities service to see how the rest of the system can load messages into the angular message framework for these toasts to be used -->
<p-toast key="sticky" position="top-center"></p-toast>
<p-toast key="normal" position="top-center"></p-toast>

<!--<div class="grid flex-column" style="height: 100vh">-->
<!--<div style="height:100vh">-->
<div class="flex flex-column p-2" style="height:90vh;">

    <!--Handle login events that come from the login module-->
    <app-login></app-login>
    <!-- This is our main panel markup-->
    <!--<p-panel [style]="{'height': '100vh'}">
-->
    <!-- Top Menu / Header-->
    <!--<div class="col align-items-start shadow-10 " style="min-height:75px; max-height:75px">-->
    <div class="align-items-start" style="min-height:75px; max-height:75px">

        <p-menubar id="topMenu" [model]="topMenuModel" appendTo="body">

            <ng-template pTemplate="end">

                <div class="flex flex-row-reverse align-items-center">
                    <div class="p-2">
                        HEMI v{{version}}
                    </div>
                    <div *ngIf="loggedIn" class="flex flex-row-reverse align-items-center">
                        <div class="justify-content-center p-2">
                            <!--<button pButton  type="button" icon="pi pi-user" class="p-button-rounded p-button-info"></button>-->
                            <p-avatar
                                label="{{loginStatus.currentUser.firstName.substr(0,1)}}{{loginStatus.currentUser.lastName.substr(0,1)}}"
                                shape="circle" [style]="{'background-color':'#2196F3','color':'#FFFFFF'}"></p-avatar>
                        </div>
                        <div class="flex justify-content-end p-2" style="width:150px;">
                            {{loginStatus.currentUser.firstName.substr(0,1)}} {{loginStatus.currentUser.lastName}}<br>(Ext.{{loginStatus.currentUser.extNumber}})
                        </div>
                    </div>
                    <div *ngIf="!loggedIn">
                        Not Logged In
                    </div>
                    <!-- 9=Zach, 13=Tom, 12=Drake-->
                    <div #twilioDiv>
                        <p-splitButton #twilioButton  [model]="twilioButtonOptions" label="{{taskrouterButtonLabel}}" [style]="{'width':'75px'}"
                        [styleClass]="taskrouterButtonClass" (onClick)="taskrouterButtonClick()" (onDropdownClick)="taskrouterDropDownClick()">
                        
                    </p-splitButton >
                    </div>
                        
                    
                    
                    
                                                                
                                        
                    <div><strong>{{taskrouterWhiteLabelAndCid}}</strong>&nbsp;&nbsp;</div>

                    <p-overlay #phiResults mode="overlay" [appendTo]="twilioDiv" [(visible)]="showResults" contentStyleClass="p-4 surface-overlay shadow-2 border-round">
                        <ng-template pTemplate="content">
                        <p-table *ngIf="phiSearchResults != null" [value]="phiSearchResults">
                            <!--<ng-template pTemplate="header">
                                <tr>
                                    <th>Member</th>
                                    <th>ID</th>
                                </tr>
                            </ng-template>-->
                            <ng-template pTemplate="body" let-phiSearchResult>
                                <tr>
                                    <td><a href="#"
                                            (click)="twilio.updateTwilioNumber(phiSearchResult.phiId)">{{phiSearchResult.result.firstName}}
                                            {{phiSearchResult.result.lastName}} {{phiSearchResult.result.dob | date:'shortDate' }}</a></td>
                                    <!--<td><a href="#"
                                            (click)="updateTwilioNumber(phiSearchResult.phiId)">{{phiSearchResult.phiId}}</a>
                                    </td>-->
                                </tr>
                            </ng-template>
                        </p-table>
                        <p-progressSpinner *ngIf="phiSearchResults == null"></p-progressSpinner>
                        </ng-template>
                    </p-overlay>
                   
                </div>

                <div class="grid flex-row-reverse align-items-center flex vertical-container">

                </div>
            </ng-template>

        </p-menubar>
    </div>

    <!--Middle Page Content-->
    <!--<div class="col shadow-10 mt-3" style="min-height:750px; max-height:750px">-->
    <div class="shadow-10  p-2 fill-vert">
        <p-overlayPanel #op>
            <ng-template pTemplate>
            </ng-template>
        </p-overlayPanel>
        <div class="grid fill-vert">
            <!-- And this is our side menu-->
            <div *ngIf="centerPanelType==CPT_SPLIT_2_10" class="col-2 fill-vert">
                <p-panelMenu [model]="sideMenuModel"></p-panelMenu>
            </div>

            <!-- <div class="row">             -->
                <!-- this is our main panel in the center of the page-->
                <div id="contentPanel" class="{{centerPanelType==CPT_SINGLE ? 'col-12 fill-vert' : 'col-10 fill-vert'}} ">

                    <router-outlet></router-outlet>

                    <!-- Research Main Panels -->
                    <!-- Home Page -->
                    <!-- <app-home-page *ngIf="visibility[HOME_PAGE]" [loginStatus]="loginStatus"
                    [(showUserHomePage)]="showHomePage"></app-home-page> -->

                    <!-- This is the plan research module-->
                    <!-- <app-plan-detail *ngIf="visibility[RESEARCH_PLAN]"></app-plan-detail>

                    <app-search-with-details *ngIf="visibility[RESEARCH_MANAGE_PRACTICE]"
                        [implementor]="components[RESEARCH_MANAGE_PRACTICE]"></app-search-with-details> -->

                    <!-- The Member Research module-->
                    <!-- <app-subscriber-search *ngIf="visibility[RESEARCH_PHI]"> </app-subscriber-search> -->

                    <!-- Instance Research-->
                    <!-- <app-instance *ngIf="vResearchInstance"> </app-instance> -->
                    <!-- <app-search-with-details *ngIf="visibility[RESEARCH_LOOKUP_INSTANCE]"
                        [implementor]="components[RESEARCH_LOOKUP_INSTANCE]"></app-search-with-details>
                    <app-search-with-details *ngIf="visibility[RESEARCH_SEARCH_INSTANCE]"
                        [implementor]="components[RESEARCH_SEARCH_INSTANCE]"></app-search-with-details> -->

                    <!-- Encounter Main Panels -->
                    <!-- <app-search-with-details *ngIf="visibility[ENCOUNTER_MATCH]"
                        [implementor]="components[ENCOUNTER_MATCH]"></app-search-with-details>
                    <app-encounter-lookup *ngIf="visibility[ENCOUNTER_LOOKUP]"></app-encounter-lookup>

                    <app-procedure-lookup *ngIf="visibility[RESEARCH_PROCEDURE_LOOKUP]"></app-procedure-lookup>

                    <app-transaction-list *ngIf="visibility[UTILITY_TRANSACTION_MANAGER]"></app-transaction-list>
                    <app-invoice-list *ngIf="visibility[UTILITY_INVOICE_MANAGER]"></app-invoice-list>
                    <app-company-manager *ngIf="visibility[UTILITY_COMPANY_MANAGER]"></app-company-manager>
                    <app-reportmanager *ngIf="visibility[REPORTS_REPORT_MANAGER]"></app-reportmanager>
                    <app-reportviewer *ngIf="visibility[REPORTS_REPORT_VIEWER]"></app-reportviewer>
                    <app-reportrunner *ngIf="visibility[REPORTS_REPORT_RUNNER]"></app-reportrunner> -->
                </div>

                <!-- <div class="col-3" *ngIf="toggleMessaging">
                    <iframe class="iframe" [src]="urlSafe"></iframe>
                </div>  -->
        </div>
    </div>

    <!-- Footer -->
    <!--<div class="col align-items-end shadow-10 mt-3" style="min-height:75px; max-height:75px" >-->
    <!-- <div class="shadow-10 mt-3 align-items-end" style="min-height:75px; max-height:75px">
        Footer
    </div> -->
    <!--</p-panel>
-->

</div>
<app-sidebar></app-sidebar>
