import { EncounterStatus } from '../model/encounter/encounter_status';
import { createSelector, createFeatureSelector } from "@ngrx/store";
import { ClosureReason } from '../model/encounter/closure_reason';
import { Source } from '../model/support/source';
import { TransactionType } from '../model/finance/transaction';
import { EncounterType } from '../model/encounter/encounter';
import { Network, NetworkGroup } from '../model/support/network';
import { Category } from '../model/claim/category';
import { User, UserState } from '../model/util/user';
import { ContactType } from '../model/util/contact_type';
import { CheckingAccount } from '../model/finance/checking_account';
import { AppointmentStatus } from '../model/encounter/appointment_status';
import { PlanType } from '../model/phi/plan';
import { CompanyType } from '../model/support/company_type';
import { ProcedureHierarchy } from '../model/claim/procedure_hierarchy';
import { Captive } from '../model/support/captive';

//Here's the shape of our application state
export interface AppState
{
    encounterStatus: EncounterStatus[];
    closureReasons: ClosureReason[];
    sources: Source[];
    networks: Network[];
    networkgroups: NetworkGroup[];
    transactionTypes: TransactionType[];
    encounterTypes: EncounterType[];
    planTypes: PlanType[];
    categories: Category[];
    currentUser: User;
    contactTypes: ContactType[];
    userState: UserState;
    checkingAccounts: CheckingAccount[];
    appointmentStatuses: AppointmentStatus[];
    activeUsers: User[];
    companyTypes: CompanyType[];
    procHierarchy: {cpt:ProcedureHierarchy,hcpcs:ProcedureHierarchy};
    captives: Captive[];


    //twilio stuff
    twilioCurrentReservation: {reservation:any,reservationStatus:string};
    twilioWorker: any;
    currentWorkerStatus: string;

}

//Selectors

export const selectCurrentUser = createSelector(
  (state: AppState) => state.currentUser,
  (user: User) => user
);

export const selectUserState = createSelector(
  (state: AppState) => state.userState,
  (userState: UserState) => userState
);

export const selectContactTypes = createSelector(
  (state: AppState) => state.contactTypes,
  (contactType: ContactType[]) => contactType
);

export const selectEncounterStatuses = createSelector(
    (state: AppState) => state.encounterStatus,     //Select from the app state
    (statuses: EncounterStatus[]) => statuses       //We don't need to transform... just send it straight through

  );

export const selectAppointmentStatuses = createSelector(
  (state: AppState) => state.appointmentStatuses,     //Select from the app state
  (appointmentStatuses: EncounterStatus[]) => appointmentStatuses       //We don't need to transform... just send it straight through

);

export const selectEncounterClosureReasons = createSelector(
    (state: AppState) => state.closureReasons,     //Select from the app state
    (closureReasons: ClosureReason[]) =>  closureReasons      //We don't need to transform... just send it straight through
  );

export const selectSources = createSelector(
  (state: AppState) => state.sources,     //Select from the app state
  (sources: Source[]) =>  sources      //We don't need to transform... just send it straight through
);

export const selectNetworks = createSelector(
  (state: AppState) => state.networks,     //Select from the app state
  (networks: Network[]) =>  networks      //We don't need to transform... just send it straight through
);


export const selectCategories = createSelector(
  (state: AppState) => state.categories,     //Select from the app state
  (categories: Category[]) =>  categories      //We don't need to transform... just send it straight through
);

export const selectNetworkGroups = createSelector(
  (state: AppState) => state.networkgroups,     //Select from the app state
  (networkgroups: NetworkGroup[]) =>  networkgroups      //We don't need to transform... just send it straight through
);

export const selectTransactionType = createSelector(
  (state: AppState) => state.transactionTypes,     //Select from the app state
  (transactionTypes: TransactionType[]) =>  transactionTypes      //We don't need to transform... just send it straight through
);

export const selectEncounterType = createSelector(
  (state: AppState) => state.encounterTypes,     //Select from the app state
  (encounterTypes: EncounterType[]) =>  encounterTypes      //We don't need to transform... just send it straight through
);

export const selectPlanType = createSelector(
  (state: AppState) => state.planTypes,     //Select from the app state
  (planTypes: PlanType[]) =>  planTypes      //We don't need to transform... just send it straight through
);

export const selectActiveUsers = createSelector(
  (state: AppState) => state.activeUsers,     //Select from the app state
  (activeUsers: User[]) =>  activeUsers      //We don't need to transform... just send it straight through
);

export const selectCheckingAccounts = createSelector(
  (state: AppState) => state.checkingAccounts,     //Select from the app state
  (accounts: CheckingAccount[]) =>  accounts      //We don't need to transform... just send it straight through
);

export const selectCompanyTypes = createSelector(
  (state: AppState) => state.companyTypes,
  (companyTypes: CompanyType[]) => companyTypes
);

export const selectCaptives = createSelector(
  (state: AppState) => state.captives,
  (captives: Captive[]) => captives
);

export const selectProcHierarchy = createSelector(
  (state: AppState) => state.procHierarchy,
  (ph: {cpt: ProcedureHierarchy, hcpcs:ProcedureHierarchy}) => ph
);

export const selectTwilioWorkerStatus = createSelector(
  (state: AppState) => state.currentWorkerStatus,
  (status: string) => status
);

export const selectTwilioWorker = createSelector(
  (state: AppState) => state.twilioWorker,
  (worker) => worker
);

export const selectTwilioReservation = createSelector(
  (state: AppState) => state.twilioCurrentReservation,
  (reservation) => reservation
);


