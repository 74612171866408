import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Store } from '@ngrx/store';
import { TcemsService } from '../../../api/tcems.service';
import { Phi } from '../../../model/phi/phi';
import { selectContactTypes } from '../../../state/app.state';
import { ContactType } from '../../../model/util/contact_type';
import { Address, AddressUpdate } from '../../../model/phi/address';
import { EmailAddress } from '../../../model/phi/email-address';
import { PhoneNumber, PhoneNumberUpdate } from '../../../model/phi/phone-number';

@Component({
  selector: 'app-address-editor',
  templateUrl: './address-editor.component.html',
  styleUrls: ['./address-editor.component.scss']
})
export class AddressEditorComponent implements OnInit {

  private _addresses: Address[];
  private _origAddresses: Address[];

  constructor(private store: Store, private tcems: TcemsService) { }

  @Input() phiId: number;
  @Input() showAddressEditor: boolean;
  @Input() editablePhi = false;
  @Input() showMarketing = false;
  @Input()
  set addresses(val: Address[])
  {
    this._addresses = val.map(v => Object.assign(new Address(), v));
    this._origAddresses = val.map(v => Object.assign(new Address(), v));
  }
  get addresses(): Address[]
  {
    return this._addresses;
  }
  @Output() addressesChange: EventEmitter<Address[]> = new EventEmitter<Address[]>();
  @Output() showAddressEditorChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  contactTypes$ = this.store.select(selectContactTypes);
  ContactTypes: ContactType[];

  ngOnInit(): void
  {
    this.contactTypes$.subscribe(t => {this.ContactTypes = t; });
  }

  updateAddress(addressToUpdate: Address): void {
    let addressUpdate = new AddressUpdate();
    addressUpdate.address1 = addressToUpdate.address1;
    addressUpdate.address2 = addressToUpdate.address2;
    addressUpdate.city = addressToUpdate.city;
    addressUpdate.state = addressToUpdate.state;
    addressUpdate.zipCode = addressToUpdate.zipCode;
    addressUpdate.addressType = addressToUpdate.addressType;
    addressUpdate.active = addressToUpdate.active;
    addressUpdate.addressId = addressToUpdate.addressId;

    this.tcems.updateAddress
    (
      {
        phiId: this.phiId,
        addressId: addressToUpdate.addressId,
        addressUpdate
      }
    ).toPromise()
        .then
        (
          result => {
            addressUpdate = result;
          }
        )
        .catch
        (
          e =>
          {
            console.log('Update Address Failed:' + e);
          }
        );
  }

  createAddress(addressToAdd: Address): void {
    let addressUpdate = new AddressUpdate();
    addressUpdate.address1 = addressToAdd.address1;
    addressUpdate.address2 = addressToAdd.address2;
    addressUpdate.city = addressToAdd.city;
    addressUpdate.state = addressToAdd.state;
    addressUpdate.zipCode = addressToAdd.zipCode;
    addressUpdate.addressType = addressToAdd.addressType;
    addressUpdate.active = addressToAdd.active;
    addressUpdate.addressId = addressToAdd.addressId;

    this.tcems.createAddress
    (
      {
        phiId: this.phiId,
        addressUpdate
      }
    ).toPromise()
        .then
        (
          result =>
          {
            addressUpdate = result;
            this.refreshAddresses();
          }
        )
        .catch
        (
          e =>
          {
            console.log('Create Address Failed: ' + e);
          }
        );
  }

  addAddress(): void
  {
    const newAddress = new Address();
    newAddress.addressType.code = this.ContactTypes[0].code;
    newAddress.addressType.description = this.ContactTypes[0].description;
    this._addresses.push(newAddress);
  }

  updateButton(event): void
  {
    // Compare original list with current list for changes
    this._addresses.forEach((Addr, idx) => {
      if (idx < (this._origAddresses.length)) {
        if (JSON.stringify(Addr) !== JSON.stringify(this._origAddresses[idx])) {
          //this._addresses[idx].addressType.description = this.ContactTypes[Addr.addressType.code - 1].description;
          this.updateAddress(Addr);
        }
      } else {
        this.createAddress(Addr);
      }
    });

    this.showAddressEditor = false;

    this.addressesChange.emit(this._addresses);
    this.showAddressEditorChange.emit(this.showAddressEditor);
  }

  cancelButton(event): void
  {
    this.showAddressEditor = false;
    this.showAddressEditorChange.emit(this.showAddressEditor);
  }

  refreshAddresses(): void
  {
    this.tcems.getPhi(this.phiId).toPromise()
    .then
    (
      result =>
        this.addresses = result.addresses
    )
  }


}
