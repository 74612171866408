import { Category } from "../claim/category";
import { Task } from "../comm/Task";

export class User 
{
    userId: number;
    // admin: boolean;
    lastLogin: Date;
    active: boolean;
    firstName: string;
    lastName: string;
    email: string;
    username: string;
    // canLogin: boolean;
    modifiedBy: User;
    lastEdit: Date;
    azureId: string;
    userState: UserState;
    hemiPhoneNumber: string;
    extNumber: string;
    twilioWorkerSid: string;
    twilioProgram: string;
    twilioContactUri: string;
    cellPhone: string;
    roles: UserRole[];
    labels: UserLabel[];


    get displayName():string
    {
        return this.firstName + " " + this.lastName;
    }
}

export class UserRole
{
    id: number;
    role: string;
}

export class UserLabel
{
    id: number;
    label: string;
}


export class UserMapper
{
    public static mapUser(u: any ) : User
    {
        var user: User;
        user.userId = u.userId;
        // user.admin = u.admin;
        user.lastLogin = u.lastLogin;
        user.active = u.active;
        user.firstName = u.firstName;
        user.lastName = u.lastName;
        user.email = u.email;
        user.username = u.username;
        user.modifiedBy = u.modifiedBy;
        user.lastEdit = u.lastEdit;
        user.azureId = u.azureId;
        user.hemiPhoneNumber = u.hemiPhoneNumber;
        user.extNumber = u.extNumber;
        user.twilioWorkerSid = u.twilioWorkerSid;
        user.twilioProgram = u.twilioProgram;
        user.twilioContactUri = u.twilioContactUri;
        user.cellPhone = u.cellPhone;
        user.roles = u.roles;
        user.labels = u.labels;
        
        return user;
    }
}

export class UserState
{
    userId: number;
    userPreferences: UserPreferences;
    pinnedEncounters: number[];
    recentLookups: number[];
    activeTask: Task;
}

export class UserPreferences
{
    defaultCategoriesForUnassignedEncounters: number[];
    defaultUserForEncounterList: number;
    defaultEncounterTypes: number[];
    defaultStatuses: number[];
    companyFilter: number[];
}