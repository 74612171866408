import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Store } from '@ngrx/store';
import { TcemsService } from 'src/app/api/tcems.service';
import { SinglePanelComponent } from 'src/app/base/single-panel/single-panel.component';
import { CheckingAccount } from 'src/app/model/finance/checking_account';
import { Transaction, TransactionType } from 'src/app/model/finance/transaction';
import { TcemsUtilitiesService } from 'src/app/util/tcems-utilities.service';

@Component({
  selector: 'app-transaction',
  templateUrl: './transaction.component.html',
  styleUrls: ['./transaction.component.css']
})
export class TransactionComponent 
extends SinglePanelComponent<Transaction>   
implements OnInit 
{
  protected createNewEmptyObject(): Transaction 
  {
    return new Transaction();
  }

  @Input() isNew: boolean;
  @Output() transaction:  EventEmitter<Transaction> = new EventEmitter<Transaction>();

  
  get selectedTransactionType():TransactionType
  {
    return this.modelData?.transactionType;
  }

  set selectedTransactionType(val:TransactionType )
  {
    this.modelData.transactionType = val;
  }


  get selectedCheckingAccount():CheckingAccount
  {
    return this.modelData?.account;
  }

  set selectedCheckingAccount(val:CheckingAccount )
  {
    this.modelData.account = val;
  }

  get encounterId(): number
  {
    return this.modelData?.encounter?.encounterId;
  }

  set encounterId(val: number)
  {
    //Set the encounter from a lookup by API
    if(val != null)
    {
      this.tcemsService.getEncounter(val).toPromise().then
      (
        e=> 
          {
            this.modelData.encounter = e;
            this.modelData.payee = e.patient.phi;
          }
      );
    }
    else
    {
      this.modelData.encounter = null;
    }
  }

  get firstName(): string
  {
    return this.modelData?.payee?.firstName;
  }

  get lastName(): string
  {
    return this.modelData?.payee?.lastName;
  }

  get company(): string
  {
    return this.modelData?.encounter?.subscriberCompany?.companyName;
  }

  get amount(): number
  {
    return this.modelData?.transactionMagnitude;
  }

  set amount(val: number)
  {
    this.modelData.transactionMagnitude = val;
  }


  constructor(private store: Store, private tcemsService: TcemsService, public util: TcemsUtilitiesService) //Inherited objects still need to handle their own dependency injection. So, we do that here
  {
    super();
  }

  ngOnInit(): void 
  {
    super.ngOnInit();
  }

  protected loadPanelFromModelData() 
  {
    //We don't have anything to do here
  }
  protected loadModelDataFromId(): Promise<Transaction> 
  {
    //If we didn't get a model object, then we need to load it from the api.
    return this.isNew ? new Promise((resolve,reject) => resolve(new Transaction())) : this.tcemsService.getTransaction(super.modelId).toPromise();
  }

  persist(event)
  {
    //set some other fields on the transaction
    this.modelData.approved = true;
    this.modelData.approvedDate = new Date();
    this.tcemsService.createTransaction({transaction:this.modelData}).toPromise()
    .then(result => this.transaction.emit(result));

    //reset this guy (if we were editing something new)
    if(this.isNew) this.modelData = new Transaction();
  }


}
