import { Component, Input, OnInit } from '@angular/core';
import { LocationResult } from 'src/app/model/claim/procedure_lookup_result';
import { Point } from 'src/app/model/geocode/point';

@Component({
  selector: 'app-location-tag',
  templateUrl: './location-tag.component.html',
  styleUrls: ['./location-tag.component.css']
})
export class LocationTagComponent implements OnInit {

  @Input() location: LocationResult;

  constructor() { }

  ngOnInit(): void
  {
    // Figure out what we have for a point type
    // console.log(this.location);
  }

  get formattedLocation():string
  {
    return this.location.location.street1 + '\n' +
            ((this.location.location.street2 != null && this.location.location.street2.length > 0) ? this.location.location.street2 + '\n' : '') +
            this.location.location.city + ', ' + this.location.location.state + ' ' + this.location.location.zip

  }
}
