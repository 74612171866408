
<p-fileUpload name="file" [url]="fileDestURL" (onUpload)="fileUploaded($event)" [auto]="true">
    <ng-template pTemplate="content" style="min-height:400px">
    <!-- <p-virtualScroller [value]="encounterFiles" scrollHeight="500px">
        <ng-template pTemplate="item" let-fd> -->
        <!-- <div style="min-height:400px"> -->
    <p-table  [value]="encounterFiles" dataKey="fileId" >
        <!-- <ng-template pTemplate="header">
            <tr>
                <th>Name</th>
                <th>Type</th>
                <th>Format</th>
                <th style="width:6rem;">

                </th>
            </tr>
        </ng-template> -->
        <ng-template pTemplate="body" let-fd let-i="rowIndex">
            <tr>
                <!-- <p-card> -->
                <td>
                    <i *ngIf="fd.contentType.includes('image')" class="pi pi-image"></i>
                    <i *ngIf="fd.contentType == 'application/pdf'" class="pi pi-file-pdf"></i>
                </td>
                <td>
                    <!-- <p-inplace class="w12" #editor [preventClick]="true">
                        <ng-template pTemplate="display">
                            <span>{{fd.description}}</span>                            
                        </ng-template>
                        <ng-template pTemplate="content">
                            <div class="p-inputgroup">
                                <input type="text" [(ngModel)]="fd.description" pInputText  />
                                <button type="button" pButton icon="pi pi-check" styleClass="p-button-success" (click)="nameChanged(fd,$event); editor.activate();"></button>
                                <button type="button" pButton icon="pi pi-times" styleClass="p-button-danger" (click)="editor.deactivate();"></button>
                            </div>
                        </ng-template>
                    </p-inplace> -->
                    
                    <span>{{fd.shortDesc}}</span>  
                </td>
                <td>
                    <p-overlayPanel #nameEditor>
                        <div class="p-inputgroup">
                            <input type="text" [(ngModel)]="fd.description" pInputText  />
                            <button type="button" pButton icon="pi pi-check" styleClass="p-button-success" (click)="nameChanged(fd,$event); nameEditor.hide();"></button>
                            <button type="button" pButton icon="pi pi-times" styleClass="p-button-danger" (click)="fd.description=fileOriginalNames[i]; nameEditor.hide();"></button>
                        </div>                        
                    </p-overlayPanel>
                    <!-- <button *ngIf="!editor.active" pButton type="button" icon="pi pi-pencil" (click)="editor.activate()" class="p-button-rounded p-button-text"></button>  -->
                    <button  pButton type="button" icon="pi pi-pencil" (click)="fileOriginalNames[i]=fd.description; nameEditor.show($event)" class="p-button-rounded p-button-text"></button> 
                </td>
                <td>
                    <p-dropdown class="w12" appendTo="body" id="fileType" [showClear]="false" [options]="fileTypes" [(ngModel)]="fd.fileType" [autoDisplayFirst]="false" (onChange)="typeChanged(fd,$event)"></p-dropdown>
                </td>
                <td>
                    <button pButton pRipple type="button" icon="pi pi-print" (click)="viewFile(fd)" class="p-button-rounded p-button-text"></button> 
                    <button  pButton pRipple type="button" icon="pi pi-trash" (click)="deleteFile(fd)" class="p-button-rounded p-button-text"></button>         
                </td>

                <!-- </p-card> -->
            </tr>
        </ng-template>
        </p-table>
        <!-- </ng-template>
    </p-virtualScroller> -->
            <!-- <tr>
                <td>
                    <p-inplace #editor [closable]="true">
                        <ng-template pTemplate="display">
                            <span>{{fd.description}}</span>
                        </ng-template>
                        <ng-template pTemplate="content">
                            <div class="p-inputgroup">
                                <input type="text" [(ngModel)]="fd.description" pInputText  />
                                <button type="button" pButton icon="pi pi-check" styleClass="p-button-success" (click)="nameChanged(fd,$event); editor.active=false;"></button>
                            </div>
                        </ng-template>
                    </p-inplace>
                    
                </td>
                <td>
                    <p-dropdown appendTo="body" id="fileType" [showClear]="false" [options]="fileTypes" [(ngModel)]="fd.fileType" [autoDisplayFirst]="false" (onChange)="typeChanged(fd,$event)"></p-dropdown>
                </td>
                <td>{{fd.fileExtension}}</td>
                <td>
                    <button pButton pRipple type="button" icon="pi pi-print" (click)="viewFile(fd)" class="p-button-rounded p-button-text"></button> 
                    <button  pButton pRipple type="button" icon="pi pi-trash" (click)="deleteFile(fd)" class="p-button-rounded p-button-text"></button> 
                </td>
            </tr> -->
        <!-- </ng-template>
    </p-table>
    </div> -->
    </ng-template>
</p-fileUpload>