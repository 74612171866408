import { createAction, props } from '@ngrx/store';
import { Category } from 'src/app/model/claim/category';
import { ProcedureHierarchy } from 'src/app/model/claim/procedure_hierarchy';
import { AppointmentStatus } from 'src/app/model/encounter/appointment_status';
import { ClosureReason } from 'src/app/model/encounter/closure_reason';
import { EncounterType } from 'src/app/model/encounter/encounter';
import { EncounterStatus } from 'src/app/model/encounter/encounter_status';
import { CheckingAccount } from 'src/app/model/finance/checking_account';
import { TransactionType } from 'src/app/model/finance/transaction';
import { PlanType } from 'src/app/model/phi/plan';
import { Captive } from 'src/app/model/support/captive';
import { CompanyType } from 'src/app/model/support/company_type';
import { Network, NetworkGroup } from 'src/app/model/support/network';
import { Source } from 'src/app/model/support/source';
import { ContactType } from 'src/app/model/util/contact_type';
import { User, UserState } from 'src/app/model/util/user';

export const loadEncounterStatuses = createAction(
  '[Tcems] Load Encounter Statuses',
  props<{ statuses: EncounterStatus[]}>()
);

export const loadEncounterClosureReasons = createAction(
  '[Tcems] Load Encounter Closure Reasons',
  props<{closureReasons: ClosureReason[]}>()
);

export const loadSources = createAction(
  '[Tcems] Load Sources',
  props<{sources: Source[]}>()
);

export const loadNetworks = createAction(
  '[Tcems] Load Networks',
  props<{networks: Network[]}>()
);

export const loadCategories = createAction(
  '[Tcems] Load Categories',
  props<{categories: Category[]}>()
);

export const loadNetworkGroups = createAction(
  '[Tcems] Load Network Groups',
  props<{networkgroups: NetworkGroup[]}>()
);

export const loadEncounterTypes = createAction(
  '[Tcems] Load Encounter Typees',
  props<{encounterTypes: EncounterType[]}>()
);

export const loadPlanTypes = createAction(
  '[Tcems] Load Plan Types',
  props<{planTypes: PlanType[]}>()
);

export const loadTransactionTypes = createAction(
  '[Tcems] Load Trransaction Types',
  props<{transactionTypes: TransactionType[]}>()
);

export const loadContactTypes = createAction(
  '[Tcems] Load ContactTypes',
  props<{contactTypes: ContactType[]}>()
);

export const loadCheckingAccounts = createAction(
  '[Tcems] Load Checking Accounts',
  props<{accounts: CheckingAccount[]}>()
);

export const loadActiveUsers = createAction(
  '[Tcems] Load Active Users',
  props<{users: User[]}>()
);

export const updateUserState = createAction(
  '[Tcems] Update User State',
  props<{userState: UserState}>()
);

export const updatedLoggedInUser = createAction(
  '[Tcems] Modify User',
  props<{user: User}>()
)

export const loadAppointmentStatuses = createAction(
  '[Tcems] Load Appointment Statuses',
  props<{appointmentStatuses: AppointmentStatus[]}>()
);

export const loadCompanyTypes = createAction(
  '[Tcems] Load Company Types',
  props<{companyTypes: CompanyType[]}>()
);

export const loadProcedureHierarchy = createAction(
  '[Tcems] Load Proc Hierarchy',
  props<{cpt: ProcedureHierarchy, hcpcs: ProcedureHierarchy}>()
);

export const loadCaptives = createAction(
  '[Tcems] Load Captives',
  props<{captives: Captive[]}>()
);