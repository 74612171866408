import { formatDate } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { AutoComplete } from 'primeng/autocomplete';
import { TcemsService } from 'src/app/api/tcems.service';
import { EncounterSummary } from 'src/app/model/encounter/encounter_summary';
import { Phi } from 'src/app/model/phi/phi';
import { Plan } from 'src/app/model/phi/plan';

  export abstract class PhiSearchResult
  {
    phiId: number;
    abstract result: Phi | EncounterSummary | Plan;
    abstract get displayName():string;
  }
  
  export class PhiResult extends PhiSearchResult
  {
    result: Phi;
  
    get displayName(): string
    {
      return this.result.firstName + " " + this.result.lastName + " " + formatDate(this.result.dob, "MM/dd/yyyy","en-us");
    }
    get resultType(): string
    {
      return "pi-user";
    }
  }
  
  export class PlanResult extends PhiSearchResult
  {
    result: Plan;
  
    get displayName(): string
    {
      return this.result.memberId + " " + this.result.phi.firstName + " " + this.result.phi.lastName;
    }
    get resultType(): string
    {
      return "pi-credit-card";
    }
  }
  
  export class EncounterResult extends PhiSearchResult
  {
    result: EncounterSummary;
  
    get displayName(): string
    {
      return this.result.encounterId.toString() + " " + this.result.patientFirstName + " " + this.result.patientLastName;
    }
    get resultType(): string
    {
      return "pi-folder";
    }
  }

@Component({
  selector: 'app-phi-search',
  templateUrl: './phi-search.component.html',
  styleUrls: ['./phi-search.component.scss']
})

export class PhiSearchComponent implements OnInit {
  
  selectedPhiSearchResult: PhiSearchResult; //{phiId: number,result:{patient: Phi, encounter: Encounter, plan: Plan}};
  phiSearchResults:PhiSearchResult[];

  @ViewChild("phiAutocomplete", { static: true }) phiAutocomplete: AutoComplete;
  @Input() limitToSubscribers: boolean = false;
  @Output() phiSelected: EventEmitter<{phi: Phi, encounterId: number}> = new EventEmitter<{phi: Phi, encounterId: number}>();

  constructor( private tcems:TcemsService
             ) { }

  ngOnInit(): void 
  {
  }

  phiSearch($event)
  {
    let q = $event.query;
    // console.log("Search:" + q);

    //Depending on the search string, we want to build our results a bit differently
    //Numbers mean that we're looking at member ids and/or encounter ids
    //strings mean that we're looking at member ids and/or phi

    let allPromises: Promise<PhiSearchResult[]>[] = [];

    if (q == null || q.length == 0 ) { return; } // empty query
    
    if (/^-?\d+$/.test(q) && q.length != 10) // Numbers only and not ten digits (ten digits is assumed to be a phone number)
    {
      // Do Encounters
      allPromises = [... allPromises, this.tcems.searchForEncounters({q:q}).toPromise()
      .then
      (
        results =>
          {
            // console.log(results);
            return results.filter(es => (this.limitToSubscribers && es.subscriberPhiId == es.patientPhiId) || !this.limitToSubscribers).map
            (
              e =>
              {
                let encounterResult = new EncounterResult();
                encounterResult.result = e;
                encounterResult.phiId = e.patientPhiId;
                return encounterResult;
              }
            );
          }
      )
      .catch
      (
        error =>
        {
          console.log('PHI Search Failed: ' + error);
          // this.phiSearchResults = [];
          return null;
        }
      )
      ];
    }
    else //String based or ten digits (phone number)
    {
      // Do Phi
      allPromises = [...allPromises, this.tcems.searchForPhi({query: q}).toPromise()
      .then
      (
        results =>
          {
            // console.log(results);
            return results.filter(p => (this.limitToSubscribers && Object.assign(new Phi(),p).isSubscriber) || !this.limitToSubscribers).map
            (
              p =>
              {
                let phiResult = new PhiResult();
                phiResult.result = p;
                phiResult.phiId = p.phiId;
                return phiResult;
              }
            );
          }
      )
      .catch
      (
        error =>
        {
          console.log('PHI Search Failed: ' + error);
          // this.phiSearchResults = [];
          return null;
        }
      )];

    }

    if (!(/^-?\d+$/.test(q)) || q.length != 10) // Not all numbers or length not equal to ten (phone number)
    {
      // And, also get Member Ids
      allPromises = [...allPromises, this.tcems.searchForPlan({query: q}).toPromise()
        .then
        (
          results =>
            {
              // console.log(results);
              return results.filter(e => (this.limitToSubscribers && e.isSubscriber) || !this.limitToSubscribers).map
              (
                p =>
                {
                  let phiResult = new PlanResult();
                  phiResult.result = p;
                  phiResult.phiId = p.phi.phiId;
                  return phiResult;
                }
              );
            }
        )
        .catch
        (
          error =>
          {
            //console.log('PHI Search Failed: ');
            //console.log(error);

            // this.phiSearchResults = [];
            return null;
          }
        )];
    }

    // Wait til we get everything back
    Promise.all(allPromises).then
    (
      //Set up our list of suggestions

      result =>
      {
        let flatResults:PhiSearchResult[] = [];
        result.forEach( subResult => {if (subResult != null) flatResults = [...flatResults, ...subResult]});
        this.phiSearchResults = [... flatResults];
      }
    )
  }

  phiSelectedFromSearch(event:PhiSearchResult)
  {
    if (event == null || event.phiId == null || event.phiId == undefined) return;
    // console.log("User Selected");
    // console.log(event);

    // See if we've got an encounter search result
    let encounterId = event instanceof EncounterResult ? (event as EncounterResult).result.encounterId : null;
    
    this.tcems.getPhi(event.phiId).toPromise().then(result => this.phiSelected.emit({phi:result,encounterId:encounterId}));
  }
}
