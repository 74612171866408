export class Procedure
{
    procedureId:number;
    code:string;
    description:string;
    alternateDescription:string;
    get displayText(): string
    {
        return this.alternateDescription ?? this.description;
    }
    level:number;
    l1Id:number;
    l2Id:number;
    l3Id:number;
    l4Id:number;
    l5Id:number;
    l6Id:number;
    l7Id:number;
    l8Id:number;
    l9Id:number;

    l1Code:string;
    l2Code:string;
    l3Code:string;
    l4Code:string;
    l5Code:string;
    l6Code:string;
    l7Code:string;
    l8Code:string;
    l9Code:string;

    l1Description:string;
    l2Description:string;
    l3Description:string;
    l4Description:string;
    l5Description:string;
    l6Description:string;
    l7Description:string;
    l8Description:string;
    l9Description:string;
    l1AltDescription:string;
    l2AltDescription:string;
    l3AltDescription:string;
    l4AltDescription:string;
    l5AltDescription:string;
    l6AltDescription:string;
    l7AltDescription:string;
    l8AltDescription:string;
    l9AltDescription:string;

    codeset:string;
    active:boolean;

    constructor() {}


    public shortDescription(): string
    {
        if (this.description == null) return "";
        let splitDesc: string[] = this.description.split(",");
        return (splitDesc.length > 0 ? splitDesc[0] : this.description).substr(0,25);
    }

}