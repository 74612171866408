<!-- <div class="flex "> -->

<p-toast position="top-center" key="companyUpdate"></p-toast>


<!-- ----------------------------------- -->
<!-- Generate invoices Overlay Panel -->
<!-- ----------------------------------- -->
<p-overlayPanel #invoicesOverlay appendTo="body">
    <ng-template pTemplate>
        
        <!-- Gather Input  -->
        <div *ngIf="!creatingInvoices"  class="grid">
            <div class="col-12">
                <span class="p-float-label">
                    <p-calendar id="invoiceDate" inputId="basic" [(ngModel)]="invoiceDate"></p-calendar>
                    <label for="invoiceDate">Invoice Date</label>
                </span>
            </div>
            <div class="col-6">
                <span class="p-float-label">
                    <p-calendar id="invoiceFrom" inputId="basic" [(ngModel)]="invoiceFrom"></p-calendar>
                    <label for="invoiceFrom">Invoice From</label>
                </span>
            </div>
            <div class="col-6">
                <span class="p-float-label">
                    <p-calendar id="invoiceTo" inputId="basic" [(ngModel)]="invoiceTo"></p-calendar>
                    <label for="invoiceTo">Invoice To</label>
                </span>
            </div>
            <div class="col-12 flex justify-content-center align-items-center">
                <span class="p-buttonset">
                    <button pButton pRipple  id="updateWhiteLabelButton" label="Save" class="p-button-small p-button-rounded p-button-success" (click)="confirmInvoice()"></button>
                    <button pButton pRipple  id="cancelWhiteLabelButton" label="Cancel" class="p-button-small p-button-rounded p-button-danger"  (click)="cancelInvoice()"></button>
                </span>
            </div>
        </div>

        <!-- Display Progress -->
        <div *ngIf="creatingInvoices" style="width:250px">
            <p-progressBar [value]="percentComplete"></p-progressBar>
        </div>

    </ng-template>
</p-overlayPanel>


<!-- ----------------------------------- -->
<!-- Sub Company Sidebar -->
<!-- ----------------------------------- -->
<p-sidebar appendTo="body" [(visible)]="displayChildCompanySidebar" position="bottom" [style]="{height:'90%'}" (onHide)="activeSidebarCompany=null; ">
    <app-company-manager *ngIf="activeSidebarCompany != null" [rootCompany]="activeSidebarCompany.companyId"></app-company-manager>
</p-sidebar>

<!-- ----------------------------------- -->
<!-- Reports Sidebar -->
<!-- ----------------------------------- -->
<p-sidebar appendTo="body"  [(visible)]="displayReportsSidebar" position="right" [style]="{width:'50%'}" (onHide)="activeSidebarCompany=null; companyReports=null; companyReportMenu=null">
    <p-toast position="top-center" key="rUpdate"></p-toast>
    <div class="grid">
        <div class="col-4">
            <!-- Menu to navigate the path -->
            <p-slideMenu appendTo="body" [model]="companyReportMenu" [viewportHeight]="300" [style]="{width:'200px'}" ></p-slideMenu>
        </div>
        <div class="col-8">
            <!-- Table of Files -->
            <p-table *ngIf="companyReports!=null"[value]="companyReports">
                <ng-template pTemplate="header">
                <tr>
                    <th>Name</th>
                    <th>Type</th>
                    <th>Download</th>
                </tr>
                </ng-template>
                <ng-template pTemplate="body" let-report>
                <tr>
                    <td>{{report.reportExecutionSpec.reportSpecName}}</td>
                    <td>
                        <i *ngIf="report.reportExecutionSpec.outputFormat.fileExtension == 'pdf'" class="pi pi-file-pdf" style="font-size: 2rem; color: red;"></i>
                        <i *ngIf="report.reportExecutionSpec.outputFormat.fileExtension == 'xlsx'" class="pi pi-file-excel" style="font-size: 2rem; color: green"></i>
                    </td>
                    <td>
                        <button pButton type="button" icon="pi pi-download" (click)="downloadReport(report)" style="width:120px">Download</button>      
                    </td>
                </tr>
                </ng-template>
            </p-table>
        </div>
    </div>
</p-sidebar>

<!-- ----------------------------------- -->
<!-- Company Contact Details Sidebar -->
<!-- ----------------------------------- -->

<p-sidebar appendTo="body"  [(visible)]="displayContactsSidebar" position="right" [style]="{width:'50%'}" (onHide)="cancelCompanyContactUpdate(); activeSidebarCompany=null">
    <p-toast position="top-center" key="ccUpdate"></p-toast>

    <div class="p-3">
        
        <p-card header="Company Contacts">
            <p-table [value] = "companyContacts" [(selection)]="selectedCompanyContact" dataKey="companyContactId" sortMode="single" selectionMode="single" (onRowSelect)="selectCompanyContact($event)" (onRowUnselect)="unselectCompanyContact($event)">
                <ng-template pTemplate="header">
                    <tr>
                        <th style="width:8rem">First Name</th>
                        <th style="width:8rem">Last Name</th>
                        <th style="width:10rem">Email</th>
                        <th style="width:8rem">Phone</th>
                        <th style="width:3rem"></th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-contact>
                    <tr [pSelectableRow]="contact">
                        <td style="width:8rem">{{contact.firstName}}</td>
                        <td style="width:8rem">{{contact.lastName}}</td>
                        <td style="width:10rem">{{contact.email}}</td>
                        <td style="width:8rem">{{contact.phone}}</td>
                        <td style="width:3rem">
                            <p-confirmPopup appendTo="body"></p-confirmPopup>
                            <button pButton pRipple type="button" icon="pi pi-trash" (click)="deleteCompanyContact(contact)" class="p-button-rounded p-button-text p-button-danger"></button> 
                        </td>  
                    </tr>
                </ng-template>
            </p-table>
            <div class="flex justify-content-center pt-4">
                <button icon="pi pi-plus" pButton pRipple  id="addCompanyContactButton" label="Add" class="p-button-small p-button-rounded" (click)="newCompanyContact()"></button>
            </div>
        </p-card>

    </div>
    <div class="p-3">
        
        <p-card header="Selected Contact Details">

            <!-- <form [formGroup]="rr_fg"> -->
            <div *ngIf="selectedCompanyContact != null" class="grid">

                <div class="col-6">
                    <div class="grid">
                        <div class="col-12">
                            <span class="p-float-label">
                                <input id="firstName" type="text" [(ngModel)]="selectedCompanyContact.firstName" pInputText [style]="{width:'100%'}" >
                                <label for="firstName">First Name</label>
                            </span>
                        </div>
                        <div class="col-12">
                            <span class="p-float-label">
                                <input id="lastName" type="text" [(ngModel)]="selectedCompanyContact.lastName" pInputText [style]="{width:'100%'}" >
                                <label for="lastName">Last Name</label>
                            </span>
                        </div>
                        <div class="col-12">
                            <span class="p-float-label">
                                <input id="email" type="text" [(ngModel)]="selectedCompanyContact.email" pInputText [style]="{width:'100%'}" >
                                <label for="email">Email</label>
                            </span>
                        </div>
                        <div class="col-12">
                            <span class="p-float-label">
                                <input id="phone" type="text" [(ngModel)]="selectedCompanyContact.phone" pInputText [style]="{width:'100%'}" >
                                <label for="phone">Phone</label>
                            </span>
                        </div>
                        <div class="col-12">
                            <span class="p-float-label">
                                <input id="fax" type="text" [(ngModel)]="selectedCompanyContact.fax" pInputText [style]="{width:'100%'}" >
                                <label for="fax">Fax</label>
                            </span>
                        </div>
                                 
                    </div>
                </div>
                <div class="col-6">
                    <div class="grid">
                        <div class="col-12">
                            <span class="p-float-label">
                                <input id="address1" type="text" [(ngModel)]="selectedCompanyContact.address1" pInputText [style]="{width:'100%'}" >
                                <label for="address1">Street 1</label>
                            </span>
                        </div>
                        <div class="col-12">
                            <span class="p-float-label">
                                <input id="address2" type="text" [(ngModel)]="selectedCompanyContact.address2" pInputText [style]="{width:'100%'}" >
                                <label for="address2">Street 2</label>
                            </span>
                        </div>
                        <div class="col-6">
                            <span class="p-float-label">
                                <input id="city" type="text" [(ngModel)]="selectedCompanyContact.city" pInputText [style]="{width:'100%'}" >
                                <label for="city">Street 1</label>
                            </span>
                        </div>
                        <div class="col-2">
                            <span class="p-float-label">
                                <input id="state" type="text" [(ngModel)]="selectedCompanyContact.state" pInputText [style]="{width:'100%'}" >
                                <label for="state">Street 1</label>
                            </span>
                        </div>
                        <div class="col-4">
                            <span class="p-float-label">
                                <input id="zip" type="text" [(ngModel)]="selectedCompanyContact.zipCode" pInputText [style]="{width:'100%'}" >
                                <label for="zip">Street 1</label>
                            </span>
                        </div>
                    </div>
                </div>
                
                <div class="col-12 flex align-items-center justify-content-center">
                    <span class="p-buttonset">
                        <button pButton pRipple  id="updateCompanyContactButton" label="Save" class="p-button-small p-button-rounded p-button-success" (click)="saveCompanyContact(selectedCompanyContact)"></button>
                        <button pButton pRipple  id="cancelCompanyContactButton" label="Cancel" class="p-button-small p-button-rounded p-button-danger"  (click)="cancelCompanyContactUpdate()"></button>
                    </span>
                </div>
            </div>
        </p-card>

    </div>

</p-sidebar>


<!-- ----------------------------------- -->
<!-- Hierarchy Details Sidebar -->
<!-- ----------------------------------- -->

<p-sidebar  appendTo="body" [(visible)]="displayHDSidebar" position="right" [style]="{width:'50%'}" (onHide)="cancelInvoiceRuleUpdate(); activeSidebarCompany=null">
    <p-toast position="top-center" key="hdUpdate"></p-toast>

    <div class="grid p-4">

        <div *ngIf="hierarchyDetail != null" class="col-6">
            <div class="grid">
                <div class="col-12 pt-4">
                    <span class="p-float-label">
                        <p-dropdown appendTo="body" id="attachmentTier" [options]="tiers" [(ngModel)]="hierarchyDetail.attachmentTier" [style]="{'width':'200px'}" [autoDisplayFirst]="false"></p-dropdown>
                        <label for="attachmentTier">Encounters Created At Tier</label>
                    </span> 
                </div>
                <div class="col-12 pt-4">
                    <span class="p-float-label">
                        <p-dropdown appendTo="body" id="employerTier" [options]="tiers" [(ngModel)]="hierarchyDetail.employerTier"  [style]="{'width':'200px'}" [autoDisplayFirst]="false"></p-dropdown>
                        <label for="employerTier">Employers Located At Tier</label>
                    </span> 
                </div>
            </div>
        </div>
        <div *ngIf="hierarchyDetail != null" class="col-6">
            <div class="grid">
                <div class="col-12">
                    <span class="p-float-label">
                        <input id="tier2Name" type="text" [(ngModel)]="hierarchyDetail.tier2Label" pInputText [style]="{width:'100%'}" >
                        <label for="tier2Name">Tier 2 Label</label>
                    </span>
                </div>
                <div class="col-12">
                    <span class="p-float-label">
                        <input id="tier3Name" type="text" [(ngModel)]="hierarchyDetail.tier3Label" pInputText [style]="{width:'100%'}" >
                        <label for="tier3Name">Tier 3 Label</label>
                    </span>
                </div>
                <div class="col-12">
                    <span class="p-float-label">
                        <input id="tier3Name" type="text" [(ngModel)]="hierarchyDetail.tier4Label" pInputText [style]="{width:'100%'}" >
                        <label for="tier3Name">Tier 4 Label</label>
                    </span>
                </div>
                <div class="col-12">
                    <span class="p-float-label">
                        <input id="tier3Name" type="text" [(ngModel)]="hierarchyDetail.tier5Label" pInputText [style]="{width:'100%'}" >
                        <label for="tier3Name">Tier 5 Label</label>
                    </span>
                </div>
                <div class="col-12">
                    <span class="p-float-label">
                        <input id="tier3Name" type="text" [(ngModel)]="hierarchyDetail.tier6Label" pInputText [style]="{width:'100%'}" >
                        <label for="tier3Name">Tier 6 Label</label>
                    </span>
                </div>
                <div class="col-12">
                    <span class="p-float-label">
                        <input id="tier3Name" type="text" [(ngModel)]="hierarchyDetail.tier7Label" pInputText [style]="{width:'100%'}" >
                        <label for="tier3Name">Tier 7 Label</label>
                    </span>
                </div>
            </div>
        </div>

        <div *ngIf="hierarchyDetail != null" class="col-12 flex align-items-center justify-content-center flex align-items-center justify-content-center">
            <span class="p-buttonset">
                <button pButton pRipple  id="updateHierarchyButton" label="Save" class="p-button-small p-button-rounded p-button-success" (click)="saveHierarchy()"></button>
                <button pButton pRipple  id="cancelHierarchyButton" label="Cancel" class="p-button-small p-button-rounded p-button-danger"  (click)="cancelHierarchyUpdate()"></button>
            </span>
        </div>
    </div>
</p-sidebar>


<!-- ----------------------------------- -->
<!-- Invoice Rule Sidebar -->
<!-- ----------------------------------- -->

<p-sidebar appendTo="body"  [(visible)]="displayInvoiceRuleSidebar" position="right" [style]="{width:'50%'}" (onHide)="cancelInvoiceRuleUpdate(); activeSidebarCompany=null">
    <p-toast position="top-center" key="irUpdate"></p-toast>

    
    <div class="p-3">
        
        <p-card header="Rules">
            <p-table [value] = "invoiceRules" [(selection)]="selectedInvoiceRule" dataKey="ruleId" sortMode="single" selectionMode="single" (onRowSelect)="selectInvoiceRule($event)" (onRowUnselect)="unselectInvoiceRule($event)">
                <ng-template pTemplate="header">
                    <tr>
                        <th style="width:15rem">Rule Name</th>
                        <th style="width:3rem">Active?</th>
                        <th style="width:3rem"></th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-rule>
                    <tr [pSelectableRow]="rule">
                        <td style="width:15rem">{{rule.ruleName}}</td>
                        <td style="width:3rem"><p-checkbox [binary]="true" [readonly]="true" [(ngModel)]="rule.active" ></p-checkbox></td>
                        <td style="width:3rem">
                            <p-confirmPopup appendTo="body"></p-confirmPopup>
                            <button pButton pRipple type="button" icon="pi pi-trash" (click)="deleteInvoiceRule(rule)" class="p-button-rounded p-button-text p-button-danger"></button> 
                        </td>  
                    </tr>
                </ng-template>
            </p-table>
            <div class="flex justify-content-center pt-4">
                <button icon="pi pi-plus" pButton pRipple  id="addInvoiceRuleButton" label="Add" class="p-button-small p-button-rounded" (click)="newInvoiceRule()"></button>
            </div>
        </p-card>

    </div>
    <div class="p-3">
        
        <p-card header="Selected Rule Details">

            <!-- <form [formGroup]="rr_fg"> -->
            <div *ngIf="tempInvoiceRule != null" class="grid">

                <div class="col-12">
                    <div class="grid">
                        <div class="col-12">
                            <span class="p-float-label">
                                <input id="ruleNameInput" type="text" [(ngModel)]="selectedInvoiceRule.ruleName" pInputText [style]="{width:'100%'}" >
                                <label for="ruleNameInput">Rule Name</label>
                            </span>
                        </div>
                        <div class="col-6">
                            <div class="grid">
                                <div class="col-12 mt-2">
                                    <!-- <span class="p-float-label"> -->
                                        <p-checkbox id="activeCheck" label="Active" [binary]="true" [(ngModel)]="selectedInvoiceRule.active"></p-checkbox>
                                    <!-- </span> -->
                                </div>
                                <div class="col-12 mt-2">
                                    <!-- <span class="p-float-label"> -->
                                        <p-checkbox id="netOfRewardsCheck" label="Net Of Rewards" [binary]="true" [(ngModel)]="selectedInvoiceRule.netOfRewards"></p-checkbox>
                                    <!-- </span> -->
                                </div>
                                <div class="col-12 mt-2">
                                    <!-- <span class="p-float-label"> -->
                                        <p-checkbox id="useGreaterOfFeesCheck" label="Use Greatest Admin Fee" [binary]="true" [(ngModel)]="selectedInvoiceRule.useGreaterOfFees"></p-checkbox>
                                    <!-- </span> -->
                                </div>
                                
                            </div>
                        </div>
                        <div class="col-6">
                            <div class="grid">
                                <div class="col-12 mt-2">
                                    <span class="p-float-label">
                                        <p-inputNumber [(ngModel)]="selectedInvoiceRule.pepmFee" inputId="pepmFeeInput" mode="currency" currency="USD" locale="en-US"></p-inputNumber>                            
                                        <label for="pepmFeeInput">PEPM Fee</label>
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div class="col-6">
                            <span class="p-float-label">
                                <p-inputNumber [(ngModel)]="selectedInvoiceRule.fixedFee" inputId="fixedFeeAmount" mode="currency" currency="USD" locale="en-US" (onInput)="selectedInvoiceRule.percentOfSavingsAsWholeNumber = null"></p-inputNumber>                            
                                <label for="fixedFeeAmount">Fixed Amount</label>
                            </span>       
                        </div>
                        <div class="col-6">
                            <span class="p-float-label">
                                <p-inputNumber [(ngModel)]="selectedInvoiceRule.percentOfSavingsAsWholeNumber" inputId="percentSavingsAmt" mode="decimal" suffix="%" (onInput)="selectedInvoiceRule.fixedFee = null"></p-inputNumber>                            
                                <label for="percentSavingsAmt">Percent of Savings</label>
                            </span>                       
                        </div> 
                                   
                    </div>
                </div>
                
                <div class="col-12 flex align-items-center justify-content-center">
                    <span class="p-buttonset">
                        <button pButton pRipple  id="updateInvoiceRuleButton" label="Save" class="p-button-small p-button-rounded p-button-success" (click)="saveInvoiceRule(selectedInvoiceRule)"></button>
                        <button pButton pRipple  id="cancelInvoiceRuleButton" label="Cancel" class="p-button-small p-button-rounded p-button-danger"  (click)="cancelInvoiceRuleUpdate()"></button>
                    </span>
                </div>
            </div>
        </p-card>

    </div>

</p-sidebar>


<!-- ----------------------------------- -->
<!-- Reward Rule Sidebar -->
<!-- ----------------------------------- -->
<p-sidebar appendTo="body"  [(visible)]="displayRewardRuleSidebar" position="right"  [style]="{width:'50%'}" (onHide)="cancelRewardRuleUpdate(); activeSidebarCompany=null">
    <p-toast position="top-center" key="rrUpdate"></p-toast>

    
    <div class="p-3">
        
        <p-card header="Rules">
            <p-table [value] = "rewardRules" [(selection)]="selectedRewardRule" dataKey="ruleId" sortMode="single" selectionMode="single" (onRowSelect)="selectRewardRule($event)" (onRowUnselect)="unselectRewardRule($event)">
                <ng-template pTemplate="header">
                    <tr>
                        <th style="width:15rem">Rule Name</th>
                        <th style="width:10rem">Category</th>
                        <th style="width:3rem">Active?</th>
                        <th style="width:3rem"></th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-rule>
                    <tr [pSelectableRow]="rule">
                        <td style="width:15rem">{{rule.ruleName}}</td>
                        <td style="width:10rem">{{rule.category?.categoryName}}</td>
                        <td style="width:3rem"><p-checkbox [binary]="true" [readonly]="true" [(ngModel)]="rule.active" ></p-checkbox></td>
                        <td style="width:3rem">
                            <p-confirmPopup appendTo="body"></p-confirmPopup>
                            <button pButton pRipple type="button" icon="pi pi-trash" (click)="deleteRewardRule(rule)" class="p-button-rounded p-button-text p-button-danger"></button> 
                        </td>  
                    </tr>
                </ng-template>
            </p-table>
            <div class="flex justify-content-center pt-4">
                <button icon="pi pi-plus" pButton pRipple  id="addRewardRuleButton" label="Add" class="p-button-small p-button-rounded" (click)="newRewardRule()"></button>
            </div>
        </p-card>

    </div>
    <div class="p-3">
        
        <p-card header="Selected Rule Details">

            <!-- <form [formGroup]="rr_fg"> -->
            <div *ngIf="tempRewardRule != null" class="grid">

                <div class="col-6">
                    <div class="grid">
                        <div class="col-12">
                            <span class="p-float-label">
                                <input id="ruleNameInput" type="text" [(ngModel)]="selectedRewardRule.ruleName" pInputText [style]="{width:'100%'}" >
                                <label for="ruleNameInput">Rule Name</label>
                            </span>
                        </div>
                        <div class="col-6">
                            <div class="grid">
                                <div class="col-12 mt-2">
                                    <!-- <span class="p-float-label"> -->
                                        <p-checkbox id="activeCheck" label="Active" [binary]="true" [(ngModel)]="selectedRewardRule.active"></p-checkbox>
                                    <!-- </span> -->
                                </div>
                                <div class="col-12 mt-2">
                                    <!-- <span class="p-float-label"> -->
                                        <p-checkbox id="allowOverrideCheck" label="Allow Override" [binary]="true" [(ngModel)]="selectedRewardRule.allowManualOverride"></p-checkbox>
                                    <!-- </span> -->
                                </div>
                                <div class="col-12 mt-2">
                                    <!-- <span class="p-float-label"> -->
                                        <p-checkbox id="paidByClientCheck" label="Paid By Client" [binary]="true" [(ngModel)]="selectedRewardRule.paidByClient"></p-checkbox>
                                    <!-- </span> -->
                                </div>
                            </div>
                        </div>
                        <div class="col-6">
                            <div class="grid">
                                <div class="col-12 mt-2">
                                    <span class="p-float-label">
                                        <p-inputNumber [(ngModel)]="selectedRewardRule.minSavings" inputId="minSavingsInput" mode="currency" currency="USD" locale="en-US"></p-inputNumber>                            
                                        <label for="minSavingsInput">Min Savings</label>
                                    </span>
                                </div>
                                <div class="col-12 mt-2">
                                    <span class="p-float-label">
                                        <p-inputNumber [(ngModel)]="selectedRewardRule.maxSavings" inputId="maxSavingsInput" mode="currency" currency="USD" locale="en-US"></p-inputNumber>                            
                                        <label for="maxSavingsInput">Max Savings</label>
                                    </span>
                                </div>
                                <div class="col-12 mt-2">
                                    <span class="p-float-label">
                                        <p-inputNumber [(ngModel)]="selectedRewardRule.baseReward" inputId="baseRewardInput" mode="currency" currency="USD" locale="en-US"></p-inputNumber>                            
                                        <label for="baseRewardInput">Base Reward Amount</label>
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div class="col-6">
                            <!-- <p-tabView (onChange)="selectedRewardRule.fixedAmount = null; selectedRewardRule.percentSavingsAsWholeNumber = null">
                                <p-tabPanel header="Fixed Amount"> -->
                            <span class="p-float-label">
                                <p-inputNumber [(ngModel)]="selectedRewardRule.fixedAmount" inputId="fixedAmount" mode="currency" currency="USD" locale="en-US" (onInput)="selectedRewardRule.percentSavingsAsWholeNumber = null"></p-inputNumber>                            
                                <label for="fixedAmount">Fixed Amount</label>
                            </span>       
                        </div>
                        <div class="col-6">
                            <span class="p-float-label">
                                <p-inputNumber [(ngModel)]="selectedRewardRule.percentSavingsAsWholeNumber" inputId="percentSavings" mode="decimal" suffix="%" (onInput)="selectedRewardRule.fixedAmount = null"></p-inputNumber>                            
                                <label for="percentSavings">Percent of Savings</label>
                            </span>                       
                        </div> 
                        <div class="col-12">
                            <span class="p-float-label">
                                <p-dropdown appendTo="body" id="checkingAccount" [options]="util?.checkingAccounts" [(ngModel)]="selectedRewardRule.paidThroughAccount" optionLabel="accountName" [style]="{'width':'200px'}" [autoDisplayFirst]="false"></p-dropdown>
                                <label for="checkingAccount">Paid Through Account</label>                            
                            </span>
                        </div>     
                        <div class="col-12">
                            <span class="p-float-label">
                                <p-dropdown appendTo="body" dataKey="categoryId" id="category"  [options]="util?.categories" [(ngModel)]="selectedRewardRule.category" optionLabel="categoryName" [style]="{'width':'200px'}" [autoDisplayFirst]="false"></p-dropdown>
                                <label for="checkingAccount">Applies To Category</label>                            
                            </span>
                        </div>                  
                    </div>
                </div>
                <div class="col-6">
                    <p-editor [(ngModel)]="selectedRewardRule.descriptionText" [style]="{'height':'300px'}"></p-editor>
                </div>
                
                <div class="col-12 flex align-items-center justify-content-center">
                    <span class="p-buttonset">
                        <button pButton pRipple  id="updateRewardRuleButton" label="Save" class="p-button-small p-button-rounded p-button-success" (click)="saveRewardRule(selectedRewardRule)"></button>
                        <button pButton pRipple  id="cancelRewardRuleButton" label="Cancel" class="p-button-small p-button-rounded p-button-danger"  (click)="cancelRewardRuleUpdate()"></button>
                    </span>
                </div>
            </div>
        </p-card>

    </div>

        <!-- </form> -->
</p-sidebar>




<!-- ----------------------------------- -->
<!-- White Label Sidebar -->
<!-- ----------------------------------- -->

<p-sidebar [(visible)]="displayWhiteLabelSidebar" position="right" [style]="{width:'50%'}" (onHide)="cancelWhiteLabelUpdate(); activeSidebarCompany=null">
    <p-toast position="top-center" key="wlpUpdate"></p-toast>

    <div class="grid">

        <div *ngIf="whiteLabelDetail != null" class="col-6">
            <span class="p-float-label">
                <input id="whiteLabelName" type="text" [(ngModel)]="whiteLabelDetail.programName" pInputText [style]="{width:'100%'}" >
                <label for="whiteLabelName">Program Name</label>
            </span> 
        </div>
        <div *ngIf="whiteLabelDetail != null" class="col-6">
            <span class="p-float-label">
                <p-inputMask mask="(999) 999-9999" [(ngModel)]="whiteLabelDetail.phoneNumber" [style]="{width:'100%'}"></p-inputMask>
                <label for="whiteLabelPhone">Phone Number</label>
            </span> 
        </div>
        <div *ngIf="whiteLabelDetail != null && whiteLabelDetail.id !=null" class="col-12 flex align-items-center justify-content-center">
            <p-confirmPopup appendTo="body"></p-confirmPopup>
            <button pButton pRipple type="button" label="Delete" (click)="deleteWhiteLabelProgram()" class="p-button-rounded p-button-danger"></button> 
        </div>
        <div *ngIf="whiteLabelDetail == null" class="col-12">
            <button pButton pRipple type="button" label="New" (click)="newWhiteLabelProgram()" class="p-button-rounded"></button> 
        </div>
        <div *ngIf="whiteLabelDetail != null" class="col-12 flex align-items-center justify-content-center flex align-items-center justify-content-center">
            <span class="p-buttonset">
                <button pButton pRipple  id="updateWhiteLabelButton" label="Save" class="p-button-small p-button-rounded p-button-success" (click)="saveWhiteLabel()"></button>
                <button pButton pRipple  id="cancelWhiteLabelButton" label="Cancel" class="p-button-small p-button-rounded p-button-danger"  (click)="cancelWhiteLabelUpdate()"></button>
            </span>
        </div>
    </div>
</p-sidebar>





<!-- --------------------------------- -->
<!-- Main Company Table -->
<!-- --------------------------------- -->


<p-table #companyTable [value] = "companies" [(selection)]="selectedCompanies"  [filters]="filters"
        dataKey="companyId" sortMode="multiple" [multiSortMeta]="sortFields" (onSort)="listSorted($event)"
        [scrollable]="true" scrollHeight="520px" (onHeaderCheckboxToggle)="updateContextMenu($event)" 
        (onRowSelect)="updateContextMenu($event)" (onRowUnselect)="updateContextMenu($event)"
        editMode="row" >
    <ng-template pTemplate="header">
        <tr>
            <th style="min-width: 4rem; width: 4rem; max-width: 4rem;">
                <p-tableHeaderCheckbox></p-tableHeaderCheckbox>

            </th>
            <th style="min-width:20rem; width: 20rem; max-width: 20rem;" pSortableColumn="companyName">Client<p-sortIcon field="companyName"></p-sortIcon></th>
            <th style="min-width:6rem; width: 6rem; max-width: 6rem;">
                Active
                <p-columnFilter type="boolean" field="active" display="menu"></p-columnFilter>
            </th>
            <th style="min-width: 6rem; width: 6rem; max-width: 6rem">Active Subscribers</th>
            <th style="min-width: 15rem; width: 15rem; max-width: 15rem">Company Type</th>
            <th style="min-width: 15rem; width: 15rem; max-width: 15rem">Captive</th>
            <th style="min-width: 6rem; width: 6rem; max-width: 6rem">Source</th>
            <th style="min-width: 6rem; width: 6rem; max-width: 6rem">Source Client Id</th>
            <th style="min-width: 20rem; width: 20rem; max-width: 20rem ">Address</th>
            <!-- <th style="min-width: 2rem"></th>
            <th style="min-width: 2rem"></th>
            <th style="min-width: 2rem"></th>
            <th style="min-width: 2rem"></th>
            <th style="min-width: 2rem"></th>
            <th style="min-width: 2rem"></th> -->
            <th colspan="8" style="min-width: 28rem; width: 28rem; max-width: 28rem">
                <div class="flex justify-content-end" style="width:100%">
                    <button class="ml-2" type="button" pButton icon="pi pi-bars"  (click)="actionMenu.toggle($event)"></button>
                    <button class="ml-2" type="button" pButton icon="pi pi-plus" [disabled]="editingNewRow" (click)="newCompany(companyTable)"></button>
                    
                    
                    <p-menu #actionMenu [popup]="true" [model]="actions" appendTo="body" >
                    </p-menu>

                    
                </div>
            </th>
        </tr>
    </ng-template>
    <ng-template pTemplate="body" let-company let-editing="editing" let-ri="rowIndex">
        <tr [pEditableRow]="company">
            <td  style="min-width: 4rem; width: 4rem; max-width: 4rem;">
                <p-tableCheckbox [value]="company"></p-tableCheckbox>
            </td>
            <td style="min-width: 20rem; width: 20rem; max-width: 20rem;">
                <p-cellEditor>
                    <ng-template pTemplate="input">
                        <input style="width: 20rem" type="text" pInputText [(ngModel)]="company.companyName"/>
                    </ng-template>
                    <ng-template pTemplate="output">
                        {{company.companyName}}
                    </ng-template>
                </p-cellEditor>
            </td>
            <td style="min-width: 6rem; width: 6rem; max-width: 6rem;">
                <p-cellEditor>
                    <ng-template pTemplate="input">
                        <p-checkbox [(ngModel)]="company.active" [binary]="true" [readonly]="false" ></p-checkbox>
                        <!-- <p-dropdown appendTo="body" id="transactionAccount" [showClear]="false" [style]="{'width':'250px'}" [options]="this.tcemsUtilities.checkingAccounts" [(ngModel)]="transaction.account" optionLabel="accountName" [autoDisplayFirst]="false"></p-dropdown> -->
                    </ng-template>
                    <ng-template pTemplate="output">
                        <p-checkbox [ngModel]="company.active" [binary]="true" [readonly]="true" [disabled]="true" ></p-checkbox>
                    </ng-template>
                </p-cellEditor>
            </td>
            <td style="min-width: 6rem; width: 6rem; max-width: 6rem">
                <p-cellEditor>
                    <ng-template pTemplate="input">
                        <p-inputNumber [(ngModel)]="company.subCount" inputId="subCountInput"></p-inputNumber>                            
                    </ng-template>
                    <ng-template pTemplate="output">
                        <span *ngIf="company.subCount == null">
                            <span *ngIf="activeSubs[company.companyId] == null">
                                <p-progressSpinner [style]="{width: '2rem', height: '2rem'}" styleClass="custom-spinner" strokeWidth="8" fill="var(--surface-ground)" animationDuration=".5s"></p-progressSpinner>
                            </span>
                            <span *ngIf="activeSubs[company.companyId] != null">{{activeSubs[company.companyId]}}</span>
                        </span>
                        <span *ngIf="company.subCount != null">
                            {{company.subCount}}
                        </span>
                    </ng-template>  
                </p-cellEditor>                 
            </td>
            <td style="min-width: 15rem; width: 15rem; max-width: 15rem;">
                <p-cellEditor>
                    <ng-template pTemplate="input">
                        <p-dropdown appendTo="body" id="companyType" [showClear]="false" [options]="this.util.companyTypes" [(ngModel)]="company.companyType" optionLabel="typeDescription" [autoDisplayFirst]="false"></p-dropdown>
                    </ng-template>
                    <ng-template pTemplate="output">
                        {{company.companyType?.typeDescription}}
                    </ng-template>
                </p-cellEditor>
            </td>
            <td style="min-width: 15rem; width: 15rem; max-width: 15rem;">
                <p-cellEditor>
                    <ng-template pTemplate="input">
                        <p-dropdown appendTo="body" id="captive" [showClear]="false" [options]="this.util.captives" [(ngModel)]="company.captive" optionLabel="captiveName" [autoDisplayFirst]="false"></p-dropdown>
                    </ng-template>
                    <ng-template pTemplate="output">
                        {{company.captive?.captiveName}}
                    </ng-template>
                </p-cellEditor>
            </td>
            <td style="min-width: 6rem; width: 6rem; max-width: 6rem;">
                <p-cellEditor>
                    <ng-template pTemplate="input">
                        <p-dropdown appendTo="body" id="source" [showClear]="false"  [options]="this.util.sources" [(ngModel)]="company.source" optionLabel="sourceName" [autoDisplayFirst]="false"></p-dropdown>
                    </ng-template>
                    <ng-template pTemplate="output">
                        {{company.source?.sourceName}}
                    </ng-template>
                </p-cellEditor>
            </td>
            <td style="min-width: 6rem; width: 6rem; max-width: 6rem;">
                <p-cellEditor>
                    <ng-template pTemplate="input">
                        <input style="width: 6rem" type="text" pInputText [(ngModel)]="company.clientCompanyId"/>
                    </ng-template>
                    <ng-template pTemplate="output">
                        {{company.clientCompanyId}}
                    </ng-template>
                </p-cellEditor>
            </td>
            <td style="min-width: 20rem; width: 20rem; max-width: 20rem;">
                <p-cellEditor>
                    <ng-template pTemplate="input">
                        <div style="width: 20rem" class="w12 formgrid grid">
                            <div class="col-12"><input type="text" pInputText [(ngModel)]="company.address1"/></div>
                            <div class="col-12"><input type="text" pInputText [(ngModel)]="company.address2"/></div>
                            <div class="col-6"><input style="width: 8rem" type="text" pInputText [(ngModel)]="company.city"/>,</div>
                            <div class="col-2"><input style="width: 2rem" type="text" pInputText [(ngModel)]="company.state"/></div>  
                            <div class="col-4"><input style="width: 5rem" type="text" pInputText [(ngModel)]="company.zipCode"/></div>
                        </div>
                    </ng-template>
                    <ng-template pTemplate="output">
                        <div>
                            <div>{{company.address1}}</div>
                            <div *ngIf="company.address2 != null">{{company.address2}}</div>
                            <div>{{company.city}}, {{company.state}} {{company.zipCode}}</div>
                        </div>
                    </ng-template>
                </p-cellEditor>
            </td>
            <td *ngIf="!editing" style="min-width: 4rem; width: 4rem; max-width: 4rem;">
                <p-button pTooltip="Company Contacts" type="button" class="mr-2 p-button-rounded p-button-text" icon="pi pi-user-edit" (click)="displayContactsSidebar = true; companyContacts=company.companyContacts; activeSidebarCompany=company"></p-button>
            </td>
            <td *ngIf="!editing" style="min-width: 4rem; width: 4rem; max-width: 4rem;">
                <p-button *ngIf="rootCompany == null" pTooltip="White Label Details" type="button" class="mr-2 p-button-rounded p-button-text" icon="pi pi-tag" (click)="selectWhiteLabelProgram(company); displayWhiteLabelSidebar = true; activeSidebarCompany=company"></p-button>
            </td>
            <td *ngIf="!editing" style="min-width: 4rem; width: 4rem; max-width: 4rem;">
                <p-button *ngIf="rootCompany == null" pTooltip="Company Hierarchy" type="button" class="mr-2 p-button-rounded p-button-text" icon="pi pi-sitemap" (click)="selectHierarchy(company); displayHDSidebar = true; activeSidebarCompany=company"></p-button>
            </td>
            <td *ngIf="!editing" style="min-width: 4rem; width: 4rem; max-width: 4rem;">
                <p-button pTooltip="Reward Rules" type="button" class="mr-2 p-button-rounded p-button-text" icon="pi pi-money-bill" (click)="displayRewardRuleSidebar = true; rewardRules=company.rewardRules; activeSidebarCompany=company"></p-button>
            </td>
            <td *ngIf="!editing && company.hierarchyDetail!= null" style="min-width: 4rem; width: 4rem; max-width: 4rem;">
                <p-button *ngIf="company.hierarchyDetail.maxTier > company.tier" pTooltip="Sub Companies" type="button" class="mr-2 p-button-rounded p-button-text" icon="pi pi-angle-double-down" (click)="displayChildCompanySidebar = true; activeSidebarCompany=company"></p-button>
            </td>
            <td *ngIf="!editing && company.hierarchyDetail!= null" style="min-width: 4rem; width: 4rem; max-width: 4rem;">
                <span *ngIf="company.hierarchyDetail.maxTier >= company.tier">
                    <p-button *ngIf="company.invoiceRules != null && company.invoiceRules.length > 0" pTooltip="Invoice Rules" type="button" class="mr-2 p-button-rounded p-button-text" icon="pi pi-dollar" (click)="displayInvoiceRuleSidebar = true; invoiceRules=company.invoiceRules; activeSidebarCompany=company"></p-button>
                    <p-button *ngIf="company.invoiceRules == null || company.invoiceRules.length == 0" pTooltip="Invoice Rules" type="button" class="mr-2 p-button-rounded p-button-text" (click)="displayInvoiceRuleSidebar = true; invoiceRules=company.invoiceRules; activeSidebarCompany=company">
                        <i class="pi pi-dollar" pBadge value="0"  severity="danger"></i>

                        <!-- badge="*" badgeClass="p-badge-danger" -->
                    </p-button>
                </span>
               
            </td>
            <td *ngIf="!editing" style="min-width: 4rem; width: 4rem; max-width: 4rem;">
                <p-button pTooltip="Reports" type="button" class="mr-2 p-button-rounded p-button-text" icon="pi pi-folder" (click)="displayReportsSidebar = true; selectReportsForCompany(company); activeSidebarCompany=company"></p-button>
            </td>
            <td style="min-width: 4rem;  width: 4rem; max-width: 4rem;">
            <button *ngIf="!editing" pButton pRipple type="button" pInitEditableRow icon="pi pi-pencil" (click)="onRowEditInit(company)" class="p-button-rounded p-button-text"></button> 
            <button *ngIf="editing" pButton pRipple type="button" pSaveEditableRow icon="pi pi-check" (click)="onRowEditSave(company, ri, companyTable)" class="p-button-rounded p-button-text p-button-success mr-2"></button> 
            <button *ngIf="editing" pButton pRipple type="button" pCancelEditableRow icon="pi pi-times" (click)="onRowEditCancel(company, ri)" class="p-button-rounded p-button-text p-button-danger"></button> 
            </td>
        </tr>
    </ng-template>   
    <!-- <ng-template pTemplate="summary">
        <div class="grid">
            <div class="col-2">
                Transactions Found: {{totalTransactions}}
            </div>                    
            <div class="col-2">
                Transactions Selected: {{selectedTransactionCount}}
            </div>
            <div class="col-2">
                Value of Selected Transactions: {{selectedTransactionTotal | currency: 'USD' : "symbol" }}
            </div>
        </div>
    </ng-template>     -->
</p-table>
<!-- </div> -->