import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { SearchField } from 'src/app/base/search-with-details/search-field';
import { SearchWithDetailsComponent } from 'src/app/base/search-with-details/search-with-details.component';
import { SearchResult } from 'src/app/base/search-with-details/search-result';
import { ApiService } from 'src/app/api/api.service';
import { environment } from 'src/environments/environment';
import { formatDate } from '@angular/common';
import { SearchWithDetailsImplementor } from 'src/app/base/search-with-details/search-with-details-implementor';
import { SinglePanelComponent } from 'src/app/base/single-panel/single-panel.component';
import { Type } from '@angular/core';
import { Visit } from 'src/app/model/claim/visit';
import { Line } from 'src/app/model/claim/line';
import { Claim } from 'src/app/model/claim/claim';
import { EVENT_KEYS } from 'msal/lib-commonjs/telemetry/ApiEvent';
import { Procedure } from 'src/app/model/claim/procedure';
import { SearchError } from 'src/app/base/search-with-details/search-error';
import { TcemsService } from 'src/app/api/tcems.service';
import { InstanceOfCare } from 'src/app/model/claim/instance_of_care';
import { Observable, of } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { AbstractSearchWithDetailsComponent } from 'src/app/base/search-with-details/abstract-search-with-details.component';
import { MessageService } from 'primeng/api';
// import { SinglePanelImplementor } from 'src/app/base/single-panel/single-panel-implementor';
@Component({
  selector: 'app-instance-search',
  templateUrl: './instance-search.component.html',
  styleUrls: ['./instance-search.component.scss']
})
export class InstanceSearchComponent
extends AbstractSearchWithDetailsComponent
implements OnInit, OnChanges
  // extends SinglePanelComponent<InstanceOfCare>
  // implements SearchWithDetailsImplementor<InstanceOfCare>
// extends SearchWithDetailsComponent<InstanceOfCare> //implements OnInit
{
  
  public searchType: string = "none";

  protected  isInstanceType() :boolean
  {
    return this.searchType == "instance";
  }

  protected isSearchType() : boolean
  {
    return this.searchType == "search";
  }

  constructor(private tcemsService: TcemsService, private route: ActivatedRoute, messageService: MessageService, private apiService: ApiService)
  {
    super(messageService);
  }
  
  fields: SearchField<any>[];
  searchResults: SearchResult<InstanceOfCare>[];
  sortFields: {key: string, value: string}[] = (["Date Of Service","Instance Id","Member Id","Instance Cost"]).map(sc => {return {key:sc, value:sc}});
  defaultSortField: {sortField:{key:string, value:string} ,sortDesc:boolean} = {sortField: {key:"Instance Id", value:"Instance Id"},sortDesc:false};
  currentSortField: {sortField:{key:string, value:string} ,sortDesc:boolean} = this.defaultSortField;
  selectedResult: InstanceOfCare;



  doSearch(searchEvent: SearchField<any>[])
  {
    return this.apiService.makeAPIGetCall<InstanceOfCare[]>(
    environment.tcemsAPIBase,

    this.isInstanceType() ? environment.tcemsAPI_claims + "/instance/" + searchEvent[0].value : ""//Field 0 is the only one for this type
    + this.isSearchType() ? environment.tcemsAPI_claims + "/list/member/" + searchEvent[0].value : "" //Filed 0 is also the member id if we are using the search type
    ,
    this.isSearchType() ?
    {
      //We have additional parameters if we're using the search type
      "searchStart": searchEvent[1].value != null ? searchEvent[1].value.getMonth() + 1 + "/" + searchEvent[1].value.getUTCDate() + "/" + searchEvent[1].value.getFullYear() : "",
      "searchEnd": searchEvent[2].value != null ? searchEvent[2].value.getMonth() + 1 + "/" + searchEvent[2].value.getUTCDate() + "/" + searchEvent[2].value.getFullYear() : ""

    } : {}


  )
  //When done...
  .then((result: any) =>
    {
      if(this.isInstanceType())
      {
        this.searchResults = [
          new SearchResult<InstanceOfCare>(
          {
            payload: Object.assign(new InstanceOfCare(), result),
            id: result.instanceId,
            line1: result.memberId,
            line2: formatDate(result.serviceBegin,"MM/dd/yyyy","en-US"),
            line3: result.generalProcedureDescription
          })
        ];

        this.searchInProgress = false;
      }
      else
      {
        this.searchResults = result.map( i => new SearchResult<InstanceOfCare>(
          {
            payload: Object.assign(new InstanceOfCare(), i),
            id: i.instanceId,
            line3: "$" + i.instanceCost.toFixed(2),
            line2: formatDate(i.serviceBegin,"MM/dd/yyyy","en-US"),
            line1: i.generalProcedureDescription
          }
        )).sort((a, b) => (a.payload.serviceBegin > b.payload.serviceBegin) ? 1 : -1);
        this.searchInProgress = false;

      }


    })
    .catch(error =>
    {
      // console.log('Cound Not Find Instance: ' + JSON.stringify(error));
      // throw new SearchError('Could Not Find Instance: ' + error.statusText);
      // return null;
      super.logError(error.errorMessage);
    });
  }



  doSort(sortEvent: {sortField:{key:string, value:string} ,sortDesc:boolean} )
  {
    if (sortEvent.sortField.key == "Date Of Service")
    {
      this.searchResults = this.searchResults.slice().sort((a,b) => (new Date((b.payload as any).serviceBegin).valueOf() - new Date((a.payload as any).serviceBegin).valueOf()) * (sortEvent.sortDesc ? -1 : 1));
    }
    else if (sortEvent.sortField.key == "Instance Id")
    {
      this.searchResults = this.searchResults.slice().sort((a,b) => (a.payload as any).instanceId.toString().localeCompare((b.payload as any).instanceId.toString())* (sortEvent.sortDesc ? -1 : 1));
    }
    else if (sortEvent.sortField.key == "Member Id")
    {
      this.searchResults = this.searchResults.slice().sort((a,b) => (a.payload as any).memberId.localeCompare((b.payload as any).memberId)* (sortEvent.sortDesc ? -1 : 1));
    }

    else if (sortEvent.sortField.key == "Instance Cost")
    {
      this.searchResults = this.searchResults.slice().sort((a,b) => (a.payload as any).instanceCost.toString().localeCompare((b.payload as any).instanceCost.toString() )* (sortEvent.sortDesc ? -1 : 1));
    }
    else
    {
      return this.searchResults;
    }

  }

  ngOnInit()
  {
    //Set up the search mode based on the route handed in
    this.route.paramMap.subscribe
    (
      pm => 
      {
        let searchType = pm.get("searchType");
        if(searchType == "search" || searchType == "instance")
        {
          this.searchType = searchType;
        }
        else
        {
          searchType = "none";
        }
      
    
      if(this.isInstanceType())
        {
          this.fields = [
            new SearchField<string>(
              {
                key: 'InstanceId',
                label: 'Instance Id',
                type: "string"
              }
            )
          ];
        }
        else if (this.isSearchType())
        {
          this.fields = [
            new SearchField<string>(
              {
                key: 'MemberId',
                label: 'Member Id',
                type: 'string'
              }),
              new SearchField<Date>({
                key: 'DateFrom',
                label: 'From Date Of Service',
                type: 'date'
              }),
              new SearchField<Date>({
                key: 'DateFrom',
                label: 'To Date Of Service',
                type: 'date'
              })
          ]
        }
      }
    )
  }




  //These are the methods that we implement for being a single panel
  //component as well


  
  ngOnChanges(changes: SimpleChanges): void 
  {
    this.ngOnInit();
  }



  resultSelected(selectionEvent: SearchResult<InstanceOfCare>)
  {
    this.selectedResult = selectionEvent.payload;
  }

}
