import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { combineLatest, forkJoin, from, Observable, of } from 'rxjs';
import { LazyLoadEvent, MenuItem } from 'primeng/api';
import { Button } from 'primeng/button';
import { Menu } from 'primeng/menu';
import { OverlayPanel } from 'primeng/overlaypanel';
import { Table } from 'primeng/table';
import { updatedLoggedInUser, updateUserState } from 'src/app/actions/api/tcems.actions';
import { TcemsService } from 'src/app/api/tcems.service';
import { Category } from 'src/app/model/claim/category';
import { ClosureReason } from 'src/app/model/encounter/closure_reason';
import { Encounter, EncounterType } from 'src/app/model/encounter/encounter';
import { EncounterStatus } from 'src/app/model/encounter/encounter_status';
import { Diary, DiaryType } from 'src/app/model/support/diary';
import { ReferenceCode } from 'src/app/model/support/reference_code';
import { LazyEncounterBuffer, SortAggregate, SortDetails, SortSpec } from 'src/app/model/util/resultpaging';
import { User, UserPreferences, UserState } from 'src/app/model/util/user';
import { TcemsUtilitiesService } from 'src/app/util/tcems-utilities.service';
import { combineAll, map, mergeAll, mergeMap} from 'rxjs/operators';
import { Company } from 'src/app/model/support/company';

@Component({
  selector: 'app-encounter-list',
  templateUrl: './encounter-list.component.html',
  styleUrls: ['./encounter-list.component.scss']
})
export class UnassignedEncountersComponent implements OnInit {

  encounterBuffer: LazyEncounterBuffer;
  scrollSize: number = 15;
  bufferSize: number = this.scrollSize * 2;
  rowHeight: number = 45;
  rowHeightInPixels: string = this.rowHeight.toString() + 'px';
  showBusy: boolean = false;
  selectedCategories: Category[];  
  selectedEncounterStatus: EncounterStatus[];  
  selectedEncounterTypes: EncounterType[];
  companies$ : Observable<Company[]>;
  filterCompanies: Company[] = [];
  selectedCompanies: Company[];
  employerNameContains: string = null;
  showEncountersFor: User[] = [];
  sortFields = [
                          {field: "creationDate", order: -1},
                          {field: "mostRecentAppointment?.appointmentDate", order:1 }
               ];
  underlyingSort = [];


  // @Input() defaultCategories: Category[] = [];
  @Output() encounterOpened:EventEmitter<Encounter> = new EventEmitter<Encounter>();
  @Output() encounterAssigned:EventEmitter<Encounter> = new EventEmitter<Encounter>();

  @ViewChild('encTable') encTable: Table;

  // @ViewChild('assignToOverlay') assignToOverlay: OverlayPanel;
  // @ViewChild('closeOverlay') closeOverlay: OverlayPanel;
  // @ViewChild('actionMenuButton') actionMenuButtton;
  showAssignToDialog: boolean = false;
  showCloseDialog: boolean = false;
  selectedCloseStatus: EncounterStatus = null;
  selectedClosureReason: ClosureReason = null;
  selectedActiveUser: User = null;

  constructor(private tcems: TcemsService, public util:TcemsUtilitiesService, private cdr:ChangeDetectorRef, private store:Store) { }

  //Actions for Unassigned Encounters Menu
  assignAction: MenuItem =
  {
    label: 'Assign To',
    icon: 'pi pi-user',
    disabled: true,
    command: (event) => this.toggleMassAssignTo(event)
  };

  closeAction: MenuItem =
  {
    label: 'Close',
    icon: 'pi pi-ban',
    disabled: true,
    command: (event) => this.toggleMassClose(event)
  };

  cancelAction: MenuItem =
  {
    label: 'Cancel',
    icon: 'pi pi-times',
    disabled: true,
    command: (event) => this.toggleMassCancel(event)
  };

  actions: MenuItem[] = [this.assignAction,this.closeAction, this.cancelAction];


  selectedEncounters: Encounter[] = [];







  ngOnInit(): void
  {

    //Companies list
    this.companies$ = this.tcems.getRootCompanies();
    
    var newSub$ = this.companies$
    .pipe
    (
      map(
        // c => 
        //   combineLatest([of(c),this.tcems.getSubscriberCount(c.companyId)])
        results =>
        {
          if(results != null && results.length > 0)
          {
            this.filterCompanies = results.map(c => Object.assign(new Company(), c));
            this.filterCompanies.sort((a,b) => a.companyName > b.companyName ? 1 : -1);
          }
          return results;
        }
      )
    );
    // .subscribe
    // (
    //   results =>
    //   {
    //     if(results != null && results.length > 0)
    //     {
    //       this.filterCompanies = results.map(c => Object.assign(new Company(), c));
    //       this.filterCompanies.sort((a,b) => a.companyName > b.companyName ? 1 : -1);
    //     }
    //   }
    //   );

    combineLatest([ this.util.currentUser$, this.util.categories$, this.util.activeUsers$, this.util.encounterTypes$, this.util.encounterStatuses$, newSub$])
    .subscribe(
      val =>
      {
        if(val.every(v => v != null) && val[1].length > 0 && val[2].length > 0 && val[3].length > 0 && val[4].length >0 && val[5].length>0)
        {
          // this.selectedCategories = this.util.categories.filter(c => this.defaultCategories.map(dc => dc.categoryId).includes(c.categoryId));
          if(val[0]?.userState.userPreferences.defaultCategoriesForUnassignedEncounters != null)        
            this.selectedCategories = this.util.categories.filter(c => val[0].userState.userPreferences.defaultCategoriesForUnassignedEncounters.includes(c.categoryId));  
          if(val[0]?.userState.userPreferences.defaultUserForEncounterList != null && this.showEncountersFor != null && this.showEncountersFor.length == 0)
          {
            // this.showEncountersFor = [this.util.activeUsers.find(u => u.userId == val[0].userState.userPreferences.defaultUserForEncounterList)] ;
            // if ((this.showEncountersFor.length == 1 && this.showEncountersFor[0] == null) || this.showEncountersFor.length == 0)
            // {
            //   this.showEncountersFor = null;
            // }
            this.showEncountersFor = null;
          }
          if(val[0]?.userState.userPreferences.defaultEncounterTypes != null)
            this.selectedEncounterTypes = this.util.encounterTypes.filter(s => val[0].userState.userPreferences.defaultEncounterTypes.includes(s.encounterTypeId)  );
          else
            this.selectedEncounterTypes = this.util.encounterTypes.filter(s => [6].includes(s.encounterTypeId) ); 
          if(val[0]?.userState.userPreferences.companyFilter != null)
            this.selectedCompanies = this.filterCompanies.filter(c =>  val[0].userState.userPreferences.companyFilter.includes(c.companyId));
          if(val[0]?.userState.userPreferences.defaultStatuses != null)
            this.selectedEncounterStatus = this.util.encounterStatuses.filter(s => val[0].userState.userPreferences.defaultStatuses.includes(s.statusCode) );   
          else
            this.selectedEncounterStatus = this.util.encounterStatuses.filter(s => [1,2,3].includes(s.statusCode));
            //             
          this.reloadEncounters(this.sortFields);
          // console.log(this.util.closureReasons);        
        }
      }
    );


    

  }

  storeFilters()
  {
    //Store what we've chosen as our filter here
    //get the state first
    let state: UserState = Object.assign(new UserState(), this.util.currentUserState);
    let prefs: UserPreferences = Object.assign(new UserPreferences(), state.userPreferences);
    // if(state.userPreferences.defaultEncounterTypes == null)
    //   state.userPreferences.defaultEncounterTypes = [];
    let cats = this.selectedCategories.map(c => c.categoryId);
    let types = this.selectedEncounterTypes.map(c => c.encounterTypeId);
    let statuses = this.selectedEncounterStatus.map(c => c.statusCode);
    let comps = this.selectedCompanies?.map(c => c.companyId) ?? [];
    let changeMade: boolean = false;


    //Only change if we need to
    if(JSON.stringify(prefs.defaultCategoriesForUnassignedEncounters) != JSON.stringify(cats))
    {
      prefs.defaultCategoriesForUnassignedEncounters = [...cats];
      changeMade = true;
    }    
    if(JSON.stringify(prefs.defaultUserForEncounterList) != JSON.stringify(this.util.currentUser.userId)) //!= JSON.stringify(this.showEncountersFor?.userId))
    {
      prefs.defaultUserForEncounterList = this.util.currentUser.userId;//this.showEncountersFor?.userId;
      changeMade = true;
    }
    if(JSON.stringify(prefs.defaultEncounterTypes) != JSON.stringify(types))
    {
      prefs.defaultEncounterTypes = [...types];
      changeMade = true;
    }
    if(JSON.stringify(prefs.defaultStatuses) != JSON.stringify(statuses))
    {
      prefs.defaultStatuses = [...statuses];
      changeMade = true;
    }
    if(JSON.stringify(prefs.companyFilter) != JSON.stringify(comps))
    {
      prefs.companyFilter = [...comps];
      changeMade = true;
    }
    if(changeMade)
    {
      state.userPreferences = prefs;
      this.store.dispatch({type: updateUserState.type, userState: state});
      var newUser = {...this.util.currentUser};
      newUser.userState = {...newUser.userState};
      newUser.userState.userPreferences= {...newUser.userState.userPreferences};
      newUser.userState.userPreferences = {...prefs};
      this.store.dispatch({type:updatedLoggedInUser.type, user:newUser});
    }
  }

  reloadEncounters(sortFields)
  {
    var spec = new SortSpec();

    //spec.sortFields[LazyEncounterBuffer.SORT_BY_CREATE_DATE] = LazyEncounterBuffer.DIRECTION_DESC;
    //spec.sortFields[LazyEncounterBuffer.SORT_BY_NEXT_APPT] = LazyEncounterBuffer.DIRECTION_ASC;


    //Add sort fields based on what we have sorted in the table
    sortFields.forEach
    (
      sf => 
      {
        var dir = sf.order < 0 ? SortDetails.DIR_DESC : SortDetails.DIR_ASC;
        var agg = sf.field == "mostRecentAppointment?.appointmentDate" ?  LazyEncounterBuffer.SORT_BY_NEXT_APPT_AGG : SortDetails.NOT_A_LIST;
        var field = (sf.field == "creationDate" ? LazyEncounterBuffer.SORT_BY_CREATE_DATE :
                    sf.field == "mostRecentAppointment?.appointmentDate" ? LazyEncounterBuffer.SORT_BY_NEXT_APPT : 
                    sf.field == "costToBeat" ? "CostToBeat" :
                    sf.field == "probableSavings" ? "ProbableSavings" :
                    sf.field == "encounterId" ? LazyEncounterBuffer.SORT_BY_ENCOUNTER_ID : 
                    "");


        spec.sortFields[field]= new SortDetails(dir,agg);
      }
    );

    this.underlyingSort = JSON.parse(JSON.stringify(sortFields));

    //TODO: Tie these to input controls
    // if(this.selectedCategories != null && this.selectedCategories.length > 0)
    // {
      let categories:number[] = this.selectedCategories?.map(c => c.categoryId); //[1,2]; //MRIs and CT Scans
      let types:number[] = this.selectedEncounterTypes?.map(t => t.encounterTypeId);
      let status: number[] = this.selectedEncounterStatus?.map(t =>t.statusCode);
      let assignedTo: number[] = this.showEncountersFor?.map(e => e.userId);
      let includeUnassigned: boolean = assignedTo == null || assignedTo.length == 0 ? true : false;
      let includeAssigned: boolean = assignedTo == null || assignedTo.length == 0 ? false : true;
      let includeOptedOutMembers: boolean = false;
      let companies: number[] = this.selectedCompanies?.map(c => c.companyId);
      this.encounterBuffer = new LazyEncounterBuffer
                              (
                                this.tcems
                                ,this.bufferSize
                                ,spec
                                ,{includeRelatedRecords: false, includeUnassigned: includeUnassigned , includeAssigned: includeAssigned,
                                  categories:categories,encounterTypes:types, encounterStatus:status, includeOptedOutMembers: includeOptedOutMembers,
                                  includeCostData: true, includeAppointments: true, assignedTo: assignedTo, clientId: companies, employerNameContains: this.employerNameContains}
                                ,this.cdr
                              );

      //load our first set of encounters
      this.encounterBuffer.lazyLoadFromBuffer(0,this.scrollSize)
      // .then
      // (
      //   r =>
      //   {
      //     console.log(r);
      //   }
      // );
    // }
    // else
    // {
    //   this.encounterBuffer.recordsOut = null;
    // }
    // this.cdr.detectChanges();
    // this.encTable.reset();
  }

  encounterSortChange(event)
  {    //Look at the sort fields... if it's different, then we'll need/want to reload the whole buffer
    
    this.sortFields = event.multisortmeta;

    if(JSON.stringify(this.underlyingSort) != JSON.stringify(event.multisortmeta))
    {
      this.reloadEncounters(event.multisortmeta);
      this.encounterBuffer.lazyLoadFromBuffer(0,this.scrollSize);
    }

    // this.cdr.markForCheck();
  }


  async loadMoreEncounters(event:LazyLoadEvent)
  {
    let loaded: boolean = false;
    let p =  this.encounterBuffer.lazyLoadFromBuffer(event.first, event.rows);
    if (p != null)
    {
      await p
      .then
      (
        r => 
        {
          loaded = true;
          // event.forceUpdate();
        }
      );
      }
    // this.cdr.detectChanges();
  }

  assignEncounter(encounter: Encounter)
  {
    //Assign it to me, commit, and then open
    this.showBusy = true;
    encounter.assignedTo = this.util.currentUser;

    this.tcems.updateEncounterRaw({encounterId: encounter.encounterId, encounter: Encounter.generateUpdateVersion(encounter)}).toPromise()
    .then
    (
      result =>
        {
          this.encounterAssigned.emit(encounter);
          this.showBusy = false;
        }
    );


  }


  async closeEncounter(encounter: Encounter): Promise<boolean>
  {
    if(encounter.closureReason == null)
      {
        //warn and return
        this.util.displayError("Closure Reason Needed","When closing an encounter, you must specify a closure reason.");
        return false;
      }

    this.showBusy = true;

    encounter.status = this.selectedCloseStatus;

    //Persist!
    var result = await this.tcems.updateEncounterRaw({encounterId: encounter.encounterId, encounter: Encounter.generateUpdateVersion(encounter)}).toPromise()
    // .then
    // (
    //   result =>
    //     {
          this.reloadEncounters(this.sortFields);
          this.encounterBuffer.lazyLoadFromBuffer(0,this.scrollSize);
          this.showBusy = false;
          return true;
        // }
    // );
  }

  openEncounter(encounter: Encounter)
  {
    this.encounterOpened.emit(encounter);
  }


  updateContextMenu(event: any)
  {
    this.closeAction.disabled = this.selectedEncounters.length == 0;
    this.cancelAction.disabled = this.closeAction.disabled;
    this.assignAction.disabled = this.selectedEncounters.length == 0;
  }


  toggleMassAssignTo(event)
  {
    this.showAssignToDialog = true;
  }

  setCloseStatus()
  {
    this.selectedCloseStatus = this.util.encounterStatuses.find(s => s.statusCode == EncounterStatus.STATUS_CLOSED);
  }

  setCancelStatus()
  {
    this.selectedCloseStatus = this.util.encounterStatuses.find(s => s.statusCode == EncounterStatus.STATUS_CANCELED);
  }

  toggleMassClose(event)
  {
    this.setCloseStatus();
    this.showCloseDialog = true;
  }

  toggleMassCancel(event)
  {
    this.setCancelStatus();
    this.showCloseDialog = true;
  }

  closeSelectedEncounters()
  {
    //Iterate through each encounter, closing it
    this.showBusy = true;

    let updateThreads: Promise<Encounter>[] = [];

    this.selectedEncounters.forEach
    (
      e =>
      {
        //Set the encounter as closed, and persist
        e.status = this.selectedCloseStatus;
        e.closureReason = this.selectedClosureReason;

        //Persist and add to the list of threads being updated
        updateThreads.push(this.tcems.updateEncounterRaw({encounterId: e.encounterId, encounter: Encounter.generateUpdateVersion(e)}).toPromise()
        .then
        (
          result =>
            {
              this.reloadEncounters(this.sortFields);
              this.encounterBuffer.lazyLoadFromBuffer(0,this.scrollSize);
              return result;
            }
        ));
      }
    );

    //Wait for all of our updates to complete
    Promise.all(updateThreads).then
    ( 
      result => 
      {
        //Clear the list of selected encounters
        this.selectedEncounters = [];

        //Reload Screen
        this.showBusy = false
        this.reloadEncounters(this.sortFields);
        this.encounterBuffer.lazyLoadFromBuffer(0,this.scrollSize);
      }
    );
  }
  

  assignSelectedEncounters()
  {
    //Iterate through each encounter, assigning it
    this.showBusy = true;

    let updateThreads: Promise<Encounter>[] = [];

    this.selectedEncounters.forEach
    (
      e =>
      {
        e.assignedTo = this.selectedActiveUser;

        updateThreads.push(this.tcems.updateEncounterRaw({encounterId: e.encounterId, encounter: Encounter.generateUpdateVersion(e)}).toPromise()
        .then
        (
          result =>
            {    
              let diary:Diary = new Diary();
              diary.assignedTo = result.assignedTo;
              diary.createDate = new Date();
              diary.diaryDueDate = new Date();
              diary.referenceId = result.encounterId;
              diary.referenceType = new ReferenceCode();
              diary.referenceType.code = ReferenceCode.TYPE_ENCOUNTER;
              diary.shortDescription = "Encounter Assigned";
              diary.diaryText = "You've been assigned an Encounter";
              diary.diaryType = DiaryType.GetType(DiaryType.TYPE_ID_NEW_ENCOUNTER);
              
              this.tcems.createDiary({referenceCode: ReferenceCode.TYPE_ENCOUNTER, relatedTo: result.encounterId, diary: diary.asUpdate()}).toPromise()
              .then
              (
                newDiary => 
                {
                  //Created... now assign it
                  this.tcems.assignDiary({diaryId: newDiary.diaryId, assignedTo:diary.assignedTo,diary: diary.asUpdate()}).toPromise()
                  .then
                  (
                    result =>
                    {
                    }
                  )
      
                }
      
              )
              return result;
            }
        ));
      }
    )
    
    //Wait for all of our updates to complete
    Promise.all(updateThreads).then
    ( 
      result => 
      {

        //Clear the list of selected encounters
        this.selectedEncounters = [];

        //Reload the screen
        this.showBusy = false
        this.reloadEncounters(this.sortFields);
        this.encounterBuffer.lazyLoadFromBuffer(0,this.scrollSize);

      }
    );

  }

}
