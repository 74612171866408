import { ChangeDetectorRef } from "@angular/core";
import { TcemsService } from "src/app/api/tcems.service";
import { Encounter, EncounterGroup } from "../encounter/encounter";
import { Phi } from "../phi/phi";
import { Diary } from "../support/diary";
import { Email } from "../email/email";
import { Note } from "../support/note";
import { ReferenceCode } from "../support/reference_code";
import { Location } from "../provider/location";
import { User } from "./user";
import { Company } from "../support/company";
import { CompanyHierarchy } from "../support/company_hierarchy";


export class SortAggregate
{

    constructor(listField: string, func: string)
    {
        this.listField = listField;
        this.function = func;
    }

    listField: string;
    function: string;

    static readonly AGG_SUM: string = "SUM";
    static readonly AGG_MAX: string = "MAX";
    static readonly AGG_MIN: string = "MIN";
}

export class SortDetails
{

    // constructor()
    // {
    //     this.sortDirection = SortDetails.DIR_ASC;
    //     this.aggregateFunction = SortDetails.NOT_A_LIST_PROPERTY;
    // }

    constructor(sortDir: string, aggFunction: SortAggregate)
    {
        this.sortDirection = sortDir;
        this.aggregateFunction = aggFunction;
    }

    sortDirection:string;
    aggregateFunction: SortAggregate;

    static readonly DIR_ASC: string = "ASC";
    static readonly DIR_DESC: string = "DESC"; 
    static readonly AGG_NONE: string = null;
    static readonly NOT_A_LIST: SortAggregate = null; //new SortAggregate(null,SortDetails.AGG_NONE);
}

export class SortSpec
{
    sortFields: {[field: string]:SortDetails} = {};
}

export class PagingSpec
{
    pageSize: number;
    pageNumber: number;
    sortSpec: SortSpec;
    startIndex: number;
    records: number;
}

export class PagingResult<T>
{
    totalRecords: number;
    startIndex: number;
    count: number;
    records: T[];
}

export abstract class LazyBuffer<T>
{
    public static DIRECTION_ASC: string = "ASC";
    public static DIRECTION_DESC: string = "DESC";

    bufferSize: number;

    set buffer(bufferLen: number)
    {
        this.bufferSize = bufferLen;
        // this.recordsOut = Array.from({length: this.bufferSize});

        // this.recordsOut = Array.from({length: 500});
        this.firstPageSize = this.bufferSize * 2;
    }

    recordsIn: T[] = [];
    recordsOut: T[] = [];

    firstPageSize: number;
    inPageNumber: number = 1;

    fullyLoaded: boolean = false;

    totalRecords: number;

    constructor(bufferLen: number, private cdr:ChangeDetectorRef)
    {
        //set our buffer length
        this.buffer = bufferLen;

        // //Make our first fetch!
        // this.fetchIntoBuffer(this.inPageNumber,this.firstPageSize)
        // .then
        // (
        //     result =>
        //     {
        //         this.recordsIn = this.recordsIn.concat(result);
        //         this.inPageNumber ++;
        //     }
        // )


        //Set up observables on all this.
        //When we add records to our records in, we want to dump a buffer worth of them
        //out to our records out

    }


    lazyLoadFromBuffer(index: number, records: number) : Promise<T[]>
    {
        //assume that the number of records is the page size.
        if(records != 0)
        {
            return this.fetchIntoBuffer(null, null, index, records).then
            (
                r =>
                {
                    if(this.recordsOut.length == 0)
                    {
                        this.recordsOut = new Array<T>(r.totalRecords).fill(null);
                        this.recordsIn = new Array(r.totalRecords).fill(null);
                        this.totalRecords = r.totalRecords;

                    }
                    // this.recordsIn.splice(index, r.records.length, ...r.records);
                    this.recordsOut.splice(index, r.records.length, ...r.records);


                    // let readThisMany = Math.min(this.totalRecords-index,records);
                    // this.recordsOut.splice(index, readThisMany, ...this.recordsIn.slice(index,index+readThisMany));
                    // this.recordsOut = [... this.recordsOut];
                    return this.recordsOut;
                }
            )
        }
        return ;
    }

    lazyLoadFromBufferOld(index: number, records: number): void //Promise<PagingResult<T>>
    {

        //Break the request into the starting and ending pages being requested
        let recordCap = this.totalRecords == null ? 1 : ((this.totalRecords/this.bufferSize) | 0) + 1;
        let startingPage = Math.max(1,((index / this.bufferSize) | 0));
        let endingPage = Math.min(recordCap,(((index+records) / this.bufferSize) | 0) + 1 + 1);



        //If anything in the window requested is undefined, then load it up
        if(this.recordsIn.length == 0 || this.recordsIn.slice(index,index+records).filter(r => r==null).length > 0)
        //Only buffer if this request gets within a buffer length of our input
        //if (index+records >= this.recordsIn.length - this.bufferSize && !this.fullyLoaded)
        //if (index+records >= this.recordsIn.length - this.bufferSize && !this.fullyLoaded)

        {
            // let pageNum = Math.max(0,Math.round(index/this.bufferSize) - 1) + 1;
            // let recordsToFetch = (2 + Math.round(records/this.bufferSize) + 1) * this.bufferSize;
            //let pages = Math.round(records/this.bufferSize) + 1;
            //We want to load into our output array from our input array. But, we want to pre-fetch new records first
            //Also, If this is our first load, we want to use our first page size
            //return this.fetchIntoBuffer(this.inPageNumber, this.inPageNumber == 1 ? this.firstPageSize : this.bufferSize )

            //For each page we need to load => fetch, load, splice
            for(let i: number = startingPage ; i <= endingPage ; ++i )
            {
                this.fetchIntoBuffer(i,this.bufferSize)
                .then
                (
                    result =>
                    {

                        //see if we have any results
                        let resultsAvailable: boolean = result.count > 0;
                        let endOfResults: boolean = result.count < this.bufferSize;

                        //If we have results, and our output is still empty, let's be sure to initalize it to the size we'll need
                        if(resultsAvailable && this.recordsOut.length == 0)
                        {
                            //this.recordsOut.length = result.totalRecords;
                            this.recordsOut = new Array(result.totalRecords).fill(null);
                            this.totalRecords = result.totalRecords;
                            //Trigger update
                            this.recordsOut = [...this.recordsOut];
                        }

                        if(resultsAvailable && this.recordsIn.length == 0)
                        {
                            this.recordsIn = new Array(result.totalRecords).fill(null);
                        }

                        //Concat our results
                        // this.recordsIn = this.recordsIn.concat(result);
                        //this.recordsIn = [...this.recordsIn,...result.records];
                        this.recordsIn.splice(result.startIndex, result.records.length, ...result.records);


                        //Concat our results
                        // this.recordsIn = this.recordsIn.concat(result);
                        //this.recordsIn = [...this.recordsIn,...result.records];


                        //If we have hit the end of our results, make sure we mark ourselves as fully loaded
                        this.fullyLoaded = !resultsAvailable || endOfResults || this.recordsIn.length == result.totalRecords;

                        //push from in to out
                        this.spliceFromBuffer(index,records);


                        //Increment our page number for the input
                        // if (this.inPageNumber == 1)
                        // {
                        //     this.inPageNumber += this.firstPageSize/this.bufferSize;
                        // }
                        // else
                        // {
                        //     this.inPageNumber ++;
                        // }
                        
                        return result;
                    }
                )
                .catch
                (
                    error =>
                    console.log(error)
                )
            }
        }
        else
        {
            this.spliceFromBuffer(index,records);
        }

    }

    spliceFromBuffer(index: number, records: number)
    {

        //Limit the upper end to the size of our buffer...
        let readThisMany = Math.min(this.totalRecords-index,records);

        //this'll make sure that we update our output array with records from the buffer
        //Array.prototype.splice.apply(this.recordsOut, [...[index, records], ...this.recordsIn]);
        this.recordsOut.splice(index, readThisMany, ...this.recordsIn.slice(index,index+readThisMany));

        //Trigger update
        this.recordsOut = [...this.recordsOut];

        //Grow our output array by the buffer size
        // if(!this.fullyLoaded)
        // {
        //     this.recordsOut.length += this.bufferSize;
        // }

        //Fire Change Detection
        this.cdr.detectChanges();
    }

    abstract fetchIntoBuffer(pageNumber?: number, pageSize?: number, index?: number, records?: number): Promise<PagingResult<T>>;

}



export abstract class LazyDiaryBuffer extends LazyBuffer<Diary>
{

    constructor
    (
        protected tcems: TcemsService,
        bufferLength: number,
        cdr: ChangeDetectorRef
    )
    {
        super(bufferLength,cdr);
    }



    userFetch = result =>
            {
                //Instantiate each result as a diary
                result.records = result.records.map(r => Object.assign(new Diary(),r));

                //And now, go fetch the target of the diary
                result.records.forEach
                (
                    d =>
                    {
                        //instantiate this as a date string before we show the dialog
                        if (d.diaryDueDate != null) d.diaryDueDate = new Date(d.diaryDueDate);
                        if(d.referenceType.code == ReferenceCode.TYPE_ENCOUNTER)
                        {
                            //Fetch an encounter
                            this.tcems.getEncounter(d.referenceId).toPromise().then
                            (
                                e =>
                                {
                                    d.target = e;
                                }
                            )
                        }
                        else if(d.referenceType.code == ReferenceCode.TYPE_PHI)
                        {
                            //Fetch an encounter
                            this.tcems.getPhi(d.referenceId).toPromise().then
                            (
                                e =>
                                {
                                    d.target = e;
                                }
                            )
                        }
                    }
                )

                return result;
            }
}

export class LazyUserDiaryBuffer extends LazyDiaryBuffer
{

    constructor
    (
        tcems: TcemsService,
        private user: User,
        bufferLength: number,
        cdr: ChangeDetectorRef
    )
    {
        super(tcems, bufferLength,cdr);
    }

    fetchIntoBuffer(pageNumber?: number, pageSize?: number, index?:number, records?: number): Promise<PagingResult<Diary>>
    {

        return this.tcems.getDiariesAssignedToUser({userId: this.user.userId , page: pageNumber, pageSize: pageSize, index: index,records: records  }).toPromise()
        .then
        (
            this.userFetch
        )

    }
}

export class LazyEncounterDiaryBuffer extends LazyDiaryBuffer
{
    constructor
    (
        tcems: TcemsService,
        private encounterId: number,
        bufferLength: number,
        private statuses: string[] = ['O','A'],
        cdr: ChangeDetectorRef
    )
    {
        super(tcems, bufferLength,cdr);
    }

    fetchIntoBuffer(pageNumber: number, pageSize: number, index?:number, records?: number): Promise<PagingResult<Diary>>
    {

        return this.tcems.getDiariesForEncounter({encounterId: this.encounterId , page: pageNumber, pageSize: pageSize, statuses:this.statuses, index: index, records: records }).toPromise()
        .then
        (
            this.userFetch
        )

    }
}

export class LazyRelatedToDiaryBuffer extends LazyDiaryBuffer
{
    constructor
    (
        tcems: TcemsService,
        private referenceId: number,
        private referenceType: number,
        bufferLength: number,
        private statuses: string[] = ['O','A'],
        cdr: ChangeDetectorRef
    )
    {
        super(tcems, bufferLength,cdr);
    }

    fetchIntoBuffer(pageNumber: number, pageSize: number, index?:number, records?: number): Promise<PagingResult<Diary>>
    {
        return this.tcems.getDiariesRelatedTo({referenceType:this.referenceType, referenceId: this.referenceId, page: pageNumber, pageSize: pageSize, index: index, records: records, statuses:this.statuses  }).toPromise()

        // return this.tcems.getDiariesRelatedTo({referenceType: this.referenceCode ,referenceId: this.referenceId, page: pageNumber, pageSize: pageSize, statuses:this.statuses, index: index, records: records }).toPromise()
        .then
        (
            this.userFetch
        )

    }
}




export abstract class LazyNoteBuffer extends LazyBuffer<Note>
{

    constructor
    (
        protected tcems: TcemsService,
        bufferLength: number,
        cdr: ChangeDetectorRef
    )
    {
        super(bufferLength,cdr);
    }



    userFetch = (result: PagingResult<Note>) =>
            {
                //Instantiate each result as a note
                result.records = result.records.map(r => Object.assign(new Note(),r));

                // //And now, go fetch the target of the note
                // result.records.forEach
                // (
                //     d =>
                //     {
                //         if(d.referenceType.code == ReferenceCode.TYPE_ENCOUNTER)
                //         {
                //             //Fetch an encounter
                //             this.tcems.getEncounter(d.referenceId).toPromise().then
                //             (
                //                 e =>
                //                 {
                //                     d.target = e;
                //                 }
                //             )
                //         }
                //         else if(d.referenceType.code == ReferenceCode.TYPE_PRACTICE)
                //         {
                //             //Fetch an encounter
                //             this.tcems.getEncounter(d.referenceId).toPromise().then
                //             (
                //                 e =>
                //                 {
                //                     d.target = e;
                //                 }
                //             )
                //         }
                //     }
                // )

                return result;
            }
}


export class LazyEncounterNoteBuffer extends LazyNoteBuffer
{
    constructor
    (
        tcems: TcemsService,
        private encounterId: number,
        bufferLength: number,
        cdr: ChangeDetectorRef
    )
    {
        super(tcems, bufferLength,cdr);
    }

    fetchIntoBuffer(pageNumber?: number, pageSize?: number, index?:number, records?: number): Promise<PagingResult<Note>>
    {

        return this.tcems.getNotesForEncounter({encounterId: this.encounterId , page: pageNumber, pageSize: pageSize, index: index, records: records }).toPromise()
        .then
        (
            this.userFetch
        )

    }
}

export class LazyRelatedToNoteBuffer extends LazyNoteBuffer
{
    constructor
    (
        tcems: TcemsService,
        private referenceType: ReferenceCode,
        private referenceId: number,
        bufferLength: number,
        cdr: ChangeDetectorRef
    )
    {
        super(tcems, bufferLength,cdr);
    }

    fetchIntoBuffer(pageNumber?: number, pageSize?: number, index?:number, records?: number): Promise<PagingResult<Note>>
    {

        return this.tcems.getNotesRelatedTo({referenceType:this.referenceType.code, referenceId: this.referenceId, page: pageNumber, pageSize: pageSize, index: index, records: records  }).toPromise()
        .then
        (
            this.userFetch
        )

    }
}

export class LazyUserNoteBuffer extends LazyNoteBuffer
{
    constructor
    (
        tcems: TcemsService,
        private user: User,
        bufferLength: number,
        cdr: ChangeDetectorRef
    )
    {
        super(tcems, bufferLength,cdr);
    }

    fetchIntoBuffer(pageNumber?: number, pageSize?: number, index?: number, records?: number): Promise<PagingResult<Note>>
    {

        return this.tcems.getNotesForUser({userId: this.user.userId , page: pageNumber, pageSize: pageSize, index: index, records: records }).toPromise()
        .then
        (
            this.userFetch
        )

    }
}



export class LazyEncounterBuffer extends LazyBuffer<Encounter>
{

    // public static SORT_BY_NEXT_APPT_LIST_FIELD: string = "AppointmentDate";
    public static SORT_BY_NEXT_APPT_AGG: SortAggregate = new SortAggregate("AppointmentDate",SortAggregate.AGG_MAX);
    public static SORT_BY_NEXT_APPT:string = "Appointment";
    public static SORT_BY_CREATE_DATE: string = "EncounterCreationDate";
    public static SORT_BY_ENCOUNTER_ID: string = "EncounterId";

    constructor
    (
        protected tcems: TcemsService,
        bufferLength: number,
        private sortSpec: SortSpec = null,
        private options :
                {
                    startDate?: Date,
                    endDate?: Date,
                    patientMemberId?: string,
                    subscriberMemberId?: string,
                    encounterStatus?: number[],
                    clientId?: number [],
                    assignedTo?: number[],
                    encounterTypes?: number[],
                    categories?: number[],
                    includeUnassigned?: boolean,
                    includeAssigned?: boolean,
                    encounterIds?:number[],
                    includeRelatedRecords?:boolean,
                    includeAppointments?: boolean,
                    includeCostData?: boolean,
                    includeOptedOutMembers?:boolean,
                    employerNameContains?:string,
                    q?: string,
                } = {},
        cdr: ChangeDetectorRef,
        private sortBySuppliedEncounters: boolean = false
    )
    {
        super(bufferLength,cdr);
    }

    fetchIntoBuffer(pageNumber?: number, pageSize?: number, index?: number, records?: number): Promise<PagingResult<Encounter>>
    {
        //Insert our paging spec into the options
        let ps = new PagingSpec();
        ps.pageNumber = pageNumber;
        ps.pageSize = pageSize;
        ps.sortSpec = this.sortSpec;
        ps.startIndex = index;
        ps.records = records;
        let newOpts;
        
        if(!this.sortBySuppliedEncounters)
            newOpts = {...this.options, pageResults: true, pagingSpec: ps};    //Page from the api/db
        else
        {
            // newOpts = {...this.options, pageResults: true, pagingSpec: ps};                    //Don't page... we're going to page from here
            // //newOpts.encounterIds = this.options.encounterIds.slice((pageNumber-1)*pageSize,((pageNumber-1)*pageSize) + pageSize);
            newOpts = {...this.options, pageResults: false, pagingSpec: {}};                    //Don't page... we're going to page from here
            if(pageNumber != null && pageSize != null)
                newOpts.encounterIds = this.options.encounterIds.slice((pageNumber-1)*pageSize,((pageNumber-1)*pageSize) + pageSize);
            if(index != null && records != null)
                newOpts.encounterIds = this.options.encounterIds.slice(index, index+records);

        }

        return this.tcems.searchForEncountersPaged(newOpts).toPromise()
        .then
        (
            result =>
            {
                result.records = result.records.map(e => Object.assign(new Encounter(), e));
                result.records.forEach
                (
                    e =>
                    {
                        e.lowCostProviders.forEach(lc => lc.providerLocation.location = Object.assign(new Location(), lc.providerLocation.location));
                        e.encounterProviders.forEach(ep => ep.providerLocation.location = Object.assign(new Location(), ep.providerLocation.location));
                        if(e.providerToBeat?.providerLocation != null)
                            e.providerToBeat.providerLocation.location = Object.assign(new Location(), e.providerToBeat.providerLocation.location);
                        e.subscriberCompany = Object.assign(new Company(), e.subscriberCompany);
                        e.subscriberCompany.hierarchyDetail = Object.assign(new CompanyHierarchy(), e.subscriberCompany.hierarchyDetail);
                    }
                );

                //sort by the encounter id list if we need to
                if(this.sortBySuppliedEncounters)
                {
                    result.totalRecords = this.options.encounterIds.length;
                    result.records.sort((a,b) => newOpts.encounterIds.indexOf(a.encounterId) - newOpts.encounterIds.indexOf(b.encounterId));

                    //Fake the start index
                    result.startIndex = (pageNumber - 1) * pageSize;
                }
                return result;
            }

        )
        .catch(e => {return {totalRecords:0,records:[],startIndex:0} as PagingResult<Encounter>});
    }

}

export class LazyUserEncounterGroupBuffer extends LazyBuffer<EncounterGroup>
{

    public static SORT_BY_FIRST_NAME:string = "FirstName"
    public static SORT_BY_LAST_NAME: string = "LastName"


    constructor
    (
        protected tcems: TcemsService,
        bufferLength: number,
        private sortSpec: SortSpec = null,
        private userId: number = null,
        private includeClosed: boolean = false,
        cdr: ChangeDetectorRef
    )
    {
        super(bufferLength,cdr);
    }

    fetchIntoBuffer(pageNumber?: number, pageSize?: number, index?: number, records?: number): Promise<PagingResult<EncounterGroup>>
    {
        //Insert our paging spec into the options
        let ps = new PagingSpec();
        ps.pageNumber = pageNumber;
        ps.pageSize = pageSize;
        ps.startIndex = index;
        ps.records = records;
        ps.sortSpec = this.sortSpec;


        return this.tcems.getEncounterGroupingsForUser({userId:this.userId,pagingSpec:ps,includeClosed:this.includeClosed}).toPromise()
        .then
        (
            result =>
            {
                //Instantite all the encounters in each result
                result.records = result.records.map(
                    eg => 
                    {
                        let newEg = new EncounterGroup();
                        newEg.phi = Object.assign(new Phi(),eg.phi);
                        newEg.encounters = eg.encounters.map(e => Object.assign(new Encounter(),e));
                        return newEg;
                    });
                return result;
                //console.log(result);
                //return null;
            }

        );
    }

}
