import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Phi } from '../../../model/phi/phi';
import { selectContactTypes } from '../../../state/app.state';
import { ContactType } from '../../../model/util/contact_type';
import { Store } from '@ngrx/store';
import { PhoneNumber, PhoneNumberUpdate } from '../../../model/phi/phone-number';
import { TcemsService } from '../../../api/tcems.service';

@Component({
  selector: 'app-phone-editor',
  templateUrl: './phone-editor.component.html',
  styleUrls: ['./phone-editor.component.scss']
})
export class PhoneEditorComponent implements OnInit {

  private _phoneNumbers: PhoneNumber[];
  private _origPhoneNumbers: PhoneNumber[];

  constructor(private store: Store, private tcems: TcemsService) { }

  @Input() phiId: number;
  @Input() showPhoneEditor: boolean;
  @Input() editablePhi = false;
  @Input() showMarketing = false;
  @Input()
  set phoneNumbers(val: PhoneNumber[])
  {
    this._phoneNumbers = val.map( v => Object.assign(new PhoneNumber(), v));
    this._origPhoneNumbers = val.map(v => Object.assign(new PhoneNumber(), v));
  }
  get phoneNumbers(): PhoneNumber[]
  {
    return this._phoneNumbers;
  }
  @Output() phoneNumbersChange: EventEmitter<PhoneNumber[]> = new EventEmitter<PhoneNumber[]>();
  @Output() showPhoneEditorChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  contactTypes$ = this.store.select(selectContactTypes);
  ContactTypes: ContactType[];

  ngOnInit(): void {
    this.contactTypes$.subscribe(t => {this.ContactTypes = t; });
  }

  updatePhoneNumber(phoneToUpdate: PhoneNumber): void {
    let phoneNumberUpdate = new PhoneNumberUpdate();
    phoneNumberUpdate.phoneNumber = phoneToUpdate.phoneNumber;
    phoneNumberUpdate.phoneType = phoneToUpdate.phoneType;
    phoneNumberUpdate.isMobileNumber = phoneToUpdate.isMobileNumber;
    phoneNumberUpdate.active = phoneToUpdate.active;
    phoneNumberUpdate.phoneId = phoneToUpdate.phoneId;

    this.tcems.updatePhoneNumber
    (
      {
        phiId: this.phiId,
        phoneId: phoneToUpdate.phoneId,
        phoneNumberUpdate
      }
    ).toPromise()
        .then
        (
          result =>
          {
            phoneNumberUpdate = result;
            this.refreshPhoneNumbers();
          }
        )
        .catch
        (
          e =>
          {
            console.log('Update Phone Number Failed: ' + e);
          }
        );
  }

  createPhoneNumber(phoneToAdd: PhoneNumber): void {
    let phoneNumberUpdate = new PhoneNumberUpdate();
    phoneNumberUpdate.phoneNumber = phoneToAdd.phoneNumber;
    phoneNumberUpdate.phoneType = phoneToAdd.phoneType;
    phoneNumberUpdate.isMobileNumber = phoneToAdd.isMobileNumber;
    phoneNumberUpdate.active = phoneToAdd.active;
    phoneNumberUpdate.phoneId = phoneToAdd.phoneId;

    this.tcems.createPhoneNumber
    (
      {
        phiId: this.phiId,
        phoneNumberUpdate
      }
    ).toPromise()
        .then
        (
          result =>
          {
            phoneNumberUpdate = result;
            this.refreshPhoneNumbers();
          }
        )
        .catch
        (
          e =>
          {
            console.log('Create Phone Number Failed: ' + e);
          }
        );
  }


  refreshPhoneNumbers(): void
  {
    this.tcems.getPhi(this.phiId).toPromise()
    .then
    (
      result =>
        this.phoneNumbers = result.phoneNumbers
    )
  }

  addPhone(): void
  {
    const newPhone = new PhoneNumber();
    newPhone.phoneType.code = this.ContactTypes[0].code;
    newPhone.phoneType.description = this.ContactTypes[0].description;
    this._phoneNumbers.push(newPhone);
  }

  updateButton(event): void
  {
    // Compare original list with current list for changes
    this._phoneNumbers.forEach(( phNum, idx) => {
      if (idx < (this._origPhoneNumbers.length)) {
        if (JSON.stringify(phNum) !== JSON.stringify(this._origPhoneNumbers[idx])) {
          this.updatePhoneNumber(phNum);
        }
      } else {
        this.createPhoneNumber(phNum);
      }
    });

    this.showPhoneEditor = false;

    this.phoneNumbersChange.emit(this._phoneNumbers);
    this.showPhoneEditorChange.emit(this.showPhoneEditor);
  }

  cancelButton(event): void
  {
    this.showPhoneEditor = false;
    this.showPhoneEditorChange.emit(this.showPhoneEditor);
  }

}
