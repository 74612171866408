import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { SinglePanelComponent } from 'src/app/base/single-panel/single-panel.component';
import { Practice } from 'src/app/model/provider/practice';
import { ProviderLocation, ProviderLocationUpdate } from 'src/app/model/provider/provider_location';
import { Location } from 'src/app/model/provider/location';
import { TcemsService } from 'src/app/api/tcems.service';
import { PagingSpec } from 'src/app/model/util/resultpaging';
import { Provider } from 'src/app/model/provider/provider';


export class ProviderLocationByLocationId
{
  constructor(location: Location, providerLocations: ProviderLocation[])
  {
    this.locationId = location.locationGeocodeId;
    this.location = location;
    this.providerLocations = providerLocations;
  }

  locationId: number;
  location: Location;
  providerLocations: ProviderLocation[];


  get active():boolean
  {
    return this.providerLocations.some(pl => pl.active);
  }

  get confirmed(): boolean
  {
    return this.providerLocations.some(pl => pl.confirmed);
  }

  get isFacility(): boolean
  {
    return this.providerLocations.some(pl => pl.providerIsOrganization);
  }

  get facilities(): ProviderLocation[]
  {
    let facilities = [... this.providerLocations.filter(pl => pl.providerIsOrganization)];
    facilities.sort((a,b) => a.providerName > b.providerName ? 1 : -1);
    return facilities;
  }

  get facilityCount(): number
  {
    return this.facilities == null ? 0 : this.facilities.length;
  }
}

@Component({
  selector: 'app-practice-location-editor',
  templateUrl: './practice-location-editor.component.html',
  styleUrls: ['./practice-location-editor.component.scss']
})
export class PracticeLocationEditorComponent
implements OnInit, OnChanges
// extends SinglePanelComponent<Practice>
{
  showUpdateDialog: boolean = false;
  providerLocationsByLocationId: ProviderLocationByLocationId[] = [];//Map<number,ProviderLocation[]>;
  locations: Location[] = [];
  providerLocations: ProviderLocation[] = [];
  selectedProviderLocation: ProviderLocation;

  @Input() provider:Provider = null;
  @Input() practice:Practice = null;
  @Input() edit:boolean = false;
  @Input() select:boolean = false;

  @Output() locationSelected:EventEmitter<ProviderLocation> = new EventEmitter<ProviderLocation>();


  tableFilter;

  constructor(private tcems: TcemsService)
  {
    // super();
  }


  ngOnChanges(changes: SimpleChanges): void
  {
    this.loadData();
  }

  ngOnInit()
  {
    this.loadData();
  }

  loadData()
  {
    this.tableFilter = {confirmed: [{value:true,matchMode:'contains',operator:'and'}]};

    // console.log(this.tableFilter);

    // Load from practice if we have that. Don't page results. Just get everything
    if (this.practice != null)
    {
      this.tcems.getProviderLocationsForPractice({ein:this.practice.ein,pageResults:false,pagingSpec:new PagingSpec()}).toPromise()
      .then
      (
        providerLocations =>
        {
          this.loadProviderLocations(providerLocations.records);
        }
      )
    }

    //Alternatively, load from our provider
    if(this.provider != null)
    {
      this.tcems.getProviderLocationsForProvider({npi:this.provider.npi,pageResults:false,pagingSpec:new PagingSpec()}).toPromise()
      .then
      (
        providerLocations =>
        {
          // console.log(providerLocations);
          this.loadProviderLocations(providerLocations.records);
        }
      )
    }

    // this.tcems.getProvidersForPractice({ein:this.modelData.ein,pageResults:false,pagingSpec:new PagingSpec()}).toPromise()
    // .then
    // (
    //   providers =>
    //   {
    //     this.modelData.providers = providers.records.map(p => Object.assign(new Provider(), p));
    //   }
    // )
  }

  loadProviderLocations(providerLocations: ProviderLocation[])
  {

    //this.modelData.providerLocations = providerLocations.records.map(p => Object.assign(new ProviderLocation(), p));
    this.providerLocations = providerLocations.map(p => Object.assign(new ProviderLocation(), p));
    let ids:Set<number> = new Set(providerLocations.map(pl => pl.location.locationGeocodeId));
    this.locations = Array.from(ids).map( l => this.providerLocations.find(pl => pl.location.locationGeocodeId == l).location);
    this.providerLocationsByLocationId = this.locations.map( loc => new ProviderLocationByLocationId(loc,this.providerLocations.filter(pl => pl.location.locationGeocodeId == loc.locationGeocodeId)));


  }




  // isLocationActive(location:Location)
  // {
  //   return this.providerLocationsByLocationId.get(location.locationId).some(pl => pl.active);
  // }

  // isLocationConfirmed(location:Location)
  // {
  //   return this.providerLocationsByLocationId.get(location.locationId).some(pl => pl.confirmed);
  // }

  toggleLocationActive(event, location:ProviderLocationByLocationId)
  {
    this.showUpdateDialog = true;
    let newVal:boolean =  !location.active; //!this.isLocationActive(location);
    Promise.all(location.providerLocations.map //this.providerLocationsByLocationId.get(location.locationId).map
    (
      pl =>
        {
          pl.active = newVal;
          //Persist for each location
          return this.tcems.updateProviderLocation({ein:pl.ein, providerLocationId: pl.id, providerLocation:pl.toUpdate()}).toPromise();

        }
    )).then(result => this.showUpdateDialog = false);


  }

  toggleLocationConfirmed(event, location:ProviderLocationByLocationId)
  {
    this.showUpdateDialog = true;

    let newVal:boolean =  !location.confirmed; //!this.isLocationConfirmed(location);
    Promise.all(location.providerLocations.map //this.providerLocationsByLocationId.get(location.locationId).map
    (
      pl =>
      {
        pl.confirmed = newVal;
        if(newVal)
          pl.conversionLocation = false; //also clear conversion location setting
        return this.tcems.updateProviderLocation({ein:pl.ein, providerLocationId: pl.id, providerLocation:pl.toUpdate()}).toPromise();
      }
    )).then(result => this.showUpdateDialog = false);

    //Persist

  }

  providerLocationSelected(event)
  {
    // Fire
    // console.log("selected!");
    // console.log(event.data);
    this.locationSelected.emit(event.data);
  }

  providerLocationUnselected(event)
  {
    //Fire
    this.locationSelected.emit(null);
  }

  commitPhone(providerLocation: ProviderLocation)
  {
    //Issue a provider location update statement
    let plu:ProviderLocationUpdate = new ProviderLocationUpdate();
    //no need to update if nothing has changed
    
    plu.phoneNumber = providerLocation.phoneNumber;
    plu.faxNumber = providerLocation.faxNumber;


    this.tcems.updateProviderLocation({ein: providerLocation.ein,providerLocationId:providerLocation.id,providerLocation:plu}).toPromise().then
    (
      
    )
  }

}
