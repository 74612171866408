<!-- <div *ngIf="modelData != null" class="grid scroll-overflow fill-vert" style="width: 100%;"> -->
<div *ngIf="modelData != null" class="grid" style="width: 100%;">

    <div class="col-12 mt-3 mb-3">
        <!-- Practice and Alias Details -->
        <p-card header="Practice">
            <div class="grid" style="width: 100%">
                <div class="col-6 p-3 fill-vert">
                    <div class="grid" >
                        <div class="col-4 p-3">
                            <span class="p-float-label">
                                <input pInputText id="ein" type="text" [disabled]="true" [(ngModel)]="modelData.ein">
                                <label for="ein">EIN</label>
                            </span>
                        </div>
                        <div class="col-8"></div>
                        <div class="col-12 p-3">
                            <span class="p-float-label">
                                <input pInputText id="practiceName" type="text" [disabled]="true" [(ngModel)]="modelData.practiceName">
                                <label for="practiceName">Practice Name</label>
                            </span>
                        </div> 
                        <div class="col-12 m-2" style="height:400px">
                            <app-note-scroller [allowEdit]="true" title="Practice Notes" [referenceCode]="noteScrollerType" [relatedTo]="modelData.practiceId" rowBufferLen="2" ></app-note-scroller>
                        </div>
  
                    </div>
                </div>
                <div class="col-6 p-3">
                    <p-table [value]="modelData.aliases" [scrollable]="true" scrollHeight="400px">
                        <ng-template pTemplate="header">
                            <tr>
                                <th style="width: 3rem;">Active</th>
                                <th>Practice Name</th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-alias>
                            <tr>
                                <td style="width: 3rem;"><p-checkbox [binary]="true" [ngModel]="(alias.aliasName == modelData.practiceName)" [disabled]="alias.aliasName == modelData.practiceName" (onChange)="setPracticeName(alias.aliasId)"></p-checkbox> </td>
                                <td>{{alias.aliasName}}</td>
                            </tr>
                        </ng-template>
                    </p-table>     
                </div>         
            </div>
        </p-card>
    </div>
    <!-- <div class="col-2"></div>
    <div class="col-5 scroll-overflow mt-3 mb-3" style="max-height: 400px">
        <p-card header="Providers">
            <div class="grid">
                <div class="col-12">
                    <p-table [value]="modelData.providers">
                        <ng-template pTemplate="header">
                            <tr>
                                <th style="width: 3rem;">Org?</th>
                                <th>Name</th>
                                <th>NPI</th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-provider>
                            <tr>
                                <td style="width: 3rem;">
                                    <p-checkbox [binary]="true" [disabled]="true" [ngModel]="provider.isOrganization"></p-checkbox>
                                </td>
                                <td>{{provider.isOrganization ? provider.organizationName : provider.providerFirstName + ' ' + provider.providerLastName}}</td>
                                <td>{{provider.npi}}</td>

                            </tr>
                        </ng-template>
                    </p-table>
                </div>
            </div>
        </p-card>
    </div> -->
    <div class="col-12 mt-3 mb-3" >
        <app-practice-location-editor [practice]="modelData" [edit]="true"></app-practice-location-editor>
    </div>
</div>