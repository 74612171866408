import { Component, Input, OnChanges, OnInit, Optional, SimpleChanges } from '@angular/core';
import { B2CUserService } from 'src/app/api/b2cuser.service';
import { B2CUser } from 'src/app/model/phi/b2cuser';
import { Phi } from 'src/app/model/phi/phi';
import { EndUser } from 'src/app/model/util/end_user';
import { TcemsUtilitiesService } from 'src/app/util/tcems-utilities.service';

interface Option {
  name: string,
}

@Component({
  selector: 'app-b2cuser-list',
  templateUrl: './b2cuser-list.component.html',
  styleUrls: ['./b2cuser-list.component.css']
})
export class B2CUserListComponent implements OnInit, OnChanges
{
    @Input() phi: Phi
    b2cUsers: B2CUser[] = []
    selectedB2CUser: B2CUser

    options: Option[]
    selectedOption: Option
    
    name: string 
    dateOfBirthSelected: boolean = false
    selectedDate: Date

    endUserAlreadyExists: boolean = false;


    constructor(private b2cUserService: B2CUserService, private util: TcemsUtilitiesService) {
      this.options = [
        {name: 'First Name'},
        {name: 'Last Name'},
        {name: 'Date of Birth'}
      ];
    }

    ngOnChanges(changes: SimpleChanges): void 
    {
      this.checkForExistingEndUser();
    }

    ngOnInit(): void 
    {
      this.checkForExistingEndUser();
    }

    checkForExistingEndUser()
    {
      this.endUserAlreadyExists = this.phi.endUser != null;
    }

    dropdownChanged(selectedOption) {
      if (selectedOption.value.name === 'Date of Birth') {
        this.dateOfBirthSelected = true;
      } else {
        this.dateOfBirthSelected = false;
      }
    }
    
    async retrieveUsers() {
      if (this.selectedOption.name === "First Name") {
        this.b2cUserService.retrieveUsersByFirstName(this.phi.phiId, this.name).toPromise().then(
          users => {
            if (users.length < 1) {
              this.b2cUsers = []
              return
            } 
            this.b2cUsers = users.map(u => Object.assign(new B2CUser(), u))
          } 
        ).catch
        (
          error => console.log(error)
        )
      }
      
      if (this.selectedOption.name === "Last Name") {
        this.b2cUserService.retrieveUsersByLastName(this.phi.phiId, this.name).toPromise().then(
          users => this.b2cUsers = users.map(u => Object.assign(new B2CUser(), u))
        )
      }
      
      // Tokenized
      if (this.selectedOption.name === "Date of Birth") {
        this.b2cUserService.retrieveUsersByDateOfBirth(this.phi.phiId, this.selectedDate).toPromise().then(
          users => this.b2cUsers = users.map(u => Object.assign(new B2CUser(), u))
        )
      }
    }

    createEndUserFromRevisedPhi() {
      this.b2cUserService.submitUserSelection(this.phi.phiId, this.selectedB2CUser.id)
      .toPromise()
      .then
      (
        result =>
        {
          //WE should be mapped now
          this.phi.endUser = Object.assign(new EndUser(), result);
          this.endUserAlreadyExists = true;
        }
      )
      .catch
      (
        error => this.util.displayError("Could Not Link End User",error.errorMessage)
      )
    }
}
