<p-table [value]="categoryResults" dataKey="practice.ein" sortField="practice.practiceName">
    <ng-template pTemplate="header">
        <tr>
            <th style="width: 3rem"></th>
            <th pSortableColumn="practice.practiceName">Practice <p-sortIcon field="practice.practiceName"></p-sortIcon></th>
            <th style="width: 3rem"></th>
            <th pSortableColumn="closestLocation.distance.length">Locations  <p-sortIcon field="closestLocation.distance.length"></p-sortIcon></th>
            <th>Min</th>
            <th>Max</th>
            <th>Avg</th>
            <th pSortableColumn="categoryBasedResult.n">Count <p-sortIcon field="categoryBasedResult.n"></p-sortIcon></th>
            <th pSortableColumn="categoryBasedResult.median">Suggested Price <p-sortIcon field="categoryBasedResult.median"></p-sortIcon></th>
        </tr>
    </ng-template>
    <ng-template pTemplate="body" let-result let-expanded="expanded">
        <tr>
            <td>
                <button type="button" pButton pRipple [pRowToggler]="result" class="p-button-text p-button-rounded p-button-plain" [icon]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'" (click)="loadInstances(result.practice.ein, result.categoryBasedResult);"></button>
            </td>
            <td><span>{{result.practice.practiceName}}</span><span><p-tag *ngIf="result.tier != null && result.tier >0">Tier {{result.tier}}</p-tag></span></td>
            <td>
                <button type="button" pButton pRipple class="p-button-text p-button-rounded p-button-plain" icon="pi pi-pencil" (click)="showPracticeEditor[result.practice.ein] = true"></button>
                <!-- Pracice Editor Dialog -->
                <p-dialog appendTo="body" header="Edit Practice" [(visible)]="showPracticeEditor[result.practice.ein]" [style] ="{width: '900px', 'max-height': '800px' }">
                    <!-- <app-practice-editor [(modelData)]="result.practice"></app-practice-editor> -->
                    <app-practice-editor *ngIf="showPracticeEditor[result.practice.ein]" [modelId]="result.practice.ein"></app-practice-editor>
                </p-dialog>
            </td>
            <td>
                <app-location-tag *ngFor="let location of result.locations" [location]="location"></app-location-tag>
            </td>
            <td>{{result.categoryBasedResult.min | currency }}</td>
            <td>{{result.categoryBasedResult.max | currency }}</td>
            <td>{{result.categoryBasedResult.mean | currency }}</td>
            <td>{{result.categoryBasedResult.n}}</td>
            <td>{{result.categoryBasedResult.median | currency }}</td>
        </tr>
    </ng-template>
    <ng-template pTemplate="rowexpansion" let-result>
        <tr>
            <td colspan="8">
                <app-instancelist title="Show Details" [instancesAndLines]="instances[result.practice.ein]"  (chosenLines)="instanceChosen(result, result.practice, $event)" ></app-instancelist>
            </td>
        </tr>
    </ng-template>
</p-table>
