<p-toast position="top-center" key="rUpdate"></p-toast>
<p-tree [value]="reportTree" selectionMode="checkbox" [(selection)]="selectedReports"></p-tree>
<button pButton type="button" icon="pi pi-download" (click)="downloadSelectedReports()" style="width:120px">Download</button>      


    <div *ngIf="false" class="grid">
        <div class="col-4">
            <!-- Menu to navigate the path -->
            <!-- <p-slideMenu appendTo="body" [model]="companyReportMenu" [viewportHeight]="250" [style]="{width:'200px'}" ></p-slideMenu> -->
        </div>
        <div class="col-8">
            <!-- Table of Files -->
            <!-- <p-table *ngIf="companyReports!=null"[value]="companyReports"> -->
                <ng-template pTemplate="header">
                <tr>
                    <th>Name</th>
                    <th>Type</th>
                    <th>Download</th>
                </tr>
                </ng-template>
                <ng-template pTemplate="body" let-report>
                <tr>
                    <td>{{report.reportExecutionSpec.reportSpecName}}</td>
                    <td>
                        <i *ngIf="report.reportExecutionSpec.outputFormat.fileExtension == 'pdf'" class="pi pi-file-pdf" style="font-size: 2rem; color: red;"></i>
                        <i *ngIf="report.reportExecutionSpec.outputFormat.fileExtension == 'xlsx'" class="pi pi-file-excel" style="font-size: 2rem; color: green"></i>
                    </td>
                    <td>
                        <!-- <button pButton type="button" icon="pi pi-download" (click)="downloadReport(report)" style="width:120px">Download</button>       -->
                    </td>
                </tr>
                </ng-template>
            <!-- </p-table> -->
        </div>
    </div>