import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TcemsService } from 'src/app/api/tcems.service';
import { Appointment } from 'src/app/model/encounter/appointment';
import { Encounter } from 'src/app/model/encounter/encounter';
import { ProviderLocation } from 'src/app/model/provider/provider_location';
import { TcemsUtilitiesService } from 'src/app/util/tcems-utilities.service';

@Component({
  selector: 'app-appointment-editor',
  templateUrl: './appointment-editor.component.html',
  styleUrls: ['./appointment-editor.component.scss']
})
export class AppointmentEditorComponent implements OnInit {

  //@Input() appointments: Appointment[] = [];
  @Input() encounter: Encounter;

  get appointments() :Appointment[]
  {
    return this.encounter.appointments;
  }

  set appointments(val: Appointment[])
  {
    this.encounter.appointments = val;
  }

  tempAppointments:{ [id: number]: Appointment; } = {};
  availableProviderLocations: ProviderLocation[] = [];
  newRows:number[] = [];


  @Output() appointmentChange: EventEmitter<Appointment> = new EventEmitter<Appointment>();

  constructor(private tcems: TcemsService, public util: TcemsUtilitiesService)
  { }

  ngOnInit(): void 
  {
    //convert all our appointments to actual appointments
    this.appointments = this.appointments.map(a => Object.assign(new Appointment(), a));

    //So that our status dropdown works, replace the generated status objects with ones from our dropdown
    this.appointments.forEach( a=> a.status = a.status == null ? null : this.util.appointmentStatuses.find(s => s.statusCode == a.status.statusCode));

    //convert our appointment dates to a real date (instead of a string)
    this.appointments.forEach(a => a.appointmentDate = new Date(a.appointmentDate));

    //Use the provider locations from our supplied encounter
    //Also, deduplicate them :-)
    if(this.encounter.encounterProviders != null)
    {
      let locationIds = new Set(this.encounter.encounterProviders.map(pl => pl.providerLocation.id));
      this.availableProviderLocations = [...locationIds].map(l => this.encounter.encounterProviders.find(p => p.providerLocation.id ==l).providerLocation);
    }
    else
    {
      this.availableProviderLocations = [];    
    }


  }


  onRowEditInit(appt: Appointment) {
    this.tempAppointments[appt.id] = Object.assign(new Appointment(), appt);
}

addNewRow(table)
{
  //Add our new Row
  this.appointments = [...this.appointments,new Appointment()];

  //Mark that row as new
  this.newRows = [...this.newRows, this.appointments.length-1];

  table.initRowEdit(this.appointments[this.appointments.length-1]);
}

onRowEditSave(appt: Appointment, index: number) 
{

  //See if this is a new row, or an update row
  if(this.newRows.includes(index))
  {
    //commit by inserting
    //Let's try to commit our records
    this.tcems.createAppointment({encounterId: this.encounter.encounterId, appt:appt.asUpdate()}).toPromise()
    .then
    (
      result =>
      {
        //If we're successful, delete the new index entry
        this.newRows = this.newRows.filter(r => r != index);
      }
    )
  }
  else
  {
    //Let's try to commit our records
    this.tcems.editAppointment({encounterId: this.encounter.encounterId,appointmentId: appt.id, appt:appt.asUpdate()}).toPromise()
    .then
    (
      result =>
      {
        //If we're successful, delete the cloned, emp appointment
        delete this.tempAppointments[appt.id];
      }
    )
  }
  
    
}

onRowEditCancel(appt: Appointment, index: number) 
{
  //For new rows, we delete the row
  if(this.newRows.includes(index))
  {
    //Delete that new row
    delete this.appointments[index];
    
    //Clear out the new row index entry
    this.newRows = this.newRows.filter(r => r != index);
  }
  else
  {
    //For update rows, we just replace everything back to the way it was
    this.appointments[index] = this.tempAppointments[appt.id];
    delete this.tempAppointments[appt.id];
  }
}
}
