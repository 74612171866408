import { User } from '../util/user';
import { ContactType } from '../util/contact_type';


export class EmailAddress
{
  active: boolean;
  confirmed: boolean;
  effectiveDate: Date;
  emailAddress: string;
  emailId: number;
  emailType: ContactType;
  modifiedBy: User;

  constructor()
  {
    this.emailAddress = null;
    this.emailType = new ContactType();
    this.active = true;
  }

}

export class EmailAddressUpdate
{
    active: boolean;
    confirmed: boolean;
    effectiveDate: Date;
    emailAddress: string;
    emailId: number;
    emailType: ContactType;
}
