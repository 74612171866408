import { Claim } from "./claim";
import { Procedure } from "./procedure";

export class Line
{
    lineId:number;
    procedure:Procedure;
    modifier1:string;
    modifier2:string;
    cost:number;
    units:number;
    unitCost:number;
    claim: Claim;

    constructor() {}
}