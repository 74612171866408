import { Action, createReducer, on } from '@ngrx/store';
import { EncounterStatus } from 'src/app/model/encounter/encounter_status';
import { ClosureReason } from 'src/app/model/encounter/closure_reason';
import { loadEncounterClosureReasons, loadEncounterStatuses, loadSources, loadEncounterTypes, loadTransactionTypes, loadNetworks, loadNetworkGroups, loadCategories, updateUserState, loadContactTypes, loadCheckingAccounts, loadAppointmentStatuses, loadPlanTypes, loadActiveUsers, loadCompanyTypes, loadProcedureHierarchy, updatedLoggedInUser, loadCaptives } from 'src/app/actions/api/tcems.actions';
import { ApiService } from 'src/app/api/api.service';
import { environment } from 'src/environments/environment';
import { Source } from 'src/app/model/support/source';
import { EncounterType } from 'src/app/model/encounter/encounter';
import { TransactionType } from 'src/app/model/finance/transaction';
import { Network, NetworkGroup } from 'src/app/model/support/network';
import { Category } from 'src/app/model/claim/category';
import { User, UserState } from 'src/app/model/util/user';
import { loggedIn } from 'src/app/actions/login.actions';
import { ContactType } from 'src/app/model/util/contact_type';
import { CheckingAccount } from 'src/app/model/finance/checking_account';
import { AppointmentStatus } from 'src/app/model/encounter/appointment_status';
import { PlanType } from 'src/app/model/phi/plan';
import { ProcedureHierarchy } from 'src/app/model/claim/procedure_hierarchy';
import { CompanyType } from 'src/app/model/support/company_type';
import { Captive } from 'src/app/model/support/captive';


export const globalsFeatureKey = 'globals';


export const initialStatusState: EncounterStatus[] = [];

export const initialClosureReasonState: ClosureReason[] = [];

export const initialSourceState: Source[] = [];
export const initialCategoryState: Category[] = [];

export const initialNetworkState: Network[] = [];
export const initialNetworkGroupState: NetworkGroup [] = [];

export const initialEncounterTypeState: EncounterType[] = [];
export const initialPlanTypeState: PlanType[] = [];

export const initialTransactionTypeState: TransactionType[] = [];

export const initialContactTypeState: ContactType[] = [];

export const initialCheckingAccounts: CheckingAccount[] = [];

export const initialCurrentUser: User = null;


export const initialUserState: UserState = null;

export const initialActiveUsers: User[] = [];

export const initialAppointmentStatuses: AppointmentStatus[] = [];

export const initialCompanyTypes: CompanyType [] = [];

export const initialCaptives: Captive [] = [];

export const initialProcHierarchies: {cpt:ProcedureHierarchy,hcpcs:ProcedureHierarchy} = {cpt:null,hcpcs:null};

// export class EncounterStatusReducer
// {
//   constructor(private apiService: ApiService){}

//   createReducer()
//   {
//     // handle specific action with the help of your injected service and return new states, this is the pure reducer function
//     // return (state: EncounterStatus[], action: Action) => {
//     //   if(action == loadEncounterStatuses /* or better still action instanceof SomethingUsefulAction, but this makes for another post ;-)*/) {
//     //     return {...state, {useful: this.usefulService.something(action.payload)}}
//     //   }
//     return createReducer
//     (
//       initialStatusState,
//       on(loadEncounterStatuses , () =>
//       {
//         this.apiService.makeAPIGetCall
//         (
//           environment.tcemsAPIBase,
//           environment.tcemsAPI_util,
//           [environment.tcemsAPIScope_util],
//           {}
//         ).then((result: EncounterStatus[]) => {return result} )
//       })
//     )
//   }

// }
export const encounterStatusReducer = createReducer(
  initialStatusState,
  on(loadEncounterStatuses, (state, {statuses}) => [...statuses])
);

export const encounterClosureReasonreducer = createReducer(
  initialClosureReasonState,
  on(loadEncounterClosureReasons, (state,{closureReasons}) => [...closureReasons])
);

export const appointmentStatusReducer = createReducer(
  initialAppointmentStatuses,
  on(loadAppointmentStatuses, (state,{appointmentStatuses}) => [...appointmentStatuses])
);

export const sourceReducer = createReducer(
  initialSourceState,
  on(loadSources, (state,{sources}) => [...sources])
);

export const networkReducer = createReducer(
  initialNetworkState,
  on(loadNetworks, (state,{networks}) => [...networks])
);

export const categoryReducer = createReducer(
  initialCategoryState,
  on(loadCategories, (state,{categories}) => [...categories])
);

export const networkGroupReducer = createReducer(
  initialNetworkGroupState,
  on(loadNetworkGroups, (state,{networkgroups}) => [...networkgroups])
);

export const encounterTypeReducer = createReducer(
  initialEncounterTypeState,
  on(loadEncounterTypes, (state,{encounterTypes}) => [...encounterTypes])
);

export const planTypeReducer = createReducer(
  initialPlanTypeState,
  on(loadPlanTypes, (state,{planTypes}) => [...planTypes])
);

export const transactionTypeeReducer = createReducer(
  initialTransactionTypeState,
  on(loadTransactionTypes, (state,{transactionTypes}) => [...transactionTypes])
);

export const contactTypeReducer = createReducer(
  initialContactTypeState,
  on(loadContactTypes, (state,{contactTypes}) => [...contactTypes])
)
export const checkingAccountReducer = createReducer(
  initialCheckingAccounts,
  on(loadCheckingAccounts, (state,{accounts}) => [...accounts])
)
export const userReducer = createReducer(
  initialCurrentUser,
  on(loggedIn, (state,action) => action.currentUser),
  on(updatedLoggedInUser, (state,action) => action.user)
);

export const activeUsersReducer = createReducer(
  initialActiveUsers,
  on(loadActiveUsers, (state,{users}) => [...users])
);

export const userStateReducer = createReducer(
  initialUserState,
  on(updateUserState, (state, action) => action.userState)
);

export const companyTypesReducer = createReducer(
  initialCompanyTypes,
  on(loadCompanyTypes, (state, action) => action.companyTypes)
);

export const captivesReducer = createReducer(
  initialCaptives,
  on(loadCaptives, (state, action) => action.captives)
);

export const procHierarchyReducer = createReducer(
  initialProcHierarchies,
  on(loadProcedureHierarchy, (state, action) => action)
);
