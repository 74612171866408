import { formatDate } from '@angular/common';
import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { ReportParamSpec, RuntimeParameter } from 'src/app/model/reports/report_spec';

@Component({
  selector: 'app-reportparameter',
  templateUrl: './reportparameter.component.html',
  styleUrls: ['./reportparameter.component.scss']
})
export class ReportparameterComponent implements OnInit {

  @Input() parameter: RuntimeParameter;
  @Input() initToDefault: boolean;
  @Output() parameterChange: EventEmitter<RuntimeParameter> = new EventEmitter<RuntimeParameter>();
  constructor() { }

  dateInput: Date = null;
  numberInput: number = null;
  stringInput: string = null;
  booleanInput: boolean = null;

  ngOnInit(): void 
  {
    //Set the value to the default value
    if(this.parameter.value == null && this.initToDefault != null && this.initToDefault)
    {
      this.parameter.value = this.parameter.param.defaultValue;

      if(this.parameter.param.dataType == "number")
      {
        this.numberInput = parseInt(this.parameter.value);
      }

      else if (this.parameter.param.dataType == "boolean")
      {
        this.booleanInput = this.parameter.value === 'true';
      }

      else if(this.parameter.param.dataType == "DateTime")
      {
        this.dateInput = new Date(this.parameter.value);
      }
      else
      {
        this.stringInput = this.parameter.value;
      }

    }

  }

  dateValueSet(value)
  {
    this.parameter.value = formatDate(value, "MM/dd/yyyy","en-us");
    this.parameterChange.emit(this.parameter);
  }

  dateFieldExit()
  {
    this.dateValueSet(this.dateInput);
  }
  // dateValueChanged(event:InputEvent)
  // {
  //   //This will go in as a straight string. So, let's see what we have so far
  //   //Strip out any slashes
  //   let nakedDate = event.data.replace("/","");
  //   if(nakedDate.length == 8)
  //   {
  //     //This is a full date string
    
  //     let d = new Date(Date.parse(nakedDate.substring(0,1) + "/" + nakedDate.substring(2,3) + "/" + nakedDate.substring(4)));
  //     this.parameter.value = formatDate(d, "MM/dd/yyyy","en-us");
  //     this.parameterChange.emit(this.parameter);
  //   }
  //   //Else, we do nothing, and just keep waiting 
  // }
  

  stringValueSet()
  {
    this.parameter.value = this.stringInput;
    this.parameterChange.emit(this.parameter);
  }

  boolValueSet()
  {
    this.parameter.value = this.booleanInput.toString();
    this.parameterChange.emit(this.parameter);
  }

  numberValueSet(event)
  {
    this.parameter.value = event.value?.toString();
    this.parameterChange.emit(this.parameter);
  }

  // numberValueChanged(e)
  // {
  //   this.parameter.value = event.data;
  //   this.parameterChange.emit(this.parameter);  
  // }

}
