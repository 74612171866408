export class ClosureReason
{
    reasonCode: number;
    reasonDescription: string;


    public static  PATIENT_CHANGED_MIND: number = 1;
    public static  REWARD_LIMIT: number = 2;
    public static  MIN_SAVINGS_NOT_MET: number = 3;
    public static  NOT_ENOUGH_PROC_DATA: number  = 4;
    public static  NEVER_SCHEDULED: number = 5;
    public static  STOPPED_RESPONDING: number = 6;
    public static  APPOINTMENT_CANCELLED: number = 7;
    public static  NO_CLAIM_RECEIVED: number = 8;
    public static  PROC_NOT_ELIGIBLE: number = 9;
    public static  MEMBER_DECLINED_LOW_COST_OPTIONS: number = 10;
    public static  PROCEDURE_BEFORE_ENCOUNTER: number = 11;
    public static  PLAN_NOT_ELIGIBLE: number = 12;
    public static  NOT_LOW_COST_OPTION: number = 13;   // member used provider to beat
    public static  OTHER_REASON: number = 14;
    public static  PAYOR_DENIED: number = 15;
    public static  REF_PROVIDER_DECLINED: number = 16;
    public static  NO_CONTACT_INFO: number = 17;
    public static  MEM_DECLINED_MET_DEDUCTBLE: number = 18;
    public static  MEM_DECLINED_INCENTIVE_LOW: number = 19;
    public static  MEM_DECLINED_PROVIDER_TRUST: number = 20;
    public static  ALREADY_AT_LOW_COST: number = 21;
    public static  MEM_NOT_APPROPRIATE_FOR_OUTREACH: number = 22;
    public static  MEM_OPTED_OUT_OF_PROG: number = 23;
    public static  DUPLICATE_ENCOUNTER: number = 24;
    public static  TEST_PROCEDURE_CANCELED: number = 25;
    public static  AUTH_WAS_DENiED: number = 26;

}