import { Phi } from "../phi/phi";

export class EndUser
{
    userId: number;
    emailAddress: string;
    deviceId: string;
    phoneNumber: string;
    azureId: string;
    phi: Phi;
    preferences: EndUserPreferences
}

export class EndUserPreferences
{
    promoTexts: boolean;
    businessTexts: boolean;
    appointmentReminderTexts: boolean;
    businessEmails: boolean;
    promoEmails: boolean;
    isPinInlocked: boolean;


}

export class ContactPreferences

{
    monday: TriDayBool;
    tuesday: TriDayBool;
    wednesday: TriDayBool;
    thursday: TriDayBool;
    friday: TriDayBool;
    saturday: TriDayBool;
    sunday: TriDayBool;
}

export class TriDayBool
{
    eightToTwelve: boolean;
    twelveToFour: boolean;
    fourToEight: boolean;
}
