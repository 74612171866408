
<div>

<p-table [value]="lines" dataKey="lineId" selectionMode="single" [(selection)]="selectedLine" (onRowSelect)="selectLine($event)" (onRowUnselect)="unselectLine($event)" sortField="claim.visit.instance.serviceBegin">
    <ng-template pTemplate="header">
        <tr>
            <th>Line Id</th>
            <th pSortableColumn="claim.visit.instance.serviceBegin">Service Date <p-sortIcon field="claim.visit.instance.serviceBegin"></p-sortIcon></th>
            <th pSortableColumn="cost">Cost <p-sortIcon field="cost"></p-sortIcon></th>
        </tr>
    </ng-template>
    <ng-template pTemplate="body" let-line>
        <tr [pSelectableRow]="line">
            <td>{{line.lineId}}</td>
            <td>{{line.claim.visit.instance.serviceBegin | date:'shortDate'}}</td>
            <td>{{line.cost | currency}}</td>
        </tr>
    </ng-template>
</p-table>
</div>