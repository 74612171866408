export class WhiteLabelProgram
{
    id: number;
    programName: string;
    phoneNumber: string;
    twilioQueue: string;
    personality: string;
    emailSuffix: string;

    asUpdate(): WhiteLabelProgramUpdate
    {
        return Object.assign(new WhiteLabelProgramUpdate(),this);
    }
}

export class WhiteLabelProgramUpdate
{
    programName: string;
    phoneNumber: string;
    twilioQueue: string;
    personality: string;
    emailSuffix: string;
}