import { InstanceOfCare } from '../claim/instance_of_care';
import { Line } from '../claim/line';
import { Procedure } from '../claim/procedure';
import { User } from '../util/user';

export class EncounterModelProcedure
{
    id: number;
    // instanceOfCare: InstanceOfCare;
    // line: Line;
    cost: number;
    unitCost: number;
    units: number;
    procedure: Procedure;
    modifiedByUser: User;
    instanceId: number;
    lineId:  number;
    mrfProcedurePriceId: number;

    toUpdate(): EncounterModelProcedureUpdate
    {
        let empu = new EncounterModelProcedureUpdate();
        empu.instanceId = this.instanceId;
        empu.lineId = this.lineId;
        empu.mrfProcedurePriceId = this.mrfProcedurePriceId;
        return empu;
    }
}

export class EncounterModelProcedureUpdate
{
    id: number;
    instanceId: number;
    lineId: number;
    mrfProcedurePriceId: number;
}