<!-- New Email Dialog -->
<p-dialog appendTo="body" header="New Email" [(visible)]="newEmailVisible"
                            [modal]="true" [dismissableMask]="true" [closeOnEscape]="true"
                            [closable]="true" [style]="{width: '75%'}">

    <div style="height: 620px;">
        <div *ngIf="pendingEmail != null" class="formgrid grid">
            
            <div class="field col-6 mt-3 grid" >
                <div class="col-12 mt-3 grid align-items-center">
                    <div class="col-2 justify-content-start" style="text-align: left;"><span>From:</span></div>
                    <div class="col-3 justify-content-end" style="text-align: right;"><span>{{emailFrom}}</span></div>
                    <div class="col-7"><p-dropdown class="w12" [(ngModel)]="selectedEmailDomain" [options]="emailDomains"
                        [showClear]="true" [autoDisplayFirst]="false" ></p-dropdown></div>
                </div>
                
                <div class="col-12 mt-3">
                    <span class="field p-float-label">
                        <p-chips class="w12" id="shortDesc" separator="," [addOnTab]="true" [addOnBlur]="true" [(ngModel)]="pendingEmail.recipients"></p-chips>
                        <label for="shortDesc">Recipients</label>
                    </span>
                </div>

                <div class="col-12 mt-3" >
                    <span class="field p-float-label">
                        <p-chips class="w12" id="shortDesc" separator="," [addOnTab]="true" [addOnBlur]="true" [(ngModel)]="pendingEmail.ccs"></p-chips>
                        <label for="shortDesc">CC</label>
                    </span>
                </div>

                <div class="col-12 mt-3" >
                    <span class="field p-float-label">
                        <p-chips class="w12" id="shortDesc" separator="," [addOnTab]="true" [addOnBlur]="true" [(ngModel)]="pendingEmail.bccs"></p-chips>
                        <label for="shortDesc">BCC</label>
                    </span>
                </div>
            </div>

            <div class="mt-3 col-6">
               
                <div>
                    <p-pickList [source]="encounterFiles" [target]="selectedFilesToSend">
                        <ng-template let-file pTemplate="item">
                            <div class="grid">
                                <div class="col-11 grid mt-3">
                                    <div class="col-2">                    
                                        <i *ngIf="file.contentType.includes('image')" class="pi pi-image"></i>
                                        <i *ngIf="file.contentType == 'application/pdf'" class="pi pi-file-pdf"></i>
                                    </div>
                                    <div class="col-10">
                                        {{file.shortDesc}}
                                    </div>
                                    <div class="col-12">
                                        <p-tag>{{file.fileType}}</p-tag>
                                    </div>
                                </div>
                                <div class="col-1">
                                    <button pButton pRipple type="button" icon="pi pi-search" (click)="previewFile(file)" class="p-button-rounded p-button-text"></button> 
                                </div>
                            </div>
                        </ng-template>
                    </p-pickList>
                </div>
            </div>
    <!-- 
            <div class="field col-3">
                <span class="p-float-label">
                    <p-dropdown id="noteType" [(ngModel)]="newNote.noteType" [options]="noteTypes" optionLabel="typeName"
                                [showClear]="true" [autoDisplayFirst]="false" dataKey="id" ></p-dropdown>
                    <label for="noteType">Type</label>                    
                </span>
            </div> -->

            <!-- <div class="col-6">

                
            </div> -->

            <!-- <div class="col-6">
                
            </div> -->



            <div class="field col-12 mt-3" >
                <span class="field p-float-label">
                    <input style="width:100%" class="w12" id="shortDesc" type="text" pInputText [(ngModel)]="pendingEmail.subject">
                    <label for="shortDesc">Subject</label>
                </span>
            </div>

            <!-- <div class="field col-12 mt-3" >
                <span class="p-float-label">
                    <p-chips id="shortDesc" separator="," [addOnTab]="true" [addOnBlur]="true" [(ngModel)]="pendingEmail.ccs"></p-chips>
                    <label for="shortDesc">CC</label>
                </span>
            </div>


            <div class="field col-12 mt-3" >
                <span class="p-float-label">
                    <p-chips id="shortDesc" separator="," [addOnTab]="true" [addOnBlur]="true" [(ngModel)]="pendingEmail.bccs"></p-chips>
                    <label for="shortDesc">BCC</label>
                </span>
            </div> -->

            <!-- <div class="field col-9">
            </div> -->

            <div class ="col-12">
                <p-editor [(ngModel)]="pendingEmail.body" [style]="{'height':'250px'}" >
                </p-editor>
            </div>

            
        </div>
    </div>
    <div>
        <div class="flex align-items-center justify-content-center">
            <span class="p-buttonset">
                <button pButton pRipple label="Cancel" class="p-button-small p-button-rounded p-button-danger" (click)="cancelButton($event)"></button>
                <button pButton pRipple label="Send!" class="p-button-small p-button-rounded p-button-success" (click)="saveButton($event)"></button>
            </span>
        </div>
    </div>

<!-- Mouseover Panel  -->
 <p-overlayPanel #details [style]="{width: '30vw'}">
    <ng-template pTemplate>
        <div class="grid flex align-items-center"  (mouseleave)="details.hide()">
            <div class="col-8">
                From: <p-tag [style]="{'background-color':'darkgrey'}">{{selectedEmail.from}}</p-tag>
            </div>
            <div class="col-4">
                <button pButton pRipple type="button" style="font-size: 8px;" icon="material-icons mi-reply" (click)="reply(selectedEmail)" class="p-button-rounded p-button-text"></button> 
                <button pButton pRipple type="button" style="font-size: 8px;" icon="material-icons mi-reply-all" (click)="replyAll(selectedEmail)" class="p-button-rounded p-button-text"></button>             
                <button pButton pRipple type="button" style="font-size: 8px;" icon="material-icons mi-forward" (click)="forward(selectedEmail)" class="p-button-rounded p-button-text"></button>

            </div>
            <div class="col-4">
                Recipients:<p-tag *ngFor="let r of selectedEmail.recipients">{{r}}</p-tag>
            </div>
            <div class="col-4">
                <div>Cc:</div>
                <p-tag *ngFor="let r of selectedEmail.cCs">{{r}}</p-tag>
            </div>
            <div class="col-4">
                <div>Bcc:</div>
                <p-tag *ngFor="let r of selectedEmail.bcCs">{{r}}</p-tag>
            </div>
            <div class="col-12">
                Subject: {{selectedEmail.subject}}
            </div>
            <div class="col-12">
                <p-editor [readonly]="true"  [(ngModel)]="selectedEmail.body">
                    <ng-template pTemplate="header">
                        Body:
                    </ng-template>
                </p-editor>
            </div>
            <div>
                Attachments:
                <!-- Attachments -->
                <div *ngFor="let a of selectedEmail.attachments" class="flex align-items-center">
                    <button *ngIf="a.contentType.includes('image')" pButton pRipple type="button" icon="pi pi-image" (click)="previewFile(a)" class="p-button-text"></button> 
                    <button *ngIf="a.contentType == 'application/pdf'" pButton pRipple type="button" icon="pi pi-file-pdf" (click)="previewFile(a)" class="p-button-text"></button> 
                    <span (click)="previewFile(a)" style="cursor: pointer"  >{{a.description}}</span>                    
                </div>
            </div>
            <!-- <div class="col-6">
                Sent: {{selectedFax.faxSendTime | date:'short'}}
            </div>
            <div class="col-6">
                From: {{selectedFax.from}}
            </div>
            <div class="col-6">
                Complete: {{selectedFax.faxCompleteTime | date: 'short'}}
            </div>
            <div class="col-6">/*
                Status: {{selectedFax.status}}
            </div>
            <div class="col-6"> 
                Pages (Complete/Total): {{selectedFax.pagesSent}}/{{selectedFax.totalPages}}
            </div>
            <div class="col-6">
                Subject: {{selectedFax.subject}}
            </div>
            <div class="col-6">
                Files: <div *ngFor="let f of faxFiles(selectedFax.attachmentIds)"> {{f.baseName}} - {{f.fileType}}</div>
            </div>
            <div class="col-12">
                Notes: {{selectedFax.notes}}
            </div> -->
        </div>
    </ng-template>
</p-overlayPanel>


</p-dialog>

<p-table [value]="encounterEmails" dataKey="id" scrollHeight="160px" sortMode="single" sortField="dateSent" [sortOrder]="-1">

    <ng-template pTemplate="caption">
        <div class="grid flex align-items-center">
            <div class="col-10 table-header">
                Emails
            </div>
            <div class="col-2">
                <button pButton pRipple type="button" icon="pi pi-plus" class="p-button-rounded" (click)="newEmail()"></button>
            </div>
        </div>
    </ng-template>
    <ng-template pTemplate="header">
        <tr>
            <th style="width: 5%"></th>
            <th style="width: 5%">
                <span>
                    <i  class="pi pi-arrow-right" style="color:chartreuse"></i>
                    <i  class="pi pi-arrow-left" style="color:darkorange"></i>
                </span>
            </th>
            <th style="width: 15%">Date</th>
            <!-- <th style="width: 6em">To/From</th> -->
            <th style="width: 50%">Subject</th>
            <th style="width: 10%;"></th>
        </tr>
    </ng-template>
    <ng-template pTemplate="body" let-e>
        <!-- <tr (mouseenter)="selectedEmail=e;details.show($event)"> -->
        <tr>
            <td style="width: 5%"><span><i *ngIf="!e.read" class="pi pi-circle-on" style="color:steelblue"></i></span></td>
            <td style="width: 5%">
                <span>
                    <i *ngIf="e.sent" class="pi pi-arrow-right" style="color:chartreuse"></i>
                    <i *ngIf="e.received" class="pi pi-arrow-left" style="color:darkorange"></i>
                </span>
            </td>
            <td style="width: 15%">{{e.dateSent | date: 'shortDate'}}</td>
            <!-- <td style="width: 6em"><p-tag *ngFor="let r of e.recipients">{{r}}</p-tag></td> -->
            <td style="width: 50%">{{e.subject}}</td>
            <td style="width: 10%">
                <button pButton pRipple type="button" style="font-size: 8px;" icon="pi pi-search" (click)="selectedEmail=e;read(e);details.show($event)" class="p-button-rounded p-button-text"></button> 
            </td>
        </tr>
    </ng-template>
</p-table>
