import { User } from '../util/user';
import { ContactType } from '../util/contact_type';

export class PhoneNumber
{
  phoneId: number;
  phoneNumber: string;
  phoneType: ContactType;
  isMobileNumber: boolean;
  active: boolean;
  effectiveDate: Date;
  modifiedBy: User;

  constructor()
  {
    // console.log(this);
    this.phoneNumber = null;
    this.phoneType = new ContactType();
    this.isMobileNumber = false;
    this.active = true;
  }
}

export class PhoneNumberUpdate
{
  phoneId: number;
  phoneNumber: string;
  phoneType: ContactType;
  isMobileNumber: boolean;
  active: boolean;
  effectiveDate: Date;
}
