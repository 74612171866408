import { ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { TcemsService } from 'src/app/api/tcems.service';
import { Phi } from 'src/app/model/phi/phi';
import { Plan } from 'src/app/model/phi/plan';
import { Company } from 'src/app/model/support/company';
import { Network } from 'src/app/model/support/network';
import { TcemsUtilitiesService } from 'src/app/util/tcems-utilities.service';

@Component({
  selector: 'app-plan-summary',
  templateUrl: './plan-summary.component.html',
  styleUrls: ['./plan-summary.component.css']
})
export class PlanSummaryComponent implements OnInit, OnChanges {

  private _plans:Plan[];
  @Input() phi: Phi;
  set plans(val: Plan[])
  {
    this._plans = [...val];
  }

  planEditable(plan: Plan) : boolean
  {
    if(this.phi == null) return false;
    if(this.phi.currentActivePlan != null) return this.phi.currentActivePlan.memberId == plan.memberId;
    return !plan.company.source.receiveEligibility
  }


  get plans():Plan[]
  {
    return this._plans == null || this._plans.length == 0 ? [] : this._plans.sort(
      (a,b) =>
      {
        if (a.active && !b.active)
          return -1;
        else if (!a.active && b.active)
          return 1;
        else if (a.active == b.active)
          return a.effectiveDate > b.effectiveDate ? 1 : -1;
     }
     )
  }

  planStatuses: {label:string, value:boolean}[] = [{label:"Active",value:true},{label:"Inactive",value:false}];
  pendingPlanStatus: boolean = null;
  pendingNewPlan: Plan = new Plan();
  pendingNewPlanSubscriber: Phi = null;
  pendingNewCompany: Company = null;

  availableNetworks: Network[] = [];
  subscriberSearchResult: Phi = null;
  phiSearchResults: Phi[] = [];

  showBusy:boolean = false;
  showExpired = 0;

  // @Output() planUpdated: EventEmitter<Plan> = new EventEmitter<Plan>();
  // @Output() planInserted: EventEmitter<Plan> = new EventEmitter<Plan>();
  // @Output() phiCompanyChanged: EventEmitter<Company> = new EventEmitter<Company>();
  @Output() phiChange: EventEmitter<Phi> = new EventEmitter<Phi>();


  constructor(private tcems:TcemsService, private cdr: ChangeDetectorRef,public util: TcemsUtilitiesService) { }
  
  
  ngOnChanges(changes: SimpleChanges): void 
  {
    this.init();
  }
  
  ngOnInit(): void 
  {
    this.init();
  }

  init()
  {

      //instantiate from our supplied phi
      this.tcems.getPlansForPhi(this.phi.phiId).toPromise().then
      (
        plans =>
          this.plans= plans.map(p => Object.assign(new Plan(), p))
      )

      this.resetPendingPlan();
      if(this.phi.company != null)
      {
        this.loadAvailableNetworks(this.phi.company.companyId);
      }  
  }

  loadAvailableNetworks(companyId: number)
  {
    this.tcems.getNetworksForCompany(companyId).toPromise().then
    (
      networks => 
      {
        this.availableNetworks = networks.map(n => Object.assign(new Network(), n));
        this.availableNetworks.sort((a,b) => a.displayName > b.displayName ? 1 : -1);
      }
    );
  }


  formComplete():boolean
  {

    let formGood: boolean = true;

    if(!this.pendingNewPlan.isSubscriber)
    {
      formGood = this.pendingNewPlan.dependentOf != null
    }
      
    return formGood && this.pendingNewPlan.planType != null && this.pendingNewPlan.network != null

  }

  subscriberSelected(event:{phi:Phi, encounterId:number})
  {
    this.tcems.getPhi(event.phi.phiId).toPromise().then
    (
      phi =>
      {
        //We have a company change to have to deal with here.
        if(phi.company != this.phi.company)
        {
          this.pendingNewPlan.network = null;
          this.pendingNewCompany = phi.company;
          this.loadAvailableNetworks(this.pendingNewCompany.companyId);
        }

        this.pendingNewPlanSubscriber = Object.assign(new Phi(), phi);
      }
    )  
  }

  subscriberPlanSelected(event)
  {
    //Set the network for our plan to whatever the network of the subscriber is
    this.pendingNewPlan.network = event.value.network;//this.availableNetworks.find(n => n.networkId == event.value.network.networkId);
  }

  subscriberChanged(event)
  {
    this.pendingNewPlan.isSubscriber = event.checked;
    if (!event.checked)
    {
      this.pendingNewPlanSubscriber = null;
      this.pendingNewPlan.network = null;
      this.loadAvailableNetworks(this.phi.company.companyId);
    }
  }

  resetPendingPlan()
  {

      //Tee up our new plan to have some of the basics already set up
      this.pendingNewPlan = new Plan();
      this.pendingNewPlan.phi = this.phi;
      this.pendingNewPlan.isSubscriber = true;
      this.pendingNewPlan.active =true;
      this.pendingNewPlan.effectiveDate = new Date();
      this.pendingNewPlan.hsaEligible = false;
      this.pendingNewPlanSubscriber = null;     
      this.pendingNewCompany = null;
  }

  preparePendingStatus(plan:Plan)
  {
    this.pendingPlanStatus = plan.active;
  }

  commitStatusChange(plan:Plan)
  {
    this.showBusy = true;
    let pu = plan.asUpdate();
    pu.active = this.pendingPlanStatus;
    this.tcems.updatePlan({phiId: this.phi.phiId,memberId: plan.memberId,planUpdate:pu}).toPromise().then
      (
        plan => 
        {
          //After we update the plan, best thing to do is just get the phi all over again, rather than
          //try to manage state...
          this.tcems.getPhi(plan.phi.phiId).toPromise().then
          (
            phi => 
            {
              let realPhi = Object.assign(new Phi(), phi);
              // this.pendingPlanStatus = null;
              // let company = Object.assign(new Company(), plan.company);
    

              //Change our active status
              // this.phi.plans.find(p => p.memberId == plan.memberId).active = plan.active;
              // this._plans.find(p => p.memberId == plan.memberId).active = plan.active;
              this.phi = realPhi;
              this.init();

              //Also, double check that the company is set properly everywhere
              // this.phi.plans.find(p => p.memberId == plan.memberId).company = company;
              // this._plans.find(p => p.memberId == plan.memberId).company = company;
              // this.phi.company = company;
              
              // this.phi.plans = [...this.phi.plans];
              // this.plans = [...this.plans];
              
    
              //change detection
              // this.phi = Object.assign(new Phi(),this.phi);
    
 
              //this.planUpdated.emit(this.plans.find(p => p.memberId));
              this.phiChange.emit(this.phi);
              this.cdr.detectChanges();
              this.showBusy = false;
            }
          );
        }
      );
  }

  confirmNewPlan(event, overlay)
  {
    this.showBusy = true;
    this.tcems.newPlan({plan: this.pendingNewPlan.asUpdate(),memberId:this.pendingNewPlan.memberId,phiId:this.phi.phiId}).toPromise().then
    (
      plan => 
      {
        //get a full blown version of the phi to insert
        this.tcems.getPlan(plan.memberId).toPromise().then
        (
          planDetail => 
          {

            this.tcems.getPhi(planDetail.phi.phiId).toPromise().then
            (
              phi => 
              {
                let realPhi = Object.assign(new Phi(), phi);
                // this.pendingPlanStatus = null;
                // let company = Object.assign(new Company(), plan.company);
      
  
                //Change our active status
                // this.phi.plans.find(p => p.memberId == plan.memberId).active = plan.active;
                // this._plans.find(p => p.memberId == plan.memberId).active = plan.active;
                this.phi = realPhi;
                this.init();
                
                //this.planInserted.emit(p);
                this.phiChange.emit(this.phi);
    
                this.cdr.detectChanges();
                this.resetPendingPlan();

                overlay.hide();
                this.showBusy = false;
              }
            )
          }
        );
      }
    )

            // //instantiate the plan
            // let p = Object.assign(new Plan(), planDetail);
            // this.phi.plans = [...this.phi.plans, planDetail];
            // this._plans = [...this._plans, planDetail];

            // //Now, we also need to enter a new people record (potentially)
            // if(this.pendingNewCompany != null)
            // {
            //   this.tcems.updatePhiCompany({phiId:this.phi.phiId,companyId:this.pendingNewCompany.companyId}).toPromise().then
            //   (
            //   )
            //   this.phi.company = this.pendingNewCompany;
            // }

            // //change detection
            // this.phi = Object.assign(new Phi(),this.phi);

            // //this.planInserted.emit(p);
            // this.phiChange.emit(this.phi);

            // this.cdr.detectChanges();
            // this.resetPendingPlan();
            // //close the overlay
            // overlay.hide();
    //       }
    //     )
    //   }
    // );


  }

  companySelected(company:Company)
  {
    //Change what networks we have access to
    this.loadAvailableNetworks(company.companyId);
    this.pendingNewPlan.company = company;
  }


  cancelNewPlan(overlay)
  {
    this.pendingNewPlan = new Plan();
    overlay.hide();
  }

}
