import { Component, Input, OnChanges, OnInit, SimpleChanges, EventEmitter, Output } from '@angular/core';
import { TcemsService  } from 'src/app/api/tcems.service';
import { Phi, PhiUpdate } from 'src/app/model/phi/phi';
import { CalendarModule } from 'primeng/calendar';
import { PhoneNumber, PhoneNumberUpdate } from '../../model/phi/phone-number';
import { EmailAddress } from '../../model/phi/email-address';
import { ContactType } from '../../model/util/contact_type';
import { selectContactTypes, selectTransactionType } from '../../state/app.state';
import { select, Store } from '@ngrx/store';

@Component({
  selector: 'app-phi-summary',
  templateUrl: './phi-summary.component.html',
  styleUrls: ['./phi-summary.component.css']
})
export class PhiDetailsComponent implements OnInit, OnChanges{

  constructor(private store: Store, private tcems: TcemsService) {
  }
  //@Input() phiId: number;
  @Input() phi: Phi;
  @Input() header: string;
  @Input() editablePhi: boolean;
  @Input() showAbbreviated = false;
  @Output() optOutStatusChange: EventEmitter<boolean> = new EventEmitter<boolean>()

  currentMemberSubscriber: Phi;

  idx: number;
  showPhoneEditor = false;
  showEmailEditor = false;
  showAddressEditor = false;
  originalPhi: string[] = [];
  originalDob: Date;

  contactTypes$ = this.store.select(selectContactTypes);
  ContactTypes: ContactType[];

  ngOnInit(): void
  {
    this.contactTypes$.subscribe(t => {this.ContactTypes = t; });
    this.instantiatePhi();
  }

  ngOnChanges(changes: SimpleChanges): void 
  {
    this.instantiatePhi();
  }

  instantiatePhi(): void
  {
    //Can't be too sure what we got for phi... fetch from the db again
    //this.tcems.getPhi(this.phiId).toPromise().then
    //(
    //  phi =>
    //  {
    //    this.phi = Object.assign(new Phi(), phi);
    this.phi = Object.assign(new Phi(), this.phi);

        //Fetch our subscriber
        if(!this.phi.isSubscriber)
        {
          this.tcems.getPhi(this.phi.currentSubscriber.phiId).toPromise().then
          (
            sub => 
            {
              this.currentMemberSubscriber = Object.assign(new Phi(), sub);
            }
          )
        }
        else
          this.currentMemberSubscriber = this.phi;
    //  }
    //);
    
    // //Get subscriber deets
    // if (!this.phi.isSubscriber)
    // {
    //   this.tcems.getPhi(this.phi.currentSubscriber.phiId).toPromise().then
    //   (
    //     sub => 
    //      this.currentMemberSubscriber = Object.assign(new Phi(), sub)
    //   )
    // }
    // else
    // {
    //   this.currentMemberSubscriber = this.phi;
    // }
  }

  updatePhiName(event, phiToUpdate: Phi): void {
    let phiUpdate: PhiUpdate = new PhiUpdate();
    phiUpdate.firstName = phiToUpdate.firstName;
    phiUpdate.lastName = phiToUpdate.lastName;
    phiUpdate.middleInitial = phiToUpdate.middleInitial;

    // this.tcems.updatePhi(phiUpdate).toPromise()
    this.tcems.updatePhi
    (
      {
        phiId: phiToUpdate.phiId,
        phiUpdate
      }
    ).toPromise()
      .then
      (
        result =>
        phiUpdate = result
      )
      .catch
    (
      e =>
      {
        console.log('Update PHI Name Failed: ' + e);
      }
    );
  }

  updateDob(event, phiToUpdate: Phi): void {
    let phiUpdate: PhiUpdate = new PhiUpdate();
    phiUpdate.dob = phiToUpdate.dob;

    // this.tcems.updatePhi(phiUpdate).toPromise()
    this.tcems.updatePhi
    (
      {
        phiId: phiToUpdate.phiId,
        phiUpdate
      }
    ).toPromise()
      .then
      (
        result =>
        phiUpdate = result
      )
      .catch
    (
      e =>
      {
        console.log('Update PHI DOB Failed: ' + e);
      }
    );
  }

  updatePhiDnp(event,phiToUpdate: Phi): void {
    let phiUpdate: PhiUpdate = new PhiUpdate();
    phiUpdate.hasOptedOut = phiToUpdate.hasOptedOut;

    this.tcems.updatePhi
    (
      {
        phiId: phiToUpdate.phiId,
        phiUpdate
      }
    ).toPromise()
      .then
      (
        result =>
        phiUpdate = result
      )
      .catch
    (
      e =>
      {
        console.log('Update PHI hasOptedOut Failed: ' + e);
      }
    );
    this.optOutStatusChange.emit(phiToUpdate.hasOptedOut);

  }

  setColor( n:number ) {
    switch( n ) {
      case 1:
        return 'success';
        break;
      case 2:
        return 'warning';
        break;
      case 3:
        return 'info';
        break;
      default:
        return 'danger'
    }
  }

}
