
<div class="w12 p-5 formgrid grid">

    <div class="field col-6">
        <!-- <span class="p-float-label">
            <p-inputNumber [disabled]="!providerToBeat.override" [(ngModel)]="providerToBeat.cost" inputId="providerToBeatFinal" mode="currency" currency="USD" locale="en-US"></p-inputNumber>                            
            <label [for]="providerToBeatFinal">Price To Beat</label> 
        </span> -->
        <span class="p-float-label">
            <p-dropdown requied="true"  appendTo="body" id="transType" [options]="util?.transactionTypes" [(ngModel)]="selectedTransactionType" optionLabel="transactionDescription" [style]="{'width':'200px'}" [showClear]="true" placeholder="Transaction Type"></p-dropdown>
        </span>
    </div>
    <div class="field col-6">
        <span class="p-float-label">
            <p-dropdown [required]="true" appendTo="body" id="checkingAccount" [options]="util?.checkingAccounts" [(ngModel)]="selectedCheckingAccount" optionLabel="accountName" [style]="{'width':'200px'}" [showClear]="true" placeholder="Checking Account"></p-dropdown>
        </span>
    </div>
    <div class="field col-3">
        <span class="p-float-label">
            <p-inputNumber [required]="true" [(ngModel)]="encounterId" mode="decimal" inputId="encounterId" [useGrouping]="false"></p-inputNumber>
            <label for="encounterId">Encounter Id</label>
        </span>
    </div>
    <div class="field col-3">
        <span class="p-float-label">
            <input pInputText type="text" id="firstName" [ngModel]="firstName" disabled="disabled"/>
            <label [for]="firstName">First Name</label>
        </span>
    </div>   
    <div class="field col-3">
        <span class="p-float-label">
            <input  pInputText type="text"  id="lastName" [ngModel]="lastName" disabled="disabled"/>
            <label [for]="lastName">Last Name</label>
        </span>
    </div>
    <div class="field col-3">
        <span class="p-float-label">
            <input pInputText type="text"  id="company" [ngModel]="company" disabled="disabled"/>
            <label [for]="company">Company</label>
        </span>
    </div> 
    <div class="field col-3">
        <span class="p-float-label">
            <p-inputNumber [required]="true" [(ngModel)]="amount" mode="currency" inputId="amount" currency="USD" locale="en-US"></p-inputNumber>
            <label for="amount">Transaction Amount</label>
        </span>
    </div> 
    <div class="col-9"></div>
    <div class="col-3 align-self-center">
        <p-button type="button" label="Create" icon="pi pi-money-bill" (onClick)="persist($event)"></p-button>
    </div>
    
</div>