import { ZipCenter } from '../geocode/zipcode';
import { User } from '../util/user';

export class Location
{
    locationId:number;
    locationGeocodeId: number;
    street1: string;
    street2: string;
    city:string;
    state:string;
    zip:string;
    lat:string;
    lon:string;
    conversionLocation:boolean;
    modifiedByUser:User;

    get fullAddress ():string
    {
        return this.street1 + " " + (this.street2 ?? "") + "\n" + this.city + ", " + this.state + " " + this.zip; 
    }
}