import { ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { LazyLoadEvent } from 'primeng/api';
import { TcemsService } from 'src/app/api/tcems.service';
import { Phi } from 'src/app/model/phi/phi';
import { Diary, DiaryType } from 'src/app/model/support/diary';
import { ReferenceCode } from 'src/app/model/support/reference_code';
import { LazyDiaryBuffer, LazyEncounterDiaryBuffer, LazyRelatedToDiaryBuffer, LazyUserDiaryBuffer } from 'src/app/model/util/resultpaging';
import { User } from 'src/app/model/util/user';
import { TcemsUtilitiesService } from 'src/app/util/tcems-utilities.service';
import { formatDate } from '@angular/common';
import { loggedIn } from '../../actions/login.actions';
import { selectCurrentUser } from '../../state/app.state';
import { Store } from '@ngrx/store';


@Component({
  selector: 'app-diary-scroller',
  templateUrl: './diary-scroller.component.html',
  styleUrls: ['./diary-scroller.component.scss']
})
export class DiaryScrollerComponent implements OnInit, OnChanges
{

  @Input() getForCurrentUser: boolean = false;
  @Input() referenceCode:number;
  @Input() relatedTo: number;
  @Input() allowStatusSelection: boolean = true;
  @Input() set rowBufferLen(val: string)
  {
    this.scrollSize = parseInt(val);
  }
  @Input() showReferenceLinks: boolean = false;
  @Input() allowNew: boolean = true;

  @Output() loadDiaryReference:EventEmitter<Diary> = new EventEmitter<Diary>();

  loggedIn: boolean = false;

  loggedIn$ = this.store.select(selectCurrentUser);
  loggedInUser: User;

  availableStatuses = [{val: "A", display: "Active"},{val: "O",display:"Open"},{val: "C", display:"Closed"}];
  selectedStatuses:string[] =  ["A","O"];

  todaysDate = formatDate(Date.now(), 'yyyyMMdd', 'en-US');
  fiveDaysAgo = formatDate(Date.now() + -5 * 24 * 60 * 60 * 1000, 'yyyyMMdd', 'en-US');

  diaryBuffer: LazyDiaryBuffer;
  lastLazyLoadEvent: LazyLoadEvent;
  scrollSize: number = 4;
  bufferSize: number = this.scrollSize * 5;

  diaryDialogs: {[key: number]: boolean} ={};
  forwardDialogs: {[key: number]: boolean} ={};
  diaryPhi: {[key: number]: Phi}={};
  showNewDiaryDialog: boolean = false;
  showBusy: boolean = false;
  newDiary: Diary = new Diary();
  activeUsers: User[] = [];
  diaryTypes: DiaryType[] = [];

  // cachedDiaries: Diary[] = [];

  // Constants for the diary view

  diaryCardHeight:number = 140;
  diaryCardHeightInPixels:string = this.diaryCardHeight.toString() + 'px';
  // tableSize: string = ((this.diaryCardHeight * this.scrollSize /5)*.95).toString() + "px";
  // tableSize: string = ((this.diaryCardHeight * this.scrollSize) + 30).toString() + "px";
  // tableSize: string = "650px";
  constructor(
    private tcems: TcemsService,
    public util: TcemsUtilitiesService,
    private store: Store,
    private cdr: ChangeDetectorRef
  )
  {}

  ngOnChanges(changes: SimpleChanges): void 
  {
    //Update the buffer size
    this.bufferSize = this.scrollSize *5;
  }
  
  ngOnInit(): void
  {
    if (this.getForCurrentUser)
    {
      this.util.currentUser$.subscribe
      (
        loggedInUser => this.loadDiaryBuffer()
      );
    }
    else
      this.loadDiaryBuffer();

    // Get our active users
    // this.tcems.getActiveUsers().toPromise()
    // .then
    // (
    //   users => this.activeUsers = users.map(u => Object.assign(new User(), u)).sort((a,b) => a.displayName < b.displayName ? -1 : 1 )
    // );
    this.util.activeUsers$.subscribe
    (
      au =>
      {
        this.activeUsers = this.util.activeNurses;
      }
    )

    //Get Diary Types
    this.tcems.getAllDiaryTypes().toPromise()
    .then
    (
      types => this.diaryTypes = types.map(t => Object.assign(new DiaryType(), t)).sort((a,b) => a.typeName < b.typeName ? -1 : 1 )
    );

    // Subscribe to a logged in event and set our user.
    this.loggedIn$.subscribe(
      u =>
      {
        if (u == null) { return; }
        this.loggedInUser = Object.assign(new User(), u);
      });

  }

  

  loadDiaryBuffer()
  {
    // Get diaries for the current user
    if(this.util.currentUser != null && this.util.currentUser.userId != null)
    {
      if (this.getForCurrentUser )
      {
        // Once logged in, we can get our diaries
        this.diaryBuffer = new LazyUserDiaryBuffer(this.tcems, this.util.currentUser, this.bufferSize, this.cdr);
        this.loggedIn = true;
        // Get our first set of rows
        this.diaryBuffer.lazyLoadFromBuffer(0, this.scrollSize);
      }

      else
      {
        // Set this up for a specific kind of object
        // if (this.referenceCode == ReferenceCode.TYPE_ENCOUNTER)
        // {
          this.diaryBuffer = new LazyRelatedToDiaryBuffer(this.tcems,this.relatedTo,this.referenceCode,this.bufferSize,this.selectedStatuses,this.cdr)
          this.loggedIn = true;
          //Get our first set of rows
          this.diaryBuffer.lazyLoadFromBuffer(0,this.scrollSize);
        // }
      }
    }
    // this.cdr.detectChanges();
  }

  loadMoreDiaries(event:LazyLoadEvent)
  {
    if (this.loggedIn)
    {
      this.lastLazyLoadEvent = event;
      this.diaryBuffer.lazyLoadFromBuffer(event.first, event.rows);
      event.forceUpdate();
    }
  }

  showDiaryDialog(diary: Diary)
  {
    
    
    if(diary.referenceType.code == ReferenceCode.TYPE_ENCOUNTER)
    {
      //To get the phi for this, we need to fetch the entire encounte first
      this.tcems.getEncounter(diary.referenceId).toPromise().then
      (
        encounter =>
        {
          //The phi in the encounter isn't quite enough... we need the whole thing
          this.tcems.getPhi(encounter.patient.phi.phiId).toPromise().then
          (
            phi =>
            {
              this.diaryPhi[diary.diaryId] = Object.assign(new Phi(),phi);
              this.diaryDialogs[diary.diaryId] = true;
            }
          )
        }
      ) 
    }
    else
    {
      this.diaryDialogs[diary.diaryId] = true;
    }

  }


  loadReference(diary:Diary)
  {
    this.loadDiaryReference.emit(diary);
  }

  updateStatus(event)
  {
    this.loadDiaryBuffer();
  }

  finishDiaryAdd()
  {
    this.showNewDiaryDialog = false;
    this.newDiary = new Diary();
    this.loadDiaryBuffer();
    //Reload
    this.loadMoreDiaries(this.lastLazyLoadEvent);
    this.showBusy = false;
  }

  createNewDiary(event): void
  {
    // Show New Diary Dialog
    this.newDiary = new Diary();
    this.newDiary.assignedTo = this.loggedInUser;
    this.newDiary.diaryDueDate = new Date( Date.now() ); // new Date( Date.now() + 7 * 24 * 60 * 60 * 1000);
    this.showNewDiaryDialog = true;
  }

  cancelButton(event)
  {
    this.showNewDiaryDialog = false;
    this.newDiary = new Diary();
  }

  loadForward(diary:Diary)
  {
    this.forwardDialogs[diary.diaryId] = true;
  }

  saveButton(event)
  {
    this.showBusy = true;
    //Commit this thing
    this.tcems.createDiary({referenceCode: this.referenceCode, relatedTo: this.relatedTo, diary: this.newDiary.asUpdate()}).toPromise()
    .then
    (
      result =>
      {
        // we should have a new diary at this point. If we need to assign it, then do so
        if (this.newDiary.assignedTo != null)
        {
          this.tcems.assignDiary({diaryId: result.diaryId, assignedTo:this.newDiary.assignedTo,diary:this.newDiary.asUpdate()}).toPromise()
          .then
          (
            assignedDiary =>
            {
              this.finishDiaryAdd();
            }
          );
        }
        else
        {
          this.finishDiaryAdd();
        }
      }
    )
    .catch
    (
      error =>
      {
        console.log('Save Diary Failed: ' + error);
        this.showBusy = false;
      }
    );
  }

  assignDiary(event, diary: Diary)
  {
    this.showBusy = true;
    this.tcems.assignDiary({diaryId: diary.diaryId, assignedTo: diary.assignedTo, diary:diary.asUpdate()}).toPromise()
    .then
    (
      assignedDiary =>
      {
        this.loadDiaryBuffer();
        this.forwardDialogs[diary.diaryId] = false;
        this.showBusy = false;
      }
    )
  }

  closeDiary(event, diary:Diary)
  {
    this.showBusy = true;
    this.tcems.closeDiary({diaryId:diary.diaryId}).toPromise()
    .then
    (
      closedDiary =>
      {
        this.loadDiaryBuffer();
        this.showBusy = false;
      }
    )
  }
}
