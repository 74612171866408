import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TcemsService } from 'src/app/api/tcems.service';
import { ClosureReason } from 'src/app/model/encounter/closure_reason';
import { Encounter, EncounterUpdate } from 'src/app/model/encounter/encounter';
import { EncounterStatus } from 'src/app/model/encounter/encounter_status';
import { TcemsUtilitiesService } from 'src/app/util/tcems-utilities.service';

@Component({
  selector: 'app-encounter-summary',
  templateUrl: './encounter-summary.component.html',
  styleUrls: ['./encounter-summary.component.css']
})

export class EncounterSummaryComponent implements OnInit {

  @Input() encounter: Encounter;
  pendingStatus:EncounterStatus;
  pendingClosureReason:ClosureReason;
  closed: number = EncounterStatus.STATUS_CLOSED;
  showClosureEdit: boolean = false;
  get isConfirmed():boolean
  {
    return this.encounter.status.statusCode == EncounterStatus.STATUS_CONFIRMED || this.encounter.status.statusCode == EncounterStatus.STATUS_COMPLETE;
  }

  @Output() statusChange: EventEmitter<EncounterStatus> = new EventEmitter<EncounterStatus>();
  @Output() closureReasonChange: EventEmitter<ClosureReason> = new EventEmitter<ClosureReason>();

  constructor(private tcems:TcemsService, public util:TcemsUtilitiesService)
  {
  }

  ngOnInit(): void
  {
    this.pendingStatus = this.util.encounterStatuses.find(s => s.statusCode == this.encounter.status.statusCode);
    this.pendingClosureReason = this.util.closureReasons.find( cr => cr.reasonCode == this.encounter.closureReason?.reasonCode);
  }


  processStatusChange(event)
  {
    // console.log("Status Changed");
    // If we're closing things, then we need to show the closure reason edit first
    // otherwise, we can just change!
    if (this.pendingStatus.statusCode == EncounterStatus.STATUS_CLOSED)
    {
      this.showClosureEdit = true;
    }
    else
    {
      this.showClosureEdit = false;
      this.commitEncounterStatus(null);
    }
  }

  commitEncounterStatus(event)
  {
    // need a closure reason
    if (this.pendingClosureReason == null && this.pendingStatus.statusCode == EncounterStatus.STATUS_CLOSED)
    {
      this.showClosureEdit = true;
      return false;
    }
    // Create our update object
    let updateEncounter: EncounterUpdate = new EncounterUpdate();
    updateEncounter.status = this.pendingStatus;
    if (this.pendingClosureReason != null) updateEncounter.closureReason = this.pendingClosureReason;
    this.showClosureEdit = false;

    this.tcems.updateEncounterRaw({encounterId:this.encounter.encounterId,encounter:updateEncounter}).toPromise()
    .then
    (
      result =>
      {
        this.encounter.status = this.pendingStatus;
        //And, fire our event emitter
        this.statusChange.emit(result.status);

        if(this.pendingClosureReason != null)
        {
          this.encounter.closureReason = this.pendingClosureReason;
          this.closureReasonChange.emit(result.closureReason);
        }
      }
    );
  }


}
