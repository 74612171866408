<p-table #appointmentTable [value]="appointments" editMode="row" dataKey="id">
    <ng-template pTemplate="caption">
        <div class="grid flex align-items-center">
            <div class="col-10 table-header">
                Appointments
            </div>
            <div class="col-2">
                <button pButton pRipple type="button" icon="pi pi-plus" class="p-button-rounded" (click)="addNewRow(appointmentTable)"></button>
            </div>
        </div>

    </ng-template>
    <ng-template pTemplate="header">
      <tr>
        <th style="width:10rem">Status</th>
        <th style="width:10rem">Date</th>
        <th>Location</th>
        <th style="width:8rem"></th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-appointment let-editing="editing" let-ri="rowIndex">
      <tr [pEditableRow]="appointment">
          <td class="mt-2">
              <p-cellEditor>
                <ng-template pTemplate="input">
                    <span class="p-float-label">
                        <p-dropdown appendTo="body" id="apptStatus" [options]="util.appointmentStatuses" [(ngModel)]="appointment.status" optionLabel="statusDescription" [autoDisplayFirst]="false"></p-dropdown>
                        <label for="apptStatus">Status</label>
                    </span>
                </ng-template>
                  <ng-template pTemplate="output">
                      {{appointment.status?.statusDescription}}
                  </ng-template>
              </p-cellEditor>
          </td>
          <td>
            <p-cellEditor>
                <ng-template pTemplate="input">
                    <span class="p-float-label">
                        <p-calendar appendTo="body" id="apptDate" [(ngModel)]="appointment.appointmentDate"></p-calendar>
                        <label for="apptDate">Appointment Date</label>
                    </span>
                </ng-template>
                <ng-template pTemplate="output">
                    {{appointment.appointmentDate | date: 'shortDate'}}
                </ng-template>
            </p-cellEditor>
        </td>
        <td>
          <p-cellEditor>
              <ng-template pTemplate="input">
                <span class="p-float-label">
                    <p-dropdown  appendTo="body"  id="provider" [options]="availableProviderLocations" [(ngModel)]="appointment.providerLocation" optionLabel="practiceName" [autoDisplayFirst]="false"></p-dropdown>
                    <label for="provider">Location</label>
                </span>
              </ng-template>
              <ng-template pTemplate="output">
                  {{appointment.providerLocation?.practiceName}}
              </ng-template>
          </p-cellEditor>
      </td>
      <td style="text-align:center">
        <button *ngIf="!editing" pButton pRipple type="button" pInitEditableRow icon="pi pi-pencil" (click)="onRowEditInit(appointment)" class="p-button-rounded p-button-text"></button>
        <button *ngIf="editing" pButton pRipple type="button" pSaveEditableRow icon="pi pi-check" (click)="onRowEditSave(appointment, ri)" class="p-button-rounded p-button-text p-button-success mr-2"></button>
        <button *ngIf="editing" pButton pRipple type="button" pCancelEditableRow icon="pi pi-times" (click)="onRowEditCancel(appointment, ri)" class="p-button-rounded p-button-text p-button-danger"></button>
    </td>
    </tr>
    </ng-template>
    </p-table>
