import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TcemsService } from 'src/app/api/tcems.service';
import { InstanceOfCare } from 'src/app/model/claim/instance_of_care';
import { Line } from 'src/app/model/claim/line';
import { Procedure } from 'src/app/model/claim/procedure';
import { CategoryBasedResult, PracticeCategoryResult, PracticeResult } from 'src/app/model/claim/procedure_lookup_result';
import { EncounterModelProcedure } from 'src/app/model/encounter/encounter_model_procedure';
import { Phi } from 'src/app/model/phi/phi';
import { PhoneNumber } from 'src/app/model/phi/phone-number';
import { Practice } from 'src/app/model/provider/practice';
import { ProviderLocation } from 'src/app/model/provider/provider_location';
import { InstanceAndLines } from '../../../instancelist/instancelist.component';
import { ResultsSelection } from '../results-selection';

@Component({
  selector: 'app-category-results',
  templateUrl: './category-results.component.html',
  styleUrls: ['./category-results.component.css']
})
export class CategoryResultsComponent extends ResultsSelection<CategoryBasedResult> implements OnInit {


  private _categoryResults: PracticeCategoryResult[] = [];
  private _selectedResult: PracticeResult;


  @Input() set categoryResults(val: PracticeCategoryResult[])
  {
    if(val == null)
    {
      this._categoryResults = [];
      this.instances = {};
    }
    else
    {
      this._categoryResults = val;
    }


    this._categoryResults = this._categoryResults.map
    (
      pr => 
      {
        pr.practice = Object.assign(new Practice(),pr.practice);
        pr.categoryBasedResult = Object.assign(new PracticeCategoryResult(), pr.categoryBasedResult);

        return Object.assign(new PracticeCategoryResult(),pr);
      }
    );
  }

  get categoryResults(): PracticeCategoryResult[]
  {
    return this._categoryResults;
  }

  instances: {[key:string]: InstanceAndLines[]} = {};
  instanceLines: {[key:number]: Line[]}= {};
  chosenLines: Line[] = [];
  chosenInstance: InstanceOfCare;
  showInstanceSelector: {[key:string]: boolean} = {};
  showPracticeEditor: {[key:string]: boolean} = {};


  constructor(private tcems:TcemsService)
  {
    super();
  }

  ngOnInit(): void {
  }

  showDialog(ein: string )
  {
    this.showInstanceSelector[ein] = true;
  }

  closeInstanceDialog(ein: string)
  {
    this.showInstanceSelector[ein] = false;
  }

  updateSelections(): EncounterModelProcedure[] {
    //Convert our lines into EMPs

    var retArr: EncounterModelProcedure[] = this.chosenLines.map
    (
      l =>
      {
        var emp = new EncounterModelProcedure();
        emp.instanceId = this.chosenInstance.instanceId;
        emp.lineId =  l.lineId;
        emp.cost = l.cost;
        emp.procedure = l.procedure;
        emp.unitCost = l.unitCost;
        emp.units = l.units;
        // emp.instanceOfCare = this.chosenInstance;
        // emp.line = l;
        return emp;
      }
    );


    // console.log(retArr);
    return retArr;
  }

  updateChosenResult(): PracticeResult
  {
    return this._selectedResult;
  }

  instanceChosen(result: PracticeResult, practice: Practice, event: InstanceAndLines)
  {
    this.chosenInstance = event.instance;
    this._selectedResult = result;
    this.chosenLines= event.lines;
    this.chosenLines.forEach(l => l.procedure = Object.assign(new Procedure(),l.procedure));
    this.publishSelection();
  }

  loadInstances(ein: string, data: CategoryBasedResult)
  {
    //Get our lines
    //We will make several async calls to get lines

        // if(this.instances[ein] == null || this.instances[ein].length == 0)
        // {
        //   this.instances[ein] = [];


        //   for (var instanceId in data.instanceAndLineIds)
        //   {
        //     //See if we've already got these guys loaded

        //       this.tcems.getInstanceOfCare(parseInt(instanceId)).toPromise()
        //       .then
        //       (
        //         result =>
        //         {
        //           var il = new InstanceAndLines();
        //           var instance:InstanceOfCare = Object.assign(new InstanceOfCare(),result);
        //           var lines: Line[] = instance.lines.filter(l => data.instanceAndLineIds[instance.instanceId].includes(l.lineId)).map(l => Object.assign(new Line(), l));
        //           il.instance = instance;
        //           il.lines = lines;
        //           this.instances[ein] = this.instances[ein].concat([il]);
        //           if (this.instanceLines[instance.instanceId] == null || this.instanceLines[instance.instanceId].length == 0)
        //           {
        //             this.instanceLines[instance.instanceId] = [];
        //           }
        //           this.instanceLines[instance.instanceId] = lines;
        //         }
        //       )
        //     }
        // }


    for (var i in data.instanceAndLineIds)
    {
      //See if we've already got these guys loaded
      if(this.instances[ein] == null || this.instances[ein].length == 0)
      {
        this.instances[ein] = [];
        this.tcems.getInstanceOfCare(parseInt(i)).toPromise()
        .then
        (
          result =>
          {
            var il = new InstanceAndLines();
            var instance:InstanceOfCare = Object.assign(new InstanceOfCare(),result);
            var lines: Line[] = instance.lines.filter(l => data.instanceAndLineIds[instance.instanceId].includes(l.lineId)).map(l => Object.assign(new Line(), l));
            il.instance = instance;
            il.lines = lines;
            this.instances[ein] = [...this.instances[ein], il];// this.instances[ein].concat([il]);
            if (this.instanceLines[instance.instanceId] == null || this.instanceLines[instance.instanceId].length == 0)
            {
              this.instanceLines[instance.instanceId] = [];
            }
            this.instanceLines[instance.instanceId] = lines;
          }
        )
      }
    }

  }



}
