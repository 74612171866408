import { Company } from "./company";

export class CompanyHierarchy
{
    id:number;
    client: Company;
    tier2Label: string;
    tier3Label: string;
    tier4Label: string;
    tier5Label: string;
    tier6Label: string;
    tier7Label: string;
    tier8Label: string; 
    attachmentTier: number;
    employerTier: number;

    asUpdate():CompanyHierarchyUpdate
    {
        return Object.assign(new CompanyHierarchyUpdate(), this);
    }

    get maxTier(): number
    {
        if(this.tier8Label != null){return 8;}
        else if(this.tier7Label != null){return 7;}
        else if(this.tier6Label != null){return 6;}
        else if(this.tier5Label != null){return 5;}
        else if(this.tier4Label != null){return 4;}
        else if(this.tier3Label != null){return 3;}
        else if(this.tier2Label != null){return 2;}
        else {return 1;}
    }
}

export class CompanyHierarchyUpdate
{
    tier2Label: string;
    tier3Label: string;
    tier4Label: string;
    tier5Label: string;
    tier6Label: string;
    tier7Label: string;
    tier8Label: string; 
    attachmentTier: number;
    employerTier: number;
}