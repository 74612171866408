<p-dialog [(visible)]="showPhoneEditor" [style]="{width:'600px'}" [closable]="false">
  <table style="width:100%">
    <tr>
      <td style="text-align: center;background-color: #007ad9;color: white">Active</td>
      <td style="text-align: center;background-color: #007ad9;color: white">Type</td>
      <td style="text-align: center;background-color: #007ad9;color: white">Number</td>
      <td style="text-align: center;background-color: #007ad9;color: white">Cell</td>
      <td *ngIf="showMarketing" style="text-align: center;background-color: #007ad9;color: white">Mktg</td>
    </tr>
    <tr *ngFor="let ph of phoneNumbers; index as idx">
      <td style="text-align:center">
        <p-checkbox [(ngModel)]="ph.active" [binary]="true" [disabled]="!editablePhi"></p-checkbox>
      </td>
      <td style="text-align:center">
        <p-dropdown [options]="ContactTypes" [(ngModel)]="ph.phoneType" optionLabel="description" appendTo="body"></p-dropdown>
      </td>
      <td style="text-align:center">
        <p-inputMask [(ngModel)]="ph.phoneNumber" mask="(999) 999-9999? x*****" [style]="{'width':'175px'}"></p-inputMask>
      </td>
      <td style="text-align:center">
        <p-checkbox [(ngModel)]="ph.isMobileNumber" [binary]="true" [disabled]="!editablePhi"></p-checkbox>
      </td>
      <td *ngIf="showMarketing" style="text-align:center">
        <p-checkbox [binary]="true" [disabled]="!editablePhi"></p-checkbox>
      </td>
    </tr>
  </table>
  <ng-template pTemplate="footer">
    <button pButton pRipple label="Add Phone" class="p-button-small p-button-rounded p-button-info" (click)="addPhone()"></button>
    <span class="p-buttonset">
            <button pButton pRipple label="Cancel" class="p-button-small p-button-rounded p-button-danger" (click)="cancelButton($event)"></button>
            <button pButton pRipple label="Save" class="p-button-small p-button-rounded p-button-success" (click)="updateButton($event)"></button>
          </span>
  </ng-template>
</p-dialog>
