import { Component, OnInit } from '@angular/core';
import { MenuItem, MessageService } from 'primeng/api';
import { TcemsService } from 'src/app/api/tcems.service';
import { ReportOutputFormat, ReportSpec, RuntimeClientReportSpec, RuntimeParameter, RuntimeReportSpec } from 'src/app/model/reports/report_spec';
import { Company } from 'src/app/model/support/company';
import { TcemsUtilitiesService } from 'src/app/util/tcems-utilities.service';

@Component({
  selector: 'app-reportrunner',
  templateUrl: './reportrunner.component.html',
  styleUrls: ['./reportrunner.component.scss']
})
export class ReportrunnerComponent implements OnInit {

  steps: MenuItem[];
  activeStep:number = 0;
  prevDisabled: boolean = false;
  nextDisabled: boolean = false;
  
  step1: MenuItem = {label: 'Choose Report Type', disabled: false};
  reportPeriodStep: MenuItem = {label: 'Select Period', disabled: false};
  chooseCompanyStep: MenuItem = {label: 'Select Companies', disabled: false};
  chooseReportStep: MenuItem = {label: 'Select Report', disabled: false}
  setParamsStep: MenuItem = {label: 'Supply Parameters', disabled: false};
  executeStep: MenuItem = {label: 'Execute!', disabled: false}
  
  availableCompanies: Company[]; 
  availableReports: ReportSpec[];
  availableOutputFormats: ReportOutputFormat[];


  selectedReportType: string = null;
  selectedCompanies: Company[] = [];
  selectedPeriod: string;
  selectedPeriodName: string;
  selectedYear: number;
  selectedReport: ReportSpec;  
  selectedOutputFormat: ReportOutputFormat;
  reportParameters: RuntimeParameter[];

  periods: string[] = ["Annual","Quarterly","Monthly","Ad Hoc"];
  periodNames: string[] = [];
  months: string[] = ["January", "February", "March","April","May","June","July","August","September","October","November","December"];
  quarters: string [] = ["Q1","Q2","Q3","Q4"]
  years: number[] = [...Array(5).keys()].map(y =>  (new Date()).getFullYear() - y); 
  


  constructor(private ms: MessageService, public util: TcemsUtilitiesService, private tcems: TcemsService) 
  { 

  }

  ngOnInit(): void 
  {
    this.goToStep1();

    //Load our company list
    this.tcems.getRootCompanies().toPromise().then
    (
      result =>
        {
          this.availableCompanies = result.filter(r => r.active).map(r => Object.assign(new Company(), r));
          this.availableCompanies.sort((a,b) => a.companyName.localeCompare(b.companyName));
        }
    )

    this.tcems.getReportOutputFormats().toPromise().then
    (
      result => 
      {
        this.availableOutputFormats = result.map(r => Object.assign(new ReportOutputFormat(), r));
        this.availableOutputFormats.sort((a,b) => a.fileExtension.localeCompare(b.fileExtension));
      }
    )

    

  }

  goToStep1()
  {
    this.setSteps();
    this.activeStep = 0;
    this.prevDisabled = true;
    this.nextDisabled = false;
    this.chooseCompanyStep.disabled = true;
    this.reportPeriodStep.disabled = true;
  }

  setSteps()
  {
    //Set our base steps

    this.steps =
    [
      this.step1,this.chooseCompanyStep,this.reportPeriodStep,this.chooseReportStep,this.setParamsStep,this.executeStep
    ];
  }


  nextStep()
  {
    if(!this.validateInput())
      return;

    if(this.activeStep == 0)
    {
      //Check if client or system
      if(this.selectedReportType == 'client')
      {
        this.chooseCompanyStep.disabled = false;
        this.reportPeriodStep.disabled = false;
        this.activeStep = 1;

        this.tcems.getClientReportSpecs().toPromise().then
        (
          result =>
            {
              this.availableReports = result.map(r => Object.assign(new ReportSpec(), r));
              this.availableReports.sort((a,b) => a.reportName.localeCompare(b.reportName));
            }
        )
      }
      else
      {
        this.activeStep = 3;
        this.tcems.getSystemReportSpecs().toPromise().then
        (
          result =>
            {
              this.availableReports = result.map(r => Object.assign(new ReportSpec(), r));
              this.availableReports.sort((a,b) => a.reportName.localeCompare(b.reportName));
            }
        )
      }
    }
    else
    {
      this.activeStep = Math.min(this.activeStep + 1, this.steps.length - 1);
    }

    if(this.activeStep > 0)
    {
      this.prevDisabled = false;
    }
    if(this.activeStep == this.steps.length - 1)
    {
      this.nextDisabled = true;
    }
  }

  prevStep()
  {

    if(this.activeStep == 3)
    {
      if(this.selectedReportType == 'client')
      {
        this.activeStep = 2;
      }
      else
      {
        this.activeStep = 0
      }
    }
    else
    {
      this.activeStep = Math.max(0, this.activeStep - 1);
    }

    if(this.activeStep == 0)
    {
      this.prevDisabled = true;
    }
    if(this.activeStep < this.steps.length - 1)
    {
      this.nextDisabled = false;
    }
  }

  validateInput():boolean
  {
    if(this.activeStep == 0 &&(this.selectedReportType == null))
    {
      this.ms.add({key:'reportRunner', severity: 'error',summary:'Error',detail:'Please select a report type to run'});
    }
    else if(this.activeStep == 1 && (this.selectedCompanies == null || this.selectedCompanies.length == 0))
    {
      this.ms.add({key:'reportRunner', severity: 'error',summary:'Error',detail:'Please Select a company'});

    }
    
    else if(this.activeStep == 2 && (this.selectedPeriod == null || (this.selectedPeriodName == null && this.selectedPeriod!="Annual")))
    {
      this.ms.add({key:'reportRunner', severity: 'error',summary:'Error',detail:'Please select a report period and value'});

    }
    else if (this.activeStep == 3 && (this.selectedReport == null || this.selectedOutputFormat == null))
    {
      this.ms.add({key:'reportRunner', severity: 'error',summary:'Error',detail:'Please select a report to run and an output format'});

    }
    else if (this.activeStep == 4 && this.reportParameters.filter(p => p.param.required).map(p => p.value).includes(null) )
    {
      this.ms.add({key:'reportRunner', severity: 'error',summary:'Error',detail:'All Required arameters must contain a value'});
    }
    else
    {
      return true;
    }

    return false;
    
  }

  reportSelected()
  {
    this.reportParameters = this.selectedReport.runtimeParameters;
  }

  periodSelected()
  {
    if(this.selectedPeriod == "Quarterly")
    {
      this.periodNames = [...this.quarters];
    }
    else if (this.selectedPeriod == "Monthly")
    {
      this.periodNames = [...this.months];
    }
    else
    {
      this.periodNames = [];
    }
  }

  executeReport()
  {

    //Determine if we are in client or system mode
    if(this.selectedReportType == 'client')
    {    
      let runtimeSpec: RuntimeClientReportSpec = new RuntimeClientReportSpec();

      runtimeSpec.clientIds = this.selectedCompanies.map(c => c.companyId);
      runtimeSpec.clientParam = this.reportParameters.find(p=>p.param.clientParameter).parameterSpecId;
      runtimeSpec.exportSpecId = this.selectedOutputFormat.id;
      runtimeSpec.parameters = this.reportParameters.filter(p => !p.param.clientParameter && p.value != null);
      runtimeSpec.reportSpecId = this.selectedReport.reportId;

      //we've got our spec built out
      //Run it!

      this.tcems.runClientReports(
        {
          year: this.selectedYear,
          period: this.selectedPeriod,
          spec: runtimeSpec,
          ... this.selectedPeriod == "Ad Hoc" && {fileName: this.selectedPeriodName},
          ... this.selectedPeriod == "Monthly" && {month: this.months.findIndex(m => m==this.selectedPeriodName)+1},
          ... this.selectedPeriod == "Quarterly" && {quarter: this.quarters.findIndex(q=> q==this.selectedPeriodName)+1}
        }
      ).toPromise()
      .then(
        result=>
        {
          this.ms.add({key:'reportRunner', severity: 'success',summary:'Report Started',detail: result.length.toString() + ' Jobs started'});
        }
      )
    }
    else
    {    
      let runtimeSpec: RuntimeReportSpec = new RuntimeReportSpec();

      runtimeSpec.exportSpecId = this.selectedOutputFormat.id;
      runtimeSpec.parameters = this.reportParameters.filter(p => !p.param.clientParameter && p.value != null);
      runtimeSpec.reportSpecId = this.selectedReport.reportId;

      //we've got our spec built out
      //Run it!

      this.tcems.runSystemReport(
        {
          year: (new Date()).getFullYear(),
          fileName: this.selectedReport.reportName,
          spec: runtimeSpec
        }
      ).toPromise()
      .then(
        result=>
        {
          this.ms.add({key:'reportRunner', severity: 'success',summary:'Report Started',detail: '1 Job started'});
        }
      )
    }
  }

}
