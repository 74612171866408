<p-dialog [(visible)]="showEmailEditor" [style]="{width:'600px'}" [closable]="false">
  <table style="width:100%">
    <tr>
      <td style="text-align: center;background-color: #007ad9;color: white">Active</td>
      <td style="text-align: center;background-color: #007ad9;color: white">Type</td>
      <td style="text-align: center;background-color: #007ad9;color: white">Email Address</td>
      <td *ngIf="showMarketing" style="text-align: center;background-color: #007ad9;color: white">Mktg</td>
    </tr>
    <tr *ngFor="let em of emailAddresses; index as idx">
      <td style="text-align:center">
        <p-checkbox [(ngModel)]="em.active" [binary]="true" [disabled]="!editablePhi"></p-checkbox>
      </td>
      <td style="text-align:center">
        <p-dropdown [options]="ContactTypes" [(ngModel)]="em.emailType" optionLabel="description" appendTo="body"></p-dropdown>
      </td>
      <td style="text-align:center">
        <input type="text" [(ngModel)]="em.emailAddress" [style]="{'width':'175px'}">
      </td>
      <td *ngIf="showMarketing" style="text-align:center">
        <p-checkbox [binary]="true" [disabled]="!editablePhi"></p-checkbox>
      </td>
    </tr>
  </table>
  <ng-template pTemplate="footer">
    <button pButton pRipple label="Add Email" class="p-button-small p-button-rounded p-button-info" (click)="addEmail()"></button>
    <span class="p-buttonset">
      <button pButton pRipple label="Cancel" class="p-button-small p-button-rounded p-button-danger" (click)="cancelButton($event)"></button>
      <button pButton pRipple label="Save" class="p-button-small p-button-rounded p-button-success" (click)="updateButton($event)"></button>
    </span>
  </ng-template>
</p-dialog>
