import { User } from '../util/user';
import { Location } from './location'
import { PracticeLocation } from './practice_location';
export class ProviderLocation
{
    id: number;
    npi: string;
    ein: string;
    location: Location;
    active: boolean;
    confirmed: boolean;
    placeId: string;
    modifiedBy: User;
    practiceName: string;
    providerName: string;
    providerOrganizationName: string;
    providerFirstName: string;
    providerLastName: string;
    providerIsOrganization: boolean;
    conversionLocation: boolean;
    alternateLocation: boolean;
    phoneNumber:string;
    faxNumber:string;
    practiceLocation: PracticeLocation;

    toUpdate():ProviderLocationUpdate
    {
        let retVal: ProviderLocationUpdate = new ProviderLocationUpdate();
        retVal.active = this.active;
        retVal.alternateLocation = this.alternateLocation;
        retVal.confirmed = this.confirmed;
        retVal.conversionLocation = this.conversionLocation;
        retVal.ein = this.ein;
        retVal.locationId = this.location.locationId;
        retVal.npi = this.npi;

        return retVal;
    }
}

export class ProviderLocationUpdate
{
    npi:string;
    ein:string;
    locationId: number;
    active: boolean;
    conversionLocation: boolean;
    alternateLocation: boolean;
    confirmed: boolean;
    phoneNumber: string;
    faxNumber: string;
}