<p-dialog [modal]="true" [(visible)]="showUpdateDialog" [closable]="false" appendTo="body" [showHeader]="false" [style]="{'box-shadow':'none'}" [contentStyle]="{'background':'rgba(0,0,0,0)'}">
    <p-progressSpinner [style]="{'background':'rgba(0,0,0,0)','width':'50px','height':'50px'}" animationDuration=".5s" strokeWidth="8" ></p-progressSpinner>
</p-dialog>




<div class="col-12 scroll-overflow mt-3 mb-3" >
    <!-- Locations -->
    <p-card header="Locations">
        <div class="grid">
            <div class="col-12">
                <p-table #dt1 [value]="providerLocationsByLocationId" dataKey="locationId" [filters]="tableFilter" sortField="facilityCount" [sortOrder]="-1" [scrollable]="true" scrollHeight="400px" >
                    <ng-template pTemplate="header">
                        <tr>
                            <th style="width: 1rem"></th>
                            <th style="width: 3rem;">Active</th>
                            <th style="width: 3rem;">Confirmed
                                <p-columnFilter field="confirmed" display="menu" type="boolean">
                                </p-columnFilter>
                            </th>
                            <th pSortableColumn="location.street1" style="width: 10rem;">Street <p-sortIcon field="location.street1"></p-sortIcon></th>
                            <th style="width: 6rem;">City</th>
                            <th style="width: 3rem;">State</th>
                            <th style="width: 5rem;">Zip Code</th>
                            <th pSortableColumn="providerLocations.length" style="width: 3rem;"># <p-sortIcon field="providerLocations.length"></p-sortIcon></th>
                            <th pSortableColumn="facilityCount" style="width: 3rem;">Facility <p-sortIcon field="facilityCount"></p-sortIcon></th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-location let-expanded="expanded">
                        <tr>
                            <td style="width: 1rem">
                                <button type="button" pButton pRipple [pRowToggler]="location" class="p-button-text p-button-rounded p-button-plain" [icon]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"></button>
                            </td>
                            <td style="width: 3rem;"><p-checkbox [binary]="true" [disabled]="!edit" [ngModel]="location.active" (onChange)="toggleLocationActive($event,location)"  ></p-checkbox></td>
                            <td style="width: 3rem;"><p-checkbox [binary]="true" [disabled]="!edit" [ngModel]="location.confirmed"  (onChange)="toggleLocationConfirmed($event, location)"></p-checkbox></td>
                            <td style="width: 10rem;">{{location.location.street1}} {{location.location.street2}}</td>
                            <td style="width: 6rem;">{{location.location.city}}</td>
                            <td style="width: 3rem;">{{location.location.state}}</td>
                            <td style="width: 5rem;">{{location.location.zip}}</td>
                            <td style="width: 3rem;">{{location.providerLocations.length}}</td>
                            <!-- <td style="width: 3rem;"><p-checkbox [binary]="true" [disabled]="true" [ngModel]="location.isFacility" ></p-checkbox></td> -->
                            <td style="width: 3rem;">
                                <div *ngIf="location.isFacility">
                                    <p-overlayPanel appendTo="body" #facilityList>
                                        <div *ngFor="let facility of location.facilities">
                                            {{facility.providerName}}
                                        </div>
                                    </p-overlayPanel>
                                    <p-badge [value]="location.facilityCount" styleClass="mr-2" (mouseover)="facilityList.show($event)" (mouseleave)="facilityList.hide()" ></p-badge>
                                </div>
                            </td>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="rowexpansion" let-location>
                        <tr>
                            <td colspan="9">
                                <div class="p-3">
                                    <p-table [value]="location.providerLocations" selectionMode="single" [(selection)]="selectedProviderLocation" [sortOrder]="-1" sortField="providerIsOrganization" dataKey="id" (onRowSelect)="providerLocationSelected($event)" (onRowUnselect)="providerLocationUnselected($event)">
                                        <ng-template pTemplate="header">
                                            <tr>
                                                <th *ngIf="select" style="width: 3rem;"></th>
                                                <th style="width: 6rem;">Active</th>
                                                <th style="width: 9rem;">Confirmed</th>
                                                <th style="width: 7rem;">Facility</th>
                                                <th style="width: 3rem;"></th>
                                                <th>Provider Name</th>
                                                <th>NPI</th>
                                            </tr>
                                        </ng-template>
                                        <ng-template pTemplate="body" let-pl>
                                            <tr>
                                                <td *ngIf="select"><p-tableRadioButton [value]="pl"></p-tableRadioButton></td>
                                                <td style="width: 3rem;"><p-checkbox [binary]="true" [ngModel]="pl.active" [disabled]="true"></p-checkbox></td>
                                                <td style="width: 3rem;"><p-checkbox [binary]="true" [ngModel]="pl.confirmed" [disabled]="true"></p-checkbox></td>
                                                <td><p-checkbox [binary]="true" [disabled]="true" [ngModel]="pl.providerIsOrganization"></p-checkbox></td>
                                                <td>
                                                    <p-overlayPanel appendTo="body" #phoneNumberEditor>
                                                        <div class="form-grid grid w12">
                                                            <div class="col-4 field">
                                                                <span class="p-float-label">
                                                                    <p-inputMask id="phone" [(ngModel)]="pl.phoneNumber" mask="(999) 999-9999? x*****"></p-inputMask>
                                                                    <label for="phone">Phone</label>
                                                                </span>
                                                            </div>
                                                            <div class="col-4 field">
                                                                <span class="p-float-label">
                                                                    <p-inputMask id="fax" [(ngModel)]="pl.faxNumber" mask="(999) 999-9999? x*****"></p-inputMask>
                                                                    <label for="fax">Fax</label>
                                                                </span>
                                                            </div>
                                                            <div class="col-4 field">
                                                                <p-button icon="pi pi-check" class="p-button-sm" (click)="commitPhone(pl); phoneNumberEditor.hide()"></p-button>
                                                                <p-button icon="pi pi-times" class="p-button-sm" (click)="phoneNumberEditor.hide()"></p-button>
                                                            </div>
                                                        </div>
                                                    </p-overlayPanel>
                                                    <button *ngIf="pl.providerIsOrganization" type="button" pButton pRipple class="p-button-text p-button-rounded p-button-plain" icon="pi pi-phone" (click)="phoneNumberEditor.toggle($event)" ></button>
                                                </td>
                                                <td>{{pl.providerIsOrganization ? pl.providerOrganizationName : (pl.providerFirstName + ' ' + pl.providerLastName)}}</td>
                                                <td>{{pl.npi}}</td>
                                            </tr>
                                        </ng-template>
                                    </p-table>
                                </div>
                            </td>
                        </tr>
                    </ng-template>

                </p-table>
            </div>
        </div>
    </p-card>
</div>
