 <!-- New Fax Dialog  -->
 <p-dialog [modal]="true" [(visible)]="newFaxVisible" [style]="{width: '50vw'}" header="Send Fax">
    <div *ngIf="pendingFax != null" class="w12 p-5 formgrid grid">
        <div class="field col-6">
            <span class="p-float-label">
                <input id="attnInput" type="text" [required]="true" [(ngModel)]=pendingFax.attn pInputText style="width:305px" maxlength="40" />
                <label for="attnInput">Attn</label>
            </span>
        </div>
        <div class="field col-6">
            <span class="p-float-label">
                <p-inputMask  mask="(999) 999-9999" id="faxNumberInput" type="text" [required]="true" [(ngModel)]=pendingFax.faxNumber ></p-inputMask>
                <label for="faxNumberInput">Fax Number</label>
            </span>
        </div>
        <!-- <div class="col-12 grid w12 formgrid"> -->
            <div class="field col-12">
                <span class="p-float-label">
                    <input id="fromInput" type="text" [required]="true" [(ngModel)]="pendingFax.from" pInputText style="width:305px" maxlength="40"/>
                    <label for="fromInput">From</label>
                </span>
            </div>
            <div class="field col-6">
                <span class="p-float-label">
                    <input id="subInput" type="text" [required]="true" [(ngModel)]="pendingFax.subject" pInputText style="width:600px" maxlength="255"/>
                    <label for="subInput">Subject</label>
                </span>            
            </div>
            <div class="field col-12">
                <span class="p-float-label">
                    <textarea id="notesInput" type="text" [required]="true" [(ngModel)]="pendingFax.notes" pInputTextarea style="width:600px; height:100px"></textarea>
                    <label for="subInput">Notes</label>
                </span>            
            </div>
        <!-- </div> -->
        
        <div class="field col-12">
            <p-pickList [source]="encounterFiles" [target]="selectedFilesToSend">
                <ng-template let-file pTemplate="item">
                    <div class="grid">
                        <div class="col-10">
                            <div>
                                {{file.description}} ({{file.lastModified|date:'MM/dd/yy'}})
                            </div>
                            <div>
                                <p-tag>{{file.fileType}}</p-tag>
                            </div>
                        </div>
                        <div class="col-2">
                            <button pButton pRipple type="button" icon="pi pi-search" (click)="previewFile(file)" class="p-button-rounded p-button-text"></button> 
                        </div>
                    </div>
                </ng-template>
            </p-pickList>
        </div>
        
        <div class="col-12 flex align-items-center justify-content-center">
            <span class="p-buttonset">
                <button pButton pRipple label="Cancel" class="p-button-small p-button-rounded p-button-danger" (click)="cancelButton($event)"></button>
                <button active pButton pRipple [disabled]="!saveButtonActive" label="Send" class="p-button-small p-button-rounded p-button-success" (click)="saveButton($event)"></button>
            </span>
        </div>
        
    </div>
 </p-dialog>
 
 <!-- Mouseover Panel  -->
 <p-overlayPanel #details [style]="{width: '30vw'}">
    <ng-template pTemplate>
        <div class="grid flex">
            <div class="col-6">
                To: {{selectedFax.attn}} {{selectedFax.faxNumber}}
            </div>
            <div class="col-6">
                Sent: {{selectedFax.faxSendTime | date:'short'}}
            </div>
            <div class="col-6">
                From: {{selectedFax.from}}
            </div>
            <div class="col-6">
                Complete: {{selectedFax.faxCompleteTime | date: 'short'}}
            </div>
            <div class="col-6">
                Status: {{selectedFax.status}}
            </div>
            <div class="col-6"> 
                Pages (Complete/Total): {{selectedFax.pagesSent}}/{{selectedFax.totalPages}}
            </div>
            <div class="col-6">
                Subject: {{selectedFax.subject}}
            </div>
            <div class="col-6">
                Files: <div *ngFor="let f of faxFiles(selectedFax.attachmentIds)"> {{f.description}} - {{f.fileType}}</div>
            </div>
            <div class="col-12">
                Notes: {{selectedFax.notes}}
            </div>
        </div>
    </ng-template>
</p-overlayPanel>


<p-table  [value]="encounterFaxes" dataKey="id" scrollHeight="160px">
    
    <ng-template pTemplate="caption">
        <div class="grid flex align-items-center">
            <div class="col-10 table-header">
                Faxes
            </div>
            <div class="col-2">
                <button pButton pRipple type="button" icon="pi pi-plus" class="p-button-rounded" (click)="newFax()"></button>
            </div>
        </div>
        <table width="100%">
        <tr>
            <td width="20%">Date</td>
            <td width="50%">To</td>
            <td width="15%">Pgs/Files</td>
            <td width="15%">Status</td>
        </tr>
    </table>
    </ng-template>
    <!--
    <ng-template pTemplate="header">
        <tr>
            <th width="20%">Date</th>
            <th width="55%">To</th>
            <th width="10%">Pgs/Files</th>
            <th width="15%">Status</th>
        </tr>
    </ng-template>
-->
    <ng-template pTemplate="body" let-ef>
        <tr (mouseenter)="selectedFax=ef; details.toggle($event)" (mouseout)="details.toggle($event)">
            <td>
            <table width="100%">
                <tr>
                    <td width="20%" style="font-weight:bold;">{{ef.faxSendTime | date:'shortDate'}}</td>
                    <td width="55%" style="font-weight:bold;">{{ef.attn.substr(0,25)}}</td>
                    <!--<td>{{ef.pagesSent}}/{{ef.totalPages}}</td>-->
                    <td width="10%" style="font-weight:bold;">{{ef.pagesSent}} / {{ef.attachmentIds.length}}</td>
                    <td width="15%" style="font-weight:bold;">{{ef.status}}</td>
                </tr>
                <tr>
                    <td colspan="4" style="background:#ffffff;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{ef.subject}}</td>
                </tr>
            </table>
            </td>
        </tr>
    </ng-template>
</p-table>