import { Injectable } from "@angular/core";
import { Observable, catchError, from, map, of } from "rxjs";
import { MassEmailHeader } from "../model/util/massemail/mass_email";
import { ApiService } from "./api.service";
import { environment } from "src/environments/environment";
import { formatDate } from "@angular/common";
import { Job } from "../model/jobs/job";

@Injectable({
    providedIn: 'root'
  })
  export class TcemsJobApi
  {

    constructor(private apiService: ApiService ) {}



    GetJobsStartedBetween<T>(startedAfter: Date, startedBefore: Date): Observable<Job<T>[]>
    {
        return from (this.apiService.makeAPIGetCall<Job<T>[]>
        (
            environment.tcemsAPIBase,
            environment.tcemsAPI_job + "/search",
            {
                ... startedAfter && {startedAfter: formatDate(startedAfter,"MM/dd/yyyy","en-US")},
                ... startedBefore && {startedBefore: formatDate(startedBefore,"MM/dd/yyyy","en-US")},
            }
        ))
        .pipe
        (
            map
            (
                data => 
                {
                    return data.map(d => Object.assign(new Job<T>(), d));
                }
            )
        );
    }

  }