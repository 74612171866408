import { ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { LazyLoadEvent } from 'primeng/api';
import { TcemsService } from 'src/app/api/tcems.service';
import { Phi } from 'src/app/model/phi/phi';
import { Diary } from 'src/app/model/support/diary';
import { Note, NoteType } from 'src/app/model/support/note';
import { ReferenceCode } from 'src/app/model/support/reference_code';
import { LazyDiaryBuffer, LazyEncounterDiaryBuffer, LazyEncounterNoteBuffer, LazyNoteBuffer, LazyRelatedToNoteBuffer, LazyUserDiaryBuffer, LazyUserNoteBuffer } from 'src/app/model/util/resultpaging';
import { User } from 'src/app/model/util/user';
import { TcemsUtilitiesService } from 'src/app/util/tcems-utilities.service';


@Component({
  selector: 'app-note-scroller',
  templateUrl: './note-scroller.component.html',
  styleUrls: ['./note-scroller.component.scss']
})
export class NoteScrollerComponent implements OnInit, OnChanges
{
  @Input() getForCurrentUser: boolean = false;
  @Input() referenceCode:ReferenceCode;
  @Input() relatedTo: number;
  @Input() set rowBufferLen(val: string)
  {
    this.scrollSize = parseInt(val);
  }
  @Input() showReferenceLinks: boolean = false;
  @Input() allowNew: boolean = true;
  @Input() title: string;
  @Input() allowEdit: boolean = false;

  @Output() loadNoteReference:EventEmitter<Note> = new EventEmitter<Note>();

  loggedIn: boolean = false;


  noteBuffer: LazyNoteBuffer;
  lastLazyLoadEvent: LazyLoadEvent;
  scrollSize: number = 4;
  bufferSize: number = this.scrollSize * 3;
  noteDialogs: {[key: number]: boolean} ={};
  showNewNoteDialog: boolean = false;
  showBusy: boolean = false;
  newNote: Note = new Note();
  editMode:boolean = false;
  noteTypes:NoteType [] = [];
  showObsolete: boolean = false;
  noteObsolete: {[key: number]: boolean} = {};
  oneDayAgo: Date;

  //cachedDiaries: Diary[] = [];

  //Constants for the diary view

  noteCardHeight:number = 100;
  noteCardHeightInPixels:string = this.noteCardHeight.toString() +"px";
  //tableSize: string = ((this.diaryCardHeight * this.scrollSize /5)*.95).toString() + "px";
  //tableSize: string = ((this.diaryCardHeight * this.scrollSize) + 30).toString() + "px";
  //tableSize: string = "650px";

  constructor(private tcems:TcemsService, public util: TcemsUtilitiesService, private cdr: ChangeDetectorRef)
  { }

  
  ngOnChanges(changes: SimpleChanges): void 
  {
    //Update the buffer size
    this.bufferSize = this.scrollSize *3;
  }

  ngOnInit(): void
  {
    if(this.getForCurrentUser)
    {
      this.util.currentUser$.subscribe
      (
        loggedInUser => this.loadNoteBuffer()
      )
    }
    this.loadNoteBuffer();

    this.tcems.getAllNoteTypes().toPromise()
    .then
    (
      types => this.noteTypes = types.map(t => Object.assign(new NoteType(), t)).sort((a,b) => a.typeName < b.typeName ? -1 : 1 )
    );

    this.oneDayAgo = new Date();
    this.oneDayAgo.setTime( this.oneDayAgo.getTime() - ( 24 * 60 * 60 * 1000 ) );  // 24 hours
  }

  loadNoteBuffer()
  {
    //Get notes for the current user
    if(this.getForCurrentUser && this.util.currentUser != null && this.util.currentUser.userId != null)
    {
      //Once logged in, we can get our diaries
      this.noteBuffer = new LazyUserNoteBuffer(this.tcems,this.util.currentUser,this.bufferSize,this.cdr);
      this.loggedIn = true;
      //Get our first set of rows
      //this.noteBuffer.lazyLoadFromBuffer(0,this.scrollSize);
    }

    else
    {
      this.noteBuffer = new LazyRelatedToNoteBuffer(this.tcems,this.referenceCode, this.relatedTo,this.bufferSize,this.cdr)
      this.loggedIn = true;
      this.noteBuffer.lazyLoadFromBuffer(0,this.scrollSize);
      //   //Get our first set of rows
      //   this.noteBuffer.lazyLoadFromBuffer(0,this.scrollSize);
      // //Set this up for a specific kind of object
      // if(this.referenceCode == ReferenceCode.TYPE_ENCOUNTER)
      // {
      //   this.noteBuffer = new LazyEncounterNoteBuffer(this.tcems,this.relatedTo,this.bufferSize,this.cdr)
      //   this.loggedIn = true;
      //   //Get our first set of rows
      //   this.noteBuffer.lazyLoadFromBuffer(0,this.scrollSize);
      // }
      // if(this.referenceCode == ReferenceCode.TYPE_PRACTICE)
      // {
      //   this.noteBuffer = new LazyPracticeNoteBuffer(this.tcems,this.relatedTo,this.bufferSize,this.cdr)
      //   this.loggedIn = true;
      //   //Get our first set of rows
      //   this.noteBuffer.lazyLoadFromBuffer(0,this.scrollSize);
      // }
    }

    this.cdr.detectChanges();
  }

  loadMoreNotes(event:LazyLoadEvent)
  {
    if(this.loggedIn)
    {
      this.lastLazyLoadEvent = event;
      this.noteBuffer.lazyLoadFromBuffer(event.first, event.rows);
    }
  }

  showNoteDialog(noteId: number, allowEdit: boolean = false)
  {
    this.editMode = allowEdit;
    this.noteDialogs[noteId] = true;

  }


  loadReference(note:Note)
  {
    this.loadNoteReference.emit(note);
  }

  updateStatus(event)
  {
    this.loadNoteBuffer();
  }

  finishNoteAdd()
  {
    this.showNewNoteDialog = false;
    this.newNote = new Note();
    this.loadNoteBuffer();
    //Reload
    this.loadMoreNotes(this.lastLazyLoadEvent);
    this.showBusy = false;
  }

  createNewNote(event)
  {
    //Show New Diary Dialog
    this.newNote = new Note();
    this.showNewNoteDialog = true;
  }

  cancelButton(event)
  {
    this.showNewNoteDialog = false;
    this.newNote = new Note();
  }

  saveButton(event)
  {

    this.showBusy = true;
    //Commit this thing
    this.tcems.createNote({referenceCode: this.referenceCode.code, relatedTo: this.relatedTo, note: this.newNote.asUpdate()}).toPromise()
    .then
    (
      result =>
      {
          this.finishNoteAdd();
      }
    )
    .catch
    (
      error =>
      {
        console.log('Note Save Failed: ' + error);
        this.showBusy = false;
      }
    )
  }

  editNote(event, note: Note)
  {
    this.showNoteDialog(note.noteId,true);
  }

  cancelEdit(note: Note)
  {
    // console.log("Note Edit Cancelled");
    this.editMode = false;
    this.noteDialogs[note.noteId] = false;
  }

  commitEdit(note: Note)
  {
    this.showBusy = true;
    this.noteDialogs[note.noteId] = false;

    //Set the obsolete status for this note
    let obsolete=this.noteObsolete[note.noteId];
    if(obsolete != null)
    {
      note.isObsolete = obsolete;
    }
    
    // console.log("Commit Edit");
    this.tcems.editNote({noteId: note.noteId, note: note}).toPromise()
    .then
    (
      newNote =>
        {
          this.loadNoteBuffer();
          this.showBusy = false;
        }
    )
  }

  convertToLocal( utcDateString: string ) {
    const utcDate = new Date(utcDateString);
    const offsetMinutes = utcDate.getTimezoneOffset();
    return new Date( utcDate.getTime() - offsetMinutes * 60 * 1000 );
  }

}
