import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-reportmanager',
  templateUrl: './reportmanager.component.html',
  styleUrls: ['./reportmanager.component.scss']
})
export class ReportmanagerComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
