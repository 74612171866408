import { User } from "../util/user";

export class Job<T>
{
    jobId: number;
    jobTitle: string;
    startTime: Date;
    endTime: Date;
    success: boolean;
    fail: boolean;
    cancelled: boolean;
    results: T;
    statusMessage: string;
    startedBy: User;
    get status() : string
    {
        return this.success ? "Completed" : this.fail ? "Failed" : this.cancelled ? "Cancelled" : this.startTime != null ? "Running" : "Created";
    }
}