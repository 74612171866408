import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { TcemsService } from 'src/app/api/tcems.service';
import { Encounter } from 'src/app/model/encounter/encounter';

@Component({
  selector: 'app-encounter-lookup',
  templateUrl: './encounter-lookup.component.html',
  styleUrls: ['./encounter-lookup.component.css']
})
export class EncounterLookupComponent implements OnInit 
{

  encounterId: string;
  encounter: Encounter = null;

  message: string = '';

  opening: boolean = false;
  searchIcon: string = '';

  constructor(private tcems: TcemsService, private store: Store) 
  { 
  }

  ngOnInit(): void 
  {
  }

  openEncounter() :void
  {
    this.updateOpening(true);
    this.tcems.getEncounter(parseInt(this.encounterId)).toPromise().then
    (
      result =>
        {
          this.encounter = result;
          this.updateOpening(false);

        }
    )
    .catch
    (
      e =>
      {
        this.message = e.errorMessage;
      }
    );

  }

  updateOpening(opening:boolean)
  {
    this.opening = opening;
    this.searchIcon = opening ? 'pi pi-spin pi-spinner' : '';
  }

}
